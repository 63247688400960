import { useState } from "react";
import styled from "styled-components";

import TagsAvatarLineUp from "../../library/common/AvatarLineUp";
import Button from "../../library/common/Button";
import ImgSlider from "../../library/common/ImgSlider";
import InputGroup from "../../library/common/InputGroup";
import { truncateText, handleSiteClick } from "../../library/utilities";

import {
    INPUT_TYPES,
    REACT_APP_DEFAULT_USER_PROFILE,
} from "../../library/common/constants";

const PostBodyView = ({
    post,
    setPopUpToDisplay,
    theme,
    history,
    postFullView, // optional
    createPostView, // optional
    handleImageDelete, // optional
    handlePostTextChange, // optional
    userIsAdmin, // optional will render add tags button if no tags present
    handleAvatarLineupClick, // required handle user clicking tags
    startingImgIndex, // if defined then sets initial image to display in slider
    handleAddTag, // required
}) => {
    // Image currently displayed in slider of images
    const [DisplayImgIndex, setDisplayImgIndex] = useState(
        startingImgIndex || 0
    );

    // No limit is applied if postFullView is true
    // Post text longer than limit is cut off but can be viewed with see all button
    const [textCharacterLimit, setTextCharacterLimit] = useState(
        postFullView ? undefined : 280
    );

    // Boxes used to indicate position in image array, only shown when img array length  > 1
    const ScrollBoxes = !!post.img
        ? post.img.map((img, i) => {
            return <ImgScrollBox selected={i === DisplayImgIndex} key={i} />;
        })
        : undefined;

    const postHasTags =
        (post.userTags && post.userTags[0]) ||
        (post.companyTags && post.companyTags[0]);

    return (
        <PostBody>
            {/* POST IMAGE(S) */}
            {post.img[0] && (
                <ImgSlider
                    imgArray={post.img}
                    DisplayImgIndex={DisplayImgIndex}
                    setDisplayImgIndex={setDisplayImgIndex}
                    fullScreenOnClick={createPostView ? false : true}
                    handleDelete={handleImageDelete}
                />
            )}
            {post.site && post.site.name && (
                <SiteRow
                    postHasImg={post.img[0] ? true : false}
                    onClick={(e) => handleSiteClick(e, history, post.site._id)}
                >
                    <PinIcon className="fas fa-map-pin" />
                    <SiteText>{post.site.name}</SiteText>
                </SiteRow>
            )}
            {/* POST TAGS */}
            <TagsRow belowText={post.img[0] ? false : true}>
                {postHasTags && (
                    <TagsAvatarLineUp
                        avatarArray={[
                            // add user and company tags to avatarArray, each company tag gets a companyTag: true field
                            ...post.userTags,
                            ...post.companyTags.map((company) => ({
                                ...company,
                                companyTag: true,
                            })),
                        ]}
                        handleClick={(e) => {
                            handleAvatarLineupClick();
                        }}
                        maxAvatars={4}
                        icon={<i className="fas fa-user-tag" />}
                        hideAvatarsWithoutPic={false}
                        size={"2rem"}
                        defaultAvatarPic={REACT_APP_DEFAULT_USER_PROFILE}
                    />
                )}
                {!postHasTags &&
                    post.img[0] &&
                    userIsAdmin && ( // Button only shows if post has no tags AND post has images AND user is admin
                        <AddTagWrapper>
                            <Button
                                handleClick={handleAddTag}
                                textSize={"1rem"}
                                textColor={theme.colors.secondary}
                                bgColor={theme.colors.primary}
                                icon={<i className="fas fa-user-plus"></i>}
                                noShadow={true}
                            ></Button>
                        </AddTagWrapper>
                    )}
                {post.img && post.img.length > 1 && (
                    <ImgScrollHelper>{ScrollBoxes}</ImgScrollHelper>
                )}
            </TagsRow>
            {/* POST TEXT */}
            <PostTextWrapper
                onClick={
                    !postFullView && !createPostView
                        ? (e) => {
                            history.push(`/posts/${post._id}`);
                        }
                        : () => { }
                } // link to post full view only fires if not already in full view
                showPointer={postFullView && !createPostView ? false : true}
                postHasImg={post.img[0] ? true : false}
            >
                {!createPostView && ( // Show text
                    <PostText>
                        {truncateText(post.text, textCharacterLimit)}
                        {textCharacterLimit &&
                            post.text.length > textCharacterLimit && (
                                <SeeFullPost
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setTextCharacterLimit(undefined);
                                    }}
                                >
                                    See All
                                </SeeFullPost>
                            )}
                    </PostText>
                )}
                {createPostView && ( // If create post view then show text area input
                    <InputGroup
                        type={INPUT_TYPES.TEXT_AREA}
                        rows={"6"}
                        handleChange={handlePostTextChange}
                        value={post.text}
                        name="text"
                        placeholder={"Write a caption..."}
                    />
                )}
            </PostTextWrapper>
        </PostBody>
    );
};

export default PostBodyView;

const PostBody = styled.div`
    display: flex;
    flex-direction: column;
`;

const SiteRow = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem;
    order: ${(props) =>
        props.postHasImg
            ? 0
            : 2}; /* Site tag moves depending on is post has an image*/
    background-color: ${({ theme, postHasImg }) =>
        postHasImg
            ? theme.colors.secondary
            : theme.colors
                .offSecondary}; /* Site tag uses subtler bg color if no image in post*/
    color: ${({ theme, postHasImg }) =>
        postHasImg ? theme.colors.primary : theme.colors.primary};
`;

// Site pin icon
const PinIcon = styled.i`
    font-size: 120%;
    margin-right: 0.4rem;
`;

const SiteText = styled.p`
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0rem;
`;

const TagsRow = styled.div`
    display: flex;
    align-items: center;
    padding: ${(props) =>
        props.belowText ? "0.5rem 1rem" : "1rem 1rem 0 1rem"};
    order: ${(props) => (props.belowText ? 1 : 0)};
`;

// Wrapper for img scroll boxes
const ImgScrollHelper = styled.div`
    display: flex;
    justify-content: stretch;
    align-items: center;
    flex: 0;
    margin-left: auto;
`;

// Each box in image scroll boxes, color changes depending on image currently viewed in slider
const ImgScrollBox = styled.div`
    height: 1rem;
    width: 1rem;
    margin: 0rem 0.2rem;
    background-color: ${(props) => props.theme.colors.secondary};
    opacity: ${(props) => (props.selected ? 0.9 : 0.2)};
    flex: 1;
    border-radius: 25%;
`;

const PostTextWrapper = styled.div`
    padding: ${(props) =>
        props.postHasImg
            ? "1rem 1.5rem 1.5rem 1.5rem"
            : "0.5rem 1.5rem 1rem 1.5rem"};
    cursor: ${(props) => (props.showPointer ? "pointer" : "auto")}; ;
`;

const PostText = styled.p`
    margin: 0;
    white-space: pre-wrap;
`;

// Button to display all text in post if limited
const SeeFullPost = styled.span`
    font-weight: 600;
    &:hover {
        text-decoration: underline;
    }
`;

const AddTagWrapper = styled.div`
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.offSecondary};
`;
