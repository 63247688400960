import styled from "styled-components";

import InputGroup from "../../library/common/InputGroup";
import { INPUT_TYPES } from "../../library/common/constants";

// Renders input fields for about field of popup form
const EditAboutUsView = ({
    formState,
    handleChange,
    validationFailedState
}) => {
    const { aboutUs } = formState;

    const characterLimit = 600;
    return (
        <Wrapper>
            <InputGroup
                type={INPUT_TYPES.TEXT_AREA}
                rows={"9"}
                characterLimit={characterLimit}
                handleChange={handleChange}
                errorMessage={validationFailedState.aboutUs}
                value={aboutUs}
                name="aboutUs"
                title={"About"}
                description={`Tell people about your company, what work you do, the size of your team, how long you've been in business etc. Paragraph must be less than ${characterLimit} characters.`}
            />
        </Wrapper>
    );
};

export default EditAboutUsView;

const Wrapper = styled.div`
    min-width: min(800px, 95vw);
    padding: 0.7rem 0;
    display: flex;
    flex-direction: column;
    @media (max-width: ${({ theme }) => theme.sizes.mdViewWidth}) {
        /* Must remove min width or content will overflow on smaller screens */
        min-width: 0;
    }
`;
