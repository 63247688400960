import { useCallback, useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { sendApiRequest } from "../../../services/api";
import { addError } from "../../../main/store/actions/errors";
import SearchBoxView from "./SearchBox-view";
import { UseOutsideClick } from "../../utilities";

// NOTE: searchApiRequest prop consists of four elements:
// (1) apiRequestType - string: must be exact match of "get"/"post"/"put"
// (2) apiRequestPath - the route for the search request
// (3) searchField - the field on the model to query the search text against
// (4) imgField (optional) - the field on the model to display as a thumbnail
// (5) validationError - if given then search box has a red border and a red exclamation mark inside it
// (6) apiRequestPath can also contain an uniqueFields array for fields unique to each apiRequestType - for instance givenName in a user search
const SearchBox = ({
    dropdownMaxHeight, // optional - default is 150px
    validationError,
    theme,
    searchDescription,
    searchApiRequest,
    reqBody,
    addResultToParentState,
    valueCanBeCustomString, // optional if true user can select their own custom strings by hitting enter
    updateStringInParent, // optional if true strong will be updated in parent as user types
    clearInputOnSelect,
    noResultsText,
    initialValue,
    borderRadius, // optional
    clearSearchOnOutsideClick, // optional default false
    autoFocus, // optional default false
}) => {
    const [searchInputs, setSearchInputs] = useState({
        string: initialValue ? initialValue : "",
        isActive: false,
    });
    const [searchResults, setSearchResults] = useState([]);
    const [currentResult, setCurrentResult] = useState({
        index: undefined,
        isFinal: false,
    });
    const [isSearching, setIsSearching] = useState(false);
    const { getAccessTokenSilently } = useAuth0();

    const componentRef = useRef({});
    // clear search string on clock outside SearchBox component (that is outside search box and dropdown)
    UseOutsideClick(componentRef, (e) => {
        if (clearSearchOnOutsideClick === true) {
            setSearchInputs({ string: "", isActive: false });
        }
    });

    // defines string constant to act as an id for the list entry "no results found".
    // the code will test against this id when trying to select a list item
    const NO_RESULTS_FOUND = "NO_RESULTS_FOUND";

    // changes the searchInputs whenever the searchBox input field is updated
    const handleChange = (e) => {
        setSearchInputs({ string: e.target.value, isActive: true });
        if (updateStringInParent) {
            updateStringInParent(e.target.value); // update parents state with search string
        }
    };

    // listens for changes to searchInputs state and updates searchResults
    useEffect(() => {
        const timeoutId = setTimeout(async () => {
            // if the searchInputs is not empty and the display toggle is on (from typing in letters),
            // query the database and update searchResults
            // otherwise, clear searchResults (including when display toggle is off from selecting a result)
            // console.log(`${searchInputs}, length: ${searchInputs.length}`);
            try {
                if (searchInputs.string.length > 0 && searchInputs.isActive) {
                    setIsSearching(true);
                    const queryResult = await sendApiRequest(
                        getAccessTokenSilently,
                        {
                            method: searchApiRequest.apiRequestType,
                            url: `${searchApiRequest.apiRequestPath}?search_field=${searchApiRequest.searchField}&search=${searchInputs.string}`,
                            data: reqBody || {},
                        }
                    );
                    setCurrentResult({ index: undefined, isFinal: false });
                    if (queryResult.length > 0) {
                        setSearchResults(
                            queryResult.map((result) => {
                                let resultObj = {};
                                // Fields always returned
                                resultObj.id = result._id;
                                resultObj.name =
                                    result[searchApiRequest.searchField];
                                resultObj.img = !!result[
                                    searchApiRequest.imgField
                                ]
                                    ? result[searchApiRequest.imgField] // first check for image and if it exists use that
                                    : searchApiRequest.defaultImage // If doesn't exist but the search type has a default image use that
                                    ? searchApiRequest.defaultImage
                                    : ""; // if neither return nothing for img field
                                resultObj.cat = !!result[
                                    searchApiRequest.catField
                                ]
                                    ? result[searchApiRequest.catField]
                                    : ""; // if catField is not included, then leave it blank

                                // Fields bespoke to each searchApiRequest
                                if (searchApiRequest.uniqueFields) {
                                    searchApiRequest.uniqueFields.forEach(
                                        (field) => {
                                            resultObj[field] = result[field];
                                        }
                                    );
                                }
                                return resultObj;
                            })
                        );
                    } else {
                        // setCurrentResult({ index: undefined, isFinal: false });
                        setSearchResults(() => {
                            return !!noResultsText
                                ? [
                                      {
                                          name: noResultsText,
                                          id: NO_RESULTS_FOUND,
                                      },
                                  ]
                                : [];
                        });
                    }
                    setIsSearching(false);
                } else {
                    setSearchResults([]);
                }
            } catch (error) {
                addError(error);
                setIsSearching(false);
            }
        }, 200);
        return () => clearTimeout(timeoutId);
    }, [
        searchInputs,
        searchApiRequest,
        reqBody,
        noResultsText,
        getAccessTokenSilently,
    ]);

    // clears dropdown menu and searchbox
    const clearSearch = useCallback(
        (selectedResultName) => {
            if (clearInputOnSelect) {
                // Empty search box on select
                setSearchInputs({ string: "", isActive: false });
            } else {
                // Set searchBox to selected result
                setSearchInputs({
                    string: selectedResultName,
                    isActive: false,
                });
            }
            // Clear search result and reset current result in both cases
            setSearchResults([]);
            setCurrentResult({ index: undefined, isFinal: false });
        },
        [clearInputOnSelect]
    );

    // On enter of clicking add button adds searchInputs.string to parent and clears search
    const addCustomStringToParent = useCallback(() => {
        addResultToParentState({ name: searchInputs.string });
        clearSearch(searchInputs.string);
    }, [addResultToParentState, clearSearch, searchInputs.string]);

    // sets the parent state when the current result is "locked in" (by pressing enter or clicking with mouse)
    // first, it checks to see if there are search results, if they are valid results, and if it is final, then:
    // (1) process parent state changes
    // (2) clear dropdown menu and searchbox
    useEffect(() => {
        if (
            // searchResults.length > 0 &&
            currentResult.index !== undefined &&
            currentResult.isFinal
        ) {
            addResultToParentState(
                searchResults[currentResult.index],
                searchApiRequest // optional not all addResultToParentState functions need to use this variable
            );
            clearSearch(searchResults[currentResult.index].name);
        }
    }, [
        addResultToParentState,
        searchResults,
        currentResult,
        clearSearch,
        searchApiRequest,
    ]);

    // catchall keydown listener:
    const keydownListener = (e) => {
        if (!!componentRef.current.contains(e.target)) {
            // First check searchBox is active, stop unintended firing
            switch (e.key) {
                // pressing enter key selects the currently highlighted result (if one exists) or adds string directy to state if user is allowed to do so.
                case "Enter":
                    if (
                        valueCanBeCustomString === true &&
                        currentResult.index === undefined
                    ) {
                        // if enter with no search result selected on search boxes where user allowed a custom string then that string will be added to parent state
                        addCustomStringToParent();
                    } else if (
                        searchResults.length > 0 &&
                        searchResults[currentResult.index] &&
                        searchResults[currentResult.index].id !==
                            NO_RESULTS_FOUND
                    ) {
                        setCurrentResult({
                            ...currentResult,
                            isFinal: true,
                        });
                    }
                    break;
                // pressing escape clears the search result
                case "Escape":
                    clearSearch();
                    break;
                // using up and down arrow keys while dropdown is open will scroll through dropdown
                case "ArrowDown":
                    e.preventDefault();
                    if (currentResult.index === undefined) {
                        // if no current result index then set to 0 on first down arrow click
                        setCurrentResult({
                            ...currentResult,
                            index: 0,
                        });
                    } else if (currentResult.index < searchResults.length - 1) {
                        // If there is still a result after current then add one to current result index
                        setCurrentResult({
                            ...currentResult,
                            index: currentResult.index + 1,
                        });
                    }
                    break;
                case "ArrowUp":
                    e.preventDefault();
                    if (currentResult.index === 0) {
                        // if index is zero and arrow up set to undefined
                        setCurrentResult({
                            ...currentResult,
                            index: undefined,
                        });
                    } else if (currentResult.index > 1) {
                        // up one result
                        setCurrentResult({
                            ...currentResult,
                            index: currentResult.index - 1,
                        });
                    }
                    break;
                default:
                    break;
            }
        }
    };

    // onMouseEnter trigger - to be applied to each result
    const onMouseEnter = (currentIndex) => {
        setCurrentResult({
            ...currentResult,
            index: currentIndex,
        });
    };

    // onMouseLeave trigger - reset currentResult
    const onMouseLeave = () => {
        setCurrentResult({ index: 0, isFinal: false });
    };

    // onClick - if dropdown item has been clicked
    const onClick = (currentIndex) => {
        if (searchResults[currentIndex].id !== NO_RESULTS_FOUND) {
            setCurrentResult({ index: currentIndex, isFinal: true });
        }
    };

    // if the dropdown exists and a dropdown item is clicked, select it
    // otherwise, clear dropdown without clearing the searchbox
    const dropdownRef = useRef(null);
    const clickListener = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setSearchResults([]);
        }
    };

    // add listeners
    useEffect(() => {
        document.addEventListener("keydown", keydownListener, true);
        document.addEventListener("click", clickListener, true);
        return () => {
            document.removeEventListener("keydown", keydownListener, true);
            document.removeEventListener("click", clickListener, true);
        };
    });

    return (
        <SearchBoxView
            theme={theme}
            dropdownMaxHeight={dropdownMaxHeight}
            validationError={validationError}
            searchDescription={searchDescription}
            searchString={searchInputs.string}
            handleChange={handleChange}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            searchResults={searchResults}
            currentResult={currentResult}
            isSearching={isSearching}
            dropdownRef={dropdownRef}
            borderRadius={borderRadius}
            componentRef={componentRef}
            addCustomStringToParent={
                valueCanBeCustomString ? addCustomStringToParent : undefined // only passed in if action is allowed
            }
            autoFocus={autoFocus}
        />
    );
};

export default SearchBox;
