import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import CompanyList from "./CompanyList-container";
import { addError, removeError } from "../../../main/store/actions/errors";

function mapState(state) {
    return {
        currentUser: state.currentUser,
        errors: state.errors,
    };
}

const mapDispatch = {
    addError,
    removeError,
};

export default withRouter(connect(mapState, mapDispatch)(CompanyList));