import styled from "styled-components";

import NotificationItem from "./NotificationItem";
import LoadingDots from "../../library/common/LoadingDots";
import ErrorPopup from "../../library/common/popups/ErrorPopup";

const NotificationsView = ({
    handleClick,
    listOnly,
    notifications = [],
    notificationsLoading,
    ntfError,
    setNtfError,
}) => {

    const loadingDots = (
        <CenterContentBox>
            <LoadingDots size={"20px"} />
        </CenterContentBox>
    );

    const noNotifications = (
        <CenterContentBox>
            <NoNotificationsMessage>No notifications yet.</NoNotificationsMessage>
        </CenterContentBox>
    );

    const unseenNotifications = notifications
        .filter((el) => !el.hasBeenSeen)
        .map((el, i) => (
            <NotificationItem
                key={i}
                notification={el}
                handleClick={handleClick}
            />
        ));

    const seenNotifications = notifications
        .filter((el) => el.hasBeenSeen)
        .map((el, i) => (
            <NotificationItem
                key={i}
                notification={el}
                handleClick={handleClick}
            />
        ));

    const unseenNotificationsHeading = unseenNotifications.length > 0 ?
        <NewNotificationsHeading>New</NewNotificationsHeading> : null;

    const seenNotificationsHeading = seenNotifications.length > 0 ?
        <SeenNotificationsHeading>Seen</SeenNotificationsHeading> : null;

    const errorMessage = ntfError ?
        <ErrorPopup
            removeError={() => setNtfError("")}
            customExitHandling={() => setNtfError("")}
        /> : null;

    const notificationsList = notificationsLoading ? loadingDots :
        notifications.length === 0 ? noNotifications : (
            <Wrapper>
                {unseenNotificationsHeading}
                {unseenNotifications}
                {seenNotificationsHeading}
                {seenNotifications}
                {errorMessage}
            </Wrapper>
        );


    return listOnly ?
        notificationsList :
        <FullScreenWrapper>
            <NotificationsCol>
                <Heading>Notifications</Heading>
                <ListWrapper>{notificationsList}</ListWrapper>
            </NotificationsCol>
        </FullScreenWrapper>;
};

export default NotificationsView;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
    z-index: 0;
`;

const NewNotificationsHeading = styled.h4`
    padding-bottom: 0.1rem;
    margin-top: 0;
    margin-bottom: 0;
    position: relative; /* ensures error is overtop */
    z-index: 0;
`;

const SeenNotificationsHeading = styled(NewNotificationsHeading)`
    margin-top: 1rem;
`;

const CenterContentBox = styled.div`
    display: flex;
    justify-content: center;
    padding: 3rem 0;
`;

const NoNotificationsMessage = styled.h5`
    color: ${(props) => props.theme.colors.justOffSecondary};
`;

const FullScreenWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 2rem 1rem;
`;

const Heading = styled.h3`
    margin: 0;
    padding: 0 0 1rem 0;
`;

const ListWrapper = styled.div`
    padding: 0 0.5rem;
`;

const NotificationsCol = styled.div`
    flex: 0 1 700px;
    border-radius: 1rem;
    padding: 1rem;
    background-color: ${(props) => props.theme.colors.primary};
`;