import styled from "styled-components";

import WorkExperienceItems from "../WorkExperienceItems-view";
import { SECTION_TITLES } from "../../../../library/common/constants";
import FooterBar from "../../../../library/common/PopupFooterBar";
import HeaderBar from "../../../../library/common/PopupHeaderBar";
import { ContentBox } from "../../../../resources/styles";

// A list of a users work experiences with edit buttons for each experience
const ExperienceListView = ({
    workExperience,
    handleEditClick,
    handleAddClick,
    setPopUpToDisplay,
    handleExperienceDelete,
}) => {
    return (
        <Wrapper>
            <HeaderBar
                setPopUpToDisplay={setPopUpToDisplay}
                popupTitle={SECTION_TITLES.workExperiences}
                handleAdd={handleAddClick}
            />
            <ContentBox>
                <ContentWrapper>
                    {workExperience && workExperience[0] && (
                        <WorkExperienceItems
                            items={workExperience}
                            handleEditClick={handleEditClick}
                            handleDelete={handleExperienceDelete}
                        />
                    )}
                </ContentWrapper>
            </ContentBox>
            <FooterBar handleDone={setPopUpToDisplay} doneLabel={"Done"} />
        </Wrapper>
    );
};

export default ExperienceListView;

export const Wrapper = styled.div`
    width: min(800px, 95vw);
    min-height: 250px;
`;

const ContentWrapper = styled.div`
    padding: 1.5rem;
    @media (max-width: ${({ theme }) => theme.sizes.mdViewWidth}) {
        padding: 0.8rem;
    }
`;
