import styled, { withTheme } from "styled-components";

import BtnBar from "./BtnBar-view";

import Alert from "../../library/common/Alert";
import InputMap from "../../library/common/InputMap";
import { ALERT_TYPES } from "../../library/common/constants";

const StepOne = ({
    theme,
    formState,
    mapUpdateLocation,
    handleNext,
    validationFailedState,
    initialMapPosition,
    handleCancel,
}) => {
    const { latLng } = formState.locationsArr[0];
    const screenBreakSize = theme.sizes.mdViewWidth; // Map moves form side to above sidebar

    return (
        <Wrapper screenBreakSize={screenBreakSize}>
            <SideBar screenBreakSize={screenBreakSize}>
                <SideBarContent screenBreakSize={screenBreakSize}>
                    <PageTitle>Create Site</PageTitle>
                    <p>Click position on map to move site location.</p>
                </SideBarContent>
                <div>
                    {validationFailedState.latLng && (
                        <LgScreenValidError screenBreakSize={screenBreakSize}>
                            <Alert
                                message={validationFailedState.latLng}
                                type={ALERT_TYPES.ERROR}
                                hideBorder={true}
                                noBorderRadius={true}
                            />
                        </LgScreenValidError>
                    )}
                    <BtnBar
                        handleCancel={handleCancel}
                        handleNext={handleNext}
                        nextText={"Next"}
                    />
                </div>
            </SideBar>
            <MainContent screenBreakSize={screenBreakSize}>
                {validationFailedState.latLng && (
                    <SmScreenValidError screenBreakSize={screenBreakSize}>
                        <Alert
                            message={validationFailedState.latLng}
                            type={ALERT_TYPES.ERROR}
                            hideBorder={true}
                        />
                    </SmScreenValidError>
                )}
                <InputMap
                    initialMapPosition={initialMapPosition}
                    initialMapZoom={11}
                    latLng={latLng}
                    mapUpdateLocation={mapUpdateLocation}
                />
            </MainContent>
        </Wrapper>
    );
};

export default withTheme(StepOne);

const Wrapper = styled.div`
    display: flex;
    height: calc(100vh - ${(props) => props.theme.sizes.navHeight});
    @media (max-width: ${({ screenBreakSize }) => screenBreakSize}) {
        flex-direction: column-reverse;
    }
`;

const SideBar = styled.div`
    flex: 0 0.2 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: ${({ screenBreakSize }) => screenBreakSize}) {
        flex: 0;
    }
`;

const MainContent = styled.div`
    position: relative;
    flex: 1 2 1000px;
    @media (max-width: ${({ screenBreakSize }) => screenBreakSize}) {
        flex: 1;
    }
`;

const SideBarContent = styled.div`
    padding: 15px;
    @media (max-width: ${({ screenBreakSize }) => screenBreakSize}) {
        padding: 5px;
    }
`;

const LgScreenValidError = styled.div`
    /* padding: 0.5rem; */
    display: block;
    @media (max-width: ${({ screenBreakSize }) => screenBreakSize}) {
        display: none;
    }
`;

const SmScreenValidError = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 1rem 4rem 2rem 0.5rem;
    z-index: 1;
    display: none;
    @media (max-width: ${({ screenBreakSize }) => screenBreakSize}) {
        display: block;
    }
`;

const PageTitle = styled.h3``;
