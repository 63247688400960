import { useRef, useState } from "react";
import EditAccountsView from "./EditAccountsScreen-view";

import {
    USER_SEARCH,
    COMPANY_SEARCH,
    CONNECTION_STATUS,
    USER_STATUS,
} from "../../constants";
import { UseOutsideClick } from "../../../utilities";

const viewType = {
    DEFAULT: "DEFAULT",
    ADD: "ADD",
    EDIT: "EDIT",
};

const EditAccounts = ({
    history,
    currentUser,
    users = [],
    companies = [],
    add: lockedToAddView = false, // optional, if true isAddView rendered from mount
    userIsAdmin = false, // optional, If true user can add, edit, delete accounts
    membersCanAdd,
    statusOptions = {},
    requestActions = {}, // optional, use if want action requests buttons for admin use
    handleDone, // Done button in footer
    doneText = "Done", // optional
    handleSubmit, // submit button in footer
    submitText = "Save", // optional
    leaveText, // text for leave button
    leaveIcon, // icon for leave btn
    showStatus, // optional. Status icon shown next to account
    hideNewStatus, // optional, if true then no "New" account status in isAddView
    showAllUsers, // optional if true all accounts shown (as opposed to only acceptedAccountStatus accounts)

    // pass-through props
    popupLoading, // loading state passed in from parent
    popupTitle, // Title of popup
    customExitHandling, // required X button top right handling
    handleCancel, // Cancel button in footer
    addBtnOptions,
    listTitle, // optional title for list of accounts (recommended if !!popoverShowsEditBtn)
}) => {

    const userIsMember = users.some(user => user.id === currentUser.user.id);

    const canSwitchToAddView = (userIsAdmin || (membersCanAdd && userIsMember)) && !lockedToAddView;
    const popoverShowsEditBtn = userIsAdmin && !lockedToAddView;

    // *** Manually turn off companiesIncluded functionality
    // (companies can't be tagged, can be a member of a site and can't like a post for now)
    const companiesIncluded = false;

    // Used in initial setState - adds a timeAdded field to allow any accounts added subsequently to be sorted to top
    const addTimeStampsToArr = (arr) => arr.map((el) => ({ ...el, timeAdded: Date.now() }));

    // Account states
    const [usersWithTime, setUsersWithTime] = useState(addTimeStampsToArr(users));
    const [companiesWithTime, setCompaniesWithTime] = useState(addTimeStampsToArr(companies));

    // if no users or companies comes in and users has permission to add then go straight to isAddView
    const mountInAddView = lockedToAddView || (
        users.length === 0 && companies.length === 0 && canSwitchToAddView
    );

    // // Add and edit view state used to render variations on the popup
    const [view, setView] = useState(mountInAddView ? viewType.ADD : viewType.DEFAULT);

    // Keeps track of whether an items has been removed
    const [itemWasRemoved, setItemWasRemoved] = useState(false);
    // When true a grey background is used to focus user on search box.
    // ALSO avatar clicks outside search box are disabled
    const [searchBoxFocus, setSearchBoxFocus] = useState(mountInAddView);

    // Delete account from relevant array
    const deleteAccount = (delete_id, deleteCompany) => {
        if (deleteCompany) {
            setCompaniesWithTime(companiesWithTime.filter((el) => el._id !== delete_id));
        } else {
            setUsersWithTime(usersWithTime.filter((el) => el._id !== delete_id));
        }
        setItemWasRemoved(true);
    };

    const {
        // optional, used to hide accounts without acceptedAccountStatus (userCanAdd users can see all)
        acceptedAccountStatus: acceptedConxStatus,
        // optional define if accounts can be removed e.g. "INACTIVE" users
        removedAccountStatus = USER_STATUS.INACTIVE,
        // Accounts awaiting approval
        pendingConxStatus = CONNECTION_STATUS.PENDING,
        // Status of current users if they have requested to join
        // requestedStatus = CONNECTION_STATUS.REQUESTED,
    } = statusOptions;

    // Handles select from searchBox dropdown
    // Adds account with time stamp field to relevant array (users or companies)
    const handleSelect = (result, searchType) => {

        const { id: resultId, img, name, givenName } = result;

        if (searchType.searchTitle === USER_SEARCH.searchTitle) {
            setUsersWithTime(f => [
                {
                    _id: resultId,
                    username: name,
                    givenName,
                    img,
                    timeAdded: Date.now(),
                    new: true,
                },
                ...f.filter((el) => el._id !== resultId)
            ]);
        } else {
            setCompaniesWithTime(f => [
                {
                    _id: resultId,
                    name,
                    img,
                    timeAdded: Date.now(),
                    new: true,
                },
                ...f.filter((el) => el._id !== resultId)
            ]);
        }

        setSearchBoxFocus(false); // remove focus after account selected
    };

    const handleClickOutsideSearchBox = (e) => {
        // Remove grey box on click event outside searchBox
        setSearchBoxFocus(false);
    };

    const handleClickInsideSearchBox = (e) => {
        e.stopPropagation(); // stops propagation to ContentWrapper
        // Click in search box sets searchBoxFocus true
        setSearchBoxFocus(true);
    };

    const excludedResults = companiesIncluded ?
        {
            [USER_SEARCH.searchTitle]: usersWithTime
                .filter(el => el.status !== removedAccountStatus),
            [COMPANY_SEARCH.searchTitle]: companiesWithTime
                .filter(el => el.status !== removedAccountStatus),
        } :
        usersWithTime.filter(el => el.status !== removedAccountStatus);


    //==================== Account List Props ====================//

    const getAccountProps = (accountData, isCompany = false) => {

        const {
            _id: accountId,
            username,
            name,
            img: accountImg,
            status: conxStatus,
            new: isNew,
        } = accountData;

        const accountName = isCompany ? name : username;

        const isPending = conxStatus === pendingConxStatus;
        const isAccepted = conxStatus === acceptedConxStatus;

        const userCanDelete = (
            ( // either admin status
                view === viewType.EDIT &&
                popoverShowsEditBtn &&
                isAccepted &&
                accountId !== currentUser.user.id
            ) || ( // or starting completely new array
                view === viewType.ADD && isNew
            )
        );

        const statusIconVisible = showStatus && view === viewType.DEFAULT;
        const newTagVisible = !hideNewStatus && view === viewType.ADD && isNew;

        const { handleAccept, handleReject } = requestActions;
        const requestActionsVisible = userIsAdmin &&
            (view === viewType.DEFAULT || view === viewType.EDIT) &&
            isPending && handleAccept && handleReject;

        const handleAccountClick = (e) => {
            e.stopPropagation();
            const accountType = isCompany ? "company" : "user";
            history.push(`/${accountType}/${accountId}`);
        };

        const handleRemoveClick = (e) => {
            e.stopPropagation();
            deleteAccount(accountId, isCompany);
        };

        return {
            accountName,
            accountImg,
            isCompany,
            isPending,
            isAccepted,
            userCanDelete,
            statusIconVisible,
            newTagVisible,
            requestActionsVisible,
            handleAccept: () => handleAccept(accountId),
            handleReject: () => handleReject(accountId),
            handleAccountClick,
            handleRemoveClick,
        };

    };

    const allAccountsVisible = userIsAdmin || showAllUsers;
    const accounts = usersWithTime
        .concat(companiesWithTime)
        .sort((a, b) =>
            b.status === pendingConxStatus - a.status === pendingConxStatus || // pending first
            b.timeAdded - a.timeAdded // then time added
        ).filter(el =>
            !(el.status === removedAccountStatus) &&
            (allAccountsVisible || el.status === acceptedConxStatus)
        ).map(el => getAccountProps(el));

    //==================== Options Button Props ====================//

    const [popoverShowing, setPopoverShowing] = useState(false);

    const popoverRef = useRef();
    UseOutsideClick(popoverRef, () => { setPopoverShowing(false); });
    const handleOptionsButtonClick = () => { setPopoverShowing(true); };

    const handleEdit = () => { setView(viewType.EDIT); };

    const handleLeave = () => {
        setUsersWithTime(usersWithTime.filter((el) => el._id !== currentUser.user.id));
        setItemWasRemoved(true);
        setPopoverShowing(false);
    };

    const optionsButtonProps = {
        popoverRef,
        popoverShowing,
        popoverShowsEditBtn,
        leaveText,
        leaveIcon,
        handleEdit,
        handleLeave,
        handleOptionsButtonClick,
    };

    //==================== Footer Bar Props ====================//

    const handleAddClick = () => {
        setSearchBoxFocus(true);
        // setIsAddView(true);
        setView(viewType.ADD);
    };

    // Variable changes footer button to submit button if in isAddView OR an items has been deleted
    const showSubmitFooter = itemWasRemoved || view === viewType.ADD;
    const handleConfirmClick = showSubmitFooter ?
        () => { handleSubmit(usersWithTime, companiesWithTime); }
        : handleDone;
    const confirmText = showSubmitFooter ? submitText : doneText;

    //==================== View Props ====================//

    const viewProps = {
        // popup props
        popupLoading,
        popupTitle,
        customExitHandling,
        handleCancel,
        // searchBox props
        companiesIncluded,
        searchBoxFocus,
        handleClickOutsideSearchBox,
        handleClickInsideSearchBox,
        handleSelect,
        excludedResults,
        // list, title, and options button props
        accounts,
        isAddView: view === viewType.ADD,
        listTitle,
        optionsButtonProps,
        optionsButtonVisible: (userIsAdmin || userIsMember) && !lockedToAddView,
        // footer button props
        addBtnOptions,
        addButtonVisible: !itemWasRemoved && canSwitchToAddView && view !== viewType.ADD,
        confirmText,
        handleConfirmClick,
        handleAddClick,
    };

    return <EditAccountsView {...viewProps} />;
};

export default EditAccounts;