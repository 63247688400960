import { connect } from "react-redux";

import StoreLoader from "./StoreLoader";
import { setMySites } from "../../main/store/actions/mySites";

const mapState = (state) => {
    return {
        currentUser: state.currentUser,
    };
};

const mapDispatch = {
    setMySites,
};

export default connect(mapState, mapDispatch)(StoreLoader);
