import styled from "styled-components";

import InputMap from "../../InputMap";
import AutocompleteAddressInput from "../../Autocomplete";
import { ContentBox } from "../../../../resources/styles/index";
import HeaderBar from "../../PopupHeaderBar";
import FooterBar from "../../PopupFooterBar";
import { Wrapper } from "./AddressList-view";

const AddressEditView = ({
    popupTitle,
    setLocationIndex,
    address,
    latLng,
    mapUpdateLocation,
    autofillUpdateLocation,
    initialMapPosition,
    setPopUpToDisplay,
    handleSubmit,
    submitFailed,
}) => {
    return (
        <Wrapper>
            <HeaderBar
                setPopUpToDisplay={setPopUpToDisplay}
                popupTitle={popupTitle}
                handleBack={() => {
                    setLocationIndex(undefined);
                }}
            />
            <ContentBox>
                <ContentWrapper>
                    <AddressWrapper>
                        <AutocompleteAddressInput
                            options={{
                                types: ["address"],
                                componentRestrictions: { country: "nz" },
                            }}
                            address={address}
                            autofillUpdateLocation={autofillUpdateLocation}
                            addressFailedValidation={
                                submitFailed
                                    ? "Please select from map or search dropdown addresses"
                                    : undefined
                            }
                        />
                    </AddressWrapper>
                    <InputMap
                        initialMapPosition={initialMapPosition}
                        initialMapZoom={13}
                        latLng={latLng}
                        mapUpdateLocation={mapUpdateLocation}
                        idIndex="Two"
                    />
                </ContentWrapper>
            </ContentBox>
            <FooterBar
                handleDone={handleSubmit}
                handleCancel={() => {
                    setLocationIndex(undefined);
                }}
                cancelLabel={"Cancel"}
                doneLabel={"Save"}
            />
        </Wrapper>
    );
};

export default AddressEditView;

const ContentWrapper = styled.div`
    padding: 0.5rem 1rem 1rem 1rem;
    @media (max-width: ${({ theme }) => theme.sizes.mdViewWidth}) {
        padding: 1rem 1rem 1rem 1rem;
    }
`;

const AddressWrapper = styled.div`
    margin-bottom: 1rem;
`;
