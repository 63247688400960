import styled, { withTheme } from "styled-components";

import Button from "../../../library/common/Button";

import { FooterBar } from "../../../resources/styles";

// Footer bar used on image upload and text input popup screens, some styles are exported for use in PostCreateView
const PostCreateFooterBarView = ({
    handleBack,
    handleNext,
    nextText,
    theme,
}) => {
    return (
        <FooterBar>
            <FooterBtnBlock>
                <LeftFooterBtnWrapper>
                    {handleBack && (
                        <Button
                            handleClick={handleBack}
                            textSize={"1rem"}
                            secondaryBtnColors={true}
                        >
                            Back
                        </Button>
                    )}
                </LeftFooterBtnWrapper>
                <RightFooterBtnWrapper>
                    <Button
                        handleClick={handleNext}
                        textSize={"1rem"}
                        bgColor={theme.colors.secondary}
                        textColor={theme.colors.primary}
                    >
                        {nextText}
                    </Button>
                </RightFooterBtnWrapper>
            </FooterBtnBlock>
        </FooterBar>
    );
};

export default withTheme(PostCreateFooterBarView);

const FooterBtnBlock = styled.div`
    display: flex;
    width: 100%; /* Match width of parent  */
    padding: 0 1rem;
    justify-content: space-between;
`;

export const LeftFooterBtnWrapper = styled.div`
    margin-right: 1rem;
    flex: 1 1 100px;
`;

export const RightFooterBtnWrapper = styled.div`
    flex: 1 1 250px;
`;
