import styled, { css } from "styled-components";
import { lighten, darken } from "polished";
import { useState, useRef } from "react";

import { UseOutsideClick } from "../../utilities";
import SearchBox from "../SearchBox";
import { INPUT_TYPES } from "../constants";

const InputGroupText = ({
    theme,
    type,
    rows,
    handleChange,
    characterLimit, // optional
    errorMessage,
    name,
    value,
    placeholder,
    required,
    title,
    small, // optional small text below
    description, // optional description popover
    id, // optional id for component
    searchBoxProps,
    dateInputProps,
    checkboxInputProps,
    children,
    autocomplete,
    borderRadius, // optional valid for TextArea and regular text input
    bgColor, // optional text input bgColor
    noAdjustTextAreaWithScreenSize, // only for text areas, if false text are does not change height with screen size
    autoFocus, // optional default false
    zIndex, // optional z-index of wrapper - default is 0
    inputBoxPadding, // optional padding around text in input - has default
}) => {
    // useOutsideClick logic used for popover, user clicks outside popover its closed
    const ref = useRef();
    UseOutsideClick(ref, () => {
        setShowDescription(false);
    });

    // If true a description popover box is shown pointing to input title
    const [showDescription, setShowDescription] = useState(false);

    // State used to store length of string
    const [charactersLength, setCharacterLength] = useState(
        String(value).length
    );

    // State used to show characters remaining.
    const [showCharactersLeft, setShowCharactersLeft] = useState(false);

    // Handle click inside input box
    // Show characters remaining if characterLimit prop present
    const handleClick = () => {
        if (characterLimit) {
            setShowCharactersLeft(true);
        }
    };

    // Run handleChange function if length is less than the limit or if no limit
    const handleChangeWithLimit = (e) => {
        const newLength = String(e.target.value).length;
        if (!characterLimit || newLength <= characterLimit) {
            setCharacterLength(newLength);
            handleChange(e);
        }
    };

    return (
        <InputGroup zIndex={zIndex}>
            <TitleRow>
                <Title>{title}</Title>
                {description && ( // if there is a description show circle "?" button next to title
                    <QuestionMarkBtn
                        onClick={() => setShowDescription(true)}
                        className="fas fa-question-circle"
                    ></QuestionMarkBtn>
                )}
                <RequiredTag required={required}>(Required)</RequiredTag>
            </TitleRow>
            {showDescription && (
                <DescriptionPopover ref={ref}>
                    <TriangleUp />
                    <DescriptionBox>
                        {description}
                        <ExitButton onClick={() => setShowDescription(false)}>
                            <i className="fas fa-times"></i>
                        </ExitButton>
                    </DescriptionBox>
                </DescriptionPopover>
            )}
            <InputRow>
                {type === INPUT_TYPES.TEXT_AREA && (
                    <TextArea
                        name={name}
                        onChange={handleChangeWithLimit}
                        onClick={handleClick}
                        value={value}
                        errorMessage={errorMessage}
                        rows={rows}
                        id={id}
                        placeholder={placeholder}
                        borderRadius={borderRadius}
                        bgColor={bgColor}
                        noAdjustTextAreaWithScreenSize={
                            noAdjustTextAreaWithScreenSize
                        }
                        inputBoxPadding={inputBoxPadding}
                    />
                )}
                {type === INPUT_TYPES.TEXT && (
                    <DefaultInput
                        onChange={handleChangeWithLimit}
                        onClick={handleClick}
                        name={name}
                        value={value}
                        type="text"
                        errorMessage={errorMessage}
                        placeholder={placeholder}
                        id={id}
                        autoComplete={autocomplete ? "on" : "off"} // default off
                        borderRadius={borderRadius}
                        bgColor={bgColor}
                        autoFocus={autoFocus}
                        inputBoxPadding={inputBoxPadding}
                    />
                )}
                {type === INPUT_TYPES.SELECT && (
                    <Select
                        name={name}
                        onChange={handleChange}
                        value={value}
                        type="text"
                        errorMessage={errorMessage}
                        id={id}
                        inputBoxPadding={inputBoxPadding}
                    >
                        {children}
                    </Select>
                )}
                {type === INPUT_TYPES.CHECKBOX && (
                    <div>
                        <CheckBox
                            onChange={handleChange}
                            type="checkbox"
                            checked={value}
                            id={id}
                        />
                        <CheckLabel>{checkboxInputProps.label}</CheckLabel>
                    </div>
                )}
                {type === INPUT_TYPES.SEARCH_TEXT && (
                    <SearchBox
                        addResultToParentState={handleChange}
                        updateStringInParent={
                            searchBoxProps.updateStringInParent
                        }
                        searchApiRequest={searchBoxProps.searchApiRequest}
                        clearInputOnSelect={
                            searchBoxProps.clearOnSelect
                                ? searchBoxProps.clearOnSelect
                                : false
                        }
                        noResultsText={
                            searchBoxProps.noResultsText
                                ? searchBoxProps.noResultsText
                                : ""
                        }
                        initialValue={value}
                        reqBody={{
                            excludedResults:
                                searchBoxProps.excludedFromSearchBox,
                        }}
                        searchDescription={placeholder}
                        validationError={errorMessage}
                        valueCanBeCustomString={
                            searchBoxProps.valueCanBeCustomString
                        }
                        clearSearchOnOutsideClick={
                            searchBoxProps.clearSearchOnOutsideClick
                        }
                        autoFocus={autoFocus}
                    />
                )}
                {type === INPUT_TYPES.DATE && (
                    <DefaultInput
                        errorMessage={errorMessage}
                        type="date"
                        name={name}
                        onChange={handleChange}
                        value={value}
                        max={dateInputProps ? dateInputProps.max : undefined}
                        min={dateInputProps ? dateInputProps.min : undefined}
                        inputBoxPadding={inputBoxPadding}
                    ></DefaultInput>
                )}
                <ErrorIcon errorMessage={errorMessage}>
                    <i className="fas fa-exclamation-circle"></i>
                </ErrorIcon>
            </InputRow>

            <ErrorMessage>{errorMessage}</ErrorMessage>
            {showCharactersLeft && (
                <CharactersRemaining
                    limitReached={
                        characterLimit - charactersLength === 0 ? true : false
                    }
                >
                    {characterLimit - charactersLength} Characters Remaining.
                </CharactersRemaining>
            )}
            <InfoSmall>{small}</InfoSmall>
        </InputGroup>
    );
};

export default InputGroupText;

export const InputGroup = styled.div`
    position: relative;
    /* Turned z-index off as it was causing issues with popover box if their was an input component below (box wasn't above), delete later if no issues */
    z-index: ${(props) => props.zIndex || 0};
    width: 100%;
    font-size: 1rem;
    color: ${(props) => props.theme.colors.secondary};
`;

const InputRow = styled.div`
    position: relative;
    width: 100%;
    display: flex;
`;

const shared = css`
    width: 100%;
    min-height: 100%;
    padding: ${(props) =>
        props.inputBoxPadding || "0.375rem 1.5rem 0.375rem 0.7rem"};
    border-radius: 0.3rem;
    border: 1px solid
        ${({ errorMessage, theme }) =>
        errorMessage
            ? darken(0.4, theme.colors.semantic.error)
            : theme.colors.offPrimary};
    background-color: ${({ theme }) => theme.colors.primary};
    &:focus {
        box-shadow: 0 0 0 0.1rem ${({ theme }) => `${theme.colors.accent}50`};
    }
`;

// Input type text
export const DefaultInput = styled.input`
    ${shared}
    /* optional borderRadius prop */
    border-radius: ${(props) =>
        props.borderRadius ? props.borderRadius : "0.3rem"};
    /* Optional background color */
    background-color: ${(props) =>
        props.bgColor ? props.bgColor : props.theme.primary};
`;

// Text area
const TextArea = styled.textarea`
    ${shared}
    resize: none; /* remove text area resize button at bottom left of text area */
    /* optional borderRadius prop */
    border-radius: ${(props) =>
        props.borderRadius ? props.borderRadius : "0.3rem"};
    /* Optional background color */
    background-color: ${(props) =>
        props.bgColor ? props.bgColor : props.theme.primary};
    @media (max-width: ${({ theme }) => theme.sizes.mdViewWidth}) {
        /* Account for smaller width by expanding height of textarea, note if noAdjustTextAreaWithScreenSize then height is not adjusted */
        min-height: ${({ rows, noAdjustTextAreaWithScreenSize }) =>
        noAdjustTextAreaWithScreenSize ? `rows` : `calc(${rows}*40px)}`};
    }
    @media (max-width: ${({ theme }) => theme.sizes.smViewWidth}) {
        min-height: ${({ rows, noAdjustTextAreaWithScreenSize }) =>
        noAdjustTextAreaWithScreenSize ? `rows` : `calc(${rows}*50px)}`};
    }
`;

export const Select = styled.select`
    ${shared}
    font-size: 1rem;
`;

// ! in circle appears on right hand side of input box if front end validation error occurs
export const ErrorIcon = styled.div`
    position: absolute;
    z-index: 1;
    right: 0.2rem;
    top: 0;
    display: ${(props) => (props.errorMessage ? "inline" : "none")};
    color: ${({ theme }) => darken(0.4, theme.colors.semantic.error)};
`;

const TitleRow = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
`;

const Title = styled.div`
    /* font-size: 110%; */
`;

const RequiredTag = styled.div`
    color: ${(props) => lighten(0.2, props.theme.colors.secondary)};
    display: ${(props) => (props.required ? "inline" : "none")};
    font-size: 80%;
    margin-left: 0.5rem;
`;

const CharactersRemaining = styled.div`
    font-size: 80%;
    color: ${(props) =>
        props.limitReached
            ? props.theme.colors.semantic.error
            : lighten(0.1, props.theme.colors.secondary)};
`;

// Optional small text below input
const InfoSmall = styled.div`
    font-size: 80%;
    color: ${(props) => lighten(0.1, props.theme.colors.secondary)};
`;

// Error message below input
const ErrorMessage = styled.div`
    color: ${({ theme }) => darken(0.4, theme.colors.semantic.error)};
    font-size: 80%;
`;

// Question mark button for displaying description popover
const QuestionMarkBtn = styled.i`
    margin-left: 0.5rem;
    font-size: 110%;
`;

// A positioning component that contains up triangle and description box
const DescriptionPopover = styled.div`
    position: absolute;
    z-index: 1;
    margin: -0.2rem 0rem;
    max-width: ${(props) => (props.width ? props.width : "100%")};
`;

// Arrow pointing to title
const TriangleUp = styled.div`
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid ${(props) => props.theme.colors.secondary};
    position: absolute;
    z-index: 0;
    left: 0.5rem;
`;

// Box containing description
const DescriptionBox = styled.div`
    position: relative;
    background-color: ${(props) => props.theme.colors.secondary};
    color: ${(props) => props.theme.colors.primary};
    border-radius: 5px;
    padding: 1rem 3rem 1rem 1rem;
    margin-top: 0.5rem;
    max-width: 600px;
`;

// X button to exit description popover
const ExitButton = styled.div`
    position: absolute;
    z-index: 1;
    font-size: 1.4rem;
    right: 0.6rem;
    top: 0rem;
`;

const CheckBox = styled.input``;

const CheckLabel = styled.label`
    font-size: 115%;
    margin-left: 0.5rem;
`;
