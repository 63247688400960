import moment from "moment";
import styled from "styled-components";

import Alert from "../../../../library/common/Alert";
import Button from "../../../../library/common/Button";
import TrashButton from "../../../../library/common/ButtonTrashCircle";
import InputGroup from "../../../../library/common/InputGroup";
import HeaderBar from "../../../../library/common/PopupHeaderBar";
import { handleTextChange } from "../../../../library/utilities";

import {
    LICENCE_SEARCH,
    INPUT_TYPES,
    ALERT_TYPES,
    SECTION_TITLES,
} from "../../../../library/common/constants";

import {
    ContentBox,
    InputRow,
    HalfWidthWrapper,
    FullWidthWrapper,
    FooterBar,
    FooterBarContent,
    MiddleBtnWrapper,
    RightBtnWrapper,
} from "../../../../resources/styles";

import { LicenceItem } from "../LicenceItems";

// TODO needs a validationFailedState.uploadError for image upload
const LicenceDetailsView = ({
    licences,
    addLicenceToState,
    handleSearchBoxStringChange,
    validationFailedState,
    setLicenseDetails,
    licenseDetails,
    onFileChange,
    handleSubmit,
    handleBack,
    handleImageDelete,
    addLicenceOnly,
    setPopUpToDisplay,
}) => {
    const { licenceName, licencePhoto } = licenseDetails;
    const issueDate = licenseDetails.issueDate
        ? moment(licenseDetails.issueDate).format("YYYY-MM-DD")
        : "";
    const expiryDate = licenseDetails.expiryDate
        ? moment(licenseDetails.expiryDate).format("YYYY-MM-DD")
        : "";
    const hasLicencePhoto = licencePhoto && licencePhoto[0] ? true : false;

    return (
        <Wrapper>
            <HeaderBar
                setPopUpToDisplay={setPopUpToDisplay}
                handleBack={addLicenceOnly ? undefined : handleBack}
                popupTitle={SECTION_TITLES.licences}
            />
            <ContentBox>
                <ContentWrapper>
                    <SectionTitle>Licence Details</SectionTitle>
                    <FullWidthWrapper>
                        <InputGroup
                            value={licenceName}
                            title={`Name`}
                            type={INPUT_TYPES.SEARCH_TEXT}
                            placeholder={`First Aid Certificate`}
                            required={false}
                            errorMessage={validationFailedState.licenceName}
                            handleChange={addLicenceToState}
                            searchBoxProps={{
                                excludedFromSearchBox: licences.map(
                                    (el) => el.licenceName
                                ),
                                searchApiRequest: LICENCE_SEARCH,
                                updateStringInParent:
                                    handleSearchBoxStringChange,
                            }}
                            zIndex={2}
                        />
                    </FullWidthWrapper>
                    <InputRow>
                        <HalfWidthWrapper>
                            <InputGroup
                                title={`Date Issued`}
                                type={INPUT_TYPES.DATE}
                                required={false}
                                errorMessage={validationFailedState.issueDate}
                                name={"issueDate"}
                                value={issueDate}
                                handleChange={(e) =>
                                    handleTextChange(
                                        e,
                                        setLicenseDetails,
                                        licenseDetails
                                    )
                                }
                                dateInputProps={{
                                    max: expiryDate,
                                }}
                            />
                        </HalfWidthWrapper>
                        <HalfWidthWrapper spaceBetween={"2rem"}>
                            <InputGroup
                                title={`Expiry / Renewal Date`}
                                type={INPUT_TYPES.DATE}
                                required={false}
                                errorMessage={validationFailedState.expiryDate}
                                name={"expiryDate"}
                                value={expiryDate}
                                handleChange={(e) =>
                                    handleTextChange(
                                        e,
                                        setLicenseDetails,
                                        licenseDetails
                                    )
                                }
                                dateInputProps={{
                                    min: issueDate,
                                }}
                            />
                        </HalfWidthWrapper>
                    </InputRow>
                    <FullWidthWrapper>
                        {hasLicencePhoto && (
                            <ImgRow>
                                <LicenceImgWrapper>
                                    <LicenceImg
                                        src={licencePhoto[0].path}
                                        alt={licencePhoto[0].path}
                                    ></LicenceImg>
                                    <LicenceImgButton>
                                        <TrashButton
                                            handleClick={handleImageDelete}
                                            circleDiameter={"2.8rem"}
                                            fontSize={"1.2rem"}
                                        // iconColor={theme.colors.secondary}
                                        // bgColor={theme.colors.primary}
                                        />
                                    </LicenceImgButton>
                                </LicenceImgWrapper>
                            </ImgRow>
                        )}
                        <SectionTitle>Profile View</SectionTitle>
                        <Box>
                            <LicenceItem
                                i={1}
                                hasLicencePhoto={hasLicencePhoto}
                                licenceName={licenceName}
                                issueDate={issueDate}
                                dark={false}
                            />
                        </Box>
                        {!hasLicencePhoto && (
                            <VerifyMessage>
                                <Bold>Verify</Bold> your licence by adding a
                                photo. Licence images are private, other users
                                can only see photos by requesting your
                                permission.
                                {/* <Bold>Verify</Bold> your qualification by adding
                                a photo. Verified licences appear with a tick
                                but the image is kept private. A user can
                                request your permission to view an image if
                                needed. */}
                            </VerifyMessage>
                        )}
                    </FullWidthWrapper>
                    {validationFailedState.uploadError && (
                        <Alert
                            message={validationFailedState.uploadError}
                            type={ALERT_TYPES.ERROR}
                            hideBorder={true}
                        />
                    )}
                </ContentWrapper>
            </ContentBox>
            <FooterBar>
                <FooterBarContent>
                    <UploadBtnWrapper>
                        <Button
                            handleClick={onFileChange}
                            fileUpload={true}
                            secondaryBtnColors={true}
                            icon={<i className="fas fa-id-badge"></i>}
                        >
                            Upload
                        </Button>
                    </UploadBtnWrapper>
                    <RightBtnWrapper>
                        <Button
                            handleClick={handleSubmit}
                            primaryBtnColors={true}
                        >
                            Save
                        </Button>
                    </RightBtnWrapper>
                </FooterBarContent>
            </FooterBar>
        </Wrapper>
    );
};

export default LicenceDetailsView;

export const Wrapper = styled.div`
    width: min(800px, 95vw);
    min-height: 250px;
`;

const ContentWrapper = styled.div`
    padding: 1rem 2rem 1rem 2rem;
    @media (max-width: ${({ theme }) => theme.sizes.mdViewWidth}) {
        padding: 1rem 1rem 1rem 1rem;
    }
`;

const LicenceImgButton = styled.div`
    position: absolute;
    z-index: 1;
    top: 0.5rem;
    left: 0.5rem;
    display: flex;
`;

const LicenceImg = styled.img`
    width: 100%;
    object-fit: contain;
    max-height: 350px;
    max-width: 400px;
`;

const LicenceImgWrapper = styled.div`
    position: relative;
`;

const VerifyMessage = styled.div``;

const Bold = styled.span`
    font-weight: bold;
`;

const ImgRow = styled.div`
    display: flex;
    justify-content: center;
`;

const UploadBtnWrapper = styled(MiddleBtnWrapper)`
    flex: 0 1 250px;
`;

const SectionTitle = styled.h4`
    margin: 0;
`;

const Box = styled.div`
    /* display: inline-block;
    padding-right: 5rem; */
    margin-top: 5px;
    border: 1px solid ${(props) => props.theme.colors.offPrimary};
    border-radius: 5px;
`;
