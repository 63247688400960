import { useRef } from "react";

// import "../../../resources/styles/withPopUp.css";
import styled from "styled-components";
import { darken } from "polished";

import { lightTheme as theme } from "../../../resources/styles/Themes";
// import UseOutsideClick from "../../common/useOutsideClick";
import { UseOutsideClick } from "../../utilities";
import SidebarPopupPlaceholder from "./SidebarPopupPlaceholder-view";

export const popUpHeaderHeight = "4rem";
export const popUpMarginBelowNavbar = "2rem";
export const popUpBorderRadius = "5px";

// This is a HOC used for rendering a popup box and errors in popup box.
const WithPopUpContainer = (WrappedComponent) =>
    // HOC's must return a function that renders the WrappedComponent. We cant use hooks inside callbacks, we need to define the function below as opposed to it being an anonymous function like (props) => {} which would cause error.
    function WithPopUpHOC(props) {
        const { setPopUpToDisplay } = props;

        // Ref needed for handling outside click logic (when user clicks outside popup they are exited out of popup)
        const ref = useRef();
        // Adds and removes event listeners for a mousedown event outside of popup box
        UseOutsideClick(ref, (e) => {
            // TODO Issue with google autocomplete dropdown - click in dropdown counts as a user outside click and causes popup to close !!
            // This will work if you call popup background with class name model but think there has to be better solution
            // if (e.target.className === "modal") {
            // console.log("e.target.className");
            // console.log(e.target.className);
            // console.log(e.target.id);
            if (
                !e.target.className.includes("pac-container") &&
                !e.target.className.includes("pac-item")
            ) {
                // if customExitHandling function passed in call that - if not just remove popUpToDisplay
                if (props.customExitHandling) {
                    props.customExitHandling();
                } else {
                    setPopUpToDisplay("");
                }
            }
            // }
        });

        const stopPropagationAbovePopup = (e) => {
            // Stop any propagation above the popup in the dom tree.
            // E.g. clicking on shaded area outside popup should not trigger and buttons or links popup ot PopupBackground
            e.stopPropagation();
        };

        // Renders popup with outside click logic for click event outside popup
        return (
            <PopupBackground onClick={stopPropagationAbovePopup}>
                <SidebarPopupPlaceholder />
                <PopupRow popUpMarginBelowNavbar={popUpMarginBelowNavbar}>
                    <Popup ref={ref}>
                        <WrappedComponent {...props} />
                    </Popup>
                </PopupRow>
            </PopupBackground>
        );
    };

export default WithPopUpContainer;

const PopupBackground = styled.div`
    position: fixed; /* Stay in place */
    z-index: 50; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: hidden; /* Enable scroll if needed for SidebarPopupPlaceholder to work*/
    background-color: ${(props) => `${darken(0.1, props.theme.colors.body)}99`};
    display: flex; /* Flex needed for side*/
    align-items: flex-start;
`;

// Row wrapper component for popup, controls vertical and horizontal location
const PopupRow = styled.div`
    margin-top: ${(props) =>
        `calc(${props.popUpMarginBelowNavbar} + ${props.theme.sizes.navHeight})`};
    display: flex;
    justify-content: center;
    width: 100%;
`;

const Popup = styled.div`
    position: relative; /* Needed for positioning element inside it for instance exit button  */
    background-color: #fefefe;
    box-shadow: 15px 15px 50px ${(props) => `${props.theme.colors.secondary}85`};
    border-radius: ${popUpBorderRadius};
    /* min-width: 30vw; */
    /* @media (max-width: ${theme.sizes.mdViewWidth}) {
        min-width: 65vw;
    } */
    /* @media (max-width: ${theme.sizes.xsViewWidth}) {
        min-width: 80%;
        max-width: 95%;
    } */
`;
