import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";

import Icon from "../../library/common/Icon";

const Footer = ({ history }) => {
    const { loginWithRedirect } = useAuth0();
    return (
        <Wrapper>
            <FirstRow>
                <FirstBlock>
                    {/* <AppIcon src={"/LandingPageImgs/AppIcon.svg"} /> */}
                    <AppNameText onClick={loginWithRedirect}>
                        BOOMSUP
                    </AppNameText>
                </FirstBlock>
                {/* <SecondBlock>
                <StoreLinkBadge src={"/LandingPageImgs/AppleAppStore.svg"} />
                <StoreLinkBadge src={"/LandingPageImgs/GoogleAppStore.svg"} />
            </SecondBlock> */}
                <SecondBlock>
                    <IconWrapper href="https://www.linkedin.com/company/boomsup">
                        <Icon name={"linkedin"} brands />
                        {/* <i class="fa-brands fa-facebook"></i> */}
                        {/* <i class="fab fa-airbnb"></i> */}
                    </IconWrapper>
                    {/* <StoreLinkBadge src={"/LandingPageImgs/AppleAppStore.svg"} />
                <StoreLinkBadge src={"/LandingPageImgs/GoogleAppStore.svg"} /> */}
                </SecondBlock>
            </FirstRow>
            <SecondRow>
                <FooterTextLink onClick={() => history.push("/terms")}>
                    Terms
                </FooterTextLink>
                <FooterTextLink onClick={() => history.push("/terms")} padLeft>
                    Privacy
                </FooterTextLink>
            </SecondRow>
        </Wrapper>
    );
};

export default withRouter(Footer);

const footScreenSizeBreak = "500px";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const FirstRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 50px 0 20px 0;
    @media (max-width: ${footScreenSizeBreak}) {
        /* flex-direction: column;
        justify-content: flex-start; */
        padding: 20px;
    }
`;

const SecondRow = styled(FirstRow)`
    border-top: 1px solid ${(props) => props.theme.colors.offSecondary};
    /* margin: 0 5px; */
    padding: 20px 0;
    justify-content: flex-end;
    @media (max-width: ${footScreenSizeBreak}) {
        margin: 0 10px;
        /* border-top: none; */
        padding: 15px 10px;
    }
`;

const FirstBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;

const SecondBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    @media (max-width: ${footScreenSizeBreak}) {
        flex-direction: row;
        justify-content: space-between;
    }
`;

const AppIcon = styled.img`
    /* height: 100px; */
    width: 100px;
    margin-bottom: 10px;
`;

const AppNameText = styled.div`
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
    font-size: 30px;
    color: ${(props) => props.theme.colors.primary};
    cursor: pointer;
    /* Color Gradient */
    background-color: ${(props) => props.theme.colors.lightAccent};
    background-image: linear-gradient(
        45deg,
        ${(props) => props.theme.colors.accentGreen},
        ${(props) => props.theme.colors.lightAccent}
    );
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
`;

const StoreLinkBadge = styled.img`
    width: 200px;
    margin: 10px 0;
    @media (max-width: ${footScreenSizeBreak}) {
        width: 45%;
    }
`;

const IconWrapper = styled.a`
    color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.secondary};
    font-size: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: ${(props) => props.padBottom || 0};
    &:hover {
        color: ${(props) => props.theme.colors.offPrimary};
        font-size: 51px;
    }
`;

const FooterTextLink = styled.div`
    color: ${(props) => props.theme.colors.primary};
    font-size: 15px;
    font-family: "Montserrat", sans-serif;
    padding-left: ${(props) => (props.padLeft ? 15 : 0)}px;
    text-transform: uppercase;
    &:hover {
        color: ${(props) => props.theme.colors.offPrimary};
        cursor: pointer;
    }
`;
