// This is a landing page when the user is not logged in.

// import { Link } from "react-router-dom";

const LandingPage = ({ errors, loginWithRedirect }) => {
    return (
        <div className="row justify-content-center mt5">
            <div
                className="
                        col-xs-12
                        col-lg-6 
                        text-center 
                        d-block 
                        align-items-center 
                        justify-content-center 
                        mt5"
            >
                <div className="row" style={{ height: "8rem" }}></div>

                <h1 style={{ fontSize: "5rem" }}>cosQos</h1>

                <div>
                    {/* <Link
                                to="/signup"
                                className="btn btn-secondary landingButtons"
                            >
                                Sign Up
                            </Link> */}
                    {/* <Link
                                to="/login"
                                className="btn btn-secondary landingButtons"
                            >
                                Log In
                            </Link> */}
                    <div
                        className="btn btn-secondary landingButtons"
                        onClick={() => loginWithRedirect()}
                    >
                        Log In
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingPage;
