import styled from "styled-components";

import Button from "../../library/common/Button";
import { PRIMARY_AUTHOR } from "../../library/common/constants";

// Back bar displays in post full view mode, bar at top of screen with authors name in it and back button
// Position sticky to stay at top of screen as user scrolls down post
const BackBar = ({ history, theme, post, showName }) => {
    return (
        <PostHeadNav>
            <LeftBtnWrapper>
                <Button
                    handleClick={() => {
                        history.goBack();
                    }}
                    textSize={"1rem"}
                    icon={<i className="fas fa-angle-double-left"></i>}
                    bgColor={theme.colors.secondary}
                    textColor={theme.colors.primary}
                >
                    Back
                </Button>
            </LeftBtnWrapper>
            {showName && (
                <MiddleBox>
                    <AuthorsName nameLength={post.user.username.length}>
                        {post.primaryAuthor === PRIMARY_AUTHOR.COMPANY
                            ? post.company.name
                            : post.user.givenName}
                        's post
                    </AuthorsName>
                </MiddleBox>
            )}
            <RightBtnSpacePlaceholder />
        </PostHeadNav>
    );
};

export default BackBar;

const PostHeadNav = styled.div`
    z-index: 10; /* Needs to be above the multiple levels in post but below nav and popup at 100 and 50 respect*/
    position: sticky; /* Stay at top of post on scoll*/
    top: ${(props) => props.theme.sizes.navHeight};
    background-color: ${(props) => props.theme.colors.body};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
`;

const LeftBtnWrapper = styled.div`
    flex: 0 1 200px;
`;

// Creates a space on the right equal to the back buttons space on the left, this allows authors name to be centered properly.
const RightBtnSpacePlaceholder = styled(LeftBtnWrapper)``;

const MiddleBox = styled.div`
    flex: 1 1 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 0; /* allow box to shrink smaller than its content (i.e. text nowrap)*/
`;

const AuthorsName = styled.p`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    display: ${(props) =>
        props.nameLength > 15
            ? "none"
            : "block"}; /* don't show for long names*/
`;
