import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import SiteMap from "./ProfileSiteMap-container";

function mapState(state) {
    return {
        currentUser: state.currentUser,
    };
}

const mapDispatch = {};

export default withRouter(connect(mapState, mapDispatch)(SiteMap));
