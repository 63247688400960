import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { sendApiRequest } from "../../../../services/api";

import {
    fieldsFailingValidation,
    checkDocumentFieldInDb,
} from "../../../../library/utilities";
import ExperienceList from "./ExperienceList-view";
import ExperienceDetails from "./ExperienceDetails-view";
import {
    COMPANY_SEARCH,
    WORK_EXPERIENCE_EDIT_SCREENS,
    WORK_EXPERIENCE_VALIDATION_RULES,
} from "../../../../library/common/constants";
import PopupLoadingScreen from "../../../../library/common/PopupLoadingScreen";

// Logic for editing user workExperience
const ExperienceEditContainer = ({
    workExperience,
    addError,
    setPopUpToDisplay,
    refreshDataFromServer,
}) => {
    // popup loading state
    const [loading, setLoading] = useState(false);

    // Stores a specific experience being edited or added
    const [experienceDetails, setExperienceDetails] = useState({});
    // Stores front end validation failures
    const [validationFailedState, setValidationFailedState] = useState({});
    const [firstSubmitFailed, setFirstSubmitFailed] = useState(false);

    // User arrives at list screen on mount
    const [experienceScreen, setExperienceScreen] = useState(
        WORK_EXPERIENCE_EDIT_SCREENS.list
    );
    const { getAccessTokenSilently } = useAuth0();

    let { user_id } = useParams();

    // Set loaded on mount
    useEffect(() => {
        setLoading(false);
    }, [setLoading]);

    // Add index of experience being edited with experienceDetails on edit click
    const handleEditClick = (experienceToEditIndex) => {
        workExperience.forEach((experience, i) => {
            if (i === experienceToEditIndex) {
                setExperienceDetails({
                    ...experience,
                    experienceToEditIndex: experienceToEditIndex,
                });
                setExperienceScreen(WORK_EXPERIENCE_EDIT_SCREENS.edit);
            }
        });
    };

    // When user hits add.
    const handleAddClick = () => {
        // Define the required fields (needed for validation)
        setExperienceDetails({
            companyName: "",
            jobTitle: "",
            startDate: "",
        });
        setExperienceScreen(WORK_EXPERIENCE_EDIT_SCREENS.add);
    };

    // Adds company id && name to state (used in SearchBox)
    const addCompanyToState = (result) => {
        setExperienceDetails((f) => {
            return {
                ...f,
                company: result.id,
                companyName: result.name,
                companyLogo: result.img,
            };
        });
    };

    // Handle change in searchBox, updates companyName as user types
    const handleSearchBoxStringChange = (string) => {
        setExperienceDetails((f) => {
            return {
                ...f,
                companyName: string,
                company: "",
                companyLogo: "",
            };
        });
    };

    // loop through licenceDetails and check each field against validation rules
    const checkFieldsPassValidationRules = useCallback(() => {
        const fieldsFailedValidation = fieldsFailingValidation(
            experienceDetails,
            WORK_EXPERIENCE_VALIDATION_RULES
        );
        // Update state to show error messages to user
        setValidationFailedState(fieldsFailedValidation);
        // return false if failure, true if no failures
        return Object.keys(fieldsFailedValidation).length > 0 ? false : true;
    }, [experienceDetails]);

    // Cleares error from screen as user fixes
    useEffect(() => {
        if (firstSubmitFailed) {
            checkFieldsPassValidationRules();
        }
    }, [experienceDetails, checkFieldsPassValidationRules, firstSubmitFailed]);

    // Submit edit & add experience requests to server
    const handleSubmit = async () => {
        try {
            setLoading(true);
            // Front end validation
            const pass = checkFieldsPassValidationRules();
            if (pass) {
                // Checks for company id, adds or creates a new company in db if necessary
                const newOrUpdatedExperience = await checkDocumentFieldInDb(
                    getAccessTokenSilently,
                    experienceDetails.company, // doc id
                    experienceDetails.companyName, // doc name
                    "company", // key of id doc id field
                    COMPANY_SEARCH,
                    "/api/companies/",
                    experienceDetails,
                    false // titleCase = false
                );
                // check if editing OR adding new experience then update workExperience array accordingly
                const updatedWorkExperienceArr =
                    experienceScreen === WORK_EXPERIENCE_EDIT_SCREENS.edit
                        ? workExperience.map((experience, i) => {
                            // edit item in array
                            return i ===
                                experienceDetails.experienceToEditIndex
                                ? newOrUpdatedExperience
                                : experience;
                        })
                        : [...workExperience, newOrUpdatedExperience]; // add to array
                // Send to server
                await sendApiRequest(getAccessTokenSilently, {
                    method: "put",
                    url: `/api/users/${user_id}`,
                    data: {
                        workExperience: updatedWorkExperienceArr,
                    },
                });
                // refresh parent state once added to server
                await refreshDataFromServer();
            } else {
                setFirstSubmitFailed(true);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            addError(`Error submitting licence: ${error.message}`);
        }
    };

    // Deletes experience form workExperience array and sends updated array to server
    const handleExperienceDelete = async (licenseToDeleteIndex) => {
        try {
            setLoading(true);
            let updatedWorkExperienceArr = [];
            console.log("licenseToDeleteIndex");
            console.log(licenseToDeleteIndex);
            workExperience.forEach((experience, i) => {
                if (i !== licenseToDeleteIndex) {
                    // Only push for those experiences not being deleted
                    updatedWorkExperienceArr.push(experience);
                }
            });
            // Send to server
            await sendApiRequest(getAccessTokenSilently, {
                method: "put",
                url: `/api/users/${user_id}`,
                data: {
                    workExperience: updatedWorkExperienceArr,
                },
            });
            await refreshDataFromServer();
        } catch (error) {
            setLoading(false);
            addError(`Error submitting licence: ${error.message}`);
        }
    };

    // Back button in add or edit screens - returns user to workExperience list screen
    const handleBack = () => {
        setExperienceDetails({});
        setExperienceScreen(WORK_EXPERIENCE_EDIT_SCREENS.list);
        setValidationFailedState({});
    };

    if (loading) {
        return <PopupLoadingScreen />;
    } else if (experienceScreen === WORK_EXPERIENCE_EDIT_SCREENS.list) {
        return (
            <ExperienceList
                workExperience={workExperience}
                handleEditClick={handleEditClick}
                handleAddClick={handleAddClick}
                setPopUpToDisplay={setPopUpToDisplay}
                handleExperienceDelete={handleExperienceDelete}
            />
        );
    } else {
        return (
            <ExperienceDetails
                addCompanyToState={addCompanyToState}
                setPopUpToDisplay={setPopUpToDisplay}
                validationFailedState={validationFailedState}
                setExperienceDetails={setExperienceDetails}
                experienceDetails={experienceDetails}
                handleSubmit={handleSubmit}
                handleBack={handleBack}
                handleExperienceDelete={handleExperienceDelete}
                experienceScreen={experienceScreen}
                handleSearchBoxStringChange={handleSearchBoxStringChange}
            />
        );
    }
};

export default ExperienceEditContainer;
