import { createGlobalStyle } from "styled-components";
// Global styed used throughout app
export const GlobalStyles = createGlobalStyle`

  body {
    background-color: ${({ theme }) => theme.colors.body};
    color: ${({ theme }) => theme.colors.text.body};
    /* font-family: Roboto; */
    overflow-y: auto;
    overflow-x: hidden; /* If allow overflow x get a horizontal scroll for the width of vertical scrollbar on small screens*/
  }

  a {
    color: ${({ theme }) => theme.colors.text.body};
  }
  a:hover{
    color: ${({ theme }) => theme.colors.text.body};
    text-decoration: none;
  }

  button {
    padding: 0; 
    border: none;
    cursor: pointer;
    background-color: inherit;
    text-align: start;
    color: ${({ theme }) => theme.colors.text.body};
  }

  button:focus {
    outline: none;
  }

  label {
    margin: 0;
  }

  input {
    color: ${({ theme }) => theme.colors.text.body};
  }

  input:focus {
    outline: none;
  }

  select {
    color: ${({ theme }) => theme.colors.text.body};
  }

  select:focus {
    outline: none;
  }

  textArea:focus {
    outline: none;
  }

  `;
