import { useState, useRef } from "react";
import styled from "styled-components";

import { UseOutsideClick } from "../../utilities";
import Button from "../Button";
import { CONNECTION_ACTION, CONNECTION_STATUS } from "../constants";
import { TriangleUp } from "../../../resources/styles";
// import { useApi } from "../../../services/api";

const BtnBarContainer = ({
    userIsAdmin,
    theme,
    conxStatus,
    showConxBtn,
    userProps = {},
    companyProps = {},
    phone,
    handleMessageClick,
    handleEditProfile,
    isUserProfile,
    isCompanyProfile,
    handleConxAction,
}) => {
    // States used for showing and hiding popovers
    const [showOptionsPopover, setShowOptionsPopover] = useState(false);
    const [showPhonePopover, setShowPhonePopover] = useState(false);

    // Clicks outside popover closes it
    const optionsRef = useRef();
    UseOutsideClick(optionsRef, () => {
        setShowOptionsPopover(false);
    });

    const phoneRef = useRef();
    UseOutsideClick(phoneRef, () => {
        setShowPhonePopover(false);
    });

    // props common to all conx btns
    const commonConxBtnProps = {
        noShadow: true,
        fillParentHeight: true,
        borderRadius: "0.7rem",
    };

    let ConxBtn; // Initialize conx btn
    // Define conx btn based off whether user is friends with, not friends with or has sent a request to owner of profile
    if (isUserProfile && !showConxBtn) {
        // ***** User connection button *******
        if (conxStatus === CONNECTION_STATUS.NOTAPPLICABLE && !userIsAdmin) {
            ConxBtn = (
                <Button
                    handleClick={() => handleConxAction(CONNECTION_ACTION.ADD)}
                    icon={<i className="fas fa-user-plus"></i>}
                    bgColor={theme.colors.darkAccent}
                    textColor={theme.colors.primary}
                    {...commonConxBtnProps}
                >
                    <AddConxText>Friend</AddConxText>
                </Button>
            );
        } else if (conxStatus === CONNECTION_STATUS.REQUESTED) {
            ConxBtn = (
                <Button
                    icon={<i className="fas fa-user-clock"></i>}
                    bgColor={theme.colors.offPrimary}
                    textColor={theme.colors.secondary}
                    {...commonConxBtnProps}
                >
                    <AddConxText>Sent</AddConxText>
                </Button>
            );
        } else if (conxStatus === CONNECTION_STATUS.ACCEPTED) {
            ConxBtn = (
                <Button
                    icon={<i className="fas fa-user-check"></i>}
                    bgColor={theme.colors.offPrimary}
                    textColor={theme.colors.secondary}
                    iconColor={theme.colors.accent}
                    {...commonConxBtnProps}
                >
                    <AddConxText>Friends</AddConxText>
                </Button>
            );
        }
    }

    if (isCompanyProfile && !showConxBtn) {
        // ***** Company connection button *******
        if (conxStatus === CONNECTION_STATUS.NOTAPPLICABLE) {
            ConxBtn = (
                <Button
                    handleClick={() =>
                        handleConxAction(CONNECTION_ACTION.FOLLOW)
                    }
                    icon={<i className="fas fa-plus-circle"></i>}
                    bgColor={theme.colors.darkAccent}
                    textColor={theme.colors.primary}
                    {...commonConxBtnProps}
                >
                    <AddConxText>Follow</AddConxText>
                </Button>
            );
        } else if (conxStatus === CONNECTION_STATUS.ACCEPTED) {
            ConxBtn = (
                <Button
                    icon={<i className="fas fa-check-circle"></i>}
                    bgColor={theme.colors.offPrimary}
                    textColor={theme.colors.secondary}
                    iconColor={theme.colors.accent}
                    {...commonConxBtnProps}
                >
                    <AddConxText>Following</AddConxText>
                </Button>
            );
        }
    }

    const commonOptionsBtnProps = {
        secondaryBtnColors: true,
        noShadow: true,
    };

    const OptionsPopover = (
        <PopoverWrapper ref={optionsRef}>
            <Triangle bgColor={theme.colors.primary} />
            <PopoverBox>
                {isUserProfile &&
                    !userIsAdmin &&
                    conxStatus === CONNECTION_STATUS.ACCEPTED && (
                        <OptionsBtnWrapper>
                            <Button
                                handleClick={() =>
                                    handleConxAction(CONNECTION_ACTION.REMOVE)
                                }
                                icon={<i className="fas fa-user-times"></i>}
                                iconColor={theme.colors.text.semantic.error}
                                {...commonOptionsBtnProps}
                            >
                                <BtnText>Unfriend</BtnText>
                            </Button>
                        </OptionsBtnWrapper>
                    )}
                {!!userIsAdmin && (
                    <OptionsBtnWrapper>
                        <Button
                            handleClick={handleEditProfile}
                            btnPadding={"0.1rem 1.5rem"} // add extra minimum padding to edit btn
                            icon={
                                isCompanyProfile ? (
                                    <i className="fas fa-building"></i>
                                ) : (
                                    <i className="fas fa-user-edit"></i>
                                )
                            }
                            {...commonOptionsBtnProps}
                        >
                            <BtnText>Edit</BtnText>
                        </Button>
                    </OptionsBtnWrapper>
                )}
                {isCompanyProfile &&
                    !userIsAdmin &&
                    conxStatus === CONNECTION_STATUS.ACCEPTED && (
                        <OptionsBtnWrapper>
                            <Button
                                handleClick={() =>
                                    handleConxAction(CONNECTION_ACTION.UNFOLLOW)
                                }
                                icon={<i className="fas fa-user-times"></i>}
                                iconColor={theme.colors.text.semantic.error}
                                {...commonOptionsBtnProps}
                            >
                                <BtnText>Unfollow</BtnText>
                            </Button>
                        </OptionsBtnWrapper>
                    )}
            </PopoverBox>
        </PopoverWrapper>
    );

    const PhonePopover = (
        <PopoverWrapper ref={phoneRef}>
            <Triangle bgColor={theme.colors.primary} />
            <PopoverBox>
                <PhoneTxt>{phone}</PhoneTxt>
            </PopoverBox>
        </PopoverWrapper>
    );

    // Props all icon btns have - makes use of props from commonConxBtnProps
    const commonIconBtnProps = {
        ...commonConxBtnProps,
        bgColor: theme.colors.offPrimary,
        iconPadding: "0 0.1rem",
    };

    return (
        <BtnBarWrapper>
            {!!ConxBtn && <BtnWrapper width={"150px"}>{ConxBtn}</BtnWrapper>}
            {(conxStatus === CONNECTION_STATUS.ACCEPTED ||
                userIsAdmin ||
                isCompanyProfile) &&
                !!phone && (
                    // Can only see phone btn if your a friend (or if looking at your own profile)
                    <BtnWrapper>
                        <Button
                            handleClick={() => setShowPhonePopover(true)}
                            icon={<i className="fas fa-phone"></i>}
                            textColor={theme.colors.text.semantic.emerald}
                            iconSize={"1.5rem"}
                            {...commonIconBtnProps}
                        ></Button>
                        {!!showPhonePopover && PhonePopover}
                    </BtnWrapper>
                )}
            {isUserProfile && conxStatus === CONNECTION_STATUS.ACCEPTED && (
                // Can only see message btn if your a friend and account is a user (no message for companies)
                <BtnWrapper>
                    <Button
                        handleClick={handleMessageClick}
                        icon={<i className="fas fa-envelope"></i>}
                        textColor={theme.colors.text.semantic.info}
                        iconSize={"1.8rem"}
                        {...commonIconBtnProps}
                    ></Button>
                </BtnWrapper>
            )}
            {isCompanyProfile && companyProps.websiteURL && (
                <BtnWrapper>
                    <Button
                        handleClick={handleMessageClick}
                        icon={<i className="fas fa-globe"></i>}
                        textColor={theme.colors.text.semantic.info}
                        iconSize={"1.8rem"}
                        {...commonIconBtnProps}
                    ></Button>
                </BtnWrapper>
            )}
            {(conxStatus === CONNECTION_STATUS.ACCEPTED || userIsAdmin) && (
                <BtnWrapper>
                    <Button
                        handleClick={() => setShowOptionsPopover(true)}
                        icon={<i className="fas fa-ellipsis-h"></i>}
                        textColor={theme.colors.secondary}
                        iconSize={"2rem"}
                        {...commonIconBtnProps}
                    ></Button>
                    {!!showOptionsPopover && OptionsPopover}
                </BtnWrapper>
            )}
        </BtnBarWrapper>
    );
};

export default BtnBarContainer;

const BtnBarWrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
`;

const BtnWrapper = styled.div`
    position: relative;
    width: ${(props) => props.width || "auto"};
    padding-left: 0.4rem;
    height: 42px;
    @media (max-width: 350px) {
        /* Space between rows on screen size BtnBar has likely wrapped  */
        margin-bottom: 0.3rem;
    }
`;

const BtnText = styled.div`
    font-weight: 500;
`;

const AddConxText = styled(BtnText)`
    font-size: 1.1rem;
`;

const OptionsBtnWrapper = styled.div`
    padding-bottom: 0.5rem;
`;

/* Positions the popover */
const PopoverWrapper = styled.div`
    top: 100%;
    right: 0;
    position: absolute;
    z-index: 1;
`;

const Triangle = styled(TriangleUp)`
    right: 1.2rem; /* points to approx center of btn above */
    z-index: 1; /* Ensure arrow not hidden behind shadow of PopoverBox */
`;

// Box containing content
const PopoverBox = styled.div`
    position: relative;
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.secondary};
    border-radius: 10px;
    padding: 1rem;
    margin: 0.5rem 0 0 0;
    box-shadow: 10px 5px 35px rgba(0, 0, 0, 0.15);
`;

/* Phone popover text */
const PhoneTxt = styled.div`
    font-weight: 500;
    font-size: 1.4rem;
    letter-spacing: 0.05rem;
`;
