import styled from "styled-components";

import Avatar from "../../library/common/Avatar";
import {
    formatDateForPost,
    useUpdateTime,
    handleUserClick,
} from "../../library/utilities";
import InputGroup from "../../library/common/InputGroup";
import { INPUT_TYPES } from "../../library/common/constants";
import Button from "../../library/common/Button";
import LoadingDots from "../../library/common/LoadingDots";
import EditCommentButton from "./EditCommentBtn-view";

const commentAvatarSize = "2.2rem";

const PostCommentsView = ({
    post,
    theme,
    history,
    currentUser,
    commentState,
    setCommentState,
    handlePostSubmit,
    commentLoading,
    handleCommentDelete,
    deletingComment,
    commentsLoading,
}) => {
    const timeNow = useUpdateTime(30000); // update time ago every 30 seconds

    const CommentsToRender =
        post && post.comments ? (
            post.comments.map((comment, i) => (
                <CommentRow key={i}>
                    <AvatarWrapper>
                        <Avatar
                            path={comment.img}
                            user={true} // if path is undefined will use default picture
                            size={commentAvatarSize}
                            handleClick={(e) =>
                                handleUserClick(e, history, post.user._id)
                            }
                            borderColor={theme.colors.offPrimary}
                        />
                    </AvatarWrapper>
                    <CommentCol>
                        <CommentBubble>
                            <Username>{comment.username}</Username>
                            <Text>{comment.text}</Text>
                        </CommentBubble>
                        <CreatedAtDate>
                            {formatDateForPost(comment.createdAt, timeNow)}
                        </CreatedAtDate>
                    </CommentCol>
                    <EditCommentButton
                        theme={theme}
                        handleDelete={() => {
                            handleCommentDelete(comment._id);
                        }}
                        loading={deletingComment}
                    />
                </CommentRow>
            ))
        ) : (
            <div></div>
        );

    const CommentInputSection = (
        <CommentInputWrapper>
            <Avatar
                path={currentUser.user.img}
                user={true} // if path is undefined will use default picture
                size={commentAvatarSize}
                handleClick={(e) => handleUserClick(e, history, post.user._id)}
                borderColor={theme.colors.offPrimary}
            />
            <CommentInput>
                {/* <Username>{currentUser.user.username}</Username> */}
                <InputGroup
                    type={INPUT_TYPES.TEXT}
                    handleChange={(e) => setCommentState(e.target.value)}
                    value={commentState}
                    name="comment"
                    placeholder={"Write a caption..."}
                    borderRadius={"0.8rem"}
                    bgColor={theme.colors.body}
                />
                <PostButtonWrapper>
                    <Button
                        handleClick={handlePostSubmit}
                        textSize={"1rem"}
                        // primaryBtnColors={true}
                        bgColor={theme.colors.secondary}
                        textColor={theme.colors.primary}
                        maxHeight={"2.45rem"}
                        icon={<i className="fas fa-paper-plane"></i>}
                        borderRadius={"1.1rem"}
                        buttonLoading={commentLoading}
                    ></Button>
                </PostButtonWrapper>
            </CommentInput>
        </CommentInputWrapper>
    );

    if (commentsLoading) {
        return (
            <LoadingRow>
                {/* <LoadingSpinner
                        color={theme.colors.offSecondary}
                        theme={theme}
                    /> */}
                <LoadingDots size={"10px"} />
            </LoadingRow>
        );
    } else if (!post.comments) {
        // will occur when comments are not loaded (i.e on NewsFeed)
        return <div></div>;
    } else if (post.comments[0] === undefined) {
        // If comments loaded but no comments in post
        return (
            <WrapperNoComments>
                <NoCommentsMessage>No comments yet.</NoCommentsMessage>
                {CommentInputSection}
            </WrapperNoComments>
        );
    } else {
        // post has comments
        return (
            <Wrapper>
                {CommentsToRender}
                {CommentInputSection}
            </Wrapper>
        );
    }
};

export default PostCommentsView;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1.5rem 0.7rem 0.5rem 0.7rem;
    border-top: 1px solid ${(props) => props.theme.colors.offPrimary};
`;

const WrapperNoComments = styled(Wrapper)`
    border-top: none;
    padding: 0.7rem 0.7rem 0.5rem 0.7rem;
`;

const AvatarWrapper = styled.div`
    padding-top: 0.3rem;
`;

const CommentRow = styled.div`
    display: flex;
`;

const CommentCol = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 0.5rem;
`;

const NoCommentsMessage = styled.p`
    text-align: center;
    color: ${(props) => props.theme.colors.offSecondary};
    /* font-size: 0.9rem; */
`;

const CommentBubble = styled.div`
    background-color: ${(props) => props.theme.colors.body};
    border-radius: 0.8rem;
    padding: 0.5rem 1rem;
`;

const Username = styled.div`
    font-weight: bold;
`;

const Text = styled.div`
    display: flex;
`;

const CreatedAtDate = styled.p`
    margin: 0;
    color: ${(props) => props.theme.colors.offSecondary};
    font-size: 80%;
    white-space: nowrap;
`;

const CommentInputWrapper = styled.div`
    display: flex;
    padding: 1rem 0 0 0;
`;

const CommentInput = styled(CommentCol)`
    flex-direction: row;
    flex: 1;
`;

const PostButtonWrapper = styled.div`
    padding: 0 0 0 0.7rem;
`;

const LoadingRow = styled.div`
    display: flex;
    justify-content: center;
    padding: 1rem;
`;
