import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { withRouter } from "react-router";

const LandingNavbarContainer = ({ startBgColor, scrollBgColor, history }) => {
    const [inTopPosition, setInTopPosition] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    // Control navbar based on scroll position
    const controlNavbar = () => {
        if (typeof window !== "undefined") {
            // Check if in top scroll position
            if (window.scrollY === 0) {
                setInTopPosition(true);
            } else {
                setInTopPosition(false);
            }
            setLastScrollY(window.scrollY);
        }
    };

    // Call controlNavbar on scroll
    useEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener("scroll", controlNavbar);
            // cleanup function
            return () => {
                window.removeEventListener("scroll", controlNavbar);
            };
        }
    }, [lastScrollY]);

    return (
        <LandingNavbar
            inTopPosition={inTopPosition}
            startBgColor={startBgColor}
            scrollBgColor={scrollBgColor}
        >
            <AppNameText
                onClick={() => {
                    history.push("/");
                }}
            >
                BOOMSUP
            </AppNameText>
        </LandingNavbar>
    );
};

export default withRouter(LandingNavbarContainer);

const LandingNavbar = styled.div`
    position: sticky;
    top: 0;
    z-index: 100; /* to move anything above the navbar z-index needs to be higher than 100 */
    height: ${({ theme }) => theme.sizes.navHeight};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.5rem;
    color: ${(props) => props.theme.colors.primary};
    transition: background-color 0.3s ease;
    /* box-shadow: 0 1px 12px rgba(0, 0, 0, 0.2); */
    box-shadow: ${({ inTopPosition }) =>
        inTopPosition
            ? "0 1px 12px rgba(0, 0, 0, 0)"
            : "0 1px 12px rgba(0, 0, 0, 0.3)"};
    background-color: ${({ inTopPosition, startBgColor, scrollBgColor }) =>
        inTopPosition ? startBgColor : scrollBgColor};
`;

const AppNameText = styled.div`
    color: ${(props) => props.theme.colors.primary};
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
    font-size: 35px;
    cursor: pointer;
`;
