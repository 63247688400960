import styled from "styled-components";

import ButtonCircleIcon from "../ButtonCircleIcon";
import Avatar from "../Avatar";
import {
    REACT_APP_DEFAULT_USER_PROFILE,
    REACT_APP_DEFAULT_COMPANY_PROFILE,
} from "../constants";

// Renders user avatars in a horizontal line with an icon to the left
const AvatarLineUpView = ({
    theme,
    avatarArray,
    handleClick, // optional
    maxAvatars, // optional
    avatarSize, // optional, default 2rem
    icon, // optional icon left of avatars
    hideAvatarsWithoutPic, // optional removes avatars with no pic from rendered avatars
    loading, // optional if true loading lineup shown
    avatarBorderColor, // optional
}) => {
    avatarSize = avatarSize || "2rem";

    // Renders avatars loading screen with maxAvatars loading avatars in lineup (or default 4 if not given)
    if (loading) {
        maxAvatars = maxAvatars || 4;
        const LoadingAvatars = [...Array(maxAvatars)].map((el, i) => (
            <AvatarWrapper key={i} positionInTags={i} avatarSize={avatarSize}>
                <Avatar size={avatarSize} parentLoading={loading} />
            </AvatarWrapper>
        ));

        return (
            <Wrapper hasClickHandler={false}>
                {icon && (
                    <LineupIconWrapper size={avatarSize} loadingColor={true}>
                        {icon}
                    </LineupIconWrapper>
                )}
                <LineUpWidthPlaceholder
                    numTags={maxAvatars}
                    avatarSize={avatarSize}
                >
                    {LoadingAvatars}
                </LineUpWidthPlaceholder>
            </Wrapper>
        );
    } else {
        // Decide what avatars to render in line up
        // Filter out all avatars without display pics (If hideAvatarsWithoutPic = true)
        // Limit number of avatars to maxAvatars
        let avatarsForLineUp = avatarArray
            .filter((el) => (hideAvatarsWithoutPic ? !!el.img : true))
            .slice(0, maxAvatars); // (if maxAvatars undefined then no limit there)

        // Number of avatars in lineup && number not in lineup
        let numAvatarsToRender = avatarsForLineUp.length;
        let numFilteredAvatars = avatarArray.length - numAvatarsToRender;

        // If there are filters avatars but no avatars to render then render the first avatar in array and update numbers
        if (numFilteredAvatars > 0 && numAvatarsToRender === 0) {
            avatarsForLineUp.push(avatarArray[0]);
            numAvatarsToRender = 1; // Add one to numAvatarsToRender
            numFilteredAvatars -= 1; // take one off numFilteredAvatars
        }

        const avatarLineUpForRender = [];
        avatarsForLineUp.forEach((el, i) => {
            avatarLineUpForRender.push(
                <AvatarWrapper
                    key={el._id || el.userId}
                    positionInTags={i}
                    avatarSize={avatarSize}
                >
                    <Avatar
                        path={
                            el.img
                                ? el.img
                                : el.companyTag
                                ? REACT_APP_DEFAULT_USER_PROFILE
                                : REACT_APP_DEFAULT_COMPANY_PROFILE
                        }
                        alt={el.companyTag ? el.name : el.username} // company's come in as el.name while users el.username
                        size={avatarSize}
                        borderColor={
                            avatarBorderColor || `${theme.colors.offPrimary}50` // default is half transparent offPrimary
                        }
                        showPointer={!!handleClick} // Show pointer if click handler given
                    />
                </AvatarWrapper>
            );
        });

        if (numFilteredAvatars > 0) {
            // Circle with + number for number of avatars not shown in lineup
            avatarLineUpForRender.push(
                <FilteredAvatarsWrapper
                    key={"MoreBtn"}
                    positionInTags={numAvatarsToRender}
                    avatarSize={avatarSize}
                >
                    <ButtonCircleIcon
                        circleDiameter={avatarSize}
                        fontSize={"1.2rem"}
                        bgColor={theme.colors.offSecondary}
                        iconColor={theme.colors.primary}
                    >
                        <FilteredAvatars>+{numFilteredAvatars}</FilteredAvatars>
                    </ButtonCircleIcon>
                </FilteredAvatarsWrapper>
            );
        }

        if (numAvatarsToRender > 0) {
            return (
                <Wrapper
                    onClick={handleClick}
                    hasClickHandler={handleClick ? true : false}
                >
                    {icon && (
                        <LineupIconWrapper size={avatarSize}>
                            {icon}
                        </LineupIconWrapper>
                    )}
                    {avatarLineUpForRender && avatarLineUpForRender.length > 0 && (
                        <LineUpWidthPlaceholder
                            numTags={avatarLineUpForRender.length}
                            avatarSize={avatarSize}
                        >
                            {avatarLineUpForRender}
                        </LineUpWidthPlaceholder>
                    )}
                </Wrapper>
            );
        } else {
            return <div></div>;
        }
    }
};

export default AvatarLineUpView;

const Wrapper = styled.div`
    position: relative;
    display: flex;
    cursor: ${(props) => (props.hasClickHandler ? "pointer" : "auto")};
`;

const LineupIconWrapper = styled.div`
    display: flex;
    font-size: ${(props) => props.size};
    padding: 0 0.5rem;
    color: ${({ loadingColor, theme }) =>
        loadingColor ? theme.colors.offSecondary : "inherit"};
`;

const LineUpWidthPlaceholder = styled.div`
    position: relative;
    min-height: ${(props) => props.avatarSize};
    /* Children are positioned absolute, create correct space for them in flexbox */
    width: ${({ numTags, avatarSize }) =>
        `calc(${numTags + 1} * ${avatarSize}/1.8)`};
    overflow: hidden; /* Will hide some avatarArray on really small screen 300px or less on posts with four photos and 5 or more avatarArray */
`;

const AvatarWrapper = styled.div`
    position: absolute;
    display: flex; /* Stop avatar being cut off on small avatar sizes*/
    left: ${(props) =>
        `calc(${props.positionInTags} * ${props.avatarSize}/1.8)`};
`;

const FilteredAvatarsWrapper = styled(AvatarWrapper)``;

const FilteredAvatars = styled.h6`
    margin: 0;
    padding-right: 0.1rem;
`;
