import styled from "styled-components";

import EditButton from "../../library/common/ButtonEditCircle";
import Map from "../../library/common/Map";
import NoDetailsScreen from "../../library/common/NoDetailsView";
import ServicesList from "../../library/common/ServicesList";

import {
    InfoIcon,
    LocationIcon,
    PasteIcon,
    ServiceIcon,
} from "../../library/common/Icon";

import { addMarkersToMap } from "../../library/utilities";

import {
    SectionBox,
    HeaderBox,
    BoxTitle,
    SectionContent,
    SectionBoxWrapper,
} from "../../resources/styles";

const BTN_SIZE = "3rem";
const BTN_FONT_SIZE = "1.3rem";

// Renders company about page
const AboutView = ({
    companyDetails,
    profileSections,
    createView,
}) => {
    const {
        aboutUs,
        services,
        officeLocations,
        userIsAdmin: userIsCompanyAdmin,
    } = companyDetails;

    return (
        <Wrapper>
            {profileSections.about.has && (
                <SectionBoxWrapper>
                    <SectionBox>
                        <HeaderBox>
                            <BoxTitle>
                                {/* <HeaderIcon className="fas fa-info-circle"></HeaderIcon> */}
                                <InfoIcon marginRight={"1rem"} />
                                {profileSections.about.title}
                            </BoxTitle>
                            {userIsCompanyAdmin && (
                                <EditButton
                                    handleClick={profileSections.about.edit}
                                    invDefaultColors={true}
                                    circleDiameter={BTN_SIZE}
                                    fontSize={BTN_FONT_SIZE}
                                />
                            )}
                        </HeaderBox>
                        <SectionContent>
                            <AboutUsTxt>{aboutUs}</AboutUsTxt>
                        </SectionContent>
                    </SectionBox>
                </SectionBoxWrapper>
            )}

            {profileSections.services.has && (
                <SectionBoxWrapper>
                    <SectionBox>
                        <HeaderBox>
                            <BoxTitle>
                                {/* <HeaderIcon className="fas fa-hard-hat"></HeaderIcon> */}
                                <ServiceIcon marginRight={"1rem"} />
                                {profileSections.services.title}
                            </BoxTitle>
                            {userIsCompanyAdmin && (
                                <EditButton
                                    handleClick={profileSections.services.edit}
                                    invDefaultColors={true}
                                    circleDiameter={BTN_SIZE}
                                    fontSize={BTN_FONT_SIZE}
                                />
                            )}
                        </HeaderBox>
                        <SectionContent>
                            <ServiceContentWrapper>
                                <ServicesList services={services} />
                            </ServiceContentWrapper>
                        </SectionContent>
                    </SectionBox>
                </SectionBoxWrapper>
            )}

            {profileSections.locations.has && (
                <CreateViewPositioning createView={createView}>
                    <SectionBoxWrapper>
                        <SectionBox>
                            <HeaderBox>
                                <BoxTitle>
                                    {/* <HeaderIcon className="fas fa-map-marker-alt"></HeaderIcon> */}
                                    <LocationIcon marginRight={"1rem"} />
                                    {profileSections.locations.title}
                                </BoxTitle>
                                {userIsCompanyAdmin && (
                                    <EditButton
                                        handleClick={
                                            profileSections.locations.edit
                                        }
                                        invDefaultColors={true}
                                        circleDiameter={BTN_SIZE}
                                        fontSize={BTN_FONT_SIZE}
                                    />
                                )}
                            </HeaderBox>
                            <MapContentWrapper>
                                <Map
                                    addMarkersToMap={addMarkersToMap}
                                    locationsArray={officeLocations}
                                    idIndex="One"
                                />
                            </MapContentWrapper>
                        </SectionBox>
                    </SectionBoxWrapper>
                </CreateViewPositioning>
            )}
            {!profileSections.about.has &&
                !profileSections.services.has &&
                !profileSections.locations.has && (
                    <NoDetailsScreen
                        icon={<PasteIcon />}
                        message={"No profile details yet"}
                    />
                )}
        </Wrapper>
    );
};

export default AboutView;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 5rem;
`;

// const HeaderIcon = styled.i`
//     margin-right: 1rem;
// `;

// Only takes effect when in create company view
// Add larger space between section and header when the map is the only thing on the company profile
const CreateViewPositioning = styled.div`
    padding-top: ${(props) => (props.createView ? "2rem" : "0rem")};
`;

const AboutUsTxt = styled.p`
    white-space: pre-wrap;
`;

const ServiceContentWrapper = styled.div`
    padding: 0 0 0 0.5rem;
    /* accounts for the face that their is normally extra white space on the right */
    /* display: flex;
    align-items: center;
    justify-content: center; */
`;

const MapContentWrapper = styled.div`
    position: relative;
    height: 500px;
`;
