import styled, { withTheme } from "styled-components";

import AccountRow from "./AccountRow";
import OptionsButton from "./OptionsButton";

import Button from "../../Button";
import Icon from "../../Icon";
import HeaderBar from "../../PopupHeaderBar";
import PopupLoadingScreen from "../../PopupLoadingScreen";
import SearchBox from "../../SearchBox";
import SearchBoxWithToggler from "../../SearchBoxWithToggler";

import {
    USER_SEARCH,
    COMPANY_SEARCH,
} from "../../constants";

import { ContentBox, FooterBar } from "../../../../resources/styles";

const EditAccountsView = ({
    theme,
    // popup props
    popupLoading,
    popupTitle,
    customExitHandling,
    handleCancel,
    // searchBox props
    companiesIncluded,
    excludedResults,
    searchBoxFocus,
    handleClickOutsideSearchBox,
    handleClickInsideSearchBox,
    handleSelect,
    // list, title, and options button props
    accounts = [],
    isAddView,
    listTitle,
    optionsButtonProps,
    optionsButtonVisible,
    // footer button props
    addBtnOptions = {},
    addButtonVisible,
    confirmText,
    handleConfirmClick,
    handleAddClick,
}) => {


    const searchBox = companiesIncluded ? (
        <SearchBoxWithToggler
            searchTypes={[USER_SEARCH, COMPANY_SEARCH]}
            defaultSearchType={USER_SEARCH}
            handleSelect={handleSelect}
            togglerMinWidth="135px"
            singleRowView={true}
            dropdownMaxHeight="200px"
            excludedResults={{ excludedResults }}
            autoFocus={true}
        />
    ) : (
        <SearchBox
            dropdownMaxHeight="200px"
            searchApiRequest={USER_SEARCH}
            addResultToParentState={handleSelect}
            borderRadius="0.8rem"
            clearInputOnSelect={true}
            searchDescription={"Search Users"}
            clearSearchOnOutsideClick={true}
            reqBody={{ excludedResults }}
            autoFocus={true}
        />
    );

    const accountList = accounts.map((props, i) => <AccountRow key={i} {...props} />);

    const optionsButton = optionsButtonVisible ? <OptionsButton {...optionsButtonProps} /> : null;
    const titleRow = (listTitle || optionsButtonVisible) ?
        <TitleRow>
            <SectionTitle>{listTitle || "Accounts"}</SectionTitle>
            {optionsButton}
        </TitleRow> :
        null;

    const {
        addText = "Add", // optional text in add button
        addIcon = <Icon name="user-plus" />,
    } = addBtnOptions;

    const footerBtnRow = (
        <FooterBar>
            <FooterBtnBlock>
                <LeftBtnWrapper>
                    {addButtonVisible && (
                        <Button
                            handleClick={handleAddClick}
                            children={addText}
                            icon={addIcon}
                            secondaryBtnColors={true}
                            iconColor={theme.colors.accent}
                            fontWeight={500}
                        />
                    )}
                </LeftBtnWrapper>
                <RightBtnWrapper>
                    <Button
                        children={confirmText}
                        handleClick={handleConfirmClick}
                        primaryBtnColors={true}
                        fontWeight={500}
                    />
                </RightBtnWrapper>
            </FooterBtnBlock>
        </FooterBar>
    );

    if (popupLoading) {
        return <PopupLoadingScreen maxHeight={"500px"} maxWidth={"600px"} />;
    } else {
        return (
            <Wrapper>
                <HeaderBar
                    popupTitle={popupTitle}
                    customExitHandling={customExitHandling || handleCancel}
                />
                <ContentBox>
                    <ContentWrapper
                        searchBoxFocus={searchBoxFocus}
                        onClick={handleClickOutsideSearchBox}
                    >
                        {searchBoxFocus && <DisableClicksInGreyBox show={searchBoxFocus} />}
                        {isAddView && (
                            <AddTagSection>
                                <AddTitleRow>
                                    <SectionTitle>Add</SectionTitle>
                                </AddTitleRow>
                                <SearchWrapper onClick={handleClickInsideSearchBox}>
                                    {searchBox}
                                </SearchWrapper>
                            </AddTagSection>
                        )}
                        {accountList.length > 0 && (
                            <TagCol>
                                {titleRow}
                                {accountList}
                            </TagCol>
                        )}
                    </ContentWrapper>
                </ContentBox>
                {footerBtnRow}
            </Wrapper>
        );
    }
};

export default withTheme(EditAccountsView);

const Wrapper = styled.div`
    width: min(600px, 95vw);
    min-height: 350px;
`;

const ContentWrapper = styled.div`
    padding: 0.8rem 1.5rem 0.2rem 1.5rem;
    min-height: 300px;
    position: relative;
    background-color: ${({ theme, searchBoxFocus }) =>
        searchBoxFocus ? `${theme.colors.secondary}10` : `inherit`};
    @media (max-width: ${({ theme }) => theme.sizes.mdViewWidth}) {
        padding: 0.5rem 0.7rem;
    }
`;

const DisableClicksInGreyBox = styled.div`
    position: absolute;
    z-index: 3;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding-bottom: -3rem;
`;

const TagCol = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 0;
    position: relative;
    z-index: 0;
`;

const TitleRow = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 0.3rem;
    min-height: 2.3rem;
`;

const AddTitleRow = styled(TitleRow)`
    min-height: 0;
`;

const SectionTitle = styled.h6`
    margin: 0;
    padding-right: 1rem;
    font-weight: 600;
    /* padding-bottom: 1rem; */
`;

const AddTagSection = styled.div`
    padding: 0rem 0 1.5rem 0;
`;

const SearchWrapper = styled.div`
    padding-top: 0.3rem;
    position: relative;
    z-index: 3;
`;

const FooterBtnBlock = styled.div`
    display: flex;
    width: 100%; /* Match width of parent  */
    padding: 0 1rem;
    justify-content: flex-end;
`;

const LeftBtnWrapper = styled.div`
    flex: 0 1 200px;
    margin-right: 1rem;
`;

const RightBtnWrapper = styled.div`
    flex: 0 1 200px;
`;

// const BtnText = styled.div`
//     font-weight: 500;
// `;
