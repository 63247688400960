import { SET_CURRENT_USER } from "../actionTypes";

const DEFAULT_STATE = {
    isAuthenticated: false, // true when logged in
    user: {}, // all the user info when logged in
};

const userReducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case SET_CURRENT_USER:
            // sets current user with action.data. Add fields to currentUser redux state
            const user = action.data.user || {};
            return {
                isAuthenticated: !!Object.keys(user).length,
                user: user,
            };
        default:
            return state; // initialisation
    }
};

export default userReducer;
