import { useState } from "react";

import UserListView from "./UserList-view";
import ErrorPopup from "../popups/ErrorPopup";
import { USER_SEARCH } from "../constants";

import { useApi } from "../../../services/api";

// can pass in otherSearchOptions to modify the search
// currently available options available are: { currentCompany: companyId }
// TODO - need to test connection button
// TODO - need to test error handling again
const UserList = ({
    currentUser, // index
    history, // index
    otherSearchOptions = {},
    maxResults = 5,
    ViewComp = UserListView,
    handleCardClickCustom = () => { },
    ...viewCompOptions
    // *** optional params for view component ***
    // allowConnect,
    // allowSearch,
    // searchPlaceholder,
    // noResultsText,
    // avatarSize,
    // nameTextSize,
}) => {
    // error handling
    // if string is not empty, "something went wrong" popup is displayed
    const [serverError, setServerError] = useState("");

    const [searchResults, setSearchResults] = useState([]);
    const [canLoadMore, setCanLoadMore] = useState(false);
    const [searchUrl, setSearchUrl] = useState(USER_SEARCH.apiRequestPath);
    const [searchString, setSearchString] = useState("");

    // add api response data to searchResults, filtering out any duplicates
    // then turn off canLoadMore if the data did not hit maxResults
    const callback = (newResults) => {
        setSearchResults(existingResults => [
            ...existingResults,
            ...newResults.filter((newEl) =>
                existingResults.every((existingEl) => newEl._id !== existingEl._id)
            ),
        ]);
        setCanLoadMore(newResults.length === maxResults);
    };

    const defaultApiOptions = {
        callback,
        errorCallback: setServerError,
        method: USER_SEARCH.apiRequestType,
        url: USER_SEARCH.apiRequestPath,
        data: { maxResults, ...otherSearchOptions, },
    };

    // initialise useApi hook for main search function
    const {
        isLoading: isSearching,
        refresh: refreshSearch,
    } = useApi(defaultApiOptions);

    // initialise useApi hook for connection status updates
    const { refresh: updateConxStatus } = useApi();

    // updates the search string when the input is changed
    const handleChange = (e) => {
        setSearchString(e.target.value);
        const url =
            e.target.value.length > 0
                ? `${USER_SEARCH.apiRequestPath}` +
                `?search_field=${USER_SEARCH.searchField}` +
                `&search=${e.target.value}`
                : USER_SEARCH.apiRequestPath;
        setSearchUrl(url);

        const timeoutId = setTimeout(() => {
            setSearchResults([]);
            refreshSearch({ url });
        }, 200);

        return () => clearTimeout(timeoutId);
    };

    const handleLoadMore = () => {
        refreshSearch({
            url: searchUrl,
            data: {
                ...defaultApiOptions.data,
                excludedResults: searchResults.map((result) => result._id),
            },
        });
    };

    // redirects to a user's profile page when their card is clicked
    const handleCardClick = (userId) => {
        handleCardClickCustom();
        history.push(`/users/${userId}`);
    };

    // send update connection request with the clicked user's id
    // and the connection action object {route, STATUS_RESULT}
    // also update connection status in displayedResults state
    const handleConxAction = (e, userId, actionObj) => {
        e.stopPropagation();

        updateConxStatus({
            errorCallback: setServerError,
            method: "put",
            url:
                `/api/users/${currentUser.user.id}` +
                `/${actionObj.route}?id=${userId}`,
        });

        setSearchResults((f) => {
            return f.map((result) => {
                return result._id === userId
                    ? {
                        ...result,
                        conxStatus: actionObj.STATUS_RESULT,
                    }
                    : result;
            });
        });
    };

    // define props for view component
    const viewCompProps = {
        ...viewCompOptions,
        currentUser,
        searchResults,
        handleChange,
        handleCardClick,
        handleConxAction,
        handleLoadMore,
        canLoadMore,
        isSearching,
        searchString,
    };

    return (
        <div>
            <ViewComp {...viewCompProps} />
            {serverError && ( // Server error popup
                <ErrorPopup
                    removeError={() => setServerError("")}
                    customExitHandling={() => setServerError("")}
                />
            )}
        </div>
    );
};

export default UserList;
