import styled from "styled-components";
import { lighten, darken, getContrast } from "polished";

// Circle edit button with a logo in the middle
const ButtonCircleIconContainer = ({
    handleClick, // optional
    iconColor, // optional default primary
    bgColor, // optional default secondary
    circleDiameter,
    fontSize, // optional default inherit
    borderColor, // optional
    secondaryBtnColors, // optional color scheme
    theme, // index
    shadow, // optional if true button will have shadow
    showPointer, // optional if true circle will use pointer, if falsy then a check for handleClick is used
    children,
}) => {
    // if a color is specified use that, if not use secondaryBtnColor if true or default
    /// Note could not uses the or operator notation (iconColor || secondaryBtnColors etc), causing no value to be given for iconColor.
    iconColor =
        iconColor ||
        (secondaryBtnColors ? theme.colors.secondary : theme.colors.primary);
    bgColor =
        bgColor ||
        (secondaryBtnColors ? theme.colors.primary : theme.colors.secondary);
    borderColor =
        borderColor ||
        (secondaryBtnColors ? lighten(0.7, theme.colors.secondary) : bgColor);

    return (
        <Circle
            onClick={handleClick ? handleClick : () => {}}
            bgColor={bgColor}
            iconColor={iconColor}
            borderColor={borderColor}
            circleDiameter={circleDiameter}
            fontSize={fontSize}
            shadow={shadow}
            hasClickHandler={handleClick ? true : false}
            showPointer={showPointer}
        >
            {children}
        </Circle>
    );
};

export default ButtonCircleIconContainer;

const Circle = styled.div`
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${({ circleDiameter }) => circleDiameter};
    height: ${({ circleDiameter }) => circleDiameter};
    min-width: ${({ circleDiameter }) =>
        circleDiameter}; /* Stop circle shrinking*/
    min-height: ${({ circleDiameter }) => circleDiameter};
    font-size: ${({ fontSize }) => (fontSize ? fontSize : "inherit")};
    color: ${({ iconColor }) => iconColor};
    background-color: ${({ bgColor }) => bgColor};
    cursor: ${({ hasClickHandler, showPointer }) =>
        showPointer ? "pointer" : hasClickHandler ? "pointer" : "auto"};
    border: 1px solid ${(props) => props.borderColor};
    box-shadow: ${(props) =>
        props.shadow ? "2px 2px 5px rgba(150, 150, 150, 0.3)" : "none"};
    &:hover {
        color: ${(
            { iconColor } // lightens for dark colors and darkens for light colors
        ) =>
            getContrast(iconColor, "#ffffff") >= 10
                ? lighten(0.2, iconColor)
                : darken(0.2, iconColor)};
        background-color: ${(
            { bgColor } // lightens for dark colors and darkens for light colors
        ) =>
            getContrast(bgColor, "#ffffff") >= 10
                ? lighten(0.05, bgColor)
                : darken(0.02, bgColor)};
        border: 1px solid
            ${({ borderColor }) =>
                getContrast(borderColor, "#ffffff") >= 10
                    ? lighten(0.05, borderColor)
                    : darken(0.03, borderColor)};
    }

    /* User clicking down on button */
    &:active {
        box-shadow: none;
        color: ${({ iconColor }) =>
            getContrast(iconColor, "#ffffff") >= 10
                ? lighten(0.3, iconColor)
                : darken(0.3, iconColor)};
        background-color: ${({ bgColor }) =>
            getContrast(bgColor, "#ffffff") >= 10
                ? lighten(0.1, bgColor)
                : darken(0.15, bgColor)};
        border: 1px solid
            ${({ borderColor }) =>
                getContrast(borderColor, "#ffffff") >= 10
                    ? lighten(0.1, borderColor)
                    : darken(0.15, borderColor)};
    }
`;
