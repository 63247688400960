import { Link } from "react-router-dom";
import styled from "styled-components";

import SearchBox from "../../library/common/SearchBox";
import {
    USER_SEARCH,
    COMPANY_SEARCH,
    SERVICE_SEARCH,
    POST_POPUP_TYPES,
    SUPER_USER_POPUP_TYPES,
} from "../../library/common/constants";
import CreateOrEditPostForm from "../CreatePostForm";
// import NotificationsContainer from "./NotificationsHomepage";
import InputGroup from "../../library/common/InputGroup";
import { INPUT_TYPES, SITE_SEARCH } from "../../library/common/constants";
import ConfirmationScreen from "../../library/common/ConfirmationScreen";
import withPopUp from "../../library/hoc/WithPopUp";
import withRemoveErrorOnUnmount from "../../library/hoc/WithRemoveErrorOnUnmount";
const ConfirmationPopup = withRemoveErrorOnUnmount(
    withPopUp(ConfirmationScreen)
);

const UserHomepage = ({
    currentUser,
    errors,
    setPopUpToDisplay,
    popUpToDisplay,
    testFunction,
    theme,
    selectSiteToDelete,
    handleHardDeleteSite,
    siteToDelete,
    popupLoading,
}) => {
    return (
        <Wrapper>
            <PageTitle>Dev Dashboard</PageTitle>

            <div className="row justify-content-around">
                <div
                    className="
                        col-xs-12
                        col-lg-6
                        text-center"
                >
                    <Link to="/sites" className="btn btn-info my-2 d-block">
                        Create New Site
                    </Link>
                    <Link
                        to="/milestone/"
                        className="btn btn-dark my-2 d-block"
                    >
                        Create Milestone
                    </Link>
                    <Link
                        to="/companies"
                        className="btn btn-danger my-2 d-block"
                    >
                        Create Company
                    </Link>
                    <button
                        className="btn btn-primary my-2 btn-block"
                        onClick={() => setPopUpToDisplay("createPost")}
                    >
                        Create Post
                    </button>

                    <br />
                    <br />

                    <Link
                        to="/sites/my_sites"
                        className="btn btn-info my-2 d-block"
                    >
                        Your Sites
                    </Link>
                    <Link
                        to="/milestone/milestone_list"
                        className="btn btn-dark my-2 d-block"
                    >
                        Your Milestones
                    </Link>
                    <Link
                        to="/companies/all"
                        className="btn btn-danger my-2 d-block"
                    >
                        All Companies
                    </Link>
                    <Link
                        to="/posts/all"
                        className="btn btn-primary my-2 d-block"
                    >
                        All Posts
                    </Link>
                    <DeleteSiteWrapper>
                        <InputGroup
                            type={INPUT_TYPES.SEARCH_TEXT}
                            placeholder={`Site name`}
                            title={"Hard Delete Site"}
                            handleChange={selectSiteToDelete}
                            description={`Hits a Super user route. The site selected will be fully deleted with all data erased. There is a confirmation popup to make sure you get the right one.`}
                            searchBoxProps={{
                                searchApiRequest: SITE_SEARCH,
                                clearOnSelect: true,
                                valueCanBeCustomString: false,
                                clearSearchOnOutsideClick: true,
                            }}
                        />
                    </DeleteSiteWrapper>
                </div>

                {/* <div
                            className="
                        col-xs-12
                        col-lg-8
                        text-center"
                        >
                            <NewsFeed />
                        </div> */}

                <div
                    className="
                        col-xs-12
                        col-lg-6
                        text-center"
                >
                    <SearchBox
                        searchDescription="Search for a user"
                        searchApiRequest={USER_SEARCH}
                        addResultToParentState={(result) => {
                            console.log(
                                `id: ${result.id}, name: ${result.name}`
                            );
                        }}
                        clearInputOnSelect={true}
                        noResultsText="No users were found."
                    />

                    <Link
                        to="/users/all"
                        className="btn btn-success my-2 d-block"
                    >
                        Browse Users
                    </Link>

                    <SearchBox
                        searchDescription="Search for a company"
                        searchApiRequest={COMPANY_SEARCH}
                        addResultToParentState={(result) => {
                            console.log(
                                `id: ${result.id}, name: ${result.name}, industry: ${result.cat}`
                            );
                        }}
                        clearInputOnSelect={false}
                    />

                    <SearchBox
                        searchDescription="Search for a service"
                        searchApiRequest={SERVICE_SEARCH}
                        addResultToParentState={(result) => {
                            console.log(
                                `id: ${result.id}, name: ${result.name}, industry: ${result.cat}`
                            );
                        }}
                        clearInputOnSelect={true}
                    />

                    <br />
                    <br />

                    <button
                        className="btn btn-success my-2 btn-block"
                        onClick={() => testFunction()}
                    >
                        Generate notification for testing
                    </button>

                    {/* <NotificationsContainer currentUser={currentUser} /> */}
                </div>
            </div>
            {popUpToDisplay === "createPost" && (
                <CreateOrEditPostForm
                    popUpToDisplay={POST_POPUP_TYPES.createOrEditPost}
                    setPopUpToDisplay={setPopUpToDisplay}
                />
            )}
            {popUpToDisplay === SUPER_USER_POPUP_TYPES.hardDeleteSite && (
                <ConfirmationPopup
                    handleCancel={() => {
                        setPopUpToDisplay("");
                    }}
                    cancelText={"Edit"}
                    theme={theme}
                    handleSubmit={handleHardDeleteSite}
                    submitText={"Delete Site!"}
                    title={"Delete Site"}
                    loading={popupLoading}
                    setPopUpToDisplay={setPopUpToDisplay}
                    message={`You are about to delete - "${siteToDelete.name}" - are you sure...`}
                    maxWidth={"500px"}
                />
            )}
        </Wrapper>
    );
};

export default UserHomepage;

const Wrapper = styled.div`
    padding: 1rem;
    background-color: ${(props) => props.theme.colors.primary};
    height: calc(100vh - ${({ theme }) => theme.sizes.navHeight});
`;

const PageTitle = styled.h1`
    margin: 0;
    padding: 0 0 1rem 0;
    color: ${(props) => props.theme.colors.secondary};
`;

const DeleteSiteWrapper = styled.div`
    position: relative;
    z-index: 0;
    padding: 1rem 0 0 1rem;
`;
