import {
    SelectAuthorScreen,
    ImageUploadScreen,
    TextInputScreen,
    PostPreviewScreen,
    AddSiteScreen,
} from "./FormViews";

import ConfirmationScreen from "../../library/common/ConfirmationScreen";
import PopupLoadingScreen from "../../library/common/PopupLoadingScreen";
import TagsPopup from "../../library/common/popups/AddOrRemoveAccounts";

import { POST_POPUP_TYPES } from "../../library/common/constants";

const CreatePostView = ({
    popupLoading,
    popupScreenToDisplay,
    selectAuthorViewProps,
    imageUploadViewProps,
    textInputViewProps,
    postPreviewViewProps,
    addSiteViewProps,
    addTagsViewProps,
    exitConfirmationViewProps,
}) => {

    if (popupLoading) {
        return <PopupLoadingScreen />;
    } else {
        switch (popupScreenToDisplay) {

            case POST_POPUP_TYPES.SELECT_AUTHOR:
                return <SelectAuthorScreen {...selectAuthorViewProps} />;

            case POST_POPUP_TYPES.IMAGE_UPLOAD:
                return <ImageUploadScreen {...imageUploadViewProps} />;

            case POST_POPUP_TYPES.TEXT_INPUT:
                return <TextInputScreen {...textInputViewProps} />;

            case POST_POPUP_TYPES.CREATE_POST:
                return <PostPreviewScreen {...postPreviewViewProps} />;

            case POST_POPUP_TYPES.ADD_SITE:
                return <AddSiteScreen {...addSiteViewProps} />;

            case POST_POPUP_TYPES.ADD_TAGS:
                return <TagsPopup {...addTagsViewProps} />;

            case POST_POPUP_TYPES.EXIT_CONFIRMATION:
                return <ConfirmationScreen {...exitConfirmationViewProps} />;

            default:
                return null;
        }
    }
};

export default CreatePostView;