import { useEffect, useState } from "react";

import { useApi } from "../../services/api";
// import { useAuth0 } from "@auth0/auth0-react";
// import { sendApiRequest } from "../../services/api";
// import { addError } from "../../main/store/actions/errors";
// import { setNewsFeed } from "../../main/store/actions/newsFeed";
import NewsFeedView from "./NewsFeed-view";

const NewsFeed = ({
    currentUser,
    history,
    newsFeed,
    updateFeed,
    theme,
    addError,
    removeError,
    errors,
}) => {
    // const [newsFeed, setNewsFeed] = useState([]);

    const [canLoadMore, setCanLoadMore] = useState(false);
    // const [isLoading, setIsLoading] = useState(false);
    // const { getAccessTokenSilently } = useAuth0();

    const [serverError, setServerError] = useState("");

    const { data, isLoading, refresh } = useApi({
        method: "post",
        url: `/api/users/${currentUser.user.id}/feed`,
        errorCallback: setServerError,
    });

    useEffect(() => {
        // If there's data, process it
        if (data) {
            updateFeed(data);
            setCanLoadMore(data.length > 0);
        }
    }, [data, updateFeed]);

    const handleLoadMore = () => {
        if (!isLoading && canLoadMore) {
            // Stops refresh firing due to scroll event before data has been loaded AND if no more items to load
            refresh({
                method: "post",
                url: `/api/users/${currentUser.user.id}/feed`,
                data: {
                    latestDate:
                        newsFeed.posts[newsFeed.posts.length - 1].createdAt,
                },
            });
        }
    };

    // // gets the news feed on first load
    // useEffect(() => {
    //     (async () => {
    //         try {
    //             if (
    //                 newsFeed.posts.length === 0 ||
    //                 newsFeed.date < new Date() - 1 * 60 * 1000 // One minute
    //             ) {
    //                 setIsLoading(true);
    //                 console.log("fetching news feed");
    //                 const newsFeedItems = await sendApiRequest(
    //                     getAccessTokenSilently,
    //                     {
    //                         method: "post",
    //                         url: `/api/users/${currentUser.user.id}/feed`,
    //                     }
    //                 );

    //                 const sortedNewsFeed = newsFeedItems.sort(
    //                     (
    //                         a,
    //                         b // sort in time added order, newest first
    //                     ) =>
    //                         a.createdAt > b.createdAt
    //                             ? -1
    //                             : a.createdAt > b.createdAt
    //                             ? 1
    //                             : 0
    //                 );

    //                 setNewsFeed(sortedNewsFeed);
    //                 if (sortedNewsFeed.length === 0) {
    //                     setCanLoadMore(false);
    //                 }
    //                 console.log("sortedNewsFeed");
    //                 console.log(sortedNewsFeed);
    //                 setIsLoading(false);
    //             }
    //         } catch (error) {
    //             console.log("Error useEffect NewsFeed container ");
    //             addError(error.message);
    //             setIsLoading(false);
    //         }
    //     })();
    // }, [currentUser, addError, newsFeed, setNewsFeed, getAccessTokenSilently]);

    // // gets more results from the news feed
    // const handleLoadMore = async () => {
    //     try {
    //         setIsLoading(true);
    //         const newsFeedItems = await sendApiRequest(getAccessTokenSilently, {
    //             method: "post",
    //             url: `/api/users/${currentUser.user.id}/feed`,
    //             data: {
    //                 latestDate:
    //                     newsFeed.posts[newsFeed.posts.length - 1].createdAt,
    //             },
    //         });
    //         setNewsFeed((f) => {
    //             return [...f, ...newsFeedItems];
    //         });
    //         if (newsFeedItems.length === 0) {
    //             setCanLoadMore(false);
    //         }
    //         setIsLoading(false);
    //     } catch (error) {
    //         addError(error.message);
    //         setIsLoading(false);
    //     }
    // };

    return (
        <NewsFeedView
            newsFeed={newsFeed}
            canLoadMore={canLoadMore}
            handleLoadMore={handleLoadMore}
            isLoading={isLoading}
            theme={theme}
            history={history}
            errors={errors}
            removeError={() => setServerError("")}
            serverError={serverError}
        />
    );
};

export default NewsFeed;
