import React from "react";
import styled from "styled-components";

const HeaderPhones = () => {
    return (
        <Wrapper>
            <Phone
                rank={1}
                src={
                    "https://cosqos-bucket.s3.ap-southeast-2.amazonaws.com/DarenPostPageNoNav.png"
                }
            />
            <Phone
                rank={2}
                src={
                    "https://cosqos-bucket.s3.ap-southeast-2.amazonaws.com/DarenProfilePageNoNav.png"
                }
            />
            <Phone
                rank={3}
                src={
                    "https://cosqos-bucket.s3.ap-southeast-2.amazonaws.com/3ProfieBrayden.png"
                }
            />
            <Phone
                rank={4}
                src={
                    "https://cosqos-bucket.s3.ap-southeast-2.amazonaws.com/PhoneMapWithPins.png"
                }
            />
        </Wrapper>
    );
};

const LgScreenPhoneHeight = "380px";
const MdScreenPhoneHeight = "340px";
const SmScreenPhoneHeight = "380px";
const XsScreenPhoneHeight = "280px";
const MdLayoutBreakPoint = "1300px";
const SmLayoutBreakPoint = "900px";
const XsLayoutBreakPoint = "650px";

const Wrapper = styled.div`
    width: 420px;
    /* border: 1px solid pink; */
    height: ${LgScreenPhoneHeight};
    position: relative;
    @media (max-width: ${MdLayoutBreakPoint}) {
        width: 340px;
        height: ${MdScreenPhoneHeight};
    }
    @media (max-width: ${SmLayoutBreakPoint}) {
        width: 545px;
        height: ${SmScreenPhoneHeight};
    }
    @media (max-width: ${XsLayoutBreakPoint}) {
        width: 310px;
        height: ${XsScreenPhoneHeight};
    }
`;

const Phone = styled.img`
    height: ${LgScreenPhoneHeight};
    position: absolute;
    right: ${(props) => (props.rank - 1) * 80}px;
    z-index: ${(props) => 4 - props.rank};
    @media (max-width: ${MdLayoutBreakPoint}) {
        height: ${MdScreenPhoneHeight};
        right: ${(props) => (props.rank - 1) * 60}px;
    }
    @media (max-width: ${SmLayoutBreakPoint}) {
        height: ${SmScreenPhoneHeight};
        right: ${(props) => (props.rank - 1) * 120}px;
    }
    @media (max-width: ${XsLayoutBreakPoint}) {
        height: ${XsScreenPhoneHeight};
        right: ${(props) => (props.rank - 1) * 60}px;
    }
`;

export default HeaderPhones;
