import { useState, useRef, useCallback, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { sendApiRequest } from "../../../../services/api";

import AddressList from "./AddressList-view";
import AddressEdit from "./AddressEdit-view";
import { checkAndLoadMapsAPI } from "../../../utilities";
import { getAddress, getLatLng } from "../../../utilities";
import { CHRISTCHURCH_COORDS } from "../../constants";

// Component for editing address fields where location objects are stored in an array. Can render list of addresses with edit and delete buttons OR a location input when user hits edit on item in list or adds new address to list.
const ImageEditContainer = ({
    popupTitle,
    addressList,
    addError,
    errors,
    setPopUpToDisplay,
    setParentState,
    serverPath,
    addressFieldName,
    setLoading,
    loading,
    LoadingScreen,
}) => {
    // Map position must be defined and mapsAPILoading before rendering InputMap
    const [mapPositionLoading, setMapPositionLoading] = useState(true);
    const [mapsAPILoading, setMapsAPILoading] = useState(true);

    const [submitFailed, setSubmitFailed] = useState(false);

    const { getAccessTokenSilently } = useAuth0();

    // On mount checks for maps API script tag, if not loaded then loads it and setMapsAPILoading to false
    useEffect(() => {
        checkAndLoadMapsAPI(setMapsAPILoading);
        setLoading(false);
    }, [setLoading]);

    // State used to store location list
    const [formState, setFormState] = useState([...addressList]);

    // Defines index of location being changed when user hits edit or delete buttons
    const [locationIndex, setLocationIndex] = useState(undefined);

    // store initialMapPosition in a ref so it can be defined in UseEffect when user clicks edit button
    const initialMapPosition = useRef();

    // Defines initialMapPosition based off location being edited
    // If index not in formState then uses default coordinates
    // useEffect(() => {
    //     if (locationIndex !== undefined) {
    //         initialMapPosition.current = formState[locationIndex]
    //             ? formState[locationIndex].latLng
    //             : CHRISTCHURCH_COORDS;
    //         setMapPositionLoading(false); // Triggers render of InputMap if APILoaded
    //     }
    // }, [locationIndex, addressList, formState]);

    // Handles add or edit button click
    // If index is not in locations array then AddressEdit will create a new location
    const addOrEditLocation = (index) => {
        if (index !== undefined) {
            initialMapPosition.current = formState[index]
                ? formState[index].latLng
                : CHRISTCHURCH_COORDS;
            setMapPositionLoading(false); // Triggers render of InputMap if APILoaded
        }
        setLocationIndex(index);
        console.log(`index: ${index}`);
    };

    // Used inside InputMap to update parent state on user location click
    const mapUpdateLocation = useCallback(
        (latLng) => {
            getAddress(latLng, (address, latLng) => {
                setFormState((f) => {
                    let updatedList = [...f];
                    updatedList[locationIndex] = {
                        address: address,
                        latLng: latLng,
                    };
                    return [...updatedList];
                });
            });
        },
        [locationIndex]
    );

    // Used inside Autocomplete component to update parents state on location change
    const autofillUpdateLocation = useCallback(
        (address) => {
            getLatLng(address, (latLng, address) => {
                setFormState((f) => {
                    let updatedList = [...f];
                    updatedList[locationIndex] = {
                        address: address,
                        latLng: latLng,
                    };
                    return [...updatedList];
                });
            });
        },
        [locationIndex]
    );

    // Send changes made to server, update parent state and return to list of addresses
    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setLoading(true); // loading screen
            if (
                !formState[locationIndex] ||
                !formState[locationIndex].address
            ) {
                // Check for address before sending to server
                setSubmitFailed(true);
            } else {
                await sendToServer(formState);
                // Update parent state after API request finished
                setParentState((f) => {
                    return {
                        ...f,
                        OfficeLocations: formState,
                    };
                });
                setLocationIndex(undefined); // Back to AddressList screen of popup
            }
            setLoading(false); // Remove loading screen
        } catch (error) {
            handleError(error);
        }
    };

    // Check for valid inputs used to clear error after user fixes mistakes in their form
    useEffect(() => {
        // User has to have sent a fail submission for this to run
        if (
            submitFailed &&
            formState[locationIndex] &&
            formState[locationIndex].address
        ) {
            setSubmitFailed(false);
        }
    }, [submitFailed, formState, locationIndex]);

    // clears validation error if user clicks back or cancel from AddressEdit-view
    useEffect(() => {
        setSubmitFailed(false);
    }, [locationIndex]);

    // Handles delete logic if user hits delete from location list
    const handleDelete = async (i) => {
        try {
            setLoading(true);
            const updatedList = filterIndexItemFromArray(i, [...formState]);
            await sendToServer(updatedList);
            // update State with post delete state
            setFormState(updatedList);
            // Keep parent state up to date
            setParentState((f) => {
                return {
                    ...f,
                    OfficeLocations: updatedList,
                };
            });
            setLoading(false); // Remove loading screen
        } catch (error) {
            handleError(error);
        }
    };

    // Sends data to server, creates  key and value pair to send the data in so server knows what field to update
    const sendToServer = async (dataToSend) => {
        const formStateForServer = {};
        formStateForServer[addressFieldName] = dataToSend;
        // Send to server
        await sendApiRequest(
            getAccessTokenSilently,
            {
                method: "put",
                url: serverPath,
                data: formStateForServer,
            }
        );
    };

    // Handles catch logic in handleSave and handleDelete
    const handleError = (error) => {
        setLoading(false); // Remove loading screen if error caught
        console.log("Save error");
        console.log(error.message);
        addError(error.message);
        throw new Error("error");
    };

    // Filter item with index out of array and return filtered array
    const filterIndexItemFromArray = (index, arr) => {
        return [...arr].filter((location, i) => i !== parseInt(index));
    };

    // Render
    // If locationIndex is falsy and not loading then render list of locations, if truthy and not loading then render single location edit view else loading screen
    if (locationIndex === undefined && !loading) {
        return (
            <AddressList
                popupTitle={popupTitle}
                addressList={formState}
                setPopUpToDisplay={setPopUpToDisplay}
                addOrEditLocation={addOrEditLocation}
                handleDelete={handleDelete}
            />
        );
    } else if (
        locationIndex !== undefined &&
        !mapsAPILoading &&
        !mapPositionLoading &&
        !loading
    ) {
        return (
            <AddressEdit
                popupTitle={popupTitle}
                setLocationIndex={setLocationIndex}
                address={
                    formState[locationIndex]
                        ? formState[locationIndex].address
                        : ""
                }
                latLng={
                    formState[locationIndex]
                        ? formState[locationIndex].latLng
                        : CHRISTCHURCH_COORDS
                }
                mapUpdateLocation={mapUpdateLocation}
                autofillUpdateLocation={autofillUpdateLocation}
                initialMapPosition={initialMapPosition}
                setPopUpToDisplay={setPopUpToDisplay}
                handleSubmit={handleSubmit}
                submitFailed={submitFailed}
            />
        );
    } else {
        return <LoadingScreen />;
    }
};

export default ImageEditContainer;
