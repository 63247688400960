import styled from "styled-components";
import { useState, useRef } from "react";

import { UseOutsideClick } from "../../library/utilities";
import ButtonCircleIcon from "../../library/common/ButtonCircleIcon";
import Button from "../../library/common/Button";

// Delete post button, triggers popover with delete button
const EditCommentButton = ({ theme, handleDelete, loading }) => {
    const [showPopover, setShowPopover] = useState(false);

    const ref = useRef();
    UseOutsideClick(ref, () => {
        setShowPopover(false);
    });

    return (
        <EditPostButtonCol>
            <ButtonCircleIcon
                theme={theme}
                handleClick={() => setShowPopover(true)}
                circleDiameter={"2rem"}
                fontSize={"0.8rem"}
                bgColor={theme.colors.offPrimary}
                iconColor={theme.colors.secondary}
            >
                <i className="fas fa-ellipsis-h"></i>
            </ButtonCircleIcon>
            {showPopover && (
                <EditPopover ref={ref}>
                    <ButtonWrapper>
                        <Button
                            handleClick={handleDelete}
                            icon={<i className="fas fa-minus-circle"></i>}
                            secondaryBtnColors={true}
                            noShadow={true}
                            buttonLoading={loading}
                        >
                            Delete
                        </Button>
                    </ButtonWrapper>
                </EditPopover>
            )}
        </EditPostButtonCol>
    );
};

export default EditCommentButton;

const EditPostButtonCol = styled.div`
    position: relative;
`;

const EditPopover = styled.div`
    position: absolute;
    z-index: 3; /* z-index needs to lift popover above image overlays */
    top: 2.2rem;
    right: 0;
    padding: 0.5rem;
    min-width: 10rem;
    border-radius: 10px;
    background-color: ${(props) => props.theme.colors.primary};
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
`;

const ButtonWrapper = styled.div`
    padding: 0.5rem 0;
`;
