import styled from "styled-components";

import AvatarLineUp from "../../library/common/AvatarLineUp";
import ButtonCircleIcon from "../../library/common/ButtonCircleIcon";
import Button from "../../library/common/Button";
import { CHAT_POPUP_TYPES } from "../../library/common/constants";
import { chatGivenNamesString } from "../../library/utilities";
import Icon from "../../library/common/Icon";

const AVATAR_SIZE = "2.2rem";

// Renders the header bar in chatDetails view
const ChatDetailsHeaderView = ({
    theme,
    chatDetails,
    conversationsLoading,
    handleGoBack,
    setPopUpToDisplay,
}) => {

    const { name = null, isNewChat = false, otherActiveMembers = [] } = chatDetails;

    const smallScreenBackButton =
        <BackButtonWrapper>
            <Button
                handleClick={handleGoBack}
                icon={<Icon name="angle-double-left" />}
                bgColor={theme.colors.secondary}
                textColor={theme.colors.primary}
                iconPadding={"0rem 0.3rem"}
                buttonPadding={"0"}
            />
        </BackButtonWrapper>;

    // Generates JSX for header picture and names
    const memberNames = chatGivenNamesString(otherActiveMembers);
    const chatTitleAndPicture = conversationsLoading ?
        <GroupMembersWrapper>
            <ButtonCircleIcon
                circleDiameter={AVATAR_SIZE}
                bgColor={theme.colors.offPrimary}
            />
            <NameLoading />
        </GroupMembersWrapper> :
        otherActiveMembers.length === 0 ?
            <GroupMembersWrapper>
                <ButtonCircleIcon
                    circleDiameter={"2.6rem"}
                    fontSize={"1.4rem"}
                    bgColor={theme.colors.darkAccent}
                    iconColor={theme.colors.primary}
                >
                    <Icon name="user" />
                </ButtonCircleIcon>
                <ChatName>New Message</ChatName>
            </GroupMembersWrapper> :
            name ?
                <GroupMembersWrapper>
                    <ButtonCircleIcon
                        circleDiameter={"2.6rem"}
                        fontSize={"1.2rem"}
                        bgColor={theme.colors.darkAccent}
                        iconColor={theme.colors.primary}
                    >
                        <Icon name="flag" />
                    </ButtonCircleIcon>
                    <ChatName>{name}</ChatName>
                </GroupMembersWrapper> :
                <GroupMembersWrapper>
                    <AvatarLineUp
                        theme={theme}
                        avatarArray={otherActiveMembers}
                        // handleClick={()} // TODO add popup like the tags one to add members and see members
                        maxAvatars={3}
                        HeaderAvatarSize={AVATAR_SIZE}
                        hideAvatarsWithoutPic={true}
                    />
                    <MemberNamesLineUp>{memberNames}</MemberNamesLineUp>
                </GroupMembersWrapper>;

    const infoButton = (otherActiveMembers.length > 1 || isNewChat) ?
        <ButtonCircleIcon
            circleDiameter={"2.2rem"}
            fontSize={"1rem"}
            bgColor={theme.colors.veryLightAccent}
            iconColor={theme.colors.text.body}
            handleClick={() => {
                setPopUpToDisplay(
                    isNewChat ?
                        CHAT_POPUP_TYPES.createNewChat :
                        CHAT_POPUP_TYPES.chatInfo
                );
            }}
        >
            <Icon name={isNewChat ? "user-plus" : "info"} />
        </ButtonCircleIcon> :
        null;

    return (
        <Header>
            <LeftSideWrapper>
                {smallScreenBackButton}
                {chatTitleAndPicture}
            </LeftSideWrapper>
            <RightSideWrapper>
                {infoButton}
            </RightSideWrapper>
        </Header>
    );
};

export default ChatDetailsHeaderView;

const Header = styled.div`
    height: 5rem;
    box-shadow: 3px 0px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    padding: 0 2rem 0 1rem;
`;

const RightSideWrapper = styled.div``;

const LeftSideWrapper = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    min-width: 0;
`;

const BackButtonWrapper = styled.div`
    display: none;
    @media (max-width: ${({ theme }) => theme.sizes.mdViewWidth}) {
        display: block;
        padding-right: 0.5rem;
    }
`;

const GroupMembersWrapper = styled.div`
    display: flex;
    align-items: center;
    padding-right: 1rem;
    min-width: 0;
`;

const MemberNamesLineUp = styled.div`
    font-size: 1.2rem;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 0.5rem;
`;

const ChatName = styled(MemberNamesLineUp)`
    padding-left: 1rem;
`;

const NameLoading = styled.div`
    margin-left: 1rem;
    height: 1.5rem;
    width: min(50%, 10rem);
    border-radius: 1rem;
    background-color: ${(props) => props.theme.colors.offPrimary};
`;