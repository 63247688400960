import React from "react";
import styled from "styled-components";
import ButtonCircleIcon from "../ButtonCircleIcon";

const ButtonFileUploadCircleView = ({
    handleClick,
    secondaryBtnColors, // optional
    multipleFiles, // optional
    icon,
    iconColor, // optional
    bgColor, // optional
    borderColor, // optional
    circleDiameter,
    fontSize,
    theme, // index
    shadow, // optional
}) => {
    return (
        <FileUpload>
            <ButtonCircleIcon
                bgColor={bgColor}
                iconColor={iconColor}
                borderColor={borderColor}
                secondaryBtnColors={secondaryBtnColors}
                circleDiameter={circleDiameter}
                fontSize={fontSize}
                shadow={shadow}
                showPointer={true}
            >
                {icon}
            </ButtonCircleIcon>
            <ChooseFile
                type="file"
                onChange={handleClick}
                multiple={multipleFiles ? true : false}
            ></ChooseFile>
        </FileUpload>
    );
};

export default ButtonFileUploadCircleView;

const FileUpload = styled.label`
    cursor: pointer;
`;

const ChooseFile = styled.input`
    display: none;
`;
