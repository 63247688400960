import React from "react";
import styled from "styled-components";

const Dots = ({
    baseColor, // optional default secondary,
    focusColor, // optional default secondary
    size, // required will be height and width of each dot
    theme, // index
}) => (
    <DotsWrapper>
        <Dot
            size={size}
            baseColor={baseColor}
            focusColor={focusColor}
            delay="0s"
        ></Dot>
        <Dot
            size={size}
            baseColor={baseColor}
            focusColor={focusColor}
            delay="0.15s"
        ></Dot>
        <Dot
            size={size}
            baseColor={baseColor}
            focusColor={focusColor}
            delay="0.3s"
        ></Dot>
    </DotsWrapper>
);

export default Dots;

const DotsWrapper = styled.div`
    display: flex; /* Shrink wrapper to size of dots on smaller sizes*/
`;

const Dot = styled.div`
    display: inline-block;
    background-color: ${({ theme, baseColor }) =>
        baseColor ? baseColor : theme.colors.offSecondary};
    border-radius: 50%;
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    margin: calc(${(props) => props.size} / 3.3);
    animation: scaleAnimation 1s infinite;
    animation-delay: ${(props) => props.delay};
    @keyframes scaleAnimation {
        0% {
            transform: scale(1, 1);
        }
        33% {
            transform: scale(1.2, 1.2);
            /* background-color: ${(props) => props.theme.colors.primary}; */
            background-color: ${({ theme, focusColor }) =>
                focusColor ? focusColor : theme.colors.secondary};
        }
        66% {
            transform: scale(1, 1);
        }
    }
`;
