import React, { useEffect } from "react";
import styled from "styled-components";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Icon from "../../library/common/Icon";

const phonePosVariant = {
    visible: { x: 0, transition: { duration: 1, type: "tween", delay: 0 } },
    hidden: { x: 100, transition: { duration: 0.2, type: "tween" } },
};

const BuildPhones = () => {
    const control = useAnimation();

    const { ref: topRef, inView: topInView } = useInView();
    const { ref: bottomRef, inView: bottomInView } = useInView();
    useEffect(() => {
        if (topInView && bottomInView) {
            control.start("visible");
        } else if (topInView || bottomInView) {
            return;
        } else {
            control.start("hidden");
        }
    }, [control, topInView, bottomInView]);

    return (
        <ContentBox>
            <ExplanationSection>
                <IconRow>
                    <IconCircle>
                        {/* <Icon name={"id-card"} /> */}
                        {/* <Icon name={"id-badge"} /> */}
                        {/* <Icon name={"user-circle"} /> */}
                        {/* <Icon name={"user-astronaut"} /> */}
                        {/* <Icon name={"layer-group"} /> */}
                        <Icon name={"layer-group"} />
                        {/* <Icon name={"chart-line"} /> */}
                        {/* <Icon name={"chevron-right"} /> */}
                    </IconCircle>
                </IconRow>
                <TitleText>Reputation</TitleText>
                <BodyText>
                    BoomsUp provides the foundation; what you build with your
                    platform is up to you!
                </BodyText>
            </ExplanationSection>
            <PictureSection>
                <DoublePhoneWrapper>
                    <TopOfPhone ref={topRef} />
                    <PhoneBack
                        src={
                            "https://cosqos-bucket.s3.ap-southeast-2.amazonaws.com/DarenProfilePageNoNav.png"
                        }
                        variants={phonePosVariant}
                        initial={"hidden"}
                        animate={control}
                    />
                    <PhoneFront
                        src={
                            "https://cosqos-bucket.s3.ap-southeast-2.amazonaws.com/DarenPostPageNoNav.png"
                        }
                    />
                    <BottomOfPhone ref={bottomRef} />
                </DoublePhoneWrapper>
                {/* <BottomBar /> */}
            </PictureSection>
        </ContentBox>
    );
};

export default BuildPhones;

const phoneSizeBreakPoint = "544px";
const phoneSectionBreak = "900px";

const ContentBox = styled.div`
    display: flex;
    flex-direction: row;
    /* background-color: cyan; */
    justify-content: space-between;

    @media (max-width: ${phoneSectionBreak}) {
        flex-direction: column-reverse;
        align-items: center;
    }
`;

const PictureSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    /* background-color: red; */
`;

const BottomBar = styled.div`
    width: 110%;
    height: 50px;
    background-color: ${(props) => props.theme.colors.justOffSecondary};
    border-radius: 30px;
`;

const Phone = styled(motion.img)`
    width: 280px;
    position: relative;
    @media (max-width: ${phoneSizeBreakPoint}) {
        width: 230px;
    }
`;

const TopOfPhone = styled.div`
    position: absolute;
    top: 0;
`;
const BottomOfPhone = styled.div`
    position: absolute;
    bottom: 0;
`;

const DoublePhoneWrapper = styled.div`
    width: 500px;
    height: 590px;
    /* background-color: blue; */
    /* overflow: hidden; */
    position: relative;
    @media (max-width: ${phoneSizeBreakPoint}) {
        width: 350px;
        height: 480px;
    }
`;

const PhoneFront = styled(Phone)`
    position: absolute;
    z-index: 1;
    right: 0;
`;

const PhoneBack = styled(Phone)`
    position: absolute;
    left: 0;
`;

const ExplanationSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: ${(props) => props.theme.colors.primary};
    /* align-items: center; */
    /* align-items: center; */
    /* padding-left: 80px; */
    /* padding: 30px 0 0 0; */
    @media (max-width: ${phoneSectionBreak}) {
        padding: 30px 25px 20px 25px;
    }
    /* background-color: red; */
`;

const TitleText = styled.div`
    /* display: flex;
    align-items: center;
    text-align: center; */
    /* background-color: cyan; */
    font-family: "Montserrat", sans-serif;
    max-width: 400px;
    font-size: 45px;
    font-weight: 600;
    text-align: center;
    @media (max-width: ${phoneSectionBreak}) {
        text-align: left;
        font-size: 24px;
    }
`;

const BodyText = styled(TitleText)`
    text-align: left;
    max-width: 360px;
    font-size: 15px;
    font-weight: 400;
`;

const IconRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* padding-top: ${(props) => (props.padTop ? "40px" : "0px")}; */
    padding-bottom: 20px;
    @media (max-width: ${phoneSectionBreak}) {
        display: none;
    }
`;

const IconCircle = styled.div`
    color: ${(props) => props.theme.colors.lightAccent};
    background-color: ${(props) => props.theme.colors.secondary};
    /* border: 2px solid ${(props) => props.theme.colors.lightAccent};; */
    /* box-shadow: 10px 5px 40px ${(props) =>
        props.theme.colors.lightAccent}30; */
    box-shadow: 16px 5px 40px #00000030;
    font-size: 95px;
    border-radius: 50%;
    width: 180px;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: ${(props) => props.padBottom || 0};
`;
