import styled, { withTheme } from "styled-components";

import ConnectionButton from "./SiteProfileConnectionButton-view";

import withImgFullScreenClick from "../../library/hoc/withImgFullScreenClick";

import AvatarLineUp from "../../library/common/AvatarLineUp";
import Button from "../../library/common/Button";
import ButtonCircle from "../../library/common/ButtonCircleIcon";
import EditButton from "../../library/common/ButtonEditCircle";
import Icon from "../../library/common/Icon";
import ImgTag from "../../library/common/ImgTagWithLoading";
import LoadingImgShimmer from "../../library/common/LoadingShimmer";

import {
    CONNECTION_STATUS,
    REACT_APP_DEFAULT_COMPANY_COVER,
    REACT_APP_DEFAULT_USER_PROFILE,
    SITE_POPUP_TYPES,
} from "../../library/common/constants";


const CIRCLE_BUTTON_DIAMETER = "2.6rem";
const CONX_BUTTON_SCREEN_BREAK_SIZE = "544px";

const SiteProfileHeaderView = ({
    theme,
    setPopUpToDisplay,
    openFullScreenImgView,
    siteDetails,
    conxActions,
    buttonLoading,
    // rejectButtonLoading,
    // currentUserCrewStatus,
    loading,
}) => {
    if (loading) {
        // **** Site loading header ****
        return (
            <HeaderWrapper>
                <CoverPhotoWrapper>
                    <CoverPhotoAspectRatio>
                        <CoverInnerWrapper noPointer={true}>
                            <LoadingImgShimmer />
                        </CoverInnerWrapper>
                    </CoverPhotoAspectRatio>
                </CoverPhotoWrapper>
                <NameSection>
                    <NameWrapper>
                        <Name>
                            <NameLoading />
                        </Name>
                    </NameWrapper>
                    <UserLineupRow>
                        <AvatarsLoading />
                    </UserLineupRow>
                </NameSection>
            </HeaderWrapper>
        );
    } else {
        // **** Site Header ****
        const crewToShowInAvatarLineUp = siteDetails.crew.filter(
            (member) => member.status === CONNECTION_STATUS.ACCEPTED
        );
        const crewRequestsToAction = siteDetails.crew.filter(
            (member) => member.status === CONNECTION_STATUS.PENDING
        );

        return (
            <HeaderWrapper>
                <CoverPhotoWrapper>
                    <CoverPhotoAspectRatio>
                        <CoverInnerWrapper
                            onClick={() =>
                                openFullScreenImgView(
                                    [siteDetails.coverPhoto],
                                    0
                                )
                            }
                        >
                            <ImgTag
                                src={
                                    siteDetails.coverPhoto ||
                                    REACT_APP_DEFAULT_COMPANY_COVER
                                }
                                objectFit={"cover"}
                            />
                        </CoverInnerWrapper>
                        {siteDetails.userIsAdmin && (
                            <EditCoverPhotoButtonWrapper>
                                <ButtonCircle
                                    handleClick={() => {
                                        setPopUpToDisplay(
                                            SITE_POPUP_TYPES.coverPhoto
                                        );
                                    }}
                                    circleDiameter={`calc(${CIRCLE_BUTTON_DIAMETER}/1.3)`}
                                    fontSize={`calc(${CIRCLE_BUTTON_DIAMETER}/2)`}
                                >
                                    <Icon name="camera" />
                                </ButtonCircle>
                            </EditCoverPhotoButtonWrapper>
                        )}
                    </CoverPhotoAspectRatio>
                </CoverPhotoWrapper>
                <NameSection>
                    <NameWrapper>
                        <Name>{siteDetails.name}</Name>
                        {siteDetails.userIsAdmin && (
                            <EditButtonWrapper>
                                <EditButton
                                    handleClick={() =>
                                        setPopUpToDisplay(
                                            SITE_POPUP_TYPES.siteDetails
                                        )
                                    }
                                    bgColor={theme.colors.offPrimary}
                                    iconColor={theme.colors.secondary}
                                    circleDiameter={"1.8rem"}
                                    fontSize={"0.9rem"}
                                />
                            </EditButtonWrapper>
                        )}
                    </NameWrapper>
                    {siteDetails.userIsAdmin &&
                        crewRequestsToAction.length > 0 && (
                            <RequestsToActionRow>
                                <RequestActionButtonWrapper>
                                    <Button
                                        handleClick={() => {
                                            setPopUpToDisplay(
                                                SITE_POPUP_TYPES.crew
                                            );
                                        }}
                                        icon={<Icon name="user-plus" />}
                                        secondaryBtnColors={true}
                                        textColor={
                                            theme.colors.text.semantic.warning
                                        }
                                        fontWeight={500}
                                        btnPadding={"0.1rem 1rem"}
                                    >
                                        Respond
                                    </Button>
                                </RequestActionButtonWrapper>
                                <AvatarLineUp
                                    avatarArray={crewRequestsToAction}
                                    handleClick={() => {
                                        setPopUpToDisplay(
                                            SITE_POPUP_TYPES.crew
                                        );
                                    }}
                                    maxAvatars={3}
                                    hideAvatarsWithoutPic={false}
                                    defaultAvatarPic={
                                        REACT_APP_DEFAULT_USER_PROFILE
                                    }
                                />
                            </RequestsToActionRow>
                        )}
                    <UserLineupRow>
                        <AvatarLineUp
                            avatarArray={crewToShowInAvatarLineUp}
                            // avatarArray={siteDetails.crew}
                            handleClick={() => {
                                setPopUpToDisplay(SITE_POPUP_TYPES.crew);
                            }}
                            maxAvatars={6}
                            icon={<Icon name="map-pin" />}
                            hideAvatarsWithoutPic={false}
                            defaultAvatarPic={REACT_APP_DEFAULT_USER_PROFILE}
                        />
                        <ConnectionButtonWrapper>
                            <ConnectionButton
                                theme={theme}
                                conxStatus={siteDetails.conxStatus}
                                setPopUpToDisplay={setPopUpToDisplay}
                                userIsAdmin={siteDetails.userIsAdmin}
                                conxActions={conxActions}
                                buttonLoading={buttonLoading}
                            // rejectButtonLoading={rejectButtonLoading}
                            />
                        </ConnectionButtonWrapper>
                    </UserLineupRow>
                </NameSection>
            </HeaderWrapper>
        );
    }
};

export default withImgFullScreenClick(withTheme(SiteProfileHeaderView));

const HeaderWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 0 0 15px 15px;
`;

const CoverPhotoWrapper = styled.div`
    position: relative;
`;

const CoverPhotoAspectRatio = styled.div`
    position: relative;
    width: 100%;
    padding-top: ${(props) =>
        props.theme.sizes.aspectRatios.coverPhoto.LgView}%;
    @media (max-width: ${({ theme }) => theme.sizes.xsViewWidth}) {
        padding-top: ${(props) =>
        props.theme.sizes.aspectRatios.coverPhoto.xsView}%;
    }
`;

const CoverInnerWrapper = styled.div`
    position: absolute;
    top: 0; /* position top to ignore margin in parent */
    width: 100%;
    height: 100%;
    border-radius: 0 0 15px 15px;
    cursor: ${(props) => (props.noPointer ? "auto" : "pointer")};
    overflow: hidden;
`;

const NameSection = styled.div`
    display: flex;
    flex-direction: column;
    margin: 1rem;
`;

const NameWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 0.2rem 0 0.7rem 0;
`;

const Name = styled.h3`
    margin: 0;
`;

const EditButtonWrapper = styled.div`
    padding-left: 1rem;
`;

const EditCoverPhotoButtonWrapper = styled.div`
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
`;

const RequestsToActionRow = styled.div`
    display: flex;
    align-items: center;
    padding: 0.3rem 0 0.7rem 0;
`;

const RequestActionButtonWrapper = styled.div`
    flex: 0 1 125px;
    margin-right: 0.5rem;
`;

const UserLineupRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: ${CONX_BUTTON_SCREEN_BREAK_SIZE}) {
        flex-direction: column;
        align-items: start;
    }
`;

const ConnectionButtonWrapper = styled.div`
    padding-left: 1rem;
    @media (max-width: ${CONX_BUTTON_SCREEN_BREAK_SIZE}) {
        padding: 1rem 0 0 0;
    }
`;

const NameLoading = styled.div`
    width: 14rem;
    height: 1.5rem;
    background-color: ${(props) => props.theme.colors.offPrimary};
    border-radius: 15px;
`;

const AvatarsLoading = styled.div`
    width: 8rem;
    height: 1.4rem;
    background-color: ${(props) => props.theme.colors.offPrimary};
    border-radius: 15px;
`;
