import { useCallback, useEffect } from "react";
import { useApi } from "../../services/api";

import ReactDOMServer from "react-dom/server";
import { withTheme } from "styled-components"; // ReactDOMServer sits outside of ThemeProvider

import MySitesView from "./MySites-view";
import SiteInfoWindow from "../../library/common/MapSiteInfoWindow";

import {
    MAP_ICON_BOBCAT_O,
    MAP_ICON_BOBCAT_Y,
    MAP_ICON_DIGGER_O,
    MAP_ICON_DIGGER_Y,
} from "../../library/common/constants";
import { addSiteInfoWindowListener } from "../../library/utilities";

const MySitesContainer = ({
    currentUser,
    history,
    theme,
    setShowSidebar,
    mySites,
    setMySites,
}) => {
    // useApi updates your sites in store each time component is mounted
    // Don't need to know if useApi is loading, loading screen only shown when no sites in store otherwise store data used
    const { isLoading } = useApi({
        method: "post",
        url: "/api/sites/find",
        data: { sitesWithCrewMember: currentUser.user.id },
        callback: (res) => {
            if (res) {
                setMySites(res);
            }
        },
    });

    // const { isLoading } = useApi({
    //     method: "get",
    //     url: `/api/users/${currentUser.user.id}/sites`,
    //     callback: (res) => { if (res) setMySites(res); }
    // });

    // useEffect(() => {
    //     // Each time updatedSiteData is returned from useApi update the store with new info.
    //     if (updatedSiteData) {
    //         setMySites(updatedSiteData);
    //     }
    // }, [updatedSiteData]);

    // hide sidebar on component
    useEffect(() => {
        setShowSidebar(false);
        return () => {
            setShowSidebar(true);
        };
    }, [setShowSidebar]);

    const markerSize = 45;
    const addMarkersToMap = useCallback(
        ({ map, locationsArray, clearMarkers, addMarkerToMarkers }) => {
            clearMarkers(); // clear any markers already on map
            // setting up map to contain markers for all sites, set map bounds so all markers are shown, add click listener for each and custom icons
            const bounds = new window.google.maps.LatLngBounds();

            // create a single infoWindow that will be used by all markers
            // (so that clicking on a new marker will close other windows)
            const infoWindow = new window.google.maps.InfoWindow();

            // Clear infoBar from screen on map click
            map.addListener("click", () => {
                infoWindow.close();
            });

            addSiteInfoWindowListener(infoWindow, locationsArray, history);

            const siteMarkers = [
                MAP_ICON_BOBCAT_O,
                MAP_ICON_DIGGER_Y,
                MAP_ICON_BOBCAT_Y,
                MAP_ICON_DIGGER_O,
            ];
            let markerIndex = 0;

            locationsArray.map((site, i) => {
                const marker = new window.google.maps.Marker({
                    map: map,
                    position: site.latLng,
                    icon: {
                        url: siteMarkers[markerIndex],
                        scaledSize: new window.google.maps.Size(
                            markerSize,
                            markerSize
                        ),
                    },
                });

                // Adds 1 to marker index or resets to 0 when siteMarkers.length reached
                markerIndex < siteMarkers.length - 1
                    ? (markerIndex += 1)
                    : (markerIndex = 0);

                addMarkerToMarkers({
                    marker: marker,
                });
                // extend  bounds object to include site
                bounds.extend(site.latLng);

                // Add click listener
                marker.addListener("click", () => {
                    map.panTo(marker.getPosition()); // move map to center on pin
                    // define window content using ReactDOMServer to convert component to string for google setContent method
                    const content = ReactDOMServer.renderToString(
                        <SiteInfoWindow site={site} theme={theme} />
                    );
                    infoWindow.setContent(content);
                    infoWindow.open(map, marker);
                });
                return marker;
            });

            // fit all markers onto map with padding
            const padding = 50;
            map.fitBounds(bounds, {
                left: padding,
                top: padding,
                right: padding,
                bottom: padding,
            });

            // .fitBounds() tends to result in map being too zoomed in.
            // Check map zoom and if its zoomed in to far, zoom out to maxStartingZoom level
            const listener = map.addListener("idle", () => {
                const maxStartingZoom = 13;
                if (map.getZoom() > maxStartingZoom)
                    map.setZoom(maxStartingZoom);
                window.google.maps.event.removeListener(listener);
            });
        },
        [history, theme]
    );

    const onSiteClick = (siteId) => {
        history.push(`/sites/${siteId}`);
    };

    const viewProps = {
        isLoading,
        mySites,
        addMarkersToMap,
        onSiteClick,
    };

    return <MySitesView {...viewProps} />;
};

export default withTheme(MySitesContainer);
