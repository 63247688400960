import { useState, useRef, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { sendApiRequest } from "../../services/api";
import styled, { withTheme } from "styled-components";

import { checkAndLoadMapsAPI, getAddress } from "../../library/utilities";

import InputMap from "../../library/common/InputMap";
import { ContentBox } from "../../resources/styles/index";
import HeaderBar from "../../library/common/PopupHeaderBar";
import FooterBar from "../../library/common/PopupFooterBar";
import PopupLoadingScreen from "../../library/common/PopupLoadingScreen";

import WithPopUp from "../../library/hoc/WithPopUp";
import WithRemoveErrorOnUnmount from "../../library/hoc/WithRemoveErrorOnUnmount";

import { addError } from "../../main/store/actions/errors";

const mapDispatchToProps = {
    addError,
};

// Change site location, renders popup with loading screen and input map when all loading is complete. Renders loading screen again while submit is handled then exits popup if success
// No front end validation (as none needed) but back end addError in withPopup works
const AddressEditPopupContainer = ({
    theme,
    addError,
    addressObj,
    setPopUpToDisplay,
    setParentState,
    serverPath,
}) => {
    const { getAccessTokenSilently } = useAuth0();

    const [popupLoading, setPopupLoading] = useState(false);

    // Map position must be defined and mapsAPILoading before rendering InputMap
    const [mapPositionLoading, setMapPositionLoading] = useState(true);
    const [mapsAPILoading, setMapsAPILoading] = useState(true);

    // On mount checks for maps API script tag, if not loaded then loads it and setMapsAPILoading to false
    useEffect(() => {
        checkAndLoadMapsAPI(setMapsAPILoading);
    }, []);

    // State used to store location
    const [formState, setFormState] = useState(addressObj);

    const initialMapPosition = useRef(); //ref so can be set in useEffect
    // set initialMapPosition in use effect so its not updated every time  address changes
    useEffect(() => {
        initialMapPosition.current = addressObj.latLng;
        setMapPositionLoading(false);
    }, [addressObj]);

    // Used inside InputMap to update parent state on user location click
    const mapUpdateLocation = useCallback((latLng) => {
        getAddress(latLng, (address, latLng) => {
            setFormState({
                address: address,
                latLng: latLng,
            });
        });
    }, []);

    // set loading, send request and update parent state
    const handleSubmit = async () => {
        setPopupLoading(true);
        try {
            await sendApiRequest(getAccessTokenSilently, {
                method: "put",
                url: serverPath,
                data: formState,
            });
            setParentState((f) => {
                return {
                    ...f,
                    ...formState,
                };
            });
            setPopUpToDisplay(""); // exit popup
        } catch (error) {
            addError(error.message);
            setPopupLoading(false);
        }
    };

    if (mapsAPILoading || mapPositionLoading || popupLoading) {
        return <PopupLoadingScreen />;
    } else {
        return (
            <Wrapper>
                <HeaderBar
                    theme={theme}
                    setPopUpToDisplay={setPopUpToDisplay}
                    popupTitle={"Site Location"}
                />
                <ContentBox>
                    <InputMap
                        initialMapPosition={initialMapPosition}
                        initialMapZoom={13}
                        latLng={formState.latLng}
                        mapUpdateLocation={mapUpdateLocation}
                        idIndex="Two"
                    />
                </ContentBox>
                <FooterBar
                    handleDone={handleSubmit}
                    handleCancel={() => {
                        setPopUpToDisplay("");
                    }}
                    cancelLabel={"Cancel"}
                    doneLabel={"Save"}
                />
            </Wrapper>
        );
    }
};

export default connect(mapDispatchToProps)(
    WithRemoveErrorOnUnmount(withTheme(WithPopUp(AddressEditPopupContainer)))
);

const Wrapper = styled.div`
    width: min(600px, 95vw);
`;
