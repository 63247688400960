import { useState } from "react";
import { useParams } from "react-router-dom";

import SiteProfileView from "./SiteProfile-view";
import PopupRouter from "./PopupRouter";

import {
    CONNECTION_STATUS,
    SITE_PAGES,
    SITE_POPUP_TYPES,
} from "../../library/common/constants";

import { ItemsInArrayOneNotArrayTwo } from "../../library/utilities";

import { useApi } from "../../services/api";

const SiteProfileContainer = ({
    currentUser,
    // page: pageToDisplay,
    previewState = undefined,
    // userPopupOnMount = false, // will be true when route finishes in "/users"
    // ...errorHandlingProps
    errors,
    addError,
    removeError,
}) => {

    // define urls to site api
    const { site_id: siteId = "", tab_name: tabName = "" } = useParams();

    const siteProfileBaseUrl = `/api/sites/${siteId}`;
    const siteProfileUrls = {
        serverPath: siteProfileBaseUrl,
        addCoverPicPath: `${siteProfileBaseUrl}/upload_cover_photo`,
        deleteCoverPicPath: `${siteProfileBaseUrl}/delete_cover_photo`,
        crewLicencePath: `${siteProfileBaseUrl}/crew_licences`,
        acceptRequest: `${siteProfileBaseUrl}/accept_request`,
        deleteRequest: `${siteProfileBaseUrl}/delete_request`,
        requestToJoin: `${siteProfileBaseUrl}/request_to_join`,
        updateCrewRequest: `${siteProfileBaseUrl}/update_crew`,
        deleteSite: `${siteProfileBaseUrl}/delete`,
    };

    const userPopupOnMount = tabName === "users";
    const adjustedTabName = userPopupOnMount ? "" :
        tabName.length > 0 ? `/${tabName}` : tabName;
    const pageToDisplay = Object.values(SITE_PAGES)
        .filter(el => el.urlExtension === adjustedTabName)[0].name;

    const [siteDetails, setSiteDetails] = useState({ crew: [], crewLicences: {} });
    const [activePopup, setActivePopup] = useState(userPopupOnMount ? SITE_POPUP_TYPES.crew : "");

    // get profile data
    const {
        // data: siteData,
        isLoading: isLoadingSite,
        refresh: refreshDataFromServer,
    } = useApi(
        !!previewState ? {} : {
            method: "get",
            url: siteProfileBaseUrl,
            callback: (res) => {
                setSiteDetails(f => ({
                    ...res,
                    userIsAdmin: res.adminUsers && res.adminUsers.some(
                        (adminUser) => adminUser._id === currentUser.user.id
                    ),
                    currentUserInCrew: res.crew && res.crew.some(
                        (user) => user._id === currentUser.user.id
                    ),
                    crewLicences: f.crewLicences,
                }));
            },
        }
    );

    // get site licences data
    const {
        // data: siteLicencesData,
        isLoading: isLoadingSiteLicences,
        // refresh: refreshSiteLicencesData
    } = useApi(
        !!previewState ? {} : {
            method: "get",
            url: siteProfileUrls.crewLicencePath,
            callback: (res) => {
                setSiteDetails(f => ({ ...f, crewLicences: res }));
            }
        }
    );

    const {
        isLoading: crewIsUpdating,
        refresh: updateCrew
    } = useApi({
        method: "put",
        url: siteProfileUrls.updateCrewRequest,
    });

    const handleCrewChange = (updatedUsers) => {
        // compute differences with existing crew
        const { crew: existingUsers = [] } = siteDetails;
        const addedUsers = ItemsInArrayOneNotArrayTwo(updatedUsers, existingUsers);
        const removedUsers = ItemsInArrayOneNotArrayTwo(existingUsers, updatedUsers);

        // send update to server
        updateCrew({ data: { addedUsers, removedUsers } });

        const crewAfterRemovingUsers = existingUsers.filter(el => !removedUsers.includes(el._id));
        const formattedUsersToAdd = updatedUsers
            .filter(el => addedUsers.includes(el._id))
            .map(el => ({ ...el, status: CONNECTION_STATUS.PENDING }));

        setSiteDetails(f => ({ ...f, crew: crewAfterRemovingUsers.concat(formattedUsersToAdd) }));
        setActivePopup("");
    };

    const {
        isLoading: conxIsUpdating,
        refresh: updateConxStatus,
    } = useApi();

    const handleActionConxPending = ({ approve, userId }) => {
        // send request through
        updateConxStatus({
            method: "post",
            url: `${approve ?
                siteProfileUrls.acceptRequest :
                siteProfileUrls.deleteRequest
                }?id=${userId}`,
        });
        // update state
        setSiteDetails(f => ({
            ...f,
            crew: approve ?
                f.crew.map((member) => (member._id === userId) ?
                    // update user's status in state to accepted
                    { ...member, status: CONNECTION_STATUS.ACCEPTED } : member
                ) :
                f.crew.filter((member) => member._id !== userId),
        }));
    };

    const handleJoin = () => {
        // send request through
        updateConxStatus({
            method: "post",
            url: siteProfileUrls.requestToJoin,
        });
        // add current user to crew in state
        setSiteDetails(f => ({
            ...f,
            crew: f.crew.concat({
                _id: currentUser.user.id,
                img: currentUser.user.img,
                username: currentUser.user.username,
                status: CONNECTION_STATUS.PENDING,
                jobTitle: "",
            }),
            conxStatus: CONNECTION_STATUS.PENDING,
        }));
    };

    const handleLeave = () => {
        // send request through
        updateConxStatus({
            method: "put",
            url: siteProfileUrls.updateCrewRequest,
            data: { removedUsers: currentUser.user.id }
        });
        // update state
        setSiteDetails(f => ({
            ...f,
            crew: f.crew.filter(el => el._id !== currentUser.user.id),
            conxStatus: undefined,
        }));
    };

    const conxActions = { handleActionConxPending, handleJoin, handleLeave };

    // define props for views

    const profileViewProps = {
        pageToDisplay,
        siteDetails: previewState || siteDetails,
        createSiteView: !!previewState,
        setPopUpToDisplay: setActivePopup,
        serverPaths: siteProfileUrls,
        conxActions,
        buttonLoading: conxIsUpdating,
        loading: isLoadingSite || isLoadingSiteLicences,
        errors,
        removeError,
    };

    const popupProps = {
        siteDetails,
        popUpToDisplay: activePopup,
        setSiteDetails,
        setPopUpToDisplay: setActivePopup,
        refreshDataFromServer,
        serverPaths: siteProfileUrls,
        conxActions,
        handleCrewChange,
        popupLoading: crewIsUpdating,
        errors,
        addError,
        removeError,
    };

    return (
        <div>
            <SiteProfileView {...profileViewProps} />
            {!previewState && <PopupRouter {...popupProps} />}
        </div>
    );
};

export default SiteProfileContainer;
