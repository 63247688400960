import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import { useApi, sendApiRequest } from "../../services/api";

import {
    POST_POPUP_TYPES,
    USER_STATUS,
    PRIMARY_AUTHOR,
} from "../../library/common/constants";
import { ItemsInArrayOneNotArrayTwo } from "../../library/utilities";

import PostView from "./Post-view";
import EditPostTextPopup from "./EditPostTextPopup";

import ConfirmationScreen from "../../library/common/ConfirmationScreen";
import TagsPopup from "../../library/common/popups/AddOrRemoveAccounts";
import withPopUp from "../../library/hoc/WithPopUp";
import withRemoveErrorOnUnmount from "../../library/hoc/WithRemoveErrorOnUnmount";

const ConfirmationPopup = withRemoveErrorOnUnmount(
    withPopUp(ConfirmationScreen)
);

// if postData not passing in from parent loads post state, handles tag change submit
const PostContainer = ({
    currentUser, // index
    theme, // index
    addError, // index
    removeError, // index
    errors, // index
    history, // index
    postFullView: postFullViewProp = true,
    postData,
    removeFromFeed,
    updateFeed,
}) => {
    // store post details
    const [postState, setPostState] = useState({});

    // if postData comes in as props add it to postState
    // NOTE: Never use props to initialise state as this is never revaluated when props change! Was causing a bug here
    useEffect(() => {
        if (postData) setPostState(postData);
    }, [postData]);

    const [loading, setLoading] = useState(true);
    const [commentsLoading, setCommentsLoading] = useState(false);

    // Stores a comment to be sent to server when user posts
    const [commentState, setCommentState] = useState("");
    const [commentLoading, setCommentLoading] = useState(false);
    const [deletingComment, setDeletingComment] = useState(false);

    // initialise state, timeout, and api for likes
    const [liked, setLiked] = useState(0);
    const { refresh: refreshLikes } = useApi();
    let timeoutId;

    const { getAccessTokenSilently } = useAuth0();

    const [popUpToDisplay, setPopUpToDisplay] = useState("");

    // handle loading in popups, cannot user loading prop from withLoading HOC as changing it will change posts loading state as well
    const [popupLoading, setPopupLoading] = useState(false);

    // post_id used to get data from server if postData not passed in as props
    // image_index used to set initial image in slider to show
    const { post_id, image_index } = useParams();

    const postFullView = postFullViewProp && !!post_id;

    const { user = { _id: null }, userTags = [] } = postState;
    const userIsAdmin = user._id === currentUser.user.id;
    const currentUserInTags = userTags.some((user) => user._id === currentUser.user.id);

    // Grab post_id from params or if params post_id is undefined (because in news feed) get id postState
    const serverPath = `/api/posts/${post_id || postState._id}`;

    const loadEngagementData = async () => {
        if (!postFullView) {
            // Since postFullView will already have engagement data
            try {
                setCommentsLoading(true);
                // if no post data comes in from parent get form server
                const postData = await sendApiRequest(getAccessTokenSilently, {
                    method: "get",
                    url: serverPath,
                });
                setPostState({ ...postData });
            } catch (error) {
                addError(error.message);
            }
            setCommentsLoading(false);
        }
    };

    // Load in post data from server
    useEffect(() => {
        (async () => {
            try {
                if (postData === undefined) {
                    // if no post data comes in from parent get from server
                    // serverPath will return post with comments
                    const postData = await sendApiRequest(
                        getAccessTokenSilently,
                        {
                            method: "get",
                            url: serverPath,
                        }
                    );
                    setPostState({ ...postData });
                }
                setLoading(false); // clear loading screen
            } catch (error) {
                addError(error.message);
                setLoading(false);
            }
        })();
    }, [
        postData,
        post_id,
        setLoading,
        addError,
        serverPath,
        getAccessTokenSilently,
    ]);

    // doneTagging function used in addCrewMembers screen
    // Updates server then updates postState and removes popup
    const doneTagging = async (updatedUserTags, updatedCompanyTags) => {
        try {
            setPopupLoading(true);

            const castTagsForSubmit = {
                addedUserTags: ItemsInArrayOneNotArrayTwo(
                    updatedUserTags,
                    postState.userTags
                ),
                removedUserTags: ItemsInArrayOneNotArrayTwo(
                    postState.userTags,
                    updatedUserTags
                ),
                addedCompanyTags: ItemsInArrayOneNotArrayTwo(
                    updatedCompanyTags,
                    postState.companyTags
                ),
                removedCompanyTags: ItemsInArrayOneNotArrayTwo(
                    postState.companyTags,
                    updatedCompanyTags
                ),
            };

            await sendApiRequest(getAccessTokenSilently, {
                method: "put",
                url: `${serverPath}/tag`,
                data: castTagsForSubmit,
            });

            const newPostState = {
                ...postState,
                userTags: updatedUserTags.map((user) => ({
                    _id: user._id, // Kick out time added and new fields
                    img: user.img,
                    username: user.username,
                })),
                companyTags: updatedCompanyTags.map((company) => ({
                    _id: company._id, // Kick out time added and new fields
                    img: company.img,
                    name: company.name,
                })),
            };
            // update post state in view and update news feed
            setPostState(newPostState);
            updateFeed([newPostState]);

            setPopUpToDisplay("");
            setPopupLoading(false);
        } catch (error) {
            addError(`Error adding user(s): ${error.message}`);
            setPopupLoading(false);
        }
    };

    // toggles the currentUserHasLiked property
    // cancels duplicate "like" clicks from bulkWritePostQueue
    const handleLike = (
        e,
        post
        // wasInitiallyLiked
    ) => {
        e.stopPropagation();

        // delete timeoutId if there was one
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            // timeout closure happens before setStates
            // check original number of clicks
            // i.e. should be even for the result to change
            if (liked % 2 === 0) {
                // check original state of currentUserHasliked
                const action = postState.currentUserHasLiked
                    ? "unlike"
                    : "like";
                refreshLikes({
                    method: "post",
                    url: `${serverPath}/${action}`,
                });
            }
            setLiked(0);
        }, 1000);

        setLiked((f) => f + 1);
        const updatedPost = {
            ...post,
            // If removing like - filter current user from likes array (or add them to it if liking post)
            likes: post.currentUserHasLiked
                ? post.likes.filter(
                    (likeUser) => likeUser._id !== currentUser.user.id
                )
                : [
                    ...post.likes,
                    { _id: currentUser.user.id, ...currentUser.user },
                ],
            // toggle currentUserHasLiked status
            currentUserHasLiked: !post.currentUserHasLiked,
        };
        if (postFullView) {
            // If user likes posts from postFull view then just change post container state
            setPostState(updatedPost);
        } else {
            // Else then user likes from news feed then update data in newsFeed redux state
            updateFeed([updatedPost]);
        }
    };

    const handlePostDelete = async () => {
        try {
            setPopupLoading(true);
            await sendApiRequest(getAccessTokenSilently, {
                method: "delete",
                url: serverPath,
            });
            removeFromFeed(postState._id);
            setPopupLoading(false);
            setPopUpToDisplay("");
            // history.push("/");
        } catch (error) {
            addError(error.message);
            setPopupLoading(false);
        }
    };

    const removeYourTag = async () => {
        try {
            setPopupLoading(true);
            const castTagsForSubmit = {
                removedUserTags: [currentUser.user.id],
                removedCompanyTags: [],
                addedUserTags: [],
                addedCompanyTags: [],
            };
            console.log("castTagsForSubmit");
            console.log(castTagsForSubmit);
            await sendApiRequest(getAccessTokenSilently, {
                method: "put",
                url: `${serverPath}/tag`,
                data: castTagsForSubmit,
            });

            setPostState((f) => ({
                ...f,
                userTags: postData.userTags.filter(
                    (user) => user._id !== currentUser.user.id
                ),
            }));
            setPopUpToDisplay("");
        } catch (error) {
            addError(error.message);
            setPopupLoading(false);
        }
    };

    const handlePostSubmit = async () => {
        try {
            setCommentLoading(true);
            const updatedPost = await sendApiRequest(getAccessTokenSilently, {
                method: "post",
                url: `${serverPath}/add_comment`,
                data: { comment: commentState },
            });
            console.log("updatedPost");
            console.log(updatedPost);
            setPostState(updatedPost);
            setCommentLoading(false);
        } catch (error) {
            addError(error.message);
            setCommentLoading(false);
        }
    };

    const handleCommentDelete = async (id) => {
        try {
            setDeletingComment(true);
            console.log("sending request to: ", `${serverPath}/delete_comment/${id}`);
            console.log("postState: ", postState);
            const updatedPost = await sendApiRequest(getAccessTokenSilently, {
                method: "delete",
                url: `${serverPath}/delete_comment/${id}`,
            });
            if (postFullView) {
                // If user likes posts from postFull view then just change post container state
                setPostState(updatedPost);
            } else {
                // Else then user likes from news feed then update data in newsFeed redux state
                updateFeed([updatedPost]);
            }
        } catch (error) {
            addError(error.message);
            setDeletingComment(false);
        }
    };

    const handleSeeLikes = () => {
        setPopUpToDisplay(POST_POPUP_TYPES.LIKED_POST);
    };

    const commonPropsTagsPopup = {
        errors: errors,
        addError: addError,
        removeError: removeError,
        setPopUpToDisplay: setPopUpToDisplay,
        // Tagged users and admins can tag new users
        userIsAdmin: userIsAdmin,
        userCanAdd: !!currentUserInTags || !!userIsAdmin,
        userInAccounts: !!currentUserInTags,
        users: postState.userTags,
        handleSubmit: doneTagging,
        customExitHandling: () => setPopUpToDisplay(""),
        handleDone: () => setPopUpToDisplay(""),
        handleCancel: () => setPopUpToDisplay(""),
        popupLoading: popupLoading,
        statusOptions: {
            acceptedAccountStatus: USER_STATUS.ACTIVE,
            removedAccountStatus: USER_STATUS.INACTIVE,
        },
        popupTitle:
            !!postState.user && // check if postState loaded (empty object is truthy)
                postState.primaryAuthor !== PRIMARY_AUTHOR.COMPANY
                ? `${postState.user.givenName}'s Post`
                : `Post`,
        listTitle: "Post Tags",
        addBtnOptions: {
            addIcon: <i className="fas fa-user-tag"></i>,
        },
        leaveText: "Untag",
    };

    // Renders PostView with the option of popups
    // PostView handles loading logic
    return (
        <div>
            <PostView
                theme={theme}
                errors={errors}
                post={postState}
                loading={loading}
                setPopUpToDisplay={setPopUpToDisplay}
                handleLike={handleLike}
                history={history}
                postFullView={postFullView}
                currentUser={currentUser}
                removeError={removeError}
                userIsAdmin={userIsAdmin}
                currentUserInTags={currentUserInTags}
                startingImgIndex={parseInt(image_index)}
                commentState={commentState}
                setCommentState={setCommentState}
                handlePostSubmit={handlePostSubmit}
                commentLoading={commentLoading}
                deletingComment={deletingComment}
                handleCommentDelete={handleCommentDelete}
                loadEngagementData={loadEngagementData}
                commentsLoading={commentsLoading}
                handleSeeLikes={handleSeeLikes}
            />
            {popUpToDisplay === POST_POPUP_TYPES.ADD_TAGS && (
                <TagsPopup {...commonPropsTagsPopup} add={true} />
            )}
            {popUpToDisplay === POST_POPUP_TYPES.TAGS && (
                <TagsPopup {...commonPropsTagsPopup} />
            )}
            {popUpToDisplay === POST_POPUP_TYPES.LIKED_POST && (
                <TagsPopup
                    {...commonPropsTagsPopup}
                    showAllUsers={true}
                    userIsAdmin={false} // no admin features on likes
                    // Here down are props being overwritten from common
                    users={postState.likes}
                    handleSubmit={undefined}
                    userCanAdd={false}
                    popupTitle={"Likes"}
                    listTitle={undefined}
                />
            )}
            {popUpToDisplay === POST_POPUP_TYPES.EDIT_POST && (
                <EditPostTextPopup
                    theme={theme}
                    setPopUpToDisplay={setPopUpToDisplay}
                    postState={postState}
                    setPostState={setPostState}
                />
            )}
            {popUpToDisplay === POST_POPUP_TYPES.DELETE_POST && (
                <ConfirmationPopup
                    handleCancel={() => {
                        setPopUpToDisplay(POST_POPUP_TYPES.EDIT_POST);
                    }}
                    cancelText={"Edit"}
                    theme={theme}
                    handleSubmit={handlePostDelete}
                    submitText={"Delete"}
                    title={"Delete Post"}
                    loading={popupLoading}
                    setPopUpToDisplay={setPopUpToDisplay}
                    message={
                        "You are about to delete a post. Once deleted posts cannot be recovered, are you sure you want to delete this post?"
                    }
                    maxWidth={"500px"}
                />
            )}
            {popUpToDisplay === POST_POPUP_TYPES.REMOVE_TAG && (
                <ConfirmationPopup
                    handleCancel={() => {
                        setPopUpToDisplay("");
                    }}
                    cancelText={"Cancel"}
                    theme={theme}
                    handleSubmit={removeYourTag}
                    submitText={"Remove"}
                    title={"Remove Tag"}
                    loading={popupLoading}
                    setPopUpToDisplay={setPopUpToDisplay}
                    message={
                        "By removing yourself from tags any photos in this post will no longer appear on your profile. Are your sure you want to remove your tag?"
                    }
                />
            )}
        </div>
    );
};

export default PostContainer;
