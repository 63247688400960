import styled from "styled-components";
import { darken } from "polished";

// Fills a parent and displayed a gradient shimmer effect with animation to be used on image loading screens
const LoadingImgBox = ({
    baseColor, // optional
    shimmerColor, // optional
    duration, // optional
}) => {
    return (
        <LoadingBox
            baseColor={baseColor}
            shimmerColor={shimmerColor}
            duration={duration}
        />
    );
};

export default LoadingImgBox;

const LoadingBox = styled.div`
    height: 100%;
    width: 100%;
    background-clip: content-box;
    animation-duration: ${(props) =>
        props.duration ? props.duration : "6.5s"};
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: ${(props) => props.theme.colors.primary};
    background: linear-gradient(
        to right,
        ${({ theme, baseColor }) =>
                baseColor ? baseColor : theme.colors.offPrimary}
            8%,
        ${({ theme, shimmerColor }) =>
                shimmerColor
                    ? shimmerColor
                    : darken(0.05, theme.colors.offPrimary)}
            38%,
        ${({ theme, baseColor }) =>
                baseColor ? baseColor : theme.colors.offPrimary}
            54%
    );
    background-size: 500% 200%;
    @keyframes placeHolderShimmer {
        0% {
            background-position: 200% 0;
        }
        100% {
            background-position: -200% 0;
        }
    }
`;
