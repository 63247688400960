// ************************************************
// api config
// ************************************************
export const SERVER_URL = process.env.REACT_APP_API_BASE_URL;
export const API_AUDIENCE = `${process.env.REACT_APP_API_BASE_URL}/api`;

// ************************************************
// images
// ************************************************

export const REACT_APP_DEFAULT_COMPANY_PROFILE = `${window.location.origin}/DefaultImages/defaultCompany.jpeg`;
export const REACT_APP_DEFAULT_COMPANY_COVER = `${window.location.origin}/DefaultImages/grey_square.jpg`;
export const REACT_APP_DEFAULT_USER_PROFILE = `${window.location.origin}/DefaultImages/defaultUser.jpg`;

// ************************************************
// google map config
// ************************************************

export const CHRISTCHURCH_COORDS = { lat: -43.5317227, lng: 172.6275772 };
export const MAP_ZOOM = 11;

export const mapStyleOptions = {
    // custom map styles, remove businesses labels for instance
    styles: [
        {
            featureType: "poi.business",
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
        {
            featureType: "poi.park",
            elementType: "labels.text",
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
    ],
};

export const MAP_INFO_DATA_TYPES = { site: "site", company: "company" };
// ************************************************
//  Map Markers
// ************************************************
export const MAP_ICON_BOBCAT_O = `${window.location.origin}/MapMarkers/MapIconBobCat_O.svg`;
export const MAP_ICON_BOBCAT_Y = `${window.location.origin}/MapMarkers/MapIconBobCat_Y.svg`;
export const MAP_ICON_DIGGER_O = `${window.location.origin}/MapMarkers/MapIconDigger_O.svg`;
export const MAP_ICON_DIGGER_Y = `${window.location.origin}/MapMarkers/MapIconDigger_Y.svg`;
export const MAP_ICON_ROLLER_Y = `${window.location.origin}/MapMarkers/MapIconRoller_Y.svg`;
export const MAP_ICON_ROLLER_O = `${window.location.origin}/MapMarkers/MapIconRoller_O.svg`;
export const MAP_ICON_TRUCK_Y = `${window.location.origin}/MapMarkers/MapIconTruck_Y.svg`;
export const MAP_ICON_TRUCK_O = `${window.location.origin}/MapMarkers/MapIconTruck_O.svg`;
export const MAP_ICON_LOADER_Y = `${window.location.origin}/MapMarkers/MapIconLoader_Y.svg`;
export const MAP_ICON_LOADER_O = `${window.location.origin}/MapMarkers/MapIconLoader_O.svg`;
export const MAP_ICON_CONTRACTOR = `${window.location.origin}/MapMarkers/MapIconContractor.svg`;
export const MAP_ICON_HIRE_COMPANY = `${window.location.origin}/MapMarkers/MapIconHireCompany.svg`;
export const MAP_ICON_MATERIALS_SUPPLIER = `${window.location.origin}/MapMarkers/MapIconMaterialsSupplier.svg`;
export const MAP_ICON_PIE_SHOP = `${window.location.origin}/MapMarkers/MapIconPieShop.svg`;

// ************************************************
// config variable for file upload requests
// ************************************************

export const FILE_UPLOAD_CONFIG = {
    headers: {
        "Content-Type": "multipart/form-data",
    },
};

// ************************************************
// Moment date time formats
// ************************************************
export const POST_TIME_MOMENT_FORMAT = {
    lastDay: "[Yesterday] LT",
    sameDay: "[Today] LT",
    lastWeek: "dddd",
    sameElse: "L",
};

// ************************************************
// Search types
// ************************************************
export const USER_SEARCH = {
    searchTitle: "People",
    clientRoute: "/users", // /:user_id add id in push
    apiRequestType: "post",
    apiRequestPath: "/api/users/find",
    searchField: "username",
    imgField: "img",
    defaultImage: REACT_APP_DEFAULT_USER_PROFILE,
    uniqueFields: ["givenName"],
};

export const POST_SEARCH = {
    searchTitle: "Posts",
    clientRoute: "/posts", // add id in push
    apiRequestType: "post",
    apiRequestPath: "/api/posts/find",
    searchField: "text",
    imgField: "img",
};

export const COMPANY_SEARCH = {
    searchTitle: "Companies",
    clientRoute: "/companies", // add id in push
    apiRequestType: "post",
    apiRequestPath: "/api/companies/find",
    searchField: "name",
    imgField: "logo",
    defaultImage: REACT_APP_DEFAULT_USER_PROFILE,
    // catField: "companyType",
};

export const SERVICE_SEARCH = {
    searchTitle: "Services",
    apiRequestType: "post",
    apiRequestPath: "/api/services/find",
    searchField: "name",
    catField: "industry",
};

export const LICENCE_SEARCH = {
    searchTitle: "Licences",
    apiRequestType: "post",
    apiRequestPath: "/api/licences/find",
    searchField: "name",
};

export const SITE_SEARCH = {
    searchTitle: "Sites",
    clientRoute: "/sites", // add id in push
    apiRequestType: "post",
    apiRequestPath: "/api/sites/find",
    searchField: "name",
    imgField: "img",
    // catField: "address",
};

// ************************************************
// User
// ************************************************

export const USER_STATUS = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
};

// ************************************************
// Connections
// ************************************************
export const CONNECTION_STATUS = {
    REQUESTED: "REQUESTED", // request sent
    PENDING: "PENDING", // waiting for you to accept
    ACCEPTED: "ACCEPTED",
    NOTAPPLICABLE: "N/A",
};

export const CONNECTION_ACTION = {
    ADD: {
        route: "add_connection",
        STATUS_RESULT: CONNECTION_STATUS.REQUESTED,
    },
    ACCEPT: {
        route: "accept_connection",
        STATUS_RESULT: CONNECTION_STATUS.ACCEPTED,
    },
    REMOVE: {
        route: "remove_connection",
        STATUS_RESULT: CONNECTION_STATUS.NOTAPPLICABLE,
    },
    FOLLOW: {
        route: "follow",
        STATUS_RESULT: CONNECTION_STATUS.ACCEPTED,
    },
    UNFOLLOW: {
        route: "unfollow",
        STATUS_RESULT: CONNECTION_STATUS.NOTAPPLICABLE,
    },
};

// ************************************************
// EMPLOYMENT_TYPE
// ************************************************
export const EMPLOYMENT_TYPE = {
    FULL_TIME: "FULL TIME",
    PART_TIME: "PART TIME",
    SELF_EMPLOYED: "SELF-EMPLOYED",
    CONTRACT: "CONTRACT",
    APPRENTICESHIP: "APPRENTICESHIP",
    INTERNSHIP: "INTERNSHIP",
};

// ************************************************
// Profile Pages
// ************************************************
// These constants are used to render TabNavbar, the order matters
// E.g. if ABOUT is first then "Profile" button will be rendered on left side of tab navbar
export const USER_PAGES = {
    GRID: {
        name: "Posts",
        urlExtension: "",
    },
    ABOUT: {
        name: "Profile",
        urlExtension: "/profile",
    },
    SITE_MAP: {
        name: "Projects",
        urlExtension: "/site_map",
    },
};

export const COMPANY_PAGES = {
    GRID: {
        name: "Posts",
        urlExtension: "",
    },
    ABOUT: {
        name: "Profile",
        urlExtension: "/profile",
    },
    PEOPLE: {
        name: "People",
        urlExtension: "/people",
    },
};

export const SITE_PAGES = {
    ABOUT: {
        name: "Profile",
        urlExtension: "",
    },
    GRID: {
        name: "Posts",
        urlExtension: "/posts",
    },
    CREW: {
        name: "Crew",
        urlExtension: "/crewPage",
    },
};

// ************************************************
// PopUps
// ************************************************
export const POST_POPUP_TYPES = {
    CREATE_POST: "createPost",
    EDIT_POST: "editPost",
    DELETE_POST: "deletePost",
    TAGS: "tags",
    ADD_TAGS: "addTags",
    REMOVE_TAG: "removeTag",
    EXIT_CONFIRMATION: "exitConfirmation",
    IMAGE_UPLOAD: "imageUpload",
    TEXT_INPUT: "textInput",
    ADD_SITE: "addSite",
    SELECT_AUTHOR: "selectAuthor",
    LIKED_POST: "likedPost",
};

export const USER_POPUP_TYPES = {
    header: "header",
    displayPicture: "displayPicture",
    coverPhoto: "coverPhoto",
    licences: "licences",
    addLicence: "addLicence",
    workExperiences: "workExperiences",
    expertise: "expertise",
    conxList: "conxList",
};

export const COMPANY_POPUP_TYPES = {
    header: "header",
    logo: "logo",
    coverPhoto: "coverPhoto",
    services: "services",
    OfficeLocations: "OfficeLocations",
};

export const SITE_POPUP_TYPES = {
    coverPhoto: "coverPhoto",
    crew: "crew",
    invite: "invite",
    location: "location",
    siteDetails: "siteDetails",
    leaveSite: "leaveSite",
    delete: "delete",
};

export const CHAT_POPUP_TYPES = {
    createNewChat: "createNewChat",
    addMembers: "addMembers",
    membersInfo: "membersInfo",
    chatInfo: "chatInfo",
    seenByUsers: "seenByUsers",
    error: "error",
    leaveGroup: "leaveGroup",
};

export const NAV_POPUP_TYPES = {
    createPost: "createPost",
    exitCreatePost: "exitCreatePost",
};

export const SUPER_USER_POPUP_TYPES = {
    hardDeleteSite: "hardDeleteSite",
};

// ************************************************
// Dropdown strings
// ************************************************
export const NAV_DROPDOWN_TYPES = {
    messages: "messages",
    notifications: "notifications",
    add: "add",
    accountControls: "accountControls",
};

// ************************************************
// Company types
// ************************************************
//   >>>>>> Note these MUST MATCH SERVER <<<<<
export const COMPANY_TYPE = {
    CONTRACTOR: "CONTRACTOR",
    MATERIAL_SUPPLIER: "MATERIAL_SUPPLIER",
    HIRE_COMPANY: "HIRE_COMPANY",
    QUARRY: "QUARRY",
};
// ************************************************
// Licences
// ************************************************
export const LICENCE_EDIT_SCREENS = {
    list: "list",
    edit: "edit",
    add: "add",
};

// ************************************************
// workExperience
// ************************************************
export const WORK_EXPERIENCE_EDIT_SCREENS = {
    list: "list",
    edit: "edit",
    add: "add",
};

// ************************************************
// Skills
// ************************************************
export const SERVICE_EDIT_SCREENS = {
    list: "list",
    add: "add",
};

// ************************************************
// titleStrings
// ************************************************
export const SECTION_TITLES = {
    licences: "Licences & Tickets",
    workExperiences: "Experience",
    expertise: "Expertise",
    services: "Services",
    about: "About",
    locations: "Locations",
};

// ************************************************
// Alert types
// ************************************************
export const ALERT_TYPES = {
    INFO: "INFO",
    WARNING: "WARNING",
    ERROR: "ERROR",
    SUCCESS: "SUCCESS",
};

// ************************************************
// Input Types
// ************************************************
export const INPUT_TYPES = {
    SEARCH_TEXT: "SEARCH_TEXT",
    TEXT_AREA: "TEXT_AREA",
    TEXT: "TEXT",
    DATE: "DATE",
    SELECT: "SELECT",
    CHECKBOX: "CHECKBOX",
};

// ************************************************
// validation rules
// ************************************************
export const COMPANY_PROFILE_VALIDATION_RULES = {
    name: (candidate, key) => {
        // TODO tried being fancy and checking if company with the same name exists in DB - didn't work, doesn't appear to deal with async functions, I'm sure it solvable but probably just a nice to have really
        if (candidate[key] === "") {
            return "Company name is required";
        }
    },
    companyType: (candidate, key) => {
        if (candidate[key] === "") {
            return "Company type is required";
        }
    },
    phone: (candidate, key) => {
        if (candidate[key] === "") {
            return "A phone number is required";
        }
    },
    // Going to remove email requirement
    // email: (candidate, key) => {
    //     if (candidate[key] === "") {
    //         return "An email is required";
    //     }
    // },
    OfficeLocations: (candidate, key) => {
        if (
            candidate[key][0].address === "" ||
            candidate[key][0].latLng === {}
        ) {
            return "An address is required";
        }
    },
};

export const USER_PROFILE_VALIDATION_RULES = {
    givenName: (candidate, key) => {
        if (candidate[key] === "") {
            return "Given name is required";
        }
    },
    familyName: (candidate, key) => {
        if (candidate[key] === "") {
            console.log("Rejecting");
            return "Family name is required";
        }
    },
};

export const SITE_PROFILE_VALIDATION_RULES = {
    name: (candidate, key) => {
        if (candidate[key] === "") {
            return "Site name is required";
        }
    },
};

export const LICENCE_VALIDATION_RULES = {
    licenceName: (candidate, key) => {
        if (candidate[key] === "") {
            return "Licence name is required";
        }
    },
    issueDate: (candidate, key) => {
        if (new Date(candidate[key]) > new Date()) {
            return "Issue date can not be in the future";
        }
    },
};

export const WORK_EXPERIENCE_VALIDATION_RULES = {
    companyName: (candidate, key) => {
        if (candidate[key] === "") {
            return "A company is required";
        }
    },
    jobTitle: (candidate, key) => {
        if (candidate[key] === "") {
            return "Job title is required";
        }
    },
    startDate: (candidate, key) => {
        if (candidate[key] === "") {
            return "A start date is required";
        }
    },
};

// ************************************************
// Primary author post options
// ************************************************
export const PRIMARY_AUTHOR = {
    // NOTE: * This must match PRIMARY_AUTHOR in server *
    USER: "USER",
    COMPANY: "COMPANY",
};

// ************************************************
// NewsFeed Item Types
// ************************************************

export const NEWSFEED_ITEM_TYPE = {
    POST: "POST",
    FRIEND_SUGGESTION: "FRIEND SUGGESTION",
};
