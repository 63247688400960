import { useCallback, useEffect, useRef, useState } from "react";

import ExploreMapView from "./ExploreMap-view";
import {
    CHRISTCHURCH_COORDS,
    mapStyleOptions,
    MAP_INFO_DATA_TYPES,
    COMPANY_TYPE,
    MAP_ICON_BOBCAT_O,
    MAP_ICON_BOBCAT_Y,
    MAP_ICON_DIGGER_O,
    MAP_ICON_DIGGER_Y,
    MAP_ICON_LOADER_Y,
    MAP_ICON_LOADER_O,
    MAP_ICON_CONTRACTOR,
    MAP_ICON_HIRE_COMPANY,
    MAP_ICON_MATERIALS_SUPPLIER,
    MAP_ICON_ROLLER_O,
    MAP_ICON_ROLLER_Y,
    MAP_ICON_TRUCK_O,
    MAP_ICON_TRUCK_Y,
} from "../../library/common/constants";

import { useApi } from "../../services/api";

const ExploreMapContainer = ({ theme, setShowSidebar, history }) => {
    const [isMounted, setIsMounted] = useState(true);
    const [showRefreshBtn, setShowRefreshBtn] = useState(false);
    const [infoBarContent, setInfoBarContent] = useState({});
    const [expandedWindow, setExpandedWindow] = useState(false);

    const closeInfoWindow = () => {
        setInfoBarContent({});
    };
    const toggleExpandedWindow = () => {
        setExpandedWindow((f) => !f);
    };

    // Hide sidebar on component
    useEffect(() => {
        setShowSidebar(false);
        return () => {
            setShowSidebar(true);
        };
    }, [setShowSidebar]);

    // use static value and Christchurch coords for now
    const centreToBoundaryVal = 0.27; // 1 degree is 111km
    const mapCenterAndZoom = useRef({
        center: CHRISTCHURCH_COORDS,
        zoom: 12,
    });

    // Map bounds must be stored in state so can be kept up to date with users position (as opposed to one render behind)
    const [mapBounds, setMapBounds] = useState({
        north: CHRISTCHURCH_COORDS.lat + centreToBoundaryVal,
        south: CHRISTCHURCH_COORDS.lat - centreToBoundaryVal,
        east: CHRISTCHURCH_COORDS.lng + centreToBoundaryVal,
        west: CHRISTCHURCH_COORDS.lng - centreToBoundaryVal,
    });

    const {
        data: mapData = { sites: [], companies: [] },
        isLoading,
        refresh: refreshMap,
    } = useApi({
        method: "post",
        url: "/api/map",
        data: mapBounds,
    });

    // change isMounted state when component is dismounted
    // to prevent react error in map configuration
    useEffect(() => {
        return () => {
            console.log("explore map dismounting");
            setIsMounted(false);
        };
    }, []);

    // Function called in Map - Configure map with idle listeners
    const configureMapBehaviour = useCallback(
        (map, setMapConfigured, zoom) => {
            setMapConfigured(false); // tells Map to re run addMarkersToMap
            // define initial position of map
            map.setCenter(mapCenterAndZoom.current.center);
            map.setZoom(mapCenterAndZoom.current.zoom);
            // Set map options (how many place labels to show etc)
            map.setOptions(mapStyleOptions);

            // add a listener for idle events in map
            // When user stops moving - check whats changed on map (how far they moved or if zoomed),
            // update positions AND show refresh btn if necessary
            map.addListener("idle", () => {
                const currentMapCenter = map.getCenter().toJSON();
                const currentMapZoom = map.getZoom();
                if (
                    // check if map center has moved OR if map has been zoomed out twice
                    Object.keys(currentMapCenter).some(
                        (key) =>
                            Math.abs(
                                currentMapCenter[key] -
                                    mapCenterAndZoom.current.center[key]
                            ) >= centreToBoundaryVal
                    ) ||
                    currentMapZoom < mapCenterAndZoom.current.zoom - 1
                ) {
                    // update mapCenterAndZoom for next idle event
                    mapCenterAndZoom.current = {
                        center: map.getCenter().toJSON(),
                        zoom: map.getZoom(),
                    };
                    // update mapBounds state for refresh function
                    const newBounds = map.getBounds().toJSON();
                    const newBoundsPlusPadding = {
                        north: newBounds.north + centreToBoundaryVal,
                        south: newBounds.south - centreToBoundaryVal,
                        east: newBounds.east + centreToBoundaryVal,
                        west: newBounds.west - centreToBoundaryVal,
                    };
                    setMapBounds(newBoundsPlusPadding);
                    if (isMounted) {
                        // Add refresh btn when map moves
                        setShowRefreshBtn(true);
                    }
                }
            });
            setMapConfigured(true);
        },
        [isMounted]
    );

    const baseMarkerSize = 45; // marker size
    const smMarkerSize = baseMarkerSize * 0.75;

    // Function called in Map - adds markers to state and updates markers as locationArray changes
    const addMarkersToMap = useCallback(
        ({ map, locationsArray, clearMarkers, addMarkerToMarkers }) => {
            // Clear markers from last render so user isn't creating duplicates or continually
            //  adding to the number of markers on map as they move around
            clearMarkers();

            // Clear infoBar from screen on map click
            map.addListener("click", () => {
                closeInfoWindow();
            });

            const { sites = [], companies = [] } = locationsArray;

            // Add site markers
            const siteMarkers = [
                MAP_ICON_BOBCAT_O,
                MAP_ICON_DIGGER_Y,
                MAP_ICON_BOBCAT_Y,
                MAP_ICON_LOADER_Y,
                MAP_ICON_DIGGER_O,
                MAP_ICON_ROLLER_O,
                MAP_ICON_ROLLER_Y,
                MAP_ICON_LOADER_O,
                MAP_ICON_TRUCK_O,
                MAP_ICON_TRUCK_Y,
            ];
            let markerIndex = 0;
            sites.forEach((site) => {
                const marker = new window.google.maps.Marker({
                    map: map,
                    position: site.latLng,
                    icon: {
                        // url: `${window.location.origin}/MarkerClusterer/MapIconDigger_Y.svg`,
                        // url: `${window.location.origin}/MarkerClusterer/MapMarkerBoomsUpLogo.svg`,
                        url: siteMarkers[markerIndex],
                        // url: CREWCLUB_ICON,
                        // scale: 0.1,
                        scaledSize: new window.google.maps.Size(
                            baseMarkerSize,
                            baseMarkerSize
                        ),
                    },
                });
                addMarkerToMarkers({ marker });

                // Adds 1 to marker index or resets to 0 when siteMarkers.length reached
                markerIndex < siteMarkers.length - 1
                    ? (markerIndex += 1)
                    : (markerIndex = 0);

                marker.addListener("click", () => {
                    map.panTo(marker.getPosition());
                    setInfoBarContent({
                        type: MAP_INFO_DATA_TYPES.site,
                        ...site,
                    });
                });
            });

            // Add company makers
            companies.forEach((company) => {
                const { companyType, officeLocations = {} } = company;
                const { latLng: position } = officeLocations;

                const icon = {};
                switch (companyType) {
                    case COMPANY_TYPE.MATERIAL_SUPPLIER:
                        Object.assign(icon, {
                            url: MAP_ICON_MATERIALS_SUPPLIER,
                            scaledSize: new window.google.maps.Size(
                                baseMarkerSize,
                                baseMarkerSize
                            ),
                        });
                        break;
                    case COMPANY_TYPE.HIRE_COMPANY:
                        Object.assign(icon, {
                            url: MAP_ICON_HIRE_COMPANY,
                            scaledSize: new window.google.maps.Size(
                                baseMarkerSize,
                                baseMarkerSize
                            ),
                        });
                        break;
                    case COMPANY_TYPE.QUARRY:
                        Object.assign(icon, {
                            url: MAP_ICON_CONTRACTOR,
                            scaledSize: new window.google.maps.Size(
                                baseMarkerSize,
                                baseMarkerSize
                            ),
                        });
                        break;
                    default:
                        Object.assign(icon, {
                            url: MAP_ICON_CONTRACTOR,
                            scaledSize: new window.google.maps.Size(
                                smMarkerSize,
                                smMarkerSize
                            ),
                        });
                        break;
                }

                const marker = new window.google.maps.Marker({
                    map,
                    position,
                    icon,
                });
                addMarkerToMarkers({ marker });

                marker.addListener("click", () => {
                    map.panTo(marker.getPosition());
                    setInfoBarContent({
                        type: MAP_INFO_DATA_TYPES.company,
                        ...company,
                    });
                });
            });

            if (isMounted) {
                setShowRefreshBtn(false);
            } // remove refresh button after new pins loaded
        },
        [isMounted, smMarkerSize]
    );

    const viewProps = {
        theme,
        history,
        isLoading,
        showRefreshBtn,
        configureMapBehaviour,
        addMarkersToMap,
        refreshMap,
        mapData,
        infoBarContent,
        expandedWindow,
        toggleView: toggleExpandedWindow,
    };

    return <ExploreMapView {...viewProps} />;
};

export default ExploreMapContainer;

// TURNED OFF MARKET CLUSTERING FOR NOW
// const markers = [...siteMarkers, ...companyMarkers];
// // enable a marker clusterer function
// const clusterOptions = {
//     styles: [
//         {
//             url: CLUSTER_ICON_2,
//             width: 70,
//             height: 70,
//             anchorText: [22.5, -1.5],
//             anchorIcon: [35, 35],
//             textColor: theme.colors.primary,
//             textSize: 20,
//             fontWeight: 400,
//         },
//     ],
// };
// new MarkerClusterer(map, markers, clusterOptions);
