import { useContext, useState } from "react";
import NavBar from "./Navbar-view";
import { useAuth0 } from "@auth0/auth0-react";

import { NAV_DROPDOWN_TYPES } from "../../library/common/constants";
import { checkForUnreadMsgInChat } from "../../library/utilities";

import SocketContext from "../../services/socket";

const NavbarContainer = ({
    theme,
    currentUser,
    // logout,
    history,
    setPopUp,
    setFullScreenSidebar,
    fullScreenSidebar,
    criticalScreenSize,
}) => {

    const useIO = useContext(SocketContext);
    const { notifications, conversations } = useIO();

    // dropdown to render
    const [dropdown, setDropdown] = useState("");

    // Show search box in navbar when true
    const [showFullSearchBox, setShowFullSearchBox] = useState(false);

    const { logout } = useAuth0();

    // Update dropdown state as user clicks nav dropdown buttons
    const handleDropdownClick = (name) => {
        if (dropdown !== NAV_DROPDOWN_TYPES[name]) {
            if (NAV_DROPDOWN_TYPES[name] === NAV_DROPDOWN_TYPES.notifications) {
                // if notifications button clicked
                if (history.location.pathname !== "/notifications") {
                    // Check user is not at "/notifications" location. I.e. stop notifications dropdown appearing when user is on the full screen view of notifications
                    setDropdown(NAV_DROPDOWN_TYPES[name]);
                }
            } else {
                setDropdown(NAV_DROPDOWN_TYPES[name]);
            }
        } else {
            setDropdown("");
        }
    };

    // Handles select from searchbox dropdown
    const handleSelect = (result, searchType) => {
        if (searchType.clientRoute) {
            // remove search box before navigating to page
            setShowFullSearchBox(false);
            history.push(`${searchType.clientRoute}/${result.id}`);
        } else {
            alert("No view route");
        }
    };

    const numUnreadNotifications = notifications
        .filter((ntf) => !ntf.hasBeenSeen)
        .length;

    const numUnreadChats = conversations
        .filter((chat) => checkForUnreadMsgInChat(chat, currentUser.user.id))
        .length;

    return (
        <NavBar
            theme={theme}
            currentUser={currentUser}
            dropdown={dropdown}
            setDropdown={setDropdown}
            handleDropdownClick={handleDropdownClick}
            // logoutUser={logoutUser}
            logoutUser={logout}
            history={history}
            setPopUp={setPopUp}
            fullScreenSidebar={fullScreenSidebar}
            setFullScreenSidebar={setFullScreenSidebar}
            criticalScreenSize={criticalScreenSize}
            showFullSearchBox={showFullSearchBox}
            setShowFullSearchBox={setShowFullSearchBox}
            handleSelect={handleSelect}
            numUnreadNotifications={numUnreadNotifications}
            numUnreadChats={numUnreadChats}
        />
    );
};

export default NavbarContainer;
