import React from 'react';
// import Icon from 'react-native-vector-icons/FontAwesome5';
import { withTheme } from 'styled-components';

const createIcon = ({
    theme,
    name,
    brands = false,
    size = 25,
    color,
    invert = false,
    light,
    solid,
    regular,
    brand,
    ...otherStyles
}) => {

    const { primary, secondary } = theme.colors;
    const iconColor = color || (invert ? secondary : primary);

    return (
        // <Icon
        //     name={name}
        //     size={size}
        //     color={iconColor}
        //     light={light}
        //     solid={solid}
        //     regular={regular}
        //     brand={brand}
        //     {...otherStyles}
        // />
        <i
            className={brands ? `fab fa-${name}` : `fas fa-${name}`}
            color={iconColor}
            style={otherStyles}
        />
    );
};

const Icon = (props) => createIcon(props);
export default withTheme(Icon);

export const ICON_NAME = {
    ACCEPT_USER: "user-check",
    ADD: "plus",
    ADD_SECTION: "plus-circle",
    ADD_USER: "user-plus",
    ARROW_LEFT: "arrow-left",
    ARROW_RIGHT: "arrow-right",
    AWARD: "award",
    BUILD: "cubes",
    CLOSE: "times",
    COMPANY: "building",
    CROSS: "times-circle",
    DELETE: "trash-alt",
    EDIT: "pen",
    EDIT_USER: "user-edit",
    EXPERTISE: "hard-hat",
    FOLLOW: "plus-circle",
    FOLLOWED: "check-circle",
    INFO: "info-circle",
    LICENCE: "university",
    LOAD_MORE: "arrow-circle-down",
    LOCATION: "map-marker-alt",
    LOCKED: "lock",
    MESSAGE: "envelope",
    OPTIONS: "ellipsis-h",
    PASTE: "clipboard",
    PENDING_USER: "user-clock",
    PEOPLE: "user-friends",
    PHONE: "phone",
    PHOTO: "camera",
    PIN: "map-pin",
    REMOVE: "times",
    REMOVE_USER: "user-times",
    REQUESTED_USER: "share",
    SERVICE: "hard-hat",
    TICK: "check-circle",
    UNFOLLOW: "times-circle",
    USER: "user",
    WEB: "globe",
    WORK_EXPERIENCE: "compass",
};

export const AcceptUserIcon = withTheme(props => createIcon({ name: ICON_NAME.ACCEPT_USER, ...props }));
export const AddIcon = withTheme(props => createIcon({ name: ICON_NAME.ADD, ...props }));
export const AddSectionIcon = withTheme(props => createIcon({ name: ICON_NAME.ADD_SECTION, ...props }));
export const AddUserIcon = withTheme(props => createIcon({ name: ICON_NAME.ADD_USER, ...props }));
export const ArrowLeftIcon = withTheme(props => createIcon({ name: ICON_NAME.ARROW_LEFT, ...props }));
export const ArrowRightIcon = withTheme(props => createIcon({ name: ICON_NAME.ARROW_RIGHT, ...props }));
export const AwardIcon = withTheme(props => createIcon({ name: ICON_NAME.AWARD, ...props }));
export const BuildIcon = withTheme(props => createIcon({ name: ICON_NAME.BUILD, ...props }));
export const CloseIcon = withTheme(props => createIcon({ name: ICON_NAME.CLOSE, ...props }));
export const CompanyIcon = withTheme(props => createIcon({ name: ICON_NAME.COMPANY, ...props }));
export const CrossIcon = withTheme(props => createIcon({ name: ICON_NAME.CROSS, ...props }));
export const DeleteIcon = withTheme(props => createIcon({ name: ICON_NAME.DELETE, ...props }));
export const EditIcon = withTheme(props => createIcon({ name: ICON_NAME.EDIT, ...props }));
export const EditUserIcon = withTheme(props => createIcon({ name: ICON_NAME.EDIT_USER, ...props }));
export const ExpertiseIcon = withTheme(props => createIcon({ name: ICON_NAME.EXPERTISE, ...props }));
export const FollowIcon = withTheme(props => createIcon({ name: ICON_NAME.FOLLOW, ...props }));
export const FollowedIcon = withTheme(props => createIcon({ name: ICON_NAME.FOLLOWED, ...props }));
export const InfoIcon = withTheme(props => createIcon({ name: ICON_NAME.INFO, ...props }));
export const LicenceIcon = withTheme(props => createIcon({ name: ICON_NAME.LICENCE, ...props }));
export const LoadMoreIcon = withTheme(props => createIcon({ name: ICON_NAME.LOAD_MORE, ...props }));
export const LocationIcon = withTheme(props => createIcon({ name: ICON_NAME.LOCATION, ...props }));
export const LockedIcon = withTheme(props => createIcon({ name: ICON_NAME.LOCKED, ...props }));
export const MessageIcon = withTheme(props => createIcon({ name: ICON_NAME.MESSAGE, ...props }));
export const OptionsIcon = withTheme(props => createIcon({ name: ICON_NAME.OPTIONS, ...props }));
export const PasteIcon = withTheme(props => createIcon({ name: ICON_NAME.PASTE, ...props }));
export const PendingUserIcon = withTheme(props => createIcon({ name: ICON_NAME.PENDING_USER, ...props }));
export const PeopleIcon = withTheme(props => createIcon({ name: ICON_NAME.PEOPLE, ...props }));
export const PhoneIcon = withTheme(props => createIcon({ name: ICON_NAME.PHONE, ...props }));
export const PhotoIcon = withTheme(props => createIcon({ name: ICON_NAME.PHOTO, ...props }));
export const PinIcon = withTheme(props => createIcon({ name: ICON_NAME.PIN, ...props }));
export const RemoveIcon = withTheme(props => createIcon({ name: ICON_NAME.REMOVE, ...props }));
export const RemoveUserIcon = withTheme(props => createIcon({ name: ICON_NAME.REMOVE_USER, ...props }));
export const RequestedUserIcon = withTheme(props => createIcon({ name: ICON_NAME.REQUESTED_USER, ...props }));
export const ServiceIcon = withTheme(props => createIcon({ name: ICON_NAME.SERVICE, ...props }));
export const TickIcon = withTheme(props => createIcon({ name: ICON_NAME.TICK, ...props }));
export const UnfollowIcon = withTheme(props => createIcon({ name: ICON_NAME.UNFOLLOW, ...props }));
export const UserIcon = withTheme(props => createIcon({ name: ICON_NAME.USER, ...props }));
export const WebIcon = withTheme(props => createIcon({ name: ICON_NAME.WEB, ...props }));
export const WorkExperienceIcon = withTheme(props => createIcon({ name: ICON_NAME.WORK_EXPERIENCE, ...props }));