import React from "react";
import ReactDOM from "react-dom";
import "./resources/styles/index.css";
import App from "./main/routes/App";
import { Auth0Provider } from "@auth0/auth0-react";
import reportWebVitals from "./reportWebVitals";
import ErrorBoundary from "./services/ErrorBoundary";
import { API_AUDIENCE } from "./library/common/constants";

ReactDOM.render(
    <React.StrictMode>
        <ErrorBoundary>
            <Auth0Provider
                domain="cosqos.au.auth0.com"
                clientId="te905dauJ7TZyvSeusBGW7yKCzxgRlvF"
                redirectUri={window.location.origin}
                audience={API_AUDIENCE}
                useRefreshTokens={true}
            >
                <App />
            </Auth0Provider>
        </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
