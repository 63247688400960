import React from "react";
import { withRouter } from "react-router";
import styled, { withTheme } from "styled-components";

import Map from "../../library/common/Map";
import Avatar from "../../library/common/Avatar";
import EditButton from "../../library/common/ButtonEditCircle";
import { addMarkersToMap } from "../../library/utilities";

import {
    CONNECTION_STATUS,
    REACT_APP_DEFAULT_USER_PROFILE,
    SITE_POPUP_TYPES,
    MAP_ICON_DIGGER_Y,
} from "../../library/common/constants";

import { SectionBox, HeaderBox, BoxTitle } from "../../resources/styles";
import { LocationIcon, PeopleIcon } from "../../library/common/Icon";

const avatarSize = "3rem";

const AboutPage = ({
    siteDetails,
    history,
    theme,
    setPopUpToDisplay,
    createSiteView,
}) => {
    const acceptedCrewMembers = siteDetails.crew.filter(
        (member) => member.status === CONNECTION_STATUS.ACCEPTED
    );

    const UsersToRender = acceptedCrewMembers.map((user) => (
        <FlexRow key={user._id}>
            <UserClickArea
                onClick={(e) => {
                    history.push(`/users/${user._id}`);
                }}
            >
                <Avatar
                    path={user.img || REACT_APP_DEFAULT_USER_PROFILE}
                    alt={user.username}
                    size={"3rem"}
                    borderColor={theme.colors.offPrimary}
                    showPointer={true}
                />
                <Username>{user.username}</Username>
            </UserClickArea>
        </FlexRow>
    ));

    const UserJobTitlesToRender = acceptedCrewMembers.map((user) => (
        <FlexRow key={user._id}>
            {user.jobTitle !== undefined && (
                <JobTitle>{user.jobTitle}</JobTitle>
            )}
        </FlexRow>
    ));

    return (
        <MapWrapper>
            <ContentCol>
                {acceptedCrewMembers.length > 0 && (
                    <SectionWrapper>
                        <SectionBox>
                            <HeaderBox>
                                <BoxTitle>
                                    <PeopleIcon marginRight={"1rem"} />
                                    People
                                </BoxTitle>
                                {/* {userIsCompanyAdmin && (
                                    <EditButton
                                    handleClick={profileSections.locations.edit}
                                    invDefaultColors={true}
                                    circleDiameter={sectionHeaderBtnSize}
                                    fontSize={sectionHeaderBtnFontSize}
                                    />
                                )} */}
                            </HeaderBox>
                            <UserListWrapper>
                                <LeftCol>{UserJobTitlesToRender}</LeftCol>
                                <RightCol>{UsersToRender}</RightCol>
                            </UserListWrapper>
                        </SectionBox>
                    </SectionWrapper>
                )}
                <SectionWrapper>
                    <SectionBox>
                        <HeaderBox>
                            <BoxTitle>
                                <LocationIcon marginRight={"1rem"} />
                                Location
                            </BoxTitle>
                            {siteDetails.userIsAdmin && (
                                <EditButton
                                    handleClick={() =>
                                        setPopUpToDisplay(
                                            SITE_POPUP_TYPES.location
                                        )
                                    }
                                    invDefaultColors={true}
                                    circleDiameter={"2.4rem"}
                                    fontSize={"1.1rem"}
                                />
                            )}
                        </HeaderBox>
                        <MapContentWrapper>
                            <Map
                                addMarkersToMap={addMarkersToMap}
                                locationsArray={
                                    // latLng comes in different formats form createSiteView and regular siteProfile (the array format is needed to stop re re-renders in createSiteView)
                                    createSiteView
                                        ? [...siteDetails.locationsArr]
                                        : [{ latLng: siteDetails.latLng }]
                                }
                                zoom={13}
                                siteIcon={true}
                                markerPin={MAP_ICON_DIGGER_Y}
                                markerPinSize={55}
                            />
                        </MapContentWrapper>
                    </SectionBox>
                </SectionWrapper>
            </ContentCol>
        </MapWrapper>
    );
};

export default withTheme(withRouter(AboutPage));

const MapWrapper = styled.div`
    margin: 1rem 0;
    display: flex;
    justify-content: center;
`;

const ContentCol = styled.div`
    flex: 1;
    max-width: min(650px, 95vw);
`;

const SectionWrapper = styled.div`
    padding: 1rem 0;
    position: relative;
`;

const UserListWrapper = styled.div`
    display: flex;
    padding: 1rem 0.5rem; /* padding around the whole list box */
`;

const LeftCol = styled.div`
    flex: 0 1 150px;
    border-right: 1px solid ${(props) => props.theme.colors.offPrimary};
    padding: 0 0.5rem; /* Outsides of left col*/
`;

const RightCol = styled.div`
    flex: 1 1.5 400px; /* Shrinks faster than LeftCol*/
    padding: 0 0.5rem 0 1rem;
`;

const FlexRow = styled.div`
    display: flex;
    margin-top: 0.5rem; /* Vertical spacing between rows */
    min-height: ${avatarSize}; /* Ensure rows in left col always match right in height  (assuming avatarSize is larger than text) */
    align-items: center;
`;

const UserClickArea = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    /* background-color: ${(props) => props.theme.colors.offPrimary}; */
`;

const Username = styled.h6`
    padding-left: 0.5rem;
    margin: 0;
`;

const JobTitle = styled.p`
    margin: 0;
    color: ${(props) => props.theme.colors.offSecondary};
`;

const MapContentWrapper = styled.div`
    position: relative;
    height: 500px;
`;
