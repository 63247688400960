import React from "react";

// This component is taken strait front react docs. It displays custom error JSX when a react error occures in the render method, a useEffect and a few other "reacty" type errors. This is not triggered when an errro occurs in an event hander.
// There is an npm package called react-error-boundry which can do this logic for you
// This is essentially hidden at the moment as the create react app has its own error overly that is shown over the top. This overly is removed in deployment
// TODO edit the custom UI fallback message
class ErrorBoundary extends React.Component {
   constructor(props) {
      super(props);
      this.state = { hasError: false };
   }

   static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
   }

   componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      console.log("An Error Occured");
      console.log(error.message);
   }

   render() {
      if (this.state.hasError) {
         // You can render any custom fallback UI
         return <h1>Something went wrong.</h1>;
      }

      return this.props.children;
   }
}

export default ErrorBoundary;
