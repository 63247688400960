import styled, { withTheme } from "styled-components";

import LetterBox from "./LetterBox";

// Info window for site markers
const SiteInfoWindowView = ({ theme, site }) => {
    // Note have not been able to work out a way to add an click event listener to the contents of infoWindow (would like history push to site on infoWindow click)
    return (
        <Wrapper id={site.id}>
            {!!site.img && <Img src={site.img} alt={"Error"} />}
            {!site.img && (
                <BoxSize>
                    <LetterBox
                        letters={site.name[0]}
                        borderRadius={"0.5rem"}
                        // bgColor={theme.colors.darkAccent}
                        bgColor={theme.colors.secondary}
                    />
                </BoxSize>
            )}
            <SiteName>{site.name}</SiteName>
        </Wrapper>
    );
};

export default withTheme(SiteInfoWindowView);

const Wrapper = styled.div`
    position: relative;
    cursor: pointer;
`;

const Img = styled.img`
    object-fit: cover;
    vertical-align: middle;
    width: 200px;
    height: 200px;
`;

const BoxSize = styled.div`
    position: relative;
    width: 200px;
    height: 200px;
`;

const SiteName = styled.h5`
    padding: 0.7rem 0.2rem 0 0.2rem;
    margin: 0;
    text-align: center;
`;
