import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import MySites from "./MySites-container";
import { addError, removeError } from "../../main/store/actions/errors";
import { setMySites } from "../../main/store/actions/mySites";
import { setShowSidebar } from "../../main/store/actions/sidebar";

const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
    errors: state.errors,
    mySites: state.mySites.sites,
});

const mapDispatchToProps = {
    addError,
    removeError,
    setShowSidebar,
    setMySites,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MySites));
