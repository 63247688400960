import styled from "styled-components";

import Avatar from "../../library/common/Avatar";
import AvatarLineUp from "../../library/common/AvatarLineUp";
import { formatDateForMessage } from "../../library/utilities";
import { CHAT_POPUP_TYPES } from "../../library/common/constants";
import withFullScreenClick from "../../library/hoc/withImgFullScreenClick";

const AvatarSizeInThread = "2.2rem";
// const clusterTimeWindow = 120; // seconds
const MAX_CLUSTER_TIME = 120000;

// *** These must exactly match the CONVERSATION_EVENT_TYPE in server! ***
const CONVERSATION_EVENT_TYPE = {
    ADDED_MEMBERS: "ADDED MEMBERS",
    REMOVED_MEMBERS: "REMOVED MEMBERS",
    LEFT_CONVERSATION: "LEFT CONVERSATION",
    EDITED_FIELD: "EDITED FIELD",
};

// Renders thread of messages in chat
const MessagesThreadView = ({
    theme,
    openFullScreenImgView,
    chatDetails = {},
    setPopUpToDisplay,
}) => {
    // if (!chatDetails.messages) {
    //     return <div></div>; // return empty div if no messages in chat
    // } else {
    const { messages = [], events: eventsTemp = [], members = [] } = chatDetails;
    const events = eventsTemp || []; // server is returning "null" events instead of empty array
    const groupChat = members.length > 2;

    // converts event details to a descriptive string
    const getEventString = (event) => {

        const {
            actionedByUserId: actionedById,
            eventTargetNumUsers: targetNumUsers,
            eventTargetUserId: targetUserId,
            eventType,
            valueOfEditedField: newGroupName,
        } = event;

        const actionedBy = members.filter(el => el.userId === actionedById)[0].givenName;

        switch (eventType) {

            case CONVERSATION_EVENT_TYPE.ADDED_MEMBERS:
            case CONVERSATION_EVENT_TYPE.REMOVED_MEMBERS:
                const action = eventType === CONVERSATION_EVENT_TYPE.ADDED_MEMBERS ?
                    "added" : "removed";
                const targetUser = members.filter(el => el.userId === targetUserId)[0].username;
                const numOtherUsers = targetNumUsers - 1;
                const otherUsers = numOtherUsers > 0 ?
                    `and ${numOtherUsers} other user${numOtherUsers > 1 ? "s" : ""}` : "";
                return `${actionedBy} ${action} ${targetUser} ${otherUsers}`;

            case CONVERSATION_EVENT_TYPE.EDITED_FIELD:
                return `${actionedBy} named the group "${newGroupName}".`;

            case CONVERSATION_EVENT_TYPE.LEFT_CONVERSATION:
                return `${actionedBy} left the group.`;

            default: return null;
        }
    };

    // setup array of images used in image full screen view
    const allImgsLoaded = [];
    let imgIndex = 0; // monitors the index of each image

    // add each image in chat to allImgsLoaded also add reference to images index in allImgsLoaded
    const messagesWithImgIndex = messages.map(msg => {
        if (!msg.images) return msg; // if no images arr just return message unchanged
        // if images in message convert image array to be arr of object with path and index of each image in allImgsLoaded
        const imgsWithIndex = msg.images.map(img => {
            imgIndex += 1;
            allImgsLoaded.push(img);
            return { path: img, imgIndexInAll: imgIndex - 1 };
        });
        return { ...msg, images: imgsWithIndex };
    });

    // combine messages and events
    const messagesAndEvents = messagesWithImgIndex
        .map(msg => ({
            ...msg,
            lastSeenDate: msg.lastSeenDate ?
                `Seen ${formatDateForMessage(msg.lastSeenDate)}` : null,
            dateString: formatDateForMessage(msg.messageDate),
        }))
        .concat(
            events.map(event => ({
                ...event,
                dateString: formatDateForMessage(event.eventDate),
                eventString: getEventString(event),
            }))
        )
        // sort messages and events together by date
        .sort((a, b) => a.date - b.date)
        // add clustering to messages
        .map((item, i, arr) => {

            if (item.eventString) return item;

            const msgDate = Date.parse(item.messageDate);
            const prevMsgDate = arr[i - 1] ? Date.parse(arr[i - 1].messageDate) : null;
            const nextMsgDate = arr[i + 1] ? Date.parse(arr[i + 1].messageDate) : null;

            const clusteredWithPrev = msgDate - prevMsgDate < MAX_CLUSTER_TIME || false;
            const clusteredWithNext = nextMsgDate - msgDate < MAX_CLUSTER_TIME || false;

            return { ...item, clusteredWithPrev, clusteredWithNext };

        });

    const Message = (props) => {

        const {
            oldestItem,
            images = [],
            messageText,
            senderDetails,
            sentByCurrentUser,
            seenByUsersArr,
            lastSeenDate,
            dateString,
            clusteredWithPrev,
            clusteredWithNext,
        } = props;

        const avatar = (!clusteredWithPrev && !sentByCurrentUser && senderDetails) ?
            <Avatar
                path={senderDetails.img}
                alt={senderDetails.fullName}
                size={AvatarSizeInThread}
                borderColor={
                    theme.colors.offPrimary
                }
                // showPointer={true}
                user={true}
            /> : null;

        const messageImages = images.map((img, i) => (
            <MessagePicWrapper key={i}>
                <MessagePic
                    onClick={() => openFullScreenImgView(allImgsLoaded, img.imgIndexInAll)}
                    src={img.path}
                    alt={"Image"}
                />
            </MessagePicWrapper>
        ));

        const messageTextBubble = messageText.trim().length > 0 ?
            <MessageBubble {...{ sentByCurrentUser }} >
                {messageText}
            </MessageBubble > : null;

        const seenByIndicator = clusteredWithNext ? null :
            groupChat ?
                <AvatarLineUp
                    theme={theme}
                    avatarArray={seenByUsersArr}
                    handleClick={() => setPopUpToDisplay(CHAT_POPUP_TYPES.seenByUsers)}
                    maxAvatars={10}
                    avatarSize={"1.4rem"}
                /> :
                lastSeenDate ? <SeenMessage>{lastSeenDate}</SeenMessage> : null;

        return (
            <MessageWrapper>
                {!clusteredWithPrev && (
                    <TimeStampRow oldestItem={oldestItem}>
                        <TimeStamp>{dateString}</TimeStamp>
                    </TimeStampRow>
                )}
                <MessageRow {...{ sentByCurrentUser, clusteredWithPrev }}>
                    <AvatarWrapper {...{ sentByCurrentUser }}>
                        {avatar}
                    </AvatarWrapper>
                    <DetailsWrapper {...{ sentByCurrentUser }}>
                        {messageImages}
                        {messageTextBubble}
                        <SeenWrapper>{seenByIndicator}</SeenWrapper>
                    </DetailsWrapper>
                </MessageRow>
            </MessageWrapper >
        );
    };

    const thread = messagesAndEvents.map((el, i) => {
        if (el.eventString) return (
            <EventWrapper key={i}>
                <TimeStampRow oldestItem={i === 0}>
                    <TimeStamp>{el.dateString}</TimeStamp>
                </TimeStampRow>
                <EventStringRow>{el.eventString}</EventStringRow>
            </EventWrapper>
        );
        return <Message {...el} key={i} oldestItem={i === 0} />;
    });

    return thread;
};

export default withFullScreenClick(MessagesThreadView);

const TimeStampRow = styled.div`
    display: flex;
    justify-content: center;
    padding-top: ${(props) => (props.oldestItem ? "0" : "1rem")};
    padding-bottom: 0.3rem;
`;

const TimeStamp = styled.div`
    font-size: 0.8rem;
    color: ${(props) => props.theme.colors.offSecondary};
`;

const EventWrapper = styled.div``;

const EventStringRow = styled.div`
    text-align: center;
    font-size: 0.8rem;
    font-style: italic;
    color: ${(props) => props.theme.colors.offSecondary};
`;

const MessageWrapper = styled.div``;

const MessageRow = styled.div`
    display: flex;
    margin-top: 0.3rem;
    flex-direction: ${(props) =>
        props.sentByCurrentUser ? "row-reverse" : "row"};
    min-height: ${AvatarSizeInThread};
`;

const AvatarWrapper = styled.div`
    margin-right: ${(props) => (props.sentByCurrentUser ? 0 : "0.3rem")};
    min-width: ${(props) =>
        props.sentByCurrentUser ? "0" : AvatarSizeInThread};
`;

const DetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${(props) =>
        props.sentByCurrentUser ? "flex-end" : "flex-start"};
`;

const MessageBubble = styled.div`
    background-color: ${({ sentByCurrentUser, theme }) =>
        sentByCurrentUser
            ? theme.colors.veryLightAccent
            : theme.colors.offPrimary};
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    white-space: pre-wrap;
`;

const SeenWrapper = styled.div`
    padding: 0.4rem 0 0 0;
`;

const SeenMessage = styled.div`
    font-size: 0.7rem;
    color: ${(props) => props.theme.colors.offSecondary};
`;

const MessagePicWrapper = styled.div`
    position: relative;
    padding-bottom: 0.5rem;
`;

const MessagePic = styled.img`
    width: min(15rem, 50vw); /* 1 rem account for padding */
    height: min(15rem, 50vw);
    border-radius: 0.5rem;
    object-fit: cover;
    cursor: pointer;
`;
