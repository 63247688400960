import { darken } from "polished";
import styled from "styled-components";
import { AwardIcon, LockedIcon } from "../../library/common/Icon";

import { SectionBox, HeaderBox, BoxTitle } from "../../resources/styles";

const CrewPageView = ({ siteDetails, licencesLoading }) => {
    const LicencesToRender = siteDetails.crewLicences ? (
        siteDetails.crewLicences.licenceCount.map((licence, i) => (
            <LicenceRow key={i}>
                <LicenceNameWrapper>
                    <LicenceName>{licence.licenceName}</LicenceName>
                </LicenceNameWrapper>
                <LicenceNumberWrapper>
                    <NumberCircleWrapper>
                        <LicenceNumber>{licence.count}</LicenceNumber>
                    </NumberCircleWrapper>
                </LicenceNumberWrapper>
                <ProgressBarWrapper>
                    <ProgressBar
                        count={licence.count}
                        crewCount={siteDetails.crewLicences.crewCount}
                    ></ProgressBar>
                </ProgressBarWrapper>
            </LicenceRow>
        ))
    ) : (
        <div></div>
    );

    return (
        <PageWrapper>
            <CrewOnlyMessage>
                <LockedIcon paddingRight={"10px"} />
                Page visible to {siteDetails.name} crew only.
            </CrewOnlyMessage>
            <ContentCol>
                {siteDetails.crewLicences && (
                    <SectionWrapper>
                        <SectionBox darkView={true}>
                            <HeaderBox>
                                <BoxTitle>
                                    <AwardIcon marginRight={"20px"} />
                                    {/* <HeaderIcon className="fas fa-universal-access"></HeaderIcon> */}
                                    Licences & Tickets
                                </BoxTitle>
                            </HeaderBox>
                            {!licencesLoading &&
                                siteDetails.crewLicences &&
                                siteDetails.crewLicences.licenceCount.length >
                                0 && (
                                    <LicencesListWrapper>
                                        {/* <AdminOnlyMessageWrapper>
                                            <LockIcon className="fas fa-lock"></LockIcon>
                                            Only visible to the sites admin
                                            user(s)
                                        </AdminOnlyMessageWrapper> */}
                                        {LicencesToRender}
                                    </LicencesListWrapper>
                                )}
                            {(siteDetails.crewLicences === undefined ||
                                siteDetails.crewLicences.licenceCount.length ===
                                0) && (
                                    <NoLicencesMessage>
                                        Crew members currently have no licencees on
                                        their profile.
                                    </NoLicencesMessage>
                                )}
                        </SectionBox>
                    </SectionWrapper>
                )}
            </ContentCol>
        </PageWrapper>
    );
};

export default CrewPageView;

const PageWrapper = styled.div`
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ContentCol = styled.div`
    flex: 1;
    width: min(650px, 95vw);
`;

// const HeaderIcon = styled.i`
//     margin-right: 1rem;
// `;

const SectionWrapper = styled.div`
    padding: 1rem 0;
    position: relative;
`;

const LicencesListWrapper = styled.div`
    display: block;
    padding: 0.5rem 3rem 2rem 3rem;
    @media (max-width: ${({ theme }) => theme.sizes.smViewWidth}) {
        padding: 0.5rem 1rem 2rem 1rem;
    }
`;

const NoLicencesMessage = styled.div`
    /* min-width: 100%; */
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

// const AdminOnlyMessageWrapper = styled.div`
//     text-align: center;
//     padding: 0 0 1rem 0;
//     color: ${(props) => props.theme.colors.offSecondary};
// `;

// const LockIcon = styled.i`
//     padding-right: 0.5rem;
// `;

const LicenceRow = styled.div`
    display: flex;
    align-items: center;
    padding: 0.3rem 0;
`;

const LicenceNameWrapper = styled.div`
    flex: 0 1 175px;
    @media (max-width: ${({ theme }) => theme.sizes.smViewWidth}) {
        flex: 0 1 125px;
    }
`;

const LicenceName = styled.h6`
    margin: 0;
`;

const LicenceNumberWrapper = styled.div`
    /* flex: 0 1 50px; */
    font-weight: bold;
    padding: 0 1.5rem;
`;

const ProgressBarWrapper = styled.div`
    flex: 1;
    background-color: ${(props) => props.theme.colors.offPrimary};
    border-radius: 15px;
    position: relative;
`;

const ProgressBar = styled.div`
    height: 1.5rem;
    border-radius: 15px;
    background-color: ${(props) => props.theme.colors.progressGreen};
    border: 1px solid
        ${(props) => darken(0.1, props.theme.colors.progressGreen)};
    width: ${(props) => `calc(${props.count}/${props.crewCount}*100%)`};
`;

const NumberCircleWrapper = styled.div`
    background-color: ${(props) => props.theme.colors.secondary};
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LicenceNumber = styled.div``;

const CrewOnlyMessage = styled.div`
    text-align: center;
    padding: 0 0 0.25rem 0;
    font-size: 1rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors.justOffSecondary};
`;
