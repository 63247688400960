import styled from "styled-components";

// Circle component containing a number.
// If number is 0 then display nothing
// If number is > 99 then badge will show 99+ to stop number running outside circle
const BadgeView = ({ circleSize, badgeColor, numberColor, number }) => {
    return (
        <Circle
            badgeColor={badgeColor}
            numberColor={numberColor}
            circleSize={circleSize}
            number={number}
        >
            {number >= 99 ? "99+" : number}
        </Circle>
    );
};

export default BadgeView;

const Circle = styled.div`
    display: ${({ number }) => (number === 0 ? "none" : "flex")};
    border-radius: 50%;
    background-color: ${({ badgeColor }) => badgeColor};
    color: ${({ numberColor }) => numberColor};
    height: ${({ circleSize }) => circleSize};
    width: ${({ circleSize }) => circleSize};
    min-width: ${({ circleSize }) => circleSize};
    min-height: ${({ circleSize }) => circleSize};
    justify-content: center;
    align-items: center;
    margin: 0;
    font-size: calc(${({ circleSize }) => circleSize} / 2.2);
    font-weight: 500;
`;
