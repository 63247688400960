import styled, { withTheme } from "styled-components";

import Avatar from "../Avatar";
import Button from "../Button";
import Icon from "../Icon";
import InputGroup from "../InputGroup";
import LoadingDots from "../LoadingDots";

import {
    CONNECTION_ACTION,
    CONNECTION_STATUS,
    INPUT_TYPES,
} from "../constants";


const UserListView = ({
    theme,
    currentUser,
    searchResults,
    handleChange,
    handleCardClick,
    handleConxAction,
    handleLoadMore,
    canLoadMore,
    isSearching,
    searchString,
    allowConnect = false,
    allowSearch = false,
    searchPlaceholder = "Search",
    noResultsText = "None found.",
    avatarSize = "3.5rem",
    nameTextSize = "1.3rem",
}) => {
    const UserList = searchResults.map((result, i) => {
        // Add friend button is display in list only if connection status is not applicable && of allowConnect
        let ConxBtn;
        if (currentUser.user.id !== result._id) {
            if (result.conxStatus === CONNECTION_STATUS.NOTAPPLICABLE) {
                ConxBtn = (
                    <ConxBtnWrapper>
                        <Button
                            handleClick={(e) =>
                                handleConxAction(
                                    e,
                                    result._id,
                                    CONNECTION_ACTION.ADD
                                )
                            }
                            icon={<Icon name="user-plus" />}
                            bgColor={theme.colors.accent}
                            textColor={theme.colors.primary}
                            noShadow={true}
                            btnPadding={"0 1rem"}
                        >
                            <BtnText>Friend</BtnText>
                        </Button>
                    </ConxBtnWrapper>
                );
            } else if (result.conxStatus === CONNECTION_STATUS.REQUESTED) {
                ConxBtn = (
                    <ConxBtnWrapper>
                        <Button
                            icon={<Icon name="share" />}
                            bgColor={theme.colors.offPrimary}
                            textColor={theme.colors.secondary}
                            noShadow={true}
                            btnPadding={"0 1rem"}
                        >
                            <BtnText>Sent</BtnText>
                        </Button>
                    </ConxBtnWrapper>
                );
            } else {
                ConxBtn = <div></div>;
            }
        }

        return (
            <UserRow key={i}>
                <LeftSide onClick={() => handleCardClick(result._id)}>
                    <AvatarWrapper>
                        <Avatar
                            path={result.img}
                            user={true}
                            size={avatarSize}
                            borderColor={theme.colors.offPrimary}
                            showPointer={true}
                        />
                    </AvatarWrapper>
                    <Username nameTextSize={nameTextSize}>
                        {result.username}
                    </Username>
                </LeftSide>
                {allowConnect && <RightSide>{ConxBtn}</RightSide>}
            </UserRow>
        );
    });

    const LoadingUsers = [...Array(2)].map((_, i) => (
        <UserRow key={i}>
            <LeftSide>
                <AvatarWrapper>
                    <Avatar
                        user={true}
                        size={avatarSize}
                        borderColor={theme.colors.offPrimary}
                        parentLoading={true}
                    />
                </AvatarWrapper>
                <NameBar />
            </LeftSide>
        </UserRow>
    ));

    return (
        <div>
            {allowSearch && (
                <SearchInputWrapper>
                    <InputGroup
                        type={INPUT_TYPES.TEXT}
                        handleChange={handleChange}
                        errorMessage={""}
                        value={searchString}
                        name={"searchUser"}
                        placeholder={searchPlaceholder}
                        inputBoxPadding={"0.5rem 1.5rem 0.5rem 0.7rem"}
                        borderRadius={"0.6rem"}
                        bgColor={theme.colors.body}
                    />
                </SearchInputWrapper>
            )}
            <UserListWrapper>
                {/* List of user if there are results */}
                {searchResults && searchResults.length > 0 && UserList}
                {/* If no results display no results message */}
                {!isSearching &&
                    (!searchResults || searchResults.length === 0) && (
                        <RowCenteredContent>
                            <NoResultsMessage>{noResultsText}</NoResultsMessage>
                        </RowCenteredContent>
                    )}
                {/* if loading and no results show list of loading users and loading dots */}
                {isSearching &&
                    (!searchResults || searchResults.length === 0) &&
                    LoadingUsers}
                {isSearching && (!searchResults || searchResults.length === 0) && (
                    <RowCenteredContent>
                        <LoadingDots size={"10px"} />
                    </RowCenteredContent>
                )}
                {/* If can load more show load more button at bottom of list */}
                {canLoadMore && !isSearching && (
                    <RowCenteredContent>
                        <LoadMoreBtnWrapper>
                            <Button
                                handleClick={handleLoadMore}
                                icon={<Icon name="arrow-circle-down" />}
                                secondaryBtnColors={true}
                                bgColor={theme.colors.offPrimary}
                                buttonLoading={isSearching}
                                noShadow={true}
                                borderRadius={"0.7rem"}
                            >
                                More
                            </Button>
                        </LoadMoreBtnWrapper>
                    </RowCenteredContent>
                )}
            </UserListWrapper>
        </div>
    );
};

export default withTheme(UserListView);

const UserListWrapper = styled.div``;

const SearchInputWrapper = styled.div`
    padding-bottom: 1rem;
    position: relative;
`;

const UserRow = styled.div`
    display: flex;
    align-items: center;
    padding: 0.35rem 0;
    justify-content: space-between;
    border-top: 1px solid ${(props) => props.theme.colors.body};
`;

const LeftSide = styled.div`
    display: flex;
    min-width: 0; /* allow username to be cut with ellipsis when needed*/
    align-items: center;
    cursor: pointer;
`;

const RightSide = styled.div`
    display: flex;
    align-items: center;
`;

const AvatarWrapper = styled.div`
    margin: 0rem 0.7rem 0 0.5rem;
`;

const Username = styled.div`
    font-size: ${(props) => props.nameTextSize};
    font-weight: 500;
    /* cut off words without white space that exceed width, wrap white space */
    text-overflow: ellipsis;
    overflow: hidden;
`;

const ConxBtnWrapper = styled.div`
    width: 115px;
`;

const RowCenteredContent = styled.div`
    width: 100%;
    display: flex;
    padding-top: 0.5rem;
    justify-content: center;
`;

const NameBar = styled.div`
    background-color: ${(props) => props.theme.colors.offPrimary};
    width: 200px;
    height: 1.5rem;
    border-radius: 1rem;
`;

const LoadMoreBtnWrapper = styled.div`
    width: 150px;
`;

const NoResultsMessage = styled.h6`
    padding-top: 1rem;
    color: ${(props) => props.theme.colors.offSecondary};
    margin: 0;
`;

const BtnText = styled.div`
    font-weight: 500;
`;
