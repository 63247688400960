import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import MessagesContainer from "./Messages-container";
import { addError, removeError } from "../../main/store/actions/errors";
import { setShowSidebar } from "../../main/store/actions/sidebar";

const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
    errors: state.errors,
});

const mapDispatchToProps = {
    addError,
    removeError,
    setShowSidebar,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MessagesContainer));
