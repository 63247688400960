import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTheme } from "styled-components";

import SearchPage from "./SearchPage-contanier";

function mapState(state) {
    return {
        currentUser: state.currentUser,
    };
}

const mapDispatch = {};

export default withRouter(
    connect(mapState, mapDispatch)(withTheme(SearchPage))
);
