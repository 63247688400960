import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import PostList from "./PostList-container";
import { addError, removeError } from "../../main/store/actions/errors";

const mapState = (state) => {
    return {
        currentUser: state.currentUser,
        errors: state.errors,
    };
};

const mapDispatch = {
    addError,
    removeError
};

export default withRouter(connect(mapState, mapDispatch)(PostList));