import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import CompanyListView from "./CompanyList-view";
import { COMPANY_SEARCH } from "../constants";
import { sendApiRequest } from "../../../services/api";

const CompanyList = ({ history, addError }) => {
    const [searchString, setSearchString] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [canLoadMore, setCanLoadMore] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const MAX_RESULTS = 5;

    const { getAccessTokenSilently } = useAuth0();

    // returns a list of all users on first load
    useEffect(() => {
        const timeoutId = setTimeout(async () => {
            try {
                setIsSearching(true);
                setCanLoadMore(false);
                // builds the query when a search string is entered
                const queryParams = searchString.length > 0 ?
                    `?search_field=${COMPANY_SEARCH.searchField}&search=${searchString}` :
                    "";
                const queryResult = await sendApiRequest(
                    getAccessTokenSilently,
                    {
                        method: COMPANY_SEARCH.apiRequestType,
                        url: `${COMPANY_SEARCH.apiRequestPath}${queryParams}`,
                    }
                );
                setSearchResults(queryResult);

                // turn on "load more" option if max results were found
                if (queryResult.length === MAX_RESULTS) { setCanLoadMore(true); }
                setIsSearching(false);
            } catch (error) {
                // addError("Error encountered in CompanyList-container -> useEffect");
                setIsSearching(false);
                throw new Error(error);
            }
        }, 200);
        return () => clearTimeout(timeoutId);
    }, [searchString, getAccessTokenSilently]);

    const handleLoadMore = async () => {
        try {
            setIsSearching(true);
            setCanLoadMore(false);
            const queryParams = searchString.length > 0 ?
                `?search_field=${COMPANY_SEARCH.searchField}&search=${searchString}` :
                "";
            const queryResult = await sendApiRequest(
                getAccessTokenSilently,
                {
                    method: COMPANY_SEARCH.apiRequestType,
                    url: `${COMPANY_SEARCH.apiRequestPath}${queryParams}`,
                    data: { excludedResults: searchResults.map(result => result._id) },
                }
            );
            setSearchResults((f) => { return [...f, ...queryResult]; });

            // turn on "load more" option if max results were found
            if (queryResult.length === MAX_RESULTS) { setCanLoadMore(true); }
            setIsSearching(false);
        } catch (error) {
            addError("Error encountered in CompanyList-container -> handleLoadMore");
            setIsSearching(false);
            throw new Error(error);
        }
    };

    // updates the search string when the input is changed
    const handleChange = (e) => {
        setSearchString(e.target.value);
    };

    // redirects to a user's profile page when their card is clicked
    const handleCardClick = (companyId) => {
        history.push(`/companies/${companyId}`);
    };

    return (
        <CompanyListView
            searchResults={searchResults}
            handleChange={handleChange}
            handleCardClick={handleCardClick}
            handleLoadMore={handleLoadMore}
            canLoadMore={canLoadMore}
            isSearching={isSearching}
        />
    );
};

export default CompanyList;