import { useState } from "react";

import LoadingScreen from "./LoadingScreen-view";

// Creates loading state and passes it down to WrappedComponent with ability to setLoading state and a LoadingScreen-view
const WithLoadingScreenContainer = (WrappedComponent) =>
   function WithLoadingScreenHOC(props) {
      // Loading state
      const [loading, setLoading] = useState(true);

      return (
         <WrappedComponent
            loading={loading}
            setLoading={setLoading}
            LoadingScreen={LoadingScreen}
            {...props}
         />
      );
   };

export default WithLoadingScreenContainer;
