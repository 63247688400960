import styled, { withTheme } from "styled-components";

import { titleCase } from "../../../utilities";
import { SERVICE_SEARCH, INPUT_TYPES } from "../../constants";
import { Wrapper } from "./ServicesEdit-view";
import Button from "../../Button";
import { ContentBox } from "../../../../resources/styles/index";
import FooterBar from "../../PopupFooterBar";
import HeaderBar from "../../PopupHeaderBar";
import InputGroup from "../../InputGroup";

const AddServiceView = ({
    theme,
    setPopUpToDisplay,
    servicesName,
    addServiceToState,
    addRecommendedService,
    addedServices,
    handleBack,
    deleteItemAddedServices,
    handleAddClick,
    recommendedServices,
    excludeServicesFromSearchBox,
}) => {
    // List of added services with delete buttons for each
    const addedServicesToRender = addedServices.map((service, i) => (
        <AddedServiceWrapper key={i}>
            <Button
                handleClick={() => {
                    deleteItemAddedServices(i);
                }}
                textSize={"1rem"}
                icon={<i className="fas fa-minus-circle"></i>}
                bgColor={theme.colors.semantic.success}
                textColor={theme.colors.secondary}
                noShadow={true}
            >
                {service.name}
            </Button>
        </AddedServiceWrapper>
    ));

    const recommendedSkillsToRender = recommendedServices.map((service, i) => (
        <RecServiceWrapper key={i}>
            <Button
                handleClick={() => {
                    addRecommendedService(service.name);
                }}
                textSize={"1rem"}
                icon={<i className="fas fa-plus-circle"></i>}
                secondaryBtnColors={true}
                noShadow={true}
            >
                {service.name}
            </Button>
        </RecServiceWrapper>
    ));

    return (
        <Wrapper>
            <HeaderBar
                setPopUpToDisplay={setPopUpToDisplay}
                handleBack={handleBack}
                popupTitle={titleCase(servicesName)}
            />
            <ContentBox>
                <ContentWrapper>
                    <SearchTitle>Search {titleCase(servicesName)}</SearchTitle>
                    <InputGroup
                        type={INPUT_TYPES.SEARCH_TEXT}
                        placeholder={`Retaining structures, road construction, kerb & channel etc`}
                        required={false}
                        handleChange={addServiceToState}
                        searchBoxProps={{
                            excludedFromSearchBox:
                                excludeServicesFromSearchBox(),
                            searchApiRequest: SERVICE_SEARCH,
                            clearOnSelect: true,
                            valueCanBeCustomString: true,
                            clearSearchOnOutsideClick: true,
                        }}
                        zIndex={2}
                    />
                    {addedServices[0] && (
                        <SectionTitle>
                            Added {titleCase(servicesName)}
                        </SectionTitle>
                    )}
                    <ServicesGroup>{addedServicesToRender}</ServicesGroup>
                    {recommendedServices[0] && <LineX />}
                    {recommendedServices[0] && (
                        <SectionTitle>
                            Suggested {titleCase(servicesName)}
                        </SectionTitle>
                    )}
                    <ServicesGroup>{recommendedSkillsToRender}</ServicesGroup>
                </ContentWrapper>
            </ContentBox>
            <FooterBar handleDone={handleAddClick} doneLabel={"Done"} />
        </Wrapper>
    );
};

export default withTheme(AddServiceView);

const ContentWrapper = styled.div`
    padding: 1rem 1rem 0.5rem 1rem;
    min-height: 250px;
    @media (max-width: ${({ theme }) => theme.sizes.mdViewWidth}) {
        padding: 1rem 0.5rem 0.5rem 0.5rem;
    }
`;

const SearchTitle = styled.h5`
    margin: 0 0 0.3rem 0;
`;
const ServicesGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const RecServiceWrapper = styled.div`
    margin: 0.2rem 0.2rem;
`;

const AddedServiceWrapper = styled.div`
    margin: 0.2rem 0.2rem;
`;

const SectionTitle = styled.h6`
    margin: 0.5rem 0 0.3rem 0;
`;

const LineX = styled.hr`
    margin: 2rem 0 0rem 0;
`;
