import AboutPage from "./CompanyProfileAboutUs-view";
import People from "./CompanyProfilePeople-view";

import Alert from "../../library/common/Alert";
import ImageGrid from "../../library/common/ImageGrid";
import ProfileAddSectionBox from "../../library/common/ProfileAddSectionBox";
import ProfileHeader from "../../library/common/ProfileHeader";
import TabNavbar from "../../library/common/TabNavbar";

import {
    ALERT_TYPES,
    COMPANY_PAGES,
    COMPANY_POPUP_TYPES,
    SECTION_TITLES,
} from "../../library/common/constants";

import {
    WidthWrapper,
    HeaderSection,
    ContentWrapper,
} from "../../resources/styles";

// View component render company profile
const CompanyProfileView = ({
    pageToDisplay,
    companyDetails,
    createView = false,
    setPopUpToDisplay = () => { },
    setCompanyDetails = () => { },
    loading = false,
    errors,
    removeError,
}) => {
    const {
        _id,
        name,
        quickDescription,
        logo = { path: undefined },
        phone,
        websiteURL,
        aboutUs,
        services,
        officeLocations = [],
        userIsAdmin,
        conxStatus,
    } = companyDetails;

    // Used to tell ProfileAddSectionBox and About page what section contain content, how to add to them and the title for each section
    const profileSections = {
        about: {
            has: !!aboutUs,
            edit: () => { setPopUpToDisplay(COMPANY_POPUP_TYPES.about); },
            title: SECTION_TITLES.about,
        },
        services: {
            has: !!services && !!services[0],
            edit: () => { setPopUpToDisplay(COMPANY_POPUP_TYPES.services); },
            title: SECTION_TITLES.services,
        },
        locations: {
            has:
                !!officeLocations[0] &&
                !!officeLocations[0].latLng &&
                Object.keys(officeLocations[0].latLng).length > 0,
            edit: () => { setPopUpToDisplay(COMPANY_POPUP_TYPES.officeLocations); },
            title: SECTION_TITLES.locations,
        },
    };

    const profileHeaderProps = {
        userIsAdmin,
        avatarPaths: { src: logo.path },
        setPopUpToDisplay,
        popupStrings: {
            editProfile: COMPANY_POPUP_TYPES.header,
            coverPhoto: COMPANY_POPUP_TYPES.coverPhoto,
            displayPic: COMPANY_POPUP_TYPES.logo,
        },
        phone,
        name,
        conxStatus,
        companyProps: {
            companyID: _id,
            setCompanyDetails: setCompanyDetails,
            websiteURL: websiteURL,
            bio: quickDescription,
        },
        createView,
        scrollFocusContent:
            // scroll down to content when on any tab other than the first one
            pageToDisplay !==
            COMPANY_PAGES[Object.keys(COMPANY_PAGES)[0]].name,
        loading,
    };

    return (
        <div>
            {errors.message && (
                <Alert
                    message={errors.message}
                    type={ALERT_TYPES.ERROR}
                    removeError={removeError}
                    noBorderRadius={true}
                    hideBorder={true}
                />
            )}
            <HeaderSection>
                <WidthWrapper>
                    <ProfileHeader {...profileHeaderProps} />
                    <TabNavbar
                        pageToDisplay={pageToDisplay}
                        commonUrl={`/companies/${_id}`}
                        pages={Object.values(COMPANY_PAGES)}
                        loading={loading}
                    />
                </WidthWrapper>
            </HeaderSection>
            {userIsAdmin && // Show admin add section controls only on about page
                pageToDisplay === COMPANY_PAGES.ABOUT.name && (
                    <ProfileAddSectionBox
                        profileSections={profileSections}
                        title={"Help people find your business."}
                    />
                )}
            {!loading && (
                <ContentWrapper>
                    <WidthWrapper>
                        {pageToDisplay === COMPANY_PAGES.ABOUT.name && (
                            <AboutPage
                                companyDetails={companyDetails}
                                profileSections={profileSections}
                                createView={createView}
                            />
                        )}
                        {pageToDisplay === COMPANY_PAGES.GRID.name && (
                            <ImageGrid viewRoute={`/api/companies/${_id}/grid`} />
                        )}
                        {pageToDisplay === COMPANY_PAGES.PEOPLE.name && (
                            <People companyId={_id} />
                        )}
                    </WidthWrapper>
                </ContentWrapper>
            )}
        </div>
    );
};

export default CompanyProfileView;
