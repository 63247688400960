import { useCallback, useEffect, useState, useRef } from "react";
import styled from "styled-components";

import StepOneView from "./CreateStepOne-view";
import StepTwoView from "./CreateStepTwo-view";

import Alert from "../../library/common/Alert";
import LoadingSpinner from "../../library/common/LoadingSpinner";

import {
    ALERT_TYPES,
    CHRISTCHURCH_COORDS,
    SITE_PROFILE_VALIDATION_RULES,
} from "../../library/common/constants";

import {
    checkAndLoadMapsAPI,
    getAddress,
    fieldsFailingValidation,
    handleTextChange,
} from "../../library/utilities";

// import { useAuth0 } from "@auth0/auth0-react";
// import { sendApiRequest } from "../../services/api";
import { useApi } from "../../services/api";

// Create site from.
const CreateSiteContainer = ({
    errors,
    history,
    // addError,
    removeError,
    setShowSidebar,
    currentUser,
}) => {
    // const { getAccessTokenSilently } = useAuth0();

    // Loading states
    // const [submitting, setSubmitting] = useState(false);
    const [mapsAPILoading, setMapsAPILoading] = useState(true);

    // State used in front end validation
    const [firstSubmitFailed, setFirstSubmitFailed] = useState(false);
    const [validationFailedState, setValidationFailedState] = useState({});

    // Two step form - first is input location second is site name and crew
    const [step, setStep] = useState(1);

    // Hide sidebar on component
    useEffect(() => {
        setShowSidebar(false); // don't show sidebar
        return () => { setShowSidebar(true); }; // show sidebar again on unmount
    }, [setShowSidebar]);

    const [formState, setFormState] = useState({
        name: "",
        locationsArr: [
            // Needs to be in array to stop annoying re-rendering of map every time something in formState is updated
            {
                address: "",
                latLng: {},
            },
        ],
        companies: [],
        crew: [],
    });

    const initialMapPosition = useRef(CHRISTCHURCH_COORDS);

    // On mount checks for maps API script tag, if maps API has not yet been loaded then loads it and set mapsAPILoading state to false
    useEffect(() => {
        checkAndLoadMapsAPI(setMapsAPILoading);
    }, [setMapsAPILoading]);

    // Format formState to the from required by server.
    const castForSubmit = (objToSubmit) => {
        let castObj = {};
        Object.keys(objToSubmit).forEach((key) => {
            if (key === "crew") {
                // kick out sub-document fields in crew (i.e. remove username and displayPicture)
                castObj["crew"] = objToSubmit.crew
                    .map((crewMember) => crewMember._id)
                    .filter((member) => member !== currentUser.user.id); // filtering out current user not currently required as cant ad yourself in Searchbox but left this in in case SearchBox changes that feature
            } else if (key === "locationsArr") {
                // Removing latLng and address from array and placing directly in castObj
                castObj["latLng"] = objToSubmit["locationsArr"][0].latLng;
                castObj["address"] = objToSubmit["locationsArr"][0].address;
            } else {
                castObj[key] = objToSubmit[key];
            }
        });
        return castObj;
    };

    // Check if all fields pass rules, if an error occurs its added to fieldsFailedValidation state
    const checkFieldsPassValidationRules = useCallback(() => {
        const fieldsFailedValidation = fieldsFailingValidation(
            formState,
            SITE_PROFILE_VALIDATION_RULES
        );
        setValidationFailedState(fieldsFailedValidation);
        // return false if failure, true if no failures
        return !(Object.keys(fieldsFailedValidation).length > 0);
    }, [formState]);

    // Removes error messages form fieldsFailedValidation as fixed. Only runs after user has failed first attempt
    useEffect(() => {
        if (firstSubmitFailed) { checkFieldsPassValidationRules(); }
    }, [firstSubmitFailed, checkFieldsPassValidationRules]);

    const {
        isLoading: isSubmitting,
        refresh: submitForm,
    } = useApi();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (checkFieldsPassValidationRules()) {
            const dataToSubmit = castForSubmit(formState);
            submitForm({
                method: "post",
                url: "/api/sites/",
                data: dataToSubmit,
                callback: (res) => { history.push(`/sites/${res._id}`); }
            });
        } else setFirstSubmitFailed(true);
    };

    // Send user to previous page on cancel
    const handleCancel = () => {
        history.goBack();
    };

    // Used inside Map child components to set site latLng and address state
    const mapUpdateLocation = useCallback((latLng) => {
        getAddress(latLng, (address, latLng) => {
            setFormState((f) => {
                return {
                    ...f,
                    locationsArr: [{ address: address, latLng: latLng }],
                };
            });
        });
    }, []);

    // Used in SearchBox to add a new user to users array
    const addUser = (user) => {
        const newUser = {
            _id: user.id,
            username: user.name,
            displayPicture: user.img,
            timeAdded: Date.now(),
        };
        setFormState((f) => ({ ...f, crew: [...f.crew, newUser] }));
    };

    // Removes user form crew
    const removeUser = (indexToDelete) => {
        setFormState((f) => {
            return {
                ...f,
                crew: formState.crew.filter((el, i) => i !== indexToDelete),
            };
        });
    };

    // Handle next in step 1.
    // Check for latLng if not user hasn't clicked a location and forces them to do so
    const handleNext = () => {
        if (Object.keys(formState.locationsArr[0].latLng).length < 1) {
            setValidationFailedState({ latLng: "Choose a location first" });
        } else {
            setValidationFailedState({}); // clear any errors
            setFirstSubmitFailed(false); // reset to false
            setStep(2); // proceed to stop 2
        }
    };

    // Go back to first page
    const handleBack = () => {
        setStep(1);
    };

    // Handle tech changes like site name
    const handleChange = (e) => {
        handleTextChange(e, setFormState, formState);
    };

    // If maps api still loading render full screen spinner if not render step.
    // Note submitting state has a loading spinner within stepTwo
    if (mapsAPILoading) {
        return (
            <LoadingRow>
                <LoadingWrapper>
                    <LoadingSpinner />
                </LoadingWrapper>
            </LoadingRow>
        );
    } else {
        return (
            <Wrapper>
                {errors.message && (
                    <ShowAlertOverTop>
                        <Alert
                            message={errors.message}
                            type={ALERT_TYPES.ERROR}
                            removeError={removeError}
                            noBorderRadius={false}
                            hideBorder={false}
                        />
                    </ShowAlertOverTop>
                )}
                {step === 1 && (
                    <StepOneView
                        formState={formState}
                        mapUpdateLocation={mapUpdateLocation}
                        handleNext={handleNext}
                        validationFailedState={validationFailedState}
                        initialMapPosition={initialMapPosition}
                        handleCancel={handleCancel}
                    />
                )}
                {step === 2 && (
                    <StepTwoView
                        formState={formState}
                        handleSubmit={handleSubmit}
                        addUser={addUser}
                        removeUser={removeUser}
                        submitting={isSubmitting}
                        validationFailedState={validationFailedState}
                        handleCancel={handleCancel}
                        handleBack={handleBack}
                        handleChange={handleChange}
                    />
                )}
            </Wrapper>
        );
    }
};

export default CreateSiteContainer;

const LoadingRow = styled.div`
    display: flex;
    justify-content: center;
    padding: 1rem;
`;

const LoadingWrapper = styled.div`
    flex: 0 1 50px;
`;

const Wrapper = styled.div`
    position: relative;
`;

const ShowAlertOverTop = styled.div`
    position: absolute;
    z-index: 1;
    width: 100%;
    padding: 0.5rem;
`;
