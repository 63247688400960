import styled, { withTheme } from "styled-components";

import BtnBar from "./BtnBar-view";
import SiteProfile from "../SiteProfile";

import Alert from "../../library/common/Alert";
import Avatar from "../../library/common/Avatar";
import Button from "../../library/common/Button";
import ButtonEditCircle from "../../library/common/ButtonEditCircle";
import XButton from "../../library/common/ButtonXCircle";
import InputGroup from "../../library/common/InputGroup";
import LoadingSpinner from "../../library/common/LoadingSpinner";

import {
    INPUT_TYPES,
    ALERT_TYPES,
    USER_SEARCH,
    SITE_PAGES,
} from "../../library/common/constants";
import { LocationIcon } from "../../library/common/Icon";

const StepTwo = ({
    formState,
    handleSubmit,
    addUser,
    removeUser,
    submitting,
    theme,
    validationFailedState,
    handleCancel,
    handleBack,
    handleChange,
}) => {
    const { name } = formState;
    const screenBreakSize = theme.sizes.mdViewWidth;

    const CrewList = formState.crew
        .sort((a, b) => b.timeAdded - a.timeAdded)
        .map((member, i) => {
            // map out JSX
            return (
                <CrewMemberRow key={i}>
                    <NameAndAvatar>
                        <Avatar path={member.displayPicture} size={"40px"} />
                        <Username>{member.username}</Username>
                    </NameAndAvatar>
                    <XButton
                        handleClick={() => removeUser(i)}
                        circleDiameter={"1.5rem"}
                        bgColor={theme.colors.offPrimary}
                        iconColor={theme.colors.secondary}
                    />
                </CrewMemberRow>
            );
        });

    // Contents of for details like name input etc
    const FormDetails = (
        <DetailsWrapper>
            <PageTitle>Create Site</PageTitle>
            <InputGroupWrapper>
                <InputGroup
                    type={INPUT_TYPES.TEXT}
                    handleChange={handleChange}
                    errorMessage={validationFailedState.name}
                    value={name}
                    name={"name"}
                    title={"Name"}
                    placeholder={"Site name"}
                    required={true}
                    description={`Help people identify your site.`}
                />
            </InputGroupWrapper>
            <LocationRow>
                <LocationButtonWrapper>
                    <Button
                        handleClick={handleBack}
                        textSize={"1rem"}
                        secondaryBtnColors={true}
                        noShadow={true}
                        icon={<LocationIcon />}
                    >
                        Location
                    </Button>
                </LocationButtonWrapper>
                <EditButtonWrapper>
                    <ButtonEditCircle
                        handleClick={handleBack}
                        circleDiameter={"2rem"}
                        textSize={"1rem"}
                    />
                </EditButtonWrapper>
            </LocationRow>
            <AddUserInputWrapper>
                <InputGroup
                    type={INPUT_TYPES.SEARCH_TEXT}
                    placeholder={`Users name`}
                    required={false}
                    title={"Invite Crew"}
                    handleChange={addUser}
                    description={`Invite crew members to join the site. Crew members can be added latter from the sites profile.`}
                    searchBoxProps={{
                        excludedFromSearchBox: formState.crew.map(
                            (user) => user._id
                        ),
                        searchApiRequest: USER_SEARCH,
                        clearOnSelect: true,
                        valueCanBeCustomString: false,
                        clearSearchOnOutsideClick: true,
                    }}
                />
            </AddUserInputWrapper>
            {formState.crew.length > 0 && (
                <InvitedCrewTitle>Added Crew Members:</InvitedCrewTitle>
            )}
            {CrewList}
        </DetailsWrapper>
    );

    return (
        <Wrapper>
            <SiteDetailsCol screenBreakSize={screenBreakSize}>
                {submitting && ( // Loading spinner in Details section is submitting is true
                    <LoadingSidebar>
                        <LoadingWrapperSize>
                            <LoadingSpinner />
                        </LoadingWrapperSize>
                    </LoadingSidebar>
                )}
                {!submitting && (
                    <StickyWrapper>
                        <SidebarWrapper>
                            {FormDetails}
                            {Object.keys(validationFailedState).length > 0 && (
                                // If there are any validationFailed errors (font end errors) then Check details error alert is shown above btnBar
                                <ValidationErrorWrapper
                                    screenBreakSize={screenBreakSize}
                                >
                                    <Alert
                                        message={"Check Details"}
                                        type={ALERT_TYPES.ERROR}
                                        hideBorder={true}
                                        noBorderRadius={true}
                                    />
                                </ValidationErrorWrapper>
                            )}
                            <BtnBar
                                handleCancel={handleCancel}
                                handleSubmit={handleSubmit}
                                submitText={"Create"}
                            />
                        </SidebarWrapper>
                    </StickyWrapper>
                )}
            </SiteDetailsCol>
            <ProfilePreviewCol screenBreakSize={screenBreakSize}>
                <PreviewBox>
                    <DisableClickOverlay />
                    <PreviewTitle>Profile Preview</PreviewTitle>
                    <ProfileBackgroundColor>
                        <SiteProfile
                            page={SITE_PAGES.ABOUT.name}
                            previewState={formState}
                        />
                    </ProfileBackgroundColor>
                </PreviewBox>
            </ProfilePreviewCol>
        </Wrapper>
    );
};

export default withTheme(StepTwo);

const Wrapper = styled.div`
    display: flex;
`;

const SiteDetailsCol = styled.div`
    flex: 0 0.2 400px;
    box-shadow: 2px 2px 50px rgba(150, 150, 150, 0.2);
    @media (max-width: ${({ screenBreakSize }) => screenBreakSize}) {
        flex: 0 1 400px; /* Restore shrink 1 so width shrinks with screen below 400px*/
    }
`;

const ProfilePreviewCol = styled.div`
    flex: 1 2 1000px;
    display: flex;
    justify-content: center;
    padding: 1rem;
    @media (max-width: ${({ screenBreakSize }) => screenBreakSize}) {
        display: none; /* Don't display profile preview on small screens */
    }
`;

const StickyWrapper = styled.div`
    position: sticky;
    top: ${({ theme }) => theme.sizes.navHeight};
    max-height: calc(100vh - ${({ theme }) => theme.sizes.navHeight});
    overflow-y: auto;
`;

const DetailsWrapper = styled.div`
    /* height: calc(100vh - ${(props) => props.theme.sizes.navHeight}); */
    padding: 1rem 1.5rem;
    position: relative;
    z-index: 0;
    flex: 1;
    max-height: auto;
    overflow-y: auto; /* Scroll within this div when more height needed */
`;

const SidebarWrapper = styled.div`
    height: calc(100vh - ${(props) => props.theme.sizes.navHeight});
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const LoadingSidebar = styled(SidebarWrapper)`
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: auto;
    padding: 1rem;
`;

const LoadingWrapperSize = styled.div`
    flex: 0 1 75px;
`;

const PageTitle = styled.h3`
    margin: 0;
    padding: 0 0 1rem 0;
`;

const InputGroupWrapper = styled.div`
    padding: 0 0 1.5rem 0;
`;

const AddUserInputWrapper = styled(InputGroupWrapper)`
    position: relative;
    z-index: 2;
`;

const CrewMemberRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0 0 0;
`;

const NameAndAvatar = styled.div`
    display: flex;
    align-items: center;
`;

const Username = styled.div`
    padding: 0 0 0 1rem;
`;

const InvitedCrewTitle = styled.div`
    padding: 0 0 0.5rem 0;
`;

const PreviewBox = styled.div`
    flex: 0 1 800px;
    border-radius: 0.5rem;
    padding: 0.2rem 1rem 1rem 1rem;
    border: 1px solid ${(props) => props.theme.colors.offPrimary};
    box-shadow: 2px 2px 30px rgba(150, 150, 150, 0.2);
    background-color: ${(props) => props.theme.colors.primary};
    position: relative; /* So ProfileBackgroundColor can fill PreviewBox height */
    display: flex;
    flex-direction: column; /* Needed to make ProfileBackgroundColor fill space */
`;

// Rendered overtop of profile preview to stop the user being able to interact with the preview profile (e.g. stop clicking profile pic, changing tabs etc)
const DisableClickOverlay = styled.div`
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
`;

const PreviewTitle = styled.h6`
    margin: 0;
    padding: 0.5rem;
`;

const ProfileBackgroundColor = styled.div`
    background-color: ${(props) => props.theme.colors.body};
    flex: 1; /* Fill remaining spacing in PreviewBox */
    border-radius: 0.5rem;
    overflow: hidden;
    position: relative;
    z-index: 0;
`;

const LocationRow = styled(InputGroupWrapper)`
    display: flex;
    align-items: center;
`;

const EditButtonWrapper = styled.div`
    flex: 0 1 80px;
`;

const LocationButtonWrapper = styled.div`
    flex: 0 1 150px;
    padding-right: 0.5rem;
`;

const ValidationErrorWrapper = styled.div`
    /* padding: 0.5rem; */
    display: block;
`;
