import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

// sends request to backend using axios
// getAccessToken should be the function pulled from the useAuth0 hook
// axiosOptions should include: method (post, get, put etc.) and path (url endpoint)
export const sendApiRequest = async (getAccessToken, axiosOptions) => {
    try {
        const accessToken = await getAccessToken();
        const result = await axios({
            ...axiosOptions,
            url: `${process.env.REACT_APP_API_BASE_URL}${axiosOptions.url}`,
            headers: {
                ...axiosOptions.headers,
                Authorization: `Bearer ${accessToken}`,
            },
        });
        return result.data;
    } catch (error) {
        console.log("catch in sendApiRequest");
        console.log("Error is =>", error);
        throw error.response.data.error;
    }
};

export const useApi = ({
    callback = () => {},
    errorCallback = () => {},
    method = "",
    url = "",
    ...axiosOptions
} = {}) => {
    const { getAccessTokenSilently } = useAuth0();
    const [refreshCounter, setRefreshCounter] = useState(1);
    const optionsRef = useRef(axiosOptions);
    const cb = useRef(callback);
    const errorCb = useRef(errorCallback);

    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(true);

    // send the api request whenever method, url, or refreshCounter changes
    // note that changes to other options will NOT trigger a new api request
    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();

        (async () => {
            try {
                setIsLoading(true);
                // IF method and url are both provided
                // THEN get the access token from Auth0
                // and send the request through axios
                if (
                    (method || optionsRef.current.method) &&
                    (url || optionsRef.current.url)
                ) {
                    console.log(
                        `${refreshCounter} api request${
                            refreshCounter > 1 ? "s" : ""
                        } sent with options:`
                    );
                    console.log({ method, url, ...optionsRef.current });

                    const accessToken = await getAccessTokenSilently();
                    // console.log(
                    //     "`${process.env.REACT_APP_API_BASE_URL}${url}`"
                    // );
                    // console.log(`${process.env.REACT_APP_API_BASE_URL}${url}`);
                    // console.log(optionsRef.current);
                    // console.log({
                    //     method,
                    //     url,
                    //     ...optionsRef.current,
                    //     baseURL: process.env.REACT_APP_API_BASE_URL,
                    //     headers: {
                    //         ...optionsRef.current.headers,
                    //         Authorization: `Bearer ${accessToken}`,
                    //     },
                    //     cancelToken: cancelTokenSource.token,
                    // });
                    const response = await axios({
                        method,
                        url,
                        ...optionsRef.current,
                        baseURL: process.env.REACT_APP_API_BASE_URL,
                        headers: {
                            ...optionsRef.current.headers,
                            Authorization: `Bearer ${accessToken}`,
                        },
                        cancelToken: cancelTokenSource.token,
                    });
                    const data = response.data;

                    console.log("api response received:");
                    console.log(data);

                    // set data in state and run callback if it exists
                    setData(data);
                    cb.current(data);
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.log("New Error in useApi");
                    // const errorObj = error.response.data.error;
                    // // Check for handled error message, if no message add catch all "Error" string
                    // if (errorObj && errorObj.message)
                    //     errorCb.current(error.response.data.error.message);
                    // else errorCb.current("Error");
                    const { error: errorData = {} } = error.response.data;
                    const { message: errorMsg = "Error" } = errorData;
                    errorCb.current(errorMsg);
                } else console.log(error);
            }
            setIsLoading(false);
        })();

        return () => {
            cancelTokenSource.cancel();
            setIsLoading(false);
        };
    }, [refreshCounter, getAccessTokenSilently, method, url]);

    // refresh the data for the api hook
    // This callback will:
    // update optionsRef by copying axiosOptions again in case they have changed
    // add newOptions if they are provided
    // (this allows for a kind of override of the initial axiosOptions)
    // THEN increment refreshCounter to trigger the api request
    const refresh = (newOptions = {}) => {
        console.log("refresh function called");
        console.log(newOptions);
        optionsRef.current = {
            ...axiosOptions,
            ...newOptions,
        };
        if (newOptions.callback) cb.current = newOptions.callback;
        if (newOptions.errorCallback)
            errorCb.current = newOptions.errorCallback;
        setRefreshCounter((f) => f + 1);
    };

    return {
        data,
        isLoading,
        refresh,
    };
};
