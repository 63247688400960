import { addError } from "../../main/store/actions/errors";
import { sendApiRequest } from "../../services/api";
import { useEffect, useState } from "react";
import heic2any from "heic2any";
import imageCompression from "browser-image-compression";
import { USER_STATUS } from "../../library/common/constants";

// Use google maps Geocoder API to set address state from a latLng location
export const getAddress = async (latLng, callback) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === "OK") {
            const addressString = results[0]
                ? results[0].formatted_address
                : "No results found.";
            callback(addressString, latLng);
        } else {
            addError("Geocoder failed due to : " + status + ".");
        }
    });
};

// Use google maps Geocoder API to set latLng state from an address
export const getLatLng = async (address, callback) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: address }, (results, status) => {
        if (status === "OK") {
            // Extracting latLng from the geocoder result
            const latLng = results[0]
                ? {
                      lat: results[0].geometry.location.lat(),
                      lng: results[0].geometry.location.lng(),
                  }
                : "";
            callback(latLng, address);
        } else {
            // TODO check this error handling actully works
            addError("Geocoder failed due to : " + status + ".");
        }
    });
};

// Checks if maps API has already been loaded, if not loads maps api then setLoading state false in "parent", if it has already been loaded then simply setLoading false
export const checkAndLoadMapsAPI = (setLoading) => {
    if (!window.google) {
        // Maps api has not been loaded into app yet so load it into script tag and then setMapsAPILoaded
        let script = document.createElement("script");
        script.setAttribute("class", "mapsAPI");
        script.type = "text/javascript";
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places&v=weekly`;
        script.addEventListener("load", () => {
            setLoading(false);
        });
        document.getElementsByTagName("script")[0].append(script);
        return () =>
            script.removeEventListener("load", () => {
                setLoading(false);
            });
    } else {
        // If maps api has already been loaded into application
        setLoading(false);
    }
};

// Function for checking an object filled with keys against an object of rules for key values
// For example: candidateObj might contain a name fields and validationRules might dictate that name must not be an empty string. Returns an object of failed fields that will be empty if all keys pass
export const fieldsFailingValidation = (candidateObj, validationRules) => {
    const failedFields = {};
    // Check each key against the rules for its value
    Object.keys(candidateObj).forEach((key) => {
        if (validationRules[key]) {
            console.log("found a rule to check");
            // if validation rules exists
            const result = validationRules[key](candidateObj, key); // only returns value if candidate fails
            if (result) {
                // If candidate failed add result to failFields obj
                failedFields[key] = result;
            }
        }
    });
    // console.log("failedFields");
    // console.log(failedFields);
    return failedFields;
};

export const handleTextChange = (e, setFormState, formState) => {
    // Standard handleChange function
    e.preventDefault();
    setFormState({
        ...formState,
        [e.target.name]: e.target.value,
    });
};

// converts a nested path "key1.key2.key3" to nested keys [key1][key2][key3]
export const convertNestedKeys = (obj, string) => {
    let result = obj;
    string.split(".").forEach((key) => {
        result = result[key];
    });
    return result;
};

// ************************************************
// DateTime Conversion functions
// ************************************************

const timeInMilliseconds = {
    second: 1000,
    minute: 60 * 1000,
    hour: 60 * 60 * 1000,
    day: 24 * 60 * 60 * 1000,
    sixDays: 6 * 24 * 60 * 60 * 1000,
    week: 7 * 24 * 60 * 60 * 1000,
    month: 4 * 7 * 24 * 60 * 60 * 1000,
    year: 12 * 4 * 7 * 24 * 60 * 60 * 1000,
};

// formats date for posts
export const formatDateForPost = (date, dateNow) => {
    let formattedDate;
    // const dateNow = new Date();
    const postDate = new Date(date);

    // if (dateNow - postDate < timeInMilliseconds.minute) {
    //     // within a minute, SS seconds ago
    //     const numSecs = Math.floor(
    //         (dateNow - postDate) / timeInMilliseconds.second
    //     );
    //     formattedDate = `${numSecs} second${numSecs > 1 ? "s" : ""} ago`; // get time in seconds
    // } else
    if (dateNow - postDate < timeInMilliseconds.hour) {
        // within an hour, MM minutes ago
        let numMins = Math.floor(
            (dateNow - postDate) / timeInMilliseconds.minute
        );
        numMins = numMins < 1 ? 1 : numMins;
        formattedDate = `${numMins} minute${numMins > 1 ? "s" : ""} ago`; // get time in minutes
    } else if (dateNow - postDate < timeInMilliseconds.day) {
        // within a day, HH hours ago
        const numHours = Math.floor(
            (dateNow - postDate) / timeInMilliseconds.hour
        );
        formattedDate = `${numHours} hour${numHours > 1 ? "s" : ""} ago`; // get time in hours
    } else if (dateNow - postDate < timeInMilliseconds.sixDays) {
        // within a six days,
        // formats date to en-NZ standard, "Day HH:MM e.g. Friday 09:53" in 24 hour time
        formattedDate = postDate.toLocaleString("en-NZ", {
            weekday: "long",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
        });
    } else if (postDate.getFullYear() === dateNow.getFullYear()) {
        // Within this caledner year DD Month, HH:MM e.g. "5 March"
        formattedDate = postDate.toLocaleString("en-NZ", {
            day: "numeric",
            month: "long",
        });
    } else if (dateNow - postDate < timeInMilliseconds.year) {
        // with 52 weeks, DD Month YY e.g. "5 March 2020"
        formattedDate = postDate.toLocaleString("en-NZ", {
            dateStyle: "long",
        });
    } else {
        // A different year. DD/MM/YY eg "02/06/19"
        formattedDate = postDate.toLocaleString("en-NZ", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
        });
    }
    return formattedDate;
};

// formats date for messages
export const formatDateForMessage = (date) => {
    let formattedDate;
    const dateNow = new Date();
    const messageDate = new Date(date);

    if (
        messageDate.getFullYear() === dateNow.getFullYear() &&
        messageDate.getMonth() === dateNow.getMonth() &&
        messageDate.getDate() === dateNow.getDate()
    ) {
        // Same day
        // "Today HH:MM e.g. Today 08:52"
        formattedDate = `${messageDate.toLocaleString("en-NZ", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
        })}`;
    } else if (dateNow - messageDate < timeInMilliseconds.sixDays) {
        // within 6 days
        // formats date to en-NZ standard, "Day HH:MM e.g. Friday 09:53" in 24 hour time
        formattedDate = messageDate.toLocaleString("en-NZ", {
            weekday: "long",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
        });
    } else if (messageDate.getFullYear() === dateNow.getFullYear()) {
        // More than 7 days but in same year
        // "DD Month, HH:MM e.g. 02 Jan, 10:43"
        formattedDate = messageDate.toLocaleString("en-NZ", {
            day: "2-digit",
            month: "long",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
        });
    } else {
        // Different year
        // "DD/MM/YY, HH:MM eg 02/06/19, 09:43"
        formattedDate = messageDate.toLocaleString("en-NZ", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
        });
    }
    return formattedDate;
};

// formats date for notifications, hours ago, days ago, weeks ago then when more than a year just gives the date
export const formatDateTimeAgo = (date, dateNow, formatShort) => {
    let formattedDate;
    // const dateNow = new Date();
    const inputDate = new Date(date);

    if (dateNow - inputDate < timeInMilliseconds.hour) {
        // within 60 minutes
        // Smallest possible formattedDate is 1 minute
        let numMins = Math.floor(
            (dateNow - inputDate) / timeInMilliseconds.minute
        );
        numMins = numMins < 1 ? 1 : numMins; // give minutes ago a minimum of 1 minute ago
        formattedDate = formatShort
            ? `${numMins} m`
            : `${numMins} minute${numMins > 1 ? "s" : ""} ago`; // get time in minutes
    } else if (dateNow - inputDate < timeInMilliseconds.day) {
        // Same day "HH hours ago"
        const numHours = Math.floor(
            (dateNow - inputDate) / timeInMilliseconds.hour
        );
        formattedDate = formatShort
            ? `${numHours} h`
            : `${numHours} hour${numHours > 1 ? "s" : ""} ago`; // get time in hours
    } else if (dateNow - inputDate < timeInMilliseconds.week) {
        // within 7 days "DD days ago"
        const numDays = Math.floor(
            (dateNow - inputDate) / timeInMilliseconds.day
        );
        formattedDate = formatShort
            ? `${numDays} d`
            : `${numDays} day${numDays > 1 ? "s" : ""} ago`; // days ago
    } else if (dateNow - inputDate < timeInMilliseconds.year) {
        // More than 7 days but within 52 weeks (i.e approx within a year) "WW weeks ago"
        // Note can't use months as formatShort leaves no distinction between months and minutes (both denoted by m).
        const numWeeks = Math.floor(
            (dateNow - inputDate) / timeInMilliseconds.week
        );
        formattedDate = formatShort
            ? `${numWeeks} w`
            : `${numWeeks} week${numWeeks > 1 ? "s" : ""} ago`; // weeks ago
    } else {
        const numYears = Math.floor(
            (dateNow - inputDate) / timeInMilliseconds.year
        );
        formattedDate = formatShort
            ? `${numYears} y`
            : `${numYears} year${numYears > 1 ? "s" : ""} ago`; // years ago
    }
    return formattedDate;
};

export const titleCase = (string) => {
    var sentence = string.toLowerCase().split(" ");
    for (var i = 0; i < sentence.length; i++) {
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    return sentence.join(" ");
};

// ************************************************
// Image upload utility functions
// ************************************************
// Converts Heic images taken on IOS devices to JPEGS
const convertHeicToJpg = async (file) => {
    if (file.type === "image/heic") {
        try {
            const conversionResult = await heic2any({
                blob: file,
                toType: "image/jpg",
                // quality: 0.5,
            });
            return new File([conversionResult], `${file.name}_heic.jpg`, {
                type: "image/jpeg",
            });
        } catch (error) {
            throw new Error();
        }
    } else return file;
};

// Checkes files size - if its greater than max then compress to max
const compressFileSize = async (file, maxImgFileSize) => {
    if (file.size >= maxImgFileSize) {
        const options = {
            maxSizeMB: maxImgFileSize * 10 ** -6, // covert to MB
            maxWidthOrHeight: 4000,
        };
        try {
            const compressedFile = await imageCompression(file, options);
            return compressedFile;
        } catch (error) {
            throw new Error();
        }
    } else return file;
};

// Checks an array of files meet type and size requirements, failures a filtered from array and validation error added
const checkImageFiles = async (files, setValidationError, numFilesObj) => {
    // Convert any HEIC files to jpeg first - this function can take some time if it has to convert any
    try {
        const maxImgFileSize = 5000000; // bytes, MB max
        const minImgFileSize = 2500; // bytes, eg 50 x 50 pixels or 2.5KB
        files = await Promise.all(
            [...files].map((file) => convertHeicToJpg(file))
        );
        files = await Promise.all(
            [...files].map((file) => compressFileSize(file, maxImgFileSize))
        );
        return [...files].filter((file, i) => {
            let uploadError = null;
            console.log("file.size");
            console.log(file.size);
            if (
                file.type !== "image/jpeg" &&
                file.type !== "image/png"
                // file.type !== "image/heic"
            ) {
                uploadError = "Image files must be jpeg or png format";
            } else if (file.size <= minImgFileSize) {
                uploadError = `Images must be larger than ${
                    minImgFileSize * 10 ** -3 // convert to Mb
                }KB`;
            } else if (
                numFilesObj !== undefined &&
                numFilesObj.initial + i >= numFilesObj.max
            ) {
                uploadError = `Maximum of ${numFilesObj.max} images accepted`;
            }
            // Add validation error, if no validation errors found then any previous uploadError in state are cleared
            setValidationError((f) => {
                return {
                    ...f,
                    uploadError: uploadError,
                };
            });
            return (
                (file.type === "image/jpeg" || file.type === "image/png") &&
                // file.size <= maxImgFileSize &&
                file.size >= minImgFileSize &&
                (numFilesObj === undefined ||
                    numFilesObj.initial + i < numFilesObj.max)
            );
        });
    } catch (error) {
        throw new Error();
    }
};

// Add images to formState. Used to handle onChange event in file <input /> component
// Run check function, images that fail will not be added and validation error will be created
export const addImagesToState = async (
    e,
    setFormState,
    formState,
    imageKey,
    type,
    setValidationError,
    numFilesObj
) => {
    try {
        if (e.target.files[0]) {
            // If file(s) added
            // Check files meet image size and type requirements
            let checkedFiles = await checkImageFiles(
                e.target.files,
                setValidationError,
                numFilesObj
            );
            console.log(`Checked files => ${checkedFiles}`);
            if (checkedFiles[0]) {
                // If one or more image passes check add to state
                const imagesToRender = checkedFiles.map((file) => ({
                    path: URL.createObjectURL(file), // Path used temporarily to render image
                }));
                const filesToAdd = checkedFiles.map((file) => file);
                const updatedState = { ...formState };
                if (type === "multiple") {
                    console.log("multiple");
                    // Add to array
                    updatedState[imageKey] = [
                        ...formState[imageKey],
                        ...imagesToRender,
                    ];
                    updatedState["files"] = [...formState.files, ...filesToAdd];
                } else {
                    // if input type is single then previous state will be overridden.
                    updatedState[imageKey] = [...imagesToRender];
                    updatedState["files"] = [...filesToAdd];
                }
                // set formState to updated version with files and images added
                console.log("updatedState: ", updatedState);
                setFormState(updatedState);
            }
            e.target.value = null; // empty the file input once state set
        }
    } catch (error) {
        throw new Error();
    }
};

// Adds data to FormData object for submitting. Multer needs files sent in special FormData object.
export const packStateInFormDataObj = (formState, imageKey) => {
    const formDataForSubmitting = new FormData();
    // Must use .append() method to add things to FormData object.
    // Append files to formDataForSubmitting. Why not just append the formState.files array? Files will not be sent, doesn't work
    if (formState.files) {
        formState.files.forEach((file) => {
            formDataForSubmitting.append("myfiles", file);
        });
    }
    // Add file details to formDataForSubmitting
    formDataForSubmitting.append(
        "fileDetails",
        JSON.stringify(formState[imageKey])
    );
    // Add the rest of formState to formDataForSubmitting, only used forms taht contain files and other information for uploading to Db (e.g. creating post)
    formDataForSubmitting.append("formDetails", JSON.stringify(formState));
    return formDataForSubmitting;
};

// Deletes an image from an array of images in a formState
// Accepts variable imageKey so can be used regardless of what the images field is called. Image files are always stored in "files" key, so no need for variable file location.
export const deleteImgFromArrayInState = (
    indexToDelete,
    imageKey,
    formState,
    setFormState
) => {
    // Make deep copt of formState
    const updatedState = { ...formState };
    // Create updated array for images and files that is oldArr minus item being deleted
    const updatedImagesArr = formState[imageKey].filter(
        (item, i) => i !== indexToDelete
    );
    // overwrite {imageKey} and files fields with updated version
    updatedState[imageKey] = [...updatedImagesArr];
    if (formState.files) {
        const updatedFilesArr = formState.files.filter(
            (item, i) => i !== indexToDelete
        );
        updatedState["files"] = [...updatedFilesArr];
    }
    // Finally setFormState in parent
    setFormState(updatedState);
};

// ************************************************
// Check state against Db
// ************************************************
// For form field that has its own document - checks if the documents id is in form state.
// If no id but there is a document with the same name in db then adds its id to parents form state
// If no id and no matching name in db then creates a new doc and uses the newly created id.
export const checkDocumentFieldInDb = async (
    getAccessToken,
    docId,
    docName,
    docIdKey,
    SearchType,
    addDocServerPath,
    formState,
    saveTitleCase
) => {
    try {
        const checkedFormState = { ...formState };
        if (!docId) {
            // search for matching documents if no docId
            const queryResult = await sendApiRequest(getAccessToken, {
                method: SearchType.apiRequestType,
                url: `${SearchType.apiRequestPath}?search_field=${SearchType.searchField}&search=${docName}`,
            });
            // Generate an array of names form queryResult that exactly match docName
            const matchingNames = queryResult.filter(
                (result) => result.name.toLowerCase() === docName.toLowerCase()
            );
            if (matchingNames.length === 0) {
                // No matching names in queryResult, create a new doc in db, add its docId & return
                const NameToSave = saveTitleCase ? titleCase(docName) : docName;
                const result = await sendApiRequest(getAccessToken, {
                    method: "post",
                    url: addDocServerPath,
                    data: {
                        name: NameToSave,
                    },
                });
                checkedFormState[docIdKey] = result.id;
                return checkedFormState;
            } else if (matchingNames.length === 1) {
                // One matching doc name was found, add its docId & return
                checkedFormState[docIdKey] = matchingNames[0]._id;
                return checkedFormState;
            } else {
                // If there is more than one matchingName force user to pick form dropdown. I don't think this well ever be triggered unless use the function for a doc where unique names are not required.
                throw new Error(`Please select ${docIdKey} name from dropdown`);
            }
        } else {
            console.log("docID already present no need to check.");
            // Already have a docId, no need for checks
            return checkedFormState;
        }
    } catch (error) {
        throw new Error(error);
    }
};

// ************************************************
// Add markers to map && fit map to show all markers
// ************************************************
// Generic function used in map components with one or more markers where all of the markers are to be fit inside the starting position for the map
export const addMarkersToMap = ({
    map,
    locationsArray,
    clearMarkers,
    addMarkerToMarkers,
    zoom,
    markerPin,
    markerPinSize,
}) => {
    clearMarkers(); // remove any old markers
    // Configure map to contain markers for all locations. Create a bounds object and expand its bounds to include all markers
    const bounds = new window.google.maps.LatLngBounds();

    locationsArray.map((location, i) => {
        bounds.extend(location.latLng);
        let markerOptions = { map: map, position: location.latLng };
        const customSize = markerPinSize || 50;
        if (markerPin) {
            markerOptions.icon = {
                url: markerPin,
                scaledSize: new window.google.maps.Size(customSize, customSize),
            };
        }
        const marker = new window.google.maps.Marker(markerOptions);
        addMarkerToMarkers({ marker: marker });
        return marker;
    });

    // fit all markers onto map with padding. note padding doesn't work when theres only one market but setZoom below takes care of that
    const padding = 50;
    map.fitBounds(bounds, {
        left: padding,
        top: padding,
        right: padding,
        bottom: padding,
    });

    // fitBounds tends to result in map being too zoomed in.
    // Check map zoom and if its zoomed in to far zoom out to maximum starting zoom
    const listener = map.addListener("idle", () => {
        const maxStartingZoom = zoom ? zoom : 10;
        if (map.getZoom() > maxStartingZoom) map.setZoom(maxStartingZoom);
        window.google.maps.event.removeListener(listener);
    });
};

// ************************************************
// Map info window click listener
// ************************************************
// Made for sites
// Add click listener to infoWindow
// After an infoWindow has mounted ("domready" event) adds click listener to the infoWindow
export const addSiteInfoWindowListener = (
    infoWindow,
    locationsArray,
    history
) => {
    window.google.maps.event.addListener(infoWindow, "domready", function () {
        locationsArray.forEach((site) => {
            // Loop through locations and find which info window is mounted in DOM
            const infoWindowElement = document.getElementById(site.id);
            // history push for mounted site infoWindow
            if (!!infoWindowElement) {
                infoWindowElement.onclick = () =>
                    history.push(`/sites/${site.id}`);
            }
        });
    });
};

// ************************************************
// useOutsideClick
// ************************************************
// Component adds and removes event listeners for a click event outside a given area. Used for closing popup boxes, dropdowns etc.
export const UseOutsideClick = (ref, callback) => {
    const handleClick = (e) => {
        // console.log("ref.current");
        // console.log(ref.current);
        // if (ref.current) {
        // console.log("ref.current.contains(e.target)");
        // console.log(ref.current.contains(e.target));
        // console.log("e.target");
        // console.log(e.target.getAttribute("class"));
        // }
        // Checks if the target is not in clicks area, if not fires callback varible
        if (ref.current && !ref.current.contains(e.target)) {
            callback(e);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    });
};

export const useMultiRefsOutsideClick = (refs, callback) => {
    const handleClick = (e) => {
        // Checks if users clicks is in any of the refs provided
        const clickedInRef = Object.values(refs.current).some((ref) => {
            if (ref && ref.contains(e.target)) {
                // e.target is in the ref
                return true;
            }
            return false;
        });
        // If user did not click in one of the refs run callback
        if (!clickedInRef) {
            callback(e);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    });
};

export const truncateText = (text, limit) => {
    if (limit === undefined || text.length <= limit) {
        // if no limit given or text length is less than limit return text uncahnged
        return text;
    }
    // Removes character over the limit
    return text.substr(0, limit) + "\u2026";
};

// ************************************************
// Click on user event handler
// ************************************************
// direct to user profile on click
export const handleUserClick = (e, history, userID) => {
    // e.stopPropagation();
    history.push(`/users/${userID}`);
};

export const handleCompanyClick = (e, history, companyID) => {
    // e.stopPropagation();
    history.push(`/companies/${companyID}`);
};

export const handleSiteClick = (e, history, siteID) => {
    history.push(`/sites/${siteID}`);
};

// ************************************************
// Post functions uses in edit post and create post
// ************************************************
export const postContainsContent = (
    imageState,
    textState,
    validationFailedState,
    setValidationFailedState
) => {
    if (imageState[0] || (textState && textState.length > 0)) {
        // If post contains an image or text clear && noContent is not undefined clear noContent error
        // Not check of noContent error is required to avoid infinite loop in useEffect functions
        if (validationFailedState.noContent !== undefined) {
            setValidationFailedState((f) => {
                return {
                    ...f,
                    noContent: undefined,
                };
            });
        }
        return true; // return true post contains content
    } else {
        // If post doesn't contain an image or text then add no content error
        if (validationFailedState.noContent === undefined) {
            setValidationFailedState((f) => {
                return {
                    ...f,
                    noContent: "Post must contain text or images",
                };
            });
        }
        return false;
    }
};

// ************************************************
// Check for _id in array one but not array two
// ************************************************
// Checks all items in array one against array two.
// Returns array of elements in array one but not array two (determined by the _id key)
export const ItemsInArrayOneNotArrayTwo = (arrayOne, arrayTwo) => {
    const outputArr = [];
    arrayOne.forEach((elArrOne) => {
        if (!arrayTwo.some((elArrTwo) => elArrTwo._id === elArrOne._id)) {
            outputArr.push(elArrOne._id);
        }
    });
    return outputArr;
};

// ************************************************
// Used in any messages components to sort chats in time order according to last message
// ************************************************

// sort in time added order, newest first
export const sortChatsByDate = (chatsArr) =>
    chatsArr.sort(
        (a, b) =>
            Date.parse(b.messages[b.messages.length - 1].messageDate) -
            Date.parse(a.messages[a.messages.length - 1].messageDate)
    );

// ************************************************
// Check through message in a chat and determine if one or more message is unread
// ************************************************
export const checkForUnreadMsgInChat = (chat, currentUserId) => {
    if (!chat.members || chat.members.length < 0) return false;
    // The time current user last looked at the chat
    const currentUserLastSeen = chat.members
        .filter((el) => el.userId === currentUserId)
        .map((el) => el.lastSeen);
    // See if a message in chat is unread by current user
    // Find the time of the latest message (not from current user) in messages. Check that time against the currentUserLastSeen time
    return (
        Math.max(
            ...chat.messages
                .filter((msg) => msg.senderId !== currentUserId) // remove current user messages
                .map((msg) => Date.parse(msg.messageDate)), // create an array of messages dates
            0
        ) > Date.parse(currentUserLastSeen)
    );
};

// ************************************************
// Used in chat components to generate a string of common separated given names
// ************************************************
// Formulates a string with the users names i.e. "userOneName, userTwoName" etc

export const chatGivenNamesString = (members, maxNames = 3) => {
    const nameArray = members
        .filter((member) => member.status !== USER_STATUS.INACTIVE)
        .map((member) => member.givenName);

    if (nameArray.length < 1) {
        return null;
    } else if (nameArray.length <= maxNames) {
        return nameArray.join(", ");
    } else {
        const numOthers = nameArray.length - maxNames;
        return nameArray
            .slice(0, maxNames - 1)
            .join(", ")
            .concat(`, and ${numOthers} other${numOthers > 1 ? "s" : ""}`);
    }
};

// ************************************************
// Update time now hook
// ************************************************
// update the current time every interval milliseconds
export const useUpdateTime = (interval) => {
    const [timeNow, setTimeNow] = useState(new Date());

    // Counts down timer
    useEffect(() => {
        // save intervalId to clear the interval on unmount
        const intervalId = setInterval(() => {
            setTimeNow(new Date());
        }, interval); // update time every minute

        // stop and clear timer when component un-mounts
        return () => clearInterval(intervalId);
    }, [timeNow, interval]);

    return timeNow;
};

// ************************************************
// Img loaded in Dom status monitor
// ************************************************
// Before img has loaded in dom imgLoaded is false and when its loaded onImgLoad function updates this
// Img tag in component needs to have an onLoad event with the onImgLoad passed in
// imgLoaded status used in component to change opacity form 0 - 1 for instance and remove the bit by bit loading effect
export const useImgLoadStatus = () => {
    const [imgLoaded, setImgLoaded] = useState(false);
    const onImgLoad = () => {
        setImgLoaded(true);
    };
    return { imgLoaded, onImgLoad };
};
