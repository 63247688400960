import styled from "styled-components";
import { lighten } from "polished";

import {
    popUpBorderRadius,
    popUpHeaderHeight,
    popUpMarginBelowNavbar,
} from "../../library/hoc/WithPopUp/WithPopUp-container";

// *****************************************************************
// Profile styles box
// *****************************************************************
export const SectionBox = styled.div`
    padding: 0;
    background-color: ${(props) => props.theme.colors.primary};
    background-color: ${({ darkView, theme }) =>
        darkView ? lighten(0.2, theme.colors.secondary) : theme.colors.primary};
    color: ${({ darkView, theme }) =>
        darkView ? theme.colors.primary : "inherit"};

    border-radius: 10px;
    overflow: hidden; /* without map edges will be square and border radius will not appear to work at bottom*/
`;

export const HeaderBox = styled.div`
    /* min-height: 70px; */
    border-radius: 10px 10px 0 0;
    background-color: ${({ theme }) => theme.colors.secondary};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    @media (max-width: ${({ theme }) => theme.sizes.smViewWidth}) {
        padding: 1rem 1rem;
    }
`;

export const BoxTitle = styled.div`
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.primary};
`;

export const SectionContent = styled.div`
    padding: 1rem 1.5rem;
    @media (max-width: ${({ theme }) => theme.sizes.smViewWidth}) {
        padding: 1rem 1rem;
    }
`;

export const SectionWrapperStyles = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const WidthWrapper = styled.div`
    max-width: ${(props) => props.theme.sizes.maxProfileWidth};
    width: 100%;
    position: relative;
`;

export const HeaderSection = styled(SectionWrapperStyles)`
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 0 0 20px 20px;
`;

export const ContentWrapper = styled(SectionWrapperStyles)`
    padding: 0 0;
`;

export const SectionBoxWrapper = styled.div`
    margin: 1.5rem 6rem;
    @media (max-width: ${({ theme }) => theme.sizes.smViewWidth}) {
        margin: 0.5rem 0.5rem;
    }
`;
// *****************************************************************

// *****************************************************************
// PopUp section
// *****************************************************************
// Components used when reacting a popup. Could not be passed in through withPopUp
// due to the variabilty of the content each is expected to provide

// Defines a box for content to live inside relative to the vert space available on screen
// Content in box will be shrunk with scroll bar if needed
// Also leaves space for FooterBar below (which is needed since footer positioned absolute)
export const ContentBox = styled.div`
    position: relative;
    /* padding-bottom: ${popUpHeaderHeight}; accounts for footer */
    margin-bottom: ${popUpHeaderHeight}; /* accounts for footer */
    overflow-y: auto; /* scroll when max height reached */
    /* height left after all other vertical heights are accounted for */
    max-height: ${({ theme }) => `calc(100vh - 2*${popUpHeaderHeight} -
            ${theme.sizes.navHeight} -
            2*${popUpMarginBelowNavbar})`};
`;

// Footer stays put at bottom of popup regardless of screen height
export const FooterBar = styled.div`
    position: absolute;
    border-top: 1px solid ${(props) => props.theme.colors.offPrimary};
    height: ${popUpHeaderHeight}; /* Match popups top bar */
    bottom: 0;
    background-color: ${(props) =>
        props.theme.colors
            .primary}; /* Hide content below when overflow occurs */
    border-radius: 0 0 ${popUpBorderRadius} ${popUpBorderRadius}; /* Will form the bottom border user sees in popup */
    display: flex;
    width: 100%; /* Match width of parent  */
    align-items: center;
`;

// Styled for adding a footer bar with three buttons, the left button disappears on phone size screens
export const FooterBarContent = styled.div`
    display: flex;
    width: 100%; /* Match width of parent  */
    padding: 0 1rem;
    justify-content: flex-end;
    align-items: center;
`;

// Left wrapper will be not be display on xs screens
export const LeftBtnWrapper = styled.div`
    flex: 0 1 150px;
    margin-right: auto;
    margin-left: 0;
    @media (max-width: ${({ theme }) => theme.sizes.xsViewWidth}) {
        display: none;
    }
`;

export const MiddleBtnWrapper = styled.div`
    flex: 0 1 125px;
    margin-right: 1rem;
    margin-left: 1rem;
`;

export const RightBtnWrapper = styled.div`
    flex: 0 1 250px;
`;

// *****************************************************************

// *****************************************************************
// From input wrapper components
// *****************************************************************

export const FullWidthWrapper = styled.div`
    margin: 0.3rem 0;
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
`;

export const InputRow = styled.div`
    /* margin: 0.3rem 0 0.8rem 0; */
    display: flex;
    flex-direction: row;
    justify-content: start;
    @media (max-width: ${({ theme }) => theme.sizes.mdViewWidth}) {
        flex-direction: column;
    }
`;

export const HalfWidthWrapper = styled(FullWidthWrapper)`
    flex: 1;
    margin-left: ${(props) => props.spaceBetween};
    @media (max-width: ${({ theme }) => theme.sizes.mdViewWidth}) {
        flex: 1;
        margin-left: 0rem;
    }
`;
// *****************************************************************

// *****************************************************************
// Base div - used to build from
// *****************************************************************

// A div that scroll when reaches it max height. Scroll is a custom scrollbar that shows thumb only
// used in components with border radius that is cut off by standard scrollbar
// note webkit-scrollbar will only work in webkit based browsers inc Chrome, Edge, Safari (doesn't work in Firefox)
// Scrollbar is also not visible unless hovering over element
export const ThumbOnlyOverflowScroll = styled.div`
    overflow-y: auto;
    ::-webkit-scrollbar {
        width: 15px;
        /* border: 1px solid #bfbfbf; */
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        border: solid 4px transparent;
        border-radius: 10px;
        box-shadow: inset 0 0 10px 10px transparent;
    }
    &:hover {
        ::-webkit-scrollbar-thumb {
            border: solid 4px transparent;
            border-radius: 10px;
            box-shadow: inset 0 0 10px 10px #bfbfbf;
        }
    }
`;

// *****************************************************************

// Triangle up base style
export const TriangleUp = styled.div`
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid
        ${(props) =>
            props.bgColor ? props.bgColor : props.theme.colors.secondary};
    position: absolute;
    z-index: 0;
`;
