import styled from "styled-components";

import { Wrapper } from "./PostPreview-view";
import PostCreateFooterBar from "./PostCreateFooterBar-view";
import ValidationErrorAlert from "./ValidationErrorAlert-view";

import Button from "../../../library/common/Button";
import LoadingDots from "../../../library/common/LoadingDots";
import HeaderBar from "../../../library/common/PopupHeaderBar";

import { ContentBox } from "../../../resources/styles/index";
import Icon from "../../../library/common/Icon";

// image upload popup screen - with next button. Made to be the first popup screen when creating a post
const ImageUploadScreenView = ({
    setPopUpToDisplay,
    validationAlertProps,
    imgUploading,
    handleChooseImage,
    handleClose,
    handleBack,
    handleNext,
}) => {
    // const nextScreen = POST_POPUP_TYPES.TEXT_INPUT;

    return (
        <Wrapper>
            <HeaderBar
                setPopUpToDisplay={setPopUpToDisplay}
                popupTitle={"Create Post"}
                customExitHandling={handleClose}
            />
            <ContentBox>
                <ContentWrapper>
                    <Message>
                        Choose photo(s) for your post or click next to skip
                    </Message>
                    <ButtonRow>
                        {imgUploading && (
                            <LoadingDotsWrapper>
                                <LoadingDots size={"10px"} />
                            </LoadingDotsWrapper>
                        )}
                        <ButtonWrapper>
                            <Button
                                handleClick={handleChooseImage}
                                fileUpload={true}
                                textSize={"1rem"}
                                secondaryBtnColors={true}
                                icon={<Icon name="image" />}
                                multipleFiles={true}
                            >
                                Choose
                            </Button>
                        </ButtonWrapper>
                    </ButtonRow>
                </ContentWrapper>
            </ContentBox>
            <ValidationErrorAlert {...validationAlertProps} />
            <PostCreateFooterBar
                handleBack={handleBack}
                handleNext={handleNext}
                nextText={"Next"}
            />
        </Wrapper>
    );
};

export default ImageUploadScreenView;

const ContentWrapper = styled.div`
    /* min-height: 12rem; */
    padding: 1rem 2rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Message = styled.div`
    padding-bottom: 1rem;
`;

const ButtonRow = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
`;

const LoadingDotsWrapper = styled.div`
    top: 0;
    position: absolute;
    z-index: 1;
`;

const ButtonWrapper = styled.div`
    flex: 1;
`;
