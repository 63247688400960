// import MembersPopup from "../../library/common/popups/AddOrRemoveAccounts";
import MembersPopup from "../../library/common/popups/EditAccountsScreen";
import { CHAT_POPUP_TYPES, USER_STATUS } from "../../library/common/constants";
import ErrorPopup from "../../library/common/popups/ErrorPopup";
import ChatInfoPopup from "./PopupChatInfo-view";
import ConfirmationScreen from "../../library/common/ConfirmationScreen";
import withPopUp from "../../library/hoc/WithPopUp";
import withRemoveErrorOnUnmount from "../../library/hoc/WithRemoveErrorOnUnmount";

const ConfirmationPopup = withRemoveErrorOnUnmount(
    withPopUp(ConfirmationScreen)
);

const PopupRouterContainer = ({
    popUpToDisplay,
    errors,
    addError,
    removeError,
    setPopUpToDisplay,
    theme,
    chatDetails,
    sortedChatMembers,
    checkForExistingChat,
    handleChangeUsersInGroup,
    popUpLoading,
    newChat,
    handleUpdateName,
    handleLeaveGroup,
    handleExit,
    navigateToUser,
}) => {
    // Default props for MembersPopup
    const standardMembersPopupProps = {
        errors,
        addError,
        removeError,
        setPopUpToDisplay,
        users: chatDetails.members.map((el) => ({
            ...el,
            _id: el.userId,
        })),
        showStatus: false,
        popupLoading: popUpLoading,
        statusOptions: {
            acceptedAccountStatus: USER_STATUS.ACTIVE,
            removedAccountStatus: USER_STATUS.INACTIVE,
        },
    };

    if (popUpToDisplay === CHAT_POPUP_TYPES.error && errors.message) {
        return (
            <ErrorPopup
                removeError={() => {
                    setPopUpToDisplay("");
                    removeError();
                }}
                setPopUpToDisplay={setPopUpToDisplay}
            />
        );
    } else if (popUpToDisplay === CHAT_POPUP_TYPES.createNewChat) {
        return (
            // ** TODO check popup won't show "INACTIVE" user in the list for group chats where a user is removed **
            // IF it does pass in fullMemberStatus={CONNECTION_STATUS.ACTIVE} prop should fix it
            <MembersPopup
                {...standardMembersPopupProps}
                add={true}
                handleSubmit={checkForExistingChat} // overwrite the handleSubmit in standard props
                handleCancel={handleExit}
                customExitHandling={handleExit}
                popupTitle={"New Message"}
                listTitle={"Chat members"}
                userIsAdmin={true}
                noEditingAllowed={true}
                showStatus={false}
                hideNewStatus={true}
                submitText={"Next"}
            />
        );
    } else if (popUpToDisplay === CHAT_POPUP_TYPES.membersInfo) {
        return (
            <MembersPopup
                {...standardMembersPopupProps}
                handleDone={() => setPopUpToDisplay(CHAT_POPUP_TYPES.chatInfo)} // overwrite standard
                popupTitle={"Add"}
                listTitle={"Chat members"}
                userIsAdmin={newChat ? true : chatDetails.currentUserIsChatAdmin}
                handleCancel={() => { setPopUpToDisplay(""); }}
            />
        );
    } else if (popUpToDisplay === CHAT_POPUP_TYPES.addMembers) {
        return (
            <MembersPopup
                {...standardMembersPopupProps}
                popupTitle={"Add"}
                listTitle={"Chat members"}
                add={true}
                userIsAdmin={false}
                userCanAdd={true}
                submitText={"Done"}
            />
        );
    } else if (popUpToDisplay === CHAT_POPUP_TYPES.seenByUsers) {
        return (
            <MembersPopup
                {...standardMembersPopupProps}
                popupTitle={"Seen By"}
                listTitle={"Members"}
                userIsAdmin={false}
            />
        );
    } else if (popUpToDisplay === CHAT_POPUP_TYPES.chatInfo) {
        return (
            <ChatInfoPopup
                theme={theme}
                chatDetails={chatDetails}
                sortedChatMembers={sortedChatMembers}
                setPopUpToDisplay={setPopUpToDisplay}
                handleUpdateName={handleUpdateName}
                popupLoading={popUpLoading}
                navigateToUser={navigateToUser}
                handleCancel={() => { setPopUpToDisplay(""); }}
            />
        );
    } else if (popUpToDisplay === CHAT_POPUP_TYPES.leaveGroup) {
        return (
            <ConfirmationPopup
                handleCancel={() => {
                    setPopUpToDisplay(CHAT_POPUP_TYPES.chatInfo);
                }}
                cancelText={"No, cancel"}
                theme={theme}
                handleSubmit={handleLeaveGroup}
                submitText={"Yes, Leave"}
                title={"Leave Group"}
                loading={popUpLoading}
                setPopUpToDisplay={setPopUpToDisplay}
                message={"Are you sure you want to leave this group chat?"}
                maxWidth={"500px"}
            />
        );
    }
};

export default PopupRouterContainer;
