import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import UserProfileView from "./UserProfile-view";
import PopUpRouter from "./PopUpRouter-container";
import Alert from "../../library/common/Alert";
import { ALERT_TYPES, CONNECTION_STATUS, USER_PAGES } from "../../library/common/constants";
import { useApi } from "../../services/api";

const UserProfileContainer = ({
    history,
    currentUser,
    errors,
    addError,
    removeError,
    // pageObj,
}) => {
    const [userDetails, setUserDetails] = useState({});
    const [popUpToDisplay, setPopUpToDisplay] = useState("");

    // Server path and params logic
    const { user_id = "", tab_name: tabName = "" } = useParams();

    const adjustedTabName = tabName.length > 0 ? `/${tabName}` : tabName;
    const pageObj = Object.values(USER_PAGES)
        .filter(el => el.urlExtension === adjustedTabName)[0].name;

    const serverPath = `/api/users/${user_id}`;

    // Paths for currentUser, with admin paths enabled (if user_id === currentUser.user.id)
    const userIsAdmin = currentUser.user && currentUser.user.id === user_id;
    const currentUserPath = `/api/users/${currentUser.user.id}`;
    const userAdminPaths = {
        serverPath: currentUserPath,
        addProfilePicPath: `${currentUserPath}/add_display_picture`,
        deleteProfilePicPath: `${currentUserPath}/delete_display_picture`,
        addCoverPicPath: `${currentUserPath}/upload_cover_photo`,
        deleteCoverPicPath: `${currentUserPath}/delete_cover_photo`,
        expertiseRecommendations: "/api/services/recommend",
        // allFieldsPath: `${serverPath}/all_fields`,
    };

    // get profile data
    const {
        data: userData,
        isLoading: isLoadingUser,
        refresh: refreshUserData,
    } = useApi({
        method: "get",
        url: serverPath,
    });

    // if profile isn't of currentUser, then get currentUser's data
    const currentUserOptions = userIsAdmin
        ? {}
        : {
            method: "get",
            url: currentUserPath,
        };
    const {
        data: currentUserData,
        isLoading: isLoadingCurrentUser,
        refresh: refreshCurrentUserData,
    } = useApi(currentUserOptions);

    // save profile data to userDetails state
    // and work out connection between currentUser and user in profile
    useEffect(() => {
        const conxStatus =
            currentUserData && currentUserData.connections
                ? currentUserData.connections
                    .filter((conx) => conx.id === user_id)
                    .map((conx) => conx.status)[0] ||
                CONNECTION_STATUS.NOTAPPLICABLE
                : CONNECTION_STATUS.NOTAPPLICABLE;

        setUserDetails({
            ...userData,
            userIsAdmin,
            conxStatus,
        });
    }, [userData, currentUserData, userIsAdmin, user_id]);

    // Update user data from server, used after submitting new data in popup to force refresh of parent.
    const refreshDataFromServer = () => {
        refreshUserData();
        refreshCurrentUserData();
    };

    return (
        <div>
            {errors.message && (
                <Alert
                    message={errors.message}
                    type={ALERT_TYPES.ERROR}
                    removeError={removeError}
                    noBorderRadius={true}
                    hideBorder={true}
                />
            )}
            {Object.keys(userDetails)[0] && ( // only render id userDetails
                <UserProfileView
                    history={history}
                    currentUser={currentUser}
                    setUserDetails={setUserDetails}
                    userDetails={userDetails}
                    setPopUpToDisplay={setPopUpToDisplay}
                    profilePageToDisplay={pageObj}
                    loading={isLoadingUser || isLoadingCurrentUser}
                />
            )}
            {/* PopUpRouter is more or less a router that decides what popup to render */}
            <PopUpRouter
                popUpToDisplay={popUpToDisplay}
                userDetails={userDetails}
                addError={addError}
                removeError={removeError}
                errors={errors}
                setPopUpToDisplay={setPopUpToDisplay}
                setUserDetails={setUserDetails}
                refreshDataFromServer={refreshDataFromServer}
                serverPaths={userAdminPaths}
            />
        </div>
    );
    // }
};

export default UserProfileContainer;
