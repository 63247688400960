import { connect } from "react-redux";

import CompanyProfile from "./CompanyProfile-container";
import { addError, removeError } from "../../main/store/actions/errors";

const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
    errors: state.errors,
});

const mapDispatchToProps = {
    addError,
    removeError,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfile);
