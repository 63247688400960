import LoadingSpinner from "../LoadingSpinner";
import styled from "styled-components";
import { popUpMarginBelowNavbar } from "../../../library/hoc/WithPopUp/WithPopUp-container";

// popup loading screen can pass in an optional maxHeight and maxWidth, default is 600px x 600px and on small screen this shrinks with space available
const PopupLoadingScreenView = ({ theme, maxHeight, maxWidth }) => {
    return (
        <Wrapper
            maxHeight={maxHeight || "600px"}
            maxWidth={maxWidth || "600px"}
        >
            <SpinnerBox>
                <LoadingSpinner />
            </SpinnerBox>
        </Wrapper>
    );
};

export default PopupLoadingScreenView;

// Height and width is 600px unless screen size is an issue
const Wrapper = styled.div`
    /* Account for navbar and space between popup and navbar */
    height: ${({
    theme,
    maxHeight,
}) => `min( ${maxHeight}, (calc(100vh - ${theme.sizes.navHeight} -
            2*${popUpMarginBelowNavbar})))`};

    width: min(${(props) => props.maxWidth}, 95vw);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`;

// Sets the size of loading spinner to be 100px or 90% of Wrapper on tiny screen
const SpinnerBox = styled.div`
    height: min(100px, 90%);
    width: min(100px, 90%);
`;
