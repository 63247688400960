import moment from "moment";
import styled, { withTheme } from "styled-components";

import Avatar from "../../../library/common/Avatar";
// import ButtonCircle from "../../../library/common/ButtonCircleIcon";
import EditButton from "../../../library/common/ButtonEditCircle";
import TrashButton from "../../../library/common/ButtonTrashCircle";

const FormattedDates = ({ startDate, endDate }) => {
    const start = startDate ? moment(startDate).format("MMMM YYYY") : "Now";
    const end = endDate ? moment(endDate).format("MMMM YYYY") : "Now";
    return `${start} - ${end}`;
};

const btnDiameter = "2.3rem";
const iconSize = "1rem";

// Render all work experience object passed in. Optional handleEditClick which if true will show an edit function next to each experience
const workExperienceItemsView = ({
    items,
    theme,
    handleEditClick,
    handleDelete,
}) => {
    console.log("items");
    console.log(items);
    return items.map((experience, i) => (
        <ExperienceWrapper key={i}>
            <BtnCol hide={handleEditClick ? false : true}>
                {handleEditClick && (
                    <TopButtonWrapper>
                        <EditButton
                            handleClick={() => {
                                handleEditClick(i);
                            }}
                            circleDiameter={btnDiameter}
                            fontSize={iconSize}
                        />
                    </TopButtonWrapper>
                )}
                {handleDelete && (
                    <TrashButton
                        handleClick={() => {
                            handleDelete(i);
                        }}
                        bgColor={theme.colors.semantic.error}
                        iconColor={theme.colors.secondary}
                        circleDiameter={btnDiameter}
                        fontSize={iconSize}
                    />
                )}
            </BtnCol>
            <ExperienceItem>
                <CompanyTitleRow>{experience.companyName}</CompanyTitleRow>
                <DetailsRow>
                    <AvatarCol>
                        <Avatar
                            path={experience.companyLogo}
                            size={"5rem"}
                            borderColor={theme.colors.offPrimary}
                            borderWidth={"3px"}
                            letterAsAltImg={{
                                altLetter: experience.companyName[0],
                                fontSize: "2.5rem",
                                bgColor: theme.colors.primary,
                                textColor: theme.colors.accent,
                            }}
                        />
                    </AvatarCol>
                    <DetailsCol>
                        <JobTitleRow>
                            <JobTitle>{experience.jobTitle}</JobTitle>
                        </JobTitleRow>
                        <Dates>
                            <FormattedDates
                                startDate={experience.startDate}
                                endDate={experience.endDate}
                            />
                        </Dates>
                    </DetailsCol>
                </DetailsRow>
            </ExperienceItem>
        </ExperienceWrapper>
    ));
};

export default withTheme(workExperienceItemsView);

const ExperienceWrapper = styled.div`
    display: flex;
`;

const ExperienceItem = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 2rem;
`;

const CompanyTitleRow = styled.h6``;

const DetailsRow = styled.div`
    display: flex;
`;

const AvatarCol = styled.div`
    display: flex;
    flex-direction: column;
    padding-right: 1rem;
    border-right: 1px solid ${(props) => props.theme.colors.secondary};
`;

const BtnCol = styled.div`
    display: ${(props) => (props.hide ? "none" : "flex")};
    flex-direction: column;
    padding: 0rem 1rem 0 0;
`;

const TopButtonWrapper = styled.div`
    margin-bottom: 0.5rem;
`;

const DetailsCol = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 1.2rem;
`;
const JobTitleRow = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`;

const JobTitle = styled.h5`
    margin: 0;
`;

const Dates = styled.div`
    margin: 0.2rem 0;
`;
