import styled from "styled-components";

// App logo is currently text, text can have two sizes ome for xs (phone size) screen and one for the rest.
const AppLogo = () => {
    return (
        <Wrapper>
            <LogoImg
                src={`${window.location.origin}/BoomsUpAppIcon.png`}
                alt={"BoomsUp"}
            />
        </Wrapper>
    );
};

export default AppLogo;

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    min-height: 10px;
    min-width: 10px;
    position: relative;
    /* position: relative;
    display: flex;
    align-items: center; */
`;

const LogoImg = styled.img`
    object-fit: contain;
    height: 100%;
    width: 100%;
`;
