import styled from "styled-components";
import { useRef } from "react";
import NavBtn from "./BtnCircleIcon";
import Dropdown from "./DropdownBox-view";
import NotificationBadge from "../../library/common/NotificationBadge";
import {
    NAV_DROPDOWN_TYPES,
    USER_SEARCH,
    COMPANY_SEARCH,
    SERVICE_SEARCH,
    POST_SEARCH,
    LICENCE_SEARCH,
    SITE_SEARCH,
} from "../../library/common/constants";
import SearchBoxWithToggler from "../../library/common/SearchBoxWithToggler";
import { UseOutsideClick } from "../../library/utilities";
import AppLogo from "../../library/common/AppLogo";

const navBtnSize = "2.5rem";
const fontSize = "1.3rem";
const badgeFontSize = "1.35rem";

const NavbarView = ({
    theme,
    currentUser,
    dropdown,
    setDropdown,
    handleDropdownClick,
    logoutUser,
    history,
    setPopUp,
    fullScreenSidebar,
    setFullScreenSidebar,
    criticalScreenSize,
    showFullSearchBox,
    setShowFullSearchBox,
    handleSelect,
    numUnreadNotifications,
    numUnreadChats,
}) => {
    // ref stored in object, each nav button given a key from 1 - n then the object is passed to dropdown
    // Note the dropdown's ref will be stored with key 0, this is assigned in Dropdown-view
    const ref = useRef({});

    // Close large search box on click outside
    const searchBoxRef = useRef({});
    UseOutsideClick(searchBoxRef, (e) => {
        setShowFullSearchBox(false);
    });

    // Left content block
    const LeftBlock = (
        <LeftWrapper>
            <AppNameLogoGroup onClick={() => history.push("/")}>
                {/* <AppLogo size={"2rem"} /> */}
                {/* <AppLogo size={"60"}></AppLogo> */}

                {/* <LogoWrapper size={theme.sizes.navHeight}>
                    <AppLogo />
                </LogoWrapper> */}

                {/* <AppNameWithLogo
                    color={lighten(0.05, theme.colors.secondary)}
                    width={"10rem"}
                /> */}
                <AppName theme={theme} criticalScreenSize={criticalScreenSize}>
                    {theme.appName}
                </AppName>
                {/* <AppLogo
                    smallScreenLogoHeight={60}
                    largerScreenLogoHeight={100}
                /> */}
            </AppNameLogoGroup>
            <Hamburger
                fontSize={fontSize}
                fullScreenSidebar={fullScreenSidebar}
                criticalScreenSize={criticalScreenSize}
                onClick={() => {
                    setFullScreenSidebar(fullScreenSidebar ? false : true);
                }}
            >
                <i className="fas fa-bars"></i>
            </Hamburger>
            <SearchBoxSection ref={searchBoxRef}>
                <NavBtnWrapper btnMargin={"0 0.5rem 0 1rem"}>
                    <NavBtn
                        iconColor={theme.colors.darkAccent}
                        bgColor={theme.colors.offPrimary}
                        theme={theme}
                        size={navBtnSize}
                        handleClick={() =>
                            setShowFullSearchBox(
                                showFullSearchBox ? false : true
                            )
                        }
                    >
                        {!showFullSearchBox && <i className="fas fa-search" />}
                        {showFullSearchBox && <i className="fas fa-times" />}
                    </NavBtn>
                </NavBtnWrapper>
                {showFullSearchBox && (
                    <SearchBoxWithToggler
                        searchTypes={[
                            USER_SEARCH,
                            COMPANY_SEARCH,
                            SERVICE_SEARCH,
                            POST_SEARCH,
                            LICENCE_SEARCH,
                            SITE_SEARCH,
                        ]}
                        defaultSearchType={USER_SEARCH}
                        handleSelect={handleSelect}
                        singleRowView={true}
                        singleRowOptions={{
                            btnBgColor: theme.colors.secondary,
                            btnTextColor: theme.colors.primary,
                        }}
                        autoFocus={true}
                    />
                )}
            </SearchBoxSection>
        </LeftWrapper>
    );

    // button color defined by weather button's dropdown is clicked or not
    const btnIconColor = (name) => {
        return dropdown === name
            ? theme.colors.lightAccent
            : theme.colors.primary;
    };

    const btnMargin = "0 0.3rem"; // Margin between buttons in Right block
    // Right block in navbar contains user name, and several circle buttons

    const RightBtnBlock = (
        <RightWrapper>
            {currentUser.user.givenName && (
                <Username
                    name={currentUser.user.givenName}
                    theme={theme}
                    onClick={() =>
                        history.push(`/users/${currentUser.user.id}`)
                    }
                >
                    {currentUser.user.givenName}
                    <VertLine>|</VertLine>
                </Username>
            )}
            <NavBtnWrapper
                ref={(el) => (ref.current[1] = el)}
                btnMargin={btnMargin}
            >
                <NotificationBadgeWrapper>
                    <NotificationBadge
                        badgeColor={theme.colors.notification.badge}
                        numberColor={theme.colors.primary}
                        number={numUnreadNotifications}
                        circleSize={badgeFontSize}
                    />
                </NotificationBadgeWrapper>
                <NavBtn
                    theme={theme}
                    size={navBtnSize}
                    iconColor={btnIconColor(NAV_DROPDOWN_TYPES.notifications)}
                    handleClick={() => {
                        setFullScreenSidebar(false); // remove sidebar
                        handleDropdownClick(NAV_DROPDOWN_TYPES.notifications);
                    }}
                >
                    <i className="fas fa-bell" />
                </NavBtn>
            </NavBtnWrapper>
            <NavBtnWrapper
                ref={(el) => (ref.current[2] = el)}
                btnMargin={btnMargin}
            >
                <NotificationBadgeWrapper>
                    <NotificationBadge
                        badgeColor={theme.colors.notification.badge}
                        numberColor={theme.colors.primary}
                        number={numUnreadChats}
                        circleSize={badgeFontSize}
                    />
                </NotificationBadgeWrapper>
                <NavBtn
                    theme={theme}
                    size={navBtnSize}
                    iconColor={btnIconColor(NAV_DROPDOWN_TYPES.messages)}
                    handleClick={() => {
                        setFullScreenSidebar(false); // remove sidebar
                        handleDropdownClick(NAV_DROPDOWN_TYPES.messages);
                    }}
                >
                    <i className="fas fa-envelope" />
                </NavBtn>
            </NavBtnWrapper>
            <NavBtnWrapper
                ref={(el) => (ref.current[3] = el)}
                btnMargin={btnMargin}
            >
                <NavBtn
                    theme={theme}
                    size={navBtnSize}
                    iconColor={btnIconColor(NAV_DROPDOWN_TYPES.add)}
                    handleClick={() => {
                        setFullScreenSidebar(false); // remove sidebar
                        handleDropdownClick(NAV_DROPDOWN_TYPES.add);
                    }}
                >
                    <i className="fas fa-plus" />
                </NavBtn>
            </NavBtnWrapper>
            <NavBtnWrapper
                ref={(el) => (ref.current[4] = el)}
                btnMargin={btnMargin}
            >
                <NavBtn
                    theme={theme}
                    size={navBtnSize}
                    iconColor={btnIconColor(NAV_DROPDOWN_TYPES.accountControls)}
                    handleClick={() => {
                        setFullScreenSidebar(false); // remove sidebar
                        handleDropdownClick(NAV_DROPDOWN_TYPES.accountControls);
                    }}
                >
                    <i className="fas fa-user"></i>
                </NavBtn>
            </NavBtnWrapper>
        </RightWrapper>
    );

    return (
        <Navbar>
            {LeftBlock}
            {RightBtnBlock}
            {dropdown !== "" && (
                <Dropdown
                    ref={ref}
                    theme={theme}
                    dropdown={dropdown}
                    currentUser={currentUser}
                    logoutUser={logoutUser}
                    history={history}
                    fontSize={fontSize}
                    setPopUp={setPopUp}
                    setDropdown={setDropdown}
                />
            )}
        </Navbar>
    );
};

export default NavbarView;

// Sticky positioned Navbar
// exported to LandingPage for navbar
export const Navbar = styled.div`
    position: sticky;
    top: 0;
    z-index: 100; /* to move anything above the navbar z-index needs to be higher than 100 */
    height: ${({ theme }) => theme.sizes.navHeight};
    background-color: ${({ theme }) => theme.colors.primary};
    box-shadow: 0 1px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    font-size: ${fontSize};
    padding: 0 1rem;
`;

// Common code for right and left wrappers
const NavSection = styled.div`
    display: flex;
    align-items: center;
`;

const LeftWrapper = styled(NavSection)`
    flex: 1; /* fill remainder of navbar space */
    /* justify-content: flex-start; */
`;

const RightWrapper = styled(NavSection)`
    flex: 0; /* don't grow and don't shrink */
    margin-left: 1rem; /* space between search box and right wrapper on smaller screens */
`;

const AppNameLogoGroup = styled.div`
    position: relative;
    /* height: 1.3rem;
    background-color: #353535; */
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const AppName = styled.p`
    /* color: ${(props) => props.theme.colors.accent}; */
    display: inline;
    margin: 0;
    margin-left: 0.5rem;
    font-weight: 600;
    /* font-size: 1.6rem; */
    /* Upppercase fromat */
    font-size: 1.3rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    @media (max-width: ${({ criticalScreenSize }) => criticalScreenSize}) {
        display: none;
    }
`;

// Hamburger used to toggle full screen nav on and off. Not display on screens larger than criticalScreenSize
const Hamburger = styled.div`
    display: none;
    @media (max-width: ${({ criticalScreenSize }) => criticalScreenSize}) {
        display: inline;
        margin: 0 1rem;
        font-size: calc(${({ fontSize }) => fontSize}*1.5);
        color: ${({ fullScreenSidebar, theme }) =>
            fullScreenSidebar ? theme.colors.darkAccent : "inherit"};
    }
`;

const SearchBoxSection = styled.div`
    display: flex;
    flex: 1; /* fills the remainder of LeftWrapper untill max */
    max-width: 750px;
    align-items: center;
    @media (max-width: ${({ theme }) => theme.sizes.mdViewWidth}) {
        display: none;
    }
`;

const Username = styled.div`
    color: ${(props) => props.theme.colors.darkAccent};
    cursor: pointer;
    display: ${({ name }) =>
        name.length < 15 ? "block" : "none"}; /* hidden if large name */
    margin: 0;
    padding: 1rem 0;
    @media (max-width: ${({ theme }) => theme.sizes.lgViewWidth}) {
        display: none;
    }
`;

const VertLine = styled.span`
    color: ${(props) => props.theme.colors.secondary};
    font-weight: 200;
    margin: 0 0.6rem;
`;

const NavBtnWrapper = styled.div`
    margin: ${({ btnMargin }) => btnMargin};
    position: relative;
`;

// Positions notification badge
const NotificationBadgeWrapper = styled.div`
    position: absolute;
    z-index: 1;
    top: -0.2rem;
    right: -0.5rem;
`;
