import { useEffect, useState } from "react";

import styled from "styled-components";

import { useAuth0 } from "@auth0/auth0-react";
import { sendApiRequest } from "../../../services/api";

import InputGroup from "../../../library/common/InputGroup";
import FooterBar from "../../../library/common/PopupFooterBar";
import HeaderBar from "../../../library/common/PopupHeaderBar";

import { INPUT_TYPES } from "../../../library/common/constants";

import {
    handleTextChange,
    postContainsContent,
} from "../../../library/utilities";

import { ContentBox } from "../../../resources/styles";

// Edit post text. Uses img state for front end validation, posts must have at least one image OR text
const EditPostTextPopupView = ({
    theme,
    setPopUpToDisplay,
    postState,
    setPostState,
    addError,
    loading,
    setLoading,
    LoadingScreen,
}) => {
    const { getAccessTokenSilently } = useAuth0();

    const [formState, setFormState] = useState(postState);

    // State used in front end validation
    const [validationFailedState, setValidationFailedState] = useState({});

    // remove loading screen on mount
    useEffect(() => {
        setLoading(false);
    }, [setLoading]);

    // Clears noContent error once its been added to state as user fixes it
    useEffect(() => {
        if (validationFailedState.noContent !== undefined) {
            return postContainsContent(
                formState.img,
                formState.text || "",
                validationFailedState,
                setValidationFailedState
            );
        }
    }, [formState, validationFailedState]);

    const handleSubmit = async () => {
        try {
            setLoading(true);
            if (
                postContainsContent(
                    formState.img,
                    formState.text || "",
                    validationFailedState,
                    setValidationFailedState
                )
            ) {
                await sendApiRequest(getAccessTokenSilently, {
                    method: "put",
                    url: `/api/posts/${postState._id}`,
                    data: { text: formState.text },
                });

                // update parent state
                setPostState((f) => {
                    return {
                        ...f,
                        text: formState.text,
                    };
                });
                setPopUpToDisplay("");
            } else {
                // post fails validation, error will be shown in DOM through validationFailedState
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            addError(`Error: ${error.message}`);
        }
    };

    if (loading) {
        return <LoadingScreen />;
    } else {
        return (
            <Wrapper>
                <HeaderBar
                    setPopUpToDisplay={setPopUpToDisplay}
                    popupTitle={"Edit Post"}
                />
                <ContentBox>
                    <ContentWrapper>
                        <InputGroup
                            type={INPUT_TYPES.TEXT_AREA}
                            rows={"5"}
                            handleChange={(e) =>
                                handleTextChange(e, setFormState, formState)
                            }
                            errorMessage={validationFailedState.noContent}
                            value={formState.text}
                            name="text"
                            title={"Post Text"}
                        />
                        <Message>
                            Note images can't be edited after posting.
                        </Message>
                    </ContentWrapper>
                </ContentBox>
                <FooterBar
                    handleCancel={() => setPopUpToDisplay("")}
                    cancelLabel={"Cancel"}
                    handleDone={handleSubmit}
                    doneLabel={"Save"}
                />
            </Wrapper>
        );
    }
};

export default EditPostTextPopupView;

const Wrapper = styled.div`
    width: min(600px, 95vw);
`;

const ContentWrapper = styled.div`
    padding: 1rem 2rem;
`;

const Message = styled.p`
    margin: 0;
    padding-top: 0.5rem;
    color: ${(props) => props.theme.colors.offSecondary};
    /* padding: 1.5rem 2rem;
    @media (max-width: ${({ theme }) => theme.sizes.smViewWidth}) {
        padding: 1rem;
    } */
`;
