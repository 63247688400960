import styled from "styled-components";

// Message with icon above it used for no profile details, no images yet, no more posts etc
// color and iconSize are optional
const NoDetailsView = ({ theme, icon, iconSize, message, color }) => {
    return (
        <NoProfileDetailsSection color={color || theme.colors.offSecondary}>
            <NoProfileIcon iconSize={iconSize || "6rem"}>{icon}</NoProfileIcon>
            <NoDetailsMessage>{message}</NoDetailsMessage>
        </NoProfileDetailsSection>
    );
};

export default NoDetailsView;

const NoProfileIcon = styled.div`
    font-size: ${(props) => props.iconSize};
    display: flex;
`;

const NoProfileDetailsSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin: 2rem 0.5rem;
    color: ${(props) => props.color};
`;

const NoDetailsMessage = styled.h4`
    margin: 1.5rem 0;
`;
