import styled, { css } from "styled-components";

import LoadingImgShimmer from "../LoadingShimmer";
import { useScrollPosition } from "../../../library/utilities/ScrollPosition";
import Button from "../../common/Button";

const ImageGridView = ({
    imgGridData,
    handleClick,
    isLoading,
    handleLoadMore,
    canLoadMore,
}) => {
    const GridItems =
        imgGridData.length > 0 ? (
            imgGridData.map((img, i) => {
                return (
                    <PhotoWrapper key={i} onClick={() => handleClick(img)}>
                        <Photo
                            name={img.postId}
                            src={img.imgUrl}
                            alt={img.imgUrl}
                        />
                    </PhotoWrapper>
                );
            })
        ) : (
            <div></div>
        );

    // A number of shimmering loading image placeholders
    const LoadingImages = isLoading ? (
        [...Array(3)].map((_, i) => {
            return (
                <PhotoWrapper key={i}>
                    <LoadingPhotoBox>
                        <LoadingImgShimmer />
                    </LoadingPhotoBox>
                </PhotoWrapper>
            );
        })
    ) : (
        <div></div>
    );

    useScrollPosition(
        ({ currPos }) => {
            // Will load more when user approx one viewport height from bottom for page
            if (
                -currPos.y + window.innerHeight >=
                document.body.scrollHeight - window.innerHeight
            ) {
                handleLoadMore();
            }
        },
        [isLoading, canLoadMore], // dependencies
        undefined,
        false,
        200 // re evaluate scroll position every 200 ms
    );

    return (
        <div>
            <GridWrapper>
                {GridItems}
                {LoadingImages}
            </GridWrapper>
            {canLoadMore && !isLoading && (
                // If screen size isn't filled on first load then no scroll event occur and automatic load more never fires so use button as a fallback
                <LoadMoreRow>
                    <LoadMoreBtnWrapper>
                        <Button
                            icon={<i className="fas fa-plus-circle"></i>}
                            handleClick={handleLoadMore}
                            secondaryBtnColors={true}
                        >
                            <b>Load</b>
                        </Button>
                    </LoadMoreBtnWrapper>
                </LoadMoreRow>
            )}
        </div>
    );
};

export default ImageGridView;

const GridWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding: 0.5rem;
`;

const PhotoWrapper = styled.div`
    position: relative;
    width: calc(100% / 3);
    padding-top: calc(100% / 3);
`;

const shared = css`
    padding: 0.5rem;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    @media (max-width: ${({ theme }) => theme.sizes.smViewWidth}) {
        padding: 0.08rem;
    }
`;

const Photo = styled.img`
    ${shared}
    cursor: pointer;
`;

const LoadingPhotoBox = styled.div`
    ${shared}
`;

const LoadMoreRow = styled.div`
    display: flex;
    justify-content: center;
    padding: 1rem 0;
`;

const LoadMoreBtnWrapper = styled.div`
    flex: 0 1 100px;
`;
