import { useState, useEffect } from "react";
import { useApi } from "../../services/api";

import CreatePostView from "./CreatePost-view";

import {
    FILE_UPLOAD_CONFIG,
    POST_POPUP_TYPES,
    PRIMARY_AUTHOR,
} from "../../library/common/constants";

import {
    addImagesToState,
    packStateInFormDataObj,
} from "../../library/utilities";

const MAX_NUM_PHOTOS = 4;

const CreatePost = ({
    currentUser,
    history,
    errors,
    addError,
    setPopUpToDisplay,
}) => {
    const { isLoading: popupLoading, refresh: submitPost } = useApi({});

    const [imgUploading, setImgUploading] = useState(false);

    const [formState, setFormState] = useState({
        images: [],
        files: [],
        userTags: [],
        companyTags: [],
        text: "",
        primaryAuthor: PRIMARY_AUTHOR.USER, // default author is the current user
        company: {},
        site: {},
    });
    const { images = [], text = "", userTags } = formState;

    const { companiesAdminOf = [] } = currentUser.user;
    const userIsCompanyAdmin = companiesAdminOf.length > 0;

    // state to handle views - skip author select if currentUser is not an admin of a company
    const [popupScreenToDisplay, setPopupScreenToDisplay] = useState(
        userIsCompanyAdmin
            ? POST_POPUP_TYPES.SELECT_AUTHOR
            : POST_POPUP_TYPES.IMAGE_UPLOAD
    );

    //==================== Validation Handling ====================//

    const [validationFailedState, setValidationFailedState] = useState({
        noContent: false,
        uploadError: undefined,
    });

    // when hasContent changes, if noContent flag is turned on, check it and turn it off
    const hasContent = images.length > 0 || text.length > 0;
    useEffect(() => {
        setValidationFailedState((f) => ({
            ...f,
            noContent: f.noContent && !hasContent,
        }));
    }, [hasContent]);

    const removeNoContentError = () => {
        setValidationFailedState((f) => ({ ...f, noContent: false }));
    };

    const removeUploadError = () => {
        setValidationFailedState((f) => ({ ...f, uploadError: undefined }));
    };

    const validationAlertProps = {
        validationFailedState,
        removeNoContentError,
        removeUploadError,
    };

    //==================== Post Content Handling ====================//

    // add file and image to state
    const onImageFileChange = async (e) => {
        try {
            setImgUploading(true);
            await addImagesToState(
                e,
                setFormState,
                formState,
                "images",
                "multiple",
                setValidationFailedState,
                { initial: images.length, max: MAX_NUM_PHOTOS }
            );
        } catch (error) {
            addError("Error converting image to JPEG");
        }
        setImgUploading(false);
    };

    const handleImageDelete = (indexToDelete) => {
        setFormState((f) => ({
            ...f,
            images: f.images.filter((_, i) => i !== indexToDelete),
            files: f.files.filter((_, i) => i !== indexToDelete),
        }));
    };

    const handlePostTextChange = (e) => {
        e.preventDefault();
        setFormState((f) => ({ ...f, [e.target.name]: e.target.value }));
    };

    const handleClose = () => {
        if (hasContent) {
            setPopupScreenToDisplay(POST_POPUP_TYPES.EXIT_CONFIRMATION);
        } else setPopUpToDisplay("");
    };

    //==================== Select Author View Props ====================//

    const { _id: selectedCompanyId } = formState.company;

    // Updates form state with current user as the author
    const setAuthorToUser = () => {
        setFormState((f) => ({
            ...f,
            primaryAuthor: PRIMARY_AUTHOR.USER,
            company: { _id: undefined },
        }));
    };

    // Updates form state with the clicked company as the author
    const setAuthorToCompany = (company) => {
        setFormState((f) => ({
            ...f,
            primaryAuthor: PRIMARY_AUTHOR.COMPANY,
            company,
        }));
    };

    const selectAuthorViewProps = {
        currentUser,
        selectedCompanyId,
        setPopUpToDisplay,
        handleClose,
        handleNext: () => {
            setPopupScreenToDisplay(POST_POPUP_TYPES.IMAGE_UPLOAD);
        },
        setAuthorToUser,
        setAuthorToCompany,
    };

    //==================== Image Upload View Props ====================//

    const imageUploadViewProps = {
        imgUploading,
        setPopUpToDisplay,
        validationAlertProps,
        handleBack: userIsCompanyAdmin
            ? () => {
                  setPopupScreenToDisplay(POST_POPUP_TYPES.SELECT_AUTHOR);
              }
            : undefined,
        handleChooseImage: (e) => {
            onImageFileChange(e);
            setPopupScreenToDisplay(POST_POPUP_TYPES.TEXT_INPUT);
        },
        handleClose,
        handleNext: () => {
            setPopupScreenToDisplay(POST_POPUP_TYPES.TEXT_INPUT);
        },
    };

    //==================== Text Input View Props ====================//

    const textInputViewProps = {
        images,
        imgUploading,
        maxNumPhotos: MAX_NUM_PHOTOS,
        setPopUpToDisplay,
        text,
        validationAlertProps,
        handleBack: () => {
            setPopupScreenToDisplay(POST_POPUP_TYPES.IMAGE_UPLOAD);
        },
        handleClose,
        handleImageDelete,
        handleNext: () => {
            setPopupScreenToDisplay(POST_POPUP_TYPES.CREATE_POST);
        },
        handlePostTextChange,
        onImageFileChange,
    };

    //==================== Post Preview View Props ====================//

    const post = {
        ...formState,
        user: currentUser.user, // _id is not required for preview, => don't need to recast
        img: images.map((image) => image.path),
    };

    // conditionally adds a popover with a reminder message to tag users
    const [msgPopoverVisible, setMsgPopoverVisible] = useState(false);
    useEffect(() => {
        const timerId = setTimeout(() => {
            if (popupScreenToDisplay === POST_POPUP_TYPES.CREATE_POST) {
                setMsgPopoverVisible(
                    (f) => !f && images.length > 0 && userTags.length === 0
                );
            }
        }, 750);
        return () => {
            clearTimeout(timerId);
        };
    }, [images, popupScreenToDisplay, userTags]);

    // recasts certain fields to the template required for the server to process the form
    const formattedState = {
        ...formState,
        userTags: formState.userTags.map((user) => user._id),
        companyTags: formState.companyTags.map((company) => company._id),
        site: formState.site._id,
        company: formState.company._id,
    };

    const handleSubmit = () => {
        if (hasContent) {
            submitPost({
                method: "post",
                url: "/api/posts/",
                data: packStateInFormDataObj(formattedState, "images"),
                ...FILE_UPLOAD_CONFIG,
                callback: (res) => {
                    history.push(`/posts/${res._id}`);
                    setPopUpToDisplay("");
                },
            });
        } else setValidationFailedState((f) => ({ ...f, noContent: true }));
    };

    const postPreviewViewProps = {
        history,
        post,
        msgPopoverVisible,
        setPopUpToDisplay,
        validationAlertProps,
        clearMsgPopover: () => {
            setMsgPopoverVisible(false);
        },
        handleAddSite: () => {
            setPopupScreenToDisplay(POST_POPUP_TYPES.ADD_SITE);
        },
        handleAddTags: () => {
            setPopupScreenToDisplay(POST_POPUP_TYPES.ADD_TAGS);
        },
        handleBack: () => {
            setPopupScreenToDisplay(POST_POPUP_TYPES.TEXT_INPUT);
        },
        handleClose,
        handleImageDelete,
        handlePostTextChange,
        handleSubmit,
        onImageFileChange,
    };

    //==================== Add Site View Props ====================//

    // state to hold a "copy" of the site before saving
    const [siteCopy, setSiteCopy] = useState({});
    const { name: siteCopyName } = siteCopy;

    // commits site copy to main form state
    const doneAddingSite = () => {
        setFormState((f) => ({ ...f, site: siteCopy }));
        setPopupScreenToDisplay(POST_POPUP_TYPES.CREATE_POST);
    };

    const addSiteViewProps = {
        siteCopyName,
        addSite: (result) => {
            setSiteCopy({ _id: result.id, name: result.name });
        },
        doneAddingSite,
        handleClose,
        removeSite: () => {
            setSiteCopy({});
        },
    };

    //==================== Add Tags View Props ====================//

    // doneTagging function used in tagging screen
    const doneTagging = (updatedUserTags, updatedCompanyTags) => {
        const userTags = updatedUserTags.map((user) => ({
            _id: user._id,
            img: user.img,
            username: user.username,
        }));
        const companyTags = updatedCompanyTags.map((company) => ({
            _id: company._id,
            img: company.img,
            name: company.name,
        }));
        setFormState((f) => ({ ...f, userTags, companyTags }));
        setPopupScreenToDisplay(POST_POPUP_TYPES.CREATE_POST);
    };

    const addTagsViewProps = {
        errors,
        setPopUpToDisplay,
        userIsAdmin: true,
        add: true,
        allowCompanies: true,
        companies: formState.companyTags,
        users: userTags,
        handleSubmit: doneTagging,
        submitText: "Done",
        popupTitle: "Tag",
        listTitle: "Tagged",
        noEditingAllowed: true,
        handleDone: () => {
            setPopupScreenToDisplay(POST_POPUP_TYPES.CREATE_POST);
        },
        handleCancel: () => {
            setPopupScreenToDisplay(POST_POPUP_TYPES.CREATE_POST);
        },
        cancelText: "Undo",
        customExitHandling: () => {
            setPopupScreenToDisplay(POST_POPUP_TYPES.EXIT_CONFIRMATION);
        },
    };

    //==================== Exit Confirmation View Props ====================//

    const exitConfirmationViewProps = {
        handleCancel: () => {
            setPopupScreenToDisplay(POST_POPUP_TYPES.CREATE_POST);
        },
        cancelText: "Go Back",
        handleSubmit: () => {
            setPopUpToDisplay("");
        },
        submitText: "Discard",
        title: "Discard Draft",
        setPopUpToDisplay,
        message: "Are you sure you want to exit? Your draft will be lost.",
    };

    //==================== // ====================//

    const createPostViewProps = {
        popupLoading,
        popupScreenToDisplay,
        selectAuthorViewProps,
        imageUploadViewProps,
        textInputViewProps,
        postPreviewViewProps,
        addSiteViewProps,
        addTagsViewProps,
        exitConfirmationViewProps,
    };

    return <CreatePostView {...createPostViewProps} />;
};

export default CreatePost;
