import React, { useEffect } from "react";
import styled, { withTheme } from "styled-components";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Icon from "../../library/common/Icon";

// Animation properties
const rotationVariant = {
    visible: {
        rotate: 0,
        transition: { duration: 1, type: "tween", delay: 0 },
    },
    hidden: {
        rotate: 8,
        transition: { duration: 0.1, type: "tween" },
    },
};

const SharePhone = () => {
    // Animation logic
    const control = useAnimation();
    const { ref: topRef, inView: topInView } = useInView();
    const { ref: bottomRef, inView: bottomInView } = useInView();
    useEffect(() => {
        if (topInView && bottomInView) {
            control.start("visible");
        } else if (topInView || bottomInView) {
            return;
        } else {
            control.start("hidden");
        }
    }, [control, topInView, bottomInView]);

    return (
        <ContentBox>
            <PhoneSection>
                <TopOfPhone ref={topRef} />
                <CircleUnderPhone />
                <Phone
                    src={
                        "https://cosqos-bucket.s3.ap-southeast-2.amazonaws.com/3ProfieBrayden.png"
                    }
                    variants={rotationVariant}
                    initial={"hidden"}
                    animate={control}
                />
                <BottomOfPhone ref={bottomRef} />
            </PhoneSection>
            <PointsListWrapper>
                <PointItemWrapper>
                    <IconRow>
                        <IconCircle>
                            <Icon name={"camera"} />
                        </IconCircle>
                    </IconRow>
                    <PointTextWrapper>
                        <TextHeader>Booms, Not Buzzwords.</TextHeader>
                        <TextBody>Let your work do the talking.</TextBody>
                        {/* <TextBody>
                            A picture is worth a thousand words. Let your work
                            do the talking.
                        </TextBody> */}
                    </PointTextWrapper>
                </PointItemWrapper>
                <PointItemWrapper padTop>
                    <IconRow>
                        <IconCircle>
                            <Icon name={"flag"} />
                        </IconCircle>
                    </IconRow>
                    <PointTextWrapper>
                        <TextHeader>Worksites, Not Job Titles.</TextHeader>
                        <TextBody>Focus on what really matters.</TextBody>
                    </PointTextWrapper>
                </PointItemWrapper>
                <PointItemWrapper padTop>
                    <IconRow>
                        <IconCircle padBottom={"3px"}>
                            <Icon name={"hard-hat"} />
                        </IconCircle>
                    </IconRow>
                    <PointTextWrapper>
                        <TextHeader>Experience, Not Degrees.</TextHeader>
                        <TextBody>Prioritize hands-on experience.</TextBody>
                    </PointTextWrapper>
                </PointItemWrapper>
            </PointsListWrapper>
        </ContentBox>
    );
};

export default withTheme(SharePhone);

const phoneSizeBreakPoint = "544px";
const phoneSectionBreak = "900px";

const ContentBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    @media (max-width: ${phoneSectionBreak}) {
        flex-direction: column;
        align-items: center;
    }
`;

const PhoneSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    /* @media (max-width: ${phoneSectionBreak}) {
        padding: 20px 0px 0px 0px;
    } */
`;

const TopOfPhone = styled.div``;
const BottomOfPhone = styled.div``;

const Phone = styled(motion.img)`
    width: 280px;
    position: relative;
    @media (max-width: ${phoneSizeBreakPoint}) {
        width: 240px;
    }
`;

const CircleUnderPhone = styled.div`
    height: 360px;
    width: 360px;
    border-radius: 50%;
    position: absolute;
    background-color: ${(props) => props.theme.colors.secondary};
    @media (max-width: ${phoneSizeBreakPoint}) {
        height: 280px;
        width: 280px;
    }
`;

const TextHeader = styled.div`
    font-family: "Montserrat", sans-serif;
    max-width: 330px;
    font-size: 24px;
    line-height: 23px;
    padding: 0 0 0px 0;
    font-weight: 600;
    @media (max-width: ${phoneSectionBreak}) {
        padding: 10px 25px 0px 0px;
        max-width: 1000px;
        font-size: 20px;
    }
`;

const TextBody = styled(TextHeader)`
    font-size: 17px;
    line-height: 25px;
    font-weight: 400;
    padding: 5px 0px 0px 0px;
    /* color: ${(props) => props.theme.colors.justOffSecondary}; */
    /* text-align: justify; */
    @media (max-width: ${phoneSectionBreak}) {
        padding: 0 25px 0px 0px;
        font-size: 15px;
    }
`;

const PointsListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* padding-left: 100px; */
    @media (max-width: ${phoneSectionBreak}) {
        /* align-self: flex-start; */
        padding: 30px 20px 0 20px;
    }
    @media (max-width: ${450}px) {
        align-self: flex-start;
    }
`;

const PointItemWrapper = styled.div`
    padding-top: ${(props) => (props.padTop ? "40px" : "0px")};
    display: flex;
    /* flex-direction: row; */
    flex-direction: column;
    @media (max-width: ${phoneSectionBreak}) {
        padding-top: ${(props) => (props.padTop ? "20px" : "0px")};
        flex-direction: column;
    }
`;

const PointTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    /* padding-left: 20px; */
    /* @media (max-width: ${phoneSectionBreak}) {
        padding-left: 0;
    } */
    /* justify-content: center; */
    /* background-color: cyan; */
`;

const IconRow = styled.div`
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    @media (max-width: ${phoneSectionBreak}) {
        padding-bottom: 0;
    }
`;

const IconCircle = styled.div`
    color: ${(props) => props.theme.colors.lightAccent};
    background-color: ${(props) => props.theme.colors.secondary};
    border-radius: 50%;
    font-size: 30px;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: ${(props) => props.padBottom || 0};
    @media (max-width: ${phoneSectionBreak}) {
        box-shadow: 3px 3px 5px #35353540;
        font-size: 22px;
        width: 40px;
        height: 40px;
    }
`;
