import { useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components"; // inject theme prop into all components down tree

import Main from "./MainContent";
import LandingPageContent from "./LandingPageContent";
import Navbar from "../../modules/Navbar";
import Sidebar from "../../modules/NavSideBar";
import "../../resources/styles/App.css";
import { NAV_POPUP_TYPES } from "../../library/common/constants";
import CreatePost from "../../modules/CreatePostForm";
import AppLoadingScreen from "../../modules/AppLoadingScreen";
import LandingPage from "../../modules/LandingPage";

export const criticalScreenSize = "992px"; // this is small but I needed to export so couldn't access theme

const AppScreen = ({ loaded, sidebar, signedIn }) => {
    const [popUp, setPopUp] = useState("");
    const [fullScreenSidebar, setFullScreenSidebar] = useState(false);

    if (loaded && signedIn) {
        return (
            <AppBox>
                <Navbar
                    setPopUp={setPopUp}
                    setFullScreenSidebar={setFullScreenSidebar}
                    fullScreenSidebar={fullScreenSidebar}
                    criticalScreenSize={criticalScreenSize}
                    // notificationsSocketState={notificationsSocketState}
                    // messagesSocketState={messagesSocketState}
                />
                <Content>
                    <LgScreenSidebar
                        showSidebar={sidebar.show}
                        criticalScreenSize={criticalScreenSize}
                    >
                        <StickyWrapper>
                            <Sidebar />
                        </StickyWrapper>
                    </LgScreenSidebar>
                    <SmScreenSidebar
                        fullScreenSidebar={fullScreenSidebar}
                        criticalScreenSize={criticalScreenSize}
                    >
                        <Sidebar setFullScreenSidebar={setFullScreenSidebar} />
                    </SmScreenSidebar>

                    <MainWrapper
                        fullScreenSidebar={fullScreenSidebar}
                        criticalScreenSize={criticalScreenSize}
                    >
                        <Main
                        // notificationsSocketState={notificationsSocketState}
                        // messagesSocketState={messagesSocketState}
                        />
                    </MainWrapper>
                    {popUp === NAV_POPUP_TYPES.createPost && (
                        <CreatePost
                            setPopUpToDisplay={setPopUp}
                            customExitHandling={() => {}}
                            // manually disables outside click exit, don't want user accidentally losing their post
                            // The way the popup structured, not easy to show warning confirmation box from outside click event
                        />
                    )}
                </Content>
            </AppBox>
        );
    } else if (loaded && !signedIn) {
        return (
            <AppBox>
                <LandingPageContent />
            </AppBox>
        );
    } else {
        return (
            <AppBox>
                <AppLoadingScreen />
            </AppBox>
        );
    }
};

function mapState(state) {
    return {
        sidebar: state.sidebar,
    };
}

export default connect(mapState)(AppScreen);

// Fit all of app inside
const AppBox = styled.div`
    box-sizing: border-box;
    width: 100%;
    min-height: 100vh;
    /* min-height: -webkit-fill-available; */
    /* height: 50vh;
    overflow: hidden; */
    display: flex;
    flex-direction: column;
`;

// Content below navbar, flexbox starting at bottom of navbar and z-index of 0 to start a new stacking context
const Content = styled.div`
    flex: 1;
    /* position: relative;
    z-index: 0; */
    display: flex;
    flex-direction: row;
`;

// General sidebar wrapper properties
const SidebarWrapper = styled.div`
    display: ${({ showSidebar }) => {
        return showSidebar ? "block" : "none";
    }};
`;

// Large screen sidebar wrapper. Fit sidebar to left of screen
export const LgScreenSidebar = styled(SidebarWrapper)`
    flex: 0 0 250px;
    /* max-width: 400px; */
    @media (max-width: ${({ criticalScreenSize }) => criticalScreenSize}) {
        /* Hide large screen sidebar on medium and smaller screens */
        display: none;
    }
`;

// Sidebar needs to remain fixed inside its wrapper on scroll
// Position Sticky sets position of sidebar to fixed when the viewport scrolls to navHeight down then acts like position: fixed but without loosing flex compatibility.
// Wrapper can be scrolled if the sidebar's height is bigger than available screen height
const StickyWrapper = styled.div`
    position: sticky;
    top: ${({ theme }) => theme.sizes.navHeight};
    max-height: calc(100vh - ${({ theme }) => theme.sizes.navHeight});
    overflow-y: auto;
`;

// Small screen sidebar wrapper only displays on small screens, at which point sidebar wrapper takes up whole screen except navbar
const SmScreenSidebar = styled(SidebarWrapper)`
    display: none;
    @media (max-width: ${({ criticalScreenSize }) => criticalScreenSize}) {
        width: 100%;
        min-height: calc(100vh - ${({ theme }) => theme.sizes.navHeight});
        display: ${({ fullScreenSidebar }) =>
            fullScreenSidebar ? "block" : "none"};
    }
`;

// Main is hidden on small screen when sidebar is being shown
const MainWrapper = styled.div`
    position: relative;
    flex: 1 1 1000px;
    min-width: 0; /* allow main wrapper to shrink smaller than its contents, needed for nowrap situations */
    @media (max-width: ${({ criticalScreenSize }) => criticalScreenSize}) {
        /* Hide main when sidebar showing on medium and smaller screens */
        display: ${({ fullScreenSidebar }) =>
            fullScreenSidebar ? "none" : "block"};
    }
`;
