import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "styled-components"; // inject theme prop into all components down tree

import { useAuth0 } from "@auth0/auth0-react";
import { sendApiRequest } from "../../services/api";
// import { useApi } from "../../services/api";

import { configureStore } from "../store";
import { setCurrentUser } from "../store/actions/auth";

import "../../resources/styles/App.css";
import { GlobalStyles } from "../../resources/styles/GlobalStyles";
import { lightTheme } from "../../resources/styles/Themes";

import AppScreen from "./AppScreen";

const store = configureStore();

const App = () => {
    const [appLoading, setAppLoading] = useState(true);
    const { isLoading, user, getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        (async () => {
            try {
                console.log(
                    "user in useEffect App (i.e. the user from useAuth0 hook"
                );
                if (!isLoading && !!user) {
                    const token = await getAccessTokenSilently();
                    console.log("accessToken", token);

                    if (!user.emailVerified)
                        console.log("please verify your email"); // TODO

                    // namespace to retrieve custom claims (family/given names) from useAuth0
                    // if using google-oauth2, then namespace should be blank
                    const NAMESPACE = "https://dev.cosqos/";
                    let userFamilyName, userGivenName;

                    const providerName = user.sub.split("|")[0];

                    if (providerName === "auth0") {
                        console.log("SIGN UP/IN WITH auth0");
                        console.log(user);
                        // Auth0 return names in an object with key https://dev.cosqos/user_metadata
                        // E.g. https://dev.cosqos/user_metadata: {family_name: "Hawkes", give_name: "Brayden"}
                        userFamilyName =
                            user[`${NAMESPACE}user_metadata`].family_name;
                        userGivenName =
                            user[`${NAMESPACE}user_metadata`].given_name;
                    } else if (providerName === "apple") {
                        console.log("SIGN UP/IN WITH APPLE");
                        // Apple return nickname only which looks like "Brayden.Hawkes"
                        const splitNickname = user.nickname.split(".");
                        userFamilyName = splitNickname[1] || "";
                        userGivenName = splitNickname[0];
                    } else {
                        console.log("SIGN UP/IN WITH GOOGLE");
                        // Google (or potentially Facebook if you bring that back)
                        userFamilyName = user.family_name;
                        userGivenName = user.given_name;
                    }
                    console.log("Data to send to server");
                    console.log(userFamilyName);
                    console.log({
                        sub: user.sub,
                        email: user.email,
                        familyName: userFamilyName,
                        givenName: userGivenName,
                        picture: user.picture,
                    });
                    const data = await sendApiRequest(getAccessTokenSilently, {
                        method: "POST",
                        url: "/api/auth/create",
                        data: {
                            sub: user.sub,
                            email: user.email,
                            familyName: userFamilyName,
                            givenName: userGivenName,
                            picture: user.picture,
                        },
                    });

                    console.log(
                        "data coming back from sendApiRequest /api/auth/create",
                        data
                    );

                    await store.dispatch(
                        setCurrentUser({
                            user: {
                                id: data._id,
                                username: `${data.givenName} ${data.familyName}`,
                                familyName: data.familyName,
                                givenName: data.givenName,
                                img: data.displayPicture
                                    ? data.displayPicture.path || ""
                                    : "",
                                companiesFollowed: data.companiesFollowed || [],
                                companiesAdminOf: data.companiesAdminOf || [],
                            },
                        })
                    );
                    setAppLoading(false);
                } else if (!isLoading && !user) {
                    // if no longer loading but user not logged in also setAppLoading false
                    setAppLoading(false);
                }
            } catch (error) {
                console.log(error);
                setAppLoading(false);
            }
        })();
    }, [user, isLoading, getAccessTokenSilently]);

    const theme = lightTheme;

    return (
        <Provider store={store}>
            <Router>
                <ThemeProvider theme={theme}>
                    <GlobalStyles />
                    <AppScreen
                        loaded={!appLoading}
                        theme={theme}
                        signedIn={!!user}
                    />
                </ThemeProvider>
            </Router>
        </Provider>
    );
};

export default App;
