import { SET_MY_SITES, CLEAR_MY_SITES } from "../actionTypes";

const newsFeedReducer = (state = { sites: [], date: new Date(0) }, action) => {
    switch (action.type) {
        case SET_MY_SITES:
            const mySitesArr = [...action.data].sort((a, b) =>
                b.createdAt - a.createdAt
                // a.createdAt > b.createdAt
                //     ? -1
                //     : a.createdAt < b.createdAt
                //     ? 1
                //     : 0
            );
            console.log("UPDATING STORE => Your sites updated");
            return {
                sites: mySitesArr,
                date: new Date(),
            };
        case CLEAR_MY_SITES:
            return { sites: [], date: new Date(0) };
        default:
            return state; // initialisation
    }
};

export default newsFeedReducer;
