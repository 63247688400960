import {
    SET_NEWS_FEED,
    CLEAR_NEWS_FEED,
    REMOVE_FROM_FEED,
} from "../actionTypes";

const newsFeedReducer = (state = { posts: [], date: new Date(0) }, action) => {
    switch (action.type) {
        case SET_NEWS_FEED:
            // merge current news feed state with incoming data, filter out duplicates (gives preference to posts in action obj) and sort in createdAt order
            const newsFeedPosts = [
                ...action.data,
                ...state.posts.filter((oldPost) =>
                    action.data.every((newPost) => newPost._id !== oldPost._id)
                ),
            ].sort((a, b) => b.createdAt.localeCompare(a.createdAt));

            return {
                posts: newsFeedPosts,
                date: new Date(),
            };
        case CLEAR_NEWS_FEED:
            return { posts: [], date: new Date(0) };
        case REMOVE_FROM_FEED:
            return {
                posts: [
                    ...state.posts.filter((el) => el._id !== action.data), // remove item with _id matching item to be removed
                ],
                date: state.date, // leave date unchanged
            };
        default:
            return state; // initialisation
    }
};

export default newsFeedReducer;
