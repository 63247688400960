import styled from "styled-components";

import ServicesList from "../../ServicesList";
import { ContentBox } from "../../../../resources/styles/index";
import HeaderBar from "../../PopupHeaderBar";
import { titleCase } from "../../../utilities";
import FooterBar from "../../PopupFooterBar";

const ServiceListView = ({
    servicesName,
    formState,
    setServicesScreen,
    deleteItemFormState,
    handleSubmit,
    setPopUpToDisplay,
}) => {
    return (
        <Wrapper>
            <HeaderBar
                setPopUpToDisplay={setPopUpToDisplay}
                handleAdd={setServicesScreen}
                popupTitle={titleCase(servicesName)}
            />
            <ContentBox>
                <ContentWrapper>
                    {formState && formState[0] && (
                        <ServicesList
                            services={formState}
                            handleDeleteClick={deleteItemFormState}
                        />
                    )}
                </ContentWrapper>
            </ContentBox>
            <FooterBar
                handleDone={handleSubmit}
                handleCancel={() => {
                    setPopUpToDisplay("");
                }}
                cancelLabel={"Cancel"}
                doneLabel={"Save"}
            />
        </Wrapper>
    );
};

export default ServiceListView;

export const Wrapper = styled.div`
    width: min(
        800px,
        95vw
    ); /* Services have a flex wrap so the width effect numbers of columns*/
    min-height: 300px;
`;

const ContentWrapper = styled.div`
    padding: 1rem 2rem 1rem 2rem;
`;
