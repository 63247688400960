import styled from "styled-components";

import ButtonCircleIcon from "../../library/common/ButtonCircleIcon";

const btnSize = "2.3rem";
const iconFontSize = "1.2rem";

// Dropdown content when users hits account controls button
const DropdownControls = ({ currentUser, logoutUser, theme, history }) => {
    return (
        <Wrapper>
            <Heading>Account</Heading>
            <Item onClick={() => history.push(`/users/${currentUser.user.id}`)}>
                <ButtonCircleIcon
                    circleDiameter={btnSize}
                    fontSize={iconFontSize}
                    theme={theme}
                >
                    <i className="fas fa-pen" />
                </ButtonCircleIcon>
                <Text>Edit Profile</Text>
            </Item>
            <Item
                onClick={() => logoutUser({ returnTo: window.location.origin })}
            >
                <ButtonCircleIcon
                    circleDiameter={btnSize}
                    fontSize={iconFontSize}
                    theme={theme}
                >
                    <i
                        className="fas fa-sign-out-alt"
                        style={{ marginLeft: "0.15rem" }}
                    />
                </ButtonCircleIcon>
                <Text>Log Out</Text>
            </Item>
        </Wrapper>
    );
};

export default DropdownControls;

const Heading = styled.h3`
    margin: 0 0 0.4rem 0;
    color: ${(props) => props.theme.colors.darkAccent};
`;

const Text = styled.p`
    display: inline;
    margin: 0 0 0 1rem;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 1rem;
`;

const Item = styled.div`
    display: flex;
    align-items: center;
    margin: 0.4rem 0;
    padding: 0.2rem;
    cursor: pointer;
`;
