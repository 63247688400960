import { withRouter } from "react-router-dom";
import styled from "styled-components";

import HeaderBar from "../../PopupHeaderBar";
import FooterBar from "../../PopupFooterBar";
import UserList from "../../UserList";

import WithPopUp from "../../../hoc/WithPopUp";

import { ContentBox } from "../../../../resources/styles";

const ConxList = ({ userId, handleDone, popupTitle }) => {
    return (
        <Wrapper>
            <HeaderBar
                popupTitle={popupTitle}
                customExitHandling={handleDone}
            />
            <ContentBox>
                <ContentWrapper>
                    <UserList
                        otherSearchOptions={{ connectionsOnly: userId }}
                        allowConnect={true}
                        allowSearch={true}
                        searchPlaceholder={`Search`}
                        maxResults={40}
                        noResultsText={"No Contacts Found"}
                        handleCardClickCustom={handleDone}
                    />
                </ContentWrapper>
            </ContentBox>
            <FooterBar handleDone={handleDone} doneLabel={"Done"} />
        </Wrapper>
    );
};

export default (WithPopUp(withRouter(ConxList)));

const Wrapper = styled.div`
    width: min(550px, 95vw);
    min-height: 350px;
`;

const ContentWrapper = styled.div`
    padding: 1.3rem 1.5rem 0.2rem 1.5rem;
    min-height: 350px;
    position: relative; /* Must position relative for GreyBox to work properly */
    @media (max-width: ${({ theme }) => theme.sizes.mdViewWidth}) {
        padding: 0.5rem 0.7rem;
    }
`;

