import { SET_SHOW_SIDEBAR } from "../actionTypes";

export function setShowSidebar(showSideBar) {
    return {
        type: SET_SHOW_SIDEBAR,
        showSideBar,
    };
}

// export function showSidebar(newState) {
//     return (dispatch) => {
//         dispatch(setShowSidebar({ showSidebar: newState })); // remove user from store
//     };
// }
