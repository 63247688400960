import styled from "styled-components";

import {
    USER_SEARCH,
    COMPANY_SEARCH,
    SERVICE_SEARCH,
    POST_SEARCH,
    LICENCE_SEARCH,
    SITE_SEARCH,
} from "../../library/common/constants";
import SearchBoxWithToggler from "../../library/common/SearchBoxWithToggler";

const SearchPageContainer = ({ theme, history }) => {
    // Handles select from searchbox dropdown
    const handleSelect = (result, searchType) => {
        if (searchType.clientRoute) {
            // remove search box before navigating to page
            history.push(`${searchType.clientRoute}/${result.id}`);
        } else {
            alert("No view route");
        }
    };

    return (
        <Wrapper>
            <PageTitle>Search {theme.appName}</PageTitle>
            <SearchWrapper>
                <SearchBoxWithToggler
                    searchTypes={[
                        USER_SEARCH,
                        COMPANY_SEARCH,
                        SERVICE_SEARCH,
                        POST_SEARCH,
                        LICENCE_SEARCH,
                        SITE_SEARCH,
                    ]}
                    defaultSearchType={USER_SEARCH}
                    handleSelect={handleSelect}
                    singleRowView={true}
                    singleRowOptions={{
                        btnBgColor: theme.colors.secondary,
                        btnTextColor: theme.colors.primary,
                        rowPaddingColor: `${theme.colors.offPrimary}50`,
                    }}
                    autoFocus={true}
                />
            </SearchWrapper>
        </Wrapper>
    );
};

export default SearchPageContainer;

const Wrapper = styled.div`
    position: relative;
    min-height: calc(100vh - ${(props) => props.theme.sizes.navHeight});
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${(props) => props.theme.colors.primary};
    @media (max-width: ${({ theme }) => theme.sizes.smViewWidth}) {
        background-color: ${(props) => props.theme.colors.body};
    }
`;

const SearchWrapper = styled.div`
    width: 100%;
    max-width: 750px;
`;

const PageTitle = styled.h2`
    padding-top: min(80px, 25vh);
    padding-bottom: 1rem;
    color: ${(props) => props.theme.colors.darkAccent};
    text-transform: capitalize;
    letter-spacing: 0.1rem;
`;
