import styled, { withTheme } from "styled-components";

import ChatListItem from "./ChatListItem-view";

import ButtonCircleIcon from "../../../library/common/ButtonCircleIcon";
import { AddIcon } from "../../../library/common/Icon";

const ChatListView = ({
    theme,
    chats,
    handleClickChatItem,
    handleNewChatBtn,
    conversationsLoading = false,
    listOnly = false,
}) => {

    const chatsToRender = chats.map((el, i) =>
        <ChatListItem
            key={i}
            chat={el}
            handleItemClick={() => { handleClickChatItem(el); }}
        />
    );

    const chatsLoading = Array(8).fill().map((_, i) => (
        <ChatListItem
            key={i}
            isLoading={true}
        />
    ));

    const chatList = conversationsLoading ?
        chatsLoading :
        chats.length > 0 ?
            chatsToRender :
            <NoChatsWrapper>
                <NoChatsMsg>No Active Chats</NoChatsMsg>
            </NoChatsWrapper>;

    return listOnly ?
        chatList :
        <Wrapper>
            <Header >
                <Title>Messages</Title>
                <ButtonCircleIcon
                    circleDiameter={"2.5rem"}
                    fontSize={"1.2rem"}
                    bgColor={theme.colors.darkAccent}
                    iconColor={theme.colors.primary}
                    handleClick={handleNewChatBtn}
                >
                    <AddIcon />
                </ButtonCircleIcon>
            </Header>
            <ChatsWrapper>
                {chatList}
            </ChatsWrapper>
        </Wrapper>;

};

export default withTheme(ChatListView);

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const ChatsWrapper = styled.div`
    padding: 1rem 0.5rem;
    flex: 1;
    overflow-y: auto;
`;

const NoChatsWrapper = styled.div`
    padding: 2rem 0.5rem;
`;

const NoChatsMsg = styled.div`
    padding: 0 0.5rem 0.3rem 0.5rem;
    text-align: center;
    color: ${(props) => props.theme.colors.offSecondary};
`;

const Header = styled.div`
    height: 5rem;
    box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    box-shadow: none;
    @media (max-width: ${({ theme }) => theme.sizes.mdViewWidth}) {
        box-shadow: 3px 0px 5px rgba(0, 0, 0, 0.1);
    }
`;

const Title = styled.h4`
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${(props) => props.theme.colors.darkAccent};
`;
