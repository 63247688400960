import styled from 'styled-components';

import ChatList from "./ChatList";
import ChatDetails from "./ChatDetails-view";
import PopupRouter from "./PopupRouter-container";

const MessagesView = ({
    listViewFocus,
    chatDetailsProps,
    chatListProps,
    popUpToDisplay,
    popupProps,
}) => {

    return (
        <Wrapper>
            <ChatListCol listViewFocus={listViewFocus} >
                <ChatList {...chatListProps} />
            </ChatListCol>
            <ChatViewCol listViewFocus={listViewFocus} >
                <ChatDetails {...chatDetailsProps} />
            </ChatViewCol>
            {popUpToDisplay !== "" && (
                <PopupRouter {...popupProps} />
            )}
        </Wrapper>
    );
};

export default MessagesView;

const Wrapper = styled.div`
display: flex;
width: 100%;
height: calc(100vh - ${({ theme }) => theme.sizes.navHeight});
max-height: calc(100vh - ${({ theme }) => theme.sizes.navHeight});
background-color: ${({ theme }) => theme.colors.primary};
`;

const ChatListCol = styled.div`
    flex: 1 1 400px;
    /* border-right: 1px solid ${(props) => props.theme.colors.offPrimary}; */
    min-width: 0;
    background-color: ${({ theme }) => theme.colors.body};
    @media (max-width: ${({ theme }) => theme.sizes.mdViewWidth}) {
        display: ${({ listViewFocus }) => listViewFocus ? "block" : "none"};
        flex: 1;
        background-color: ${({ theme }) => theme.colors.primary};
    }
`;

const ChatViewCol = styled.div`
    flex: 1 1 1000px;
    min-width: 0;
    position: relative; /* Used to fill col height in child */
    /* background-color: ${({ theme }) => theme.colors.secondary}; */
    /* display: none; */
    @media (max-width: ${({ theme }) => theme.sizes.mdViewWidth}) {
        display: ${({ listViewFocus }) => listViewFocus ? "none" : "block"};
        flex: 1;
    }
`;
