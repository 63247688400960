import styled, { withTheme } from "styled-components";
import { useState } from "react";

import { ContentBox } from "../../resources/styles";
import WithPopUp from "../../library/hoc/WithPopUp";
import Avatar from "../../library/common/Avatar";
import Button from "../../library/common/Button";
import ButtonCircleIcon from "../../library/common/ButtonCircleIcon";
import HeaderBar from "../../library/common/PopupHeaderBar";
import FooterBar from "../../library/common/PopupFooterBar";
import EditButton from "../../library/common/ButtonEditCircle";
import InputGroup from "../../library/common/InputGroup";
import { INPUT_TYPES, CHAT_POPUP_TYPES } from "../../library/common/constants";
import { handleTextChange } from "../../library/utilities";
import PopupLoadingScreen from "../../library/common/PopupLoadingScreen";
import Icon from "../../library/common/Icon";

const maxUsersInList = 5;
const avatarSize = "2.5rem";

const ChatInfoPopupView = ({
    theme,
    chatDetails,
    sortedChatMembers,
    setPopUpToDisplay,
    handleUpdateName,
    popupLoading,
    navigateToUser,
}) => {
    // chatDetails.name = "Test name of chat";

    const [formState, setFormState] = useState({
        name: chatDetails.name || "",
    });

    const [showNameInput, setShowNameInput] = useState(false);

    // Handle text input changes
    const handleChange = (e) => {
        handleTextChange(e, setFormState, formState);
    };

    const handleEditNameView = () => {
        setShowNameInput(true);
    };

    if (popupLoading) {
        return <PopupLoadingScreen maxHeight={"500px"} maxWidth={"600px"} />;
    } else {

        const MembersToRender = sortedChatMembers.slice(0, maxUsersInList)
            .map((el, i) => (
                <Row key={i}>
                    <Member onClick={() => { navigateToUser(el.userId); }}>
                        <Avatar
                            path={el.img}
                            alt={"Error"}
                            user={true}
                            size={avatarSize}
                            borderColor={theme.colors.offPrimary}
                            showPointer={true}
                        />
                        <NameStatusCol>
                            <NameRow>
                                <Name>{el.username}</Name>
                            </NameRow>
                            {el.isAdmin && <AdminStatus>Admin</AdminStatus>}
                        </NameStatusCol>
                    </Member>
                    {el.isCurrentUser && !el.isAdmin && ( // show leave button for current user if their not a group admin
                        <LeaveBtnWrapper>
                            <Button
                                icon={<Icon name="sign-out-alt" />
                                    // <i className="fas fa-sign-out-alt"></i>
                                }
                                handleClick={(e) => {
                                    e.stopPropagation(); // stop propagating to user click event
                                    setPopUpToDisplay(
                                        CHAT_POPUP_TYPES.leaveGroup
                                    );
                                }}
                                secondaryBtnColors={true}
                                noShadow={true}
                            >
                                Leave
                            </Button>
                        </LeaveBtnWrapper>
                    )}
                </Row>
            ));

        const ChatNameSectionContent = () => {
            if (!showNameInput) {
                // if not in name input mode
                if (chatDetails.name) {
                    // If chat has a name
                    return (
                        <SectionContent>
                            <ChatNameRow>
                                <ChatName>{chatDetails.name}</ChatName>
                                {chatDetails.currentUserIsChatAdmin && ( // only show edit button if current user is admin
                                    <EditBtnWrapper>
                                        <EditButton
                                            handleClick={handleEditNameView}
                                            invDefaultColors={true}
                                            circleDiameter={"2rem"}
                                            fontSize={"1rem"}
                                        />
                                    </EditBtnWrapper>
                                )}
                            </ChatNameRow>
                        </SectionContent>
                    );
                } else {
                    // If chat doesn't have a name
                    return (
                        <SectionContent>
                            {chatDetails.currentUserIsChatAdmin && ( // if chat admin show add button to add name
                                <ChatNameRow>
                                    <AddBtnWrapper>
                                        <Button
                                            icon={<Icon name="plus-circle" />
                                                // <i className="fas fa-plus-circle"></i>
                                            }
                                            handleClick={handleEditNameView}
                                            secondaryBtnColors={true}
                                            noShadow={true}
                                        >
                                            Add
                                        </Button>
                                    </AddBtnWrapper>
                                </ChatNameRow>
                            )}
                            {!chatDetails.currentUserIsChatAdmin && ( // if not admin display no name message
                                <ChatNameRow>
                                    <NoNameMsg>No chat name yet</NoNameMsg>
                                </ChatNameRow>
                            )}
                        </SectionContent>
                    );
                }
            } else {
                // If in name input mode
                return (
                    <SectionContent>
                        <InputGroup
                            type={INPUT_TYPES.TEXT}
                            handleChange={handleChange}
                            value={formState.name}
                            name={"name"}
                            small={
                                "Changing the name of a group chat changes it for everyone."
                            }
                        />
                    </SectionContent>
                );
            }
        };

        return (
            <Wrapper>
                <HeaderBar popupTitle={"Group Chat"} setPopUpToDisplay={setPopUpToDisplay} />
                <ContentBox>
                    <ContentWrapper>
                        <ChatNameSection>
                            <SectionTitle>Chat Name:</SectionTitle>
                            {ChatNameSectionContent()}
                        </ChatNameSection>
                        <MembersSection>
                            <SectionTitle>Chat Members:</SectionTitle>
                            <SectionContent>
                                {MembersToRender}
                                {chatDetails.members.length >
                                    maxUsersInList && (
                                        <Row>
                                            <Member
                                                onClick={() =>
                                                    setPopUpToDisplay(
                                                        CHAT_POPUP_TYPES.membersInfo
                                                    )
                                                }
                                            >
                                                <ButtonCircleIcon
                                                    circleDiameter={avatarSize}
                                                    fontSize={"1.2rem"}
                                                    bgColor={
                                                        theme.colors.offSecondary
                                                    }
                                                    iconColor={theme.colors.primary}
                                                >
                                                    <MoreUsers>
                                                        +
                                                        {chatDetails.members
                                                            .length -
                                                            maxUsersInList}
                                                    </MoreUsers>
                                                </ButtonCircleIcon>
                                                <NameStatusCol>
                                                    <Name>See all</Name>
                                                </NameStatusCol>
                                            </Member>
                                        </Row>
                                    )}
                            </SectionContent>
                        </MembersSection>
                    </ContentWrapper>
                </ContentBox>
                {!showNameInput && (
                    <FooterBar
                        handleDone={() => setPopUpToDisplay("")}
                        doneLabel={"Done"}
                    />
                )}
                {showNameInput && (
                    <FooterBar
                        handleCancel={() => setPopUpToDisplay("")}
                        cancelLabel={"Cancel"}
                        handleDone={() => {
                            handleUpdateName(formState.name);
                        }}
                        doneLabel={"Save"}
                    />
                )}
            </Wrapper>
        );
    }
};

export default WithPopUp(withTheme(ChatInfoPopupView));

const Wrapper = styled.div`
    width: min(600px, 95vw);
`;

const ContentWrapper = styled.div`
    padding: 1.5rem 2rem;
    @media (max-width: ${({ theme }) => theme.sizes.mdViewWidth}) {
        padding: 1rem;
    }
`;

const ChatNameSection = styled.div`
    padding-bottom: 1.5rem;
`;

const MembersSection = styled.div``;

const SectionTitle = styled.h5`
    margin: 0;
    padding-bottom: 0.5rem;
`;

const SectionContent = styled.div`
    padding-left: 0.3rem;
`;

const ChatNameRow = styled.div`
    display: flex;
    align-items: center;
    min-width: 0;
`;

const ChatName = styled.h6`
    margin: 0;
    /* Will cut off any words without white space breaks that excide available width. Names with white space will be wrapped onto new line */
    text-overflow: ellipsis;
    overflow: hidden;
`;

const NoNameMsg = styled(ChatName)`
    color: ${(props) => props.theme.colors.justOffSecondary};
    font-size: 90%;
`;

const EditBtnWrapper = styled.div`
    padding-left: 0.5rem;
`;

const AddBtnWrapper = styled.div`
    max-width: 200px;
`;

const LeaveBtnWrapper = styled(AddBtnWrapper)`
    padding-left: 3rem;
    @media (max-width: ${({ theme }) => theme.sizes.xsViewWidth}) {
        padding-left: 1rem;
    }
`;

const Row = styled.div`
    display: flex;
    align-items: center;
    min-width: 0;
`;

const Member = styled.div`
    display: flex;
    align-items: center;
    padding: 0 0 0.25rem 0;
    cursor: pointer;
    min-width: 0;
`;

const NameStatusCol = styled.div`
    padding-left: 0.5rem;
    flex: 1;
    min-width: 0;
`;

const NameRow = styled.div`
    display: flex;
    align-items: center;
    min-width: 0;
`;

const Name = styled.h6`
    margin: 0;
    /* Will cut off any words without white space breaks that excide available width. Names with white space will be wrapped onto new line */
    text-overflow: ellipsis;
    overflow: hidden;
`;

const AdminStatus = styled.div`
    font-size: 80%;
    /* font-weight: 300; */
    color: ${(props) => props.theme.colors.offSecondary};
`;

const MoreUsers = styled.h5`
    margin: 0;
    padding-right: 0.1rem;
`;
