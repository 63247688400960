import { useEffect, useState } from "react";
import ImageGridView from "./ImageGrid-view";
import { useApi } from "../../../services/api";

const ImageGrid = ({
    history,
    addError,
    viewRoute,
}) => {
    const { data, isLoading, refresh } = useApi({
        method: "post",
        url: viewRoute,
        errorCallback: addError,
    });

    const [canLoadMore, setCanLoadMore] = useState(false);
    const [imgGridData, setImgGridData] = useState([]);

    useEffect(() => {
        // update imgGridData && canLoadMore
        if (data) {
            setImgGridData((f) => [...f, ...data]);
            setCanLoadMore(data.length > 0);
        }
    }, [data]);

    const handleClick = (img) => {
        history.push(`/posts/${img.postId}/image/${img.slideIndex}`);
    };

    const handleLoadMore = () => {
        if (!isLoading && canLoadMore) {
            // Stops refresh firing due to scroll event before data has been loaded AND if no more items to load
            refresh({
                method: "post",
                url: viewRoute,
                data: {
                    latestDate: data[data.length - 1].createdAt,
                },
            });
        }
    };

    return (
        <ImageGridView
            imgGridData={imgGridData}
            isLoading={isLoading}
            handleClick={handleClick}
            handleLoadMore={handleLoadMore}
            canLoadMore={canLoadMore}
        />
    );
};

export default ImageGrid;
