import styled, { withTheme } from "styled-components";

import AppIcon from "../../library/common/AppIcon";
import Avatar from "../../library/common/Avatar";
import Button from "../../library/common/Button";
import ButtonCircleIcon from "../../library/common/ButtonCircleIcon";
import Icon from "../../library/common/Icon";

import { formatDateTimeAgo, useUpdateTime } from "../../library/utilities";

// *** These must exactly match the NOTIFICATION_TYPE in server! ***
const NOTIFICATION_TYPE = {
    WELCOME_TO_COSQOS: "WELCOME TO COSQOS",
    INCOMING_FRIEND_REQUEST: "INCOMING FRIEND REQUEST",
    FRIEND_REQUEST_ACCEPTED: "FRIEND REQUEST ACCEPTED",
    SITECONX_REQUEST_ACCEPTED: "SITECONX REQUEST ACCEPTED",
    POST_LIKED: "POST LIKED",
    POST_COMMENTED: "POST COMMENTED",
    POST_HAS_NEW_TAG: "POST HAS NEW TAG", // NOTE: uses actionedBy array to track newConx
    TAGGED_IN_POST: "TAGGED IN POST",
    // for company admins:
    COMPANY_POST_LIKED: "COMPANY POST LIKED",
    COMPANY_POST_COMMENTED: "COMPANY POST COMMENTED",
    COMPANY_POST_HAS_NEW_TAG: "COMPANY POST HAS NEW TAG",
    COMPANY_TAGGED_IN_POST: "COMPANY TAGGED IN POST",
    // for site admins:
    INCOMING_SITECONX_REQUEST: "INCOMING SITECONX REQUEST",
    SITE_HAS_NEW_CONX: "SITE HAS NEW CONX", // NOTE: uses actionedBy array to track newConx
};

const AVATAR_SIZE = "3.5rem";
// Takes a notification and users its type to render it
const NotificationItemView = ({ theme, notification, handleClick }) => {
    const {
        notificationType,
        friendRequestUserId,
        friendRequestUserDetails = {},
        friendRequestAccepted,
        siteConxRequestSiteId,
        siteConxRequestSiteDetails = {},
        actionedPostId,
        actionedByUserDetails = {},
        actionedByNumUsers = 1,
        hasBeenSeen = false,
        createdAt,
        friendRequestActions = {},
    } = notification;

    const { img: friendRequestUserImg, name: friendRequestUserName } =
        friendRequestUserDetails;

    const { img: siteConxRequestSiteImg, name: siteConxRequestSiteName } =
        siteConxRequestSiteDetails;

    const { img: actionedByUserImg, name: actionedByUserName } =
        actionedByUserDetails;

    const { acceptFriendRequest, removeFriendRequest } = friendRequestActions;

    const numOthers = actionedByNumUsers - 1;
    const andOtherUsers =
        numOthers > 0
            ? ` and ${numOthers} other${numOthers > 1 ? "s" : " person"}`
            : "";
    const andOtherUsersWere = numOthers > 0 ? `${andOtherUsers} were` : " was";

    // const [submittingAction, setSubmittingAction] = useState(false);

    // time now hook updates time now ever 30 seconds, for use in calculating notification time ago
    const timeNow = useUpdateTime(3000);

    const getNotificationProps = () => {
        switch (notificationType) {
            case NOTIFICATION_TYPE.WELCOME_TO_COSQOS:
                return {
                    isWelcome: true,
                    ntfMessageText: (
                        <NtfMessage>
                            <BoldText>Welcome to BoomsUp!</BoldText>
                        </NtfMessage>
                    ),
                };
            //     return (
            //         <Wrapper pointer={false} hasBeenSeen={hasBeenSeen}>
            //             <ContentRow>
            //                 <LogoWrapper>
            //                     <AppLogo logoColor={theme.colors.primary} />
            //                 </LogoWrapper>
            //                 <NotificationTextCol>
            //                     <NtfMessage>
            //                         <BoldText>Welcome to Crewclub!</BoldText>
            //                     </NtfMessage>
            //                     <NotificationTime hasBeenSeen={hasBeenSeen}>
            //                         {formatDateTimeAgo(createdAt, timeNow, false)}
            //                     </NotificationTime>
            //                 </NotificationTextCol>
            //             </ContentRow>
            //         </Wrapper>
            //     ); // skipping Welcome to Cosqos for now TODO resolve this with a welcome message

            case NOTIFICATION_TYPE.INCOMING_FRIEND_REQUEST:
                return {
                    onClickPath: `/users/${friendRequestUserId}`,
                    avatarPath: friendRequestUserImg,
                    avatarPathAlt: friendRequestUserName,
                    isUserAvatar: true,
                    icon: <Icon name="user-plus" />,
                    iconBgColor: theme.colors.semantic.info,
                    iconColor: theme.colors.secondary,
                    ntfMessageText: friendRequestAccepted ? (
                        <NtfMessage>
                            You and <BoldText>{friendRequestUserName}</BoldText>{" "}
                            are now friends.
                        </NtfMessage>
                    ) : (
                        <NtfMessage>
                            <BoldText>{friendRequestUserName}</BoldText> sent
                            you a friend request.
                        </NtfMessage>
                    ),
                };

            case NOTIFICATION_TYPE.FRIEND_REQUEST_ACCEPTED:
                return {
                    onClickPath: `/users/${friendRequestUserId}`,
                    avatarPath: friendRequestUserImg,
                    avatarPathAlt: friendRequestUserName,
                    isUserAvatar: true,
                    icon: <Icon name="user-check" />,
                    iconBgColor: theme.colors.semantic.success,
                    iconColor: theme.colors.secondary,
                    ntfMessageText: (
                        <NtfMessage>
                            <BoldText>{friendRequestUserName + " "}</BoldText>
                            accepted your friend request.
                        </NtfMessage>
                    ),
                };

            case NOTIFICATION_TYPE.SITECONX_REQUEST_ACCEPTED:
                return {
                    onClickPath: `/sites/${siteConxRequestSiteId}`,
                    avatarPath: siteConxRequestSiteImg,
                    avatarPathAlt: siteConxRequestSiteName,
                    isUserAvatar: true,
                    icon: <Icon name="user-check" />,
                    iconBgColor: theme.colors.semantic.success,
                    iconColor: theme.colors.secondary,
                    ntfMessageText: (
                        <NtfMessage>
                            You were added to the crew of
                            <BoldText>{" " + siteConxRequestSiteName}</BoldText>
                            .
                        </NtfMessage>
                    ),
                };

            case NOTIFICATION_TYPE.POST_LIKED:
                return {
                    onClickPath: `/posts/${actionedPostId}`,
                    avatarPath: actionedByUserImg,
                    avatarPathAlt: actionedByUserName,
                    isUserAvatar: true,
                    icon: <Icon name="thumbs-up" />,
                    iconBgColor: theme.colors.accent,
                    iconColor: theme.colors.primary,
                    ntfMessageText: (
                        <NtfMessage>
                            <BoldText>{actionedByUserName}</BoldText>
                            {andOtherUsers} liked your post.
                        </NtfMessage>
                    ),
                };

            case NOTIFICATION_TYPE.POST_COMMENTED:
                return {
                    onClickPath: `/posts/${actionedPostId}`,
                    avatarPath: actionedByUserImg,
                    avatarPathAlt: actionedByUserName,
                    isUserAvatar: true,
                    icon: <Icon name="comments" />,
                    iconBgColor: theme.colors.accent,
                    iconColor: theme.colors.primary,
                    ntfMessageText: (
                        <NtfMessage>
                            <BoldText>{actionedByUserName}</BoldText>
                            {andOtherUsers} commented on your post.
                        </NtfMessage>
                    ),
                };

            case NOTIFICATION_TYPE.POST_HAS_NEW_TAG:
                return {
                    onClickPath: `/posts/${actionedPostId}`,
                    avatarPath: actionedByUserImg,
                    avatarPathAlt: actionedByUserName,
                    isUserAvatar: true,
                    icon: <Icon name="user-tag" />,
                    iconBgColor: theme.colors.accent,
                    iconColor: theme.colors.primary,
                    ntfMessageText: (
                        <NtfMessage>
                            <BoldText>{actionedByUserName}</BoldText>
                            {andOtherUsersWere} tagged in your post.
                        </NtfMessage>
                    ),
                };

            case NOTIFICATION_TYPE.TAGGED_IN_POST:
                return {
                    onClickPath: `/posts/${actionedPostId}`,
                    avatarPath: actionedByUserImg,
                    avatarPathAlt: actionedByUserName,
                    isUserAvatar: true,
                    icon: <i className="fas fa-user-tag" />,
                    iconBgColor: theme.colors.semantic.info,
                    iconColor: theme.colors.secondary,
                    ntfMessageText: (
                        <NtfMessage>
                            <BoldText>{actionedByUserName}</BoldText> tagged you
                            in a post.
                        </NtfMessage>
                    ),
                };

            case NOTIFICATION_TYPE.COMPANY_POST_LIKED:
                return {
                    onClickPath: `/posts/${actionedPostId}`,
                    avatarPath: actionedByUserImg,
                    avatarPathAlt: actionedByUserName,
                    isUserAvatar: true,
                    icon: <i className="fas fa-thumbs-up" />,
                    iconBgColor: theme.colors.accent,
                    iconColor: theme.colors.primary,
                    ntfMessageText: (
                        <NtfMessage>
                            <BoldText>{actionedByUserName}</BoldText>
                            {andOtherUsers} liked a post from your company.
                        </NtfMessage>
                    ),
                };

            case NOTIFICATION_TYPE.COMPANY_POST_COMMENTED:
                return {
                    onClickPath: `/posts/${actionedPostId}`,
                    avatarPath: actionedByUserImg,
                    avatarPathAlt: actionedByUserName,
                    isUserAvatar: true,
                    icon: <Icon name="fa-comments" />,
                    iconBgColor: theme.colors.accent,
                    iconColor: theme.colors.primary,
                    ntfMessageText: (
                        <NtfMessage>
                            <BoldText>{actionedByUserName}</BoldText>{" "}
                            {andOtherUsers} commented on a post from your
                            company.
                        </NtfMessage>
                    ),
                };

            case NOTIFICATION_TYPE.COMPANY_POST_HAS_NEW_TAG:
                return {
                    onClickPath: `/posts/${actionedPostId}`,
                    avatarPath: actionedByUserImg,
                    avatarPathAlt: actionedByUserName,
                    isUserAvatar: true,
                    icon: <Icon name="user-tag" />,
                    iconBgColor: theme.colors.accent,
                    iconColor: theme.colors.primary,
                    ntfMessageText: (
                        <NtfMessage>
                            <BoldText>{actionedByUserName}</BoldText>
                            {andOtherUsersWere} tagged in a post from your
                            company.
                        </NtfMessage>
                    ),
                };

            case NOTIFICATION_TYPE.COMPANY_TAGGED_IN_POST:
                return {
                    onClickPath: `/posts/${actionedPostId}`,
                    avatarPath: actionedByUserImg,
                    avatarPathAlt: actionedByUserName,
                    isUserAvatar: true,
                    icon: <Icon name="user-tag" />,
                    iconBgColor: theme.colors.semantic.info,
                    iconColor: theme.colors.secondary,
                    ntfMessageText: (
                        <NtfMessage>
                            <BoldText>{actionedByUserName}</BoldText> tagged
                            your company in a post.
                        </NtfMessage>
                    ),
                };

            case NOTIFICATION_TYPE.INCOMING_SITECONX_REQUEST:
                return {
                    onClickPath: `/sites/${siteConxRequestSiteId}/users`,
                    avatarPath: siteConxRequestSiteImg,
                    avatarPathAlt: siteConxRequestSiteName,
                    isUserAvatar: true,
                    icon: <Icon name="user-tag" />,
                    iconBgColor: theme.colors.semantic.info,
                    iconColor: theme.colors.secondary,
                    ntfMessageText: (
                        <NtfMessage>
                            <BoldText>{actionedByUserName}</BoldText>{" "}
                            {andOtherUsers} requested to join
                            <BoldText>{siteConxRequestSiteName}</BoldText>.
                            Click to respond.
                        </NtfMessage>
                    ),
                };

            case NOTIFICATION_TYPE.SITE_HAS_NEW_CONX:
                return {
                    onClickPath: `/sites/${siteConxRequestSiteId}`,
                    avatarPath: siteConxRequestSiteImg,
                    avatarPathAlt: siteConxRequestSiteName,
                    isUserAvatar: true,
                    icon: <Icon name="user-tag" />,
                    iconBgColor: theme.colors.semantic.info,
                    iconColor: theme.colors.secondary,
                    ntfMessageText: (
                        <NtfMessage>
                            {actionedByUserName} {andOtherUsersWere} added to
                            the crew of
                            <BoldText>{siteConxRequestSiteName}</BoldText>.
                        </NtfMessage>
                    ),
                };

            default:
                return;
        }
    };

    const {
        onClickPath,
        isWelcome,
        avatarPath,
        avatarPathAlt,
        isUserAvatar,
        icon,
        iconBgColor,
        iconColor,
        ntfMessageText,
    } = getNotificationProps();

    const avatar = isWelcome ? (
        <LogoWrapper>
            <AppIcon />
        </LogoWrapper>
    ) : (
        <AvatarWrapper>
            <Avatar
                path={avatarPath}
                alt={avatarPathAlt}
                size={AVATAR_SIZE}
                borderColor={theme.colors.offPrimary}
                showPointer={true} // true since the item is clickable
                user={isUserAvatar}
                company={!isUserAvatar}
            />
            <CircleIconWrapper>
                <ButtonCircleIcon
                    circleDiameter="1.6rem"
                    fontSize="0.7rem"
                    bgColor={iconBgColor}
                    iconColor={iconColor}
                >
                    {icon}
                </ButtonCircleIcon>
            </CircleIconWrapper>
        </AvatarWrapper>
    );

    const acceptButtonProps = {
        handleClick: acceptFriendRequest,
        children: "Accept",
        icon: <Icon name="check" />,
        bgColor: theme.colors.semantic.success,
        textColor: theme.colors.secondary,
        borderColor: theme.colors.offPrimary,
        fontWeight: 500,
        noShadow: true,
    };

    const removeButtonProps = {
        handleClick: removeFriendRequest,
        children: "Reject",
        icon: <Icon name="user-times" />,
        bgColor: theme.colors.offPrimary,
        textColor: theme.colors.secondary,
        borderColor: theme.colors.offPrimary,
        fontWeight: 500,
        noShadow: true,
    };

    const friendRequestButtons =
        notificationType === NOTIFICATION_TYPE.INCOMING_FRIEND_REQUEST &&
        acceptFriendRequest &&
        removeFriendRequest ? (
            <ButtonRow onClick={(e) => e.stopPropagation()}>
                <AcceptBtnWrapper>
                    <Button {...acceptButtonProps} />
                </AcceptBtnWrapper>
                <RejectBtnWrapper>
                    <Button {...removeButtonProps} />
                </RejectBtnWrapper>
            </ButtonRow>
        ) : null;

    return (
        <Wrapper
            onClick={() => {
                handleClick(onClickPath);
            }}
            pointer={true}
            hasBeenSeen={hasBeenSeen}
        >
            <ContentRow>
                <UnreadVertLine hasBeenSeen={hasBeenSeen} />
                {avatar}
                <NotificationTextCol>
                    {ntfMessageText}
                    <NotificationTime hasBeenSeen={hasBeenSeen}>
                        {formatDateTimeAgo(createdAt, timeNow, false)}
                    </NotificationTime>
                </NotificationTextCol>
            </ContentRow>
            {friendRequestButtons}
        </Wrapper>
    );
};

export default withTheme(NotificationItemView);

const Wrapper = styled.div`
    cursor: ${(props) =>
        props.pointer
            ? "pointer"
            : "cursor"}; /* Cursor pointer if handleClick is defined */
    min-height: 4.5rem;
    font-size: 1rem;
    border-radius: 1rem;
    color: ${({ theme, hasBeenSeen }) =>
        hasBeenSeen ? theme.colors.justOffSecondary : "inherit"};
    &:hover {
        background-color: ${(props) =>
            props.pointer ? props.theme.colors.justOffPrimary : "inherit"};
    }
`;

const ContentRow = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    padding: 0.5rem 0.5rem 0.5rem 0.9rem; /* Make sure this is never less than width on UnreadVertLine */
`;

const ButtonRow = styled.div`
    padding: 0.4rem 0.5rem 0.6rem 0.5rem;
    display: flex;
    justify-content: space-between;
    max-width: 350px;
`;

const UnreadVertLine = styled.div`
    position: absolute;
    z-index: 1;
    background-color: ${({ theme, hasBeenSeen }) =>
        hasBeenSeen ? "inherit" : theme.colors.accent};
    border-radius: 1rem;
    height: 75%;
    margin: auto 0; /* center vetially */
    top: 0;
    bottom: 0;
    left: 0.4rem; /* width plus left must be left than wrapper padding right*/
    width: 0.3rem;
`;

const BoldText = styled.span`
    font-weight: 500;
`;

const AvatarWrapper = styled.div`
    padding: 0.2rem 0.5rem 0.2rem 0; /* Changing this will effect CircleIconWrapper bottom and top */
    position: relative;
    flex: 0;
    align-self: flex-start; /* avatar should always be at the top of wrapper */
`;

const LogoWrapper = styled.div`
    flex: 0;
    margin-right: 0.5rem;
    padding: 0.4rem;
    min-height: calc(${AVATAR_SIZE});
    min-width: calc(${AVATAR_SIZE});
    border-radius: 0.7rem;
    background-color: ${(props) => props.theme.colors.secondary};
`;

const CircleIconWrapper = styled.div`
    position: absolute;
    z-index: 1;
    bottom: 0; /* remember padding in AvatarWrapper effects bottom and right look */
    right: 0.3rem;
`;

const NtfMessage = styled.div``;

const NotificationTextCol = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const NotificationTime = styled.div`
    font-size: 80%;
    font-weight: 500;
    color: ${(
        { theme, hasBeenSeen } // unread notifications get highlighted time
    ) => (hasBeenSeen ? theme.colors.justOffSecondary : theme.colors.accent)};
`;

const AcceptBtnWrapper = styled.div`
    padding-right: 0.5rem;
    flex: 1;
`;

const RejectBtnWrapper = styled.div`
    flex: 1;
`;
