import styled from "styled-components";

import UserList from "../../library/common/UserList";
import UserListWithCards from "../../library/common/UserList/UserListWithCards-view";
import { InfoIcon } from "../../library/common/Icon";

import {
    SectionBox,
    HeaderBox,
    BoxTitle,
    SectionContent,
    SectionBoxWrapper,
} from "../../resources/styles";

const CompanyProfilePeopleView = ({ companyId }) => {
    return (
        <Wrapper>
            <SectionBoxWrapper>
                <SectionBox>
                    <HeaderBox>
                        <BoxTitle>
                            {/* <HeaderIcon className="fas fa-info-circle"></HeaderIcon> */}
                            <InfoIcon style={{ marginRight: "1rem" }} />
                            People
                        </BoxTitle>
                    </HeaderBox>
                    <SectionContent>
                        <UserList
                            otherSearchOptions={{ currentCompany: companyId }}
                            allowConnect={true}
                            allowSearch={true}
                            searchPlaceholder={"Search Company"}
                            maxResults={8}
                            ViewComp={UserListWithCards}
                            noResultsText={"No People Found"}
                        />
                    </SectionContent>
                </SectionBox>
            </SectionBoxWrapper>
        </Wrapper>
    );
};

export default CompanyProfilePeopleView;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 5rem;
`;