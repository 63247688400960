import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import AppDownLoadSection from "./AppDownLoadSection-view";
import SharePhone from "./SharePhone-view";
import BuildPhones from "./BuildPhones-view";
import MapSection from "./MapSection-view";
import Footer from "./Footer-view";
import WaitListForm from "./WaitListForm-view";
import HeaderPhones from "./HeaderPhones-view";
import Icon from "../../library/common/Icon";
import LandingNavbar from "../LandingNavbar";

const LandingPageContainer = ({ theme }) => {
    // Use effect for the wait list powered by launchlist
    useEffect(() => {
        const script = document.createElement("script");
        // script.src = "https://getlaunchlist.com/js/widget.js";
        script.src = "https://getlaunchlist.com/js/widget-diy.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <Wrapper>
            <InnerWrapper>
                <BackgroundImageWrapper>
                    <BackgroundImage />
                    <BackgroundImageOverlay />
                    <SectionRow bgTransparent>
                        <HeaderWrapper>
                            <HeaderTitle>Building Reputations.</HeaderTitle>
                            <HeaderContentWrapper>
                                <HeaderPhones />
                                <ExplainSectionWrapper>
                                    <HeaderExplainer>
                                        A community of operators, contrators,
                                        and professionals who build.
                                    </HeaderExplainer>
                                    <WaitListForm />
                                </ExplainSectionWrapper>
                            </HeaderContentWrapper>
                            <IconRow>
                                <Icon name={"angle-double-down"} />
                            </IconRow>
                        </HeaderWrapper>
                    </SectionRow>
                </BackgroundImageWrapper>
                <SectionRow>
                    <SectionCol>
                        <BodySection padBottom padTop>
                            <SectionTitle>Post</SectionTitle>
                            <SharePhone />
                        </BodySection>
                    </SectionCol>
                </SectionRow>
                <SectionRow bgDark>
                    <SectionCol>
                        <BodySection padBottom padTop>
                            <SectionTitle textLight>Build</SectionTitle>
                            <BuildPhones />
                        </BodySection>
                    </SectionCol>
                </SectionRow>
                <SectionRow>
                    <SectionCol>
                        <BodySection padBottom padTop>
                            <SectionTitle>Connect</SectionTitle>
                            <MapSection />
                        </BodySection>
                    </SectionCol>
                </SectionRow>
                <SectionRow bgGrey>
                    <SectionCol>
                        <BodySection>
                            <AppDownLoadSection />
                        </BodySection>
                    </SectionCol>
                </SectionRow>

                <SectionRow bgDark>
                    <SectionCol>
                        <Footer />
                    </SectionCol>
                </SectionRow>
            </InnerWrapper>
        </Wrapper>
    );
};

export default LandingPageContainer;

const MdLayoutBreakPoint = "1200px";
const SmLayoutBreakPoint = "900px";
const XsLayoutBreakPoint = "650px";

const Wrapper = styled.div`
    width: 100vw;
    position: relative;
    z-index: 0;
    font-family: "Montserrat", sans-serif;
    /* padding: 16px; */
`;

const InnerWrapper = styled.div``;

// -----------------------------------------------
// SectionRow ---------------------------------------
// -----------------------------------------------
const SectionRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    /* background color can change depending on bg#### input - default primary */
    background-color: ${({
        theme: { colors },
        bgDark,
        bgGrey,
        bgTransparent,
    }) =>
        bgDark
            ? colors.secondary
            : bgGrey
            ? colors.justOffPrimary
            : bgTransparent
            ? `${colors.primary}01`
            : colors.primary};
`;

const SectionCol = styled.div`
    flex: 0 1 900px;
    margin: 0 50px;
    display: flex;
    flex-direction: column;
    padding: ${(props) => props.padding || "0px"};
    @media (max-width: ${SmLayoutBreakPoint}) {
        margin: 0;
    }
`;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: calc(95vh - ${({ theme }) => theme.sizes.navHeight});
    /* padding-bottom: 40px; */
`;

const accentGradientTextCss = css`
    background-color: ${({ theme }) => theme.colors.lightAccent};
    background-image: linear-gradient(
        45deg,
        ${({ theme }) => theme.colors.accentGreen},
        ${({ theme }) => theme.colors.lightAccent}
    );
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
`;

const HeaderTitle = styled.div`
    font-size: 80px;
    text-align: center;
    font-weight: 700;
    padding: 60px 0;
    ${accentGradientTextCss}
    filter: drop-shadow(3px 3px 2px #00000050);
    letter-spacing: -1px;
    @media (max-width: ${MdLayoutBreakPoint}) {
        font-size: 70px;
    }
    @media (max-width: ${SmLayoutBreakPoint}) {
        font-size: 60px;
        text-align: left;
    }
    @media (max-width: ${XsLayoutBreakPoint}) {
        font-size: 45px;
        padding: 35px 20px;
    }
`;

const HeaderContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: ${SmLayoutBreakPoint}) {
        flex-direction: column;
        /* align-items: flex-start; */
        /* padding-left: 20px; */
    }
`;

const IconRow = styled.div`
    padding-top: 120px;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 30px;
    color: ${(props) => props.theme.colors.lightAccent};
`;

const ExplainSectionWrapper = styled.div`
    max-width: 550px;
    padding-left: 70px;
    display: flex;
    flex-direction: column;
    @media (max-width: ${MdLayoutBreakPoint}) {
        padding-left: 30px;
    }
    @media (max-width: ${SmLayoutBreakPoint}) {
        padding-left: 0;
        padding-top: 20px;
    }
    @media (max-width: ${XsLayoutBreakPoint}) {
        max-width: 350px;
    }
`;

const HeaderExplainer = styled.div`
    font-size: 22px;
    color: ${(props) => props.theme.colors.primary};
    letter-spacing: 1px;
    /* text-align: justify; */
    padding-bottom: 20px;
    @media (max-width: ${SmLayoutBreakPoint}) {
        font-size: 22px;
    }
    @media (max-width: ${XsLayoutBreakPoint}) {
        font-size: 13px;
    }
`;

const BackgroundImageWrapper = styled.div`
    position: relative;
`;

const BackgroundImage = styled.div`
    /* background-image: url("https://cosqos-bucket.s3.ap-southeast-2.amazonaws.com/Drill+Rig.jpg"); */
    /* background-image: url("https://cosqos-bucket.s3.ap-southeast-2.amazonaws.com/LandingPagePics-30.jpg"); */
    /* background-image: url("https://cosqos-bucket.s3.ap-southeast-2.amazonaws.com/LandingPagePics-41.jpg"); */
    background-image: url("https://cosqos-bucket.s3.ap-southeast-2.amazonaws.com/LandingPagePics-51.jpg");
    /* background-image: url("https://cosqos-bucket.s3.ap-southeast-2.amazonaws.com/LandingPagePics-61.jpg"); */
    background-position: center; /* Center the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    position: absolute;
    z-index: 0;
    top: -${(props) => props.theme.sizes.navHeight};
    bottom: 0px;
    left: 0px;
    right: 0px;
    @media (max-width: ${SmLayoutBreakPoint}) {
        top: -100px;
        bottom: -150px;
        left: -20px;
        right: 0;
    }
    /* filter: blur(3px); */
`;

// const overlayHex = "#45454599";

const BackgroundImageOverlay = styled.div`
    background-color: ${(props) => props.theme.colors.secondary}70;
    position: absolute;
    z-index: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* backdrop-filter: blur(4px); */
`;

// -----------------------------------------------
// Phone Sections --------------------------------
// -----------------------------------------------

const BodySection = styled.div`
    padding: ${({ padTop }) => (padTop ? 60 : 0)}px 0
        ${({ padBottom }) => (padBottom ? 60 : 0)}px 0;
    @media (max-width: ${SmLayoutBreakPoint}) {
        padding: ${({ padTop }) => (padTop ? 30 : 0)}px 0
            ${({ padBottom }) => (padBottom ? 30 : 0)}px 0;
    }
`;

const SectionTitle = styled.div`
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 50px;
    padding-bottom: 60px;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    letter-spacing: 5px;
    color: ${({
        theme: {
            colors: { primary, secondary },
        },
        textLight,
    }) => (textLight ? primary : secondary)};
    @media (max-width: ${SmLayoutBreakPoint}) {
        font-size: 40px;
        padding-bottom: 30px;
        letter-spacing: 5px;
    }
`;
