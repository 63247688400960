import styled from "styled-components";

import Notifications from "../Notifications";
import Button from "../../library/common/Button";

const DropdownNotificationsView = ({ history }) => (
    <Wrapper>
        <TitleRow>
            <Heading>Notifications</Heading>
            <AllBtnWrapper>
                <Button
                    handleClick={() => { history.push("/notifications"); }}
                    textSize={"1rem"}
                    secondaryBtnColors={true}
                    icon={<i className="fas fa-expand"></i>}
                    borderRadius={"1rem"}
                    noShadow={true}
                >
                    <b>All</b>
                </Button>
            </AllBtnWrapper>
        </TitleRow>
        <Notifications listOnly={true} />
    </Wrapper>
);

export default DropdownNotificationsView;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0.7rem;
`;

const TitleRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 1rem 0;
`;

const Heading = styled.h3`
    margin: 0;
    color: ${(props) => props.theme.colors.darkAccent};
`;

const AllBtnWrapper = styled.div`
    flex: 0 1 90px;
`;
