import styled from "styled-components";

import { handleCompanyClick } from "../../library/utilities";
import { REACT_APP_DEFAULT_COMPANY_COVER } from "../../library/common/constants";
import ImgTag from "../../library/common/ImgTagWithLoading";
import Avatar from "../../library/common/Avatar";
import ServicesList from "../../library/common/ServicesList";
// import AvatarLineUp from "../../library/common/AvatarLineUp";
import { SectionBox, HeaderBox, SectionContent } from "../../resources/styles";
import Icon from "../../library/common/Icon";
const logoSize = "162px";
const avatarLetterSize = "80px"; // must be smaller than logo size

const SiteInfoBarView = ({
    theme,
    infoBarContent,
    history,
    critScSize,
    toggleView,
    expandedWindow,
}) => {
    console.log("infoBarContent");
    console.log(infoBarContent);

    const {
        _id,
        name,
        coverPhoto = REACT_APP_DEFAULT_COMPANY_COVER,
        logo,
        quickDescription,
        officeLocations = {},
        services = [],
    } = infoBarContent;

    const { address } = officeLocations;

    const handleClick = (e) => { handleCompanyClick(e, history, _id); };

    return (
        <Wrapper>
            <Header>
                <SmScUpArrowRow critScSize={critScSize}>
                    <SmScCompanyNamePreview onClick={handleClick}>
                        {name}
                    </SmScCompanyNamePreview>
                    <ArrowBtn
                        // className={
                        //     !!expandedWindow
                        //         ? "fas fa-chevron-circle-down"
                        //         : "fas fa-chevron-circle-up"
                        // }
                        onClick={toggleView}
                        critScSize={critScSize}
                    >
                        <Icon name={expandedWindow ? "chevron-circle-down" : "chevron-circle-up"} />
                    </ArrowBtn>
                </SmScUpArrowRow>
                <CoverOuterWrapper>
                    <CoverInnerWrapper>
                        <ImgTag
                            src={coverPhoto}
                            objectFit={"cover"}
                        />
                    </CoverInnerWrapper>
                </CoverOuterWrapper>
                <DisplayPicRow height={logoSize}>
                    <DisplayPicOuterWrapper>
                        <DisplayPicInner>
                            <Avatar
                                path={logo}
                                letterAsAltImg={{
                                    altLetter: name[0],
                                    fontSize: avatarLetterSize,
                                    bgColor: theme.colors.primary,
                                    textColor: theme.colors.secondary,
                                }}
                                size={logoSize}
                                borderColor={theme.colors.body}
                                borderWidth={"5px"}
                                company={true}
                            />
                        </DisplayPicInner>
                    </DisplayPicOuterWrapper>
                </DisplayPicRow>
                <CompanyNameWrapper critScSize={critScSize}>
                    <CompanyName onClick={handleClick}>
                        {name}
                    </CompanyName>
                </CompanyNameWrapper>
                {quickDescription && <BioSection>{quickDescription}</BioSection>}
            </Header>
            <Body>
                {address && <CompanyAddress>{address}</CompanyAddress>}
                {services.length > 0 && (
                    <SectionBox>
                        <ModifiedHeaderBox>
                            <BoxTitle>
                                <HeaderIcon><Icon name="info-circle" /></HeaderIcon>
                                Products & Services
                            </BoxTitle>
                        </ModifiedHeaderBox>
                        <SectionContent>
                            <ServicesList
                                services={services}
                                fontSize={"1rem"}
                                vertSpace={"0.3rem"}
                                limit={4}
                            />
                        </SectionContent>
                    </SectionBox>
                )}
            </Body>
        </Wrapper>
    );
};

export default SiteInfoBarView;

const Wrapper = styled.div`
    background-color: ${(props) => props.theme.colors.body};
    position: relative;
    height: 100%;
`;

const Header = styled.div`
    padding: 0.5rem 0.5rem 1rem 0.5rem;
    border-radius: 0 0 1.5rem 1.5rem;
    background-color: ${(props) => props.theme.colors.primary};
`;

const Body = styled.div`
    padding: 0rem 0.5rem 0.5rem 0.5rem;
`;

const arrowBtnSize = "2.6rem";
const arrowBtnSpaceRight = "0.7rem";

const SmScUpArrowRow = styled.div`
    display: none;
    min-height: ${arrowBtnSize};
    margin-bottom: ${arrowBtnSpaceRight};
    @media (max-width: ${({ critScSize }) => critScSize}) {
        display: flex;
        align-items: center;
        position: relative;
        padding-right: calc(
            ${arrowBtnSize} + 2 * ${arrowBtnSpaceRight}
        ); /* account for arrow btn plus spacing left and right of it */
    }
`;

const CompanyNameWrapper = styled.div`
    display: block;
    padding-top: 0.5rem;
`;

const SmScCompanyNamePreview = styled.div`
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: 500;
    padding-left: 0.7rem;
    color: ${(props) => props.theme.colors.justOffSecondary};
    /* cut off words without white space that exceed width, wrap white space */
    text-overflow: ellipsis;
    overflow: hidden;
`;

const CompanyName = styled.h3`
    margin: 0;
    cursor: pointer;
    text-align: center;
    /* cut off words without white space that exceed width, wrap white space*/
    text-overflow: ellipsis;
    overflow: hidden;
`;

const ArrowBtn = styled.i`
    position: absolute;
    right: 0;
    z-index: 1;
    display: none;
    @media (max-width: ${({ critScSize }) => critScSize}) {
        display: block;
        flex: 0;
        font-size: ${arrowBtnSize};
        padding: 0 0.7rem 0 0;
        color: ${(props) => props.theme.colors.offSecondary};
    }
`;

const CoverOuterWrapper = styled.div`
    position: relative;
    width: 100%;
    padding-top: ${(props) =>
        props.theme.sizes.aspectRatios.coverPhoto.xsView}%;
`;

const CoverInnerWrapper = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    overflow: hidden;
`;

const logoHeightBelowCover = 40; /* Units is percent - but need as number in definition */

const DisplayPicRow = styled.div`
    position: relative;
    height: calc(${logoSize} * ${logoHeightBelowCover / 100});
`;

const DisplayPicOuterWrapper = styled.div`
    position: absolute;
    z-index: 1;
    bottom: 100%;
    width: ${logoSize};
    margin-left: calc(-${logoSize} / 2); /* half thw width */
    left: 50%;
`;

const DisplayPicInner = styled.div`
    margin-bottom: -${logoHeightBelowCover}%;
`;

const BioSection = styled.h6`
    margin: 0;
    padding: 0.2rem 0.5rem 0 0.5rem;
    text-align: center;
`;

const CompanyAddress = styled.div`
    text-align: center;
    font-style: italic;
    font-size: 0.8rem;
    padding: 1rem 0.5rem 1rem 0.5rem;
`;

const HeaderIcon = styled.i`
    margin-right: 1rem;
    font-size: 1.3rem;
`;

const BoxTitle = styled.div`
    font-size: 1.1rem;
    color: ${({ theme }) => theme.colors.primary};
`;

const ModifiedHeaderBox = styled(HeaderBox)`
    padding: 0.7rem 1.5rem;
    @media (max-width: ${({ theme }) => theme.sizes.smViewWidth}) {
        padding: 0.7rem 1rem;
    }
`;
