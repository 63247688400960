import { connect } from "react-redux";
import styled from "styled-components";
import UserList from "../../library/common/UserList";

const mapStateToProps = (state) => ({ currentUser: state.currentUser });

const MyContacts = ({ currentUser }) => (
    <OuterWrapper>
        <InnerWrapper>
            <ListTitleWrapper>
                <ListTitle>Contacts</ListTitle>
            </ListTitleWrapper>
            <UserList
                otherSearchOptions={{ connectionsOnly: currentUser.user.id }}
                maxResults={40}
                allowSearch={true}
                searchPlaceholder={"Search Contacts"}
                noResultsText={"No Contacts Found."}
            />
        </InnerWrapper>
    </OuterWrapper>
);

export default connect(mapStateToProps)(MyContacts);

const OuterWrapper = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    background-color: ${(props) => props.theme.colors.primary};
    min-height: calc(100vh - ${(props) => props.theme.sizes.navHeight});
    padding: 1.5rem 0.5rem 1rem 0.5rem;
    @media (max-width: ${({ theme }) => theme.sizes.smViewWidth}) {
        padding: 1rem 0.5rem;
    }
`;

const InnerWrapper = styled.div`
    width: 100%;
    max-width: 700px;
    position: relative;
`;

const ListTitleWrapper = styled.div`
    width: 100%;
    text-align: center;
`;

const ListTitle = styled.h2`
    padding-bottom: 1rem;
    margin: 0;
    color: ${(props) => props.theme.colors.darkAccent};
`;
