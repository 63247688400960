import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import EditAccounts from "./EditAccountsScreen-container";
import WithPopUp from "../../../hoc/WithPopUp";
import WithRemoveErrorOnUnmount from "../../../hoc/WithRemoveErrorOnUnmount";

const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
    errors: state.errors,
});

export default connect(mapStateToProps)(
    WithPopUp(withRouter(WithRemoveErrorOnUnmount(EditAccounts)))
);
