import { connect } from "react-redux";

import EditUsersAndCompanies from "./AddOrRemove-container";
import WithPopUp from "../../../hoc/WithPopUp";
import WithRemoveErrorOnUnmount from "../../../hoc/WithRemoveErrorOnUnmount";
import { withRouter } from "react-router-dom";

function mapState(state) {
    return {
        currentUser: state.currentUser,
        errors: state.errors,
    };
}

export default connect(mapState)(
    WithPopUp(withRouter(WithRemoveErrorOnUnmount(EditUsersAndCompanies)))
);
