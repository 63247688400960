import AboutPage from "./SiteProfileAboutUs-view";
import SiteProfileHeader from "./SiteProfileHeader-view";
import CrewPage from "./SiteProfileCrewPage-view";

import Alert from "../../library/common/Alert";
import ImageGrid from "../../library/common/ImageGrid";
import TabNavbar from "../../library/common/TabNavbar";

import { ALERT_TYPES, SITE_PAGES } from "../../library/common/constants";

import {
    WidthWrapper,
    HeaderSection,
    ContentWrapper,
} from "../../resources/styles/index";

// View component render company profile
const SiteProfileView = ({
    errors,
    removeError,
    siteDetails,
    setPopUpToDisplay,
    pageToDisplay,
    openFullScreenImgView,
    licencesLoading,
    conxActions,
    buttonLoading,
    currentUserCrewStatus,
    createSiteView,
    loading,
}) => {
    const ErrorAlert = errors.message ? (
        <Alert
            message={errors.message}
            type={ALERT_TYPES.ERROR}
            removeError={removeError}
            noBorderRadius={true}
            hideBorder={true}
        />
    ) : (
        <div></div>
    );

    return (
        <div>
            {ErrorAlert}
            <HeaderSection>
                <WidthWrapper>
                    <SiteProfileHeader
                        setPopUpToDisplay={setPopUpToDisplay}
                        openFullScreenImgView={openFullScreenImgView}
                        siteDetails={siteDetails}
                        conxActions={conxActions}
                        buttonLoading={buttonLoading}
                        currentUserCrewStatus={currentUserCrewStatus}
                        createSiteView={createSiteView}
                        loading={loading}
                    />
                    <TabNavbar
                        pageToDisplay={pageToDisplay}
                        commonUrl={`/sites/${siteDetails._id}`}
                        pages={Object.values(SITE_PAGES)}
                        loading={loading}
                    />
                </WidthWrapper>
            </HeaderSection>
            {!loading && Object.keys(siteDetails).length > 0 && (
                <ContentWrapper>
                    <WidthWrapper>
                        {pageToDisplay === SITE_PAGES.ABOUT.name && (
                            <AboutPage
                                siteDetails={siteDetails}
                                licencesLoading={licencesLoading}
                                setPopUpToDisplay={setPopUpToDisplay}
                                createSiteView={createSiteView}
                            />
                        )}
                        {pageToDisplay === SITE_PAGES.GRID.name && (
                            <ImageGrid
                                viewRoute={`/api/sites/${siteDetails._id}/grid`}
                            />
                        )}
                        {pageToDisplay === SITE_PAGES.CREW.name && (
                            <CrewPage
                                siteDetails={siteDetails}
                                licencesLoading={licencesLoading}
                            />
                        )}
                    </WidthWrapper>
                </ContentWrapper>
            )}
        </div>
    );
};

export default SiteProfileView;
