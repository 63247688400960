import styled from "styled-components";

const LetterBoxView = ({
    letters,
    ...props
}) => {
    // *** Props ***
    // theme =>  index (or if in infoWindow on map must be passed through)
    // letters =>  required
    // bgColor =>  optional, default off primary
    // textColor =>  optional, default primary
    // fontSize =>  optional, default 5rem
    // fontWeight =>  optional, default bold
    // borderRadius =>  optional, default 0

    return (
        <Box {...props}>
            <Letters
            // {...props}
            >{letters}</Letters>
        </Box>
    );
};

export default LetterBoxView;

const Box = styled.div`
    height: 100%;
    width: 100%;
    border-radius: ${(props) => props.borderRadius || "0"};
    background-color: ${(props) =>
        props.bgColor || props.theme.colors.offPrimary};
    color: ${(props) => props.textColor || props.theme.colors.primary};
    font-size: ${(props) => props.fontSize || "5rem"};
    font-weight: ${(props) => props.fontWeight || "bold"};
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Letters = styled.h1`
    
`;
