import styled, { withTheme } from "styled-components";
import { useRef, useState } from "react";
import ButtonTrashCircle from "./ButtonTrashCircle";
import ImgTag from "./ImgTagWithLoading";

import withImgFullScreenClick from "../hoc/withImgFullScreenClick";

const ImageSlider = ({
    theme,
    openFullScreenImgView,
    imgArray,
    DisplayImgIndex = 0, // required, 0 if its an array of length 1
    fullScreenOnClick, // optional
    handleDelete, // optional delete image button
    setDisplayImgIndex, //required if handleDelete
}) => {
    const sliderRef = useRef(null);

    // takes the direction argument (-1 or 1) and shifts the slider in that direction
    const handleArrowClick = (direction) => {
        const newSlideIndex =
            (DisplayImgIndex + direction + imgArray.length) % imgArray.length;
        setDisplayImgIndex(newSlideIndex);
    };

    const [sliderHeight, setSliderHeight] = useState(undefined);

    // Calculates the aspect ratio to use for a posts images based of the first image size in imgArray.
    // Function is called in ImgTag when image mounts in Dom,
    const handleImageLoad = () => {
        let sizeImage = new Image();
        sizeImage.src = imgArray[0];
        const sizeImageAspectRatio = sizeImage.height / sizeImage.width;
        const aspectRatios = theme.sizes.aspectRatios.feedImages;
        const portraitImgCutOff = (aspectRatios.portrait / 100) * 0.9;
        const landscapeImgCutOff = (aspectRatios.landscape / 100) * 1.1;
        if (sizeImageAspectRatio >= portraitImgCutOff) {
            if (window.innerWidth < 768) {
                // Only show portrait view aspect ratio if on mobile size screen
                // NOTE Downside to using JS to calc width is it won't update as screen resizes but not to worried about that one
                setSliderHeight(aspectRatios.portrait);
            } else {
                setSliderHeight(aspectRatios.square);
            }
        } else if (
            landscapeImgCutOff < sizeImageAspectRatio &&
            sizeImageAspectRatio < portraitImgCutOff
        ) {
            setSliderHeight(aspectRatios.square);
        } else {
            setSliderHeight(aspectRatios.landscape);
        }
    };

    const ImagesToRender = imgArray.map((img, i) => {
        return (
            <ImgInnerWrapper key={i} show={i === DisplayImgIndex}>
                <ImgTag
                    src={img}
                    imgLoadCallback={handleImageLoad}
                    objectFit={"cover"}
                />
            </ImgInnerWrapper>
        );
    });

    const handleImgClick = (e) => {
        if (fullScreenOnClick) {
            e.stopPropagation();
            openFullScreenImgView(imgArray, DisplayImgIndex);
        }
    };

    const firstImgInArr = DisplayImgIndex === 0;
    const lastImgInArr = DisplayImgIndex === imgArray.length - 1;

    return (
        <ImgSlider
            ref={sliderRef}
            onClick={handleImgClick}
            pointer={fullScreenOnClick}
        >
            <ImgSliderContent>
                <ImgOuterWrapper
                    imgHeight={
                        // Show landscape ratio while image is mounting then use proper height
                        sliderHeight ||
                        theme.sizes.aspectRatios.feedImages.landscape
                    }
                >
                    {ImagesToRender}
                </ImgOuterWrapper>
                {imgArray.length > 1 && ( // left and right arrows
                    <ArrowBox positionInImgArray={DisplayImgIndex}>
                        <LeftArrowWrapper
                            onClick={(e) => {
                                e.stopPropagation(); // stop fulls screen image click event being clicked always
                                if (!firstImgInArr) {
                                    // Nothing happens by clicking if at first image in slider
                                    handleArrowClick(-1);
                                }
                            }}
                            show={!firstImgInArr}
                        >
                            <ArrowCircle pointer={!firstImgInArr}>
                                <Arrow className="fas fa-arrow-left " />
                            </ArrowCircle>
                        </LeftArrowWrapper>
                        <RightArrowWrapper
                            onClick={(e) => {
                                e.stopPropagation();
                                if (!lastImgInArr) {
                                    handleArrowClick(1);
                                }
                            }}
                            show={!lastImgInArr}
                        >
                            <ArrowCircle pointer={!lastImgInArr}>
                                <Arrow className="fas fa-arrow-right " />
                            </ArrowCircle>
                        </RightArrowWrapper>
                    </ArrowBox>
                )}
                {handleDelete && ( // optional delete button
                    <TrashButtonWrapper>
                        <ButtonTrashCircle
                            handleClick={(e) => {
                                e.stopPropagation();
                                handleDelete(DisplayImgIndex);
                                setDisplayImgIndex(0); // return slider back to first image in array
                            }}
                            circleDiameter={"2.7rem"}
                            fontSize={"1.4rem"}
                            iconColor={theme.colors.semantic.error}
                            bgColor={theme.colors.secondary}
                        />
                    </TrashButtonWrapper>
                )}
            </ImgSliderContent>
        </ImgSlider>
    );
};

export default withImgFullScreenClick(withTheme(ImageSlider));

const ImgOuterWrapper = styled.div`
    position: relative;
    width: 100%;
    padding-top: ${(props) => props.imgHeight}%;
`;

const ImgInnerWrapper = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: ${(props) => (props.show ? "block" : "none")};
`;

const ImgSlider = styled.div`
    cursor: ${(props) => (props.pointer ? "pointer" : "auto")};
    position: relative;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
`;

const ImgSliderContent = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`;

// Fills image so arrows can be positioned within image
export const ArrowBox = styled.div`
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ArrowWrapper = styled.div`
    /* Clickable area to trigger button */
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    justify-content: center;
    align-items: center;
`;

const LeftArrowWrapper = styled(ArrowWrapper)`
    /* Don't want user being able to click image from arrow location so hide arrow with opacity and use onClick event to stop propagation */
    opacity: ${(props) => (props.show ? 1 : 0)};
    transition: opacity 0.1s linear;
    cursor: ${(props) => (props.show ? "pointer" : "default")};
`;
const RightArrowWrapper = styled(ArrowWrapper)`
    opacity: ${(props) => (props.show ? 1 : 0)};
    transition: opacity 0.1s linear;
    cursor: ${(props) => (props.show ? "pointer" : "default")};
`;

export const ArrowCircle = styled.div`
    background-color: ${(props) => props.theme.colors.primary};
    opacity: 0.7;
    border-radius: 50%;
    cursor: ${(props) =>
        props.pointer
            ? "pointer"
            : "default"}; /* Only show pointer if arrow is shown */
    height: 3rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /* Increase arrow size on hover */
    &:hover {
        opacity: 1;
        transform: scale(1.1);
    }
    transition: transform ease-in 0.1s;
`;

export const Arrow = styled.i`
    font-size: 1.7rem;
`;

// delete button wrapper
const TrashButtonWrapper = styled.div`
    position: absolute;
    z-index: 3;
    top: 0.5rem;
    right: 0.5rem;
    display: flex;
`;
