import styled from "styled-components";

import PostView from "../Post";
import PostLoading from "../Post/PostLoading-view";
import NoDetailsScreen from "../../library/common/NoDetailsView";
import {
    PostWrapper,
    // LoadingMessage
} from "../Post/Post-view";
import LoadingSpinner from "../../library/common/LoadingSpinner";
import Button from "../../library/common/Button";
import { useScrollPosition } from "../../library/utilities/ScrollPosition";
import ErrorPopup from "../../library/common/popups/ErrorPopup";
import { LoadMoreIcon } from "../../library/common/Icon";
import { NEWSFEED_ITEM_TYPE } from "../../library/common/constants";
const NewsFeedView = ({
    newsFeed,
    canLoadMore,
    handleLoadMore,
    isLoading,
    history,
    theme,
    errors,
    removeError,
    serverError,
}) => {
    const formattedNewsFeed =
        newsFeed && newsFeed.posts && newsFeed.posts.length > 0 ? (
            newsFeed.posts.map((result, i) => {
                if (result.type === NEWSFEED_ITEM_TYPE.FRIEND_SUGGESTION) {
                    return <div key={i}></div>; // Disable FRIEND_SUGGESTION on web
                } else {
                    return (
                        <PostSpacing key={i}>
                            <PostView
                                postFullView={false}
                                postData={{ ...result }}
                            />
                        </PostSpacing>
                    );
                }
            })
        ) : (
            <div></div>
        );

    useScrollPosition(
        ({ currPos }) => {
            if (
                -currPos.y + window.innerHeight >=
                document.body.scrollHeight - window.innerHeight
            ) {
                // Fires load more when user is approx one viewport height from bottom for page
                handleLoadMore();
            }
        },
        [isLoading, canLoadMore], // dependencies
        undefined,
        false,
        200 // re evaluate scroll position every 200 ms
    );

    return (
        <NewsFeedPage>
            <NewsFeedCol>
                {isLoading && newsFeed.posts.length > 0 && (
                    <LoadingCircleRow>
                        <LoadingCircle>
                            <LoadingSpinner />
                        </LoadingCircle>
                    </LoadingCircleRow>
                )}
                {formattedNewsFeed}
                {/* load more button */}
                {canLoadMore && !isLoading && (
                    // Button is a fallback for odd viewport that wont trigger load more on scroll because first load doesn't overflow screen
                    <LoadMoreRow>
                        <LoadMoreBtnWrapper>
                            <Button
                                icon={<LoadMoreIcon />}
                                handleClick={handleLoadMore}
                                secondaryBtnColors={true}
                            >
                                <b>Load</b>
                            </Button>
                        </LoadMoreBtnWrapper>
                    </LoadMoreRow>
                )}
                {/* No more post message */}
                {canLoadMore === false && !isLoading && (
                    <NoDetailsScreen
                        icon={<i className="fas fa-clock"></i>}
                        message={"That's all, folks!"}
                    />
                )}
                {/* Loading posts */}
                {isLoading && (
                    <div>
                        <PostSpacing>
                            <PostWrapper showShadow={true}>
                                <PostLoading theme={theme} image={true} />
                            </PostWrapper>
                        </PostSpacing>
                        <PostSpacing>
                            <PostWrapper>
                                <PostWrapper showShadow={true}>
                                    <PostLoading theme={theme} image={false} />
                                </PostWrapper>
                            </PostWrapper>
                        </PostSpacing>
                    </div>
                )}
            </NewsFeedCol>
            {serverError && ( // Server error popup
                <ErrorPopup
                    removeError={removeError}
                    customExitHandling={removeError}
                />
            )}
        </NewsFeedPage>
    );
};

export default NewsFeedView;

const NewsFeedPage = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
`;

const NewsFeedCol = styled.div`
    flex: 0 1 700px;
    min-width: 0; /* needed for white space no wrap */
    padding: 1rem 0;
    position: relative;
    @media (max-height: 800px) {
        flex: 0 1 600px;
    }
`;

const PostSpacing = styled.div`
    margin: 1.5rem 0;
`;

const LoadingCircleRow = styled.div`
    width: 100%;
    position: absolute;
    top: 0.75rem;
    display: flex;
    justify-content: center;
`;

const LoadMoreRow = styled.div`
    display: flex;
    justify-content: center;
    padding: 1rem 0;
`;

const LoadMoreBtnWrapper = styled.div`
    flex: 0 1 175px;
`;

const LoadingCircle = styled.div`
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.3rem;
    background-color: ${(props) => props.theme.colors.offPrimary};
    border-radius: 50%;
`;
