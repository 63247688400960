import { SET_MY_SITES, CLEAR_MY_SITES } from "../actionTypes";

export const setMySites = (data) => ({
    type: SET_MY_SITES,
    data,
});

export const clearMySites = () => ({
    type: CLEAR_MY_SITES,
});
