import { useState } from "react";

import LandingPage from "./LandingPage-view";
import UserHomepage from "./UserHomepage-view";
import { addError } from "../../main/store/actions/errors";
import { useAuth0 } from "@auth0/auth0-react";
import { SUPER_USER_POPUP_TYPES } from "../../library/common/constants";
import { sendApiRequest } from "../../services/api";

const Homepage = ({ currentUser, errors, theme }) => {
    // Used to display popup window
    const [popUpToDisplay, setPopUpToDisplay] = useState("");

    const { isAuthenticated, loginWithRedirect } = useAuth0();
    const [popupLoading, setPopupLoading] = useState(false);

    // temporary testing function
    const { getAccessTokenSilently } = useAuth0();
    const testFunction = () => {
        sendApiRequest(getAccessTokenSilently, {
            method: "post",
            url: "/api/users/temp",
        });
    };

    const [siteToDelete, setSiteToDelete] = useState({});

    const selectSiteToDelete = (site) => {
        console.log(SUPER_USER_POPUP_TYPES.hardDeleteSite);
        setSiteToDelete(site);
        setPopUpToDisplay(SUPER_USER_POPUP_TYPES.hardDeleteSite);
    };

    const handleHardDeleteSite = async () => {
        try {
            setPopupLoading(true);
            await sendApiRequest(getAccessTokenSilently, {
                method: "put",
                url: `/api/admin/sites/${siteToDelete.id}/hard_delete`,
            });
            setPopupLoading(false);
            setPopUpToDisplay("");
        } catch (error) {
            addError(error.message);
            setPopupLoading(false);
        }
    };

    return (
        <div>
            {
                // currentUser.isAuthenticated
                isAuthenticated ? (
                    <UserHomepage
                        currentUser={currentUser}
                        errors={errors}
                        setPopUpToDisplay={setPopUpToDisplay}
                        addError={addError}
                        popUpToDisplay={popUpToDisplay}
                        testFunction={testFunction}
                        theme={theme}
                        selectSiteToDelete={selectSiteToDelete}
                        siteToDelete={siteToDelete}
                        handleHardDeleteSite={handleHardDeleteSite}
                        popupLoading={popupLoading}
                    />
                ) : (
                    <LandingPage
                        errors={errors}
                        loginWithRedirect={loginWithRedirect}
                        theme={theme}
                    />
                )
            }
        </div>
    );
};

export default Homepage;
