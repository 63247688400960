import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import styled, { withTheme } from "styled-components";

import LandingPage from "../../modules/LandingPage";
import Terms from "../../modules/Terms";
import InvitedUserScreen from "../../modules/InvitedUserScreen";
import LandingNavbar from "../../modules/LandingNavbar";

const LandingPageContent = ({ theme, history }) => {
    return (
        <div>
            <LandingNavbar
                startBgColor={
                    // Landing page has special starting bgColor
                    history.location.pathname === "/"
                        ? `${theme.colors.secondary}70`
                        : `${theme.colors.secondary}`
                }
                scrollBgColor={`${theme.colors.secondary}`}
            />
            <Switch>
                <Route exact path="/terms" component={Terms} />
                <Route
                    exact
                    path="/skip_waitlist/:first_name?"
                    component={InvitedUserScreen}
                />
                <Route component={LandingPage} />
            </Switch>
        </div>
    );
};

export default withTheme(withRouter(LandingPageContent));
