import { useState, useRef } from "react";

import { UseOutsideClick } from "../../utilities";

import SingleRowView from "./SingleRow-view";
import DoubleRowView from "./DoubleRow-view";

// Search box with toggler to change search type.
// SearchTypes defines the options user can pick form. An array of search type objects e.g. [USER_SEARCH, COMPANY_SEARCH]
// Tow different views, singleRowView and a double row view where the toggle button is displayed below searchBox
const SearchBoxWithTogglerContainer = ({
    theme, // from index
    history, // from index
    singleRowView, // if true an interggrated toggler searchBox is rendered
    singleRowOptions, // optional object containing colors
    singleRowBorderColor, //optional color only relevant if singleRowView
    searchTypes, // required
    defaultSearchType, // required
    dropdownMaxHeight, // optional default 300px
    handleSelect, // required
    excludedResults, // optional object with keys equal to searchTypes.searchTitle
    togglerMinWidth, // optional default is 150px
    autoFocus, // optional, default false
}) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchType, setSearchType] = useState(defaultSearchType); // starting search field

    togglerMinWidth = togglerMinWidth || "150px";
    // changes search type based of users selection
    const searchTypeChange = (selectedRequestPath) => {
        searchTypes.forEach((el) => {
            if (el.apiRequestPath === selectedRequestPath) setSearchType(el);
        });
    };

    const ref = useRef({});
    UseOutsideClick(ref, (e) => {
        setShowDropdown(false);
    });

    if (singleRowView) {
        return (
            <SingleRowView
                Ref={ref}
                togglerMinWidth={togglerMinWidth}
                setShowDropdown={setShowDropdown}
                showDropdown={showDropdown}
                theme={theme}
                searchType={searchType}
                searchTypes={searchTypes}
                searchTypeChange={searchTypeChange}
                dropdownMaxHeight={dropdownMaxHeight}
                handleSelect={handleSelect}
                excludedResults={excludedResults}
                singleRowBorderColor={singleRowBorderColor}
                autoFocus={autoFocus}
                singleRowOptions={singleRowOptions}
            />
        );
    } else {
        return (
            <DoubleRowView
                Ref={ref}
                setShowDropdown={setShowDropdown}
                showDropdown={showDropdown}
                theme={theme}
                searchType={searchType}
                searchTypes={searchTypes}
                searchTypeChange={searchTypeChange}
                dropdownMaxHeight={dropdownMaxHeight}
                handleSelect={handleSelect}
                excludedResults={excludedResults}
                togglerMinWidth={togglerMinWidth}
                autoFocus={autoFocus}
            />
        );
    }
};

export default SearchBoxWithTogglerContainer;
