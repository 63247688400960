import styled, { withTheme } from "styled-components";

import Button from "../../Button";
import ButtonCircleIcon from "../../ButtonCircleIcon";
import Icon from "../../Icon";

const btnSize = "2rem";

const EditPostButtonView = ({
    theme,
    popoverRef,
    popoverShowing,
    popoverShowsEditBtn,
    leaveText = "Leave",
    leaveIcon = <Icon name="user-minus" />,
    handleEdit,
    handleLeave,
    handleOptionsButtonClick,
}) => {

    const editButton = (
        <ButtonWrapper>
            <Button
                handleClick={handleEdit}
                children="Edit"
                fontWeight={500}
                icon={<Icon name="pen" />}
                // icon={<i className="fas fa-pen"></i>}
                secondaryBtnColors={true}
                iconColor={theme.colors.accent}
                noShadow={true}
            />
        </ButtonWrapper>
    );

    const leaveButton = (
        <ButtonWrapper>
            <Button
                handleClick={handleLeave}
                children={leaveText}
                fontWeight={500}
                icon={leaveIcon}
                secondaryBtnColors={true}
                iconColor={theme.colors.text.semantic.error}
                noShadow={true}
            />
        </ButtonWrapper>
    );

    const popover = popoverShowing ? (
        <PopoverContent ref={popoverRef} btnSize={btnSize}>
            {popoverShowsEditBtn ? editButton : leaveButton}
        </PopoverContent>
    ) : null;

    return (
        <EditPostButtonCol>
            <ButtonCircleIcon
                handleClick={handleOptionsButtonClick}
                circleDiameter={btnSize}
                fontSize={"1rem"}
                bgColor={theme.colors.offPrimary}
                iconColor={theme.colors.secondary}
            >
                <Icon name="ellipsis-h" />
            </ButtonCircleIcon>
            {popover}
        </EditPostButtonCol>
    );
};

export default withTheme(EditPostButtonView);

const EditPostButtonCol = styled.div`
    position: relative;
`;

const PopoverContent = styled.div`
    position: absolute;
    z-index: 3;
    top: calc(${(props) => props.btnSize} + 0.3rem);
    right: -6rem;
    padding: 1rem;
    min-width: 11rem;
    border-radius: 10px;
    background-color: ${(props) => props.theme.colors.primary};
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
`;

const ButtonWrapper = styled.div`
    padding: 0.25rem 0;
`;

// const BtnText = styled.div`
//     font-weight: 500;
// `;
