import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTheme } from "styled-components";

import SearchBox from "./SearchBox-container";
import { addError, removeError } from "../../../main/store/actions/errors";

function mapState(state) {
    return {
        errors: state.errors,
    };
}

const mapDispatch = {
    addError,
    removeError,
};

export default withRouter(connect(mapState, mapDispatch)(withTheme(SearchBox)));
