import styled from "styled-components";
import {
    REACT_APP_DEFAULT_USER_PROFILE,
    REACT_APP_DEFAULT_COMPANY_PROFILE,
} from "../constants";
import ImgTag from "../ImgTagWithLoading";
import LoadingShimmer from "../LoadingShimmer";
import LetterBox from "../../common/LetterBox";

const Avatar = ({
    path,
    letterAsAltImg, // optional, object defines properties of letter to be used as avatar if !path
    user, // need one of user or company to be true, user takes priority
    company,
    size, // optional default is 50px
    alt, // optional default is the path
    borderColor, // optional if you want border (recommended)
    borderWidth, // optional default is 1px
    handleClick, // optional
    showPointer, // optional - pointer will be shown if handleClick function is given anyway
    parentLoading, // optional if true then loading avatar rendered
    theme, // index
}) => {
    // If user or company is true and no path given use default for each
    if (user && !path && !letterAsAltImg) {
        path = REACT_APP_DEFAULT_USER_PROFILE;
    } else if (company && !path && !letterAsAltImg) {
        path = REACT_APP_DEFAULT_COMPANY_PROFILE;
    }

    const commonWrapperProps = {
        size: size,
        borderWidth: borderWidth,
        borderColor: borderColor,
    };
    if (parentLoading) {
        return (
            <ImgOuterWrapper
                {...commonWrapperProps}
                onClick={() => {}}
                pointer={false}
            >
                <ImgInnerWrapper {...commonWrapperProps}>
                    <LoadingShimmer />
                </ImgInnerWrapper>
            </ImgOuterWrapper>
        );
    } else if (!!letterAsAltImg && !!letterAsAltImg.altLetter && !path) {
        return (
            <ImgOuterWrapper
                {...commonWrapperProps}
                onClick={handleClick ? handleClick : () => {}}
                pointer={handleClick ? true : showPointer ? true : false}
            >
                <ImgInnerWrapper {...commonWrapperProps}>
                    <LetterBox
                        letters={letterAsAltImg.altLetter}
                        theme={theme}
                        bgColor={letterAsAltImg.bgColor || theme.colors.primary}
                        textColor={
                            letterAsAltImg.textColor || theme.colors.accent
                        }
                        fontSize={letterAsAltImg.fontSize}
                    />
                </ImgInnerWrapper>
            </ImgOuterWrapper>
        );
    } else {
        return (
            <ImgOuterWrapper
                onClick={handleClick ? handleClick : () => {}}
                pointer={handleClick ? true : showPointer ? true : false}
                {...commonWrapperProps}
            >
                <ImgInnerWrapper {...commonWrapperProps}>
                    <ImgTag
                        src={path}
                        alt={alt || undefined}
                        objectFit={"cover"}
                        verticalAlign={"middle"}
                    />
                </ImgInnerWrapper>
            </ImgOuterWrapper>
        );
    }
};

export default Avatar;

const ImgOuterWrapper = styled.div`
    position: relative;
    cursor: ${(props) => (props.pointer ? "pointer" : "auto")};
    width: ${(props) => (props.size ? props.size : "50px")};
    height: ${(props) => (props.size ? props.size : "50px")};
    /* Stop avatar ever shrinking */
    min-width: ${(props) => (props.size ? props.size : "50px")};
    min-height: ${(props) => (props.size ? props.size : "50px")};
    border-radius: 50%;
    overflow: hidden;
    /* Hide annoying little gab between image an its border */
    padding: ${({ borderColor, borderWidth }) =>
        borderColor ? `${borderWidth ? borderWidth : "1px"}` : ""};
    background-color: ${({ borderColor }) =>
        borderColor ? borderColor : "inherit"};
`;

const ImgInnerWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    /* Border color (must be at least as large as border width */
    box-shadow: ${({ borderColor, borderWidth }) =>
        borderColor
            ? `0 0 0 ${borderWidth ? borderWidth : "1px"} ${borderColor}`
            : ""};
`;
