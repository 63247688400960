import { darken, lighten } from "polished";

export const lightTheme = {
    colors: {
        body: "#F3F3F3",
        primary: "#ffffff",
        justOffPrimary: darken(0.05, "#fff"),
        offPrimary: darken(0.1, "#fff"),
        secondary: "#353535",
        justOffSecondary: lighten(0.2, "#353535"),
        offSecondary: lighten(0.5, "#353535"),
        // accent: "#11b8b2",
        // veryLightAccent: lighten(0.55, "#11b8b2"),
        // lightAccent: lighten(0.15, "#11b8b2"),
        // darkAccent: darken(0.15, "#11b8b2"),
        accent: "#109dad",
        veryLightAccent: lighten(0.55, "#109dad"),
        lightAccent: lighten(0.15, "#109dad"),
        darkAccent: darken(0.15, "#109dad"),
        accentGreen: '#54FBEA',
        progressGreen: "#B2F063",
        notification: {
            badge: "#ff2b2b",
        },
        semantic: {
            success: "#d4edda",
            error: "#f8d7da",
            warning: "#fff3cd",
            info: "#d1ecf1",
        },
        text: {
            body: "#353535", // default text color
            semantic: {
                success: "#53bd6c",
                error: darken(0.3, "#f8d7da"),
                warning: "#d6a424",
                info: "#33bcd4",
                pumpkin: "#d35400",
                greenSea: "#16a085",
                pomegranate: "#c0392b",
                peterRiver: "#3498db",
                wetAsphalt: "#34495e",
                orange: "#f39c12",
                emerald: "#2ecc71",
                amethyst: "#9b59b6",
            },
        },
    },
    sizes: {
        navHeight: "4rem",
        xsViewWidth: "544px",
        smViewWidth: "768px",
        mdViewWidth: "992px",
        lgViewWidth: "1200px",
        aspectRatios: {
            coverPhoto: {
                LgView: (312 / 820) * 100, // i.e 312 / 820 Facebook lg screen aspect-ratio
                xsView: (3 / 5) * 100, // Phone photo aspect-ratio, 4:3 is standard smartphone photo aspect ratio
                // smView: (360 / 640) *100, // this would be Facebook's sm screen aspect ratio
            },
            feedImages: {
                square: 1 * 100, // square
                landscape: (3 / 4) * 100, // most common default phone landscape aspect ratio
                portrait: 1.07 * 100,
            },
            // coverPhoto: {
            //     LgView: 820 / 312, // Facebook lg screen aspect-ratio
            //     xsView: 4 / 3, // main phone photo aspect-ratio
            //     // smView: 640 / 360, // Facebook sm screen aspect ratio
            // },
        },
        maxProfileWidth: "900px",
    },
    appName: "BoomsUp",
};

// EXPERIMENTS =====================
// secondary: "#393939",
// secondary: "#008F96",
// secondary: "#353535",
// accent: "#0f777d",
// accent: "#008F96",
// accent: "#268386", // <-- good one
// accent: "#34495e",
// accent: "#62516D",
// accent: "#F77A36",
// accent: "#16a085",
// accent: "#2980b9",
// accent: "#16a085",
// accent: "#FA5F1A",
// accent: "#2B80BD",
