import { useState, useEffect, useCallback } from "react";

// Custom components & code
import CreateCompanyView from "./CreateCompany-view";

import {
    checkAndLoadMapsAPI,
    fieldsFailingValidation,
    getLatLng,
    handleTextChange,
} from "../../library/utilities";
import { COMPANY_PROFILE_VALIDATION_RULES } from "../../library/common/constants";

import { useApi } from "../../services/api";

// Company signup page, has front end validation built in. User directed to edit company page after creating
const CreateCompanyContainer = ({
    history,
    errors,
    addError,
    removeError,
    setShowSidebar,
}) => {
    // const { getAccessTokenSilently } = useAuth0();

    const { refresh: submitFormData } = useApi();

    // Hide sidebar on component
    useEffect(() => {
        setShowSidebar(false); // don't show sidebar
        return () => {
            setShowSidebar(true); // show sidebar again on unmount
        };
    }, [setShowSidebar]);

    const [mapsAPILoading, setMapsAPILoading] = useState(true);
    // On mount checks for maps API script tag, loads it if required and setMapsLoaded state true
    useEffect(() => {
        checkAndLoadMapsAPI(setMapsAPILoading);
    }, []);

    const [loading, setLoading] = useState(false);
    const [formState, setFormState] = useState({
        name: "",
        companyType: "",
        phone: "",
        officeLocations: [
            {
                address: "",
                latLng: {},
            },
        ],
        userIsAdmin: true, // tells server this company is owned and not a "paper company"
        coverPhoto: {}, // needed so preview profile knows there is not coverPhoto and to use default instead
        logo: {},
        websiteURL: "",
    });
    // State used in front end validation
    const [validationFailedState, setValidationFailedState] = useState({});
    const [firstSubmitFailed, setFirstSubmitFailed] = useState(false);

    // Handle text input changes
    const handleChange = (e) => {
        handleTextChange(e, setFormState, formState);
    };

    // Check if all fields pass rules, if an error occurs its added to fieldsFailedValidation state
    const checkFieldsPassValidationRules = useCallback(() => {
        const fieldsFailedValidation = fieldsFailingValidation(
            formState,
            COMPANY_PROFILE_VALIDATION_RULES // company profile rules defined as constant
        );
        // Update state to show error messages to user (if any)
        setValidationFailedState(fieldsFailedValidation);
        // return false if failure, true if no failures
        return Object.keys(fieldsFailedValidation).length === 0;
    }, [formState]);

    // Removes error messages from DOM as user fixes issue. Only runs after a user has submitted a form that failed validation.
    useEffect(() => {
        if (firstSubmitFailed) {
            checkFieldsPassValidationRules();
        }
    }, [firstSubmitFailed, checkFieldsPassValidationRules]);

    const castForSubmit = (objToSubmit) => {
        let castObj = {};
        Object.keys(objToSubmit).forEach((key) => {
            if (key === "logo" || key === "coverPhoto") {
                castObj[key] = undefined; // send nothing to server for these keys
            } else if (key === "websiteURL") {
                // Only send websiteURL if not an empty string
                if (objToSubmit[key] !== "") {
                    castObj[key] = objToSubmit[key];
                } else {
                    castObj[key] = undefined;
                }
            } else {
                castObj[key] = objToSubmit[key];
            }
        });
        return castObj;
    };

    // Submits to server if all fields pass validation checks
    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            // Check if any of the fields is empty
            const pass = checkFieldsPassValidationRules();
            if (pass) {
                setLoading(true);
                const castState = castForSubmit(formState);

                submitFormData({
                    method: "post",
                    url: `/api/companies/`,
                    data: castState,
                    callback: (res) => { history.push(`/companies/${res._id}`); },
                });

            } else {
                // If pass === false allow user to edit form again, error messages have been added to DOM in checkForEmptyFields
                setFirstSubmitFailed(true);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            addError(error.message);
            return;
        }
    };

    // Send user to previous page on cancel
    const handleCancel = () => {
        history.goBack();
    };

    // Used inside Autofill child components to set site address and latLng
    const autofillUpdateLocation = useCallback((address) => {
        getLatLng(address, (latLng, address) => {
            setFormState((f) => {
                return {
                    ...f,
                    officeLocations: [{ address: address, latLng: latLng }],
                };
            });
        });
    }, []);

    const createCompanyFormProps = {
        errors,
        removeError,
        formState,
        validationFailedState,
        handleSubmit,
        handleChange,
        handleCancel,
        autofillUpdateLocation,
        loading,
        mapsAPILoading,
    };

    return <CreateCompanyView {...createCompanyFormProps} />;
};

export default CreateCompanyContainer;
// TODO you will get an odd error if you open create component and then save something in client (making react update without a full refresh), something to do with inputMap not sure. Not sure if its a problem and if a user could ever encounter this error

