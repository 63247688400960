import { connect } from "react-redux";
import { Switch, Route, withRouter } from "react-router-dom";
import { useEffect, useRef } from "react";
import styled from "styled-components";

import { removeError } from "../store/actions/errors";

import Homepage from "../../modules/Homepage";
import ExploreMap from "../../modules/ExploreMap";
// user
import NewsFeed from "../../modules/NewsFeed";
import UserProfile from "../../modules/UserProfile";
import UserList from "../../library/common/UserList";
// sites
import CreateSite from "../../modules/CreateSiteForm";
import SiteProfile from "../../modules/SiteProfile";
import MySites from "../../modules/MySites";
// companies
import CreateCompanyForm from "../../modules/CreateCompanyForm";
import CompanyProfile from "../../modules/CompanyProfile";
import CompanyList from "../../library/common/CompanyList";
// posts
import Post from "../../modules/Post";
import PostList from "../../modules/PostList";
// messages & notifications
import MessagesScreen from "../../modules/MessagesScreen";
import NotificationsScreen from "../../modules/Notifications";
import StoreLoader from "../../modules/StoreLoader";
import SearchPage from "../../modules/SearchPage";
import MyContacts from "../../modules/MyContacts";

const MainContent = ({
    history,
    removeError,
    // notificationsSocketState,
    // messagesSocketState,
}) => {
    const path = useRef(null); // useRef used to check if route has changed form render to render

    // Removes error from store every time the location changes.
    useEffect(() => {
        if (path.current !== history.location.pathname) {
            // path.current will be the history.location.pathname on last render
            removeError();
        }
        path.current = history.location.pathname; // update path.current for next render
    }, [history.location.pathname, removeError]);

    return (
        <Main>
            <StoreLoader />
            <Switch>
                <Route exact path="/users/all" component={UserList} />
                <Route path="/users/:user_id/:tab_name?" component={UserProfile} />
                <Route exact path="/sites" component={CreateSite} />
                <Route exact path="/sites/my_sites" component={MySites} />
                <Route path="/sites/:site_id/:tab_name?/:popup?" component={SiteProfile} />
                <Route exact path="/companies" component={CreateCompanyForm} />
                <Route exact path="/companies/all" component={CompanyList} />
                <Route path="/companies/:company_id/:tab_name?" component={CompanyProfile} />
                <Route exact path="/posts/all" component={PostList} />
                <Route path="/posts/:post_id/:image?/:image_index?" component={Post} />
                <Route path="/messages/:message_id?" component={MessagesScreen} />
                <Route path="/notifications" component={NotificationsScreen} />
                <Route path="/contacts" component={MyContacts} />
                <Route path="/map" component={ExploreMap} />
                <Route path="/search" component={SearchPage} />
                <Route path="/dev_dashboard" component={Homepage} />
                <Route component={NewsFeed} />
            </Switch>
        </Main>
    );
};

const mapStateToProps = (state) => ({ currentUser: state.currentUser });
const mapDispatchToProps = { removeError };

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MainContent));

const Main = styled.div`
    /* display: flex;
    justify-content: center; */
    /* min-height: 100vh - ${(props) => props.theme.sizes.navHeight}; */
    /* position: relative;
    min-height: 100%; */
`;
