import styled from "styled-components";
import { useState } from "react";

import TrashButton from "../ButtonTrashCircle";
import Button from "../Button";

// List of services, used in edit and view components
const ServiceListView = ({
    theme,
    services,
    handleDeleteClick,
    fontSize, // optional
    limit, // optional limit on num services to display before load more btn
    vertSpace, // optional
    iconColor, // optional color for tick icons
}) => {
    const [showAll, setShowAll] = useState(false); // use for Show All btn to show all services
    const limitedNumberServices = limit || 5; // max number services show unless show all clicked
    const ServicesToRender = []; // stores services then is rendered later

    if (handleDeleteClick) {
        // User is in edit mode - don't limit number of services and show delete icon next to each
        services.forEach((service, i) => {
            ServicesToRender.push(
                <ServiceItem
                    vertSpace={vertSpace}
                    key={i}
                    className={`service-item`}
                >
                    <TrashButton
                        handleClick={() => {
                            handleDeleteClick(i);
                        }}
                        bgColor={theme.colors.semantic.error}
                        iconColor={theme.colors.secondary}
                        circleDiameter={"2.5rem"}
                        fontSize={"0.9rem"}
                    />
                    <Name fontSize={fontSize}>{service.name}</Name>
                </ServiceItem>
            );
        });
    } else {
        // User is in a view mode - limit number of services shown unless user has clicked show all btn
        services.forEach((service, i) => {
            if (showAll || i < limitedNumberServices) {
                ServicesToRender.push(
                    <ServiceItem
                        vertSpace={vertSpace}
                        key={i}
                        className={`service-item`}
                    >
                        <Icon
                            fontSize={fontSize}
                            iconColor={iconColor}
                            className="far fa-check-circle"
                        ></Icon>
                        <Name fontSize={fontSize}>{service.name}</Name>
                    </ServiceItem>
                );
            }
        });
    }
    return (
        <Wrapper>
            {ServicesToRender}
            {!handleDeleteClick && services.length > limitedNumberServices && (
                <BottomRow>
                    <ShowAllBtnWrapper>
                        <Button
                            handleClick={() => { setShowAll(f => !f); }}
                            textSize={"1rem"}
                            bgColor={theme.colors.body}
                            textColor={theme.colors.secondary}
                            noShadow={true}
                        >
                            <AllBtnText>
                                {showAll ? "Show Less" : "Show All"}
                            </AllBtnText>
                        </Button>
                    </ShowAllBtnWrapper>
                </BottomRow>
            )}
        </Wrapper>
    );
};

export default ServiceListView;

const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const ServiceItem = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: ${({ vertSpace }) =>
        !!vertSpace ? `${vertSpace} 0.5rem` : `0.5rem`};
`;

const Name = styled.div`
    margin: 0 0 0 0.7rem;
    font-size: ${(props) => props.fontSize || "1.4rem"};
    font-weight: 500;
`;

const Icon = styled.div`
    color: ${(props) =>
        props.iconColor ? props.iconColor : props.theme.colors.accent};
    font-size: calc(${(props) => props.fontSize || "1.4rem"} * 1.2);
`;

const BottomRow = styled.div`
    width: 100%;
    margin-top: 0.7rem;
    display: flex;
    justify-content: center;
`;

const ShowAllBtnWrapper = styled.div`
    flex: 0 1 300px;
`;

const AllBtnText = styled.div`
    font-weight: 500;
`;
