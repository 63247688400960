import styled, { css } from "styled-components";
import { darken } from "polished";

import Avatar from "../../library/common/Avatar";

const fontSize = "1.2rem";
const iconSize = "1.3rem";
const SideBarContainer = ({
    theme,
    currentUser,
    logOut,
    history,
    setFullScreenSidebar,
}) => {
    // Closes sidebar if user is viewing sidebar form a small screen
    // Used to remove sidebar when user clicks an item (i.e. click a button then remove sidebar to see the action)
    const closeSmScreenSidebar = () => {
        if (setFullScreenSidebar) {
            setFullScreenSidebar(false);
        }
    };

    return (
        <Wrapper>
            {currentUser.user.username && (
                <Item
                    theme={theme}
                    onClick={() => {
                        history.push(`/users/${currentUser.user.id}`);
                        closeSmScreenSidebar();
                    }}
                >
                    <AvatarWrapper>
                        <Avatar
                            path={currentUser.user.img}
                            user={true}
                            size={"2.4rem"}
                            borderColor={theme.colors.offPrimary}
                        />
                    </AvatarWrapper>
                    {/* <Icon theme={theme} className="fas fa-user"></Icon> */}
                    <Text theme={theme}>{currentUser.user.username}</Text>
                </Item>
            )}
            <Item
                theme={theme}
                onClick={() => {
                    history.push(`/sites/my_sites`);
                    closeSmScreenSidebar();
                }}
            >
                <Icon
                    // color={theme.colors.accent}
                    className="fas fa-map-pin"
                ></Icon>
                <Text>Your Sites</Text>
            </Item>
            <Item
                theme={theme}
                onClick={() => {
                    history.push(`/feed`);
                    closeSmScreenSidebar();
                }}
            >
                <Icon className="far fa-newspaper"></Icon>
                <Text>News Feed</Text>
            </Item>

            <Item
                theme={theme}
                onClick={() => {
                    history.push(`/contacts`);
                    closeSmScreenSidebar();
                }}
            >
                <Icon className="fas fa-user-friends"></Icon>
                <Text>Contacts</Text>
            </Item>
            {/* <Item
                theme={theme}
                onClick={() => {
                    closeSmScreenSidebar();
                    history.push(`/messages`);
                }}
            >
                <Icon
                    // color={theme.colors.accent}
                    className="fas fa-envelope"
                ></Icon>
                <Text>Messages</Text>
            </Item>
            <Item
                theme={theme}
                onClick={() => {
                    history.push(`/notifications`);
                }}
            >
                <Icon
                    // color={theme.colors.notification.badge}
                    className="fas fa-bell"
                ></Icon>
                <Text>Notifications</Text>
            </Item> */}
            <SectionHeading color={theme.colors.darkAccent}>
                Find
            </SectionHeading>
            <Item
                theme={theme}
                onClick={() => {
                    closeSmScreenSidebar();
                    history.push("/map");
                }}
            >
                <Icon className="fas fa-map-marked-alt"></Icon>
                <Text>Explore Map</Text>
            </Item>
            <Item
                theme={theme}
                onClick={() => {
                    closeSmScreenSidebar();
                    history.push("/search");
                }}
            >
                <Icon className="fas fa-search"></Icon>
                <Text>Search</Text>
            </Item>

            {/* <Item
                theme={theme}
                onClick={() => {
                    alert("Route not created yet");
                    closeSmScreenSidebar();
                }}
            >
                <Icon
                    // color={theme.colors.accent}
                    className="fas fa-star-half-alt"
                ></Icon>
                <Text>Top Crust</Text>
            </Item>
            <Item
                theme={theme}
                onClick={() => {
                    alert("Route not created yet");
                    closeSmScreenSidebar();
                }}
            >
                <Icon className="fas fa-briefcase"></Icon>
                <Text>Jobs</Text>
            </Item> */}
            {/* <Item
                theme={theme}
                onClick={() => {
                    alert("Route not created yet");
                    closeSmScreenSidebar();
                }}
            >
                <Icon
                    // color={theme.colors.accent}
                    className="fas fa-search-dollar"
                ></Icon>
                <Text>Wages</Text>
            </Item> */}

            {/* <SectionHeading color={theme.colors.darkAccent}>
                Dev Shortcuts
            </SectionHeading>
            <Item
                theme={theme}
                onClick={() => {
                    history.push(`/dev_dashboard`);
                    closeSmScreenSidebar();
                }}
            >
                <Icon className="fas fa-layer-group"></Icon>
                <Text>Dev Dashboard</Text>
            </Item>
            <Item
                theme={theme}
                onClick={() => {
                    history.push(`/companies/all`);
                    closeSmScreenSidebar();
                }}
            >
                <Icon className="fas fa-building"></Icon>
                <Text>Search Companies</Text>
            </Item>
            <Item
                theme={theme}
                onClick={() => {
                    history.push(`/posts/all`);
                    closeSmScreenSidebar();
                }}
            >
                <Icon className="fas fa-caret-right"></Icon>
                <Text>Search All Posts</Text>
            </Item> */}

            {/* <Item
                theme={theme}
                onClick={() => {
                    history.push(`/companies/6042b59cea933d5f455583bc`);
                    closeSmScreenSidebar();
                }}
            >
                <Icon className="fas fa-caret-right"></Icon>
                <Text>Frank Co</Text>
            </Item> */}
        </Wrapper>
    );
};

export default SideBarContainer;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow-wrap: break-word;
    padding: 0.5rem;
    /* color: ${(props) => props.theme.colors.text.body}; */
`;

const Item = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    min-height: 3.3rem;
    /* padding: 0.7rem 0rem; */
    font-size: ${fontSize};
    border-radius: 5px;
    &:hover {
        background-color: ${({ theme }) => darken(0.03, theme.colors.body)};
    }
`;

const shared = css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 1 calc(${iconSize} + 2rem);
`;

const Icon = styled.i`
    ${shared}
    color: ${({ theme, color }) => color || theme.colors.secondary};
    font-size: ${iconSize};
`;

const AvatarWrapper = styled.div`
    ${shared}
`;

const Text = styled.div`
    /* color: ${({ theme }) => theme.colors.secondary}; */
    display: inline;
    flex: 1;
`;

const SectionHeading = styled.div`
    color: ${(props) => props.theme.colors.darkAccent};
    padding: 0.3rem;
    font-weight: 500;
    /* color: ${({ color }) => color}; */
    border-top: 1px solid ${({ color }) => color};
    margin: 1rem 0 -0.3rem 0;
`;
