import { useState } from "react";
import { useParams } from "react-router-dom";

import CompanyProfileView from "./CompanyProfile-view";
import PopupRouter from "./PopupRouter";

import { COMPANY_PAGES, CONNECTION_STATUS } from "../../library/common/constants";

import { useApi } from "../../services/api";

const CompanyProfileContainer = ({
    currentUser,
    // page: pageToDisplay,
    previewState = undefined,
    ...errorHandlingProps
    // errors,
    // addError,
    // removeError,
}) => {

    // define component and popup states
    const [companyDetails, setCompanyDetails] = useState({});
    const [activePopup, setActivePopup] = useState("");

    // define urls to company api
    const { company_id: companyId = "", tab_name: tabName = "" } = useParams();

    const companyProfileBaseUrl = `/api/companies/${companyId}`;
    const companyProfileUrls = {
        serverPath: companyProfileBaseUrl,
        addLogoPicPath: `${companyProfileBaseUrl}/upload_logo`,
        deleteLogoPicPath: `${companyProfileBaseUrl}/delete_logo`,
        addCoverPicPath: `${companyProfileBaseUrl}/upload_cover_photo`,
        deleteCoverPicPath: `${companyProfileBaseUrl}/delete_cover_photo`,
        allFieldsPath: `${companyProfileBaseUrl}/all_fields`,
        serviceRecommendations: "/api/services/recommend",
    };
    const currentUserPath = `/api/users/${currentUser.user.id}`;

    const adjustedTabName = tabName.length > 0 ? `/${tabName}` : tabName;
    const pageToDisplay = Object.values(COMPANY_PAGES)
        .filter(el => el.urlExtension === adjustedTabName)[0].name;

    // get profile data
    const {
        // data: companyData,
        isLoading: isLoadingCompany,
        refresh: refreshCompanyData,
    } = useApi(
        !!previewState ?
            {} :
            {
                method: "get",
                url: companyProfileBaseUrl,
                callback: (res) => {
                    setCompanyDetails({
                        ...res,
                        userIsAdmin: res.adminUsers && res.adminUsers.some(
                            (adminUser) => adminUser.user === currentUser.user.id
                        )
                    });
                },
            }
    );

    // get currentUser's data to check conx status with company
    const {
        // data: currentUserData,
        isLoading: isLoadingCurrentUser,
        refresh: refreshCurrentUserData,
    } = useApi(
        !!previewState ?
            {} :
            {
                method: "get",
                url: currentUserPath,
                callback: (res) => {
                    if (res.companiesFollowed) {
                        const conxStatus = res.companiesFollowed
                            .map(el => el.companyId).includes(companyId) ?
                            CONNECTION_STATUS.ACCEPTED :
                            CONNECTION_STATUS.NOTAPPLICABLE;
                        setCompanyDetails(f => ({ ...f, conxStatus }));
                    }
                },
            }
    );

    // // save profile data to companyDetails state
    // // and determine connection between currentUser and company
    // useEffect(() => {
    //     if (!!companyData && !!currentUserData) {
    //         const companiesFollowedArray =
    //             currentUserData.companiesFollowed || [];

    //         setCompanyDetails({
    //             ...companyData,
    //             userIsAdmin: companyData.adminUsers.some(
    //                 (adminUser) => adminUser.user === currentUserData._id
    //             ),
    //             conxStatus: companiesFollowedArray
    //                 .map((obj) => obj.companyId)
    //                 .includes(companyData._id)
    //                 ? CONNECTION_STATUS.ACCEPTED
    //                 : CONNECTION_STATUS.NOTAPPLICABLE,
    //         });
    //     }
    // }, [companyData, currentUserData]);

    // refresh both company and currentUser data
    const refreshDataFromServer = () => {
        refreshCompanyData();
        refreshCurrentUserData();
    };

    // define props for views

    const profileViewProps = {
        pageToDisplay,
        companyDetails: previewState || companyDetails,
        createView: !!previewState,
        setPopUpToDisplay: setActivePopup,
        setCompanyDetails,
        loading: isLoadingCompany || isLoadingCurrentUser,
        ...errorHandlingProps,
    };

    const popupProps = {
        companyDetails,
        setPopUpToDisplay: setActivePopup,
        setCompanyDetails,
        popUpToDisplay: activePopup,
        refreshDataFromServer,
        serverPaths: companyProfileUrls,
        ...errorHandlingProps,
    };

    return (
        <div>
            <CompanyProfileView {...profileViewProps} />
            {!previewState && <PopupRouter {...popupProps} />}
        </div>
    );
};

export default CompanyProfileContainer;
