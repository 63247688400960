import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { sendApiRequest } from "../../../../services/api";

import { LICENCE_SEARCH } from "../../../../library/common/constants";
import {
    addImagesToState,
    packStateInFormDataObj,
    deleteImgFromArrayInState,
    fieldsFailingValidation,
    checkDocumentFieldInDb,
} from "../../../../library/utilities";
import LicenceList from "./LicenceList-view";
import LicenceDetails from "./LicenceDetails-view";
import {
    FILE_UPLOAD_CONFIG,
    LICENCE_EDIT_SCREENS,
    LICENCE_VALIDATION_RULES,
} from "../../../../library/common/constants";
import PopupLoadingScreen from "../../../../library/common/PopupLoadingScreen";

const LicenseEditContainer = ({
    licences,
    addError,
    setPopUpToDisplay,
    refreshDataFromServer,
    addLicenceOnly,
}) => {
    const { getAccessTokenSilently } = useAuth0();
    const [loading, setLoading] = useState(false);
    // Licence being edited or added is stores in licenseDetails
    const [licenseDetails, setLicenseDetails] = useState({});
    // Stores validation failed errors
    const [validationFailedState, setValidationFailedState] = useState({});
    const [firstSubmitFailed, setFirstSubmitFailed] = useState(false);

    // The screen rendered depends on addLicenceOnly prop. If true goes strait to add screen
    const [licenceScreen, setLicenceScreen] = useState(
        addLicenceOnly ? LICENCE_EDIT_SCREENS.add : LICENCE_EDIT_SCREENS.list
    );

    let { user_id } = useParams();

    // set loaded on mount
    useEffect(() => {
        setLoading(false);
        if (addLicenceOnly) {
            // If user arrived directly from profile set licenceName to empty string for from validation check
            setLicenseDetails({ licenceName: "" });
        }
    }, [setLoading, addLicenceOnly]);

    // User clicks edit button for a licence
    const handleEditClick = (licenseToEditIndex) => {
        licences.forEach((licence, i) => {
            if (i === licenseToEditIndex) {
                // add licence and its index to licenceDetails for editing
                setLicenseDetails({
                    ...licence,
                    licenseToEditIndex: licenseToEditIndex,
                });
                setLicenceScreen(LICENCE_EDIT_SCREENS.edit);
            }
        });
    };

    // User clicks button to add new licence.
    const handleAddClick = () => {
        // Add licenceName to licenceDetails as it is a required field
        setLicenseDetails({ licenceName: "" });
        setLicenceScreen(LICENCE_EDIT_SCREENS.add);
    };

    // On file change - add images to state, uses utility function
    const onFileChange = (e) => {
        addImagesToState(
            e,
            setLicenseDetails,
            licenseDetails,
            "licencePhoto",
            "single",
            setValidationFailedState
        );
    };

    // Adds license id && name to state, used in SearchBox
    const addLicenceToState = (result) => {
        setLicenseDetails((f) => {
            return {
                ...f,
                licence: result.id,
                licenceName: result.name,
            };
        });
    };

    // Handle change in searchBox, updates licenceName as user types
    const handleSearchBoxStringChange = (string) => {
        setLicenseDetails((f) => {
            return {
                ...f,
                licenceName: string,
                licence: "",
            };
        });
    };

    // loop through licenceDetails and check each field against validation rules
    const checkFieldsPassValidationRules = useCallback(() => {
        const fieldsFailedValidation = fieldsFailingValidation(
            licenseDetails,
            LICENCE_VALIDATION_RULES
        );
        // Update state to show error messages to user
        setValidationFailedState(fieldsFailedValidation);
        // return false if failure, true if no failures
        return Object.keys(fieldsFailedValidation).length > 0 ? false : true;
    }, [licenseDetails]);

    // Cleares error from screen as user fixes
    useEffect(() => {
        if (firstSubmitFailed) {
            checkFieldsPassValidationRules();
        }
    }, [licenseDetails, checkFieldsPassValidationRules, firstSubmitFailed]);

    const handleSubmit = async () => {
        try {
            setLoading(true);
            // Front end validation
            const pass = checkFieldsPassValidationRules();
            if (pass) {
                // Checks for company id, adds or creates a new company in db if necessary
                const newOrUpdatedLicence = await checkDocumentFieldInDb(
                    getAccessTokenSilently,
                    licenseDetails.licence, // doc id
                    licenseDetails.licenceName, // doc name
                    "licence", // key of id doc id field
                    LICENCE_SEARCH,
                    "/api/licences/",
                    licenseDetails,
                    true // titleCase = true
                );
                console.log("newOrUpdatedLicence");
                console.log(newOrUpdatedLicence);
                // pack details into fromData for submitting
                const formDataForSubmitting = packStateInFormDataObj(
                    newOrUpdatedLicence,
                    "licencePhoto"
                );
                // choose between add and edit paths
                const serverPath =
                    licenceScreen === LICENCE_EDIT_SCREENS.add
                        ? `/api/users/${user_id}/add_licence`
                        : `/api/users/${user_id}/edit_licence`;
                await sendApiRequest(getAccessTokenSilently, {
                    method: "put",
                    url: serverPath,
                    data: formDataForSubmitting,
                    ...FILE_UPLOAD_CONFIG,
                });
                if (addLicenceOnly) {
                    // if user arrived directly from profile (i.e. not though licence list popup screen) send them back to profile by removing popup
                    setPopUpToDisplay("");
                }
                await refreshDataFromServer();
                // If popup not cleared then user will be returned to initial licenceScreen
                // Why? Can't exactly recall but on a quick look I think, calling refresh triggers update of userDetails state
                // which remounts popup to the initially defined licenceScreen
            } else {
                setFirstSubmitFailed(true);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            addError(`Error submitting licence: ${error.message}`);
        }
    };

    // sends request to delete licence end point then refreshes data
    const handleLicenceDelete = async (licenseToDeleteIndex) => {
        try {
            setLoading(true);
            await sendApiRequest(getAccessTokenSilently, {
                method: "put",
                url: `/api/users/${user_id}/delete_licence`,
                data: {
                    licenseToDeleteIndex: licenseToDeleteIndex,
                },
            });
            await refreshDataFromServer();
        } catch (error) {
            setLoading(false); // only need to setLoading false if refreshDataFromServer is not called
            addError(`Error submitting licence: ${error.message}`);
        }
    };

    // If user hits back their returned to licences list screen
    const handleBack = () => {
        setLicenseDetails({});
        setLicenceScreen(LICENCE_EDIT_SCREENS.list);
        setValidationFailedState({}); // clear validation errors
    };

    // deletes image object from LicencePhoto array. Uses utility function
    const handleImageDelete = () => {
        deleteImgFromArrayInState(
            0, // only ever one image in array
            "licencePhoto",
            licenseDetails,
            setLicenseDetails
        );
    };

    if (loading) {
        return <PopupLoadingScreen />;
    } else if (licenceScreen === LICENCE_EDIT_SCREENS.list) {
        return (
            <LicenceList
                licences={licences}
                handleEditClick={handleEditClick}
                handleAddClick={handleAddClick}
                setPopUpToDisplay={setPopUpToDisplay}
                handleLicenceDelete={handleLicenceDelete}
            />
        );
    } else {
        return (
            <LicenceDetails
                licences={licences}
                addLicenceToState={addLicenceToState}
                handleSearchBoxStringChange={handleSearchBoxStringChange}
                validationFailedState={validationFailedState}
                setLicenseDetails={setLicenseDetails}
                licenseDetails={licenseDetails}
                onFileChange={onFileChange}
                handleSubmit={handleSubmit}
                handleBack={handleBack}
                handleImageDelete={handleImageDelete}
                handleLicenceDelete={handleLicenceDelete}
                licenceScreen={licenceScreen}
                addLicenceOnly={addLicenceOnly}
                setPopUpToDisplay={setPopUpToDisplay}
            />
        );
    }
};

export default LicenseEditContainer;
