import Navbar from "./Navbar-container";
import { withTheme } from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { setShowSidebar } from "../../main/store/actions/sidebar";

import { setCurrentUser } from "../../main/store/actions/auth";

function mapState(state) {
    return {
        currentUser: state.currentUser,
        sidebar: state.sidebar,
    };
}

const mapDispatch = {
    setCurrentUser,
    setShowSidebar,
};

export default withRouter(connect(mapState, mapDispatch)(withTheme(Navbar)));
