import styled, { withTheme } from "styled-components";

import { Wrapper } from "./PostPreview-view";
import PostCreateFooterBar from "./PostCreateFooterBar-view";
import ValidationErrorAlert from "./ValidationErrorAlert-view";

import ButtonTrashCircle from "../../../library/common/ButtonTrashCircle";
import FileUploadCircle from "../../../library/common/ButtonFileUploadCircle";
import Icon from "../../../library/common/Icon";
import InputGroup from "../../../library/common/InputGroup";
import LoadingDots from "../../../library/common/LoadingDots";
import HeaderBar from "../../../library/common/PopupHeaderBar";

import { INPUT_TYPES } from "../../../library/common/constants";

import { ContentBox } from "../../../resources/styles";

// Text focused input screen with image bar showing small preview of images uploaded in previous screen (or no images if no images added)
const TextInputScreenView = ({
    theme,
    handlePostTextChange,
    images,
    imgUploading,
    maxNumPhotos,
    setPopUpToDisplay,
    text,
    validationAlertProps,
    handleBack,
    handleClose,
    handleImageDelete,
    handleNext,
    onImageFileChange,
}) => {

    const ImagesToRender = images.map((image, i) => (
        <ImageWrapper key={i}>
            <TrashButtonWrapper>
                <ButtonTrashCircle
                    handleClick={() => { handleImageDelete(i); }}
                    circleDiameter={"2rem"}
                    fontSize={"1rem"}
                    iconColor={theme.colors.semantic.error}
                    bgColor={theme.colors.secondary}
                />
            </TrashButtonWrapper>
            <Image src={image.path} alt={"unavailable"} />
        </ImageWrapper>
    ));

    return (
        <Wrapper>
            <HeaderBar
                setPopUpToDisplay={setPopUpToDisplay}
                popupTitle={"Create Post"}
                customExitHandling={handleClose}
            />
            <ContentBox>
                <ContentWrapper>
                    <TextInputWrapper>
                        <InputGroup
                            type={INPUT_TYPES.TEXT_AREA}
                            rows={"6"}
                            handleChange={handlePostTextChange}
                            value={text}
                            name="text"
                            placeholder={"Write a caption..."}
                        />
                    </TextInputWrapper>
                    {(images[0] || !!imgUploading) && (
                        <ImageRow>
                            {ImagesToRender}
                            {images.length < maxNumPhotos && (
                                <FileUploadWrapper>
                                    {!!imgUploading && (
                                        <LoadingDotsWrapper>
                                            <LoadingDots size={"10px"} />
                                        </LoadingDotsWrapper>
                                    )}
                                    <FileUploadCircle
                                        handleClick={onImageFileChange}
                                        icon={<Icon name="plus" />}
                                        multipleFiles={true}
                                        circleDiameter={"4rem"}
                                        iconColor={theme.colors.primary}
                                        bgColor={theme.colors.offPrimary}
                                        fontSize={"2rem"}
                                    ></FileUploadCircle>
                                </FileUploadWrapper>
                            )}
                        </ImageRow>
                    )}
                </ContentWrapper>
            </ContentBox>
            <ValidationErrorAlert {...validationAlertProps} />
            <PostCreateFooterBar
                handleNext={handleNext}
                handleBack={handleBack}
                nextText={"Next"}
            />
        </Wrapper>
    );
};

export default withTheme(TextInputScreenView);

const ContentWrapper = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const TextInputWrapper = styled.div`
    padding: 1rem 0;
`;

const ImageRow = styled.div`
    display: flex;
    width: 100%;
    border: 1px solid ${(props) => props.theme.colors.offPrimary};
`;

const LoadingDotsWrapper = styled.div`
    position: absolute;
    width: 100%;
    display: flex;
    top: 1rem;
    justify-content: center;
    z-index: 1;
`;
const ImageWrapper = styled.div`
    position: relative;
    flex: 0 1 calc(100% / 4);
    padding-bottom: calc(100% / 4);
`;

const FileUploadWrapper = styled.div`
    position: relative;
    flex: 0 1 calc(100% / 4);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 150px;
`;

const Image = styled.img`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

// delete button wrapper
const TrashButtonWrapper = styled.div`
    position: absolute;
    z-index: 1;
    top: 0.3rem;
    right: 0.3rem;
    display: flex;
`;
