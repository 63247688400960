import { connect } from "react-redux";

import ErrorPopup from "./ErrorPopup-container";
import WithPopUp from "../../../hoc/WithPopUp";
// import { addError, removeError } from "../../../../main/store/actions/errors";
function mapState(state) {
    return {
        // errors: state.errors,
    };
}

const mapDispatch = {
    // addError,
    // removeError,
};

export default connect(mapState, mapDispatch)(WithPopUp(ErrorPopup));
