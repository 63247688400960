import styled, { withTheme } from "styled-components";
import { darken } from "polished";

import CloseButton from "../ButtonXCircle";
import Alert from "../Alert";
import { ALERT_TYPES } from "../constants";
import { popUpHeaderHeight } from "../../hoc/WithPopUp/WithPopUp-container";
import Button from "../Button";

const HeaderBarView = ({
    setPopUpToDisplay,
    theme,
    errors,
    removeError,
    popupTitle,
    handleBack,
    handleAdd,
    customExitHandling, // optional
}) => {
    const buttonInHeader = handleAdd ? true : handleBack ? true : false;
    return (
        <div>
            <HeaderBar popUpHeaderHeight={popUpHeaderHeight}>
                <HeaderContent buttonInHeader={buttonInHeader}>
                    {handleAdd && (
                        <BtnWrapper>
                            <Button
                                handleClick={handleAdd}
                                textSize={"1rem"}
                                secondaryBtnColors={true}
                                icon={<i className="fas fa-plus-circle"></i>}
                            >
                                Add
                            </Button>
                        </BtnWrapper>
                    )}
                    {handleBack && (
                        <BtnWrapper>
                            <Button
                                handleClick={handleBack}
                                textSize={"1rem"}
                                icon={
                                    <i className="fas fa-angle-double-left"></i>
                                }
                                bgColor={theme.colors.secondary}
                                textColor={theme.colors.primary}
                            >
                                Back
                            </Button>
                        </BtnWrapper>
                    )}
                    <TitleWrapper buttonInHeader={buttonInHeader}>
                        {popupTitle && <PopupTitle>{popupTitle}</PopupTitle>}
                    </TitleWrapper>
                </HeaderContent>
                <CloseBtnWrapper>
                    <CloseButton
                        handleClick={() => {
                            if (!customExitHandling) {
                                setPopUpToDisplay("");
                            } else {
                                customExitHandling();
                            }
                        }}
                        circleDiameter={"2.3rem"}
                        fontSize={"1.5rem"}
                        bgColor={darken(0.1, theme.colors.primary)}
                        iconColor={theme.colors.secondary}
                    ></CloseButton>
                </CloseBtnWrapper>
            </HeaderBar>
            {errors.message && (
                <ErrorRow>
                    <ErrorWrapper>
                        <Alert
                            message={errors.message}
                            type={ALERT_TYPES.ERROR}
                            removeError={removeError}
                            noBorderRadius={true}
                            hideBorder={true}
                        />
                    </ErrorWrapper>
                </ErrorRow>
            )}
        </div>
    );
};

export default withTheme(HeaderBarView);

// Contains title and X button
const HeaderBar = styled.div`
    min-height: ${(props) => props.popUpHeaderHeight};
    border-bottom: 1px solid ${(props) => props.theme.colors.offPrimary};
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 0;
`;

const PopupTitle = styled.h5`
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const CloseBtnWrapper = styled.button`
    position: absolute;
    right: 0.7rem;
`;

// row containing upload error
const ErrorRow = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ErrorWrapper = styled.div`
    flex: 1;
`;

const HeaderContent = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    min-width: 0;
    /* Add padding to the right of HeaderContent if a button is present (so title appears centered) */
    padding: ${(props) =>
        props.buttonInHeader ? "0 6rem 0 1rem" : "0 3rem 0 1rem"};
`;

const TitleWrapper = styled.div`
    flex: 1 1 200px;
    display: flex;
    justify-content: center;
    min-width: 0;
    @media (max-width: ${({ theme }) => theme.sizes.xsViewWidth}) {
        display: ${(props) => (props.buttonInHeader ? "none" : "flex")};
    }
`;

const BtnWrapper = styled.div`
    flex: 0 1 150px;
`;
