import { useState } from "react";
import styled from "styled-components";

import ExitButton from "../common/ButtonCircleIcon";
import { ArrowLeftIcon, ArrowRightIcon, CloseIcon } from "../common/Icon";

// Adds ability to display an image full screen on click.
// Renders full screen components if users set a state with an images path by clicking the image in WrappedComponent
const withFullScreenClick = (WrappedComponent) => (props) => {

    // Stores array of images, array of one item will just display one image
    const [imgArray, setImgArray] = useState([]);
    // stores index of image currently being viewed
    const [displayImgIndex, setDisplayImgIndex] = useState(undefined);

    // passed to child component for opening full screen view
    const openFullScreenImgView = (imgArray, displayImgIndex) => {
        setDisplayImgIndex(displayImgIndex);
        setImgArray(imgArray);
    };

    // handle exit button click
    const closeFullScreenView = (e) => {
        e.stopPropagation();
        setDisplayImgIndex(undefined);
        setImgArray([]);
    };

    // takes the direction argument (-1 or 1) and shifts the slider in that direction
    const handleArrowClick = (e, direction) => {
        const newSlideIndex =
            (displayImgIndex + direction + imgArray.length) %
            imgArray.length;
        setDisplayImgIndex(newSlideIndex);
    };

    const ImagesToRender = imgArray.map((img, i) => {
        return (
            <DisplayImg
                src={img}
                alt={img}
                show={i === displayImgIndex}
                key={i}
            />
        );
    });

    const firstImgInArr = displayImgIndex === 0;
    const lastImgInArr = displayImgIndex === imgArray.length - 1;

    // Passes wrapped component through with an added prop
    // When openFullScreenImgView state is set with an  array of images and the index of the image to view first the full screen view will be displayed
    return (
        <div>
            <WrappedComponent
                {...props}
                openFullScreenImgView={openFullScreenImgView}
            />
            {imgArray[0] && displayImgIndex !== undefined && (
                <FullScreenWrapper>
                    <ImgBox>
                        {ImagesToRender}
                        {imgArray.length > 1 && (
                            <ArrowBox positionInImgArray={displayImgIndex}>
                                <LeftArrowSideBar
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (!firstImgInArr) {
                                            handleArrowClick(e, -1);
                                        }
                                    }}
                                    show={!firstImgInArr}
                                >
                                    <ArrowCircle pointer={!firstImgInArr}>
                                        {/* <Arrow className="fas fa-arrow-left " /> */}
                                        <ArrowLeftIcon fontSize={"1.7rem"} />
                                    </ArrowCircle>
                                </LeftArrowSideBar>
                                <RightArrowSideBar
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (!lastImgInArr) {
                                            handleArrowClick(e, +1);
                                        }
                                    }}
                                    show={!lastImgInArr}
                                >
                                    <ArrowCircle pointer={!lastImgInArr}>
                                        {/* <Arrow className="fas fa-arrow-right " /> */}
                                        <ArrowRightIcon fontSize={"1.7rem"} />
                                    </ArrowCircle>
                                </RightArrowSideBar>
                            </ArrowBox>
                        )}
                    </ImgBox>
                    <ExitButtonWrapper>
                        <ExitButton
                            handleClick={closeFullScreenView}
                            circleDiameter={"3rem"}
                            fontSize={"2rem"}
                            bgColor={"black"}
                        >
                            <CloseIcon />
                        </ExitButton>
                    </ExitButtonWrapper>
                </FullScreenWrapper>
            )}
        </div>
    );
};

export default withFullScreenClick;

const FullScreenWrapper = styled.div`
    position: fixed;
    z-index: 101; /* Above navbar */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.secondary};
    padding: 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ExitButtonWrapper = styled.div`
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 3;
`;

const ImgBox = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const DisplayImg = styled.img`
    position: absolute; /* must be absolute since multiple images are being mounted on top of each other with only one visible to user at a time */
    width: 100%;
    height: 100%;
    visibility: ${(props) => (props.show ? "visible" : "hidden")};
    transition: opacity 0.5s linear;
    object-fit: contain;
`;

const ArrowSideBar = styled.div`
    /* sidebar width and height effectively */
    padding: 0 1rem;
    height: 100%;
    /* position arrow in center of sidebar horizontally and vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    /* Transparent sidebar around arrowclick, darker on hover  */
    background-color: ${(props) => `${props.theme.colors.secondary}05`};
    transition: background-color ease-in 0.1s;
    &:hover {
        background-color: ${(props) => `${props.theme.colors.secondary}25`};
    }
`;

// Arrows hidden when no images next in array
const LeftArrowSideBar = styled(ArrowSideBar)`
    /* display: ${(props) => (props.show ? "flex" : "none")}; */
    opacity: ${(props) => (props.show ? 1 : 0)};
    transition: opacity 0.1s linear;
    cursor: ${(props) => (props.show ? "pointer" : "default")};
`;
const RightArrowSideBar = styled(ArrowSideBar)`
    /* display: ${(props) => (props.show ? "flex" : "none")}; */
    opacity: ${(props) => (props.show ? 1 : 0)};
    transition: opacity 0.1s linear;
    cursor: ${(props) => (props.show ? "pointer" : "default")};
`;

// Fills image so arrows can be positioned within image
export const ArrowBox = styled.div`
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ArrowCircle = styled.div`
    background-color: ${(props) => props.theme.colors.primary};
    opacity: 0.7;
    border-radius: 50%;
    cursor: ${(props) =>
        props.pointer
            ? "pointer"
            : "default"}; /* Only show pointer if arrow is shown */
    height: 3rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /* Increase arrow size on hover */
    &:hover {
        opacity: 1;
        transform: scale(1.1);
    }
    transition: transform ease-in 0.1s;
`;