import styled, { withTheme } from "styled-components";

const Spinner = ({ theme, color = theme.colors.offSecondary }) => (
    <StyledSpinner viewBox="0 0 100 100" color={color}>
        <circle
            className="path"
            cx="50"
            cy="50"
            r="40"
            fill="none"
            strokeWidth="20"
        />
    </StyledSpinner>
);

export default withTheme(Spinner);

const StyledSpinner = styled.svg`
    animation: rotate 1s linear infinite;
    margin: 0 0 0 0;
    /* width: 100%;
    height: 100%; */

    & .path {
        stroke: ${({ color }) => color};
        stroke-linecap: round;
        animation: dash 3s ease-in-out infinite;
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes dash {
        0% {
            stroke-dasharray: 10, 260;
            stroke-dashoffset: -10;
        }
        50% {
            stroke-dasharray: 150, 200;
            stroke-dashoffset: -30;
        }
        100% {
            stroke-dasharray: 300, 360;
            stroke-dashoffset: -50;
        }
    }
`;
