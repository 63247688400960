import { createContext, useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { io } from "socket.io-client";
import { SERVER_URL } from "../library/common/constants";
import { sortChatsByDate } from "../library/utilities";

// ************************************************
// Socket.io events (NOTE: shared with server)
// ************************************************

const IO_EVENTS = {
    NEW_NOTIFICATIONS: "NEW NOTIFICATIONS",
    UPDATE_NOTIFICATIONS_SEEN: "UPDATE NOTIFICATIONS SEEN",
    REMOVE_NOTIFICATION: "REMOVE NOTIFICATION",
    CLEAR_NOTIFICATIONS: "CLEAR NOTIFICATIONS",
    NEW_CONVERSATIONS: "NEW CONVERSATIONS",
    UPDATE_CONVERSATION_SEEN: "UPDATE CONVERSATION SEEN",
    UPDATE_CONVERSATION_SEEN_CLIENT: "UPDATE CONVERSATION SEEN CLIENT",
    REMOVE_CONVERSATION: "REMOVE CONVERSATION",
};

// ************************************************

export const useIO = ({ appLoaded = true } = { appLoaded: true }) => {

    const [notifications, setNotifications] = useState([]);
    const [notificationsLoading, setNotificationsLoading] = useState(true);

    const [conversations, setConversations] = useState([]);
    const [conversationsLoading, setConversationsLoading] = useState(true);

    const socketRef = useRef();
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        (async () => {
            if (appLoaded) {
                // checks if app is loading first seeing as it is called in AppScreen
                const token = await getAccessTokenSilently();
                // create websocket connection
                socketRef.current = io(SERVER_URL, {
                    auth: { token: `Bearer ${token}` },
                });

                socketRef.current
                    // handle connection error
                    // .on("connect_error", (error) => {
                    //     if (error.data.type === 'UnauthorizedError') {
                    //         console.log('User token has expired');
                    //     }
                    // })

                    // ************************************************
                    // Notification Listeners
                    // ************************************************
                    // listen for incoming notifications
                    .on(
                        IO_EVENTS.NEW_NOTIFICATIONS,
                        (notificationArray) => {
                            setNotificationsLoading(false);
                            setNotifications(f => [
                                ...notificationArray,
                                // filter out any duplicates; new notifications overwrite existing ones
                                ...f.filter(el => !notificationArray.some(
                                    ntf => ntf._id === el._id
                                )),
                            ]);
                        })

                    .on(
                        IO_EVENTS.REMOVE_NOTIFICATION,
                        (notificationId) => {
                            setNotifications(f => [
                                ...f.filter(el =>
                                    el._id !== notificationId)
                            ]);
                        }
                    )

                    // ************************************************
                    // Conversation Listeners
                    // ************************************************
                    // listen for a new incoming conversation
                    .on(
                        IO_EVENTS.NEW_CONVERSATIONS,
                        (conversationArray) => {
                            setConversations(f =>
                                sortChatsByDate([
                                    ...conversationArray,
                                    // add old state but filter out any conversation in conversationArray
                                    ...f.filter(el => !conversationArray.some(
                                        convo => convo._id === el._id
                                    )),
                                ])
                            );
                            setConversationsLoading(false);
                        }
                    )

                    .on(
                        IO_EVENTS.UPDATE_CONVERSATION_SEEN_CLIENT,
                        (convoId, userId, newDate) => {
                            setConversations(f => f.map(convo => convo._id === convoId ?
                                {
                                    ...convo,
                                    members: convo.members.map(user => user.userId === userId ?
                                        {
                                            ...user,
                                            lastSeen:
                                                newDate,
                                        } :
                                        user
                                    ),
                                } :
                                convo
                            ));
                        }
                    )

                    .on(IO_EVENTS.REMOVE_CONVERSATION, (convoId) => {
                        setConversations(f => f.filter(convo => convo._id !== convoId));
                    });
                // removes socketRef when connection is closed
                return () => {
                    socketRef.current.disconnect();
                };
            }
        })();
    }, [getAccessTokenSilently, appLoaded]);

    // update all notifications in the array to "seen" status
    const updateNotificationsSeen = () => {
        const notificationsToBeUpdated = notifications
            .filter(ntf => !ntf.hasBeenSeen)
            .map(ntf => ntf._id);

        if (notificationsToBeUpdated.length > 0) {
            socketRef.current.emit(
                IO_EVENTS.UPDATE_NOTIFICATIONS_SEEN,
                notificationsToBeUpdated
            );
        }

        setNotifications(f => f.map(
            notification => ({ ...notification, hasBeenSeen: true })
        ));
    };

    // delete ALL notifications for currentUser
    const clearNotifications = () => {
        socketRef.current.emit(IO_EVENTS.CLEAR_NOTIFICATIONS);
        setNotifications([]);
    };

    // update conversation seen
    const updateConversationSeen = (convoId, userId) => {
        socketRef.current.emit(
            IO_EVENTS.UPDATE_CONVERSATION_SEEN,
            convoId,
            userId
        );
        setConversations(f => f.map(convo => convo._id === convoId ?
            {
                ...convo,
                members: convo.members.map(user => user.userId === userId ?
                    {
                        ...user,
                        lastSeen: new Date(),
                    }
                    : user
                ),
            } :
            convo
        ));
    };

    return {
        notifications,
        notificationsLoading,
        updateNotificationsSeen,
        clearNotifications,

        conversations,
        conversationsLoading,
        updateConversationSeen,
        setConversations,
    };
};

const SocketContext = createContext(useIO);
export default SocketContext;