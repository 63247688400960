import styled from "styled-components";

// App logo is currently text, text can have two sizes ome for xs (phone size) screen and one for the rest.
const AppLogo = ({
    smallScreenLogoHeight = "100%",
    largerScreenLogoHeight = "100%",
}) => {
    return (
        <Wrapper>
            {/* <TextBooms {...textProps}>BOOMS</TextBooms>
            <TextUp {...textProps}>UP</TextUp> */}
            <LogoImg
                src={`${window.location.origin}/BoomsUpFullWide.png`}
                alt={"BoomsUp"}
                smallScreenLogoHeight={smallScreenLogoHeight}
                largerScreenLogoHeight={largerScreenLogoHeight}
            />
        </Wrapper>
    );
};

export default AppLogo;

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
`;

// const TextBooms = styled.text`
//     font-family: Goldman-Bold;
//     font-weight: 700;
//     color: ${(props) => props.logoColors.LHS};
//     @media (max-width: ${({ theme }) => theme.sizes.xsViewWidth}) {
//         font-size: ${(props) => props.smallScreenLogoHeight}px;
//     }
//     font-size: ${(props) => props.largerScreenLogoHeight}px;
// `;

// const TextUp = styled(TextBooms)`
//     color: ${(props) => props.logoColors.RHS};
// `;

const LogoImg = styled.img`
    object-fit: contain;
    height: 100%;
    width: 100%;
    max-height: ${(props) => props.largerScreenLogoHeight}px;
    @media (max-width: ${({ theme }) => theme.sizes.xsViewWidth}) {
        max-height: ${(props) => props.smallScreenLogoHeight}px;
    }
`;
