// import { useEffect } from "react";

import { useApi } from "../../services/api";

const StoreLoader = ({ currentUser, setMySites }) => {
    // const { data } = useApi({
    //     method: "get",
    //     url: `/api/users/${currentUser.user.id}/sites`,
    // });

    // useEffect(() => {
    //     // If there's data, process it
    //     if (data) {
    //         setMySites(data);
    //     }
    // }, [data, setMySites]);
    // return <div></div>;

    useApi({
        method: "post",
        url: "/api/sites/find",
        data: { sitesWithCrewMember: currentUser.user.id },
        callback: (res) => { if (res) { setMySites(res); } },
    });
    return null;
};

export default StoreLoader;
