import EditProfileView from "./EditProfileDetails-view";

import TextEdit from "../../library/common/popups/TextEdit";
import ProfileImageEdit from "../../library/common/popups/ProfileImageEdit";
import {
    REACT_APP_DEFAULT_COMPANY_COVER,
    REACT_APP_DEFAULT_COMPANY_PROFILE,
    USER_POPUP_TYPES,
    USER_PROFILE_VALIDATION_RULES,
} from "../../library/common/constants";
import LicenseEdit from "./AboutPage/LicenseEdit";
import WorkExperienceEdit from "./AboutPage/WorkExperienceEdit";
import ServicesEdit from "../../library/common/popups/ServicesEdit";
import ConxList from "../../library/common/popups/ConxList";

// PopUpRouterContainer uses popUpToDisplay to decide which popup to render. All other props just passed through to the respective popups
const PopUpRouterContainer = ({
    popUpToDisplay,
    userDetails,
    removeError,
    addError,
    errors,
    setPopUpToDisplay,
    setUserDetails,
    refreshDataFromServer,
    serverPaths,
}) => {
    // define text fields of userDetails that will be passed to TextEditPopUp (i.e. kick out file fields)
    const {
        displayPicture,
        coverPhoto,
        familyName,
        givenName,
        username,
        workPhone,
        workEmail,
        expertise,
        licences,
        workExperience,
    } = userDetails;
    // Destructure server paths to be used in the various popups
    const {
        addProfilePicPath,
        deleteProfilePicPath,
        addCoverPicPath,
        deleteCoverPicPath,
        serverPath,
    } = serverPaths;

    // TODO make sure you have a way for user to change password and email account settings somewhere in app - might be here but not sure where it would live, might be better in navbar as "change password"
    if (popUpToDisplay === USER_POPUP_TYPES.header) {
        return (
            <TextEdit
                popupTitle={"Your Details"}
                parentState={{
                    username,
                    familyName,
                    givenName,
                    workPhone,
                    workEmail,
                }}
                addError={addError}
                errors={errors}
                setPopUpToDisplay={setPopUpToDisplay}
                setParentState={setUserDetails}
                serverPath={serverPath}
                FormFieldsToRender={EditProfileView}
                profileValidationRules={USER_PROFILE_VALIDATION_RULES}
            />
        );
    } else if (popUpToDisplay === USER_POPUP_TYPES.expertise) {
        return (
            <ServicesEdit
                services={expertise}
                servicesName={"expertise"}
                addError={addError}
                errors={errors}
                setPopUpToDisplay={setPopUpToDisplay}
                refreshDataFromServer={refreshDataFromServer}
                serverPath={serverPath}
                recommendationServerPath={serverPaths.expertiseRecommendations}
            />
        );
    } else if (popUpToDisplay === USER_POPUP_TYPES.displayPicture) {
        return (
            <ProfileImageEdit
                popupTitle={"Profile Picture"}
                type={USER_POPUP_TYPES.displayPicture}
                imgDetails={displayPicture}
                addError={addError}
                errors={errors}
                refreshParentState={refreshDataFromServer}
                setPopUpToDisplay={setPopUpToDisplay}
                addImgPath={addProfilePicPath}
                deleteImgPath={deleteProfilePicPath}
                defaultImg={REACT_APP_DEFAULT_COMPANY_PROFILE}
            />
        );
    } else if (popUpToDisplay === USER_POPUP_TYPES.coverPhoto) {
        return (
            <ProfileImageEdit
                popupTitle={"Cover Photo"}
                type={USER_POPUP_TYPES.coverPhoto}
                imgDetails={coverPhoto}
                addError={addError}
                errors={errors}
                refreshParentState={refreshDataFromServer}
                setPopUpToDisplay={setPopUpToDisplay}
                addImgPath={addCoverPicPath}
                deleteImgPath={deleteCoverPicPath}
                defaultImg={REACT_APP_DEFAULT_COMPANY_COVER}
            />
        );
    } else if (popUpToDisplay === USER_POPUP_TYPES.licences) {
        return (
            <LicenseEdit
                licences={licences}
                addError={addError}
                removeError={removeError}
                errors={errors}
                setPopUpToDisplay={setPopUpToDisplay}
                refreshDataFromServer={refreshDataFromServer}
                addLicenceOnly={false}
            />
        );
    } else if (popUpToDisplay === USER_POPUP_TYPES.addLicence) {
        return (
            <LicenseEdit
                licences={licences}
                addError={addError}
                removeError={removeError}
                errors={errors}
                setPopUpToDisplay={setPopUpToDisplay}
                refreshDataFromServer={refreshDataFromServer}
                addLicenceOnly={true}
            />
        );
    } else if (popUpToDisplay === USER_POPUP_TYPES.workExperiences) {
        return (
            <WorkExperienceEdit
                workExperience={workExperience}
                addError={addError}
                removeError={removeError}
                errors={errors}
                setPopUpToDisplay={setPopUpToDisplay}
                refreshDataFromServer={refreshDataFromServer}
            />
        );
    } else if (popUpToDisplay === USER_POPUP_TYPES.conxList) {
        return (
            <ConxList
                userId={userDetails._id}
                handleDone={() => setPopUpToDisplay("")}
                popupTitle={`${givenName}'s Friends`}
                setPopUpToDisplay={setPopUpToDisplay}
            />
        );
    } else {
        return <div></div>;
    }
};

export default PopUpRouterContainer;
