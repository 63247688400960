import { useState, useCallback } from "react";
import styled from "styled-components";
import ReactDOMServer from "react-dom/server";

import { useApi } from "../../../services/api";
import {
    MAP_ICON_BOBCAT_O,
    MAP_ICON_BOBCAT_Y,
    MAP_ICON_DIGGER_O,
    MAP_ICON_DIGGER_Y,
} from "../constants";

import LoadingDots from "../LoadingDots";
import Map from "../Map";
import SiteInfoWindow from "../MapSiteInfoWindow";
import NoDetailsScreen from "../NoDetailsView";
import ErrorPopup from "../popups/ErrorPopup";

import { addSiteInfoWindowListener } from "../../utilities";

const ProfileSiteMap = ({ dataFetchRoute, theme, profileName, history }) => {
    const [serverError, setServerError] = useState("");

    const { data, isLoading } = useApi({
        method: "get",
        url: dataFetchRoute,
        errorCallback: setServerError,
    });

    // const [infoWindowSiteId, setInfoWindowSiteId] = useState(undefined);

    const markerSize = 55;

    const addMarkersToMap = useCallback(
        ({ map, locationsArray, clearMarkers, addMarkerToMarkers }) => {
            clearMarkers(); // clear any markers already on map
            // setting up map to contain markers for all sites, set map bounds so all markers are shown, add click listener for each and custom icons
            const bounds = new window.google.maps.LatLngBounds();

            // create a single infoWindow that will be used by all markers
            // (so that clicking on a new marker will close other windows)
            const infoWindow = new window.google.maps.InfoWindow();

            map.addListener("click", function (event) {
                // Clear infoBar from screen on map click
                infoWindow.close();
                // setInfoWindowSiteId(undefined);
            });

            addSiteInfoWindowListener(infoWindow, locationsArray, history);

            const siteMarkers = [
                MAP_ICON_BOBCAT_O,
                MAP_ICON_DIGGER_Y,
                MAP_ICON_BOBCAT_Y,
                MAP_ICON_DIGGER_O,
            ];
            let markerIndex = 0;
            locationsArray.map((site, i) => {
                const marker = new window.google.maps.Marker({
                    map: map,
                    position: site.latLng,
                    icon: {
                        url: siteMarkers[markerIndex],
                        scaledSize: new window.google.maps.Size(
                            markerSize,
                            markerSize
                        ),
                    },
                });

                // Adds 1 to marker index or resets to 0 when siteMarkers.length reached
                markerIndex < siteMarkers.length - 1
                    ? (markerIndex += 1)
                    : (markerIndex = 0);

                addMarkerToMarkers({
                    marker: marker,
                });
                // extend  bounds object to include site
                bounds.extend(site.latLng);

                // Add click listener
                marker.addListener("click", () => {
                    map.panTo(marker.getPosition()); // move map to center on pin
                    // define window content using ReactDOMServer to convert component to string for google setContent method
                    const content = ReactDOMServer.renderToString(
                        <SiteInfoWindow site={site} theme={theme} />
                    );
                    infoWindow.setContent(content);
                    infoWindow.open({ anchor: marker, shouldFocus: false });
                    // setInfoWindowSiteId(site.id);
                    // { anchor: marker, shouldFocus: false } options stops strange parent scroll behaviour
                });

                // console.log("site");
                // console.log(site);
                // onClickForInfoWindow({
                //     infoWindowId: "infoWindowId",
                //     infoWindow: infoWindow,
                //     handleClick: () => {
                //         console.log(site.id);
                //     },
                // });

                return marker;
            });

            // fit all markers onto map with padding
            const padding = 100;
            map.fitBounds(bounds, {
                left: padding,
                top: padding,
                right: padding,
                bottom: padding,
            });

            // .fitBounds() tends to result in map being too zoomed in.
            // Check map zoom and if its zoomed in to far, zoom out to maxStartingZoom level
            const listener = map.addListener("idle", () => {
                const maxStartingZoom = 13;
                if (map.getZoom() > maxStartingZoom)
                    map.setZoom(maxStartingZoom);
                window.google.maps.event.removeListener(listener);
            });
        },
        [theme, history]
    );

    let ScreenContent;
    if ((!data || data.length === 0) && isLoading) {
        // If not sites in store && isLoading show loading screen
        ScreenContent = <LoadingDots size={"20px"} />;
    } else if (!data || data.length === 0) {
        // No sites in sites in store and no longer loading then show no sites message
        ScreenContent = (
            <NoDetailsWrapper>
                <NoDetailsScreen
                    icon={<i className="fas fa-cubes"></i>}
                    message={`${profileName} doesn't have a site yet.`}
                />
            </NoDetailsWrapper>
        );
    } else {
        // else show the sites
        ScreenContent = (
            <InnerWrapper>
                <Map addMarkersToMap={addMarkersToMap} locationsArray={data} />
            </InnerWrapper>
        );
    }

    return (
        <Wrapper>
            {ScreenContent}
            {serverError && ( // Server error popup
                <ErrorPopup
                    removeError={() => setServerError("")}
                    customExitHandling={() => setServerError("")}
                />
            )}
        </Wrapper>
    );
};

export default ProfileSiteMap;

const Wrapper = styled.div`
    padding: 1rem 0.5rem;
    width: 100%;
    height: 80vh;
    position: relative;
    /* centers loading dots && No Details message */
    display: flex;
    align-items: center;
    justify-content: center;
`;

const InnerWrapper = styled.div`
    position: relative;
    height: 100%;
    width: min(100%, 600px); /* Map width limited to 600px */
    overflow: hidden;
    border-radius: 0.5rem;
    border: 1px solid ${(props) => props.theme.colors.offPrimary};
    background-color: ${(props) => props.theme.colors.offPrimary};
`;

const NoDetailsWrapper = styled.div`
    /* Move up the no sites message */
    margin-top: -300px;
`;
