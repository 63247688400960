import { SET_SHOW_SIDEBAR } from "../actionTypes";

const sideBarReducer = (state = { show: true }, action) => {
    switch (action.type) {
        case SET_SHOW_SIDEBAR:
            return { show: action.showSideBar };
        default:
            return state; // initialisation
    }
};

export default sideBarReducer;
