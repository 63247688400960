import styled from "styled-components";

import Icon from "../../library/common/Icon";
import LetterBox from "../../library/common/LetterBox";
import LoadingDots from "../../library/common/LoadingDots";
import Map from "../../library/common/Map";
import NoDetailsScreen from "../../library/common/NoDetailsView";

const MySitesView = ({
    isLoading,
    mySites = [],
    addMarkersToMap,
    onSiteClick,
}) => {
    const siteItems = mySites.map((site, i) => (
        <SiteItemRow
            key={i}
            onClick={() => { onSiteClick(site._id); }}
        >
            <ImgSizeWrapper imgSize="80px">
                {site.img && (
                    <SiteImg src={site.img} alt={"unavailable"} />
                )}
                {!site.img && (
                    <LetterBoxWrapper>
                        <StyledLetterBox
                            letters={site.name[0]}
                            borderRadius={"0.5rem"}
                            fontSize={"3rem"}
                            fontWeight={500}
                        />
                    </LetterBoxWrapper>
                )}
            </ImgSizeWrapper>
            <SiteName>{site.name}</SiteName>
        </SiteItemRow>
    ));

    if ((mySites.length === 0) && isLoading) {
        // If not sites in store && isLoading show loading screen
        return (
            <Wrapper>
                <LoadingDots size={"20px"} />
            </Wrapper>
        );
    } else if (mySites.length === 0) {
        // No sites in sites in store and no longer loading then show no sites message
        return (
            <Wrapper>
                <NoDetailsScreen
                    icon={<Icon name="hammer" />}
                    message={"You don't have a site yet..."}
                />
            </Wrapper>
        );
    } else {
        return (
            <Wrapper>
                <SiteListWrapper>
                    <StickyWrapper>
                        {siteItems}
                    </StickyWrapper>
                </SiteListWrapper>
                <MapWrapper>
                    <Map
                        addMarkersToMap={addMarkersToMap}
                        locationsArray={mySites}
                    />
                </MapWrapper>
            </Wrapper>
        );
    };
};

export default MySitesView;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100vh - ${({ theme }) => theme.sizes.navHeight});
`;

const StickyWrapper = styled.div`
    position: sticky;
    top: ${({ theme }) => theme.sizes.navHeight};
    max-height: calc(100vh - ${({ theme }) => theme.sizes.navHeight});
    overflow-y: auto;
    padding: 0 1.5rem;
    @media (max-width: ${({ theme }) =>
        theme.sizes.smViewWidth}) {
        padding: 0rem;
    }
`;

const SiteListWrapper = styled.div`
    flex: 0 1 400px;
    min-width: 0;
    @media (max-width: ${({ theme }) =>
        theme.sizes.smViewWidth}) {
        flex: 1; /* Takes full width as map disappears on small screens*/
    }
`;

const SiteItemRow = styled.div`
    display: flex;
    align-items: center;
    padding: 0.7rem;
    margin: 1.5rem 0 0 0;
    cursor: pointer;
    border-radius: 0.5rem;
    background-color: ${(props) => props.theme.colors.primary};
    @media (max-width: ${({ theme }) =>
        theme.sizes.smViewWidth}) {
        padding: 1rem 0.5rem;
        margin: 0.7rem 0 0 0;
    }
`;

const MapWrapper = styled.div`
    flex: 1;
    height: 100%;
    background-color: ${(props) => props.theme.colors.offPrimary};
    @media (max-width: ${({ theme }) =>
        theme.sizes.smViewWidth}) {
        display: none;
    }
`;

const ImgSizeWrapper = styled.div`
    position: relative;
    min-width: ${(props) => props.imgSize};
    padding-top: ${(props) => props.imgSize};
    border-radius: 0.5rem;
`;

const SiteImg = styled.img`
    position: absolute;
    object-fit: cover;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    border: 1px solid ${(props) => props.theme.colors.offPrimary};
`;

const SiteName = styled.h4`
    margin: 0;
    padding-left: 1rem;
    font-weight: 500;
    /* Cut off any names longer than width available with ellipsis's*/
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const LetterBoxWrapper = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
`;

const StyledLetterBox = styled(LetterBox)`
    background-color: ${(props) => props.theme.colors.secondary};
`;