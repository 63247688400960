import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import MessagesView from "./Messages-view";
import SocketContext from "../../services/socket";

import {
    CHAT_POPUP_TYPES,
    FILE_UPLOAD_CONFIG,
    USER_STATUS,
} from "../../library/common/constants";

import {
    addImagesToState,
    packStateInFormDataObj,
    checkForUnreadMsgInChat,
    ItemsInArrayOneNotArrayTwo,
    sortChatsByDate,
} from "../../library/utilities";

import { useApi } from "../../services/api";

const MessagesContainer = ({
    currentUser,
    history,
    location,
    errors,
    addError,
    removeError,
    setShowSidebar,
    // newChat, // can be an obj with userId, givenName, username, and img
    // messagesSocketState,
}) => {

    const useIO = useContext(SocketContext);
    const {
        conversations,
        setConversations,
        updateConversationSeen,
        conversationsLoading,
    } = useIO();

    const [validationFailedState, setValidationFailedState] = useState({});
    const [popUpToDisplay, setPopUpToDisplay] = useState("");

    // ******************************************************
    // get message states from props
    // ******************************************************

    const [newMessage, setNewMessage] = useState({
        message: "",
        images: [],
        files: [],
    });
    const [chatDetails, setChatDetails] = useState({
        members: [],
        messages: [],
        isNewChat: true,
    });

    const clearNewMessage = () => setNewMessage({
        message: "",
        images: [],
        files: [],
    });
    const clearChatDetails = () => setChatDetails({
        members: [],
        messages: [],
        isNewChat: true,
    });

    const { message_id: messageId } = useParams();
    const isNewMessage = messageId === "new";

    const { state: locationState = {} } = location;
    const { newChat = isNewMessage } = locationState;

    // const {
    //     conversations,
    //     setConversations,
    //     updateConversationSeen,
    //     conversationsLoading,
    // } = messagesSocketState;

    // add or remove a convoDoc to/from the conversations socket state
    const updateConvoSocketState = useCallback((convo) => {
        // current convo will be removed from the former state
        // if currentUser is still in the convo,
        // add the updated convo to the new state
        const convoToAdd = (convo.members
            .map(user => user.userId)
            .includes(currentUser.user.id)
        ) ? [convo] : [];
        setConversations(f =>
            sortChatsByDate(
                // remove convo from former state
                f.filter(el => el._id !== convo._id).concat(convoToAdd)
            )
        );
    }, [currentUser.user.id, setConversations]);

    // ******************************************************
    // useApi hook to get more conversations in list
    // ******************************************************

    // const {
    //     isLoading: isLoadingMoreConvos,
    //     refresh: loadMoreConvos,
    // } = useApi({
    //     method: "post",
    //     url: "/api/conversations/load_more",
    //     callback: (res) => { if (res) setConversations(f => [...f, ...res]); }
    // });

    // ******************************************************
    // useApi hook to get conversation data
    // ******************************************************

    const {
        isLoading: showDetailsLoadingDots,
    } = useApi({
        method: "get",
        url: messageId && !isNewMessage ? `/api/conversations/${messageId}` : null,
        callback: (res) => { if (res) updateConvoSocketState(res); },
    });

    // ******************************************************
    // useApi hook to create or update conversations
    // ******************************************************

    const {
        isLoading: isUpdatingConvoData,
        refresh: updateConversation,
    } = useApi({
        callback: (res) => {
            if (res) {
                updateConvoSocketState(res);
                // these are user-initiated changes, so the user will be 
                // redirected to the convo if they are still in it
                if (res.members
                    .map(user => user.userId)
                    .includes(currentUser.user.id)
                    &&
                    res._id !== messageId
                ) history.push(`/messages/${res._id}`);
            }
        }
    });

    // ******************************************************
    // useApi hook to search for a conversation using userIds
    // ******************************************************

    const {
        // data: convoFound,
        isLoading: isSearchingForConvo,
        refresh: searchForConvo,
    } = useApi({
        method: "post",
        url: "/api/conversations/check_user_array",
        data: (newChat && newChat.userId) ?
            { memberIdArray: [newChat.userId] } :
            {},
        callback: (res) => {
            if (res && res._id) history.push(`/messages/${res._id}`);
        },
    });

    useEffect(() => {
        if (!isSearchingForConvo) setPopUpToDisplay("");
    }, [isSearchingForConvo]);

    // ******************************************************
    // useEffects
    // ******************************************************

    // when component re-renders, clear message and chat
    useEffect(() => {
        clearNewMessage();
        clearChatDetails();
    }, []);

    // when component loads, hide sidebar
    useEffect(() => {
        setShowSidebar(false); // don't show sidebar
        return () => setShowSidebar(true); // show sidebar again on unmount    
    }, [setShowSidebar]);

    // when conversations, conversationsLoading, or messageId changes,
    useEffect(() => {

        if (newChat) {
            if (newChat.userId) {
                setChatDetails(f => ({ ...f, members: [newChat] }));
            } else clearChatDetails();
        } else if (!conversationsLoading) {
            // otherwise, wait for conversations to load

            if (!messageId) {
                // if there is no messageId and it is not a newChat,
                // then set to latest message if loaded
                setChatDetails(conversations[0]);
            } else {
                // otherwise, see if messageId exists for the currentUser
                const conversationToLoad = conversations.find(
                    el => el._id === messageId
                );
                if (conversationToLoad) {
                    // if it does, load it
                    setChatDetails(conversationToLoad);
                } else {
                    // otherwise, push to default
                    history.push(`/messages`);
                }
            }

        }
    }, [
        conversations,
        conversationsLoading,
        messageId,
        history,
        newChat,
    ]);

    // when chatDetails changes, check for unread messages
    // if there are any, update conversationSeen and chatDetails
    useEffect(() => {
        if (
            chatDetails &&
            chatDetails.messages && // only run if the chat has messages
            checkForUnreadMsgInChat(chatDetails, currentUser.user.id)
        ) {
            updateConversationSeen(chatDetails._id, currentUser.user.id);
            setChatDetails((f) => ({
                ...f,
                members: f.members.map((el) =>
                    // update lastSeen date for current user after seen update sent
                    el.userId === currentUser.user.id
                        ? {
                            ...el,
                            lastSeen: new Date(),
                        }
                        : el
                ),
            }));
        }
    }, [
        chatDetails,
        updateConversationSeen,
        currentUser.user.id,
    ]);

    //==================== Chat List Props ====================//

    const handleNewChatBtn = () => {
        history.push(`/messages/new`);
        clearChatDetails();
        setPopUpToDisplay(CHAT_POPUP_TYPES.createNewChat);
    };

    const chatListProps = {
        // shared props
        newChat,
        // chat-list-only props
        messageId,
        handleNewChatBtn,
    };

    //==================== Chat Details Props ====================//

    // add extra fields to chatDetails before sending to view components
    const { members = [], messages = [] } = chatDetails;
    const currentUserIsChatAdmin = members.some(user =>
        user.userId === currentUser.user.id &&
        user.isAdmin === true &&
        user.status === USER_STATUS.ACTIVE
    );

    // adds a subset of members excluding currentUser and inactive members
    const otherMembers = members.filter(user => user.userId !== currentUser.user.id);
    const otherActiveMembers = otherMembers.filter(user => user.status !== USER_STATUS.INACTIVE);

    // adds date-time of the last message seen by each group member
    const membersWithLastMsgSeenDate = members.map(user => {
        const lastMsgSeenDate = Math.max(
            ...messages.filter(msg => msg.messageDate <= user.lastSeen)
                .map(msg => Date.parse(msg.messageDate)),
            0
        );
        return { ...user, lastMsgSeenDate };
    });

    // adds sender details and seen details to each message
    const messagesWithSendSeen = messages.map(msg => {

        const senderDetails = members.filter((el) => el.userId === msg.senderId)[0];
        const sentByCurrentUser = msg.senderId === currentUser.user.id;
        const seenByUsersArr = otherMembers.filter(el =>
            el.lastMsgSeenDate === Date.parse(msg.messageDate)
        );
        const lastSeenDate = seenByUsersArr.map(el => el.lastSeen)[0];

        return {
            ...msg,
            senderDetails,
            sentByCurrentUser,
            seenByUsersArr,
            lastSeenDate
        };
    });

    const chatDetailsWithExtraFields = {
        ...chatDetails,
        currentUserIsChatAdmin,
        otherActiveMembers,
        members: membersWithLastMsgSeenDate,
        messages: messagesWithSendSeen,
    };

    // ref used to check a message input is active on enter key event
    const newMessageRef = useRef(null);
    // Enter key listener to send message
    const keydownListener = (e) => {
        if (e.key === "Enter" && !!newMessageRef.current.contains(e.target)) {
            // On enter key event if message input is active then fire message submit handler
            e.preventDefault();
            handleSendMessage();
        }
    };

    // add listener
    useEffect(() => {
        document.addEventListener("keydown", keydownListener, true);
        return () => {
            document.removeEventListener("keydown", keydownListener, true);
        };
    });

    const handleGoBack = () => {
        history.goBack();
    };

    // updates new message state on text change events
    const handleNewMsgTextChange = (e) => {
        setNewMessage((f) => ({ ...f, message: e.target.value }));
    };

    const createNewChat = (formDataForSubmitting) => {
        updateConversation({
            method: "post",
            url: `/api/conversations/create_conversation`,
            data: formDataForSubmitting,
            ...FILE_UPLOAD_CONFIG,
        });
        clearNewMessage();
    };

    const sendMessageToChat = (formDataForSubmitting) => {
        updateConversation({
            method: "post",
            url: `/api/conversations/${chatDetails._id}/send`,
            data: formDataForSubmitting,
            ...FILE_UPLOAD_CONFIG
        });
    };

    // Packages messages data and calls relevant function (sending message to existing chat or creating new chat)
    const handleSendMessage = () => {
        if (
            newMessage.message.trim().length > 0 ||
            newMessage.images.length > 0
        ) {
            // Only send if message contains text or images
            const formDataForSubmitting = packStateInFormDataObj(
                {
                    ...newMessage,
                    messageText: newMessage.message,
                    memberIdArray: chatDetails.members
                        .map((el) => el.userId)
                        .concat(currentUser.user.id),
                },
                "images"
            );
            // Update state with messageSending === true for new message
            const messageSendingId = uuidv4();
            setChatDetails(f => ({
                ...f,
                messages: [
                    ...f.messages,
                    {
                        senderId: currentUser.user.id,
                        messageText: newMessage.message,
                        images: newMessage.images.map((img) => img.path),
                        messageDate: new Date().toISOString(),
                        messageSending: true,
                        sendingId: messageSendingId, // used to find and update message after sendRequest
                    },
                ],
            }));

            // Empty new message state
            // setNewMessage(emptyNewMessage);
            clearNewMessage();

            if (chatDetails._id) {
                // if new message is being send to an existing chat
                sendMessageToChat(formDataForSubmitting, messageSendingId);
            } else {
                createNewChat(formDataForSubmitting, messageSendingId);
            }
        }
    };

    // On file change - add images to state, uses utility function
    const onFileChange = (e) => {
        addImagesToState(
            e,
            setNewMessage,
            newMessage,
            "images",
            "multiple",
            setValidationFailedState, // TODO add ability to set validation failed state
            { initial: newMessage.images.length, max: 4 }
        );
    };

    const onImageDelete = (indexToDelete) => {
        setNewMessage((f) => ({
            ...f,
            files: f.files.filter((_, i) => i !== indexToDelete),
            images: f.images.filter((_, i) => i !== indexToDelete),
        }));
    };

    const chatDetailsProps = {
        // shared props
        conversationsLoading,
        setPopUpToDisplay,

        // chat-details-only props
        chatDetails: chatDetailsWithExtraFields,
        creatingChat: isUpdatingConvoData,
        newMessage,
        newMessageRef,
        showDetailsLoadingDots,
        validationFailedState,
        handleGoBack,
        handleNewMsgTextChange,
        handleSendMessage,
        onFileChange,
        onImageDelete,
    };

    //==================== Pop-up Props ====================//

    const sortedChatMembers = chatDetails.members
        .map(el => ({ ...el, isCurrentUser: el.userId === currentUser.user.id }))
        .sort((a, b) =>
            b.isCurrentUser - a.isCurrentUser || // currentUser first
            b.isAdmin - a.isAdmin // then admin users first
        );

    const handleChangeUsersInGroup = (updatedMembers, updatedCompaniesArr) => {
        const existingUsers = chatDetails.members.map((el) => ({
            ...el,
            _id: el.userId,
        }));
        const addedMembers = ItemsInArrayOneNotArrayTwo(
            updatedMembers,
            existingUsers
        );
        const removedMembers = ItemsInArrayOneNotArrayTwo(
            existingUsers,
            updatedMembers
        );
        if (addedMembers.length > 0 || removedMembers.length > 0) {
            // Only send to server if a user was added or removed
            updateConversation({
                method: "post",
                url: `/api/conversations/${chatDetails._id}`,
                data: { addedMembers, removedMembers },
            });
        };
    };

    const handleUpdateName = (name) => {
        updateConversation({
            method: "put",
            url: `/api/conversations/${messageId}`,
            data: { name },
        });
    };

    const handleLeaveGroup = () => {
        updateConversation({
            method: "post",
            url: `/api/conversations/${chatDetails._id}/leave_conversation`,
        });
    };

    const handleExit = () => {
        setPopUpToDisplay("");
        history.push(`/messages`);
    };

    // check if there is an existing chat with the memberIdArray
    // add companyId to conversations later
    const checkForExistingChat = useCallback((userArray) => {

        if (userArray.length === 0) history.push("/messages");

        const memberIdArray = userArray.map(user => user._id);
        if (userArray.length > 1) memberIdArray.push(currentUser.user.id);
        searchForConvo({ data: { memberIdArray } });

        const members = userArray.map(user => ({ _id: user.userId, ...user }));
        setChatDetails(f => ({ ...f, members }));

    }, [
        currentUser.user.id,
        history,
        searchForConvo
    ]);

    const navigateToUser = (userId) => {
        history.push(`/users/${userId}`);
    };

    const popupProps = {
        // shared props
        newChat,
        setPopUpToDisplay,
        // pop-up-only props
        chatDetails: chatDetailsWithExtraFields,
        errors,
        addError,
        removeError,
        popUpLoading: isUpdatingConvoData || isSearchingForConvo,
        popUpToDisplay,
        sortedChatMembers,
        handleChangeUsersInGroup,
        handleUpdateName,
        handleLeaveGroup,
        handleExit,
        checkForExistingChat,
        navigateToUser,
    };

    //==================== Other View Props ====================//

    // Constant used on small screen to define which component to render
    // If there no message id in params and no newChat prop then show list view only on small screens
    const listViewFocus = !messageId && !newChat;

    const viewProps = {
        listViewFocus,
        popUpToDisplay,
        chatListProps,
        chatDetailsProps,
        popupProps,
    };

    return <MessagesView {...viewProps} />;
};

export default MessagesContainer;