import moment from "moment";
import { useState, useEffect } from "react";
import styled from "styled-components";

import { handleTextChange } from "../../../../library/utilities";
import {
    COMPANY_SEARCH,
    EMPLOYMENT_TYPE,
    SECTION_TITLES,
} from "../../../../library/common/constants";
import { Wrapper } from "./ExperienceList-view";
import FooterBar from "../../../../library/common/PopupFooterBar";
import HeaderBar from "../../../../library/common/PopupHeaderBar";
import {
    ContentBox,
    InputRow,
    HalfWidthWrapper,
    FullWidthWrapper,
} from "../../../../resources/styles";
import InputGroup from "../../../../library/common/InputGroup";
import { INPUT_TYPES } from "../../../../library/common/constants";

// Renders the edit /add screen for user experience
const ExperienceDetailsView = ({
    addCompanyToState,
    validationFailedState,
    setExperienceDetails,
    experienceDetails,
    handleSubmit,
    handleBack,
    handleExperienceDelete,
    experienceScreen,
    handleSearchBoxStringChange,
    setPopUpToDisplay,
}) => {
    // currentEmployer true then don't show end date input. When its the user current employer no end date will be sent in request
    const [currentEmployer, setCurrentEmployer] = useState(true);
    useEffect(() => {
        if (experienceDetails.endDate) {
            // setCurrentEmployer false if their is an end date to this experience
            setCurrentEmployer(false);
        }
    }, [experienceDetails]);

    const todaysDate = moment().format("YYYY-MM-DD");
    const startDate = experienceDetails.startDate
        ? moment(experienceDetails.startDate).format("YYYY-MM-DD")
        : "";
    const endDate = experienceDetails.endDate
        ? moment(experienceDetails.endDate).format("YYYY-MM-DD")
        : "";

    return (
        <Wrapper>
            <HeaderBar
                setPopUpToDisplay={setPopUpToDisplay}
                popupTitle={SECTION_TITLES.workExperiences}
                handleBack={handleBack}
            />
            <ContentBox>
                <ContentWrapper>
                    <FullWidthWrapper>
                        <InputGroup
                            value={experienceDetails.companyName}
                            title={`Company`}
                            type={INPUT_TYPES.SEARCH_TEXT}
                            placeholder={`Company Name`}
                            required={true}
                            errorMessage={validationFailedState.companyName}
                            handleChange={addCompanyToState}
                            zIndex={2}
                            searchBoxProps={{
                                searchApiRequest: COMPANY_SEARCH,
                                updateStringInParent:
                                    handleSearchBoxStringChange,
                            }}
                        ></InputGroup>
                    </FullWidthWrapper>

                    <InputRow>
                        <HalfWidthWrapper>
                            <InputGroup
                                value={experienceDetails.jobTitle}
                                title={`Position`}
                                name={"jobTitle"}
                                type={INPUT_TYPES.TEXT}
                                placeholder={`Machine Operator`}
                                required={true}
                                errorMessage={validationFailedState.jobTitle}
                                handleChange={(e) =>
                                    handleTextChange(
                                        e,
                                        setExperienceDetails,
                                        experienceDetails
                                    )
                                }
                                description={`Job title e.g. Machine Operator, Site Engineer, Labourer etc`}
                            ></InputGroup>
                        </HalfWidthWrapper>
                        <HalfWidthWrapper spaceBetween={"2rem"}>
                            <InputGroup
                                title={`Start Date`}
                                type={INPUT_TYPES.DATE}
                                required={true}
                                errorMessage={validationFailedState.startDate}
                                name={"startDate"}
                                value={startDate}
                                handleChange={(e) =>
                                    handleTextChange(
                                        e,
                                        setExperienceDetails,
                                        experienceDetails
                                    )
                                }
                                dateInputProps={{
                                    max: todaysDate,
                                }}
                            />
                        </HalfWidthWrapper>
                    </InputRow>
                    <InputRow>
                        <HalfWidthWrapper>
                            <CheckBoxWrapper>
                                <InputGroup
                                    type={INPUT_TYPES.CHECKBOX}
                                    errorMessage={validationFailedState.endDate}
                                    value={currentEmployer}
                                    handleChange={() => {
                                        if (currentEmployer === true) {
                                            setCurrentEmployer(false);
                                        } else {
                                            delete experienceDetails.endDate;
                                            setCurrentEmployer(true);
                                        }
                                    }}
                                    checkboxInputProps={{
                                        label: "I currently work in this position",
                                    }}
                                />
                            </CheckBoxWrapper>
                        </HalfWidthWrapper>
                        <HalfWidthWrapper spaceBetween={"2rem"}>
                            {!currentEmployer && (
                                <InputGroup
                                    title={`Finish Date`}
                                    type={INPUT_TYPES.DATE}
                                    required={true}
                                    errorMessage={validationFailedState.endDate}
                                    name={"endDate"}
                                    value={endDate}
                                    handleChange={(e) =>
                                        handleTextChange(
                                            e,
                                            setExperienceDetails,
                                            experienceDetails
                                        )
                                    }
                                    dateInputProps={{
                                        min: startDate,
                                        max: todaysDate,
                                    }}
                                />
                            )}
                        </HalfWidthWrapper>
                    </InputRow>
                    <InputRow>
                        <SingleItemHalfWidth>
                            <InputGroup
                                name={"employmentType"}
                                value={experienceDetails.employmentType}
                                title={`Employment Type`}
                                type={INPUT_TYPES.SELECT}
                                errorMessage={
                                    validationFailedState.employmentType
                                }
                                handleChange={(e) =>
                                    handleTextChange(
                                        e,
                                        setExperienceDetails,
                                        experienceDetails
                                    )
                                }
                                description={`For example part time, full time an apprenticeship, self employed etc`}
                            >
                                <option value="">-</option>
                                <option value={`${EMPLOYMENT_TYPE.FULL_TIME}`}>
                                    Full Time
                                </option>
                                <option value={`${EMPLOYMENT_TYPE.PART_TIME}`}>
                                    Part Time
                                </option>
                                <option
                                    value={`${EMPLOYMENT_TYPE.SELF_EMPLOYED}`}
                                >
                                    Self Employed
                                </option>
                                <option value={`${EMPLOYMENT_TYPE.CONTRACT}`}>
                                    Contract
                                </option>
                                <option
                                    value={`${EMPLOYMENT_TYPE.APPRENTICESHIP}`}
                                >
                                    Apprenticeship
                                </option>
                                <option value={`${EMPLOYMENT_TYPE.INTERNSHIP}`}>
                                    Internship
                                </option>
                            </InputGroup>
                        </SingleItemHalfWidth>
                    </InputRow>
                </ContentWrapper>
            </ContentBox>
            <FooterBar handleDone={handleSubmit} doneLabel={"Save"} />
        </Wrapper>
    );
};

export default ExperienceDetailsView;

const ContentWrapper = styled.div`
    padding: 1rem 2rem 1rem 2rem;
`;

const CheckBoxWrapper = styled.div`
    padding: 1.3rem 0;
`;

const SingleItemHalfWidth = styled(HalfWidthWrapper)`
    max-width: 50%;
    padding-right: 1rem;
    @media (max-width: ${({ theme }) => theme.sizes.mdViewWidth}) {
        max-width: 100%;
        padding-right: 0rem;
    }
`;
