import { useState } from "react";

import moment from "moment";
import styled, { withTheme } from "styled-components";
import { lighten } from "polished";

import TickBox from "./TickBox";

import Button from "../../../library/common/Button";
import EditButton from "../../../library/common/ButtonEditCircle";
import TrashButton from "../../../library/common/ButtonTrashCircle";

export const LicenceItem = ({
    i,
    hasLicencePhoto,
    licenceName,
    issueDate,
    handleEditClick,
    handleLicenceDelete,
    theme,
    dark,
}) => {
    const btnSize = "2rem";
    const btnIconSize = "1rem";
    return (
        <LicenceRow key={i}>
            {handleEditClick && (
                <BtnCol>
                    <BtnWrapper>
                        <EditButton
                            handleClick={() => {
                                handleEditClick(i);
                            }}
                            circleDiameter={btnSize}
                            fontSize={btnIconSize}
                        />
                    </BtnWrapper>
                    <BtnWrapper>
                        {handleLicenceDelete && (
                            <TrashButton
                                handleClick={() => {
                                    handleLicenceDelete(i);
                                }}
                                bgColor={theme.colors.semantic.error}
                                iconColor={theme.colors.secondary}
                                circleDiameter={btnSize}
                                fontSize={btnIconSize}
                            />
                        )}
                    </BtnWrapper>
                </BtnCol>
            )}
            <TickBoxCol>
                <TickBox
                    showTick={hasLicencePhoto}
                    dark={dark}
                    size={"3.5rem"}
                />
            </TickBoxCol>
            <LicenceDetailsCol hasPhoto={hasLicencePhoto}>
                <Name>{licenceName}</Name>
                <IssuedDate>
                    <Issued>Issued:</Issued>
                    {issueDate ? moment(issueDate).format("L") : ""}
                </IssuedDate>
            </LicenceDetailsCol>
        </LicenceRow>
    );
};

// Render licences. Different functionality depending on if user is in edit mode or profile view
const LicenceItemsContainer = ({
    items,
    theme,
    handleEditClick,
    dark,
    handleLicenceDelete,
}) => {
    const [showAll, setShowAll] = useState(false);
    const limitedNumberLicences = 5; // max number licences show unless show all clicked
    const ProfileView = !handleEditClick; // handleEditClick is only truthy on edit mode

    const LicenceItemsToRender = [];
    items // sort first by issueDate (recently created top in list) then by hasLicencePhoto, licences with a photo appear first in list
        .sort((a, b) => b.issueDate - a.issueDate)
        .sort((a, b) =>
            !!a.hasLicencePhoto && !b.hasLicencePhoto
                ? -1
                : !a.hasLicencePhoto && !!b.hasLicencePhoto
                    ? 1
                    : 0
        );
    if (ProfileView) {
        // Limit number of licences shown on profile view
        items.forEach((license, i) => {
            const { hasLicencePhoto, licenceName, issueDate } = license;
            if (showAll || i < limitedNumberLicences) {
                LicenceItemsToRender.push(
                    <LicenceItem
                        key={i}
                        i={i}
                        hasLicencePhoto={hasLicencePhoto}
                        licenceName={licenceName}
                        issueDate={issueDate}
                        handleEditClick={handleEditClick}
                        handleLicenceDelete={handleLicenceDelete}
                        theme={theme}
                        dark={dark}
                    />
                );
            }
        });
    } else {
        items.forEach((license, i) => {
            const { hasLicencePhoto, licenceName, issueDate } = license;
            LicenceItemsToRender.push(
                <LicenceItem
                    key={i}
                    i={i}
                    hasLicencePhoto={hasLicencePhoto}
                    licenceName={licenceName}
                    issueDate={issueDate}
                    handleEditClick={handleEditClick}
                    handleLicenceDelete={handleLicenceDelete}
                    theme={theme}
                    dark={dark}
                />
            );
        });
    }
    return (
        <Wrapper>
            {LicenceItemsToRender}
            {ProfileView && items.length > limitedNumberLicences && (
                <BottomRow>
                    <ShowAllBtnWrapper>
                        <Button
                            handleClick={() => {
                                setShowAll(showAll ? false : true);
                            }}
                            textSize={"1rem"}
                            bgColor={theme.colors.body}
                            textColor={theme.colors.secondary}
                            noShadow={true}
                        >
                            {showAll ? "Show Less" : "Show All"}
                        </Button>
                    </ShowAllBtnWrapper>
                </BottomRow>
            )}
        </Wrapper>
    );
};

export default withTheme(LicenceItemsContainer);

const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
`;

const LicenceRow = styled.div`
    flex: 1 1 300px;
    display: flex;
    margin: 0.5rem 0rem 0.5rem 1rem;
    @media (max-width: ${({ theme }) => theme.sizes.mdViewWidth}) {
        margin: 0.5rem 0;
    }
`;

const TickBoxCol = styled.div`
    flex: 0 1 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LicenceDetailsCol = styled.div`
    padding: 0 0.5rem;
    /* margin: 0.3rem 0; */
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-style: ${(props) => (props.hasPhoto ? "normal" : "italic")};
    color: ${({ theme, hasPhoto }) =>
        hasPhoto
            ? theme.colors.secondary
            : lighten(0.3, theme.colors.secondary)};
`;

const Name = styled.h6`
    margin: 0;
`;

const IssuedDate = styled.div`
    font-weight: 300;
`;

const Issued = styled.span`
    font-weight: 400;
`;

const BtnCol = styled.div`
    /* flex: 0 1; */
    margin-right: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: ${({ theme }) => theme.sizes.mdViewWidth}) {
        margin-right: 0.5rem;
    }
`;

const BtnWrapper = styled.div`
    margin-top: 0.3rem;
`;

const BottomRow = styled.div`
    width: 100%;
    margin-top: 0.7rem;
    display: flex;
    justify-content: center;
`;

const ShowAllBtnWrapper = styled.div`
    flex: 0 1 300px;
`;
