// Renders input fields for header edit popup form
import styled from "styled-components";

import InputGroup from "../../library/common/InputGroup";
import { INPUT_TYPES } from "../../library/common/constants";
import {
    FullWidthWrapper,
    InputRow,
    HalfWidthWrapper,
} from "../../resources/styles";

// TODO website input needs some kind of a validation or check function for https:// or http:// protocol
const EditProfileDetailsView = ({
    formState,
    handleChange,
    validationFailedState,
}) => {
    const {
        name,
        quickDescription,
        phone,
        websiteURL,
    } = formState;

    const bioCharacterLimit = 180;
    return (
        <Wrapper>
            <FullWidthWrapper maxWidth={"300px"}>
                <InputGroup
                    type={INPUT_TYPES.TEXT}
                    handleChange={handleChange}
                    errorMessage={validationFailedState.name}
                    value={name}
                    name={"name"}
                    title={"Name"}
                    placeholder={"Company name"}
                    required={true}
                    small={"Your company name."}
                />
            </FullWidthWrapper>
            <FullWidthWrapper>
                <InputGroup
                    type={INPUT_TYPES.TEXT_AREA}
                    rows={"2"}
                    characterLimit={bioCharacterLimit}
                    handleChange={handleChange}
                    errorMessage={validationFailedState.quickDescription}
                    value={quickDescription}
                    name={"quickDescription"}
                    title={"Bio"}
                    description={`Short at a glance description of your business. This will appear at the top of the profile, below company name. Limited to ${bioCharacterLimit} characters.`}
                />
            </FullWidthWrapper>
            <InputRow>
                <HalfWidthWrapper>
                    <InputGroup
                        type={INPUT_TYPES.TEXT}
                        handleChange={handleChange}
                        errorMessage={validationFailedState.phone}
                        value={phone}
                        name={"phone"}
                        title={"Phone number"}
                        placeholder={"Phone number"}
                        required={true}
                    />
                </HalfWidthWrapper>
                <HalfWidthWrapper spaceBetween={"2rem"}>
                    <InputGroup
                        type={INPUT_TYPES.TEXT}
                        handleChange={handleChange}
                        errorMessage={validationFailedState.websiteURL}
                        value={websiteURL}
                        name={"websiteURL"}
                        title={"Website (include protocol e.g. https://)"}
                        placeholder={"www.example@domain.com"}
                    />
                </HalfWidthWrapper>
            </InputRow>
        </Wrapper>
    );
};

export default EditProfileDetailsView;

const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
    min-width: 700px;
    @media (max-width: ${({ theme }) => theme.sizes.mdViewWidth}) {
        /* Must remove min width or content will overflow on smaller screens */
        min-width: 0;
    }
`;
