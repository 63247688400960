import React from "react";
import { connect } from "react-redux";

import {
    LgScreenSidebar,
    criticalScreenSize,
} from "../../../main/routes/AppScreen";

// Generates a width placeholder using LgScreenSidebar in AppScreen and sidebar redux to determine if sidebar shown. Only needed for large screens as small screen sidebar is full width.
const SidebarPopupPlaceholderView = ({ sidebar }) => {
    return (
        <LgScreenSidebar
            showSidebar={sidebar.show}
            criticalScreenSize={criticalScreenSize}
        ></LgScreenSidebar>
    );
};

function mapState(state) {
    return {
        sidebar: state.sidebar,
    };
}

export default connect(mapState)(SidebarPopupPlaceholderView);
