import styled from "styled-components";

import HeaderBar from "../PopupHeaderBar";
import Button from "../Button";
import PopupLoadingScreen from "../PopupLoadingScreen";

// Popup screen made to be rendered in a popup with two actions
const ConfirmationScreenView = ({
    handleCancel,
    cancelText,
    handleSubmit,
    submitText,
    theme,
    title,
    message,
    loading,
    setPopUpToDisplay, // required, needed by withPopup hoc
    maxWidth, // optional default 600px
}) => {
    if (loading) {
        return <PopupLoadingScreen maxHeight={"300px"} maxWidth={"600px"} />;
    } else {
        return (
            <Wrapper maxWidth={maxWidth || "600px"}>
                <HeaderBar
                    setPopUpToDisplay={handleCancel}
                    popupTitle={title}
                />
                <Message>{message}</Message>
                <FooterBar>
                    <LeftBtnWrapper>
                        <Button
                            handleClick={handleCancel}
                            textSize={"1rem"}
                            noShadow={true}
                            secondaryBtnColors={true}
                            bgColor={theme.colors.semantic.info}
                            textColor={theme.colors.secondary}
                        >
                            {cancelText}
                        </Button>
                    </LeftBtnWrapper>
                    <RightBtnWrapper>
                        <Button
                            handleClick={handleSubmit}
                            textSize={"1rem"}
                            noShadow={true}
                            bgColor={theme.colors.semantic.error}
                            textColor={theme.colors.secondary}
                        >
                            {submitText}
                        </Button>
                    </RightBtnWrapper>
                </FooterBar>
            </Wrapper>
        );
    }
};

export default ConfirmationScreenView;

const Wrapper = styled.div`
    max-width: min(${(props) => props.maxWidth}, 95vw);
`;

const Message = styled.p`
    margin: 0;
    padding: 1.5rem 2rem;
    @media (max-width: ${({ theme }) => theme.sizes.smViewWidth}) {
        padding: 1rem;
    }
`;

const FooterBar = styled.div`
    display: flex;
    width: 100%; /* Match width of parent  */
    padding: 0 1rem 1rem 1rem;
`;

const RightBtnWrapper = styled.div`
    flex: 1;
`;

const LeftBtnWrapper = styled(RightBtnWrapper)`
    margin-right: 1rem;
`;
