import styled from "styled-components";

import SearchBox from "../SearchBox";
import Button from "../Button";
import { OptionWrapper, DropdownWrapper, Dropdown } from "./SingleRow-view";

const borderRadius = "0.5rem";

// Toggler is shown on a different line to searchBox (Need of component is to be shown on smaller screens)
const DoubleRowView = ({
    Ref,
    setShowDropdown,
    showDropdown,
    theme,
    searchType,
    searchTypes,
    searchTypeChange,
    dropdownMaxHeight,
    handleSelect,
    excludedResults,
    togglerMinWidth,
    autoFocus,
}) => {
    const DropdownOptions = [];
    searchTypes.forEach((type, i) => {
        if (type.apiRequestPath !== searchType.apiRequestPath) {
            DropdownOptions.push(
                <OptionWrapper
                    key={i}
                    onClick={() => {
                        setShowDropdown(false);
                        searchTypeChange(type.apiRequestPath);
                    }}
                >
                    {type.searchTitle}
                </OptionWrapper>
            );
        }
    });

    return (
        <Wrapper>
            <SearchBoxWrapper>
                <SearchBox
                    dropdownMaxHeight={
                        dropdownMaxHeight ? dropdownMaxHeight : "300px"
                    }
                    searchApiRequest={searchType}
                    addResultToParentState={handleSelect}
                    borderRadius={`${borderRadius}`}
                    clearInputOnSelect={true}
                    searchDescription={"Search"}
                    clearSearchOnOutsideClick={true}
                    reqBody={{
                        excludedResults: excludedResults
                            ? excludedResults[`${searchType.searchTitle}`]
                            : [],
                    }}
                    autoFocus={autoFocus}
                />
            </SearchBoxWrapper>
            <ToggleRow>
                <ToggleWrapper ref={Ref} width={togglerMinWidth}>
                    <ButtonWrapper>
                        <Button
                            icon={<i className="fas fa-caret-right"></i>}
                            handleClick={() =>
                                setShowDropdown(showDropdown ? false : true)
                            }
                            bgColor={theme.colors.secondary}
                            textColor={theme.colors.primary}
                            borderRadius={"0.8rem"}
                            noShadow={true}
                            justifyContent={"flex-start"}
                            btnPadding={"0 0.8rem"}
                        >
                            {searchType.searchTitle}
                        </Button>
                    </ButtonWrapper>
                    <DropdownWrapper>
                        {showDropdown && <Dropdown>{DropdownOptions}</Dropdown>}
                    </DropdownWrapper>
                </ToggleWrapper>
            </ToggleRow>
        </Wrapper>
    );
};

export default DoubleRowView;

const Wrapper = styled.div``;

const ToggleRow = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    z-index: 0;
`;

const ButtonWrapper = styled.div`
    z-index: 1;
`;

const ToggleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    min-width: ${(props) => props.width};
    /* align-items: flex-start; */
`;

const SearchBoxWrapper = styled.div`
    padding: 0.2rem 0;
    position: relative;
    z-index: 1;
`;
