import React from "react";
import styled from "styled-components";

import { useImgLoadStatus } from "../utilities";
import LoadingImgShimmer from "./LoadingShimmer";

const ImgTagWithLoadingContainer = ({
    objectFit, // required
    src, // required
    alt, // optional default "Error"
    imgLoadCallback, // optional function to run when image loaded
    verticalAlign, // optional vertical positioning of image
}) => {
    const { imgLoaded, onImgLoad } = useImgLoadStatus();

    const handleLoad = () => {
        if (imgLoadCallback) imgLoadCallback();
        onImgLoad();
    };
    // Loading shimmer image placeholder is used while image is mounting and then removed once mounted
    return (
        <ImageWrapper>
            {!imgLoaded && <LoadingImgShimmer />}
            <Img
                src={src}
                alt={alt || "Error"}
                imgLoaded={imgLoaded}
                onLoad={handleLoad}
                objectFit={objectFit}
                verticalAlign={verticalAlign}
            />
        </ImageWrapper>
    );
};

export default ImgTagWithLoadingContainer;

const ImageWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const Img = styled.img`
    /* position: absolute; */
    /* Not sure why really but absolute appears to be needed or else little gabs can form between picture and ImageWrapper */
    width: 100%;
    height: 100%;
    object-fit: ${(props) => props.objectFit};
    opacity: ${(props) => (props.imgLoaded ? 1 : 0)};
    vertical-align: ${(props) =>
        props.verticalAlign || "baseline"}; /* baseline is default for img*/
`;
