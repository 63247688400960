import styled, { withTheme } from "styled-components";

import Avatar from "../../Avatar";
import Button from "../../Button";
import ButtonCircleIcon from "../../ButtonCircleIcon";
import Icon from "../../Icon";

const AccountRowView = ({
    theme,
    accountName,
    accountImg,
    isCompany,
    accountIsPending,
    accountIsAccepted,
    userCanDelete,
    statusIconVisible,
    newTagVisible,
    requestActionsVisible,
    handleAccept,
    handleReject,
    handleAccountClick,
    handleRemoveClick,
}) => {

    const deleteButton = userCanDelete && (
        <RemoveButtonWrapper>
            <ButtonCircleIcon
                handleClick={handleRemoveClick}
                borderColor={theme.colors.semantic.error}
                bgColor={theme.colors.primary}
                shadow={true}
                iconColor={theme.colors.text.semantic.error}
                circleDiameter={"2.5rem"}
                fontSize={"1rem"}
            >
                <Icon name="user-times" />
            </ButtonCircleIcon>
        </RemoveButtonWrapper>
    );

    const requestActionButtons = requestActionsVisible ? (
        <ButtonsWrapper>
            <LeftButtonWrapper>
                <Button
                    handleClick={handleAccept}
                    children="Accept"
                    icon={<Icon name="user-check" />}
                    secondaryBtnColors={true}
                    textColor={theme.colors.darkAccent}
                    btnPadding={"0 1rem"}
                    fontWeight={500}
                />
            </LeftButtonWrapper>
            <RightBtnWrapper>
                <Button
                    handleClick={handleReject}
                    icon={<Icon name="user-times" />}
                    secondaryBtnColors={true}
                    textColor={theme.colors.text.semantic.error}
                    btnPadding={"0 1rem"}
                    iconPadding={"0"}
                />
            </RightBtnWrapper>
        </ButtonsWrapper>
    ) : null;

    const statusIcon = statusIconVisible ? (
        accountIsAccepted ?
            <RightSideWrapper>
                <Icon
                    name="check-circle"
                    padding="0 0 0 0.5rem"
                    fontSize="1.5rem"
                    color={theme.colors.accent}
                />
            </RightSideWrapper> :
            <RightSideWrapper>
                <Icon
                    name="user-clock"
                    padding="0 0 0 0.5rem"
                    fontSize="1.5rem"
                    color={theme.colors.offSecondary}
                />
            </RightSideWrapper>
    ) : null;

    const newTag = newTagVisible ? <New>New</New> : null;

    return (
        <AccountRow tagPending={accountIsPending}>
            <LeftHandSide>
                {deleteButton}
                <Account>
                    <Avatar
                        path={accountImg}
                        user={!isCompany}
                        company={isCompany}
                        size={"3rem"}
                        borderColor={theme.colors.offPrimary}
                        showPointer={true}
                        onClick={handleAccountClick}
                    />
                    <NameCol>
                        <Name onClick={handleAccountClick}>{accountName}</Name>
                        {requestActionButtons}
                    </NameCol>
                </Account>
            </LeftHandSide>
            {statusIcon}
            {newTag}
        </AccountRow>
    );
};

export default withTheme(AccountRowView);

const ButtonsWrapper = styled.div`
    padding: 0.3rem 0.1rem;
    display: flex;
    align-items: center;
    position: relative;
`;

const LeftButtonWrapper = styled.div`
    /* flex: 1 0 100px; */
`;

const RightBtnWrapper = styled(LeftButtonWrapper)`
    padding-left: 0.5rem;
`;

const Account = styled.div`
    display: flex;
    /* align-items: flex-start; */
    cursor: pointer;
    min-width: 0; /* Needed for cutting of long names  */
    position: relative;
`;

const LeftHandSide = styled.div`
    display: flex;
    align-items: center;
`;

const AccountRow = styled.div`
    display: flex;
    /* Extra vertical space between tags that are pending approval to make it extra clear what user you are accepting or declining */
    padding: ${(props) => (props.tagPending ? "0.4rem 0" : "0.3rem 0")};
    justify-content: space-between;
    align-items: center;
    /* flex-wrap: wrap; */
    min-width: 0;
`;

const RightSideWrapper = styled.div`
    /* Gives a vertical spacing between icon and avatar if it has to wrap  */
    /* Note this should never be large than padding vertical on AccountRow */
    margin: 0.3rem 0;
`;

const RemoveButtonWrapper = styled.div`
    min-height: 100%;
    display: flex;
    align-items: center;
    padding-right: 0.7rem;
`;

const New = styled.div`
    color: ${(props) => props.theme.colors.accent};
    padding: 0 0.5rem;
    font-weight: bold;
`;

const NameCol = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 0;
    justify-content: center;
    padding: 0 0.5rem 0 0.5rem; /* Space each side of user or companies name */
`;

const Name = styled.h6`
    margin: 0;
    /* Will cut off any words without white space breaks that excide available width. Names with white space will be wrapped onto new line */
    /* white-space: nowrap; */
    text-overflow: ellipsis;
    overflow: hidden;
`;

// const StatusIcon = styled.i`
//     padding: 0 0 0 0.5rem;
//     font-size: 1.5rem;
// `;

// const AcceptedIcon = styled(StatusIcon)`
//     /* uses base style from StatusIcon */
//     color: ${(props) => props.theme.colors.accent};
// `;

// const PendingIcon = styled(StatusIcon)`
//     /* uses base style from StatusIcon */
//     color: ${(props) => props.theme.colors.offSecondary};
// `;

// const BtnText = styled.div`
//     font-weight: 500;
// `;
