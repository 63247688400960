import { useState, useEffect, useCallback } from "react";
import styled from "styled-components";

import { ContentBox } from "../../../../resources/styles/index";
import HeaderBar from "../../PopupHeaderBar";
import FooterBar from "../../PopupFooterBar";
import PopupLoadingScreenView from "../../PopupLoadingScreen";
import { fieldsFailingValidation } from "../../../utilities";

// import { useAuth0 } from "@auth0/auth0-react";
// import { sendApiRequest } from "../../../../services/api";
import { useApi } from "../../../../services/api";

// import {
//     popUpBorderRadius,
//     popUpHeaderHeight,
//     popUpMarginBelowNavbar,
// } from "../../../hoc/WithPopUp/WithPopUp-container";

// Component for editing any text fields, can take any number of text fields from parent state and must be passed in FormFieldsToRender for JSX input fields.
// Component will not work for images, addresses, arrays etc must be a text field
const TextEditContainer = ({
    popupTitle,
    parentState,
    // errors,
    // removeError,
    addError,
    setPopUpToDisplay,
    setParentState,
    serverPath,
    FormFieldsToRender,
    profileValidationRules,
}) => {
    // const { getAccessTokenSilently } = useAuth0();
    // State used to store inputs
    const [formState, setFormState] = useState(parentState);

    // State used in front end validation
    const [validationFailedState, setValidationFailedState] = useState({});
    const [firstSubmitFailed, setFirstSubmitFailed] = useState(false);

    // const [popupLoading, setPopupLoading] = useState(false);

    const {
        isLoading: popupLoading,
        refresh: submitFormState,
    } = useApi();

    // Standard handleChange function
    const handleChange = (e) => {
        e.preventDefault();
        setFormState({
            ...formState,
            [e.target.name]: e.target.value,
        });
    };

    // Check each field being submitted against validation rules. return true if all pass, false if one or more fail
    const checkFieldsPassValidationRules = useCallback(() => {
        // fieldsFailingValidation is a utility for checking an object of key value pairs against an object of rules. Each key will be checked against its rule. The returned object contains failed keys with reason for failure.
        const fieldsFailedValidation = fieldsFailingValidation(
            formState,
            profileValidationRules
        );
        // Update state to show error messages to user
        setValidationFailedState(fieldsFailedValidation);
        // return false if failure, true if no failures
        return Object.keys(fieldsFailedValidation).length === 0;
    }, [formState, profileValidationRules]);

    // Used to clear errors from screen as user fixes them.
    // Checks fields pass validation every time formState changes (as user types), only allowed to run when user has submitted a failed submit
    useEffect(() => {
        if (firstSubmitFailed) { checkFieldsPassValidationRules(); }
    }, [firstSubmitFailed, checkFieldsPassValidationRules]);

    // Send changes made to server.
    const handleSubmit = async (e) => {
        // try {
        e.preventDefault();
        // setPopupLoading(true);
        // Front end validation
        const pass = checkFieldsPassValidationRules();
        if (pass) {
            // If fields pass front end validation
            // Send to server
            submitFormState({
                method: "put",
                url: serverPath,
                data: formState,
                callback: () => {
                    setParentState(f => ({ ...f, ...formState }));
                    setPopUpToDisplay("");
                },
                errorCallback: (err) => { addError(err.message); },
            });

            // await sendApiRequest(getAccessTokenSilently, {
            //     method: "put",
            //     url: serverPath,
            //     data: formState,
            // });
            // console.log("formState for submit");
            // console.log(formState);
            // // Update parent state after API request finished
            // setParentState((f) => {
            //     return {
            //         ...f,
            //         ...formState,
            //     };
            // });

            // setPopUpToDisplay(""); // unmount popup box by setPopUpToDisplay in parent
        } else {
            // One or more fields failed front end validation
            setFirstSubmitFailed(true);
            // setPopupLoading(false); // set popupLoading false and allow user to fix validation issues
        }
        // } catch (error) {
        //     setPopupLoading(false); // Remove popupLoading screen if error caught
        //     addError(error.message);
        // }
    };

    if (popupLoading) {
        return (
            <PopupLoadingScreenView maxHeight={"400px"} maxWidth={"600px"} />
        );
    } else {
        return (
            <FormWrapper>
                <HeaderBar
                    setPopUpToDisplay={setPopUpToDisplay}
                    popupTitle={popupTitle}
                />
                <ContentBox>
                    <ContentWrapper>
                        <FormFieldsToRender
                            formState={formState}
                            handleChange={handleChange}
                            validationFailedState={validationFailedState}
                        />
                    </ContentWrapper>
                </ContentBox>
                <FooterBar
                    handleDone={handleSubmit}
                    handleCancel={() => { setPopUpToDisplay(""); }}
                    cancelLabel={"Cancel"}
                    doneLabel={"Save"}
                />
            </FormWrapper>
        );
    }
};

export default TextEditContainer;

const FormWrapper = styled.div`
    /* On xs small screens make sure popup takes 95vw min*/
    min-width: min(${(props) => props.theme.sizes.xsViewWidth}, 95vw);
`;

const ContentWrapper = styled.div`
    padding: 0.3rem 2rem 0.5rem 2rem;
    @media (max-width: ${({ theme }) => theme.sizes.mdViewWidth}) {
        padding: 0rem 1rem 0.5rem 1rem;
    }
`;
