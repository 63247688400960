import styled from "styled-components";
import { darken } from "polished";

import SearchBox from "../SearchBox";
import Button from "../Button";

export const borderRadius = "0.8rem";

// Single row, name in toggle button disappears on smaller screens (less than screenSizeBreak defined below )
const SingleRowView = ({
    Ref,
    togglerMinWidth,
    setShowDropdown,
    showDropdown,
    theme,
    searchType,
    searchTypes,
    searchTypeChange,
    dropdownMaxHeight,
    handleSelect,
    excludedResults,
    singleRowBorderColor,
    autoFocus, // optional
    singleRowOptions = {}, // optional options
}) => {
    let { btnBgColor, btnTextColor, btnBorderColor, rowPaddingColor } =
        singleRowOptions;
    // Check for options if not use default
    btnBorderColor = btnBorderColor || btnBgColor || theme.colors.offPrimary; // order of preferences, note make sure this line is above btnBgColor
    btnBgColor = btnBgColor || theme.colors.primary;
    btnTextColor = btnTextColor || theme.colors.secondary;
    rowPaddingColor = rowPaddingColor || darken(0.04, theme.colors.primary);

    const screenSizeBreak = theme.sizes.xsViewWidth;
    const DropdownOptions = searchTypes.map((type, i) => {
        if (type.apiRequestPath !== searchType.apiRequestPath) {
            return (
                <OptionWrapper
                    key={i}
                    onClick={() => {
                        setShowDropdown(false);
                        searchTypeChange(type.apiRequestPath);
                    }}
                >
                    {type.searchTitle}
                </OptionWrapper>
            );
        } else {
            // SelectedOptionWrapper only show on screens smaller than screenSizeBreak
            return (
                <SelectedOptionWrapper
                    key={i}
                    onClick={() => {
                        setShowDropdown(false);
                        searchTypeChange(type.apiRequestPath);
                    }}
                    screenSizeBreak={screenSizeBreak}
                >
                    {type.searchTitle}
                </SelectedOptionWrapper>
            );
        }
    });

    return (
        <Wrapper rowPaddingColor={rowPaddingColor}>
            <ToggleWrapper
                ref={Ref}
                minWidth={togglerMinWidth}
                screenSizeBreak={screenSizeBreak}
            >
                <ButtonWrapper>
                    <Button
                        icon={<i className="fas fa-caret-down"></i>}
                        handleClick={() =>
                            setShowDropdown(showDropdown ? false : true)
                        }
                        borderRadius={`${borderRadius} 0 0 ${borderRadius}`}
                        bgColor={btnBgColor}
                        textColor={btnTextColor}
                        borderColor={btnBorderColor}
                        noShadow={true}
                    // btnPadding={"0.2rem 0 0.2rem 0.8rem"}
                    >
                        <ButtonText screenSizeBreak={screenSizeBreak}>
                            {searchType.searchTitle}
                        </ButtonText>
                    </Button>
                </ButtonWrapper>
                <DropdownWrapper>
                    {showDropdown && (
                        <Dropdown minWidth={togglerMinWidth}>
                            {DropdownOptions}
                        </Dropdown>
                    )}
                </DropdownWrapper>
            </ToggleWrapper>
            <SearchBoxWrapper>
                <SearchBox
                    dropdownMaxHeight={
                        dropdownMaxHeight ? dropdownMaxHeight : "300px"
                    }
                    searchApiRequest={searchType}
                    addResultToParentState={handleSelect}
                    borderRadius={`0 ${borderRadius} ${borderRadius} 0`}
                    clearInputOnSelect={true}
                    searchDescription={"Search"}
                    clearSearchOnOutsideClick={true}
                    reqBody={{
                        excludedResults: excludedResults
                            ? excludedResults[`${searchType.searchTitle}`]
                            : [],
                    }}
                    autoFocus={autoFocus}
                />
            </SearchBoxWrapper>
        </Wrapper>
    );
};

export default SingleRowView;

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    background-color: ${({ rowPaddingColor }) => rowPaddingColor || "inherit"};
    padding: ${({ rowPaddingColor }) =>
        rowPaddingColor ? "0.3rem 0.5rem" : "0"};
    border-radius: 1rem;
    /* z-index: -1; */
    position: relative;
    max-width: 100%;
`;

const ToggleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 1.1rem;
    min-width: ${(props) =>
        props.minWidth}; /* Set min toggle button width for larger screens */
    @media (max-width: ${({ screenSizeBreak }) => screenSizeBreak}) {
        /* Allow toggle button to compress on small screens */
        min-width: auto;
    }
`;

const ButtonWrapper = styled.div`
    z-index: 1; /* Button above its dropdown */
`;

const ButtonText = styled.div`
    @media (max-width: ${({ screenSizeBreak }) => screenSizeBreak}) {
        display: none; /* Remove text on smaller screens as width constrained */
    }
`;

export const DropdownWrapper = styled.div`
    position: relative;
    margin-left: 0rem; /* add margin if you want dropdown to move in from left */
`;

export const Dropdown = styled.div`
    position: absolute;
    width: 100%;
    min-width: ${(props) =>
        props.minWidth}; /* Only takes effect on small screens when toggle button wrapper can shrink below minWidth*/
    top: -1rem;
    padding: 1rem 0;
    background-color: ${(props) => props.theme.colors.primary};
    display: flex;
    flex-direction: column;
    border-radius: 0 0 ${borderRadius} ${borderRadius};
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15);
`;

export const OptionWrapper = styled.div`
    cursor: pointer;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background-color: ${(props) => props.theme.colors.offPrimary};
    }
`;

// Bold selected option
// Only shown for small screens when text disappears form button
const SelectedOptionWrapper = styled(OptionWrapper)`
    display: none;
    @media (max-width: ${({ screenSizeBreak }) => screenSizeBreak}) {
        display: flex;
        font-weight: 600;
    }
`;

const SearchBoxWrapper = styled.div`
    display: flex;
    flex: 1;
    position: relative;
    z-index: 1;
    margin-left: -0.08rem; /* Hide double border searchbox left border and button border */
`;
