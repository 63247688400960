import { useEffect } from "react";
import { connect } from "react-redux";
import { removeError } from "../../../main/store/actions/errors";
const mapDispatch = {
   removeError,
};

// Removes error from store when component un-mounts. Note the strange way I connected the HOC to the store to get RemoveError. This is the only way I could get it to work unless we sent RemoveError in as props from whatever the parent component is and that would of made the HOC a lot more annoying to use. I try redux compose helper in the index file - was hit and miss don't recommend that function although I could get it to work some times.
const WithRemoveErrorOnUnmountContainer = (WrappedComponent) =>
   connect(
      // Connect to store so we can access removeError
      null,
      mapDispatch
   )(function WithRemoveErrorOnUnmountHOC(props) {
      const { removeError } = props;

      useEffect(() => {
         // Remove error on cleanup
         return () => {
            removeError();
         };
      }, [removeError]);

      return <WrappedComponent {...props} />;
   });

export default WithRemoveErrorOnUnmountContainer;
