import { useState } from "react";
import styled, { withTheme } from "styled-components";

import Button from "../../Button";
import { handleUserClick, handleCompanyClick } from "../../../utilities";
import Avatar from "../../Avatar";
// import RemoveButton from "../../ButtonXCircle";
import ButtonCircleIcon from "../../ButtonCircleIcon";

const AccountRowView = ({
    theme,
    companyJsx,
    history,
    accountData,
    userCanDelete,
    deleteAccount,
    showStatus,
    hideNewStatus,
    editView,
    addView,
    userIsAdmin,
    requestActions,
    acceptedAccountStatus,
    pendingStatus,
}) => {
    const [acceptBtnLoading, setAcceptBtnLoading] = useState(false);
    const [rejectBtnLoading, setRejectBtnLoading] = useState(false);

    const accountStatusIcon = (status) => {
        if (status === acceptedAccountStatus) {
            return (
                <RightSideWrapper>
                    <AcceptedIcon className="fas fa-check-circle"></AcceptedIcon>
                </RightSideWrapper>
            );
        } else {
            return (
                <RightSideWrapper>
                    <PendingIcon className="fas fa-user-clock"></PendingIcon>
                </RightSideWrapper>
            );
        }
    };

    const handleAccountClick = (e) => {
        if (companyJsx) {
            handleCompanyClick(e, history, accountData._id);
        } else {
            handleUserClick(e, history, accountData._id);
        }
    };

    return (
        <AccountRow tagPending={accountData.status === pendingStatus}>
            <LeftHandSide>
                {userCanDelete(accountData) && (
                    <RemoveButtonWrapper>
                        <ButtonCircleIcon
                            handleClick={(e) => {
                                e.stopPropagation();
                                deleteAccount(
                                    accountData._id,
                                    companyJsx ? true : false
                                );
                            }}
                            borderColor={theme.colors.semantic.error}
                            bgColor={theme.colors.primary}
                            shadow={true}
                            iconColor={theme.colors.text.semantic.error}
                            circleDiameter={"2.5rem"}
                            fontSize={"1rem"}
                        >
                            <i className="fas fa-user-times"></i>
                        </ButtonCircleIcon>
                    </RemoveButtonWrapper>
                )}
                <Account>
                    <Avatar
                        path={accountData.img}
                        user={!companyJsx}
                        company={!!companyJsx}
                        size={"3rem"}
                        borderColor={theme.colors.offPrimary}
                        showPointer={true}
                        onClick={handleAccountClick}
                    />
                    <NameCol>
                        <Name onClick={handleAccountClick}>
                            {companyJsx
                                ? accountData.name
                                : accountData.username}
                        </Name>
                        {userIsAdmin &&
                            !addView &&
                            accountData.status === pendingStatus &&
                            Object.keys(requestActions).length >= 2 && (
                                // Only show add accept buttons if admin user, not in add or edit views,
                                // the accountData has a status of pending AND if the accept and request functions present
                                <ButtonsWrapper>
                                    <LeftButtonWrapper>
                                        <Button
                                            handleClick={() =>
                                                requestActions.handleAccept(
                                                    accountData._id,
                                                    setAcceptBtnLoading
                                                )
                                            }
                                            icon={
                                                <i className="fas fa-user-check"></i>
                                            }
                                            secondaryBtnColors={true}
                                            textColor={theme.colors.darkAccent}
                                            btnPadding={"0 1rem"}
                                            buttonLoading={acceptBtnLoading}
                                        >
                                            <BtnText>Accept</BtnText>
                                        </Button>
                                    </LeftButtonWrapper>
                                    <RightBtnWrapper>
                                        <Button
                                            handleClick={() =>
                                                requestActions.handleReject(
                                                    accountData._id,
                                                    setRejectBtnLoading
                                                )
                                            }
                                            icon={
                                                <i className="fas fa-user-times"></i>
                                            }
                                            secondaryBtnColors={true}
                                            textColor={
                                                theme.colors.text.semantic.error
                                            }
                                            btnPadding={"0 1rem"}
                                            iconPadding={"0"}
                                            buttonLoading={rejectBtnLoading}
                                        ></Button>
                                    </RightBtnWrapper>
                                </ButtonsWrapper>
                            )}
                    </NameCol>
                </Account>
            </LeftHandSide>
            {!!showStatus && !editView && !addView && (
                // Status icon if showStatus true
                <div>{accountStatusIcon(accountData.status)}</div>
            )}
            {!hideNewStatus && addView && accountData.new && <New>New</New>}
        </AccountRow>
    );
};

export default withTheme(AccountRowView);

const ButtonsWrapper = styled.div`
    padding: 0.3rem 0.1rem;
    display: flex;
    align-items: center;
    position: relative;
`;

const LeftButtonWrapper = styled.div`
    /* flex: 1 0 100px; */
`;

const RightBtnWrapper = styled(LeftButtonWrapper)`
    padding-left: 0.5rem;
`;

const Account = styled.div`
    display: flex;
    /* align-items: flex-start; */
    cursor: pointer;
    min-width: 0; /* Needed for cutting of long names  */
    position: relative;
`;

const LeftHandSide = styled.div`
    display: flex;
    align-items: center;
`;

const AccountRow = styled.div`
    display: flex;
    /* Extra vertical space between tags that are pending approval to make it extra clear what user you are accepting or declining */
    padding: ${(props) => (props.tagPending ? "0.4rem 0" : "0.3rem 0")};
    justify-content: space-between;
    align-items: center;
    /* flex-wrap: wrap; */
    min-width: 0;
`;

const RightSideWrapper = styled.div`
    /* Gives a vertical spacing between icon and avatar if it has to wrap  */
    /* Note this should never be large than padding vertical on AccountRow */
    margin: 0.3rem 0;
`;

const RemoveButtonWrapper = styled.div`
    min-height: 100%;
    display: flex;
    align-items: center;
    padding-right: 0.7rem;
`;

const New = styled.div`
    color: ${(props) => props.theme.colors.accent};
    padding: 0 0.5rem;
    font-weight: bold;
`;

const NameCol = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 0;
    justify-content: center;
    padding: 0 0.5rem 0 0.5rem; /* Space each side of user or companies name */
`;

const Name = styled.h6`
    margin: 0;
    /* Will cut off any words without white space breaks that excide available width. Names with white space will be wrapped onto new line */
    /* white-space: nowrap; */
    text-overflow: ellipsis;
    overflow: hidden;
`;

const StatusIcon = styled.i`
    padding: 0 0 0 0.5rem;
    font-size: 1.5rem;
`;

const AcceptedIcon = styled(StatusIcon)`
    /* uses base style from StatusIcon */
    color: ${(props) => props.theme.colors.accent};
`;

const PendingIcon = styled(StatusIcon)`
    /* uses base style from StatusIcon */
    color: ${(props) => props.theme.colors.offSecondary};
`;

const BtnText = styled.div`
    font-weight: 500;
`;
