import SideBar from "./SideBar-container";
import { withTheme } from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";


function mapState(state) {
    return {
        currentUser: state.currentUser,
    };
}

const mapDispatch = {
};

export default connect(mapState, mapDispatch)(withRouter(withTheme(SideBar)));
