import React from "react";

import AddressEditPopup from "./PopupEditAddress";
import SiteDetailsEdit from "./PopupEditProfileDetails";

import ConfirmationScreen from "../../library/common/ConfirmationScreen";
import ProfileImageEdit from "../../library/common/popups/ProfileImageEdit";
import UsersPopup from "../../library/common/popups/AddOrRemoveAccounts";
import TextEditPopup from "../../library/common/popups/TextEdit";
import withPopUp from "../../library/hoc/WithPopUp";
import withRemoveErrorOnUnmount from "../../library/hoc/WithRemoveErrorOnUnmount";

import {
    REACT_APP_DEFAULT_COMPANY_COVER,
    SITE_PROFILE_VALIDATION_RULES,
    SITE_POPUP_TYPES,
    CONNECTION_STATUS,
} from "../../library/common/constants";

const ConfirmationPopup = withRemoveErrorOnUnmount(
    withPopUp(ConfirmationScreen)
);

const PopupRouterContainer = ({
    popUpToDisplay,
    setPopUpToDisplay,
    siteDetails,
    setSiteDetails,
    errors,
    addError,
    removeError,
    refreshDataFromServer,
    serverPaths,
    theme,
    handleCrewChange,
    popupLoading,
    conxActions,
}) => {
    // allow admin users and any users in crew to edit crew (users in crew can only remove themselves once in edit vew)
    const allowCurrentUserToEditCrew =
        siteDetails.currentUserInCrew || siteDetails.userIsAdmin ? true : false;

    const usersPopupCommonProps = {
        errors: errors,
        addError: addError,
        removeError: removeError,
        setPopUpToDisplay: setPopUpToDisplay,
        userIsAdmin: siteDetails.userIsAdmin,
        userCanAdd: !!siteDetails.currentUserInCrew,
        userInAccounts: !!siteDetails.currentUserInCrew,
        users: siteDetails.crew,
        statusOptions: {
            acceptedAccountStatus: CONNECTION_STATUS.ACCEPTED,
            pendingStatus: CONNECTION_STATUS.PENDING,
        },
        handleSubmit: handleCrewChange,
        customExitHandling: () => setPopUpToDisplay(""),
        handleDone: () => setPopUpToDisplay(""),
        handleCancel: () => setPopUpToDisplay(""),
        popupTitle: "Crew",
        listTitle: "Crew Members",
        allowCurrentUserToEdit: allowCurrentUserToEditCrew,
        showStatus: true,
        popupLoading: popupLoading,
        requestActions: {
            handleAccept: (userId) =>
                conxActions.handleActionConxPending({ approve: true, userId }),
            handleReject: (userId) =>
                conxActions.handleActionConxPending({ approve: false, userId }),
        },
    };

    if (popUpToDisplay === SITE_POPUP_TYPES.coverPhoto) {
        return (
            <ProfileImageEdit
                popupTitle={"Site Photo"}
                type={SITE_POPUP_TYPES.coverPhoto}
                imgDetails={{ path: siteDetails.coverPhoto }}
                addError={addError}
                errors={errors}
                refreshParentState={refreshDataFromServer}
                setPopUpToDisplay={setPopUpToDisplay}
                addImgPath={serverPaths.addCoverPicPath}
                deleteImgPath={serverPaths.deleteCoverPicPath}
                defaultImg={REACT_APP_DEFAULT_COMPANY_COVER}
            />
        );
    } else if (popUpToDisplay === SITE_POPUP_TYPES.crew) {
        return <UsersPopup {...usersPopupCommonProps} />;
    } else if (popUpToDisplay === SITE_POPUP_TYPES.invite) {
        return <UsersPopup {...usersPopupCommonProps} add={true} />;
    } else if (popUpToDisplay === SITE_POPUP_TYPES.location) {
        return (
            <AddressEditPopup
                addressObj={{
                    address: siteDetails.address,
                    latLng: siteDetails.latLng,
                }}
                setPopUpToDisplay={setPopUpToDisplay}
                setParentState={setSiteDetails}
                serverPath={serverPaths.serverPath}
            />
        );
    } else if (popUpToDisplay === SITE_POPUP_TYPES.siteDetails) {
        return (
            <TextEditPopup
                popupTitle={"Site"}
                theme={theme}
                parentState={{ name: siteDetails.name }}
                addError={addError}
                errors={errors}
                setPopUpToDisplay={setPopUpToDisplay}
                setParentState={setSiteDetails}
                serverPath={serverPaths.serverPath}
                FormFieldsToRender={SiteDetailsEdit}
                profileValidationRules={SITE_PROFILE_VALIDATION_RULES}
            />
        );
    } else if (popUpToDisplay === SITE_POPUP_TYPES.leaveSite) {
        return (
            <ConfirmationPopup
                theme={theme}
                handleCancel={() => {
                    setPopUpToDisplay("");
                }}
                cancelText={"Cancel"}
                handleSubmit={conxActions.handleLeave}
                submitText={"Leave"}
                title={"Leave Site"}
                loading={popupLoading}
                setPopUpToDisplay={setPopUpToDisplay}
                message={"Are you sure you want to leave this site?"}
            />
        );
    } else if (popUpToDisplay === SITE_POPUP_TYPES.delete) {
        return (
            <ConfirmationPopup
                theme={theme}
                handleCancel={() => {
                    setPopUpToDisplay("");
                }}
                cancelText={"Cancel"}
                handleSubmit={conxActions.handleDeleteSite}
                submitText={"Delete"}
                title={"Delete"}
                loading={popupLoading}
                setPopUpToDisplay={setPopUpToDisplay}
                message={
                    "Deleting a site can't be undone. Are you sure you want to delete this site?"
                }
            />
        );
    } else {
        return <div></div>;
    }
};

export default PopupRouterContainer;
