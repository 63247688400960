import styled from "styled-components";

import Button from "../Button";

const AddSectionBtnBox = ({
    theme,
    profileSections,
    maxProfileWidth,
    title,
}) => {
    const SectionBtns = [];
    Object.keys(profileSections).forEach((sectionName, i) => {
        const section = profileSections[sectionName];
        if (section.has === false) {
            SectionBtns.push(
                <AddSectionBtnWrapper key={i}>
                    <Button
                        // if section has an add method use for handleClick if not use the edit method
                        handleClick={section.add ? section.add : section.edit}
                        icon={<i className="fas fa-plus-circle"></i>}
                        bgColor={theme.colors.body}
                        textColor={theme.colors.secondary}
                        borderColor={theme.colors.offSecondary}
                        noShadow={true}
                    >
                        {section.title}
                    </Button>
                </AddSectionBtnWrapper>
            );
        }
    });

    if (SectionBtns[0]) {
        // Only render component if one of the profiles sections is empty
        return (
            <AddSectionWrapper>
                <AddSectionTitleRow maxProfileWidth={maxProfileWidth}>
                    <AddSectionTitle>{title}</AddSectionTitle>
                </AddSectionTitleRow>
                <AddSectionBtnRow>{SectionBtns}</AddSectionBtnRow>
            </AddSectionWrapper>
        );
    } else {
        return <div></div>;
    }
};

export default AddSectionBtnBox;

const AddSectionWrapper = styled.div`
    margin-top: 0.7rem;
    width: 100%;
    border-radius: 5px;
    background-color: ${(props) => props.theme.colors.offPrimary};
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const AddSectionTitleRow = styled.div`
    max-width: ${(props) => props.maxProfileWidth};
    width: 100%;
    display: flex;
    justify-content: center;
`;

const AddSectionTitle = styled.h6`
    color: #575757;
`;

const AddSectionBtnRow = styled.div`
    max-width: ${(props) => props.maxProfileWidth};
    width: 100%;
    display: flex;
    justify-content: center;
    @media (max-width: ${(props) => props.theme.sizes.xsViewWidth}) {
        flex-direction: column;
    }
`;

const AddSectionBtnWrapper = styled.div`
    cursor: pointer;
    flex: 0 1 250px;
    margin: 0.3rem;
    @media (max-width: ${(props) => props.theme.sizes.xsViewWidth}) {
        flex: 0;
    }
`;
