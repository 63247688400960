import styled from "styled-components";

// Engagements bar with number of likes and comments plus buttons to like and comment below
const EngagementSection = ({
    post,
    handleLike,
    handleComment,
    theme,
    handleSeeLikes,
}) => {
    const postHasLikes = post.likes && post.likes.length > 0;
    const postHasComments = post.numComments > 0;

    return (
        <div>
            <NumberOfEngagementsBar>
                <NumberFormat
                    onClick={postHasLikes ? handleSeeLikes : undefined}
                    showPointer={postHasLikes}
                >
                    {postHasLikes &&
                        `${post.likes.length} ${post.likes.length > 1 ? "Likes" : "Like"
                        }`}
                </NumberFormat>
                <NumberFormat
                    onClick={postHasComments ? handleComment : undefined}
                    showPointer={postHasComments}
                >
                    {postHasComments &&
                        `${post.numComments} ${post.numComments > 1 ? "Comments" : "Comment"
                        }`}
                </NumberFormat>
            </NumberOfEngagementsBar>
            <EngagementBar>
                <EngagementActionBtn
                    hasLiked={post.currentUserHasLiked ? true : false}
                    onClick={(e) =>
                        handleLike(e, post, post.currentUserHasLiked)
                    }
                >
                    <EngagementIcon className="far fa-thumbs-up" />
                    <TextFormat>
                        {post.currentUserHasLiked ? "Liked" : "Like"}
                    </TextFormat>
                </EngagementActionBtn>
                <EngagementActionBtn onClick={handleComment}>
                    <EngagementIcon className="far fa-comments" />
                    <TextFormat>Comment</TextFormat>
                </EngagementActionBtn>
            </EngagementBar>
        </div>
    );
};

export default EngagementSection;

const NumberOfEngagementsBar = styled.div`
    padding: 0.2rem 2rem;
    display: flex;
    justify-content: space-between;
    color: ${(props) => props.theme.colors.offSecondary};
    @media (max-width: ${({ theme }) => theme.sizes.smViewWidth}) {
        padding: 0.2rem 1rem;
    }
`;

const NumberFormat = styled.p`
    margin: 0;
    cursor: ${(props) => (props.showPointer ? "pointer" : "auto")};
`;

const EngagementBar = styled.div`
    /* margin-top: 1rem; */
    padding: 0.8rem 5rem;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid ${(props) => props.theme.colors.offPrimary};
    @media (max-width: ${({ theme }) => theme.sizes.smViewWidth}) {
        padding: 0.8rem 2rem;
    }
`;

const EngagementActionBtn = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    color: ${(props) =>
        props.hasLiked
            ? props.theme.colors.accent
            : props.theme.colors.offSecondary};
`;

const EngagementIcon = styled.i`
    font-size: 160%;
    margin-right: 1rem;
`;

const TextFormat = styled.p`
    margin: 0;
`;
