import styled, { withTheme } from "styled-components";
import { useState, useRef } from "react";

import Button from "../../library/common/Button";
import Icon, {
    DeleteIcon,
    OptionsIcon,
    PendingUserIcon,
    UnfollowIcon,
} from "../../library/common/Icon";

import {
    CONNECTION_STATUS,
    SITE_POPUP_TYPES,
} from "../../library/common/constants";
import { UseOutsideClick } from "../../library/utilities";

const ConnectionButtonView = ({
    theme,
    conxStatus,
    setPopUpToDisplay,
    userIsAdmin,
    conxActions,
    buttonLoading,
    // rejectButtonLoading,
}) => {
    const [showDropdown, setShowDropdown] = useState(false);

    // useOutsideClick logic used for popover
    const ref = useRef();
    UseOutsideClick(ref, () => {
        setShowDropdown(false);
    });

    // Renders different button for different users
    // (1) currentUser has status accepted, invite button with small "..." ellipsis button next to it. Clicking ellipsis gives users option to leave site (if their not an admin) or delete the site if they are an admin (admins can't leave site at present)
    // (2) User has sent a request to join that has not yet been actioned by admin, a "request" button that doesn't do anything is rendered
    // (3) User has been invited (status pending) but not yet accepted invite button with smaller cancel invite button to its right.
    // (4) User has no relation to site then a join button is rendered
    if (conxStatus === CONNECTION_STATUS.ACCEPTED) {
        return (
            <div>
                <ButtonsWrapper>
                    <Button
                        handleClick={() =>
                            setPopUpToDisplay(SITE_POPUP_TYPES.invite)
                        }
                        icon={<Icon name="user-plus" />}
                        bgColor={theme.colors.offPrimary}
                        textColor={theme.colors.secondary}
                        noShadow={true}
                        btnPadding={"0 1rem"}
                    >
                        <BtnText>Invite</BtnText>
                    </Button>
                    <RightBtnWrapper>
                        <Button
                            handleClick={() => setShowDropdown(true)}
                            icon={<OptionsIcon />}
                            bgColor={theme.colors.offPrimary}
                            textColor={theme.colors.secondary}
                            noShadow={true}
                            btnPadding={"0 1rem"}
                            iconPadding={"0"}
                        ></Button>
                    </RightBtnWrapper>
                </ButtonsWrapper>
                {showDropdown && (
                    <PopoverWrapper>
                        <Popover ref={ref}>
                            <TriangleUp></TriangleUp>
                            <PopoverBox>
                                {!userIsAdmin && (
                                    <Button
                                        handleClick={() => {
                                            setShowDropdown(false);
                                            setPopUpToDisplay(
                                                SITE_POPUP_TYPES.leaveSite
                                            );
                                        }}
                                        icon={<UnfollowIcon />}
                                        bgColor={theme.colors.semantic.error}
                                        textColor={theme.colors.secondary}
                                        noShadow={true}
                                    >
                                        Leave
                                    </Button>
                                )}
                                {userIsAdmin && (
                                    <Button
                                        handleClick={() => {
                                            setShowDropdown(false);
                                            setPopUpToDisplay(
                                                SITE_POPUP_TYPES.delete
                                            );
                                        }}
                                        icon={<DeleteIcon />}
                                        bgColor={theme.colors.semantic.error}
                                        textColor={theme.colors.secondary}
                                        noShadow={true}
                                    >
                                        Delete
                                    </Button>
                                )}
                            </PopoverBox>
                        </Popover>
                    </PopoverWrapper>
                )}
            </div>
        );
    } else if (conxStatus === CONNECTION_STATUS.REQUESTED) {
        return (
            <Button
                handleClick={() => { }}
                icon={<PendingUserIcon />}
                bgColor={theme.colors.accent}
                textColor={theme.colors.primary}
                noShadow={true}
                btnPadding={"0 1rem"}
            >
                Requested
            </Button>
        );
    } else if (conxStatus === CONNECTION_STATUS.PENDING) {
        return (
            <ButtonsWrapper>
                <Button
                    handleClick={conxActions.handleAcceptInvite}
                    icon={<Icon name="user-check" />}
                    bgColor={theme.colors.semantic.success}
                    textColor={theme.colors.secondary}
                    noShadow={true}
                    btnPadding={"0 1rem"}
                    buttonLoading={buttonLoading}
                >
                    <BtnText>Accept</BtnText>
                </Button>
                <RightBtnWrapper>
                    <Button
                        handleClick={conxActions.handleRejectInvite}
                        icon={<Icon name="user-times" />}
                        bgColor={theme.colors.semantic.error}
                        textColor={theme.colors.secondary}
                        noShadow={true}
                        btnPadding={"0 1rem"}
                        iconPadding={"0"}
                        buttonLoading={buttonLoading}
                    ></Button>
                </RightBtnWrapper>
            </ButtonsWrapper>
        );
    } else {
        return (
            <Button
                handleClick={conxActions.handleJoin}
                icon={<Icon name="user-plus" />}
                bgColor={theme.colors.offPrimary}
                textColor={theme.colors.secondary}
                noShadow={true}
                btnPadding={"0 1.5rem"}
                buttonLoading={buttonLoading}
            >
                <BtnText>Join</BtnText>
            </Button>
        );
    }
};

export default withTheme(ConnectionButtonView);

const BtnText = styled.div`
    font-weight: 500;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    position: relative;
`;

const RightBtnWrapper = styled.div`
    padding-left: 0.5rem;
`;

const PopoverWrapper = styled.div`
    position: relative;
    width: 100%;
`;

// A positioning component that contains up triangle and popover box
const Popover = styled.div`
    position: absolute;
    z-index: 1;
    width: 100%;
`;

// Arrow pointing to title
const TriangleUp = styled.div`
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid ${(props) => props.theme.colors.secondary};
    position: absolute;
    z-index: 0;
    right: 1.5rem;
`;

// Box containing description
const PopoverBox = styled.div`
    position: relative;
    background-color: ${(props) => props.theme.colors.secondary};
    color: ${(props) => props.theme.colors.primary};
    border-radius: 10px;
    padding: 1rem;
    margin: 0.5rem 0 0 0;
    max-width: 600px;
`;
