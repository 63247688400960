import ButtonCircle from "../ButtonCircleIcon";
import styled from "styled-components";

const ButtonXCircleView = ({
    handleClick,
    theme, // From index
    circleDiameter, // all props from here done optional
    fontSize,
    bgColor,
    iconColor,
}) => {
    const { primary, secondary } = theme.colors;

    iconColor = iconColor ? iconColor : secondary;
    bgColor = bgColor ? bgColor : primary;

    return (
        <ButtonCircle
            handleClick={handleClick}
            bgColor={bgColor}
            iconColor={iconColor}
            circleDiameter={circleDiameter}
            fontSize={fontSize}
        >
            <XIcon className="fas fa-times"></XIcon>
        </ButtonCircle>
    );
};

export default ButtonXCircleView;

const XIcon = styled.div``;
