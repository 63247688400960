import { useState } from "react";
import styled, { withTheme } from "styled-components";

import HeaderBar from "../../PopupHeaderBar";
import { ContentBox, FooterBar } from "../../../../resources/styles";
import {
    USER_SEARCH,
    COMPANY_SEARCH,
    CONNECTION_STATUS,
    USER_STATUS,
} from "../../constants";
import SearchBoxWithToggler from "../../SearchBoxWithToggler";
import SearchBox from "../../SearchBox";
import { borderRadius } from "../../SearchBoxWithToggler/SingleRow-view";
// import ButtonCircleIcon from "../../ButtonCircleIcon";
import Button from "../../Button";
import PopupLoadingScreen from "../../PopupLoadingScreen";
import AccountRow from "./AccountRow";
import OptionsButton from "./OptionsButton-view";

// Popup for adding or removing uses or company accounts
const UsersEditView = ({
    theme, // index
    history, // index
    currentUser, // index
    customExitHandling, // required X button top right handling
    users, // required users array from parent
    companies, // required if !!allowCompanies (not if not)
    allowCompanies, // if true companies can be addded
    add, // optional, if true addView rendered from mount
    userIsAdmin, // optional, If true user can add, edit, delete accounts
    userCanAdd, // optional if users other than admin can add users (or companies)
    userInAccounts, // used to show leave btn if user is in accounts
    noEditingAllowed, // optional if true admin users can't delete accounts
    statusOptions = {},
    requestActions = {}, // optional, use if want action requests buttons for admin use
    handleDone, // Done button in footer
    doneText = "Done", // optional
    handleSubmit, // submit button in footer
    submitText = "Save", // optional
    handleCancel, // Cancel button in footer
    leaveText = "Leave", // text for leave button
    leaveIcon = <i className="fas fa-user-minus"></i>, // icon for leave btn
    addBtnOptions = {},
    popupTitle, // Title of popup
    listTitle, // optional title for list of accounts (recommended if !!userCanEdit)
    showStatus, // optional. Status icon shown next to account
    hideNewStatus, // optional, if true then no "New" account status in addView
    popupLoading, // loading state passed in from parent
    showAllUsers, // optional if true all accounts shown (as opposed to only acceptedAccountStatus accounts)
}) => {
    const {
        // optional, used to hide accounts without acceptedAccountStatus (!!userCanAdd users can see all)
        acceptedAccountStatus,
        // optional define if accounts can be removed e.g. "INACTIVE" users
        removedAccountStatus = USER_STATUS.INACTIVE,
        // Accounts awaiting approval
        pendingStatus = CONNECTION_STATUS.PENDING,
        // Status of current users if they have requested to join
        // requestedStatus = CONNECTION_STATUS.REQUESTED,
    } = statusOptions;

    const {
        addText = "Add", // optional text in add button
        addIcon = <i className="fas fa-user-plus"></i>,
    } = addBtnOptions;

    // *** Manually turn off allowCompanies functionality
    // (companies can't be tagged, can be a member of a site and can't like a post for now)
    allowCompanies = false;

    // Used in initial setState - adds a timeAdded field to allow any accounts added subsequently to be sorted to top
    const addTimeStampsToArr = (arr) => {
        return arr.map((el) => ({ ...el, timeAdded: Date.now() }));
    };

    // Account states
    const [usersWithTime, setUsersWithTime] = useState(
        addTimeStampsToArr(users)
    );
    const [companiesWithTime, setCompaniesWithTime] = useState(
        !!allowCompanies && !!companies ? addTimeStampsToArr(companies) : []
    );

    // if no users or companies comes in and users has permission to add then go strait to addView
    const mountInAddView =
        !!add ||
        (!usersWithTime[0] &&
            !companiesWithTime[0] &&
            (!!userCanAdd || !!userIsAdmin));

    // Add and edit view state used to render variations on the popup
    const [addView, setAddView] = useState(mountInAddView);
    const [editView, setEditView] = useState(false);

    // Keeps track of whether an items has been removed
    const [itemRemoved, setItemRemoved] = useState(false);

    // When true a grey background is used to focus user on search box.
    // ALSO avatar clicks outside search box are disabled
    const [searchBoxFocus, setSearchBoxFocus] = useState(!!mountInAddView);

    // Delete account from relevant array
    const deleteAccount = (delete_id, deleteCompany) => {
        if (deleteCompany) {
            setCompaniesWithTime(
                companiesWithTime.filter((el) => el._id !== delete_id)
            );
        } else {
            setUsersWithTime(
                usersWithTime.filter((el) => el._id !== delete_id)
            );
        }
        setItemRemoved(true);
    };

    // handle current user leaving accounts list
    const handleLeave = () => {
        setUsersWithTime(
            usersWithTime.filter((el) => el._id !== currentUser.user.id)
        );
        setItemRemoved(true);
    };

    // Handles select from searchBox dropdown
    // Adds account with time stamp field to relevant array (users or companies)
    const handleSelect = (result, searchType) => {
        const newAccount = {};
        newAccount["_id"] = result.id;
        newAccount["img"] = result.img;
        newAccount["timeAdded"] = Date.now();
        newAccount["new"] = true;
        if (searchType.searchTitle === USER_SEARCH.searchTitle) {
            newAccount["username"] = result.name;
            newAccount["givenName"] = result.givenName;
            // Add new account to users array
            setUsersWithTime([
                newAccount,
                ...usersWithTime.filter((el) => el._id !== result.id),
                // Filter any accounts that have newAccount's id
                // Only occurs if a user with status removedAccountStatus is being re added
            ]);
        } else {
            newAccount["name"] = result.name;
            setCompaniesWithTime([
                newAccount,
                ...companiesWithTime.filter((el) => el._id === result.id),
            ]);
        }
        setSearchBoxFocus(false); // remove focus after account selected
    };

    // Checks whether a delete button should be rendered
    const userCanDelete = (item) => {
        // Profiles can be deleted if in editView
        if (!!editView && !addView) {
            if (
                item.status === acceptedAccountStatus &&
                !!userIsAdmin &&
                currentUser.user.id !== item._id
            ) {
                // Admin can delete all acceptedAccountStatus profiles when in editView
                //  except themselves can't remove their own account
                return true;
            }
        } else if (!!addView && item.new) {
            // In !!addView delete buttons shown on new users (i.e. users not yet sent to the server)
            return true;
        }
        return false;
    };

    // Creates object with account row JSX.
    // Also timeAdded and status keys for sorting on.
    const createAccountJSXWithTimeAndStatus = (accountData, companyJsx) => ({
        timeAdded: accountData.timeAdded,
        status: accountData.status,
        JsxForRender: (
            <AccountRow
                key={accountData._id}
                companyJsx={companyJsx}
                history={history}
                accountData={accountData}
                userCanDelete={userCanDelete}
                deleteAccount={deleteAccount}
                showStatus={showStatus}
                hideNewStatus={hideNewStatus}
                editView={editView}
                addView={addView}
                userIsAdmin={!!userIsAdmin}
                requestActions={requestActions}
                acceptedAccountStatus={acceptedAccountStatus}
                pendingStatus={pendingStatus}
            />
        ),
    });

    // Array combines JSX for users and company accounts with their time add field
    let AccountsToRender = [];
    usersWithTime.forEach((user, i) => {
        AccountsToRender.push(createAccountJSXWithTimeAndStatus(user));
    });
    companiesWithTime.forEach((company, i) => {
        AccountsToRender.push(createAccountJSXWithTimeAndStatus(company, true));
    });

    AccountsToRender = AccountsToRender
        // First sort pending users to top of list
        .sort((a, b) => (a.status === pendingStatus ? -1 : 0))
        // Then sort new accounts to top of list
        .sort((a, b) =>
            a.timeAdded > b.timeAdded ? -1 : b.timeAdded > a.timeAdded ? 1 : 0
        )
        // filter any removed accounts (don't filter if status falsy)
        .filter((el) => el.status !== removedAccountStatus || !el.status)
        // Remove some accounts depending on current users permission (if showAllUsers is true then permission not relevant)
        .filter((el) => {
            if (!!userCanAdd || !!userIsAdmin || !!showAllUsers) return true;
            else return el.status === acceptedAccountStatus;
        })
        .map((el) => el.JsxForRender);

    // Exclude accounts already added from search box results (unless the account status equals removedAccountStatus then they can be re added)
    const excludedUsers = [];
    const excludedCompanies = [];
    usersWithTime.forEach((el) => {
        if (el.status !== removedAccountStatus) {
            excludedUsers.push(el._id);
        }
    });
    companiesWithTime.forEach((el) => {
        if (el.status !== removedAccountStatus) excludedCompanies.push(el._id);
    });
    const excludedResults = {}; // object to be users in SearchBoxWithToggler
    excludedResults[`${USER_SEARCH.searchTitle}`] = excludedUsers;
    excludedResults[`${COMPANY_SEARCH.searchTitle}`] = excludedCompanies;

    // Variable changes footer button to submit button if in addView OR an items has been deleted
    const showSubmitFooter = !!addView || !!itemRemoved;

    const FooterBtnRow = (
        <FooterBar>
            <FooterBtnBlock>
                <LeftBtnWrapper>
                    {!itemRemoved &&
                        !addView &&
                        (!!userCanAdd || !!userIsAdmin) && (
                            <Button
                                handleClick={() => {
                                    setSearchBoxFocus(true);
                                    setAddView(true);
                                }}
                                secondaryBtnColors={true}
                                iconColor={theme.colors.accent}
                                icon={addIcon}
                            >
                                <BtnText>{addText}</BtnText>
                            </Button>
                        )}
                </LeftBtnWrapper>
                <RightBtnWrapper>
                    <Button
                        handleClick={
                            !!showSubmitFooter
                                ? () => {
                                    handleSubmit(
                                        usersWithTime,
                                        companiesWithTime
                                    );
                                }
                                : handleDone
                        }
                        primaryBtnColors={true}
                    >
                        <BtnText>
                            {!!showSubmitFooter ? submitText : doneText}
                        </BtnText>
                    </Button>
                </RightBtnWrapper>
            </FooterBtnBlock>
        </FooterBar>
    );

    if (popupLoading) {
        return <PopupLoadingScreen maxHeight={"500px"} maxWidth={"600px"} />;
    } else {
        return (
            <Wrapper>
                <HeaderBar
                    popupTitle={popupTitle}
                    customExitHandling={customExitHandling || handleCancel}
                />
                <ContentBox>
                    <ContentWrapper
                        searchBoxFocus={searchBoxFocus}
                        onClick={(e) => {
                            // Remove grey box on click event outside searchBox
                            setSearchBoxFocus(false);
                        }}
                    >
                        {searchBoxFocus && (
                            <DisableClicksInGreyBox show={searchBoxFocus} />
                        )}
                        {addView && (
                            <AddTagSection>
                                <AddTitleRow>
                                    <SectionTitle>Add</SectionTitle>
                                </AddTitleRow>
                                <SearchWrapper
                                    onClick={(e) => {
                                        e.stopPropagation(); // stops propagation to ContentWrapper
                                        // Click in search box sets searchBoxFocus true
                                        setSearchBoxFocus(true);
                                    }}
                                >
                                    {!!allowCompanies && (
                                        <SearchBoxWithToggler
                                            searchTypes={[
                                                USER_SEARCH,
                                                COMPANY_SEARCH,
                                            ]}
                                            defaultSearchType={USER_SEARCH}
                                            handleSelect={handleSelect}
                                            togglerMinWidth={"135px"}
                                            singleRowView={true}
                                            dropdownMaxHeight={"200px"}
                                            excludedResults={{
                                                excludedResults:
                                                    excludedResults,
                                            }}
                                            autoFocus={true}
                                        />
                                    )}
                                    {!allowCompanies && (
                                        <SearchBox
                                            dropdownMaxHeight={"200px"}
                                            searchApiRequest={USER_SEARCH}
                                            addResultToParentState={
                                                handleSelect
                                            }
                                            borderRadius={borderRadius}
                                            clearInputOnSelect={true}
                                            searchDescription={"Search Users"}
                                            clearSearchOnOutsideClick={true}
                                            reqBody={{
                                                excludedResults:
                                                    excludedUsers || [],
                                            }}
                                            autoFocus={true}
                                        />
                                    )}
                                </SearchWrapper>
                            </AddTagSection>
                        )}
                        {AccountsToRender.length > 0 && (
                            <TagCol>
                                {/* Show title row if title given or if users can edit */}
                                {(!!listTitle ||
                                    (!noEditingAllowed && !!userIsAdmin)) && (
                                        <TitleRow>
                                            {/* Need a title if user can edit (edit button looks odd without title) */}
                                            <SectionTitle>
                                                {listTitle || "Accounts"}
                                            </SectionTitle>
                                            {/* Don't show button in editView, addView also don't show if editing disabled or if user is not admin or in accounts */}
                                            {!editView &&
                                                !addView &&
                                                !noEditingAllowed &&
                                                (!!userInAccounts ||
                                                    !!userIsAdmin) && (
                                                    <OptionsButton
                                                        userIsAdmin={userIsAdmin}
                                                        noEditingAllowed={
                                                            noEditingAllowed
                                                        }
                                                        userInAccounts={
                                                            userInAccounts
                                                        }
                                                        leaveText={leaveText}
                                                        leaveIcon={leaveIcon}
                                                        handleEditClick={() =>
                                                            setEditView(true)
                                                        }
                                                        handleLeave={handleLeave}
                                                    />
                                                )}
                                        </TitleRow>
                                    )}
                                {AccountsToRender}
                            </TagCol>
                        )}
                    </ContentWrapper>
                </ContentBox>
                {FooterBtnRow}
            </Wrapper>
        );
    }
};

export default withTheme(UsersEditView);

const Wrapper = styled.div`
    width: min(600px, 95vw);
    min-height: 350px;
`;

const ContentWrapper = styled.div`
    padding: 0.8rem 1.5rem 0.2rem 1.5rem;
    min-height: 300px;
    position: relative;
    background-color: ${({ theme, searchBoxFocus }) =>
        searchBoxFocus ? `${theme.colors.secondary}10` : `inherit`};
    @media (max-width: ${({ theme }) => theme.sizes.mdViewWidth}) {
        padding: 0.5rem 0.7rem;
    }
`;

const DisableClicksInGreyBox = styled.div`
    position: absolute;
    z-index: 3;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding-bottom: -3rem;
`;

const TagCol = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 0;
    position: relative;
    z-index: 0;
`;

const TitleRow = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 0.3rem;
    min-height: 2.3rem;
`;

const AddTitleRow = styled(TitleRow)`
    min-height: 0;
`;

const SectionTitle = styled.h6`
    margin: 0;
    padding-right: 1rem;
    font-weight: 600;
    /* padding-bottom: 1rem; */
`;

const AddTagSection = styled.div`
    padding: 0rem 0 1.5rem 0;
`;

const SearchWrapper = styled.div`
    padding-top: 0.3rem;
    position: relative;
    z-index: 3;
`;

const FooterBtnBlock = styled.div`
    display: flex;
    width: 100%; /* Match width of parent  */
    padding: 0 1rem;
    justify-content: flex-end;
`;

const LeftBtnWrapper = styled.div`
    flex: 0 1 200px;
    margin-right: 1rem;
`;

const RightBtnWrapper = styled.div`
    flex: 0 1 200px;
`;

const BtnText = styled.div`
    font-weight: 500;
`;
