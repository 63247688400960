import styled from "styled-components";
import AppLogo from "../../library/common/AppLogo";
import LoadingDots from "../../library/common/LoadingDots";

const AppLoadingView = ({ theme }) => {
    return (
        <Wrapper>
            <LogoWrapper>
                <AppLogo />
            </LogoWrapper>
            <DotsWrapper>
                <LoadingDots
                    size={"10px"}
                    baseColor={theme.colors.offSecondary}
                    focusColor={theme.colors.primary}
                />
            </DotsWrapper>
        </Wrapper>
    );
};

export default AppLoadingView;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.colors.secondary};
`;

const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 67%;
    max-width: 425px;
`;

const DotsWrapper = styled.div`
    position: absolute;
    bottom: 3rem;
`;
