import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTheme } from "styled-components";

import PostContainer from "./Post-container";
import withLoading from "../../library/hoc/WithLoadingScreen";
import { addError, removeError } from "../../main/store/actions/errors";
import { removeFromFeed } from "../../main/store/actions/newsFeed";
import { updateFeed } from "../../main/store/actions/newsFeed";

function mapState(state) {
    return {
        currentUser: state.currentUser,
        errors: state.errors,
    };
}

const mapDispatch = {
    addError,
    removeError,
    removeFromFeed,
    updateFeed,
};

export default withRouter(
    withLoading(connect(mapState, mapDispatch)(withTheme(PostContainer)))
);
