import styled, { withTheme } from "styled-components";
import { useState, useRef } from "react";

import { UseOutsideClick } from "../../../utilities";
import ButtonCircleIcon from "../../ButtonCircleIcon";
import Button from "../../Button";

const btnSize = "2rem";

const EditPostButtonView = ({
    theme,
    userIsAdmin,
    userInAccounts,
    leaveText,
    leaveIcon,
    handleEditClick,
    handleLeave,
}) => {
    const [showPopover, setShowPopover] = useState(false);

    const ref = useRef();
    UseOutsideClick(ref, () => {
        setShowPopover(false);
    });

    return (
        <EditPostButtonCol>
            <ButtonCircleIcon
                handleClick={() => setShowPopover(true)}
                circleDiameter={btnSize}
                fontSize={"1rem"}
                bgColor={theme.colors.offPrimary}
                iconColor={theme.colors.secondary}
            >
                <i className="fas fa-ellipsis-h"></i>
            </ButtonCircleIcon>
            {showPopover && (
                <PopoverContent ref={ref} btnSize={btnSize}>
                    {!!userIsAdmin && (
                        <ButtonWrapper>
                            <Button
                                handleClick={handleEditClick}
                                icon={<i className="fas fa-pen"></i>}
                                secondaryBtnColors={true}
                                iconColor={theme.colors.accent}
                                noShadow={true}
                            >
                                <BtnText>Edit</BtnText>
                            </Button>
                        </ButtonWrapper>
                    )}
                    {!userIsAdmin && !!userInAccounts && (
                        <ButtonWrapper>
                            <Button
                                handleClick={() => {
                                    handleLeave();
                                    setShowPopover(false);
                                }}
                                icon={leaveIcon}
                                secondaryBtnColors={true}
                                iconColor={theme.colors.text.semantic.error}
                                noShadow={true}
                            >
                                <BtnText>{leaveText}</BtnText>
                            </Button>
                        </ButtonWrapper>
                    )}
                </PopoverContent>
            )}
        </EditPostButtonCol>
    );
};

export default withTheme(EditPostButtonView);

const EditPostButtonCol = styled.div`
    position: relative;
`;

const PopoverContent = styled.div`
    position: absolute;
    z-index: 3;
    top: calc(${(props) => props.btnSize} + 0.3rem);
    right: -6rem;
    padding: 1rem;
    min-width: 11rem;
    border-radius: 10px;
    background-color: ${(props) => props.theme.colors.primary};
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
`;

const ButtonWrapper = styled.div`
    padding: 0.25rem 0;
`;

const BtnText = styled.div`
    font-weight: 500;
`;
