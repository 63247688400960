import ButtonCircle from "../ButtonCircleIcon";
import styled from "styled-components";

const ButtonTrashCircleView = ({
    handleClick,
    theme,
    circleDiameter, // all props from here done optional
    fontSize,
    bgColor,
    iconColor,
}) => {
    return (
        <ButtonCircle
            handleClick={handleClick}
            bgColor={bgColor ? bgColor : theme.colors.semantic.error}
            iconColor={iconColor ? iconColor : theme.colors.secondary}
            borderColor={bgColor ? bgColor : theme.colors.semantic.error}
            circleDiameter={circleDiameter}
            fontSize={fontSize}
        >
            <TrashIcon className="fas fa-trash-alt"></TrashIcon>
        </ButtonCircle>
    );
};

export default ButtonTrashCircleView;

const TrashIcon = styled.div`
    margin-bottom: 0.1rem;
`;
