// TODO - copied directly from Archibuild. Needs full review.

export const ADD_ERROR = "ADD_ERROR";
export const REMOVE_ERROR = "REMOVE_ERROR";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_NEWS_FEED = "SET_NEWS_FEED";
export const CLEAR_NEWS_FEED = "CLEAR_NEWS_FEED";
export const REMOVE_FROM_FEED = "REMOVE_FROM_FEED";
export const SET_SHOW_SIDEBAR = "SET_SHOW_SIDEBAR";
export const SET_MY_SITES = "SET_MY_SITES";
export const CLEAR_MY_SITES = "CLEAR_MY_SITES";

// export const LOAD_MESSAGES = "LOAD_MESSAGES";
// export const REMOVE_MESSAGE = "REMOVE_MESSAGE";
// export const ADD_COMPANY = "ADD_COMPANY";
// export const SET_USER_TYPE = "SET_USER_TYPE";
// export const SET_USER_NOTIFICATIONS = "SET_USER_NOTIFICATIONS";
// export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";
// export const REFRESH_NOTIFICATIONS = "REFRESH_NOTIFICATIONS";
