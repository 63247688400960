import styled, { withTheme } from "styled-components";
import { withRouter } from "react-router";

const TabNavbarView = ({
    theme,
    history,
    pageToDisplay,
    commonUrl,
    pages,
    loading,
    bgColor = theme.colors.primary,
    textColor = theme.colors.secondary,
    selectedColor = theme.colors.accent,
}) => {
    const tabs = loading ?
        pages.map((_, i) => (
            <Tab
                key={i}
                borderColor={bgColor}
                textColor={textColor}
                numTabs={pages.length}
            >
                <TextLoading />
            </Tab>
        )) :
        pages.map((pageObj, i) => {
            const selected = pageToDisplay === pageObj.name;
            return (
                <Tab
                    key={i}
                    onClick={() => { history.push(commonUrl + pageObj.urlExtension); }}
                    borderStyle={selected ? `2px solid ${selectedColor}` : "none"}
                    textColor={selected ? selectedColor : textColor}
                    numTabs={pages.length}
                >
                    <Text>{pageObj.name}</Text>
                </Tab>
            );
        });
    return (
        <TabBar bgColor={bgColor}>
            {tabs}
        </TabBar>
    );
};

export default withTheme(withRouter(TabNavbarView));

export const TabBar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: ${({ bgColor }) => bgColor};
    /* border-radius: 1rem; */
    min-height: 4rem;
    margin: 0 15px; /* needed to stop tab hiding header border on smaller screens*/
    padding: 0.3rem 2rem;
    border-top: 5px solid ${({ theme }) => theme.colors.body};
    @media (max-width: ${({ theme }) => theme.sizes.mdViewWidth}) {
        padding: 0rem 0.3rem;
    }
    /* position: relative; */
`;

const Tab = styled.button`
    /* flex: 1; */
    border-top: ${({ borderStyle }) => borderStyle};
    border-bottom: ${({ borderStyle }) => borderStyle};
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ textColor }) => textColor};
    min-height: 3.5rem;
    padding: 0 3rem; /* Defines width of selected tab bars above and below word */
    @media (max-width: ${({ theme }) => theme.sizes.smViewWidth}) {
        /* Small screen size tab padding depends on number of tabs in tab bar*/
        padding: 0 ${(props) => (props.numTabs > 3 ? "0.5rem" : "1.5rem")};
    }
`;

const Text = styled.h6`
    /* font-weight: 600; */
    font-size: 105%;
    margin: 0;
`;

const TextLoading = styled.div`
    width: 5rem;
    height: 1rem;
    background-color: ${(props) => props.theme.colors.offPrimary};
    border-radius: 15px;
`;
