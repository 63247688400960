import styled from "styled-components";
import { lighten } from "polished";

import ButtonCircleIcon from "../../library/common/ButtonCircleIcon";
import { NAV_POPUP_TYPES } from "../../library/common/constants";

const btnSize = "2.3rem";
const iconFontSize = "1.2rem";

// Content of dropdown when user hits plus button in navbar
const DropdownAddView = ({ theme, history, fontSize, setPopUp }) => {
    return (
        <Wrapper>
            <Heading>Create</Heading>
            <Item onClick={() => setPopUp(NAV_POPUP_TYPES.createPost)}>
                <ButtonCircleIcon
                    circleDiameter={btnSize}
                    fontSize={iconFontSize}
                    theme={theme}
                >
                    <i className="fas fa-image"></i>
                </ButtonCircleIcon>
                <TextWrapper>
                    <Text>Post</Text>
                    <Info mainFontSize={fontSize}>
                        Share your work, build your portfolio.
                    </Info>
                </TextWrapper>
            </Item>
            <Item onClick={() => history.push(`/sites`)}>
                <ButtonCircleIcon
                    circleDiameter={btnSize}
                    fontSize={iconFontSize}
                    theme={theme}
                >
                    <i className="fas fa-map-pin"></i>
                </ButtonCircleIcon>
                <TextWrapper>
                    <Text>Site</Text>
                    <Info mainFontSize={fontSize}>
                        Lift your game with work sites.
                    </Info>
                </TextWrapper>
            </Item>
            <Item onClick={() => history.push(`/companies`)}>
                <ButtonCircleIcon
                    circleDiameter={btnSize}
                    fontSize={iconFontSize}
                    theme={theme}
                >
                    <i className="fas fa-building"></i>
                </ButtonCircleIcon>
                <TextWrapper>
                    <Text>Company</Text>
                    <Info mainFontSize={fontSize}>
                        Industry reputation is everything.
                    </Info>
                </TextWrapper>
            </Item>
        </Wrapper>
    );
};

export default DropdownAddView;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 1rem;
`;

const Heading = styled.h3`
    margin: 0 0 0.4rem 0;
    color: ${(props) => props.theme.colors.darkAccent};
`;

const Item = styled.div`
    display: flex;
    align-items: center;
    margin: 0.2rem 0;
    padding: 0.2rem;
    cursor: pointer;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 1rem;
`;

const Info = styled.p`
    margin: -0.2rem 0 0 0;
    font-size: calc(${({ mainFontSize }) => mainFontSize} / 1.5);
    color: ${({ theme }) => lighten(0.1, theme.colors.secondary)};
`;

const Text = styled.p`
    margin: 0 0 0 0;
`;
