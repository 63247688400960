import React, { useEffect } from "react";
import styled from "styled-components";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import {
    MAP_ICON_BOBCAT_O,
    MAP_ICON_BOBCAT_Y,
    MAP_ICON_DIGGER_O,
    MAP_ICON_DIGGER_Y,
    MAP_ICON_LOADER_Y,
    MAP_ICON_LOADER_O,
    // MAP_ICON_ROLLER_O,
    MAP_ICON_ROLLER_Y,
    // MAP_ICON_TRUCK_O,
    MAP_ICON_TRUCK_Y,
} from "../../library/common/constants";
import Icon from "../../library/common/Icon";

const slideInVariant = ({
    delay = 0, // Default no delay
}) => ({
    visible: { y: 0, transition: { duration: 0.5, delay: delay + 0 } },
    hidden: { y: -35, transition: { duration: 0.1, delay: 0 } },
});

const MapSection = () => {
    const control = useAnimation();
    const { ref: topRef, inView: topInView } = useInView();
    const { ref: bottomRef, inView: bottomInView } = useInView();
    useEffect(() => {
        if (topInView && bottomInView) {
            control.start("visible");
        } else if (topInView || bottomInView) {
            return;
        } else {
            control.start("hidden");
        }
    }, [control, topInView, bottomInView]);

    const commonAnimationProps = {
        initial: "hidden",
        animate: control,
    };

    return (
        <SectionWrapper>
            <PhoneWrapperOuter>
                <PhoneWrapperInner>
                    <TopOfPhone ref={topRef} />
                    <MapPin
                        height={2}
                        src={MAP_ICON_DIGGER_Y}
                        top={10}
                        left={65}
                        variants={slideInVariant({ delay: 0.51 })}
                        {...commonAnimationProps}
                    />

                    <MapPin
                        height={3}
                        src={MAP_ICON_BOBCAT_O}
                        top={23}
                        left={68}
                        variants={slideInVariant({ delay: 0.42 })}
                        {...commonAnimationProps}
                    />
                    <MapPin
                        height={5}
                        src={MAP_ICON_ROLLER_Y}
                        top={34}
                        left={50}
                        variants={slideInVariant({ delay: 0.35 })}
                        {...commonAnimationProps}
                    />
                    <MapPin
                        height={6}
                        src={MAP_ICON_LOADER_Y}
                        top={35}
                        left={15}
                        variants={slideInVariant({ delay: 0.28 })}
                        {...commonAnimationProps}
                    />
                    <MapPin
                        height={7}
                        src={MAP_ICON_DIGGER_O}
                        top={51}
                        left={55}
                        variants={slideInVariant({ delay: 0.21 })}
                        {...commonAnimationProps}
                    />
                    <MapPin
                        height={8}
                        src={MAP_ICON_TRUCK_Y}
                        top={60}
                        left={35}
                        variants={slideInVariant({ delay: 0.14 })}
                        {...commonAnimationProps}
                    />
                    <MapPin
                        height={9}
                        src={MAP_ICON_BOBCAT_Y}
                        top={60}
                        left={15}
                        variants={slideInVariant({ delay: 0.07 })}
                        {...commonAnimationProps}
                    />
                    <MapPin
                        height={10}
                        src={MAP_ICON_LOADER_O}
                        top={75}
                        left={17}
                        variants={slideInVariant({ delay: 0 })}
                        {...commonAnimationProps}
                    />
                    <Phone
                        src={
                            "https://cosqos-bucket.s3.ap-southeast-2.amazonaws.com/PhoneMap.png"
                        }
                    />
                    <BottomOfPhone ref={bottomRef} />
                </PhoneWrapperInner>
            </PhoneWrapperOuter>
            <ExplanationSection>
                <IconRow>
                    <IconCircle>
                        <Icon name={"map"} />
                    </IconCircle>
                </IconRow>
                <TitleText>Projects</TitleText>
                <BodyText>
                    Be in the know. BoomsUp keeps you connected to what's
                    happening in your region.
                </BodyText>
            </ExplanationSection>
        </SectionWrapper>
    );
};

export default MapSection;

const mapWidth = 700;
// const mapHeightSm = 400;
const mapScreenSizeBreak = "900px";

const SectionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    /* background-color: red; */
    @media (max-width: ${mapScreenSizeBreak}) {
        flex-direction: column;
        align-items: center;
    }
`;

const PhoneWrapperOuter = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
`;

const PhoneWrapperInner = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
`;

const AppDownloadCol = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const TopOfPhone = styled.div`
    position: absolute;
    top: 0;
`;
const BottomOfPhone = styled.div`
    position: absolute;
    bottom: 0;
`;

const MapGraphic = styled.img`
    width: ${mapWidth}px;
`;

const Phone = styled.img`
    width: 320px;
    position: relative;
    @media (max-width: ${mapScreenSizeBreak}) {
        width: 290px;
    }
`;

const MapPin = styled(motion.img)`
    position: absolute;
    height: 80px;
    @media (max-width: ${mapScreenSizeBreak}) {
        height: 65px;
    }
    z-index: ${(props) => props.height};
    top: ${(props) => props.top}%;
    left: ${(props) => props.left}%;
`;

const ExplanationSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: ${mapScreenSizeBreak}) {
        padding: 30px 25px 20px 25px;
    }
`;

const TitleText = styled.div`
    /* display: flex;
    align-items: center;
    text-align: center; */
    /* background-color: cyan; */
    font-family: "Montserrat", sans-serif;
    max-width: 400px;
    font-size: 45px;
    font-weight: 600;
    text-align: center;
    @media (max-width: ${mapScreenSizeBreak}) {
        font-size: 24px;
        text-align: left;
    }
`;

const BodyText = styled(TitleText)`
    text-align: left;
    max-width: 360px;
    font-size: 15px;
    font-weight: 400;
`;

const IconRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* padding-top: ${(props) => (props.padTop ? "40px" : "0px")}; */
    padding-bottom: 20px;
    @media (max-width: ${mapScreenSizeBreak}) {
        display: none;
    }
`;

const IconCircle = styled.div`
    color: ${(props) => props.theme.colors.lightAccent};
    background-color: ${(props) => props.theme.colors.secondary};
    box-shadow: 16px 5px 40px #00000030;
    font-size: 85px;
    border-radius: 50%;
    width: 180px;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: ${(props) => props.padBottom || 0};
`;
