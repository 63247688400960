import styled, { withTheme } from "styled-components";

import { Wrapper } from "./PostPreview-view";
import PostCreateFooterBar from "./PostCreateFooterBar-view";

import Avatar from "../../../library/common/Avatar";
import HeaderBar from "../../../library/common/PopupHeaderBar";

import { ContentBox } from "../../../resources/styles";

const avatarSize = "3rem";
const SelectAuthorScreenView = ({
    theme,
    currentUser,
    selectedCompanyId,
    setPopUpToDisplay,
    handleClose,
    handleNext,
    setAuthorToCompany,
    setAuthorToUser,
}) => {

    const companiesToRender = currentUser.user.companiesAdminOf.map((company, i) => (
        <AuthorRow
            key={i}
            selected={company._id === selectedCompanyId}
            onClick={() => setAuthorToCompany(company)}
        >
            <Avatar
                path={company.img}
                user={true} // if path is false or undefined will use user default picture
                size={avatarSize}
                borderColor={theme.colors.offPrimary}
            />
            <Name>{company.name}</Name>
        </AuthorRow>
    ));

    return (
        <Wrapper>
            <HeaderBar
                setPopUpToDisplay={setPopUpToDisplay}
                popupTitle={"Create Post"}
                customExitHandling={handleClose}
            />
            <ContentBox>
                <ContentWrapper>
                    <Title>Post Author</Title>
                    <AuthorRow
                        selected={selectedCompanyId === undefined}
                        onClick={setAuthorToUser}
                    >
                        <Avatar
                            path={currentUser.user.img}
                            user={true} // if path is false or undefined will use user default picture
                            size={avatarSize}
                            borderColor={theme.colors.offPrimary}
                        />
                        <Name>{currentUser.user.username}</Name>
                    </AuthorRow>
                    {companiesToRender}
                </ContentWrapper>
            </ContentBox>
            <PostCreateFooterBar
                handleNext={handleNext}
                nextText={"Next"}
                theme={theme}
            />
        </Wrapper>
    );
};

export default withTheme(SelectAuthorScreenView);

const ContentWrapper = styled.div`
    padding: 2rem 1rem;
`;

const AuthorRow = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 1rem;
    border-radius: 1rem;
    margin-bottom: 1rem;
    background-color: ${(props) =>
        props.selected ? props.theme.colors.offPrimary : null};
`;

const Name = styled.h5`
    margin: 0;
    padding-left: 0.7rem;
`;

const Title = styled.h5`
    margin: 0 0 1rem 0;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid ${(props) => props.theme.colors.offSecondary};
`;
