import styled from "styled-components";

import LicenceItems from "../LicenceItems";
import { SECTION_TITLES } from "../../../../library/common/constants";
import FooterBar from "../../../../library/common/PopupFooterBar";
import HeaderBar from "../../../../library/common/PopupHeaderBar";
import { ContentBox } from "../../../../resources/styles";
import { Wrapper } from "./LicenceDetails-view";

const LicenceListView = ({
    licences,
    handleEditClick,
    handleAddClick,
    setPopUpToDisplay,
    handleLicenceDelete,
}) => {
    return (
        <Wrapper>
            <HeaderBar
                setPopUpToDisplay={setPopUpToDisplay}
                handleAdd={handleAddClick}
                popupTitle={SECTION_TITLES.licences}
            />
            <ContentBox>
                <ContentWrapper>
                    {licences && licences[0] && (
                        <LicenceItems
                            items={licences}
                            handleEditClick={handleEditClick}
                            handleLicenceDelete={handleLicenceDelete}
                            dark={false}
                        />
                    )}
                </ContentWrapper>
            </ContentBox>
            <FooterBar
                handleDone={() => {
                    setPopUpToDisplay("");
                }}
                doneLabel={"Done"}
            />
        </Wrapper>
    );
};

export default LicenceListView;

const ContentWrapper = styled.div`
    padding: 1rem 1.5rem;
    @media (max-width: ${({ theme }) => theme.sizes.mdViewWidth}) {
        padding: 0.8rem;
    }
`;
