import styled from "styled-components";
import { forwardRef } from "react";

import AccountControls from "./DropControls-view";
import Add from "./DropAdd-view";
import Notifications from "./DropNtf-view";
import Messages from "./DropMsgs-view";
import { NAV_DROPDOWN_TYPES } from "../../library/common/constants";
import { useMultiRefsOutsideClick } from "../../library/utilities";
import { ThumbOnlyOverflowScroll } from "../../resources/styles";

// Dropdown box renders relevant content depending on value of dropdown
// forward ref used to pass in ref object from parent
const DropdownView = forwardRef((props, ref) => {
    const {
        theme,
        dropdown,
        currentUser,
        logoutUser,
        history,
        fontSize,
        setPopUp,
        setDropdown,
    } = props;

    // Listens for click not in one of the ref values, if true closes dropdown
    useMultiRefsOutsideClick(ref, (e) => {
        setDropdown("");
    });

    // If this function works then handleItemClick can be removed
    const stopPropagationFromDropdown = (e) => {
        e.stopPropagation();
        setDropdown(""); // remove dropdown
    };

    const getDropdown = () => {

        switch (dropdown) {

            case NAV_DROPDOWN_TYPES.accountControls: return (
                <AccountControls
                    currentUser={currentUser}
                    logoutUser={logoutUser}
                    theme={theme}
                    history={history}
                />
            );

            case NAV_DROPDOWN_TYPES.add: return (
                <Add
                    theme={theme}
                    history={history}
                    fontSize={fontSize}
                    setPopUp={setPopUp}
                />
            );

            case NAV_DROPDOWN_TYPES.notifications: return <Notifications history={history} />;
            case NAV_DROPDOWN_TYPES.messages: return <Messages history={history} />;
            default: return;
        }
    };

    return (
        <Dropdown
            ref={(el) => (ref.current[0] = el)}
            onClick={stopPropagationFromDropdown}
        >
            {getDropdown()}
            {/* {dropdown === NAV_DROPDOWN_TYPES.accountControls && (
                <AccountControls
                    currentUser={currentUser}
                    logoutUser={logoutUser}
                    theme={theme}
                    history={history}
                />
            )}
            {dropdown === NAV_DROPDOWN_TYPES.add && (
                <Add
                    theme={theme}
                    history={history}
                    fontSize={fontSize}
                    setPopUp={setPopUp}
                />
            )}
            {dropdown === NAV_DROPDOWN_TYPES.notifications && (
                <Notifications history={history} />
            )}
            {dropdown === NAV_DROPDOWN_TYPES.messages && (
                <Messages
                    history={history}
                    messagesSocketState={messagesSocketState}
                />
            )} */}
        </Dropdown>
    );
});

export default DropdownView;

const Dropdown = styled(ThumbOnlyOverflowScroll)`
    position: absolute;
    top: ${({ theme }) => theme.sizes.navHeight.slice(0, -3) - 0.2 + "rem"};
    right: 1.2rem;
    width: 400px;
    background-color: ${({ theme }) => theme.colors.primary};
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    max-height: calc(100vh - ${({ theme }) => theme.sizes.navHeight} - 2rem);
    @media (max-width: ${({ theme }) => theme.sizes.smViewWidth}) {
        width: 100%;
        right: 0;
    }
`;

// const ScrollableArea = styled.div`
//     max-height: calc(100vh - ${({ theme }) => theme.sizes.navHeight} - 2rem);
//     padding: 1rem;
//     overflow-y: scroll;
// `;
