// import "../../../../resources/styles/TickBox.css";
import styled from "styled-components";
import { lighten } from "polished";

// Box that show a tick inside it if showTick props true. Size is passed in as prop
const TickBoxView = ({ showTick, dark, theme, size }) => {
    const bgColor = dark ? theme.colors.secondary : theme.colors.offPrimary;
    const iconColor = dark
        ? lighten(0.25, theme.colors.accent)
        : theme.colors.accent;

    return (
        <Box bgColor={bgColor} size={size}>
            {showTick && (
                <Tick
                    iconColor={iconColor}
                    size={size}
                    className="far fa-check-circle"
                />
            )}
        </Box>
    );
};

export default TickBoxView;

const Box = styled.div`
    background-color: ${({ bgColor }) => bgColor};
    border-radius: 10px;
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Tick = styled.i`
    font-size: calc(${(props) => props.size} / 1.4);
    color: ${(props) => props.iconColor};
`;
