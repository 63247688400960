import styled, { withTheme } from "styled-components";

import SiteInfoBar from "./SiteInfoBar-view";
import CompanyInfoBar from "./CompanyInfoBar-view";

import Button from "../../library/common/Button";
import Icon from "../../library/common/Icon";
import LoadingDots from "../../library/common/LoadingDots";
import Map from "../../library/common/Map";

import { MAP_INFO_DATA_TYPES } from "../../library/common/constants";

const ExploreMapView = ({
    theme,
    isLoading,
    history,
    showRefreshBtn,
    configureMapBehaviour,
    addMarkersToMap,
    refreshMap,
    mapData,
    infoBarContent,
    toggleView,
    expandedWindow,
}) => {
    const critScSize = theme.sizes.smViewWidth;

    const { type: infoBarType } = infoBarContent;

    const infoBarProps = {
        theme,
        infoBarContent,
        history,
        critScSize,
        toggleView,
        expandedWindow,
    };

    const infoBar =
        infoBarType === MAP_INFO_DATA_TYPES.site ? (
            <SiteInfoBar {...infoBarProps} />
        ) : infoBarType === MAP_INFO_DATA_TYPES.company ? (
            <CompanyInfoBar {...infoBarProps} />
        ) : null;

    return (
        <WrapperOuter>
            <WrapperInner critScSize={critScSize} showInfoBar={infoBar}>
                <InfoBarOuterWrapper
                    expandedWindow={expandedWindow}
                    critScSize={critScSize}
                    showInfoBar={infoBar}
                >
                    {infoBar}
                </InfoBarOuterWrapper>
                <MapWrapper>
                    {showRefreshBtn && (
                        <RefreshButtonWrapper>
                            <Button
                                handleClick={refreshMap}
                                icon={<Icon name="search-location" />}
                                secondaryBtnColors={true}
                                textColor={theme.colors.accent}
                                btnPadding={"0.5rem 1rem"}
                                borderRadius={"1rem"}
                                textSize={"1.1rem"}
                            >
                                <b>Search this Area</b>
                            </Button>
                        </RefreshButtonWrapper>
                    )}
                    {isLoading && (
                        <MapLoadingBox>
                            <LoadingDots size={"20px"} />
                        </MapLoadingBox>
                    )}
                    <Map
                        configureMapBehaviour={configureMapBehaviour}
                        addMarkersToMap={addMarkersToMap}
                        locationsArray={mapData}
                    />
                </MapWrapper>
            </WrapperInner>
        </WrapperOuter>
    );
};

export default withTheme(ExploreMapView);

const largeScInfoBarWidth = "350px";

const WrapperOuter = styled.div`
    position: relative;
    height: calc(100vh - ${({ theme }) => theme.sizes.navHeight});
    width: 100%;
    overflow: hidden;
`;

const WrapperInner = styled.div`
    display: flex;
    /* On large screen when Info bar not show hide it to the left of viewport, shrink width when showing so info bar is on screen */
    width: ${(props) =>
        props.showInfoBar ? `100%` : `calc(100% + ${largeScInfoBarWidth})`};
    float: right; /* Div grow right to left, so infoBar hidden to left */
    height: 100%;
    transition: width 0.15s ease-out, height 0s;
    @media (max-width: ${({ critScSize }) => critScSize}) {
        transition: width 0s, height 0.15s ease-out;
        width: 100%;
        /* On small screens if when info bar not shown hide below view port */
        height: ${({ showInfoBar, theme }) =>
            showInfoBar ? "100%" : `calc(100% + ${largeScInfoBarWidth})`};
        flex-direction: column-reverse; /* repositions order of contents (image below site name section*/
    }
`;

const MapWrapper = styled.div`
    position: relative;
    z-index: 0; /* create new stacking context */
    flex: 1;
    background-color: ${({ theme }) => theme.colors.offPrimary};
`;

// const SearchWrapper = styled.div`
//     position: absolute;
//     top: 60px;
//     padding: 0 0.3rem;
//     z-index: 1;
//     left: 50%;
//     width: 700px;
//     margin-left: calc(-700px / 2); /* half thw width */
//     @media (max-width: ${({ theme }) => theme.sizes.smViewWidth}) {
//         width: 100%;
//         margin-left: calc(-100% / 2); /* half thw width */
//     }
// `;

const MapLoadingBox = styled.div`
    /* Fill map area with loading overlay, position above everything in map */
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const refreshBtnWidth = "250px";

const RefreshButtonWrapper = styled.div`
    position: absolute;
    bottom: 100px;
    z-index: 1;
    width: ${refreshBtnWidth};
    margin-left: calc(-${refreshBtnWidth} / 2); /* half thw width */
    left: 50%;
`;

const InfoBarOuterWrapper = styled.div`
    flex: 0 0 ${largeScInfoBarWidth};
    background-color: ${(props) => props.theme.colors.primary};
    max-height: 100%;
    overflow-y: auto;
    @media (max-width: ${({ critScSize }) => critScSize}) {
        position: ${(props) => (props.expandedWindow ? "absolute" : "auto")};
        z-index: ${(props) => (props.expandedWindow ? 1 : "auto")};
        height: ${(props) => (props.expandedWindow ? "100%" : "auto")};
        top: ${(props) => (props.expandedWindow ? 0 : "auto")};
        overflow: ${(props) => (props.expandedWindow ? "scroll" : "hidden")};
        width: ${(props) => (props.expandedWindow ? "100%" : "auto")};
    }
`;
