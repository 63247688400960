import styled from "styled-components";

import {
    formatDateForPost,
    handleUserClick,
    handleCompanyClick,
    useUpdateTime,
} from "../../library/utilities";
import { PRIMARY_AUTHOR } from "../../library/common/constants";
import Avatar from "../../library/common/Avatar";
import EditPostButton from "./EditPostBtn-view";

export const AuthorAvatarSize = "3rem"; // exported to PstLoading-view

// Post header with avatar, name and date
const PostHeaderView = ({
    post,
    history,
    theme,
    setPopUpToDisplay,
    currentUser,
    createPostView,
    userIsAdmin,
    currentUserInTags,
}) => {
    const timeNow = useUpdateTime(30000);

    const companyPost = post.primaryAuthor === PRIMARY_AUTHOR.COMPANY;

    const handleAuthorClick = (e) => {
        if (createPostView) return;
        if (companyPost) handleCompanyClick(e, history, post.company._id);
        else handleUserClick(e, history, post.user._id);
    };
    return (
        <PostHeader>
            <Avatar
                path={companyPost ? post.company.img : post.user.img}
                letterAsAltImg={
                    companyPost ?
                        {
                            altLetter: post.company.name[0],
                            fontSize: "2rem",
                        } : undefined
                }
                user={!companyPost}
                company={companyPost}
                size={AuthorAvatarSize}
                handleClick={handleAuthorClick}
                borderColor={theme.colors.offPrimary}
            />
            <HeaderTextCol>
                <AuthorName onClick={handleAuthorClick}>
                    {post.primaryAuthor === PRIMARY_AUTHOR.COMPANY
                        ? post.company.name
                        : post.user.username}
                </AuthorName>
                {!createPostView && ( // no date when creating a post
                    <PostDate>
                        {formatDateForPost(post.createdAt, timeNow)}
                    </PostDate>
                )}
            </HeaderTextCol>
            {(userIsAdmin || currentUserInTags) && (
                <EditPostButton
                    theme={theme}
                    setPopUpToDisplay={setPopUpToDisplay}
                    userIsAdmin={userIsAdmin}
                    currentUserInTags={currentUserInTags}
                />
            )}
        </PostHeader>
    );
};

export default PostHeaderView;

export const PostHeader = styled.div`
    display: flex;
    padding: 1rem;
`;

export const HeaderTextCol = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; /* Stop AuthorName form filing whole width of HeaderTextCol*/
    padding: 0rem 0.8rem;
    min-width: 0;
`;

const AuthorName = styled.h6`
    cursor: pointer;
    margin: 0rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const PostDate = styled.p`
    margin: 0.1rem 0 0 0;
    color: ${(props) => props.theme.colors.offSecondary};
    font-size: 80%;
    white-space: nowrap;
`;
