const CompanyListView = ({
    searchResults,
    handleChange,
    handleCardClick,
    handleLoadMore,
    canLoadMore,
    isSearching,
}) => {
    const formattedResultsList =
        searchResults.length > 0
            ? searchResults.map((result) => {
                  return (
                      <li
                          className="company-card"
                          key={result._id}
                          onClick={() => handleCardClick(result._id)}
                      >
                          <div className="row">
                              <div className="col-2">
                                  {!!result.logo && (
                                      <img
                                          src={result.logo}
                                          alt={`${result.username}`}
                                          style={{
                                              width: "100px",
                                              height: "100px",
                                              objectFit: "cover",
                                          }}
                                      />
                                  )}
                              </div>
                              <div className="col-10">
                                  <h3> {result.name} </h3>
                              </div>
                          </div>
                      </li>
                  );
              })
            : "";
    return (
        <div className="container-fluid">
            <div className="" id="homeBox">
                <h3>Find a company:</h3>
                <input
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    autoComplete="off"
                />
                <br></br>

                <ul>{formattedResultsList}</ul>

                {canLoadMore && (
                    <div
                        className="text-center"
                        style={{ cursor: "pointer" }}
                        onClick={handleLoadMore}
                    >
                        <h5>Load more results</h5>
                    </div>
                )}
                {isSearching && (
                    <div className="text-center">
                        <h5>Searching...</h5>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CompanyListView;
