import styled, { withTheme } from "styled-components";

import {
    COMPANY_POPUP_TYPES,
    USER_POPUP_TYPES,
} from "../../../common/constants";
import {
    FooterBar,
    ContentBox,
    FooterBarContent,
    LeftBtnWrapper,
    MiddleBtnWrapper,
    RightBtnWrapper,
} from "../../../../resources/styles/index";
import Button from "../../Button";
import Alert from "../../../common/Alert";
import { ALERT_TYPES } from "../../constants";
import TrashButton from "../../ButtonTrashCircle";
import HeaderBar from "../../PopupHeaderBar";
import LoadingSpinner from "../../LoadingSpinner";

const ImageEditPopUpView = ({
    popupTitle,
    theme,
    type,
    validationFailedState,
    imageState,
    setPopUpToDisplay,
    onFileChange,
    onImageDelete,
    handleSubmit,
    defaultImg,
    imgLoadingToDom,
}) => {
    return (
        <Wrapper>
            <HeaderBar
                setPopUpToDisplay={setPopUpToDisplay}
                popupTitle={popupTitle}
            />
            <ContentBox>
                {!!imgLoadingToDom && ( // loading spinner while image is being uploaded to DOM
                    <LoadingCircleRow>
                        <LoadingCircle>
                            <LoadingSpinner />
                        </LoadingCircle>
                    </LoadingCircleRow>
                )}
                {(type === COMPANY_POPUP_TYPES.coverPhoto ||
                    type === USER_POPUP_TYPES.coverPhoto) && (
                        <CoverPicWrapper>
                            <CoverPicArea />
                            <CoverPic
                                src={(imageState && imageState.path) || defaultImg}
                                alt={defaultImg}
                            ></CoverPic>
                        </CoverPicWrapper>
                    )}
                {(type === COMPANY_POPUP_TYPES.logo ||
                    type === USER_POPUP_TYPES.displayPicture) && (
                        <DisplayPicWrapper>
                            <DisplayPicArea />
                            <DisplayPicture
                                src={(imageState && imageState.path) || defaultImg}
                                alt={defaultImg}
                            ></DisplayPicture>
                        </DisplayPicWrapper>
                    )}
                <ImgButton>
                    <TrashButton
                        handleClick={onImageDelete}
                        circleDiameter={"2.8rem"}
                        fontSize={"1.2rem"}
                        iconColor={theme.colors.secondary}
                        bgColor={theme.colors.primary}
                    />
                </ImgButton>
                {validationFailedState.uploadError && (
                    <Row>
                        <UploadError>
                            <Alert
                                message={validationFailedState.uploadError}
                                type={ALERT_TYPES.ERROR}
                                hideBorder={true}
                            />
                        </UploadError>
                    </Row>
                )}
            </ContentBox>
            <FooterBar>
                <FooterBarContent>
                    <LeftBtnWrapper>
                        <Button
                            handleClick={() => {
                                setPopUpToDisplay("");
                            }}
                            textSize={"1rem"}
                            secondaryBtnColors={true}
                        >
                            Cancel
                        </Button>
                    </LeftBtnWrapper>
                    <MiddleBtnWrapper>
                        <Button
                            handleClick={onFileChange}
                            fileUpload={true}
                            textSize={"1rem"}
                            secondaryBtnColors={true}
                            icon={<i className="fas fa-image"></i>}
                        >
                            Choose
                        </Button>
                    </MiddleBtnWrapper>
                    <RightBtnWrapper>
                        <Button
                            handleClick={handleSubmit}
                            textSize={"1rem"}
                            primaryBtnColors={true}
                        >
                            Save
                        </Button>
                    </RightBtnWrapper>
                </FooterBarContent>
            </FooterBar>
        </Wrapper>
    );
};

export default withTheme(ImageEditPopUpView);

// Min width here ensure a zoom effect if a really narrow image is uploaded
const Wrapper = styled.div`
    max-width: min(700px, 95vw);
    min-width: min(500px, 90vw);
`;

// Hides overflow and positions crop area in center of image
const CoverPicWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`;

// Highlights area of cover photo that wont be cut off when viewing profile, area that wont be seen is shaded black
// padding-top used to set box height realtive to its width
// Shaded area achieved with box-shadow and a massive blur effect
const CoverPicArea = styled.div`
    position: absolute;
    width: 100%;
    padding-top: ${(props) =>
        props.theme.sizes.aspectRatios.coverPhoto.LgView}%;
    @media (max-width: ${({ theme }) => theme.sizes.xsViewWidth}) {
        padding-top: ${(props) =>
        props.theme.sizes.aspectRatios.coverPhoto.xsView}%;
    }
    box-shadow: 0 0 0 9999px #00000099;
`;

const CoverPic = styled.img`
    width: 100%;
    height: 100%;
    /* max-height: 65vh; */
    object-fit: cover;
    @media (max-width: ${({ theme }) => theme.sizes.xsViewWidth}) {
        max-height: 600px;
    }
`;

// All properties of cover pic wrapper but wrapper is a square box
// Width given by popup at various sizes is used and height is scaled off that to make a square
// z-index starts a new stacking context for two absolutley postioned children
const DisplayPicWrapper = styled(CoverPicWrapper)`
    position: relative;
    z-index: 0;
    width: 100%;
    padding-top: 100%;
`;

// Highlights circle area of cover photo and logo not cut off by profile view.
// Must set width 100% (not height) as img tag is set to width 100%, overflow height is handled
const DisplayPicArea = styled.div`
    position: absolute;
    top: 0; /* move above padding */
    width: 100%; /* Fill avaible width */
    height: 100%; /* Fill height, parent is square */
    border-radius: 50%;
    box-shadow: 0 0 0 9999px #00000099;
`;

// If picture is wider than height or visa vera then longer side will be cropped
const DisplayPicture = styled.img`
    position: absolute;
    top: 0;
    width: 100%; /* Fill square height and width parent  */
    height: 100%;
    z-index: -1; /* Behind circle */
    object-fit: cover;
`;

// delete button wrapper
const ImgButton = styled.div`
    position: absolute;
    z-index: 1;
    top: 0.5rem;
    left: 0.5rem;
    display: flex;
`;

// row containing upload error
const Row = styled.div`
    position: absolute;
    z-index: 1;
    bottom: 1rem; /* 1rem from bottom of image box */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
`;

const UploadError = styled.div`
    flex: 0 1 800px;
`;

const LoadingCircleRow = styled.div`
    width: 100%;
    position: absolute;
    z-index: 4;
    top: 0.75rem;
    display: flex;
    justify-content: center;
`;

const LoadingCircle = styled.div`
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.3rem;
    background-color: ${(props) => props.theme.colors.offPrimary};
    border-radius: 50%;
`;
