import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTheme } from "styled-components";

import Homepage from "./Homepage-container";
import { addError, removeError } from "../../main/store/actions/errors";

function mapState(state) {
    return {
        currentUser: state.currentUser,
        errors: state.errors,
    };
}

const mapDispatch = {
    addError,
    removeError,
};

export default withTheme(withRouter(connect(mapState, mapDispatch)(Homepage)));
