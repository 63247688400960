import { useRef, useLayoutEffect, useCallback } from "react";

// Good scroll monitor hook, copied form the web (link below):
// https://dev.to/n8tb1t/tracking-scroll-position-with-react-hooks-3bbj
// Allows you to track scroll position using multiple methods
// returns the height from top of window (or ref) and from left.
// The wait time is very useful for reducing the workload on the client (stops callback being run every tiny scroll)

const isBrowser = typeof window !== `undefined`;

// function used to get position from top and left of element (or document.body)
// two possible methods, the results differ slightly depending on which you use
// NOTE: Remember this only fires on scroll, so if using for load more logic and content didn't fill the users screen (or overflow a div) then scroll event can never occur
function getScrollPosition({ element, useWindow }) {
    if (!isBrowser) return { x: 0, y: 0 };

    const target = element ? element.current : document.body;
    const position = target.getBoundingClientRect();

    return useWindow
        ? {
            x: window.scrollX,
            y: window.scrollY,
        }
        : {
            x: position.left,
            y: position.top,
        };
}

export function useScrollPosition(
    effect, // Callback function that uses prevPos / currentPos returned
    deps, // Array of dependencies, if want loading state in callback - pass in e.g. [loading] so latest value in callback
    element, // ref used in the scrolling element (if none then the window is used)
    useWindow, // if truthy window.scroll is used to get position, if falsey then target.getBoundingClientRect
    wait // timeout wait used break chain of client taxing constant re-renders
) {
    const position = useRef(getScrollPosition({ useWindow }));

    let throttleTimeout = useRef(null);

    const callBack = useCallback(() => {
        const currPos = getScrollPosition({ element, useWindow });
        effect({ prevPos: position.current, currPos });
        position.current = currPos;
        throttleTimeout.current = null; // reset timeout to null
    }, [effect, element, useWindow]);

    useLayoutEffect(() => {
        const handleScroll = () => {
            if (wait) {
                if (throttleTimeout.current === null) {
                    // if not currently waiting
                    throttleTimeout.current = setTimeout(callBack, wait); // callback fires after wait
                }
            } else {
                callBack(); // else fire callback every scroll (all the time)
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, [deps, wait, callBack]);
}
