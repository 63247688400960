import React from "react";
import styled from "styled-components";

import { INPUT_TYPES } from "../../library/common/constants";
import InputGroup from "../../library/common/InputGroup";

import {
    FullWidthWrapper,
    // InputRow,
    // HalfWidthWrapper,
} from "../../resources/styles";

const SiteDetailsEditView = ({
    formState,
    handleChange,
    validationFailedState,
}) => {
    console.log("formState in SiteDetailsEdit");
    console.log(formState);
    return (
        <Wrapper>
            <FullWidthWrapper maxWidth={"300px"}>
                <InputGroup
                    type={INPUT_TYPES.TEXT}
                    handleChange={handleChange}
                    errorMessage={validationFailedState.name}
                    value={formState.name}
                    name={"name"}
                    title={"Name"}
                    placeholder={"Site name"}
                    required={true}
                />
            </FullWidthWrapper>
        </Wrapper>
    );
};

export default SiteDetailsEditView;

const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
    width: min(600px, 95vw);
`;
