// TODO - copied directly from Archibuild. Needs full review.

// import { sendApiRequest, setTokenHeader } from "../../../services/api";
import { SET_CURRENT_USER } from "../actionTypes";
// import { addError, removeError } from "./errors";

export function setCurrentUser(data) {
    return {
        type: SET_CURRENT_USER,
        data,
    };
}

// export function setUserCompany(data) {
//     return {
//         type: ADD_COMPANY,
//         data,
//     };
// }

// export function updateUserType(data) {
//     return {
//         type: SET_USER_TYPE,
//         data,
//     };
// }

// export function setNotifications(data) {
//     return {
//         type: REFRESH_NOTIFICATIONS,
//         data,
//     };
// }

// export function setAuthToken(token) {
//     setTokenHeader(token);
// }

// export function logout() {
//     return (dispatch) => {
//         localStorage.clear(); // remove token from localStorage
//         setAuthToken(false); // remove token from axios requests
//         dispatch(setCurrentUser({})); // remove user from store
//     };
// }

// export function authUser(type, userData) {
//    // send request to signin or signup end points
//    // token is added to local storage
//    return async (dispatch) => {
//       try {
//          const { token, ...user } = await sendApiRequest(
//             "post",
//             `/api/auth/${type}`,
//             userData
//          );
//          localStorage.setItem("jwtToken", token);
//          setAuthToken(token);
//          // TODO - add this back later when we put in notifications
//          // const notifications = await getNotifications(user.id);
//          const currentUser = { user };
//          dispatch(setCurrentUser(currentUser));
//          dispatch(removeError());
//       } catch (error) {
//          dispatch(addError(error.message));
//          return;
//       }
//    };
// }
