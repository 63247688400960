import { useEffect, useRef } from "react";

// Component can be dropped into an overflowing div and will cause overflowing parent to be scrolled on mount
// !scrollToBottomDefault => top of element to top of parent viewport, OR !!scrollToBottomDefault => bottom of element to bottom of patents viewport
// !!smooth then scroll will be a smooth transition effect, else then snaps to scroll location
const ScrollToBottomOnMountView = ({ scrollToBottom, smooth }) => {
    const elementRef = useRef();
    useEffect(() =>
        elementRef.current.scrollIntoView({
            behavior: smooth ? "smooth" : "auto",
            block: scrollToBottom ? "end" : "start",
            inline: "nearest",
        })
    );
    return <div ref={elementRef} />;
};

export default ScrollToBottomOnMountView;
