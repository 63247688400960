import { combineReducers } from "redux";
import currentUser from "./currentUser";
import errors from "./errors";
import newsFeed from "./newsFeed";
import sidebar from "./sidebar";
import mySites from "./mySites";

// import messages from "./messages";
// import notifications from "./notifications";

const rootReducer = combineReducers({
    currentUser,
    errors,
    newsFeed,
    sidebar,
    mySites,
    //    messages,
    //    notifications,
});

export default rootReducer;
