import styled, { withTheme } from "styled-components";

import Avatar from "../../../library/common/Avatar";
import ButtonCircleIcon from "../../../library/common/ButtonCircleIcon";
import Icon from "../../../library/common/Icon";

const ChatListItem = ({
    theme,
    chat = {},
    handleItemClick = () => { },
    isLoading = false,
}) => {

    const {
        _id = null,
        name = null,
        unreadMessageInChat = false,
        otherChatMembers = [],
        nameToDisplay = "",
        lastMessageText = "",
        timeSinceLastMessage = "",
        isSelected = false,
    } = chat;

    const iconProps = {
        circleDiameter: "3rem",
        fontSize: "1.2rem",
        bgColor: theme.colors.offSecondary,
        iconColor: theme.colors.primary,
    };

    const getChatIcon = () => {

        if (isLoading) {
            return <ButtonCircleIcon
                {...iconProps}
                bgColor={theme.colors.offPrimary}
            />;

        } else if (name) {
            return <ButtonCircleIcon {...iconProps} >
                <Icon name="flag" />
            </ButtonCircleIcon>;

        } else if (otherChatMembers.length > 1) {
            return <ButtonCircleIcon {...iconProps} >
                <Icon name="user-friends" fontSize="110%" />
            </ButtonCircleIcon>;

        } else if (otherChatMembers.length === 1) {
            return <Avatar
                path={otherChatMembers[0].img}
                alt={otherChatMembers[0].fullName}
                size={"3rem"}
                borderColor={theme.colors.offPrimary}
                showPointer={true}
                user={true}
            />;

        } else if (otherChatMembers.length < 1) {
            return <ButtonCircleIcon {...iconProps} >
                <Icon name="user" />
            </ButtonCircleIcon>;
        }
    };

    const chatName = isLoading ?
        <NameLoading /> :
        <Name>{nameToDisplay}</Name>;

    const previewText = isLoading ?
        <MessageTextLoading /> :
        <MessageRow>
            <MessagePreviewText hasBeenSeen={!unreadMessageInChat}>
                {lastMessageText}
            </MessagePreviewText>
            <MsgTimeAgo hasBeenSeen={!unreadMessageInChat}>
                {timeSinceLastMessage}
            </MsgTimeAgo>
        </MessageRow>;

    return (
        <ChatListItemWrapper
            key={_id || "new"}
            selected={isSelected}
            unread={unreadMessageInChat}
            onClick={handleItemClick}
        >
            <UnreadMarker unread={unreadMessageInChat} />
            {getChatIcon()}
            <DetailsCol>
                {chatName}
                {previewText}
            </DetailsCol>
        </ChatListItemWrapper>
    );
};

export default withTheme(ChatListItem);

const ChatListItemWrapper = styled.div`
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    margin: 0 0 0.3rem 0;
    padding: 0.6rem 0.7rem 0.6rem 0.1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    background-color: ${({ theme, selected }) =>
        selected ? theme.colors.semantic.info : "inherit"};
    position: relative;
    cursor: pointer;
    &:hover {
        background-color: ${({ theme, selected }) =>
        selected ? "auto" : theme.colors.offPrimary};
    }
`;

const UnreadMarker = styled.div`
    background-color: ${({ theme, unread }) =>
        unread ? theme.colors.accent : "inherit"};
    border-radius: 0.2rem;
    align-self: stretch;
    width: 0.3rem;
    margin-right: 0.5rem;
`;

const DetailsCol = styled.div`
    padding-left: 0.7rem;
    min-width: 0;
    width: 100%;
`;

const Name = styled.div`
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const MessageRow = styled.div`
    display: flex;
    align-items: center;
`;
const NameLoading = styled.div`
    height: 1rem;
    width: min(50%, 10rem);
    border-radius: 0.5rem;
    background-color: ${(props) => props.theme.colors.offPrimary};
`;

const MessageTextLoading = styled(NameLoading)`
    margin-top: 0.7rem;
    height: 1rem;
    width: min(90%, 15rem);
`;
const MessagePreviewText = styled.div`
    font-size: 0.9rem;
    color: ${({ theme, hasBeenSeen }) => (
        hasBeenSeen ? theme.colors.justOffSecondary : theme.colors.accent
    )};
    font-weight: ${({ theme, hasBeenSeen }) => (hasBeenSeen ? "400" : "500")};

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const MsgTimeAgo = styled.div`
    padding-left: 0.4rem;
    /* font-size: 80%; */
    font-weight: 400;
    white-space: nowrap; /* Stop time wrapping between lines  */
    color: ${(props) => props.theme.colors.justOffSecondary};
    /* display: flex; */
`;
