import styled, { withTheme } from "styled-components";

import Avatar from "../Avatar";
import Button from "../Button";
import Icon from "../Icon";
import InputGroup from "../InputGroup";

import {
    CONNECTION_ACTION,
    CONNECTION_STATUS,
    INPUT_TYPES,
} from "../constants";

const UserListPeopleSection = ({
    currentUser,
    searchResults,
    handleChange,
    handleCardClick,
    handleConxAction,
    handleLoadMore,
    canLoadMore,
    isSearching,
    allowConnect,
    searchString,
    theme,
    allowSearch,
    searchPlaceholder = "Search",
    noResultsText = "None found.",
}) => {

    const createConxBtn = (conxStatus, conxId) => {
        switch (conxStatus) {
            case CONNECTION_STATUS.NOTAPPLICABLE:
                if (currentUser.user.id !== conxId) {
                    return (
                        <Button
                            icon={<Icon name="user-plus" />}
                            handleClick={(e) =>
                                handleConxAction(
                                    e,
                                    conxId,
                                    CONNECTION_ACTION.ADD
                                )
                            }
                            bgColor={theme.colors.darkAccent}
                            noShadow={true}
                        >
                            Friend
                        </Button>
                    );
                } else return;
            case CONNECTION_STATUS.REQUESTED:
                return (
                    <Button
                        icon={<Icon name="share" />}
                        bgColor={theme.colors.darkAccent}
                        noShadow={true}
                        disabled={true}
                    >
                        Sent
                    </Button>
                );
            case CONNECTION_STATUS.PENDING:
                return (
                    <Button
                        icon={<Icon name="user-clock" />}
                        bgColor={theme.colors.darkAccent}
                        noShadow={true}
                        disabled={true}
                    >
                        Pending
                    </Button>
                );
            default:
                return;
        }
    };

    const UserCards = searchResults.length > 0 ?
        searchResults.map((result, i) => {

            const ConxBtn = allowConnect && result.conxStatus ?
                createConxBtn(result.conxStatus, result._id) :
                null;

            return (
                <CardOuterWrapper
                    key={i}
                    onClick={() => handleCardClick(result._id)}
                >
                    <CardInnerWrapper>
                        <Avatar
                            path={result.img}
                            size={"85px"}
                            borderColor={theme.colors.offPrimary}
                            user={true}
                        />
                        <Name>{result.username}</Name>
                        {!!ConxBtn &&
                            <ConxBtnWrapper>
                                {ConxBtn}
                            </ConxBtnWrapper>
                        }
                    </CardInnerWrapper>
                </CardOuterWrapper>
            );
        }) :
        (<div></div>);

    const LoadingCards = [...Array(4)].map((_, i) => {
        return (
            <CardOuterWrapper key={i}>
                <CardInnerWrapper>
                    <Avatar
                        size={"85px"}
                        borderColor={theme.colors.offPrimary}
                        user={true}
                        parentLoading={true}
                    />
                    <NameBar />
                </CardInnerWrapper>
            </CardOuterWrapper>
        );
    });

    return (
        <div>
            {allowSearch && (
                <SearchInputWrapper>
                    <InputGroup
                        type={INPUT_TYPES.TEXT}
                        handleChange={handleChange}
                        errorMessage={""}
                        value={searchString}
                        name={"searchUser"}
                        placeholder={searchPlaceholder}
                        inputBoxPadding={"0.5rem 1.5rem 0.5rem 0.7rem"}
                        borderRadius={"0.6rem"}
                        bgColor={theme.colors.body}
                    />
                </SearchInputWrapper>
            )}
            <UserGridWrapper>
                {UserCards}
                {isSearching && // Loading cards when isSearch and no cards
                    (!searchResults || searchResults.length === 0) &&
                    LoadingCards}
                {/* If no cards to render - no users in company */}
                {!isSearching &&
                    (!searchResults || searchResults.length === 0) && (
                        <FullWidthRow>
                            <NoResultsMessage>
                                {noResultsText}
                            </NoResultsMessage>
                        </FullWidthRow>
                    )}
                {searchResults && searchResults.length > 0 && canLoadMore && (
                    /* Load more button with loading spinner */
                    <FullWidthRow>
                        <MoreBtnWrapper>
                            <Button
                                handleClick={handleLoadMore}
                                icon={<Icon name="arrow-circle-down" />}
                                secondaryBtnColors={true}
                                buttonLoading={isSearching}
                                borderRadius={"1rem"}
                            >
                                More
                            </Button>
                        </MoreBtnWrapper>
                    </FullWidthRow>
                )}
            </UserGridWrapper>
        </div>
    );
};

export default withTheme(UserListPeopleSection);

const UserGridWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding: 0.5rem;
    justify-content: center;
    position: relative;
    min-height: 200px;
`;

const CardOuterWrapper = styled.div`
    position: relative;
    width: 150px;
    padding-top: 190px;
    margin: 0 0.1rem 0.7rem 0.1rem;
`;

const CardInnerWrapper = styled.div`
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    padding: 0.5rem;
    border: 1px solid ${(props) => props.theme.colors.offSecondary};
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0.7rem;
`;

const Name = styled.div`
    padding-top: 0.5rem;
    font-weight: 500;
    /* Cut off any names longer than width available with ellipsis's*/
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const ConxBtnWrapper = styled.div`
    padding-top: 0.5rem;
`;

const FullWidthRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const MoreBtnWrapper = styled.div`
    padding-top: 0.5rem;
    flex: 1;
    max-width: 200px;
`;

const NameBar = styled.div`
    background-color: ${(props) => props.theme.colors.offPrimary};
    border-radius: 0.5rem;
`;

const SearchInputWrapper = styled.div`
    padding-bottom: 1rem;
    position: relative;
`;

const NoResultsMessage = styled.h6`
    padding-top: 1rem;
    color: ${(props) => props.theme.colors.offSecondary};
    margin: 0;
`;
