import { connect } from "react-redux";
import { withRouter } from "react-router";

import PostDetails from "./CreatePost-container";

import withPopUp from "../../library/hoc/WithPopUp";
import withRemoveErrorOnUnmount from "../../library/hoc/WithRemoveErrorOnUnmount";
import { addError, removeError } from "../../main/store/actions/errors";

const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
    errors: state.errors
});

const mapDispatchToProps = {
    addError,
    removeError,
};

export default connect(mapStateToProps, mapDispatchToProps)(
    withRouter(withRemoveErrorOnUnmount(withPopUp(PostDetails)))
);
