import styled from "styled-components";
import { popUpHeaderHeight } from "../../../library/hoc/WithPopUp/WithPopUp-container";
import Alert from "../../../library/common/Alert";
import { ALERT_TYPES } from "../../../library/common/constants";

const ValidationErrorAlertView = ({
    validationFailedState,
    removeNoContentError,
    removeUploadError,
    doubleFooter,
}) => {

    const { noContent, uploadError } = validationFailedState;

    return (
        <AlertWrapper doubleFooter={doubleFooter}>
            {noContent && (
                <Alert
                    message={"Post must contain text or images"}
                    type={ALERT_TYPES.ERROR}
                    hideBorder={true}
                    noBorderRadius={true}
                    removeError={removeNoContentError}
                />
            )}
            {uploadError && (
                <Alert
                    message={uploadError}
                    type={ALERT_TYPES.ERROR}
                    hideBorder={true}
                    noBorderRadius={true}
                    removeError={removeUploadError}
                />
            )}
        </AlertWrapper>
    );
};

export default ValidationErrorAlertView;

const AlertWrapper = styled.div`
    position: absolute;
    bottom: ${(props) =>
        props.doubleFooter
            ? `calc(${popUpHeaderHeight}*2.1)`
            : `calc(${popUpHeaderHeight}*1)`};
    width: 100%;
    z-index: 3;
`;
