import { useEffect, useRef } from "react";

// Map component using google maps api. The map allows user to change latLng and address in parent by clicking to move the marker position around. The map will also move marker and bounds if latLng in parent component changes.
// Note: Make sure you run a check for maps API in parent before rendering this component
const InputMap = ({
    initialMapPosition,
    initialMapZoom,
    latLng,
    mapUpdateLocation,
    idIndex,
}) => {
    const markerRef = useRef();
    // map is defined using a ref so it can be updated in useEffect
    const map = useRef();

    // Runs every time latLng in parent changes, updates map position and market location. Note by using Googles setCenter and setPosition methods we avoid the map component needing to remount every time latLng changes.
    useEffect(() => {
        if (map.current) {
            // Update map position if latLng is outside map bounds
            if (!map.current.getBounds().contains(latLng)) {
                map.current.setCenter(latLng);
            }
            // move marker position
            markerRef.current.setPosition(latLng);
        }
    }, [latLng]);

    useEffect(() => {
        //  defines position in the world to render in map initially. Takes in initialMapPosition from parent, this variable doesn't change after parent mounts.
        const mapOptions = {
            center: initialMapPosition.current || initialMapPosition,
            zoom: initialMapZoom,
            draggableCursor: "pointer",
        };

        // Define map
        map.current = new window.google.maps.Map(
            document.getElementById(`map${idIndex}`),
            mapOptions
        );
        // Define marker
        markerRef.current = new window.google.maps.Marker({
            map: map.current,
            position: mapOptions.center,
        });

        // addListener for click event on map, click event updates parent's latLng and address. A latLng update in parent triggers useEffect function that will in turn update the maps center and market position
        map.current.addListener("click", (e) => {
            // Updates latLng and address in parents state
            mapUpdateLocation(e.latLng.toJSON());
        });
    }, [
        initialMapPosition,
        initialMapZoom,
        mapUpdateLocation,
        idIndex,
    ]);

    return <div className="embed responsive item map" id={`map${idIndex}`} />;
};

export default InputMap;
