// import "../../../resources/styles/SearchBox.css";
import styled from "styled-components";
import Avatar from "../Avatar";

const SearchDropdown = ({
    dropdownMaxHeight,
    onMouseEnter,
    onMouseLeave,
    onClick,
    searchResults,
    currentResult,
    dropdownRef,
    isSearching,
}) => {
    const dropDownItems =
        searchResults.length > 0
            ? Object.values(searchResults).map((result, index) => {
                  return (
                      <SearchResult
                          key={result.id}
                          result-index={index}
                          highlight={
                              index === currentResult.index ? true : false
                          }
                          onMouseEnter={() => onMouseEnter(index)}
                          onClick={() => onClick(index)}
                      >
                          <LeftWrapper>
                              {result.img !== "" && (
                                  <AvatarWrapper>
                                      <Avatar
                                          path={result.img}
                                          user={true}
                                          size={"2rem"}
                                      />
                                  </AvatarWrapper>
                              )}
                              <div>{result.name}</div>
                          </LeftWrapper>
                          <RightWrapper>
                              {result.cat !== "" && (
                                  <CatWrapper>({result.cat})</CatWrapper>
                              )}
                          </RightWrapper>
                      </SearchResult>
                  );
              })
            : "";

    return (
        <Dropdown
            ref={dropdownRef}
            onMouseLeave={onMouseLeave}
            dropdownMaxHeight={dropdownMaxHeight}
            hideDropdown={dropDownItems || isSearching ? false : true}
        >
            {dropDownItems}
            {isSearching && <SearchingWrapper>Searching ...</SearchingWrapper>}
        </Dropdown>
    );
};

export default SearchDropdown;

const Dropdown = styled.div`
    position: absolute;
    z-index: -1;
    margin-top: -0.8rem;
    padding-top: 0.8rem;
    width: 100%;
    display: ${(props) => (props.hideDropdown ? "none" : "flex")};
    align-items: distance-between;
    flex-direction: column;
    background-color: ${(props) => props.theme.colors.primary};
    border-left: 1px solid ${(props) => props.theme.colors.offPrimary};
    border-right: 1px solid ${(props) => props.theme.colors.offPrimary};
    border-bottom: 1px solid ${(props) => props.theme.colors.offPrimary};
    overflow-y: auto;
    max-height: min(
        ${({ dropdownMaxHeight }) =>
            dropdownMaxHeight ? dropdownMaxHeight : "150px"},
        80vh
    );
    border-radius: 0 0 0.5rem 0.5rem;
    box-shadow: 6px 10px 15px ${(props) => `${props.theme.colors.secondary}35`};
    & > *:last-of-type {
        border-radius: 0 0 0.5rem 0.5rem;
    }
`;

const SearchResult = styled.div`
    cursor: pointer;
    padding: 0.7rem 1rem 0.7rem 0.4rem;
    background-color: ${({ highlight, theme }) =>
        highlight ? theme.colors.offPrimary : "inherit"};
    display: flex;
    justify-content: space-between;
`;

const SearchingWrapper = styled.h6`
    padding: 0.5rem;
    margin: 0;
`;

const LeftWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const AvatarWrapper = styled.div`
    margin: 0rem 0.5rem;
`;

const CatWrapper = styled.div`
    font-size: 80%;
`;

const RightWrapper = styled.div`
    display: flex;
    align-items: center;
`;

// const SearchDropdown = ({
//     onMouseEnter,
//     onMouseLeave,
//     onClick,
//     searchResults,
//     currentResult,
//     dropdownRef
// }) => {
//     const dropDownItems = searchResults.length > 0 ?
//         Object.values(searchResults).map((result, index) => {
//             return (
//                 <li
//                     key={result.id}
//                     result-index={index}
//                     className={
//                         `dropdown-buttons
//                         ${index === currentResult.index
//                             ? "dropdown-buttons-current"
//                             : ""}`
//                     }
//                     onMouseEnter={() => onMouseEnter(index)}
//                     onClick={() => onClick(index)}
//                 >
//                     {result.img !== "" &&
//                         <img
//                             src={result.img}
//                             alt={result.img}
//                         />
//                     }

//                     {result.name}

//                     {result.cat !== "" &&
//                         <div>
//                             <small><i>{result.cat}</i></small>
//                         </div>
//                     }
//                 </li>
//             );
//         }) :
//         "";

//     return (
//         <div
//             className="dropdown-button-container"
//             ref={dropdownRef}
//             onMouseLeave={onMouseLeave}
//         >
//             <ul>{dropDownItems}</ul>
//         </div>
//     );
// };
