import {
    SET_NEWS_FEED,
    CLEAR_NEWS_FEED,
    REMOVE_FROM_FEED,
} from "../actionTypes";

export const updateFeed = (data) => ({
    type: SET_NEWS_FEED,
    data,
});

export const removeFromFeed = (data) => ({
    type: REMOVE_FROM_FEED,
    data,
});

export const clearNewsFeed = () => ({
    type: CLEAR_NEWS_FEED,
});
