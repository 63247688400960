import { useState, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { sendApiRequest } from "../../../../services/api";

import { SERVICE_EDIT_SCREENS } from "../../constants";
import AddServices from "./AddServices-view";
import ServicesEditList from "./ServicesEdit-view";
import PopupLoadingScreen from "../../PopupLoadingScreen";

// import "../../../../resources/styles/ServicesEdit.css";

// Component for editing list of services, list render with button for adding a new item and delete buttons for removing services from list.
const ServicesEditContainer = ({
    services,
    servicesName,
    addError,
    setPopUpToDisplay,
    refreshDataFromServer,
    serverPath,
    recommendationServerPath,
}) => {
    // Loading state
    const [loading, setLoading] = useState(false);
    // Store all services and sends to server on save
    // Only add if services is an array with an item in it
    const [formState, setFormState] = useState(
        services && services[0] ? services : []
    );
    // Store inputs within add screen
    const [addedServices, setAddedServices] = useState([]);
    // Recommend services array, populated by server on mount
    const [recommendedServices, setRecommendedServices] = useState([]);
    // State for handling popup screens, list screen rendered on mount
    const [servicesScreen, setServicesScreen] = useState(
        SERVICE_EDIT_SCREENS.list
    );

    const { getAccessTokenSilently } = useAuth0();

    // Set recommended service based off recommendations return by recommend route
    const loadRecommendedServices = useCallback(async () => {
        const existingServicesArray = formState.map((el) => el.name);
        setRecommendedServices(
            await sendApiRequest(getAccessTokenSilently, {
                method: "post",
                url: recommendationServerPath,
                data: {
                    existingServicesArray: existingServicesArray,
                },
            })
        );
    }, [recommendationServerPath, formState, getAccessTokenSilently]);

    // Remove loading screen then send request for users recommended services. Will also updates recommended services as user adds to formState
    useEffect(() => {
        (async () => {
            setLoading(false);
            loadRecommendedServices();
        })();
    }, [setLoading, formState, loadRecommendedServices]);

    // If user hits back their returned to list screen
    const handleBack = () => {
        setAddedServices([]);
        setServicesScreen(SERVICE_EDIT_SCREENS.list);
        // Re set recommended services using recommend route
        loadRecommendedServices();
    };

    // On save button, send changes made to server and exit popup.
    const handleSubmit = async () => {
        try {
            setLoading(true);
            const dataForSubmit = {};
            dataForSubmit[servicesName] = formState; // Variable servicesName key
            await sendApiRequest(getAccessTokenSilently, {
                method: "put",
                url: serverPath,
                data: dataForSubmit,
            });
            setPopUpToDisplay(""); // remove popup window once added
            await refreshDataFromServer();
        } catch (error) {
            setLoading(false); // Remove loading screen if error caught
            addError(error.message);
        }
    };

    // Defines services to be excluded from SearchBox's results.
    // Called on each re-render of AddServices component, i.e. updates excluded list as services are added
    const excludeServicesFromSearchBox = () => {
        const excludedServiceNames = [];
        // if (formState && formState[0]) {
        formState.forEach((el) => excludedServiceNames.push(el.name));
        // }
        // if (addedServices && addedServices[0]) {
        addedServices.forEach((el) => excludedServiceNames.push(el.name));
        // }
        return excludedServiceNames;
    };

    // Adds service to addedServices state
    // Used in SearchBox when user selects from dropdown or hits enter
    const addServiceToState = async (result) => {
        // Add service to state
        setAddedServices([...addedServices, { name: result.name }]);
        // Remove service from recommended services list (prevent user adding same service twice)
        setRecommendedServices(
            recommendedServices.filter((el) => el.name !== result.name)
        );
    };

    // Adds a recommended service to state.
    // Add service to addedServices state and removes from recommendations
    const addRecommendedService = (servicesName) => {
        setAddedServices([...addedServices, { name: servicesName }]);
        setRecommendedServices(
            recommendedServices.filter((el) => el.name !== servicesName)
        );
    };

    // Done button in AddServices screen
    // Transfer addedServices to formState, empty addedServices state and change screen back to list
    const handleAddClick = () => {
        setFormState([...formState, ...addedServices]);
        setAddedServices([]);
        setServicesScreen(SERVICE_EDIT_SCREENS.list);
    };

    // Removes a service from formState - Used for delete buttons in list screen
    const deleteItemFormState = (index) => {
        setFormState(formState.filter((item, i) => i !== parseInt(index)));
    };

    // Removes a service from addedSkill state - used for delete buttons in details screen
    const deleteItemAddedServices = (index) => {
        setAddedServices(
            addedServices.filter((item, i) => i !== parseInt(index))
        );
    };

    if (loading) {
        return <PopupLoadingScreen />;
    } else if (servicesScreen === SERVICE_EDIT_SCREENS.list) {
        return (
            <ServicesEditList
                servicesName={servicesName}
                formState={formState}
                setServicesScreen={setServicesScreen}
                deleteItemFormState={deleteItemFormState}
                handleSubmit={handleSubmit}
                setPopUpToDisplay={setPopUpToDisplay}
            />
        );
    } else {
        return (
            <AddServices
                setPopUpToDisplay={setPopUpToDisplay}
                servicesName={servicesName}
                addServiceToState={addServiceToState}
                addRecommendedService={addRecommendedService}
                addedServices={addedServices}
                handleBack={handleBack}
                deleteItemAddedServices={deleteItemAddedServices}
                handleAddClick={handleAddClick}
                recommendedServices={recommendedServices}
                excludeServicesFromSearchBox={excludeServicesFromSearchBox}
            />
        );
    }
};

export default ServicesEditContainer;
