import React from "react";
import styled from "styled-components";

const AppDownLoadSection = () => {
    return (
        <OutterWrapper>
            <BackgroundImage />
            <BackgroundOverlay />
            <InnerWrapper>
                <AppIcon src={"/LandingPageImgs/AppIcon.svg"} />
                <AppStoreLinksCol>
                    <StoreLinkBadge
                        src={"/LandingPageImgs/AppleAppStore.svg"}
                    />
                    <StoreLinkBadge
                        src={"/LandingPageImgs/GoogleAppStore.svg"}
                    />
                </AppStoreLinksCol>
            </InnerWrapper>
            <BetaTestingMsg>
                The BoomsUp mobile app is currently in beta, we can't open
                downloads to everyone just yet. App Store & Google Play
                availability will be restricted.
            </BetaTestingMsg>
        </OutterWrapper>
    );
};

export default AppDownLoadSection;

// -----------------------------------------------
// App Download section --------------------------
// -----------------------------------------------

const sectionScreenSizeBreak = "544px";

const OutterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    padding: 150px 0;
    position: relative;
    @media (max-width: ${sectionScreenSizeBreak}) {
        padding: 100px 0;
    }

    /* background-color: red; */
`;

const BackgroundImage = styled.div`
    background-image: url("https://cosqos-bucket.s3.ap-southeast-2.amazonaws.com/Drill+Rig.jpg");
    background-position: center; /* Center the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;

const BackgroundOverlay = styled.div`
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${(props) => props.theme.colors.primary}50;
    background-image: linear-gradient(
        to right,
        ${({ theme }) => theme.colors.justOffPrimary},
        ${({ theme }) => theme.colors.primary}70,
        ${({ theme }) => theme.colors.justOffPrimary}
    );
    
    @media (max-width: ${sectionScreenSizeBreak}) {
        background-image: linear-gradient(
        ${({ theme }) => theme.colors.primary}90,
        ${({ theme }) => theme.colors.primary}80,
        ${({ theme }) => theme.colors.primary}95
    );
    }
`;

const InnerWrapper = styled.div`
    position: relative;
    z-index: 3;
    /* background-color: blue; */
    width: 544px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    /* padding: 0 0 0 ; */
    @media (max-width: ${sectionScreenSizeBreak}) {
        width: 100%;
        flex-direction: column;
    }
`;

const AppIcon = styled.img`
    height: 200px;
    width: 200px;
    /* margin: 0 30px 0 0; */
    @media (max-width: ${sectionScreenSizeBreak}) {
        margin: 0 0 30px 0;
    }
`;

const AppStoreLinksCol = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background-color: pink; */
    @media (max-width: ${sectionScreenSizeBreak}) {
        flex-direction: row;
        justify-content: space-around;
        width: 90vw;
    }
    /* background-color: cyan; */
    /* padding: 0 200px; */
`;

const StoreLinkBadge = styled.img`
    height: 65px;
    margin: 10px 0;
    @media (max-width: ${sectionScreenSizeBreak}) {
        height: 45px;
    }
`;

const BetaTestingMsg = styled.div`
    z-index: 3;
    font-family: "Montserrat", sans-serif;
    font-size: 17px;
    font-weight: 500;
    /* color: ${(props) => props.theme.colors.secondary}; */
    text-align: center;
    /* font-style: italic; */
    padding: 25px 0 0 0;
    max-width: 640px;
    @media (max-width: ${sectionScreenSizeBreak}) {
        max-width: 90vw;
        text-align: start;
    }
`;
// -----------------------------------------------
