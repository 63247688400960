import styled, { withTheme } from "styled-components";

import Avatar from "../Avatar";
import BtnBar from "./BtnBar-container";
import Button from "../Button";
import ButtonCircle from "../ButtonCircleIcon";
import { PhotoIcon } from "../Icon";
import LoadingShimmer from "../LoadingShimmer";
import ScrollToOnMount from "../ScrollToOnMount";

import { CONNECTION_ACTION, CONNECTION_STATUS } from "../constants";
import { useApi } from "../../../services/api";

const avatarSize = "10rem";
const circleBtnDiameter = "2.6rem";
const btnBarCriticalScSize = "1200px";

const ProfileHeaderView = ({
    currentUser,
    theme,
    avatarPaths,
    userIsAdmin,
    setPopUpToDisplay,
    popupStrings,
    openFullScreenImgView,
    handleMessageClick,
    phone,
    name,
    conxStatus,
    userProps = {},
    companyProps = {},
    createView, // optional, set to true if wish to show user a preview (e.g. creating a company)
    scrollFocusContent, // optional if true user will be scrolled down to focus on profile content
    loading, // optional, if true will render loading view
}) => {
    // since empty objects are truthy needed to create variables to check if user or company profile is being rendered
    const isUserProfile = Object.keys(userProps).length !== 0;
    const isCompanyProfile = Object.keys(companyProps).length !== 0;

    // Initialize useApi for firing conx request
    const { refresh: updateConxStatus } = useApi();

    // send update connection request with the clicked user's id
    // and the connection action object {route, STATUS_RESULT}
    // also update connection status in conxBtnStatus state in parent
    const handleConxAction = (actionObj) => {
        updateConxStatus({
            method: "put",
            url:
                `/api/users/${currentUser.user.id}` +
                `/${actionObj.route}` +
                `?${isUserProfile ?
                    "id=" + userProps.profileUsersID :
                    "company_id=" + companyProps.companyID
                }`,
        });
        if (isUserProfile) {
            userProps.setUserDetails((f) => ({
                ...f,
                conxStatus: actionObj.STATUS_RESULT,
            }));
        } else if (isCompanyProfile) {
            companyProps.setCompanyDetails((f) => ({
                ...f,
                conxStatus: actionObj.STATUS_RESULT,
            }));
        }
    };

    if (loading) {
        return (
            <HeaderWrapper>
                <CoverBox>
                    <LoadingShimmer />
                </CoverBox>
                <DisplayPicRow height={avatarSize}>
                    <DisplayPicWrapper>
                        <DisplayPic>
                            <Avatar
                                size={avatarSize}
                                borderColor={theme.colors.body}
                                borderWidth={"5px"}
                                parentLoading={loading}
                            />
                        </DisplayPic>
                    </DisplayPicWrapper>
                </DisplayPicRow>
                <NameSection>
                    <NameLoading>
                        <LoadingShimmer />
                    </NameLoading>
                    {isUserProfile && (
                        <NumConxLoading>
                            <LoadingShimmer />
                        </NumConxLoading>
                    )}
                    {isCompanyProfile && (
                        <BioLoading>
                            <LoadingShimmer />
                        </BioLoading>
                    )}
                </NameSection>
            </HeaderWrapper>
        );
    } else {
        // const { numConx } = userProps;
        const { numConx, handleSeeConx } = userProps;

        const BtnBarForRender = (
            <BtnBar
                userIsAdmin={userIsAdmin}
                phone={phone}
                handleMessageClick={handleMessageClick}
                conxStatus={conxStatus}
                userProps={userProps}
                companyProps={companyProps}
                showConxBtn={createView}
                theme={theme}
                handleEditProfile={() =>
                    setPopUpToDisplay(popupStrings.editProfile)
                }
                isUserProfile={isUserProfile}
                isCompanyProfile={isCompanyProfile}
                handleConxAction={handleConxAction}
            />
        );

        const RequestPendingBtns = (
            <ActionRequestSection>
                <PendingTitle>{`${name} sent you a friend request.`}</PendingTitle>
                <PendingButtonsRow>
                    <PendingBtnWrapper>
                        <Button
                            handleClick={() =>
                                handleConxAction(CONNECTION_ACTION.ACCEPT)
                            }
                            icon={<i className="fas fa-user-check"></i>}
                            bgColor={theme.colors.darkAccent}
                            textColor={theme.colors.primary}
                            noShadow={true}
                        >
                            Accept
                        </Button>
                    </PendingBtnWrapper>
                    <PendingBtnWrapper>
                        <Button
                            handleClick={() =>
                                handleConxAction(CONNECTION_ACTION.REMOVE)
                            }
                            icon={<i className="fas fa-user-times"></i>}
                            bgColor={theme.colors.offPrimary}
                            textColor={theme.colors.text.semantic.error}
                            noShadow={true}
                        >
                            Decline
                        </Button>
                    </PendingBtnWrapper>
                </PendingButtonsRow>
            </ActionRequestSection>
        );

        return (
            <HeaderWrapper>
                <CoverBox />
                {/* Scroll to below cover photo when !!scrollFocusContent */}
                {!!scrollFocusContent && <ScrollToOnMount smooth={true} />}
                <DisplayPicRow height={avatarSize}>
                    <DisplayPicWrapper>
                        <DisplayPic>
                            <Avatar
                                path={avatarPaths.src}
                                size={avatarSize}
                                letterAsAltImg={
                                    isCompanyProfile && name && name.length > 0
                                        ? {
                                            altLetter: name[0],
                                            fontSize: "5rem",
                                        }
                                        : undefined
                                }
                                borderColor={theme.colors.body}
                                borderWidth={"5px"}
                                handleClick={() =>
                                    openFullScreenImgView([avatarPaths.src], 0)
                                }
                                company={isCompanyProfile}
                                user={isUserProfile}
                            />
                            {userIsAdmin && (
                                <DisplayPicEditButton>
                                    <ButtonCircle
                                        handleClick={() =>
                                            setPopUpToDisplay(
                                                popupStrings.displayPic
                                            )
                                        }
                                        circleDiameter={`calc(${circleBtnDiameter}/1.1)`}
                                        fontSize={`calc(${circleBtnDiameter}/2.3)`}
                                        bgColor={theme.colors.secondary}
                                        borderColor={theme.colors.primary}
                                    >
                                        <PhotoIcon />
                                    </ButtonCircle>
                                </DisplayPicEditButton>
                            )}
                        </DisplayPic>
                    </DisplayPicWrapper>
                    <LgScreenBtnBarWrapper
                        btnBarCriticalScSize={btnBarCriticalScSize}
                    >
                        {BtnBarForRender}
                    </LgScreenBtnBarWrapper>
                </DisplayPicRow>
                <NameSection>
                    <Name>{name}</Name>
                    {isUserProfile && numConx > 0 && (
                        <NumConnxWrapper>
                            {/* <ConxIcon className="fas fa-user-friends"></ConxIcon> */}
                            <NumConxTxt onClick={handleSeeConx}>
                                {` ${numConx}`}
                                {" Friends"}
                            </NumConxTxt>
                        </NumConnxWrapper>
                    )}
                    {isCompanyProfile && !!companyProps.bio && (
                        <Bio>{companyProps.bio}</Bio>
                    )}
                </NameSection>
                <SmBtnBarRow>
                    <SmScreenBtnBarWrapper
                        btnBarCriticalScSize={btnBarCriticalScSize}
                    >
                        {BtnBarForRender}
                    </SmScreenBtnBarWrapper>
                </SmBtnBarRow>
                {/* Action incoming friend request buttons  */}
                {conxStatus === CONNECTION_STATUS.PENDING &&
                    isUserProfile &&
                    RequestPendingBtns}
            </HeaderWrapper>
        );
    }
};

export default withTheme(ProfileHeaderView);

const HeaderWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    min-width: 0;
    border-radius: 0 0 15px 15px;
`;

const CoverBox = styled.div`
    position: relative;
    width: 100%;
    height: 100px;
    border-radius: 0 0 15px 15px;
    /* background-color: ${(props) => props.theme.colors.darkAccent}; */
    background-color: ${(props) => props.theme.colors.body};
    /* background-color: ${(props) => props.theme.colors.offSecondary}; */
    /* background-color: ${(props) => props.theme.colors.secondary}; */
    overflow: hidden; /* stop loading shimmer overflowing  */
`;

const DisplayPicRow = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    height: calc(${(props) => props.height} / (1 / 0.4));
    @media (max-width: ${({ theme }) => theme.sizes.smViewWidth}) {
        margin: 0 1rem;
    }
`;

const LgScreenBtnBarWrapper = styled.div`
    position: absolute;
    left: calc(50% + (${avatarSize} / 2));
    padding: 0.7rem 0;
    @media (max-width: ${({ btnBarCriticalScSize }) => btnBarCriticalScSize}) {
        display: none;
    }
`;

const SmBtnBarRow = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const SmScreenBtnBarWrapper = styled.div`
    display: none;
    @media (max-width: ${({ btnBarCriticalScSize }) => btnBarCriticalScSize}) {
        display: block;
        padding: 0 0.5rem 0.5rem 0.5rem;
    }
`;

const DisplayPicWrapper = styled.div`
    z-index: 1;
    position: absolute;
    bottom: 100%;
`;

// With bottom: 100% the margin-bottom -50% positions the display picture half its height into cover photo
const DisplayPic = styled.div`
    margin-bottom: -45%;
    position: relative;
`;

// Edit button in bottom left of DP, adjusts with avatarSize
const DisplayPicEditButton = styled.div`
    position: absolute;
    z-index: 1;
    top: calc(${avatarSize} / 1.3);
    left: calc((${avatarSize} / 16));
`;

// Header btn bar positioned right of cover photo
// const HeaderBtnBarWrapper = styled.div`
//     margin: 0 0rem 0 calc(${avatarSize} + 0rem);
//     max-width: 100%;
//     width: 100%;
//     display: flex;
//     align-items: center;
//     @media (max-width: ${({ theme }) => theme.sizes.smViewWidth}) {
//         margin: 0 0.2rem 0 calc(${avatarSize} + 0.5rem);
//     }
// `;

const NameSection = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 0;
    align-items: center;
    margin: 1.2rem 0.5rem 0.5rem 0.5rem;
`;

const Name = styled.h3`
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    padding-bottom: 0.5rem;
`;

const NameLoading = styled.div`
    width: 175px;
    height: 1.5rem;
    border-radius: 1rem;
    overflow: hidden;
    margin-bottom: 0.7rem; /* Must be slightly more than the Name margin bottom */
`;

const Bio = styled.p`
    margin-bottom: 0.2rem;
`;

const NumConnxWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const NumConxTxt = styled.div`
    display: inline;
    font-size: 1.3rem;
    font-weight: 500;
    cursor: pointer;
`;

const NumConxLoading = styled.div`
    width: 105px;
    height: 1.3rem;
    border-radius: 1rem;
    overflow: hidden;
`;

const BioLoading = styled.div`
    width: 120px;
    height: 1rem;
    border-radius: 1rem;
    overflow: hidden;
`;

const ActionRequestSection = styled.div`
    margin: 0rem 0.5rem 1.5rem 0.5rem;
    color: ${(props) => props.theme.colors.darkAccent};
`;

const PendingTitle = styled.h6`
    padding: 0 0 0.5rem 0;
    margin: 0;
    font-style: italic;
    text-align: center;
`;

const PendingButtonsRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const PendingBtnWrapper = styled.div`
    flex: 0 1 200px;
    padding: 0 0.25rem;
`;
