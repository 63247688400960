import styled from "styled-components";

import BackBar from "./BackBar-view";
import PostLoading from "./PostLoading-view";
import PostEngagementSection from "./PostEngagementSection-view";
import PostBody from "./PostBody-view";
import PostHeader from "./PostHeader-view";
import Alert from "../../library/common/Alert";
import { ALERT_TYPES, POST_POPUP_TYPES } from "../../library/common/constants";
import PostComments from "./PostComments-view";

// Depending on if post is loading and if postFullView is true wraps loading screen or post contents in their wrappers
// E.g. if post is loading and postFullView then a full post loading screen with a back back bar is show
// E.g. is loaded and not postFullView then wrap post components in news feed wrapper
const PostView = ({
    currentUser,
    post,
    handleLike,
    history,
    theme,
    setPopUpToDisplay,
    postFullView,
    loading,
    removeError,
    errors,
    userIsAdmin,
    currentUserInTags,
    startingImgIndex,
    commentState,
    setCommentState,
    handlePostSubmit,
    commentLoading,
    handleCommentDelete,
    deletingComment,
    loadEngagementData, // function loads in all post data from server (importantly loads the comment and like data)
    commentsLoading,
    handleSeeLikes,
}) => {
    // Post contents to be rendered once loading finished
    const PostContents = (
        <div>
            {/* Render error if one exists  */}
            {errors.message &&
                postFullView && ( // only show error message in post full view since news feed handles errors its-self
                    <Alert
                        message={errors.message}
                        type={ALERT_TYPES.ERROR}
                        noBorderRadius={false}
                        hideBorder={true}
                        removeError={removeError}
                    />
                )}
            {/* Check post contains content then render post contents */}
            {Object.keys(post)[0] && (
                <div>
                    <PostHeader
                        post={post}
                        history={history}
                        theme={theme}
                        setPopUpToDisplay={setPopUpToDisplay}
                        currentUser={currentUser}
                        userIsAdmin={userIsAdmin}
                        currentUserInTags={currentUserInTags}
                    />
                    <PostBody
                        post={post}
                        setPopUpToDisplay={setPopUpToDisplay}
                        theme={theme}
                        history={history}
                        postFullView={postFullView}
                        userIsAdmin={userIsAdmin}
                        handleAvatarLineupClick={() =>
                            setPopUpToDisplay(POST_POPUP_TYPES.TAGS)
                        }
                        startingImgIndex={startingImgIndex}
                        handleAddTag={() => {
                            setPopUpToDisplay(POST_POPUP_TYPES.ADD_TAGS);
                        }}
                    />
                    <PostEngagementSection
                        post={post}
                        handleLike={handleLike}
                        handleComment={(e) => {
                            e.stopPropagation();
                            loadEngagementData();
                        }}
                        theme={theme}
                        handleSeeLikes={handleSeeLikes}
                    />
                    <PostComments
                        post={post}
                        commentState={commentState}
                        setCommentState={setCommentState}
                        handleComment={() => {}}
                        theme={theme}
                        history={history}
                        currentUser={currentUser}
                        handlePostSubmit={handlePostSubmit}
                        commentLoading={commentLoading}
                        handleCommentDelete={handleCommentDelete}
                        deletingComment={deletingComment}
                        commentsLoading={commentsLoading}
                    />
                </div>
            )}
        </div>
    );

    if (loading) {
        if (!postFullView) {
            // News feed post loading screen
            return (
                <PostWrapper showShadow={true}>
                    <PostLoading theme={theme} image={true} />
                </PostWrapper>
            );
        } else {
            // Post full view loading screen
            return (
                <div>
                    <BackBar
                        theme={theme}
                        post={post}
                        history={history}
                        loading={loading}
                    />
                    <PostFullViewPage>
                        <PostFullViewCol>
                            <PostWrapper>
                                <PostLoading theme={theme} image={true} />
                            </PostWrapper>
                        </PostFullViewCol>
                    </PostFullViewPage>
                </div>
            );
        }
    } else {
        if (!postFullView) {
            // News feed view of post
            return <PostWrapper showShadow={true}>{PostContents}</PostWrapper>;
        } else {
            // Post full view version of post
            return (
                <div>
                    <BackBar
                        theme={theme}
                        post={post}
                        history={history}
                        showName={
                            loading
                                ? false
                                : Object.keys(post)[0]
                                ? true
                                : false
                        } // only show name if not loading and post contain content
                    />
                    <PostFullViewPage>
                        <PostFullViewCol>
                            <PostWrapper>{PostContents}</PostWrapper>
                        </PostFullViewCol>
                    </PostFullViewPage>
                </div>
            );
        }
    }
};

export default PostView;

const PostFullViewCol = styled.div`
    flex: 0 1 700px;
    @media (max-height: 800px) {
        flex: 0 1 550px;
    }
    min-width: 0; /* needed for white space no wrap */
`;

const PostFullViewPage = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 0.5rem 0 2rem 0;
`;

// Exported to newsFeedView for news feed loading screen
export const PostWrapper = styled.div`
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 10px;
    box-shadow: 0px 0px 35px
        ${({ theme, showShadow }) => (showShadow ? `#35353535` : "none")};
`;
