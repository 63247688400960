import { useEffect, useState } from "react";
import TextInputGroup from "../InputGroup";
import { INPUT_TYPES } from "../constants";

// Takes in address from parent, renders and input with autocomplete functionailty. When user selects from autocomplete dropdown can updateAddress and updatelatLng in parents state  accordingly
// Note: Make sure you run a check for maps API in parent before rendering this component
const AutocompleteContainer = ({
    options,
    address,
    autofillUpdateLocation,
    addressFailedValidation,
    required,
    small,
    description,
}) => {
    // Handle address change locally and only set parents address when user selects address from dropdown. This allows us to do a front end check before form is submitted that address is a proper google maps address with the corresponding latLng in parent.
    const [typedAddress, setTypedAddress] = useState(address);

    // address is added to typedAddress on mount and useEffect will refresh this whenever address changes in parent (i.e. when user click location on map or when user clicks address in autofill dropdown)
    useEffect(() => {
        setTypedAddress(address);
    }, [address]);

    // Setup autocomplete and add listener for when user clicks on an address from dropdown
    useEffect(() => {
        const autoComplete = new window.google.maps.places.Autocomplete(
            document.getElementById("autocomplete"),
            options
        );
        autoComplete.setFields(["address_components", "formatted_address"]);
        // addListener for place changed event. When user selects a place from dropdown sets address state and latLng in parent to that formatted address
        autoComplete.addListener("place_changed", () => {
            const addressObject = autoComplete.getPlace();
            const formattedAddress = addressObject.formatted_address;
            autofillUpdateLocation(formattedAddress);
        });
    }, [options, autofillUpdateLocation]);

    return (
        <TextInputGroup
            type={INPUT_TYPES.TEXT}
            handleChange={(e) => {
                setTypedAddress(e.target.value);
            }}
            errorMessage={addressFailedValidation}
            value={typedAddress}
            title={"Address"}
            placeholder={"Search Address"}
            id={"autocomplete"}
            small={small}
            description={description}
            required={required}
            // Note this component doesn't seem to allow you to turn the browser autocomplete off, best guess is its the google api doing this
        />
    );
};

export default AutocompleteContainer;
