import styled from "styled-components";

import CompanyProfile from "../../modules/CompanyProfile";

import Alert from "../../library/common/Alert";
import AutocompleteAddressInput from "../../library/common/Autocomplete";
import Button from "../../library/common/Button";
import InputGroup from "../../library/common/InputGroup";
import LoadingSpinner from "../../library/common/LoadingSpinner";

import {
    INPUT_TYPES,
    ALERT_TYPES,
    COMPANY_PAGES,
} from "../../library/common/constants";

// View page when creating a company
const CreateCompanyView = ({
    errors,
    removeError,
    formState,
    validationFailedState,
    handleSubmit,
    handleChange,
    handleCancel,
    autofillUpdateLocation,
    loading,
    mapsAPILoading,
}) => {
    const { name, companyType, websiteURL, phone, officeLocations } = formState;

    const FormInputs = (
        <DetailsWrapper>
            <PageTitle className="">Create Company Page</PageTitle>
            <InputGroupWrapper>
                <InputGroup
                    type={INPUT_TYPES.TEXT}
                    handleChange={handleChange}
                    errorMessage={validationFailedState.name}
                    value={name}
                    name={"name"}
                    title={"Name"}
                    placeholder={"Company name"}
                    required={true}
                    description={`The name of your business, your brand, what you trade under.`}
                />
            </InputGroupWrapper>
            <InputGroupWrapper>
                <InputGroup
                    name={"companyType"}
                    value={companyType}
                    title={`Company Type`}
                    type={INPUT_TYPES.SELECT}
                    errorMessage={validationFailedState.companyType}
                    handleChange={handleChange}
                    description={`Select the main role of your company.`}
                    required={true}
                >
                    <option value="">Choose One</option>
                    <option value="CONTRACTOR">Contractor</option>
                    <option value="MATERIAL_SUPPLIER">Material Supplier</option>
                    <option value="HIRE_COMPANY">Hire Company</option>
                </InputGroup>
            </InputGroupWrapper>
            <ContactDetailsTitle>Contact Details</ContactDetailsTitle>
            <ContactInputGroupWrapper>
                <InputGroup
                    type={INPUT_TYPES.TEXT}
                    handleChange={handleChange}
                    errorMessage={validationFailedState.phone}
                    value={phone}
                    name={"phone"}
                    title={"Phone number"}
                    placeholder={"Phone number"}
                    required={true}
                    description={`General contact number for your business.`}
                />
            </ContactInputGroupWrapper>
            <ContactInputGroupWrapper>
                <AutocompleteAddressInput
                    options={{
                        types: ["address"],
                        componentRestrictions: {
                            country: "nz",
                        },
                    }}
                    address={
                        officeLocations[0] ? officeLocations[0].address : ""
                    }
                    autofillUpdateLocation={autofillUpdateLocation}
                    addressFailedValidation={
                        validationFailedState.officeLocations
                    }
                    required={true}
                    description={`Address will be shown on your business profile. You can change this location or add more at anytime.`}
                    small={"Select address from dropdown"}
                />
            </ContactInputGroupWrapper>
            <ContactInputGroupWrapper>
                <InputGroup
                    type={INPUT_TYPES.TEXT}
                    handleChange={handleChange}
                    errorMessage={validationFailedState.websiteURL}
                    value={websiteURL}
                    name={"websiteURL"}
                    title={"Website"}
                    placeholder={"https://www.example@domain.com"}
                />
            </ContactInputGroupWrapper>
        </DetailsWrapper>
    );

    const ButtonBar = (
        <FooterBtnBlock>
            <LeftBtnWrapper>
                <Button
                    handleClick={handleCancel}
                    textSize={"1rem"}
                    secondaryBtnColors={true}
                >
                    Cancel
                </Button>
            </LeftBtnWrapper>
            <RightBtnWrapper>
                <Button
                    handleClick={handleSubmit}
                    textSize={"1rem"}
                    primaryBtnColors={true}
                >
                    Create
                </Button>
            </RightBtnWrapper>
        </FooterBtnBlock>
    );

    if (!mapsAPILoading) {
        return (
            <Wrapper>
                {errors.message && (
                    <ShowAlertOverTop>
                        <Alert
                            message={errors.message}
                            type={ALERT_TYPES.ERROR}
                            removeError={removeError}
                            noBorderRadius={false}
                            hideBorder={false}
                        />
                    </ShowAlertOverTop>
                )}
                <MainFormRow>
                    <CompanyDetailsCol>
                        {loading && (
                            <LoadingSidebar>
                                <LoadingWrapperSize>
                                    <LoadingSpinner />
                                </LoadingWrapperSize>
                            </LoadingSidebar>
                        )}
                        {!loading && (
                            <StickyWrapper>
                                <SidebarWrapper>
                                    {FormInputs}
                                    {ButtonBar}
                                </SidebarWrapper>
                            </StickyWrapper>
                        )}
                    </CompanyDetailsCol>
                    <ProfilePreviewCol>
                        <PreviewBox>
                            <DisableClickOverlay />
                            <PreviewTitle>Profile Preview</PreviewTitle>
                            <ProfileBackgroundColor>
                                <CompanyProfile
                                    page={COMPANY_PAGES.ABOUT.name}
                                    previewState={formState}
                                />
                            </ProfileBackgroundColor>
                        </PreviewBox>
                    </ProfilePreviewCol>
                </MainFormRow>
            </Wrapper>
        );
    } else {
        return (
            <LoadingRow>
                <LoadingWrapper>
                    <LoadingSpinner />
                </LoadingWrapper>
            </LoadingRow>
        );
    }
};

export default CreateCompanyView;

const Wrapper = styled.div``;

const MainFormRow = styled.div`
    display: flex;
`;

const CompanyDetailsCol = styled.div`
    flex: 0 0.2 400px;
    box-shadow: 2px 2px 50px rgba(150, 150, 150, 0.2);
    @media (max-width: ${({ theme }) => theme.sizes.mdViewWidth}) {
        flex: 0 1 400px; /* Restore shrink 1 so width shrinks with screen below 400px*/
    }
`;

const ProfilePreviewCol = styled.div`
    flex: 1 2 1000px;
    display: flex;
    justify-content: center;
    padding: 1rem;
    @media (max-width: ${({ theme }) => theme.sizes.mdViewWidth}) {
        display: none; /* Don't display profile preview on small screens */
    }
`;

const SidebarWrapper = styled.div`
    height: calc(100vh - ${(props) => props.theme.sizes.navHeight});
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const PreviewBox = styled.div`
    flex: 0 1 800px;
    border-radius: 0.5rem;
    padding: 0.2rem 1rem 1rem 1rem;
    border: 1px solid ${(props) => props.theme.colors.offPrimary};
    box-shadow: 2px 2px 30px rgba(150, 150, 150, 0.2);
    background-color: ${(props) => props.theme.colors.primary};
    position: relative; /* So ProfileBackgroundColor can fill PreviewBox height */
    display: flex;
    flex-direction: column; /* Needed to make ProfileBackgroundColor fill space */
`;

// Rendered overtop of profile preview to stop the user being able to interact with the preview profile (e.g. stop clicking profile pic, changing tabs etc)
const DisableClickOverlay = styled.div`
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
`;

const ProfileBackgroundColor = styled.div`
    background-color: ${(props) => props.theme.colors.body};
    flex: 1; /* Fill remaining spacing in PreviewBox */
    border-radius: 0.5rem;
    overflow: hidden;
    position: relative;
    z-index: 0;
`;

const PreviewTitle = styled.h6`
    margin: 0;
    padding: 0.5rem;
`;

const StickyWrapper = styled.div`
    position: sticky;
    top: ${({ theme }) => theme.sizes.navHeight};
    max-height: calc(100vh - ${({ theme }) => theme.sizes.navHeight});
    overflow-y: auto;
`;

const DetailsWrapper = styled.div`
    padding: 1rem 1.5rem;
    position: relative;
    z-index: 0;
    max-height: auto;
    overflow-y: auto; /* Scroll within this div when more height needed */
`;

const FooterBtnBlock = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    background-color: ${(props) => props.theme.colors.primary};
    border-top: 1px solid ${(props) => props.theme.colors.offPrimary};
`;

const LeftBtnWrapper = styled.div`
    flex: 0 1 200px;
    margin-right: 0.5rem;
`;

const RightBtnWrapper = styled.div`
    flex: 0 1 400px;
`;

const PageTitle = styled.h4`
    margin: 0;
    padding-bottom: 1.5rem;
`;

const InputGroupWrapper = styled.div`
    padding: 0 0 2rem 0;
`;

const ContactDetailsTitle = styled.h5`
    margin: 0;
    padding: 0 0 0.5rem 0;
`;

const ContactInputGroupWrapper = styled.div`
    padding: 0 0 1.5rem 0;
`;

const ShowAlertOverTop = styled.div`
    position: absolute;
    z-index: 1;
    width: 100%;
    padding: 0.5rem;
`;

const LoadingSidebar = styled(SidebarWrapper)`
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: auto;
    padding: 1rem;
`;

const LoadingWrapperSize = styled.div`
    flex: 0 1 75px;
`;

const LoadingRow = styled.div`
    display: flex;
    justify-content: center;
    padding: 1rem;
`;

const LoadingWrapper = styled.div`
    flex: 0 1 50px;
`;