import EditProfileDetailsView from "./PopupEditProfileDetails-view";
import EditAboutUs from "./PopupEditAboutUs-view";

import AddressEdit from "../../library/common/popups/AddressListEdit";
import TextEdit from "../../library/common/popups/TextEdit";
import ProfileImageEdit from "../../library/common/popups/ProfileImageEdit";
import ServicesEdit from "../../library/common/popups/ServicesEdit";
// import ServiceEditWithHOC from "../../library/common/popups/ServicesEdit";

import {
    COMPANY_PROFILE_VALIDATION_RULES,
    COMPANY_POPUP_TYPES,
    REACT_APP_DEFAULT_COMPANY_COVER,
    REACT_APP_DEFAULT_COMPANY_PROFILE,
} from "../../library/common/constants";

// TODO there is a changing state wanring coming up when I mount edit popup for header section. I think its the phone field changing for from null to "" or somthing like that

// PopUpRouterContainer uses popUpToDisplay to decide which popup to render. All other props just passed through to the respective popups
const PopUpRouterContainer = ({
    popUpToDisplay,
    companyDetails,
    serverPaths,
    addError,
    errors,
    setPopUpToDisplay,
    setCompanyDetails,
    refreshDataFromServer,
}) => {
    // define text fields of companyDetails that will be passed to TextEditPopUp (i.e. kick out file fields)
    const {
        logo,
        coverPhoto,
        OfficeLocations,
        name,
        quickDescription,
        // companyType,
        phone,
        email,
        websiteURL,
        aboutUs,
        services,
    } = companyDetails;
    // Destructure server paths to be used in the various popups
    const {
        addLogoPicPath,
        deleteLogoPicPath,
        addCoverPicPath,
        deleteCoverPicPath,
        serverPath,
    } = serverPaths;

    if (popUpToDisplay === COMPANY_POPUP_TYPES.header) {
        return (
            <TextEdit
                popupTitle={"Company Details"}
                parentState={{
                    name,
                    quickDescription,
                    phone,
                    email,
                    websiteURL,
                }}
                addError={addError}
                errors={errors}
                setPopUpToDisplay={setPopUpToDisplay}
                setParentState={setCompanyDetails}
                serverPath={serverPath}
                FormFieldsToRender={EditProfileDetailsView}
                profileValidationRules={COMPANY_PROFILE_VALIDATION_RULES}
            />
        );
    } else if (popUpToDisplay === COMPANY_POPUP_TYPES.about) {
        return (
            <TextEdit
                popupTitle={"About"}
                parentState={{ aboutUs }}
                addError={addError}
                errors={errors}
                setPopUpToDisplay={setPopUpToDisplay}
                setParentState={setCompanyDetails}
                serverPath={serverPath}
                FormFieldsToRender={EditAboutUs}
                profileValidationRules={COMPANY_PROFILE_VALIDATION_RULES}
            />
        );
    } else if (popUpToDisplay === COMPANY_POPUP_TYPES.services) {
        return (
            <ServicesEdit
                services={services}
                servicesName={"services"}
                addError={addError}
                errors={errors}
                setPopUpToDisplay={setPopUpToDisplay}
                refreshDataFromServer={refreshDataFromServer}
                serverPath={serverPath}
                recommendationServerPath={serverPaths.serviceRecommendations}
            />
        );
    } else if (popUpToDisplay === COMPANY_POPUP_TYPES.logo) {
        return (
            <ProfileImageEdit
                popupTitle={"Logo"}
                type={COMPANY_POPUP_TYPES.logo}
                imgDetails={logo}
                addError={addError}
                errors={errors}
                refreshParentState={refreshDataFromServer}
                setPopUpToDisplay={setPopUpToDisplay}
                addImgPath={addLogoPicPath}
                deleteImgPath={deleteLogoPicPath}
                defaultImg={REACT_APP_DEFAULT_COMPANY_PROFILE}
            />
        );
    } else if (popUpToDisplay === COMPANY_POPUP_TYPES.coverPhoto) {
        return (
            <ProfileImageEdit
                popupTitle={"Cover Photo"}
                type={COMPANY_POPUP_TYPES.coverPhoto}
                imgDetails={coverPhoto}
                addError={addError}
                errors={errors}
                refreshParentState={refreshDataFromServer}
                setPopUpToDisplay={setPopUpToDisplay}
                addImgPath={addCoverPicPath}
                deleteImgPath={deleteCoverPicPath}
                defaultImg={REACT_APP_DEFAULT_COMPANY_COVER}
            />
        );
    } else if (popUpToDisplay === COMPANY_POPUP_TYPES.OfficeLocations) {
        return (
            <AddressEdit
                popupTitle={"Locations"}
                addressList={OfficeLocations}
                addError={addError}
                errors={errors}
                setPopUpToDisplay={setPopUpToDisplay}
                serverPath={serverPath}
                setParentState={setCompanyDetails}
                addressFieldName={"OfficeLocations"}
            />
        );
    } else {
        return <div></div>;
    }
};

export default PopUpRouterContainer;
