import { useContext } from "react";
import ChatListView from "./ChatList-view";

import {
    checkForUnreadMsgInChat,
    chatGivenNamesString,
    formatDateTimeAgo,
    useUpdateTime,
} from "../../../library/utilities";

import SocketContext from "../../../services/socket";

const ChatList = ({
    currentUser,
    history,
    // conversations,
    messageId,
    newChat,
    ...chatListViewProps
}) => {

    const useIO = useContext(SocketContext);
    const { conversations, conversationsLoading } = useIO();

    // time now hook - updates time now ever 30 seconds, for use in calculating message time ago
    const timeNow = useUpdateTime(30000);

    const newChatListItem = newChat ? [{ isSelected: true }] : [];

    const chats = newChatListItem.concat(conversations).map(chat => {

        const { _id = null, name = null, members = [], messages = [] } = chat;
        const isSelected = _id && _id === messageId;

        const unreadMessageInChat = checkForUnreadMsgInChat(chat, currentUser.user.id);

        const otherChatMembers = members.filter(el => el.userId !== currentUser.user.id);
        // NOTE: Don't filter by status because we just want to know if it has EVER been a group chat
        const nameToDisplay = name || chatGivenNamesString(otherChatMembers) || "New Message";

        const lastMessage = messages[messages.length - 1] || {};
        const lastMessageText = lastMessage.messageText ||
            (lastMessage.images ? "Sent a photo." : "");
        const timeSinceLastMessage = lastMessage.messageDate ?
            formatDateTimeAgo(lastMessage.messageDate, timeNow, true) : "";

        return {
            ...chat,
            isSelected,
            unreadMessageInChat,
            otherChatMembers,
            nameToDisplay,
            lastMessageText,
            timeSinceLastMessage,
        };

    });

    const handleClickChatItem = (convo) => {
        if (convo._id) history.push(`/messages/${convo._id}`);
    };

    const viewProps = {
        ...chatListViewProps,
        chats,
        conversationsLoading,
        handleClickChatItem,
    };

    return <ChatListView {...viewProps} />;
};

export default ChatList;