import styled, { withTheme } from "styled-components";

import WorkExperienceItems from "./WorkExperienceItems-view";
import ServicesList from "../../../library/common/ServicesList";
import EditButton from "../../../library/common/ButtonEditCircle";
import NoDetailsScreen from "../../../library/common/NoDetailsView";
import LicenceItems from "./LicenceItems";
import {
    SectionBox,
    HeaderBox,
    BoxTitle,
    SectionContent,
    SectionBoxWrapper,
} from "../../../resources/styles";
import ButtonCircle from "../../../library/common/ButtonCircleIcon";

const BtnSize = "2.3rem";
const BtnFontSize = "1rem";

// Renders company about page
const UserProfileAboutView = ({ userDetails, theme, profileSections }) => {
    const { userIsAdmin, licences, expertise, workExperience } = userDetails;

    return (
        <Wrapper>
            {profileSections.expertise.has && (
                <SectionBoxWrapper>
                    <SectionBox>
                        <HeaderBox>
                            <BoxTitle>
                                <i className="fas fa-hard-hat mr-3"></i>
                                {profileSections.expertise.title}
                            </BoxTitle>
                            {userIsAdmin && (
                                <EditButton
                                    handleClick={profileSections.expertise.edit}
                                    invDefaultColors={true}
                                    circleDiameter={BtnSize}
                                    fontSize={BtnFontSize}
                                />
                            )}
                        </HeaderBox>
                        <SectionContent>
                            {expertise && expertise[0] && (
                                <ServicesList services={expertise} />
                            )}
                        </SectionContent>
                    </SectionBox>
                </SectionBoxWrapper>
            )}
            {profileSections.licences.has && (
                <SectionBoxWrapper>
                    <SectionBox>
                        <HeaderBox>
                            <BoxTitle>
                                <i className="fas fa-university mr-3"></i>
                                {profileSections.licences.title}
                            </BoxTitle>
                            <LicenceBtnsWrapper>
                                {userIsAdmin && (
                                    <EditButton
                                        handleClick={
                                            profileSections.licences.edit
                                        }
                                        invDefaultColors={true}
                                        circleDiameter={BtnSize}
                                        fontSize={BtnFontSize}
                                    />
                                )}
                                {userIsAdmin && (
                                    <AddBtnWrapper>
                                        <ButtonCircle
                                            handleClick={
                                                profileSections.licences.add
                                            }
                                            bgColor={theme.colors.primary}
                                            iconColor={theme.colors.secondary}
                                            circleDiameter={BtnSize}
                                            fontSize={BtnFontSize}
                                        >
                                            <i className="fas fa-plus"></i>
                                        </ButtonCircle>
                                    </AddBtnWrapper>
                                )}
                            </LicenceBtnsWrapper>
                        </HeaderBox>
                        <SectionContent>
                            <LicenceItems items={licences} theme={theme} />
                        </SectionContent>
                    </SectionBox>
                </SectionBoxWrapper>
            )}
            {profileSections.workExperiences.has && (
                <SectionBoxWrapper>
                    <SectionBox>
                        <HeaderBox>
                            <BoxTitle>
                                <i className="fas fa-compass mr-3"></i>
                                {profileSections.workExperiences.title}
                            </BoxTitle>
                            {userIsAdmin && (
                                <EditButton
                                    handleClick={
                                        profileSections.workExperiences.edit
                                    }
                                    invDefaultColors={true}
                                    circleDiameter={BtnSize}
                                    fontSize={BtnFontSize}
                                />
                            )}
                        </HeaderBox>
                        <SectionContent>
                            {workExperience && workExperience[0] && (
                                <WorkExperienceItems
                                    items={workExperience}
                                    theme={theme}
                                />
                            )}
                        </SectionContent>
                    </SectionBox>
                </SectionBoxWrapper>
            )}
            {!profileSections.expertise.has &&
                !profileSections.licences.has &&
                !profileSections.workExperiences.has && (
                    <NoDetailsScreen
                        icon={<i className="fas fa-clipboard"></i>}
                        message={"No profile details yet"}
                    />
                )}
        </Wrapper>
    );
};

export default withTheme(UserProfileAboutView);

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 5rem;
    padding: 0.5rem 0;
`;

const LicenceBtnsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    * {
        margin: 0 0.4rem;
    }
`;

const AddBtnWrapper = styled.div`
    @media (max-width: ${({ theme }) => theme.sizes.xsViewWidth}) {
        display: none;
    }
`;
