import AboutPage from "./AboutPage/AboutPage-view";
import SiteMap from "../../library/common/ProfileSiteMap";
import ImageGrid from "../../library/common/ImageGrid";
import ProfileAddSectionBox from "../../library/common/ProfileAddSectionBox";
import ProfileHeader from "../../library/common/ProfileHeader";
import TabNavbar from "../../library/common/TabNavbar";

import {
    SECTION_TITLES,
    USER_PAGES,
    USER_POPUP_TYPES,
    CONNECTION_STATUS,
} from "../../library/common/constants";

import {
    WidthWrapper,
    HeaderSection,
    ContentWrapper,
} from "../../resources/styles/index";

// View component render company profile
const UserProfileView = ({
    history,
    currentUser,
    setUserDetails,
    userDetails = {},
    setPopUpToDisplay,
    profilePageToDisplay,
    loading,
}) => {
    const {
        _id,
        username,
        givenName,
        workPhone,
        displayPicture = {},
        conxStatus,
        userIsAdmin,
        licences,
        expertise,
        workExperience,
        connections = [],
    } = userDetails;

    const numConx = Array.isArray(connections) ?
        connections.filter((el) => el.status === CONNECTION_STATUS.ACCEPTED).length : 0;

    // Used to tell ProfileAddSectionBox and About page what sections contain content, how to add to those section and the title for each section
    const profileSections = {
        licences: {
            has: licences && licences[0],
            add: () => { setPopUpToDisplay(USER_POPUP_TYPES.addLicence); },
            edit: () => { setPopUpToDisplay(USER_POPUP_TYPES.licences); },
            title: SECTION_TITLES.licences,
        },
        expertise: {
            has: expertise && expertise[0],
            edit: () => { setPopUpToDisplay(USER_POPUP_TYPES.expertise); },
            title: SECTION_TITLES.expertise,
        },
        workExperiences: {
            has: workExperience && workExperience[0],
            edit: () => { setPopUpToDisplay(USER_POPUP_TYPES.workExperiences); },
            title: SECTION_TITLES.workExperiences,
        },
    };

    return (
        <div>
            <HeaderSection>
                <WidthWrapper>
                    <ProfileHeader
                        userIsAdmin={userIsAdmin}
                        avatarPaths={{
                            src: displayPicture.path,
                        }}
                        setPopUpToDisplay={setPopUpToDisplay}
                        popupStrings={{
                            editProfile: USER_POPUP_TYPES.header,
                            coverPhoto: USER_POPUP_TYPES.coverPhoto,
                            displayPic: USER_POPUP_TYPES.displayPicture,
                        }}
                        phone={workPhone}
                        handleMessageClick={() =>
                            history.push("/messages/new", {
                                newChat: {
                                    userId: _id,
                                    username,
                                    givenName,
                                    img: displayPicture.path
                                }
                            })
                        }
                        name={username}
                        conxStatus={conxStatus}
                        userProps={{
                            profileUsersID: _id,
                            currentUserID: currentUser.user.id,
                            setUserDetails: setUserDetails,
                            numConx,
                            handleSeeConx: () => {
                                console.log("Working");
                                setPopUpToDisplay(USER_POPUP_TYPES.conxList);
                            },
                        }}
                        scrollFocusContent={
                            profilePageToDisplay !==
                            USER_PAGES[Object.keys(USER_PAGES)[0]].name
                            // Scroll down to content when on any tab other than the first one
                        }
                        loading={loading}
                    />

                    <TabNavbar
                        pageToDisplay={profilePageToDisplay}
                        commonUrl={`/users/${_id}`}
                        pages={Object.values(USER_PAGES)}
                        loading={loading}
                    />
                </WidthWrapper>
            </HeaderSection>
            {userIsAdmin && // Show admin add section controls only on about page
                profilePageToDisplay === USER_PAGES.ABOUT.name && (
                    <ProfileAddSectionBox
                        profileSections={profileSections}
                        title={"Help people find your business."}
                    />
                )}
            <ContentWrapper>
                {!loading && (
                    <WidthWrapper>
                        {profilePageToDisplay === USER_PAGES.ABOUT.name && (
                            <AboutPage
                                userDetails={userDetails}
                                setPopUpToDisplay={setPopUpToDisplay}
                                profileSections={profileSections}
                            />
                        )}
                        {profilePageToDisplay === USER_PAGES.GRID.name && (
                            <ImageGrid
                                viewRoute={`/api/users/${_id}/grid`}
                            />
                        )}
                        {profilePageToDisplay === USER_PAGES.SITE_MAP.name && (
                            <SiteMap
                                dataFetchRoute={`/api/users/${_id}/sites`}
                                profileName={givenName}
                            />
                        )}
                    </WidthWrapper>
                )}
            </ContentWrapper>
        </div>
    );
};

export default UserProfileView;