import styled, { withTheme } from "styled-components";

import { ContentBox, FooterBar } from "../../../../resources/styles";
import Button from "../../Button";

// Generic error popup needs function to remove error and optional errorMsg
// potential annoying thing here is since popup uses withPopup HOC a click outside popup with setPopUpToDisplay to empty string but not clear error
const ErrorPopupContainer = ({
    removeError,
    errorMsg, // optional if none given default "something went wrong."
    theme, // index
}) => {
    return (
        <Wrapper>
            <ContentBox>
                <ContentWrapper>
                    <IconRow>
                        <i className="fas fa-times-circle"></i>
                    </IconRow>
                    <HeadingRow>Ooops,</HeadingRow>
                    <MessageRow>
                        {errorMsg || "something went wrong."}
                    </MessageRow>
                </ContentWrapper>
            </ContentBox>
            <FooterBar>
                <FooterBtnBlock>
                    <RightBtnWrapper>
                        <Button
                            handleClick={removeError}
                            textSize={"1rem"}
                            bgColor={theme.colors.text.semantic.error}
                            textColor={theme.colors.primary}
                        >
                            Close
                        </Button>
                    </RightBtnWrapper>
                </FooterBtnBlock>
            </FooterBar>
        </Wrapper>
    );
};

export default withTheme(ErrorPopupContainer);

const Wrapper = styled.div`
    width: min(600px, 95vw);
    /* min-height: 350px; */
`;

const ContentWrapper = styled.div`
    padding: 1.3rem 1.5rem 0.2rem 1.5rem;
    /* min-height: 350px; */
    position: relative; /* Must position relative for GreyBox to work properly */
    display: flex;
    flex-direction: column;
    @media (max-width: ${({ theme }) => theme.sizes.mdViewWidth}) {
        padding: 0.5rem 0.7rem;
    }
`;

const IconRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 7rem;
    padding: 0.5rem;
    color: ${(props) => props.theme.colors.text.semantic.error};
`;

const HeadingRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    padding: 0.5rem;
    color: ${(props) => props.theme.colors.text.semantic.error};
`;

const MessageRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    padding: 1rem 0.5rem 2rem 0.5rem;
`;

const FooterBtnBlock = styled.div`
    display: flex;
    width: 100%; /* Match width of parent  */
    padding: 0 1rem;
    justify-content: flex-end;
`;

const RightBtnWrapper = styled.div`
    flex: 0 1 250px;
`;
