// import "../../resources/styles/PostList.css";

const PostListView = ({
    searchResults,
    handleChange,
    handleCardClick,
    handleLoadMore,
    canLoadMore,
    isSearching,
}) => {
    const formattedResultsList = searchResults.length > 0 ?
        searchResults.map((result) => {
            return (
                <li
                    className="post-card"
                    key={result._id}
                    onClick={() => handleCardClick(result._id)}
                    style={{
                        border: "solid black",
                        backgroundColor: "whitesmoke",
                        padding: "1rem"
                    }}
                >
                    <div className="row">
                        <div
                            className="col-2"
                        >
                            {
                                !!result.img &&
                                <img
                                    src={result.img}
                                    alt={`${result.img}`}
                                    style={{
                                        width: "100px",
                                        height: "100px",
                                        objectFit: "cover"
                                    }}
                                />
                            }
                        </div>
                        <div
                            className="col-10"
                        >
                            {result.text}

                        </div>

                    </div>
                </li>
            );
        }) :
        "";
    return (
        <div className="container-fluid">
            <div className="" id="homeBox">

                <h3>Find a post:</h3>
                <input
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    autoComplete="off"
                />
                <br></br>

                <ul>{formattedResultsList}</ul>

                {canLoadMore &&
                    <div
                        className="text-center"
                        style={{ cursor: "pointer" }}
                        onClick={handleLoadMore}
                    >
                        <h5>Load more results</h5>
                    </div>
                }
                {isSearching &&
                    <div className="text-center">
                        <h5>Searching...</h5>
                    </div>
                }
            </div>
        </div>
    );
};

export default PostListView;