import ButtonCircle from "../ButtonCircleIcon";

const ButtonEditCircleView = ({
    theme,
    circleDiameter, // all props from here done optional
    handleClick,
    fontSize,
    bgColor,
    iconColor,
    borderColor,
    invDefaultColors,
}) => {
    const { primary, secondary } = theme.colors;
    // First checks for a specified color, if not given checks for invDefaultColors option if also not given default colors are used
    iconColor = iconColor ? iconColor : invDefaultColors ? secondary : primary;
    bgColor = bgColor ? bgColor : invDefaultColors ? primary : secondary;
    borderColor = borderColor ? borderColor : bgColor;

    return (
        <ButtonCircle
            handleClick={handleClick}
            bgColor={bgColor}
            iconColor={iconColor}
            circleDiameter={circleDiameter}
            fontSize={fontSize}
            borderColor={borderColor}
        >
            <i className="fas fa-pen"></i>
        </ButtonCircle>
    );
};

export default ButtonEditCircleView;
