import React from "react";
import styled, { withTheme } from "styled-components";

const WaitListFormView = ({ theme }) => {
    return (
        <FormWrapper
            className="launchlist-form"
            // action="https://getlaunchlist.com/s/hcBx1Q" OLD
            action="https://getlaunchlist.com/s/IjeRav"
            method="POST"
        >
            <LandingPageInput name="email" type="email" placeholder="Email" />
            <Button type="submit">Join The Waitlist</Button>
        </FormWrapper>
    );
};

export default withTheme(WaitListFormView);

const MdLayoutBreakPoint = "1300px";
const SmLayoutBreakPoint = "900px";
const XsLayoutBreakPoint = "650px";

const FormWrapper = styled.form`
    display: flex;
    flex-direction: row;
    height: 50px;
    justify-content: center;
    /* border: 2px dashed yellow; */
    @media (max-width: ${SmLayoutBreakPoint}) {
        height: 45px;
    }
    @media (max-width: ${XsLayoutBreakPoint}) {
        height: 40px;
    }
`;

const LandingPageInput = styled.input`
    flex: 0 1 1000px;
    min-height: 100%;
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.colors.offPrimary};
    background-color: ${({ theme }) => theme.colors.primary};
    &:focus {
        box-shadow: 0 0 0 0.1rem ${({ theme }) => `${theme.colors.accent}50`};
    }
    font-size: 18px;
    margin-right: 10px;
    padding: 2px 10px;
    @media (max-width: ${XsLayoutBreakPoint}) {
        font-size: 13px;
        margin-right: 5px;
        padding: 2px 5px;
    }
`;

const Button = styled.button`
    flex: 0 1 200px;
    /* background-color: blue; */
    border-radius: 10px;
    display: flex;
    justify-content: ${({ justifyContent }) =>
        justifyContent ? justifyContent : "center"};
    align-items: center;
    /* width: 100%; */
    background-image: linear-gradient(
        to right,
        ${({ theme }) => theme.colors.lightAccent},
        ${({ theme }) => theme.colors.accentGreen}
    );
    font-weight: 600;
    color: ${(props) => props.theme.colors.body.text};
    font-size: 18px;
    white-space: nowrap;
    padding: 0 15px;
    @media (max-width: ${MdLayoutBreakPoint}) {
        font-size: 17px;
    }
    @media (max-width: ${XsLayoutBreakPoint}) {
        font-size: 13px;
        padding: 0 15px;
    }
`;
