import { useEffect, useState, useRef } from "react";
import styled from "styled-components";

import { checkAndLoadMapsAPI } from "../utilities";
import LoadingDots from "./LoadingDots";

// Renders Map component with optional markers.
const Map = ({
    configureMapBehaviour, // map configure function
    idIndex, // optional only needed if multiple maps could be rendered in DOM at once (in which case must be unique)
    zoom, // optional starting zoom for configureMapBehaviour
    addMarkersToMap, // optional function for adding markers to map
    locationsArray, // locations to be added as markers in addMarkersToMap
    markerPin, // optional marker pin (google maps pin is default)
    markerPinSize, // optional - only changes size if using custom pin
}) => {
    // On mount checks for maps API script tag, if maps API has not yet been loaded then loads it and setMapsAPILoading state to false
    const [mapsAPILoading, setMapsAPILoading] = useState(true);
    useEffect(() => {
        checkAndLoadMapsAPI(setMapsAPILoading);
    }, []);

    // ref array to get access to markers after they have been added
    const mapMarkers = useRef([]);

    // Map ref, ref used so addMarkersToMap function doesn't trigger infinte loop
    const map = useRef(undefined);

    // Set in configureMapBehaviour function - tells the useEffect containing addMarkersToMap function when to run
    const [mapConfigured, setMapConfigured] = useState(false);

    // Configure map UseEffect
    useEffect(() => {
        if (!mapsAPILoading) {
            map.current = new window.google.maps.Map(
                document.getElementById(`map${idIndex}`)
            );
            if (!!configureMapBehaviour) {
                configureMapBehaviour(map.current, setMapConfigured, zoom);
            } else {
                setMapConfigured(true);
            }
        }
    }, [configureMapBehaviour, mapsAPILoading, idIndex, zoom, map]);

    // Add markers useEffect
    useEffect(() => {
        if (!mapsAPILoading && mapConfigured && !!addMarkersToMap) {
            addMarkersToMap({
                map: map.current,
                locationsArray,
                zoom,
                markersLastRender: mapMarkers.current,
                clearMarkers: () =>
                    mapMarkers.current.forEach((markerObj) =>
                        markerObj.marker.setMap(null)
                    ),
                addMarkerToMarkers: (markerToAdd) =>
                    (mapMarkers.current = [...mapMarkers.current, markerToAdd]),
                markerPin,
                markerPinSize,
            });
        }
    }, [
        locationsArray,
        mapsAPILoading,
        addMarkersToMap,
        mapConfigured,
        zoom,
        markerPin,
        markerPinSize,
    ]);

    if (!mapsAPILoading) {
        return (
            <div
                className="mx-auto embed responsive item map"
                id={`map${idIndex}`}
            />
        );
    } else {
        return (
            <LoadingWrapper className="map">
                {/* Note class map so loading wrapper inherits map size class defined in index.css */}
                <LoadingDots size={"20px"} />
            </LoadingWrapper>
        );
    }
};

export default Map;

const LoadingWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;
