import styled from "styled-components";
import { darken } from "polished";

import { ALERT_TYPES } from "../constants";
import CloseButton from "../../common/ButtonCircleIcon";

// Banner alert component
// type four options; success, info, error and warning. Dictates color and icon show
// message for the text to be show
// Optional removeError function that if given will be triggered though an "X" button on right side of alert
const AlertContainer = ({
    type,
    message,
    theme,
    removeError, // optional prop
    noBorderRadius, // optional prop
    hideBorder, // optional prop
}) => {
    let bgColor;
    let IconToRender;
    if (type === ALERT_TYPES.ERROR) {
        bgColor = theme.colors.semantic.error;
        IconToRender = <Icon className="fas fa-ban"></Icon>;
    } else if (type === ALERT_TYPES.SUCCESS) {
        bgColor = theme.colors.semantic.success;
        IconToRender = <Icon className="fas fa-check-circle"></Icon>;
    } else if (type === ALERT_TYPES.WARNING) {
        bgColor = theme.colors.semantic.warning;
        IconToRender = <Icon className="fas fa-exclamation-triangle"></Icon>;
    } else if (type === ALERT_TYPES.INFO) {
        bgColor = theme.colors.semantic.info;
        IconToRender = <Icon className="fas fa-info-circle"></Icon>;
    }
    return (
        <Banner
            bgColor={bgColor}
            borderRadius={noBorderRadius ? "0px" : "10px"}
            hideBorder={hideBorder ? hideBorder : false}
        >
            {IconToRender}
            {message}
            {removeError && (
                <CloseBtnWrapper>
                    <CloseButton
                        handleClick={removeError}
                        circleDiameter={"1.3rem"}
                        fontSize={"1.3rem"}
                        bgColor={bgColor}
                        iconColor={darken(0.6, bgColor)}
                    >
                        <i className="fas fa-times"></i>
                    </CloseButton>
                </CloseBtnWrapper>
            )}
        </Banner>
    );
};

export default AlertContainer;

const CloseBtnWrapper = styled.button`
    position: absolute;
    right: 0.5rem;
`;

const Icon = styled.i`
    font-size: 140%;
    padding: 1rem 0.5rem 1rem 0;
`;

const Banner = styled.div`
    position: relative;
    background-color: ${(props) => props.bgColor};
    color: ${(props) => darken(0.6, props.bgColor)};
    border: 1px solid
        ${({ bgColor, hideBorder }) =>
            hideBorder ? bgColor : darken(0.7, bgColor)};
    padding: 0rem 3rem 0rem 1.5rem;
    border-radius: ${(props) => props.borderRadius};
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;
