import styled from "styled-components";

import Avatar from "../../library/common/Avatar";
import AvatarLineUp from "../../library/common/AvatarLineUp";
import Icon from "../../library/common/Icon";
import ImgTag from "../../library/common/ImgTagWithLoading";
import { handleSiteClick, handleUserClick } from "../../library/utilities";

const avatarSize = "2.5rem";

const SiteInfoBarView = ({
    theme,
    infoBarContent,
    history,
    critScSize,
    toggleView,
    expandedWindow,
}) => {
    console.log("infoBarContent");
    console.log(infoBarContent);

    const {
        _id,
        name,
        crew = [],
        coverPhoto,
        isActive,
        createdAt,
        adminUsers = [],
    } = infoBarContent;


    const handleClick = (e) => { handleSiteClick(e, history, _id); };

    return (
        <Wrapper>
            <Header critScSize={critScSize}>
                <div>
                    <NameRow critScSize={critScSize}>
                        <SiteName onClick={handleClick}>
                            {name}
                        </SiteName>
                        <ArrowBtn
                            onClick={toggleView}
                            critScSize={critScSize}
                        >
                            <Icon name={expandedWindow ? "chevron-circle-down" : "chevron-circle-up"} />
                        </ArrowBtn>
                    </NameRow>
                    {crew.length > 0 && (
                        <CrewSection>
                            <AvatarLineUp
                                theme={theme}
                                avatarArray={crew}
                                maxAvatars={5}
                                avatarSize={avatarSize}
                                avatarBorderColor={`${theme.colors.offPrimary}10`}
                            />
                        </CrewSection>
                    )}
                </div>
                <ImgWrapperOuter critScSize={critScSize}>
                    <ImgWrapperInner>
                        {coverPhoto ? (
                            <ImgTag
                                src={coverPhoto}
                                objectFit={"cover"}
                                verticalAlign={"middle"}
                            />
                        ) : <FirstLetterWrapper>{name[0]}</FirstLetterWrapper>}
                    </ImgWrapperInner>
                </ImgWrapperOuter>
            </Header>
            <Status active={isActive}>
                {isActive ? "Active" : "Inactive"}
            </Status>
            <Created>
                {`Est. ${new Date(createdAt).toLocaleString("en-NZ", {
                    // MM YY e.g. May 2021
                    year: "numeric",
                    month: "long",
                })}`}
            </Created>
            <AdminUserSection>
                <SectionTitle>Admins</SectionTitle>
                {adminUsers.map((el, i) =>
                    <AdminUserRow key={i}>
                        <Avatar
                            path={el.img}
                            user={true}
                            size={avatarSize}
                            handleClick={(e) => handleUserClick(e, history, el._id)}
                            borderColor={`${theme.colors.offPrimary}`}
                        />
                        <UserName>{el.username}</UserName>
                    </AdminUserRow>
                )}
            </AdminUserSection>
        </Wrapper>
    );
};

export default SiteInfoBarView;

const Wrapper = styled.div`
    padding: 0.8rem 0.8rem;
`;

const Header = styled.div`
    display: flex;
    flex-direction: column-reverse;
    /* background-color: ${(props) => props.theme.colors.secondary}; */
    @media (max-width: ${({ critScSize }) => critScSize}) {
        flex-direction: column;
    }
`;

const ImgWrapperOuter = styled.div`
    margin: 0 0 0.5rem 0;
    @media (max-width: ${({ critScSize }) => critScSize}) {
        margin: 1rem 0 0 0;
    }
    position: relative;
    width: 100%;
    padding-top: 100%;
`;

const ImgWrapperInner = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 0.5rem;
`;

const FirstLetterWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    width: 100%;
    height: 100%;
    font-size: 7rem;
    color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.offPrimary};
`;

const arrowBtnSize = "2.6rem";
const arrowBtnSpaceRight = "0.7rem";

const NameRow = styled.div`
    display: flex;
    position: relative;
    @media (max-width: ${({ critScSize }) => critScSize}) {
        padding-right: calc(
            ${arrowBtnSize} + 2 * ${arrowBtnSpaceRight}
        ); /* account for arrow btn plus spacing left and right of it */
    }
`;

const SiteName = styled.h3`
    margin: 0;
    cursor: pointer;
    /* cut off words without white space that exceed width, wrap white space*/
    text-overflow: ellipsis;
    overflow: hidden;
`;

const ArrowBtn = styled.i`
    position: absolute;
    right: 0;
    z-index: 1;
    display: none;
    @media (max-width: ${({ critScSize }) => critScSize}) {
        display: block;
        flex: 0;
        font-size: ${arrowBtnSize};
        padding: 0 0.7rem 0 0;
        color: ${(props) => props.theme.colors.offSecondary};
    }
`;

const AdminUserRow = styled.div`
    display: flex;
    align-items: center;
    padding: 0.1rem 0 0 0.1rem;
`;

const AdminUserSection = styled.div`
    padding: 0.5rem 0 0 0;
`;

const UserName = styled.h6`
    margin: 0;
    padding-left: 0.5rem;
    /* Cut off any words longer than width, wrap the rest */
    text-overflow: ellipsis;
    overflow: hidden;
`;

const SectionTitle = styled.p`
    margin: 0;
`;

const Status = styled.div`
    padding-top: 0.5rem;
    font-weight: 500;
    color: ${({ active, theme }) =>
        active
            ? theme.colors.text.semantic.success
            : theme.colors.text.semantic.warning};
`;

const Created = styled.div`
    padding-top: 0.5rem;
`;

const CrewSection = styled.div`
    padding-top: 0.5rem;
`;
