import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { sendApiRequest } from "../../../../services/api";

import ImageEditView from "./ProfileImageEdit-view";
import { FILE_UPLOAD_CONFIG } from "../../constants";
import { addImagesToState, packStateInFormDataObj } from "../../../utilities";
import PopupLoadingScreen from "../../../common/PopupLoadingScreen";

// profile image edit screen, capable of editing any pictures from profiles (user, company, site).
// Displays current image with ability to delete or choose a new file. If there is no image in state then the default image is rendered. Default image is an env variable "defaultImg" passed in as props
// TODO get rid of this reliance on env variables (store efault images in src folder somewhere?)
const ImageEditPopUpContainer = ({
    popupTitle,
    type,
    imgDetails,
    addError,
    refreshParentState,
    setPopUpToDisplay,
    addImgPath,
    deleteImgPath,
    defaultImg,
}) => {
    // imageState stores user image until submit
    // images and files in this component can only ever contain one image and file in there array but must be sent in an array for server
    const [imageState, setImageState] = useState({
        images: [],
        files: [],
    });

    const [loading, setLoading] = useState(false);
    const [imgLoadingToDom, setImgLoadingToDom] = useState(false);

    // State used in front end validation
    const [validationFailedState, setValidationFailedState] = useState({});

    const { getAccessTokenSilently } = useAuth0();

    // If an image comes in from parent then add image to state
    useEffect(() => {
        setLoading(true);
        if (imgDetails) {
            setImageState((f) => {
                return { ...f, images: [imgDetails] };
            });
        }
        setLoading(false);
    }, [imgDetails, setLoading]);

    // On file change - add images to state, uses utility function
    const onFileChange = async (e) => {
        setImgLoadingToDom(true);
        try {
            await addImagesToState(
                e,
                setImageState,
                imageState,
                "images",
                "single",
                setValidationFailedState
            );
        } catch (error) {
            addError("Error converting image to jpeg");
        }
        setImgLoadingToDom(false);
    };

    // imageState is cleared when user hits delete button. If no image in imageState then view component will render the default for user
    const onImageDelete = () => {
        // setImageState(null);
        setImageState({ files: [], images: [] });
    };

    // Delete image from server
    const deleteImgFromServer = async () => {
        try {
            await sendApiRequest(getAccessTokenSilently, {
                method: "put",
                url: deleteImgPath,
            });
        } catch (error) {
            addError(`Error removing old image: ${error.message}`);
            throw new Error(`Error`); // throw error in parent
        }
    };

    // Uploads imageState to server. Image sent in a FormData with required file upload header
    const addImgToServer = async () => {
        try {
            console.log('imageState ---- ');
            console.log(imageState);
            const formDataForSubmitting = packStateInFormDataObj(imageState, "images");
            console.log("formDataForSubmitting: ");
            console.log(formDataForSubmitting);
            console.log(typeof formDataForSubmitting);
            // Send request
            await sendApiRequest(getAccessTokenSilently, {
                method: "put",
                url: addImgPath,
                data: formDataForSubmitting,
                ...FILE_UPLOAD_CONFIG,
            });
        } catch (error) {
            addError(`Error adding new image: ${error.message}`);
            throw new Error(`Error`); // throw error in parent
        }
    };

    // sync image state with db/server side. I.e. checks what has happened to imageState and updates Db accordingly.
    const syncServerWithState = async () => {
        try {
            console.log("imageState.images");
            console.log(imageState.images);
            if (imageState.images[0] && !imgDetails) {
                // A new image has been added but there was no old image in DB
                await addImgToServer();
            } else if (
                imageState.images[0] &&
                imgDetails &&
                imageState.images[0].path !== imgDetails.path
            ) {
                // New image added && there is an old image in DB && their different files
                await deleteImgFromServer();
                await addImgToServer();
            } else if (!imageState.images[0] && imgDetails) {
                // Old image deleted && no new image has been added
                await deleteImgFromServer();
            } else {
                // Nothing happened (still no image or same image as before)
                return;
            }
        } catch (error) {
            setLoading(false); // remove loading screen
            throw new Error("Error");
        }
    };

    // Updates image in DB before passing to handleNext in parent.
    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setLoading(true); // render loading screen while requests set
            await syncServerWithState();
            refreshParentState(); // Triggers an api request in parent that refreshed images
            setPopUpToDisplay(""); // Hides popup in parent
        } catch (error) {
            return; // Error should have been added already just exits this function (throw an error here will cause unhandled rejection)
        }
    };

    // Renders JSX
    if (loading) {
        return <PopupLoadingScreen />;
    } else {
        return (
            <ImageEditView
                popupTitle={popupTitle}
                type={type}
                validationFailedState={validationFailedState}
                imageState={imageState.images[0]}
                setPopUpToDisplay={setPopUpToDisplay}
                onFileChange={onFileChange}
                onImageDelete={onImageDelete}
                handleSubmit={handleSubmit}
                defaultImg={defaultImg}
                imgLoadingToDom={imgLoadingToDom}
            />
        );
    }
};

export default ImageEditPopUpContainer;
