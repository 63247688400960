import { useContext, useEffect, useRef, useState } from "react";
import { useApi } from "../../services/api";

import NotificationsView from "./Notifications-view";
import { CONNECTION_ACTION } from "../../library/common/constants";
import SocketContext from "../../services/socket";

// After this amount of time with list open notifications will be seen and socket emit sent
const TIME_TO_SEE_NOTIFICATIONS = 700;

const Notifications = ({
    history,
    currentUser,
    listOnly = false,
}) => {

    const useIO = useContext(SocketContext);
    const { notifications = [], notificationsLoading, updateNotificationsSeen } = useIO();

    const { refresh: updateConx } = useApi();

    const [ntfError, setNtfError] = useState("");
    const updateNotificationsSeenOnUnmount = useRef(false);

    // const fullScreenView = history.location.pathname === "/notifications";

    // Counts down timer
    useEffect(() => {
        if (notificationsLoading) return;

        // when timer runs out, set component to update notifications if there were unread notifications
        const intervalId = setTimeout(() => {
            if (notifications.some((el) => !el.hasBeenSeen)) {
                updateNotificationsSeenOnUnmount.current = true;
            }
        }, TIME_TO_SEE_NOTIFICATIONS);

        // stop and clear timer when component unmounts
        return () => {
            clearTimeout(intervalId);
            if (updateNotificationsSeenOnUnmount.current) { updateNotificationsSeen(); }
        };

    }, [
        notifications,
        notificationsLoading,
        updateNotificationsSeen,
    ]);

    const formattedNotifications = notifications.map(notification => {
        const { friendRequestUserId, friendRequestAccepted } = notification;
        if (!friendRequestUserId || friendRequestAccepted) return notification;

        const handleConxAction = (actionObj) => {
            const { route } = actionObj;
            updateConx({
                method: "put",
                url: `/api/users/${currentUser.user.id}/${route}?id=${friendRequestUserId}`,
                errorCallback: (err) => { setNtfError(err.message); }
            });
        };

        const friendRequestActions = {
            acceptFriendRequest: () => handleConxAction(CONNECTION_ACTION.ACCEPT),
            removeFriendRequest: () => handleConxAction(CONNECTION_ACTION.REMOVE),
        };

        return { ...notification, friendRequestActions };
    });

    const handleClick = (path) => { history.push(path); };

    const viewProps = {
        handleClick,
        listOnly,
        notifications: formattedNotifications,
        notificationsLoading,
        ntfError,
        setNtfError,
    };

    return <NotificationsView {...viewProps} />;

};

export default Notifications;
