import styled, { withTheme } from "styled-components";

import XButton from "../../../library/common/ButtonXCircle";
import Icon from "../../../library/common/Icon";
import InputGroup from "../../../library/common/InputGroup";
import FooterBar from "../../../library/common/PopupFooterBar";
import HeaderBar from "../../../library/common/PopupHeaderBar";

import { SITE_SEARCH, INPUT_TYPES } from "../../../library/common/constants";

import { ContentBox } from "../../../resources/styles";

const AddSiteScreenView = ({
    theme,
    siteCopyName,
    addSite,
    doneAddingSite,
    handleClose,
    removeSite,
}) => {

    return (
        <Wrapper>
            <HeaderBar
                popupTitle={"Add Site"}
                customExitHandling={handleClose}
            />
            <ContentBox>
                <ContentWrapper>
                    <InputGroup
                        value={siteCopyName}
                        title={`Name`}
                        type={INPUT_TYPES.SEARCH_TEXT}
                        placeholder={`Site Name`}
                        required={false}
                        errorMessage={undefined}
                        handleChange={addSite}
                        searchBoxProps={{ searchApiRequest: SITE_SEARCH }}
                    />
                    {siteCopyName
                        && <SectionTitle>Selected</SectionTitle>}
                    {siteCopyName
                        && (
                            <TaggedSiteRow>
                                <TaggedSiteWrapper>
                                    <SiteName>
                                        <Icon name="map-pin" margin={0} paddingRight="0.7rem" />
                                        {siteCopyName}
                                    </SiteName>
                                </TaggedSiteWrapper>
                                <XButton
                                    handleClick={removeSite}
                                    circleDiameter={"1.8rem"}
                                    fontSize={"1rem"}
                                    bgColor={theme.colors.secondary}
                                    iconColor={theme.colors.primary}
                                />
                            </TaggedSiteRow>
                        )}
                </ContentWrapper>
            </ContentBox>
            <FooterBar
                handleDone={doneAddingSite}
                doneLabel={"Done"}
            />
        </Wrapper>
    );
};

export default withTheme(AddSiteScreenView);

const Wrapper = styled.div`
    width: min(600px, 95vw);
`;

const ContentWrapper = styled.div`
    padding: 1.3rem 1.5rem 0.2rem 1.5rem;
    min-height: 350px;
    position: relative; /* Must position relative for GreyBox to work properly */
    @media (max-width: ${({ theme }) => theme.sizes.mdViewWidth}) {
        padding: 0.5rem 0.7rem;
    }
`;

const SectionTitle = styled.h6`
    margin-top: 3rem;
    padding-bottom: 0.2rem;
    border-bottom: 1px solid ${(props) => props.theme.colors.offPrimary};
    /* color: ${(props) => props.theme.colors.offSecondary}; */
`;

const TaggedSiteRow = styled.div`
    /* margin: 1.5rem 0 0 0; */
    /* padding: 1rem 0 0 0; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const TaggedSiteWrapper = styled.div`
    /* flex: 1 1 250px; */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.colors.semantic.success};
    padding: 0.7rem 1.3rem;
    border-radius: 1rem;
    margin-right: 1rem;
`;

const SiteName = styled.h4`
    /* padding-left: 1rem; */
    margin: 0;
`;
