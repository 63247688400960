import styled from "styled-components";

import { INPUT_TYPES } from "../../library/common/constants";
import InputGroup from "../../library/common/InputGroup";
import {
    InputRow,
    HalfWidthWrapper,
} from "../../resources/styles";

// Renders input fields for header edit popup form
const HeaderEditFormView = ({
    formState,
    handleChange,
    validationFailedState,
}) => {
    const { givenName, familyName, workPhone } = formState;
    return (
        <Wrapper>
            <InputRow>
                <HalfWidthWrapper>
                    <InputGroup
                        type={INPUT_TYPES.TEXT}
                        handleChange={handleChange}
                        errorMessage={validationFailedState.givenName}
                        value={givenName}
                        name={"givenName"}
                        title={"Given Name"}
                        required={true}
                    />
                    <InputGroup
                        type={INPUT_TYPES.TEXT}
                        handleChange={handleChange}
                        errorMessage={validationFailedState.workPhone}
                        value={workPhone || ""}
                        name={"workPhone"}
                        title={"Work Phone"}
                    />
                </HalfWidthWrapper>
                <HalfWidthWrapper spaceBetween={"2rem"}>
                    <InputGroup
                        type={INPUT_TYPES.TEXT}
                        handleChange={handleChange}
                        errorMessage={validationFailedState.familyName}
                        value={familyName}
                        name={"familyName"}
                        title={"Family Name"}
                        required={true}
                    />
                </HalfWidthWrapper>
            </InputRow>
        </Wrapper>
    );
};

export default HeaderEditFormView;

const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
    min-width: 700px;
    @media (max-width: ${({ theme }) => theme.sizes.mdViewWidth}) {
        /* Must remove min width or content will overflow on smaller screens */
        min-width: 0;
    }
`;

// return (
//    <div>
//        <div className="input-group my-3">
//            <div className="input-group-prepend">
//                <span className="input-group-text" id="">
//                    Name:
//                </span>
//            </div>
//            <input
//                id="username"
//                name="username"
//                onChange={handleChange}
//                value={username}
//                type="text"
//                className={`form-control ${
//                    validationFailedState.username
//                        ? "border border-danger"
//                        : ""
//                }`}
//            />
//            {validationFailedState.username && (
//                <div className="invalid-feedback d-block">
//                    {validationFailedState.username}
//                </div>
//            )}
//        </div>

//        <div className="input-group my-3">
//            <div className="input-group-prepend">
//                <span className="input-group-text" id="">
//                    Work Phone:
//                </span>
//            </div>
//            <input
//                className={`form-control ${
//                    validationFailedState.workPhone
//                        ? "border border-danger"
//                        : ""
//                }`}
//                id="workPhone"
//                name="workPhone"
//                onChange={handleChange}
//                value={workPhone}
//                type="text"
//            />
//            <small
//                className="input-group-append text-muted"
//                style={{ width: "100%" }}
//            >
//                Only connections can view your work number
//            </small>
//            {validationFailedState.workPhone && (
//                <div className="invalid-feedback d-block">
//                    {validationFailedState.workPhone}
//                </div>
//            )}
//        </div>
//        <div className="input-group my-3">
//            <div className="input-group-prepend">
//                <span className="input-group-text" id="">
//                    Work Email:
//                </span>
//            </div>
//            <input
//                className={`form-control ${
//                    validationFailedState.workEmail
//                        ? "border border-danger"
//                        : ""
//                }`}
//                id="workEmail"
//                name="workEmail"
//                onChange={handleChange}
//                value={workEmail}
//                type="text"
//            />
//            <small
//                className="input-group-append text-muted"
//                style={{ width: "100%" }}
//            >
//                Only connections can view your work email
//            </small>
//            {validationFailedState.workEmail && (
//                <div className="invalid-feedback d-block">
//                    {validationFailedState.workEmail}
//                </div>
//            )}
//        </div>
//    </div>
// );
