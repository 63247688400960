import styled, { withTheme } from "styled-components";

import ChatDetailsHeader from "./ChatDetailsHeader-view";
import ChatDetailsThread from "./ChatDetailsThread-view";

import Alert from "../../library/common/Alert";
import Button from "../../library/common/Button";
import FileUploadCircle from "../../library/common/ButtonFileUploadCircle";
import ButtonXCircle from "../../library/common/ButtonXCircle";
import InputGroup from "../../library/common/InputGroup";
import LoadingDots from "../../library/common/LoadingDots";
import ScrollToOnMount from "../../library/common/ScrollToOnMount";

import { INPUT_TYPES, ALERT_TYPES } from "../../library/common/constants";
import Icon from "../../library/common/Icon";

const ChatDetailsView = ({
    theme,
    conversationsLoading,
    setPopUpToDisplay,
    chatDetails,
    creatingChat,
    newMessage,
    newMessageRef,
    showDetailsLoadingDots,
    validationFailedState,
    handleGoBack,
    handleSendMessage,
    handleNewMsgTextChange,
    onFileChange,
    onImageDelete,
}) => {

    const headerProps = {
        theme,
        chatDetails,
        conversationsLoading,
        handleGoBack,
        setPopUpToDisplay,
    };

    const threadProps = {
        theme,
        chatDetails,
        setPopUpToDisplay,
    };

    // Update newMessage state and resize TextArea input
    // i.e. grow or shrink height to match amount of text
    const handleMessageChange = (e) => {
        // Shrink textArea if text is deleted
        e.target.style.height = "inherit";
        // grow height of text area based off scroll height plus 2px to account for padding
        e.target.style.height = `${e.target.scrollHeight + 2}px`;
        handleNewMsgTextChange(e);
    };

    const { images = [] } = newMessage;
    const newMessageImages = images.map((el, i) => (
        <PreviewPicWrapper key={i}>
            <ImgButton>
                <ButtonXCircle
                    handleClick={() => onImageDelete(i)}
                    circleDiameter={"2rem"}
                    fontSize={"1.2rem"}
                    iconColor={theme.colors.secondary}
                    bgColor={theme.colors.offPrimary}
                />
            </ImgButton>
            <PreviewMessagePic
                src={el.path}
                alt={"Error"}
            ></PreviewMessagePic>
        </PreviewPicWrapper>
    ));
    const newMessageImageRow = images.length > 0 ?
        <PictureRow>{newMessageImages}</PictureRow> : null;

    const { uploadError = null } = validationFailedState;
    const uploadErrorRow = uploadError ?
        <UploadErrorRow>
            <Alert
                message={uploadError}
                type={ALERT_TYPES.ERROR}
                hideBorder={true}
            />
        </UploadErrorRow> :
        null;

    const messageInputSection = (
        <NewMessageSection ref={newMessageRef}>
            {newMessageImageRow}
            {uploadErrorRow}
            <MessageInputRow>
                <UploadButtonWrapper>
                    <FileUploadCircle
                        handleClick={onFileChange}
                        // icon={<i className="fas fa-image"></i>}
                        icon={<Icon name="image" />}
                        multipleFiles={true}
                        circleDiameter="2.5rem"
                        iconColor={theme.colors.secondary}
                        bgColor={theme.colors.primary}
                        shadow={true}
                        fontSize="1.5rem"
                    />
                </UploadButtonWrapper>
                <InputGroup
                    type={INPUT_TYPES.TEXT_AREA}
                    rows={"1"}
                    noAdjustTextAreaWithScreenSize={true}
                    handleChange={handleMessageChange}
                    value={newMessage.message}
                    name="comment"
                    placeholder={"Message..."}
                    borderRadius={"0.8rem"}
                    bgColor={theme.colors.body}
                />
                <SendButtonWrapper>
                    <Button
                        handleClick={handleSendMessage}
                        textSize={"1rem"}
                        // primaryBtnColors={true}
                        bgColor={theme.colors.secondary}
                        textColor={theme.colors.primary}
                        maxHeight={"2.45rem"}
                        // icon={<i className="fas fa-paper-plane"></i>}
                        icon={<Icon name="paper-plane" />}
                        borderRadius={"1.1rem"}
                        buttonLoading={creatingChat}
                    />
                </SendButtonWrapper>
            </MessageInputRow>
        </NewMessageSection>
    );

    const isLoading = conversationsLoading || showDetailsLoadingDots || creatingChat;
    const loadingDots = <ThreadLoadingWrapper><LoadingDots size="20px" /></ThreadLoadingWrapper>;

    const isSending = chatDetails.messages && chatDetails.messages.some((el) => !!el.messageSending);
    const sendingDots = <DotsWrapper><LoadingDots size="5px" /></DotsWrapper>;

    const chatDetailsThread = <ChatDetailsThread {...threadProps} />;

    const chatMessageThread = isLoading ? loadingDots :
        isSending ? sendingDots : chatDetailsThread;

    return (
        <Wrapper>
            <ChatDetailsHeader {...headerProps} />
            <ChatMessageThread>
                {chatMessageThread}
                <ScrollToOnMount scrollToBottom={true} />
            </ChatMessageThread>
            {messageInputSection}
        </Wrapper>
    );
};

export default withTheme(ChatDetailsView);

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    /* padding: 1rem; */
`;

const ChatMessageThread = styled.div`
    padding: 0.5rem 0.7rem;
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
`;

const DotsWrapper = styled.div`
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    flex-direction: row-reverse; /* message can only ever be sent by current user */
`;

const ThreadLoadingWrapper = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const NewMessageSection = styled.div`
    padding: 0.5rem;
    border-top: 1px solid ${(props) => props.theme.colors.offPrimary};
`;

const UploadButtonWrapper = styled.div`
    padding-right: 0.5rem;
`;

const PictureRow = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    padding: 0.2rem;
    flex-wrap: wrap;
`;

const PreviewPicWrapper = styled.div`
    position: relative;
    margin: 0.3rem; /* margin instead of padding so it doesn't effect ImgButton positioning */
`;

// delete button wrapper
const ImgButton = styled.div`
    position: absolute;
    z-index: 1;
    top: 0.3rem;
    left: 0.3rem;
    display: flex;
`;

const PreviewMessagePic = styled.img`
    width: 10rem; /* 1 rem account for padding */
    height: 10rem;
    border-radius: 0.5rem;
    object-fit: cover;
`;

const MessageInputRow = styled.div`
    display: flex;
    align-items: flex-end;
`;

const SendButtonWrapper = styled.div`
    padding: 0 0 0 0.7rem;
`;

const UploadErrorRow = styled.div`
    padding: 0.5rem;
`;
