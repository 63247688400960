import styled from "styled-components";
import { AuthorAvatarSize, PostHeader, HeaderTextCol } from "./PostHeader-view";
import LoadingShimmer from "../../library/common/LoadingShimmer";

// Returns post content for a loading post, image is optional
const PostLoading = ({ image, theme }) => {
    return (
        <div>
            <PostHeader>
                <AvatarLoading />
                <HeaderTextCol>
                    <NameLoading />
                    <PostDateLoading />
                </HeaderTextCol>
            </PostHeader>
            {image && (
                <PostImgLoading>
                    <FillImgBox>
                        <LoadingShimmer />
                        {/* <LoadingDots
                            size={"12px"}
                            baseColor={theme.colors.offPrimary}
                            focusColor={darken(0.05, theme.colors.offPrimary)}
                        /> */}
                    </FillImgBox>
                </PostImgLoading>
            )}
            <PostTextLoadingWrapper>
                <TextLoadingLine />
                <TextLoadingLine />
                <TextLoadingLine />
            </PostTextLoadingWrapper>
        </div>
    );
};

export default PostLoading;

const AvatarLoading = styled.div`
    background-color: ${(props) => props.theme.colors.offPrimary};
    border-radius: 50%;
    height: ${AuthorAvatarSize};
    width: ${AuthorAvatarSize};
`;

const NameLoading = styled.div`
    width: 5rem;
    height: 0.8rem;
    background-color: ${(props) => props.theme.colors.offPrimary};
    border-radius: 15px;
`;

const PostDateLoading = styled.div`
    margin-top: 0.2rem;
    width: 3rem;
    height: 0.6rem;
    background-color: ${(props) => props.theme.colors.offPrimary};
    border-radius: 15px;
`;

const PostImgLoading = styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 2rem;
    padding-top: ${(props) =>
        props.theme.sizes.aspectRatios.feedImages.landscape}%;
    background-color: ${(props) => props.theme.colors.offPrimary};
`;

const FillImgBox = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const PostTextLoadingWrapper = styled.div`
    padding: 0rem 1rem 1rem 1rem;
`;

const TextLoadingLine = styled.div`
    margin: 1rem 0;
    height: 0.5rem;
    width: 100%;
    background-color: ${(props) => props.theme.colors.offPrimary};
    border-radius: 15px;
`;
