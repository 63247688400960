import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTheme } from "styled-components";

import NewsFeed from "./NewsFeed-container";
import { addError, removeError } from "../../main/store/actions/errors";
import { updateFeed } from "../../main/store/actions/newsFeed";

const mapState = (state) => {
    return {
        currentUser: state.currentUser,
        errors: state.errors,
        newsFeed: state.newsFeed,
    };
};

const mapDispatch = {
    addError,
    removeError,
    updateFeed,
};

export default withRouter(connect(mapState, mapDispatch)(withTheme(NewsFeed)));
