import styled, { withTheme } from "styled-components";
import { darken } from "polished";

import {
    LeftFooterBtnWrapper,
    RightFooterBtnWrapper,
} from "./PostCreateFooterBar-view";
import ValidationErrorAlert from "./ValidationErrorAlert-view";

import PostHeader from "../../Post/PostHeader-view";
import PostBody from "../../Post/PostBody-view";

import Button from "../../../library/common/Button";
import ButtonCircleIcon from "../../../library/common/ButtonCircleIcon";
import FileUploadCircle from "../../../library/common/ButtonFileUploadCircle";
import Icon from "../../../library/common/Icon";
import HeaderBar from "../../../library/common/PopupHeaderBar";

import {
    popUpHeaderHeight,
    popUpMarginBelowNavbar,
} from "../../../library/hoc/WithPopUp/WithPopUp-container";

import { FooterBar, ContentBox } from "../../../resources/styles";

const PostPreviewView = ({
    theme,
    history,
    msgPopoverVisible,
    post,
    setPopUpToDisplay,
    validationAlertProps,
    clearMsgPopover,
    handleAddSite,
    handleAddTags,
    handleBack,
    handleClose,
    handleImageDelete,
    handlePostTextChange,
    handleSubmit,
    onImageFileChange,
}) => {

    const postHeaderProps = {
        theme,
        history,
        post,
        setPopUpToDisplay,
        createPostView: true,
    };

    const postBodyProps = {
        theme,
        history,
        handleImageDelete,
        handlePostTextChange,
        post,
        setPopUpToDisplay,
        createPostView: true,
        userIsAdmin: true,
    };

    return (
        <Wrapper>
            <HeaderBar
                setPopUpToDisplay={setPopUpToDisplay}
                popupTitle={"Create Post"}
                customExitHandling={handleClose}
            />
            <CustomContentBox>
                <PostHeader {...postHeaderProps} />
                <PostBody {...postBodyProps} />
            </CustomContentBox>
            <ValidationErrorAlert {...validationAlertProps} doubleFooter={true} />
            <DoubleHeightFooterBar>
                <FirstFooterRow>
                    <AddToPostBox>
                        <AddMessage>
                            <div>
                                {/* <i className="fas fa-plus-circle"></i> */}
                                <Icon name="plus-circle" />
                            </div>
                            <AddText>Add</AddText>
                        </AddMessage>
                        <AddBtnsWrapper>
                            <AddButtonWrapper>
                                <ButtonCircleIcon
                                    handleClick={handleAddTags}
                                    circleDiameter={"2.6rem"}
                                    iconColor={theme.colors.secondary}
                                    bgColor={darken(0.05, theme.colors.primary)}
                                    shadow={true}
                                    fontSize={"1.5rem"}
                                >
                                    <Icon name="user-plus" padding="0 0 0.1rem 0.15rem" />
                                </ButtonCircleIcon>
                                {msgPopoverVisible && (
                                    <MessageBox                                    >
                                        <TriangleDown />
                                        <ExitButton
                                            onClick={clearMsgPopover}
                                        >
                                            <Icon name="times" />
                                        </ExitButton>
                                        Tag your crew
                                    </MessageBox>
                                )}
                            </AddButtonWrapper>
                            <AddButtonWrapper>
                                <ButtonCircleIcon
                                    handleClick={handleAddSite}
                                    circleDiameter={"2.6rem"}
                                    iconColor={theme.colors.secondary}
                                    bgColor={darken(0.05, theme.colors.primary)}
                                    shadow={true}
                                    fontSize={"1.5rem"}
                                >
                                    <Icon name="map-pin" />
                                </ButtonCircleIcon>
                            </AddButtonWrapper>
                            <UploadButtonWrapper>
                                <FileUploadCircle
                                    handleClick={onImageFileChange}
                                    icon={<Icon name="image" />}
                                    multipleFiles={true}
                                    circleDiameter={"2.6rem"}
                                    iconColor={theme.colors.secondary}
                                    bgColor={darken(0.05, theme.colors.primary)}
                                    shadow={true}
                                    fontSize={"1.6rem"}
                                />
                            </UploadButtonWrapper>
                        </AddBtnsWrapper>
                    </AddToPostBox>
                </FirstFooterRow>
                <SecondFooterRow>
                    <LeftFooterBtnWrapper>
                        <Button
                            handleClick={handleBack}
                            textSize={"1rem"}
                            secondaryBtnColors={true}
                        >
                            Back
                        </Button>
                    </LeftFooterBtnWrapper>
                    <RightFooterBtnWrapper>
                        <Button
                            handleClick={handleSubmit}
                            textSize={"1rem"}
                            primaryBtnColors={true}
                        >
                            Post
                        </Button>
                    </RightFooterBtnWrapper>
                </SecondFooterRow>
            </DoubleHeightFooterBar>
        </Wrapper>
    );
};

export default withTheme(PostPreviewView);

export const Wrapper = styled.div`
    width: min(600px, 95vw);
    /* min-height: 350px; */
`;

const CustomContentBox = styled(ContentBox)`
    margin-bottom: calc(${popUpHeaderHeight}*2.1); /* accounts for footer */
    /* Must change max height to account for larger footerBar */
    max-height: ${({ theme }) => `calc(100vh - 3.1*${popUpHeaderHeight} -
            ${theme.sizes.navHeight} -
            2*${popUpMarginBelowNavbar})`};
    @media (max-width: ${({ theme }) => theme.sizes.smViewWidth}) {
        max-height: ${({ theme }) => `calc(80vh - 3.1*${popUpHeaderHeight} -
            ${theme.sizes.navHeight} -
            2*${popUpMarginBelowNavbar})`};
    }
`;

const DoubleHeightFooterBar = styled(FooterBar)`
    /* Note must change multiplier in CustomContentBox style AND in AlertWrapper in ValidationErrorAlert-view*/
    height: calc(${popUpHeaderHeight}*2.1);
    flex-direction: column;
    align-items: normal; /* undo align items from FooterBar*/
`;

const FirstFooterRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.5rem 1rem 0.5rem 1rem;
`;

const AddToPostBox = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    border: 1px solid ${(props) => props.theme.colors.offPrimary};
    box-shadow: 2px 2px 5px rgba(150, 150, 150, 0.2);
    padding: 0.4rem 1rem;
    position: relative;
`;

const AddMessage = styled.div`
    flex: 1;
    display: flex;
    font-size: 1.2rem;
    color: ${(props) => props.theme.colors.offSecondary};
`;

const AddText = styled.div`
    padding-left: 0.3rem;
    font-weight: bold;
`;

const SecondFooterRow = styled(FirstFooterRow)`
    justify-content: flex-end;
    padding: 0.3rem 1rem;
`;

const AddBtnsWrapper = styled.div`
    flex: 0;
    display: flex;
    align-items: center;
`;

const AddButtonWrapper = styled.div`
    margin-right: 1rem;
    position: relative;
`;

const UploadButtonWrapper = styled(AddButtonWrapper)`
    margin-right: 0rem;
`;

// const AddTagIcon = styled.i`
//     padding: 0 0 0.1rem 0.15rem;
// `;

const bubbleWidth = "165px";

const MessageBox = styled.div`
    position: absolute;
    z-index: 1;
    right: calc(((-${bubbleWidth}) / 2)); /* move bubble over half its with */
    width: ${bubbleWidth};
    background-color: ${(props) => props.theme.colors.secondary};
    color: ${(props) => props.theme.colors.primary};
    border-radius: 0.5rem;
    padding: 1.2rem 2rem 1.2rem 1.2rem; /* extra right to give space to X btn */
    bottom: 130%;
    font-size: 1rem;
    font-weight: 600;
`;

const triangleWidth = "10px";
const TriangleDown = styled.div`
    width: 0;
    height: 0;
    border-left: ${triangleWidth} solid transparent;
    border-right: ${triangleWidth} solid transparent;
    border-top: ${triangleWidth} solid
        ${(props) => props.theme.colors.secondary};
    position: absolute;
    z-index: 0;
    right: calc(50% + (${triangleWidth}));
    /* move arrow half bubble width from right edge plus arrow width (the arrow width doesn't really work if you keep increasing size) */
    bottom: -${triangleWidth};
`;

// X button to exit popover
const ExitButton = styled.div`
    position: absolute;
    z-index: 1;
    font-weight: 200;
    font-size: 1.4rem;
    right: 0.6rem;
    top: 0rem;
    cursor: pointer;
`;
