import React, { useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { lighten } from "polished";

import { titleCase } from "../../library/utilities";
const criticalScreenSize = "992px";

const InvitedUserContainer = () => {
    const { first_name } = useParams();

    return (
        <Wrapper>
            <AppIcon src={"/BoomsUpFavcon.svg"} />
            <TextWrapper>
                <Headline>{`Welcome, ${titleCase(first_name)}!`}</Headline>
                <BodyText>{`Congratulations on being invited to join BoomsUp, select your link below.`}</BodyText>
            </TextWrapper>
            <StoreLinksWrapper>
                <a href="https://testflight.apple.com/join/wNVXOqqG">
                    <StoreLinkBadge
                        src={"/LandingPageImgs/AppleAppStoreColor.svg"}
                    />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.cosqosmobile">
                    <StoreLinkBadge
                        src={"/LandingPageImgs/GooglePlayStoreColor.svg"}
                    />
                </a>
            </StoreLinksWrapper>
        </Wrapper>
    );
};

export default InvitedUserContainer;

const appIconLgSize = 280;
const appIconSmSize = 220;
const maxTextWidth = 450;
const headlineTextSize = 32;
const bodyTextSize = 18;

const Wrapper = styled.div`
    display: flex;
    min-height: calc(100vh - ${(props) => props.theme.sizes.navHeight});
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 10px;
    font-family: "Montserrat", sans-serif;
    position: relative;
    background-image: linear-gradient(
        180deg,
        ${(props) => lighten(0.03, props.theme.colors.secondary)},
        ${(props) => props.theme.colors.secondary}
    );
    color: ${(props) => props.theme.colors.primary};
    @media (max-width: ${criticalScreenSize}) {
        padding: 30px 20px;
    }
`;

const AppIcon = styled.img`
    height: ${appIconLgSize}px;
    width: ${appIconLgSize}px;
    @media (max-width: ${criticalScreenSize}) {
        height: ${appIconSmSize}px;
        width: ${appIconSmSize}px;
    }
`;

// -----------------------------------------------
// Text Section  --------------------------------
// -----------------------------------------------
const TextWrapper = styled.div`
    max-width: ${maxTextWidth}px;
`;

const Headline = styled.div`
    font-size: ${headlineTextSize}px;
    font-weight: 500;
    padding: 40px 0 0 0;
    text-align: center;
`;

const BodyText = styled.div`
    font-size: ${bodyTextSize}px;
    line-height: ${bodyTextSize * 2}px;
    padding: ${bodyTextSize * 0.3}px 0 0 0;
    text-align: center;
`;
// -----------------------------------------------
// -----------------------------------------------

// -----------------------------------------------
// Download Links  --------------------------------
// -----------------------------------------------
const StoreLinksWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding: ${bodyTextSize}px 0 0 0;
    @media (max-width: ${criticalScreenSize}) {
        flex-direction: column;
        align-items: center;
    }
`;

const StoreLinkBadge = styled.img`
    width: 200px;
    padding: 0 5px;
    @media (max-width: ${criticalScreenSize}) {
        width: 230px;
        padding: 10px 0;
    }
`;

// -----------------------------------------------
// -----------------------------------------------
