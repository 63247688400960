import styled from "styled-components";

import Button from "../Button";
import { FooterBar } from "../../../resources/styles";

const HeaderBarView = ({
    theme,
    handleCancel, // optional
    cancelLabel, // optional
    handleDone,
    doneLabel,
    doneIcon, // optional
    cancelIcon, // optional
}) => {
    return (
        <FooterBar>
            <FooterBtnBlock>
                {handleCancel && (
                    <LeftBtnWrapper>
                        <Button
                            handleClick={handleCancel}
                            textSize={"1rem"}
                            secondaryBtnColors={true}
                            icon={cancelIcon}
                        >
                            {cancelLabel}
                        </Button>
                    </LeftBtnWrapper>
                )}
                <RightBtnWrapper>
                    <Button
                        handleClick={handleDone}
                        textSize={"1rem"}
                        primaryBtnColors={true}
                        icon={doneIcon}
                    >
                        {doneLabel}
                    </Button>
                </RightBtnWrapper>
            </FooterBtnBlock>
        </FooterBar>
    );
};

export default HeaderBarView;

export const FooterBtnBlock = styled.div`
    display: flex;
    width: 100%; /* Match width of parent  */
    padding: 0 1rem;
    justify-content: flex-end;
`;

const LeftBtnWrapper = styled.div`
    flex: 0 1 100px;
    margin-right: 0.5rem;
`;

const RightBtnWrapper = styled.div`
    flex: 0 1 250px;
`;
