import styled from "styled-components";

// Render a circle button with an icon inside - component can work elsewhere but is a specific format wanted for Nav buttons
const NavBtn = ({ theme, children, handleClick, size, iconColor, bgColor }) => {
    return (
        <Circle
            size={size}
            theme={theme}
            onClick={handleClick}
            iconColor={iconColor}
            bgColor={bgColor || theme.colors.secondary}
        >
            {children}
        </Circle>
    );
};

export default NavBtn;

const Circle = styled.button`
    border-radius: 50%;
    background-color: ${(props) => props.bgColor};
    color: ${({ theme, iconColor }) =>
        iconColor ? iconColor : theme.colors.primary};
    height: ${({ size }) => size};
    width: ${({ size }) => size};
    min-width: ${({ size }) => size};
    min-height: ${({ size }) => size};
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    font-size: calc(${({ size }) => size} / 2);
`;
