import { connect } from "react-redux";
import HeaderBar from "./HeaderBar-view";
import { withTheme } from "styled-components";

function mapState(state) {
    return {
        errors: state.errors,
    };
}

const mapDispatch = {
};

export default connect(mapState, mapDispatch)(withTheme(HeaderBar));
