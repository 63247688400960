import styled from "styled-components";

import SearchDropdown from "./SearchBoxDropdown-view";
import { InputGroup, DefaultInput } from "../InputGroup/InputGroup-container";
import Button from "../Button";

const SearchBox = ({
    dropdownMaxHeight,
    validationError,
    theme,
    searchDescription,
    searchString,
    handleChange,
    onMouseEnter,
    onMouseLeave,
    onClick,
    searchResults,
    currentResult,
    isSearching,
    dropdownRef,
    borderRadius,
    componentRef,
    addCustomStringToParent,
    autoFocus,
}) => {
    return (
        <Wrapper ref={componentRef}>
            <InputGroup>
                <DefaultInput
                    onChange={handleChange}
                    value={searchString}
                    type="text"
                    errorMessage={validationError}
                    placeholder={searchDescription}
                    borderRadius={borderRadius}
                    autoFocus={autoFocus}
                />

                <SearchDropdown
                    dropdownMaxHeight={dropdownMaxHeight}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onClick={onClick}
                    searchResults={searchResults}
                    currentResult={currentResult}
                    dropdownRef={dropdownRef}
                    isSearching={isSearching}
                />
            </InputGroup>
            {addCustomStringToParent && (
                <ButtonWrapper>
                    <Button
                        handleClick={addCustomStringToParent}
                        borderRadius={"0.2rem"}
                        bgColor={theme.colors.accent}
                        textColor={theme.colors.primary}
                        noShadow={true}
                    >
                        Add
                    </Button>
                </ButtonWrapper>
            )}
        </Wrapper>
    );
};

export default SearchBox;

const Wrapper = styled.div`
    display: flex;
    width: 100%;
`;

const ButtonWrapper = styled.div`
    flex: 0 7rem;
    margin-left: 0.5rem;
`;

// import SearchDropdown from "./SearchBoxDropdown-view";

// const SearchBox = ({
//    searchDescription,
//    searchString,
//    handleChange,
//    onMouseEnter,
//    onMouseLeave,
//    onClick,
//    searchResults,
//    currentResult,
//    isSearching,
//    dropdownRef,
// }) => {
//    return (
//       <div className="form-control div-form-control">
//          <input
//             type="text"
//             className="div-form-control-input"
//             placeholder={searchDescription}
//             onChange={handleChange}
//             value={searchString}
//             autoComplete="off"
//          />
//          <SearchDropdown
//             onMouseEnter={onMouseEnter}
//             onMouseLeave={onMouseLeave}
//             onClick={onClick}
//             searchResults={searchResults}
//             currentResult={currentResult}
//             dropdownRef={dropdownRef}
//          />
//          {isSearching && <div>Searching...</div>}
//       </div>
//    );
// };

// export default SearchBox;
