import styled, { css } from "styled-components";
import { lighten, darken, getContrast } from "polished";
import LoadingSpinner from "../LoadingSpinner";

const ButtonView = ({
    children,
    fileUpload, // optional, if true file upload input rendered
    multipleFiles, // optional prop for fil upload components, default false
    icon, // optional
    bgColor, // All color fields are optional
    textColor, // optional
    fontWeight = 400,
    fontFamily, // eg "Montserrat"
    iconColor, // optional
    borderColor, // optional
    handleClick,
    primaryBtnColors, // optional color schemes
    secondaryBtnColors,
    textSize, // optional
    iconSize, // optional
    noShadow, // optional if true no shadow behind button
    theme,
    borderRadius, // optional
    disabled,
    justifyContent, // optional default center
    btnPadding, // optional padding inside button
    iconPadding, // optional way to manually set the icons padding
    maxHeight, // optional way to limit button height
    fillParentHeight, // optional if true btn will be 100% parent height
    buttonLoading, // optional button loading view
}) => {
    // Default text size if not given as prop
    textSize = textSize ? textSize : "1rem";

    const { primary, secondary, accent } = theme.colors;

    // Color options, first preference is to use the specified color, second is to user primaryBtnColor, third secondaryBtnColor then if none then use default
    bgColor = bgColor
        ? bgColor
        : primaryBtnColors
        ? accent
        : secondaryBtnColors
        ? primary
        : secondary;
    textColor = textColor
        ? textColor
        : primaryBtnColors
        ? primary
        : secondaryBtnColors
        ? secondary
        : primary;
    borderColor = borderColor
        ? borderColor
        : primaryBtnColors
        ? accent
        : secondaryBtnColors
        ? lighten(0.7, secondary)
        : bgColor;
    iconColor = iconColor ? iconColor : textColor;

    // if (primaryBtnColors) {
    //     // if primaryBtnColors prop is true then colors the button with default submit buttons colors
    //     bgColor = theme.colors.accent;
    //     textColor = theme.colors.primary;
    //     borderColor = theme.colors.accent;
    //     iconColor = textColor;
    // } else if (secondaryBtnColors) {
    //     // default cancel button colors
    //     bgColor = theme.colors.primary;
    //     textColor = theme.colors.secondary;
    //     borderColor = lighten(0.7, theme.colors.secondary);
    //     iconColor = textColor;
    // } else {
    //     // Set colors according to value given in props or default value of prop not given
    //     textColor = textColor ? textColor : theme.colors.primary;
    //     bgColor = bgColor ? bgColor : theme.colors.secondary;
    //     borderColor = borderColor // Note this declaration must be after bgColor above
    //         ? borderColor
    //         : bgColor;
    //     iconColor = iconColor ? iconColor : textColor;
    // }

    if (fileUpload) {
        // If button is a file upload button
        return (
            <FileUpload
                bgColor={bgColor}
                textColor={textColor}
                textSize={textSize}
                borderColor={borderColor}
                noShadow={noShadow}
                borderRadius={borderRadius}
                justifyContent={justifyContent}
                btnPadding={btnPadding}
                maxHeight={maxHeight}
                fillParentHeight={fillParentHeight}
            >
                {icon && (
                    <Icon
                        textSize={textSize}
                        iconSize={iconSize}
                        iconColor={iconColor}
                        buttonLoading={buttonLoading}
                        iconPadding={iconPadding}
                    >
                        {icon}
                    </Icon>
                )}
                <Text
                    textSize={textSize}
                    btnPadding={btnPadding}
                    buttonLoading={buttonLoading}
                    fillParentHeight={fillParentHeight}
                    fontWeight={fontWeight}
                    fontFamily={fontFamily}
                >
                    {children}
                </Text>
                {buttonLoading && (
                    <LoadingSpinnerWrapper textSize={textSize}>
                        <LoadingSpinner color={iconColor} />
                    </LoadingSpinnerWrapper>
                )}
                <ChooseFile
                    type="file"
                    onChange={handleClick}
                    multiple={multipleFiles ? true : false}
                    disabled={disabled || buttonLoading} // disable button when loading or when disabled passed in
                ></ChooseFile>
            </FileUpload>
        );
    } else {
        // Regular buttons
        return (
            <Button
                onClick={handleClick}
                hasClickListener={!!handleClick}
                bgColor={bgColor}
                textColor={textColor}
                textSize={textSize}
                borderColor={borderColor}
                noShadow={noShadow}
                borderRadius={borderRadius}
                disabled={disabled || buttonLoading} // disable button when loading or when disabled passed in
                justifyContent={justifyContent}
                btnPadding={btnPadding}
                maxHeight={maxHeight}
                fillParentHeight={fillParentHeight}
            >
                {icon && (
                    <Icon
                        textSize={textSize}
                        iconSize={iconSize}
                        iconColor={iconColor}
                        buttonLoading={buttonLoading}
                        iconPadding={iconPadding}
                    >
                        {icon}
                    </Icon>
                )}
                <Text
                    textSize={textSize}
                    btnPadding={btnPadding}
                    buttonLoading={buttonLoading}
                    fillParentHeight={fillParentHeight}
                    fontWeight={fontWeight}
                    fontFamily={fontFamily}
                >
                    {children}
                </Text>
                {buttonLoading && (
                    <LoadingSpinnerWrapper textSize={textSize}>
                        <LoadingSpinner color={iconColor} />
                    </LoadingSpinnerWrapper>
                )}
            </Button>
        );
    }
};

export default ButtonView;

const Shared = css`
    /* If btnPadding specified then use that if not scale of text size */
    padding: ${({ btnPadding, textSize }) =>
        btnPadding !== undefined ? btnPadding : `0 calc(${textSize}*0.8)`};
    /* padding: 0 calc(${(props) => props.textSize} * 0.8); */
    display: flex;
    justify-content: ${({ justifyContent }) =>
        justifyContent ? justifyContent : "center"};
    align-items: center;
    width: 100%;
    height: ${(props) => (props.fillParentHeight ? "100%" : "auto")};
    border-radius: ${({ borderRadius }) =>
        borderRadius ? borderRadius : "0.5rem"};
    font-size: ${(props) => props.textSize};
    background-color: ${(props) => props.bgColor};
    color: ${(props) => props.textColor};
    border: 1px solid ${(props) => props.borderColor};
    /* default shadow if no shadow specified then its hidden*/
    box-shadow: ${(props) =>
        props.noShadow ? "none" : "2px 2px 5px rgba(150, 150, 150, 0.3)"};
    max-height: ${(props) => props.maxHeight || "none"};
    position: relative; /* For positioning loading spinner absolute inside */

    &:hover {
        color: ${(
            { textColor, hasClickListener } // lightens for dark colors and darkens for light colors
        ) =>
            hasClickListener
                ? getContrast(textColor, "#ffffff") >= 10
                    ? lighten(0.2, textColor)
                    : darken(0.2, textColor)
                : textColor};
        background-color: ${(
            { bgColor, hasClickListener } // lightens for dark colors and darkens for light colors
        ) =>
            hasClickListener
                ? getContrast(bgColor, "#ffffff") >= 10
                    ? lighten(0.05, bgColor)
                    : darken(0.03, bgColor)
                : bgColor};
        border: 1px solid
            ${({ borderColor, hasClickListener }) =>
                hasClickListener
                    ? getContrast(borderColor, "#ffffff") >= 10
                        ? lighten(0.05, borderColor)
                        : darken(0.03, borderColor)
                    : borderColor};
    }
    /* User clicking down on button */
    &:active {
        box-shadow: none;
        color: ${({ textColor, hasClickListener }) =>
            hasClickListener
                ? getContrast(textColor, "#ffffff") >= 10
                    ? lighten(0.3, textColor)
                    : darken(0.3, textColor)
                : textColor};
        background-color: ${({ bgColor, hasClickListener }) =>
            hasClickListener
                ? getContrast(bgColor, "#ffffff") >= 10
                    ? lighten(0.1, bgColor)
                    : darken(0.15, bgColor)
                : bgColor};
        border: 1px solid
            ${({ borderColor, hasClickListener }) =>
                hasClickListener
                    ? getContrast(borderColor, "#ffffff") >= 10
                        ? lighten(0.1, borderColor)
                        : darken(0.15, borderColor)
                    : borderColor};
    }
`;
const Button = styled.div`
    ${Shared}
    cursor: ${(props) => (props.hasClickListener ? "pointer" : "auto")};
`;

const FileUpload = styled.label`
    ${Shared}
    cursor: pointer;
`;

// Icon has larger
const Icon = styled.div`
    padding: ${({ iconPadding, textSize }) =>
        iconPadding ? iconPadding : `0 calc(${textSize}*0.6) 0 0`};
    font-size: ${({ iconSize }) => (!!iconSize ? iconSize : "150%")};
    color: ${(props) => props.iconColor};
    /* Hide button icon and text if buttonLoading */
    opacity: ${(props) => (props.buttonLoading ? 0 : 1)};
    /* color: #ffff; */
`;

const Text = styled.div`
    margin: 0;
    font-weight: ${(props) => props.fontWeight};
    /* If btnPadding has been specified then remove text padding, vertical padding specified here for default as it is not effect by an icon which might be there for some buttons and not for others */
    font-family: ${({ fontFamily }) => fontFamily};
    padding: ${({ btnPadding, textSize, fillParentHeight }) =>
        btnPadding !== undefined
            ? "0"
            : fillParentHeight
            ? "auto"
            : `calc(${textSize}*0.5) 0`};

    opacity: ${(props) => (props.buttonLoading ? 0 : 1)};
`;

const ChooseFile = styled.input`
    display: none;
`;

// Loading spinner is rendered in center of button position absolute, button contents has an opacity of 0 when buttonLoading
const LoadingSpinnerWrapper = styled.div`
    position: absolute;
    left: auto;
    right: auto;
    height: ${({ textSize }) => `calc(${textSize}*1.8)`};
    width: ${({ textSize }) => `calc(${textSize}*1.8)`};
`;
