import styled from "styled-components";

import Button from "../../library/common/Button";
import ChatList from "../MessagesScreen/ChatList";

const DropdownMessagesView = ({ history }) => {

    // const MsgsToRender = <ChatList listOnly={true} />;

    return (
        <Wrapper>
            <TitleRow>
                <Heading>Messages</Heading>
                <AllBtnWrapper>
                    <Button
                        handleClick={() => {
                            history.push("/messages");
                        }}
                        textSize={"1rem"}
                        secondaryBtnColors={true}
                        icon={<i className="fas fa-expand"></i>}
                        borderRadius={"1rem"}
                        noShadow={true}
                    >
                        <b>All</b>
                    </Button>
                </AllBtnWrapper>
            </TitleRow>
            <ChatList listOnly={true} />
        </Wrapper>
    );
};

export default DropdownMessagesView;

const Wrapper = styled.div`
    /* display: flex;
    flex-direction: column;
    flex-wrap: wrap; */
    font-size: 1rem;
    padding: 0.7rem;
`;

const TitleRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 1rem 0;
`;

const Heading = styled.h3`
    margin: 0;
    color: ${(props) => props.theme.colors.darkAccent};
`;

const AllBtnWrapper = styled.div`
    flex: 0 1 90px;
`;

// const chatNameString = chat.name
//     ? chat.name
//     : chatGivenNamesString(chat, currentUser.user.id);

// const unreadMessageInChat = checkForUnreadMsgInChat(
//     chat,
//     currentUser.user.id
// );

// const recentMsgInChat =
// chat.messages.length > 0
//     ? chat.messages[chat.messages.length - 1]
//     : undefined;

// return (
//     <ChatListItemWrapper key={i}
//     unread={unreadMessageInChat}
//     onClick={() => {
//         history.push(`/messages/${chat._id}`)
//     }}
//     >
//         <UnreadMarker unread={unreadMessageInChat} />
//         <ChatIcon chat={chat} currentUser={currentUser} theme={theme} />
//         <DetailsCol>
//             <Name>{chatNameString}</Name>
//             {chat.messages.length > 0 && (
//                 <Message>
//                     {recentMsgInChat.messageText // by default use text, of none check for images (null should never occur has can't send message without text or images)
//                         ? recentMsgInChat.messageText
//                         : recentMsgInChat.images
//                         ? "Sent a photo."
//                         : null}
//                 </Message>
//             )}
//         </DetailsCol>
//     </ChatListItemWrapper>
// )
