import styled, { withTheme } from "styled-components";

import TrashButton from "../../ButtonTrashCircle";
import EditButton from "../../ButtonEditCircle";
import { ContentBox } from "../../../../resources/styles";
// import HeaderBar from "./HeaderBar-view ";
import HeaderBar from "../../PopupHeaderBar";
import FooterBar from "../../PopupFooterBar";

// Renders view components and FormFieldsToRender custom input JSX
const AddressListView = ({
    theme,
    popupTitle,
    addressList,
    setPopUpToDisplay,
    addOrEditLocation,
    handleDelete,
}) => {
    const AddressListItems = () => {
        return addressList.map((location, i) => {
            return (
                <LocationWrapper key={i}>
                    <Row>
                        <LocationTitle>Location {i}</LocationTitle>
                    </Row>
                    <Row>
                        <MarkerIcon className="fas fa-map-marker-alt"></MarkerIcon>
                        <AddressString>{location.address}</AddressString>
                        <AddressBtnWrapper>
                            <EditButton
                                handleClick={() => {
                                    console.log("CLICK");
                                    addOrEditLocation(i);
                                }}
                                circleDiameter={"2.8rem"}
                                fontSize={"1.1rem"}
                            // bgColor={theme.colors.semantic.success}
                            // iconColor={theme.colors.secondary}
                            />
                        </AddressBtnWrapper>
                        {addressList.length > 1 && ( // Only shows delete button if more than one address in list (Ensure user always has at least one location)
                            <AddressBtnWrapper>
                                <TrashButton
                                    handleClick={() => {
                                        handleDelete(i);
                                    }}
                                    bgColor={theme.colors.semantic.error}
                                    iconColor={theme.colors.secondary}
                                    circleDiameter={"2.8rem"}
                                    fontSize={"1.2rem"}
                                />
                            </AddressBtnWrapper>
                        )}
                        <hr />
                    </Row>
                </LocationWrapper>
            );
        });
    };
    return (
        <Wrapper>
            <HeaderBar
                setPopUpToDisplay={setPopUpToDisplay}
                popupTitle={popupTitle}
                handleAdd={() => {
                    addOrEditLocation(addressList.length);
                }}
            />
            <ContentBox>
                <ContentWrapper>
                    <AddressListItems />
                </ContentWrapper>
            </ContentBox>
            <FooterBar
                handleDone={() => {
                    setPopUpToDisplay("");
                }}
                doneLabel={"Done"}
            />
        </Wrapper>
    );
};

export default withTheme(AddressListView);

export const Wrapper = styled.div`
    width: min(
        750px,
        95vw
    ); /* Services have a flex wrap so the width effect numbers of columns*/
    min-height: 250px;
`;

const ContentWrapper = styled.div`
    padding: 1rem 3rem 1rem 3rem;
    @media (max-width: ${({ theme }) => theme.sizes.mdViewWidth}) {
        padding: 1rem 1rem 1rem 1rem;
    }
`;

const LocationWrapper = styled.div`
    margin: 0 0 2rem 0;
    display: flex;
    flex-direction: column;
`;

const Row = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const LocationTitle = styled.h4`
    text-decoration: underline;
`;

const MarkerIcon = styled.i`
    color: #e01010;
    font-size: 175%;
    margin-right: 0.5rem;
`;

const AddressString = styled.p`
    margin: 0 0 0 0;
    flex: 1 1 600px;
`;

const AddressBtnWrapper = styled.div`
    /* flex: 1 1 150px; */
    margin: 0 0.4rem;
`;
