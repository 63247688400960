import React from "react";
import styled from "styled-components";
import Button from "../../library/common/Button";

const BtnBarView = ({
    handleCancel,
    handleSubmit,
    handleNext,
    submitText,
    nextText,
}) => {
    return (
        <FooterBtnBlock>
            <LeftBtnWrapper>
                <Button
                    handleClick={handleCancel}
                    textSize={"1rem"}
                    secondaryBtnColors={true}
                >
                    Cancel
                </Button>
            </LeftBtnWrapper>
            <RightBtnWrapper>
                <Button
                    handleClick={handleSubmit || handleNext}
                    textSize={"1rem"}
                    primaryBtnColors={true}
                >
                    {submitText || nextText}
                </Button>
            </RightBtnWrapper>
        </FooterBtnBlock>
    );
};

export default BtnBarView;

const FooterBtnBlock = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    background-color: ${(props) => props.theme.colors.primary};
    border-top: 1px solid ${(props) => props.theme.colors.offPrimary};
`;

const LeftBtnWrapper = styled.div`
    flex: 0 1 200px;
    margin-right: 0.5rem;
`;

const RightBtnWrapper = styled.div`
    flex: 0 1 400px;
`;
